import React from 'react'
import PropTypes from "prop-types";
import spinnerGif from "gui-common/resources/spinner.gif";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function LoadingDataSpinner(props) {

    const spinnerSize = props.spinnerSize ? props.spinnerSize : '70px';
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <img
                style={props.spinnerStyling ? props.spinnerStyling : {
                    width: spinnerSize,
                    height: spinnerSize
                }}
                src={spinnerGif} alt="waiting"
            />
            <h5 style={{margin: '-5px', padding: '0'}}>{props.messageStr ? props.messageStr :
                <XpTranslated trKey={props.trKey} trParams={props.trParams}/>}
            </h5>
        </div>
    )
}
LoadingDataSpinner.propTypes = {
    trKey:      PropTypes.string,
    trParams:   PropTypes.string,
    spinnerSize: PropTypes.string,
    messageStr:    PropTypes.string,
    spinnerStyling:    PropTypes.object,
    messageComponent: PropTypes.node,
};
LoadingDataSpinner.defaultProps = {
    classNameSpinner:      "spinner",
    classNameMessage:      "valueText",
};

export default LoadingDataSpinner;


