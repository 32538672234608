import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import FxRejectConfigList from "./FxRejectConfigList";
import ComponentLoading from "gui-common/components/ComponentLoading";
import {
    getOrmFxRejectConfigSelector,
} from "./fxRejectConfigSelectors";
import {putFxRejectConfigToApi} from "./fxRejectConfigApi";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {userSelector} from "gui-common/orm/ormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {XP_FORM_EDIT, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {useXpFormField, useXpFormState} from "gui-common/xpForm/core/xpFormHooks";
import {
    xpFormAddField,
    xpFormChangeField,
    xpFormLoadForm,
    xpFormSubmitForm
} from "gui-common/xpForm/core/xpFormReducer";
import {xpFormContainsErrors} from "gui-common/xpForm/core/xpFormFunctions";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {commonSystemRights} from "gui-common/userRights/userRightsConstants";


function FxRejectConfigType (props) {

    const thisInstanceId = "fxRejectConfig-" + props.rejectType;
    const formModel = 'fxRejectConfigForm.' + props.rejectType;

    const [gridApi, setGridApi] = useState(undefined);
    const [itemEditing, setItemEditing] = useState(false);
    const [configClearing, setConfigClearing] = useState(false);
    const [newAdded, setNewAdded] = useState(undefined);
    const translate         = useXpTranslateFunction();
    const thisUser          = useSelector(userSelector);
    const formMetaData      = useXpFormState(formModel);

    const ormFxRejectConfigs  = useSelectorInstance(getOrmFxRejectConfigSelector, {rejectType: props.rejectType})
    const formFxRejectConfigs = useXpFormField(formModel);

    const dispatch  = useDispatch();

    const userCanUpdate = useMemo(() => { return userHasSystemRight(thisUser, commonSystemRights.FxRejectConfiguration.Update);}, [thisUser]);

    const currentData = useMemo(
        () => {
            if (props.auditMode) {
                return {[props.currentData.id]: props.currentData};
            }
            let outData = {};
            if (!ormFxRejectConfigs?.length) return {};
            ormFxRejectConfigs.forEach(item => {
                outData[item.id] = item;
            })
            return outData;
        },
        [ormFxRejectConfigs, props.auditMode, props.currentData, props.beforeChangeData]
    );
    const beforeChangeData = useMemo(
        () => {
            if (props.auditMode && props.beforeChangeData) {
                return {[props.beforeChangeData.id]: props.beforeChangeData}
            }
            return undefined
        },
        [props.auditMode, props.beforeChangeData]
    );
    useEffect( // Effect to add currentData lines to form reducer.
        () => {
            if (!ormFxRejectConfigs?.length) return;
            if (!formFxRejectConfigs) return;
            if (formMetaData?.submittedToApi) return;
            for (const key in currentData) {
                if (!formFxRejectConfigs[key] || !formFxRejectConfigs[key].id) {
                    dispatch(xpFormAddField(formModel + '.' + key, {...currentData[key]}));
                }
            }
        },
        [ormFxRejectConfigs, formFxRejectConfigs, formMetaData],
    );
    useEffect( // Effect to make rejectCode cell in an added line focused and in edit mode.
        () => {
            if (!newAdded || !gridApi) return;
            const newRow = gridApi.rowModel.rowsToDisplay.find(item => item.id === newAdded);
            if (!newRow) return;
            gridApi.startEditingCell({rowIndex: newRow.rowIndex, colKey: 'rejectCode'});
            setNewAdded(undefined);
        },
        [newAdded],
    );

    const activeErrors = useMemo(
        () => {
            return (xpFormContainsErrors(formFxRejectConfigs));
        },
        [formFxRejectConfigs]
    );

    function compare( a, b ) {
        if ( a.matchOrderIndex < b.matchOrderIndex ) return -1;
        if ( a.matchOrderIndex > b.matchOrderIndex ) return 1;
        return 0;
    }
    const listData = useMemo(
        () => {
            if (props.auditMode) {
                return Object.keys(currentData).map(key => currentData[key]);
            }
            if (formMetaData?.submittedToApi) return [];
            if (configClearing) return [];
            let outArray = [];
            for (let key in formFxRejectConfigs) {
                if (key[0] === '$') continue;
                if (!formFxRejectConfigs[key]) {
                    // This should not happen, but never add an undefined element.
                    continue;
                }
                if (!formFxRejectConfigs[key].id) {
                    // This should not happen either, but if it does, clear the non valid entry.
                    dispatch(xpFormChangeField(formModel + '.' + key, undefined));
                    continue;
                }
                outArray.push(formFxRejectConfigs[key])
            }
            return outArray.sort(compare);
        },
        [formFxRejectConfigs, formMetaData, configClearing, currentData]
    );
    const listDataRef = useRef(listData);
    listDataRef.current = listData;

    function saveConfig () {
        let saveArray = [];
        let matchIndex = 1;
        for (let i = 0; i < listData.length; i++) {
            if (listData[i].isDeleted) continue;
            saveArray.push({...listData[i], matchOrderIndex: matchIndex});
            matchIndex++;
        }
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'fxRejectConfig.confirmSaveConfig'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    dispatch(putFxRejectConfigToApi(props.rejectType, saveArray))
                    setItemEditing(false);
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    function resetConfig () {
        setConfigClearing(true);
        dispatch(xpGridSetSelected(thisInstanceId, undefined));
        dispatch(xpFormLoadForm(formModel, {}))
        setItemEditing(false);
        setConfigClearing(false);
    }

    function shiftMatchOrderIndexOnItems(indexInserted, indexRemoved) {
        if ((indexInserted === undefined) && (indexRemoved === undefined)) return; // Should not happen.

        listDataRef.current.forEach(item => {
            if (indexRemoved && (item.matchOrderIndex === indexRemoved)) return; // Removed index is handled in calling code.

            let newIndex = undefined;
            if (indexRemoved === undefined) { // Only add item
                if (item.matchOrderIndex >= indexInserted) newIndex = item.matchOrderIndex + 1;
            }
            else if (indexInserted === undefined) { // Only remove item
                if (item.matchOrderIndex  > indexRemoved ) newIndex = item.matchOrderIndex - 1;
            }
            else if (indexInserted > indexRemoved) {
                if ((item.matchOrderIndex > indexRemoved) && (item.matchOrderIndex <= indexInserted)) newIndex = item.matchOrderIndex - 1;
            }
            else if (indexInserted < indexRemoved) {
                if ((item.matchOrderIndex >= indexInserted) && (item.matchOrderIndex < indexRemoved)) newIndex = item.matchOrderIndex + 1;
            }
            if (newIndex !== undefined) {
                dispatch(xpFormChangeField(formModel + '.' + item.id + '.matchOrderIndex', newIndex));
            }
        })
    }

    function moveNodeToNewIndex(node, newIndex) {
        if (!node || (newIndex === undefined)) return;
        shiftMatchOrderIndexOnItems(newIndex + 1, node.matchOrderIndex);
        dispatch(xpFormChangeField(formModel + '.' + node.id + '.matchOrderIndex', newIndex + 1));
    }

    function addConfig (insertAtMatchOrderIndex) {
        let highestNewIndex = 1;
        let highestMatchOrderIndex = 0;
        listDataRef.current.forEach(item => {
            if (item.newIndex >= highestNewIndex) highestNewIndex = item.newIndex + 1;
            if (item.matchOrderIndex > highestMatchOrderIndex) highestMatchOrderIndex = item.matchOrderIndex;
        })
        if (insertAtMatchOrderIndex) shiftMatchOrderIndexOnItems(insertAtMatchOrderIndex);
        const newId = 'new' + highestNewIndex;
        setNewAdded(newId);
        dispatch(xpFormAddField(formModel + '.new' + highestNewIndex, {
            id                  : newId,
            isNew               : true,
            rejectCode          : "",
            matchText           : "",
            autoGenerated       : false,
            originalReasonText  : "",
            retryExecution      : false,
            errorResponseText   : "",
            newIndex            : highestNewIndex,
            matchOrderIndex     : insertAtMatchOrderIndex ? insertAtMatchOrderIndex : highestMatchOrderIndex + 1,
        }));
    }

    function removeConfig(config) {
        if (config.newIndex) {
            dispatch(xpFormChangeField(formModel + '.' + config.id, undefined));
        }
        else {
            dispatch(xpFormChangeField(formModel + '.' + config.id + '.isDeleted', true));
        }
    }

    function restoreConfig(config) {
        dispatch(xpFormChangeField(formModel + '.' + config.id + '.isDeleted', undefined));
    }

    function onGridReadyCallback(e) {
        setGridApi(e.api);
    }

    return (
        <div>
            {!props.auditMode &&
            <h4>
                <XpTranslated trKey={'fxRejectConfig.configTypeHeader'}/>
                <XpTranslated trKey={'fxRejectConfig.rejectTypes.' + props.rejectType}/>
            </h4>}
            <div>
                <XpForm
                    formModel={formModel}
                    formTemplate={props.formTemplate}
                    onSubmit={(data) => saveConfig(data)}
                    initialUseState={props.auditMode ? XP_FORM_VIEW : XP_FORM_EDIT}
                    currentData={currentData}
                    beforeChangeData={beforeChangeData ? beforeChangeData : currentData}
                    auditMode={props.auditMode}
                >
                    {(formMetaData?.submittedToApi || configClearing) ?
                        <ComponentLoading loadingMessage={translate('fxRejectConfig.loadingMessage', {rejectType: translate('fxRejectConfig.rejectTypes.' + props.rejectType)})}/>
                        :
                        <FxRejectConfigList
                            rejectType={props.rejectType}
                            inputData ={listData}
                            // inputData ={rejectTypeState.configArray}
                            instanceId={thisInstanceId}
                            onGridReadyCallback={onGridReadyCallback}
                            setEditingCallback={setItemEditing}
                            auditMode={props.auditMode}
                            addConfig={addConfig}
                            removeConfig={removeConfig}
                            restoreConfig={restoreConfig}
                            moveNodeToNewIndex={moveNodeToNewIndex}
                            ormModel={'FxRejectConfiguration'}
                        />
                    }
                </XpForm>
                {(userCanUpdate && !props.auditMode) &&
                <WaitingXpoolButton
                    onClickCallback = {() => dispatch(xpFormSubmitForm(formModel))}
                    disabled        = {(activeErrors || (!formMetaData?.isTouched && !itemEditing))}
                    labelKey        = 'fxRejectConfig.saveButton'
                    waiting         = {formMetaData?.submittedToApi}
                />}
                {(userCanUpdate && !props.auditMode) &&
                <WaitingXpoolButton
                    onClickCallback = {() => resetConfig()}
                    disabled        = {(!formMetaData?.isTouched || formMetaData?.submittedToApi)}
                    labelKey        = 'fxRejectConfig.cancelButton'
                    modalKey        = 'fxRejectConfig.confirmCancel'
                />}
                {(userCanUpdate && !props.auditMode) &&
                <WaitingXpoolButton
                    onClickCallback = {() => addConfig()}
                    labelKey        = 'fxRejectConfig.addButton'
                    disabled        = {formMetaData?.submittedToApi}
                    floatRight
                />}
                <hr/>
            </div>
        </div>
    )
}
FxRejectConfigType.propTypes = {
};
export default FxRejectConfigType

