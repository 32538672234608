import {ormItemArrayTransformer} from "gui-common/orm/ormApi";
import {
    addSecondsToRunTime,
    getNestedIdProperty, getNestedIdProperty2,
    setAuditUserFields,
    setNestedIdProperty,
    setParentId,
    setParentIdIfDefined,
    setParentIdPropertyInCreateBody,
    stripSecondsFromRunTime,
} from "gui-common/functions/functions";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {createMarginGroupBody, marginGroupTransformer} from "features/marginGroup/marginGroupFunctions";
import {
    priceTolerancesIncomingTransformer,
    priceTolerancesOutgoingTransformer
} from "features/priceTolerances/priceTolerancesFunctions";
import {transformExecutionRights} from "gui-common/userRights/userRightsFunctions";
import {isArray} from "lodash";
import {commonIncomingTransformersMap} from "gui-common/api/commonTransformers";


export const refixIncomingTransformersMap = {
    Client                  : clientResponseTransformer,
    LegalEntity             : legalEntityResponseTransformer,
    LegalEntityUnit         : legalEntityUnitTransformer,
    Agreement               : agreementTransformer,
    ListRate                : listRateTransformer,
    ListRatePeriod          : listRatePeriodTransformer,
    DispatchPeriod          : dispatchPeriodTransformer,
    MarketOrder             : marketOrderTransformer,
    DispatchTrade           : dispatchTradeTransformer,
    ListRateOrder           : listRateOrderTransformer,
    ListRateOrderAllocation : listRateOrderAllocationTransformer,
    MarginGroup             : marginGroupTransformer,
    PriceTolerances         : priceTolerancesIncomingTransformer,
    PriceFeed               : priceFeedTransformer,
    ListRateOrderResult     : listRateOrderResultTransformer,
    FxTradeReportErpConfig  : fxTradeReportErpConfigTransformer
};
export const refixOutgoingTransformersMap = {
    Client                  : createClientBody,
    LegalEntity             : createLegalEntityBody,
    LegalEntityUnit         : createLegalEntityUnitBody,
    Agreement               : createAgreementBody,
    Order                   : undefined,
    ListRate                : undefined,
    ListRatePeriod          : undefined,
    MarketOrder             : undefined,
    DispatchTrade           : undefined,
    ListRateOrder           : undefined,
    MarginGroup             : createMarginGroupBody,
    PriceTolerances         : priceTolerancesOutgoingTransformer,
    PriceFeed               : undefined,
    FxTradeReportErpConfig  : fxTradeReportErpConfigOutgoingTransformer,
};

export function clientResponseTransformer(client, params) {
    setAuditUserFields(client);
    // if (params && params.createMomentObjectsForAudit) setItemDateTimes(client);

    client.operatingUnitId           = getNestedIdProperty(client, 'operatingUnit');

    transformExecutionRights(client);

    if (!client.id)                 client.id                = 0;
    if (!client.name)               client.name              = "";
    if (!client.description)        client.description       = "";
    if (!client.clientRefId)        client.clientRefId       = "";
    if (!client.timeZone)           client.timeZone          = "";

    if (client.useLegalEntities === undefined) client.useLegalEntities = true;
    if (client.useLegalEntities === null)      client.useLegalEntities = true;

    if (client.legalEntities) {
        client.legalEntities = ormItemArrayTransformer('LegalEntity', client.legalEntities, {...params, parentId: client.id});
    }
    return client;
}
function createClientBody(client, decDenLangState, method, getState) {

    let legalEntities = undefined;
    if (client.legalEntities) {
        legalEntities = client.legalEntities.map(item => createLegalEntityBody(item, decDenLangState, method, getState));
    }

    return {
        operatingUnit       : setNestedIdProperty(client, 'operatingUnitId'),
        id:                   client.id,
        name:                (client.name           !== "") ? client.name           : null,
        description:         (client.description    !== "") ? client.description    : null,
        clientRefId:         (client.clientRefId    !== "") ? client.clientRefId    : null,
        timeZone:             client.timeZone,
        netDispatchTrades:   (client.netDispatchTrades)     ? client.netDispatchTrades : false,

        useLegalEntities:     client.useLegalEntities,
        clientApiChannel:     client.clientApiChannel,
        legalEntities:        legalEntities,
    }
}

export function legalEntityResponseTransformer(legalEntity, params) {
    setAuditUserFields(legalEntity);
    // if (params && params.createMomentObjectsForAudit) setItemDateTimes(legalEntity);

    transformExecutionRights(legalEntity);

    setParentId(legalEntity, 'clientId', params ? params.parentId : null);
    if (legalEntity.name                === null)       legalEntity.name = "";
    if (legalEntity.description         === null)       legalEntity.description = "";
    if (legalEntity.useLegalEntityUnits === null)       legalEntity.useLegalEntityUnits = true;
    if (legalEntity.useLegalEntityUnits === undefined)  legalEntity.useLegalEntityUnits = true;
    legalEntity.legalPersonType = (legalEntity.legalPersonType) ? legalEntity.legalPersonType : "";

    legalEntity.countryId           = getNestedIdProperty(legalEntity, 'country');

    if (legalEntity.legalEntityUnits) {
        legalEntity.legalEntityUnits = ormItemArrayTransformer('LegalEntityUnit', legalEntity.legalEntityUnits, {...params, parentId: legalEntity.id});
    }
    if (legalEntity.fxTradeReportErpConfiguration) {
        legalEntity.fxTradeReportErpConfiguration = fxTradeReportErpConfigTransformer(legalEntity.fxTradeReportErpConfiguration);
    }
    return legalEntity;
}
function createLegalEntityBody(legalEntity, decDenLangState, method, getState) {
    let legalEntityUnits = undefined;
    if (legalEntity.legalEntityUnits) {
        legalEntityUnits = legalEntity.legalEntityUnits.map(item => createLegalEntityUnitBody(item, decDenLangState, method, getState));
    }
    if (legalEntity.fxTradeReportErpConfiguration) {
        legalEntity.fxTradeReportErpConfiguration = fxTradeReportErpConfigOutgoingTransformer(legalEntity.fxTradeReportErpConfiguration);
    }
    return {
        ...setParentIdPropertyInCreateBody(legalEntity, 'clientId', method),
        id:                  legalEntity.id,
        name:                legalEntity.name,
        description:         legalEntity.description,
        country:             setNestedIdProperty(legalEntity, 'countryId'),
        legalPersonType:     legalEntity.legalPersonType ? legalEntity.legalPersonType : null,
        useLegalEntityUnits: legalEntity.useLegalEntityUnits,
        fxTradeReportToErpActive: legalEntity.fxTradeReportToErpActive,
        legalEntityUnits:    legalEntityUnits,
        fxTradeReportErpConfiguration: legalEntity.fxTradeReportErpConfiguration,
    }
}


export function legalEntityUnitTransformer(legalEntityUnit, params) {
    setParentIdIfDefined(legalEntityUnit, 'legalEntityId', params ? params.parentId : null);

    transformExecutionRights(legalEntityUnit);

    if (legalEntityUnit.fixSession !== undefined) {
        legalEntityUnit.fixSessionId = getNestedIdProperty(legalEntityUnit, 'fixSession');
    }

    if (legalEntityUnit.tradingLimitCurrency !== undefined) {
        legalEntityUnit.tradingLimitCurrencyId = getNestedIdProperty(legalEntityUnit, 'tradingLimitCurrency');
    }

    if (legalEntityUnit.tradingLimitType) {
        legalEntityUnit.netTradingLimit = (legalEntityUnit.tradingLimitType === "Net");
    }

    if (legalEntityUnit.marginGroup !== undefined) {
        legalEntityUnit.marginGroupId = getNestedIdProperty(legalEntityUnit, 'marginGroup');
        legalEntityUnit.marginGroupAssigned = legalEntityUnit.marginGroupId !== null;
    }

    if (legalEntityUnit.agreements) {
        legalEntityUnit.agreements = ormItemArrayTransformer('Agreement', legalEntityUnit.agreements, {...params, parentId: legalEntityUnit.id});
    }
    if (legalEntityUnit.fxOrderReports) {
        legalEntityUnit.fxOrderReports = ormItemArrayTransformer('FxOrderReport', legalEntityUnit.fxOrderReports, {...params, propsFromParent: {legalEntityUnitId: legalEntityUnit.id}});
    }
    legalEntityUnit.tradingLimitUtilization = Math.abs(legalEntityUnit.tradingLimitUtilization);


    return legalEntityUnit;
}
function createLegalEntityUnitBody(legalEntityUnit, decDenLangState, method, getState) {
    let agreements = undefined;
    if (legalEntityUnit.agreements && (method === 'POST')) {
        agreements = legalEntityUnit.agreements.map(item => createAgreementBody(item, decDenLangState, method, getState));
    }
    return {
        ...setParentIdPropertyInCreateBody(legalEntityUnit, 'legalEntityId', method),
        id:             legalEntityUnit.id,
        name:           legalEntityUnit.name,
        description:    legalEntityUnit.description,
        priceFeedOnly:  legalEntityUnit.priceFeedOnly,
        appId:          legalEntityUnit.appId,
        fxEnabled:      legalEntityUnit.fxEnabled,
        fixSession:     setNestedIdProperty(legalEntityUnit, 'fixSessionId'),
        fxShortName:    legalEntityUnit.fxShortName ? legalEntityUnit.fxShortName : null,
        restTimeZone:   (legalEntityUnit.restTimeZone) ? legalEntityUnit.restTimeZone : "Europe/Stockholm",
        tradingLimit:   stringNumberToFloat(legalEntityUnit.tradingLimit, decDenLangState),
        tradingLimitCurrency:  setNestedIdProperty(legalEntityUnit, 'tradingLimitCurrencyId'),
        tradingLimitType:   (legalEntityUnit.netTradingLimit) ? "Net" : "Gross",
        todayLimitOverride: stringNumberToFloat(legalEntityUnit.todayLimitOverride, decDenLangState),
        exemptFromGlobalSuspend: legalEntityUnit.exemptFromGlobalSuspend,
        enableListrates      : legalEntityUnit.enableListrates,
        enableMarketOrder    : legalEntityUnit.enableMarketOrder,
        enableMarketOrderFileReader: legalEntityUnit.enableMarketOrderFileReader,
        useFallbackTenorForMarketOrderFile: legalEntityUnit.useFallbackTenorForMarketOrderFile,
        enableIndicativeRates: legalEntityUnit.enableIndicativeRates,
        fxTradeReportActive : legalEntityUnit.fxTradeReportActive,
        netDispatchTrades: legalEntityUnit.netDispatchTrades,
        sendToAccounting: legalEntityUnit.sendToAccounting,
        marginGroup:   legalEntityUnit.marginGroupAssigned ? setNestedIdProperty(legalEntityUnit, 'marginGroupId') : null,
        agreements: agreements,
    }
}


function agreementTransformer(agreement, params) {
    setAuditUserFields(agreement);
    // if (params && params.createMomentObjectsForAudit) setItemDateTimes(agreement);
    // if (params && params.addTenorBooleansToAgreement && isArray(agreement.tenors)) agreement.tenors.forEach(tenor => agreement[tenor] = true);
    setParentId(agreement, 'legalEntityUnitId', params ? params.parentId : null);

    transformExecutionRights(agreement);

    agreement.openTime  = agreement.openTime  ? stripSecondsFromRunTime(agreement.openTime  ) : "";
    agreement.closeTime = agreement.closeTime ? stripSecondsFromRunTime(agreement.closeTime ) : "";
    agreement.currencyPairId  = getNestedIdProperty(agreement, 'currencyPair');
    if (!agreement.comment) agreement.comment = "";
    if (!agreement.dispatchLevel) agreement.dispatchLevel = "";

    if (!agreement.name) agreement.name = agreement.type;

    if (!agreement.status) {
        agreement.status        = 'Active';
    }

    if (!agreement.type)              agreement.type = "";
    else if (agreement.type === 'ListRateAgreement') {
//        agreement.tenorId           = getNestedIdProperty(agreement, 'tenor');
        agreement.listRatePeriodId  = getNestedIdProperty(agreement, 'listRatePeriod');
        agreement.dispatchTradePeriodId  = getNestedIdProperty(agreement, 'dispatchTradePeriod');
        if (!agreement.dispatchTradePeriodId) agreement.dispatchTradePeriodId = ""; // Null does not render well in the input field...

        if (agreement.buySellType === null)  agreement.buySellType     = "";
        if (!agreement.meansOfPayment )      agreement.meansOfPayment  = "HEDGE";
        agreement.todayCutOffTime = agreement.todayCutOffTime ? stripSecondsFromRunTime(agreement.todayCutOffTime ) : "";
        if (agreement.listRates) {
            agreement.listRates = ormItemArrayTransformer('ListRate', agreement.listRates, {...params, parentId: agreement.id});
        }

        agreement.overridePriceTolerances = agreement.priceTolerance != null
            && (agreement.priceTolerance.startBusinessHours  != null
                || agreement.priceTolerance.endBusinessHours != null
                || agreement.priceTolerance.priceToleranceInsideBusinessHours != null
                || agreement.priceTolerance.priceToleranceOutsideBusinessHours != null);

        agreement.startBusinessHours = agreement.priceTolerance?.startBusinessHours ? agreement.priceTolerance.startBusinessHours : null;
        agreement.endBusinessHours = agreement.priceTolerance?.endBusinessHours ? agreement.priceTolerance.endBusinessHours : null;
        agreement.priceToleranceInsideBusinessHours = agreement.priceTolerance?.priceToleranceInsideBusinessHours ? agreement.priceTolerance.priceToleranceInsideBusinessHours : null;
        agreement.priceToleranceOutsideBusinessHours = agreement.priceTolerance?.priceToleranceOutsideBusinessHours ? agreement.priceTolerance.priceToleranceOutsideBusinessHours : null;
    }
    else if (['MarketOrderAgreement', 'MarketOrderAllAgreement'].indexOf(agreement.type) !== -1) {
        let tenorsMap = {};
        if (agreement.tenors?.length) {
            agreement.tenors.forEach(tenor => tenorsMap[tenor] = true);
        }
        agreement.tenorsMap  = tenorsMap;
    }
    else if (['ReferenceRateAgreement', 'PriceFeedAgreement'].indexOf(agreement.type) !== -1) {
        agreement.priceFeedId   = getNestedIdProperty(agreement, 'priceFeed'  );
        agreement.marginGroupId = getNestedIdProperty(agreement, 'marginGroup');
        agreement.name          = (agreement.targetTopic ? (agreement.targetTopic + '-') : '') + agreement.priceFeed.name + '-' + agreement.marginGroup.name;
    }
    else if (agreement.type === 'TradeReportAgreement') {
        agreement.fxTradeReportDeliveries = agreement.fxTradeReportDeliveries?.length ? ormItemArrayTransformer('FxTradeReportDelivery', agreement.fxTradeReportDeliveries, {...params, parentId: agreement.id}) : null;
        agreement.name = agreement.type + '-' + agreement.fxShortName;
    }
    return agreement;
}
function createAgreementBody(agreement, decDenLangState, method, getState) {
    let typeData = {};
    if (!agreement.type) return {};
    else if (agreement.type === 'ListRateAgreement') {
//        typeData.tenor          = setNestedIdProperty(agreement, 'tenorId');
        typeData.tenor          = agreement.tenor;
        typeData.listRatePeriod = setNestedIdProperty(agreement, 'listRatePeriodId');
        typeData.dispatchTradePeriod = setNestedIdProperty(agreement, 'dispatchTradePeriodId');
        typeData.dispatchLevel  = agreement.dispatchLevel ? stringNumberToFloat(agreement.dispatchLevel, decDenLangState) : null;
        typeData.buySellType    = agreement.buySellType     ? agreement.buySellType     : 'BuySell';
        typeData.meansOfPayment = agreement.meansOfPayment  ? agreement.meansOfPayment  : 'HEDGE';
        typeData.gracePeriod    = agreement.gracePeriod     ? agreement.gracePeriod     : null;
        typeData.todayCutOffTime= agreement.todayCutOffTime ? addSecondsToRunTime(agreement.todayCutOffTime)  : null;

        typeData.priceTolerance = {};

        if (agreement.overridePriceTolerances === false) {
            typeData.priceTolerance.startBusinessHours = null;
            typeData.priceTolerance.endBusinessHours = null;
            typeData.priceTolerance.priceToleranceInsideBusinessHours = null;
            typeData.priceTolerance.priceToleranceOutsideBusinessHours = null;
        } else {
            typeData.priceTolerance.startBusinessHours = agreement.startBusinessHours;
            typeData.priceTolerance.endBusinessHours = agreement.endBusinessHours;
            typeData.priceTolerance.priceToleranceInsideBusinessHours = agreement.priceToleranceInsideBusinessHours;
            typeData.priceTolerance.priceToleranceOutsideBusinessHours = agreement.priceToleranceOutsideBusinessHours;
        }
    }
    else if (['MarketOrderAgreement', 'MarketOrderAllAgreement'].indexOf(agreement.type) !== -1) {
        typeData.tenors  = [];
        Object.keys(agreement.tenorsMap).forEach(key => {
            if (agreement.tenorsMap[key] === true) typeData.tenors.push(key);
        });
    }
    else if (['ReferenceRateAgreement', 'PriceFeedAgreement'].indexOf(agreement.type) !== -1) {
        typeData.priceFeed   = setNestedIdProperty(agreement, 'priceFeedId'  );
        typeData.marginGroup = setNestedIdProperty(agreement, 'marginGroupId');
        if (agreement.type === 'PriceFeedAgreement') typeData.targetTopic = agreement.targetTopic;
    }
    else if (agreement.type === 'TradeReportAgreement') {
        typeData.fxShortName = agreement.fxShortName;
        typeData.clientRefId = agreement.clientRefId;
    }

    if (['MarketOrderAgreement', 'ListRateAgreement'].indexOf(agreement.type) !== -1) {
        typeData.currencyPair = setNestedIdProperty(agreement, 'currencyPairId')
    }

    return {
        ...setParentIdPropertyInCreateBody(agreement, 'legalEntityUnitId', method),
        id           : agreement.id,
        type         : agreement.type,
        openTime     : agreement.openTime  ? addSecondsToRunTime(agreement.openTime)  : null,
        closeTime    : agreement.closeTime ? addSecondsToRunTime(agreement.closeTime) : null,
        comment      : agreement.comment,
        ...typeData,
    }
}
function listRateTransformer(item, params) {
    setParentIdIfDefined(item, 'agreementId', params ? params.parentId : null);
    if (item.dispatchTrades) {
        item.dispatchTrades = ormItemArrayTransformer('DispatchTrade', item.dispatchTrades, {...params, parentId: item.id});
    }
    return item;
}
function dispatchTradeTransformer(item, params) {
    setParentIdIfDefined(item, 'listRateId', params ? params.parentId : null);
    if (item.listRateOrders) {
        item.listRateOrders = ormItemArrayTransformer('ListRateOrder', item.listRateOrders, {...params, parentId: item.id});
    }
    return item;
}
function listRateOrderTransformer(item, params) {
    setParentIdIfDefined(item, 'dispatchTradeId', params ? params.parentId : null);
    if (item.listRateId === 0) {
        item.listRateId = "";
    }
    if (item.listRateOrderAllocations) {
        item.listRateOrderAllocations = ormItemArrayTransformer('ListRateOrderAllocation', item.listRateOrderAllocations, {...params, parentId: item.id});
    }
/*
    item.listRateOrderAllocations = [
        {
            id                  : 345,
            listRateOrderId     : 111,
            allocationAmount    : 300,
            customerRst         : "rst",
            customerBankId      : "bankId",
            customerNumber      : "number",
            clientAllocationReference: "fish",
        },
        {
            id                  : 346,
            listRateOrderId     : 111,
            allocationAmount    : 400,
            customerRst         : "rst",
            customerBankId      : "bankId",
            customerNumber      : "number",
            clientAllocationReference: "fish",
        }
    ]
*/
    return item;
}
function listRateOrderAllocationTransformer(item, params) {
    setParentIdIfDefined(item, 'listRateOrderId', params ? params.parentId : null);
    return item;
}

function marketOrderTransformer(item, removeExecutionRights) {
    return item;
}
function listRatePeriodTransformer(item) {
    item.id     = item.id   ? item.id   : null;
    item.period = item.period ? item.period : "";
    return item;
}
function dispatchPeriodTransformer(item) {
    item.id     = item.id   ? item.id   : null;
    item.period = item.period ? item.period : "";
    return item;
}
function priceFeedTransformer(item) {
    item.default = item.isDefault;
    return item;
}
function listRateOrderResultTransformer(item) {
    return {
        listRateOrder   : item.listRateOrder    ? listRateOrderTransformer(   item.listRateOrder)   : undefined,
        listRate        : item.listRate         ? listRateTransformer(        item.listRate)        : undefined,
        dispatchTrade   : item.dispatchTrade    ? dispatchTradeTransformer(   item.dispatchTrade)   : undefined,
        legalEntityUnit : item.legalEntityUnit  ? legalEntityUnitTransformer( item.legalEntityUnit) : undefined,
    };
}

function fxTradeReportErpConfigTransformer(item) {
    item.erpItegrationId = getNestedIdProperty2(item, 'erpIntegration');
    item.erpIntegration = commonIncomingTransformersMap.ErpIntegration(item.erpIntegration);
    item.erpSystemName = item.erpIntegration?.erpSystem?.name
    return item;
}

function fxTradeReportErpConfigOutgoingTransformer(item) {
    return {
        ...item,
        erpIntegration: undefined,
        erpIntegrationId: undefined,
    };
}

