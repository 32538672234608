import {getProcessStatusPackageConfig} from "gui-common/processStatus/processStatusFunctions";

export function StatusPackageRenderer(props) {
    if (!props.statusPackage) {
        return null;
    }
    const config = getProcessStatusPackageConfig(props.statusPackage)
    if (!config || !config.renderPackage) {
        return null;
    }
    return config.renderPackage(props.statusPackage);
}
