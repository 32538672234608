import React, {useCallback, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {
    prepareListRateOrderAllocationsColumns
} from "features/listRateOrderAllocation/listRateOrderAllocationFunctions";
import {
    listRateOrderAllocationsSelector
} from "features/listRateOrderAllocation/listRateOrderAllocationSelectors";

export const listRateOrderAllocationsHiddenState = {
    id                  : true,
    listRateOrderId     : true,
    allocationAmount    : false,
    customerRst         : false,
    customerBankId      : false,
    customerNumber      : false,
    clientAllocationReference: false,
};

ListRateOrderAllocationsList.detailIcon = 'dynamic_feed';
ListRateOrderAllocationsList.detailIconToolTipKey = 'listRateOrderAllocationsList.detailIconToolTipKey';
export function ListRateOrderAllocationsList (props) {
    const translate    = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((params) => {}, []),
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translate('listRateOrderAllocationsList.noAllocationsToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? true : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                rowDataSelector         : listRateOrderAllocationsSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'ListRateOrderAllocation',
            };
        },
        [translate, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareListRateOrderAllocationsColumns(translate, listRateOrderAllocationsHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={props.gridId ? props.gridId : "listRateOrderAllocationsGrid-" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                rowData={props.rowData}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
