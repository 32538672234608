import PropTypes from "prop-types";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {xpFormChangeField, xpFormSetFieldTouched} from "gui-common/xpForm/core/xpFormReducer";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function XpFormFieldController (props) {
    const dispatch  = useDispatch();
    const translate = useXpTranslateFunction();

    const useDefaultValue = (props?.formContext?.formUseState === XP_FORM_NEW) ? props.defaultValue : "";
    const [renderValue,   setRenderValue] = useState(props.valueFormatter ? props.valueFormatter(props.fieldState ? props.fieldState.fieldValue : useDefaultValue) : (props.fieldState ? props.fieldState.fieldValue : useDefaultValue));
    const renderValueRef = useRef(renderValue);
    renderValueRef.current = renderValue;

/*
    const [inputBuffer, setInputBuffer] = useState("");
    const inputBufferRef = useRef(inputBuffer);
    inputBufferRef.current = inputBuffer;
*/

/*
    const [inputBufferTimer, setInputBufferTimer] = useState(undefined);
    const inputBufferTimerRef = useRef(inputBufferTimer);
    inputBufferTimerRef.current = inputBufferTimer;
*/

    const fieldStateRef = useRef(props.fieldState);
    fieldStateRef.current = props.fieldState;

    const noFormValidation = useAppEnvProperty('noFormValidation');


    const templateString = useMemo(
        () => {
            if (props.template  ) return props.template;
            if (props.noTemplate) return;
            const formTemplate = props.formTemplate ? props.formTemplate : props.formContext.formTemplate;
            let returnText = translate((props.fieldTemplate ? props.fieldTemplate : formTemplate + "." + props.field) + ".template");
            if (returnText.includes("Missing translation")) returnText = translate("general.template");
            return returnText;
        },
        [props.template]
    );

    const thereAreFieldErrors = useMemo(
        () => {
            if (!props.fieldState) return false;
            if (!props.fieldState.errors) return false;
            if (!props.fieldState.isTouched) return false;
            if (noFormValidation) return false;
            return (Object.keys(props.fieldState.errors).length > 0)
        },
        [props.fieldState]
    );
    useEffect(() => {
        return () => {
            document.removeEventListener("keydown", keydownFunction, {capture: true});
        };
    }, []);
    useEffect(
        () => {
            if (props.forceDisabledUncheck) {
                setRenderValue(false);
                return;
            }
            if (!props.fieldState) return;
            if (props.fieldState.fieldValue !== renderValue) setRenderValue(props.valueFormatter ? props.valueFormatter(props.fieldState?.fieldValue) : props.fieldState?.fieldValue);
        },
        [props.fieldState, props.forceDisabledUncheck],
    );

    const className = useMemo(
        () => {
            let outString = "";
            if (props.alignRight) outString += "xpMuiRightAlignInput ";
            if (props.inTable)    outString += "xpFormInputTableCell ";
            return outString;
        },
        []
    );


    function submitValue(value, params) {
        const formattedValue = props.valueFormatter ? props.valueFormatter(value) : value;

        dispatch(props.onChangeThunk ?
            props.onChangeThunk(props.fieldModel, formattedValue, fieldStateRef.current?.fieldValue, newValue => dispatch(xpFormChangeField(props.fieldModel, newValue)), props)
            : xpFormChangeField(props.fieldModel, formattedValue)
        )
        if (props.onChangeCallback) props.onChangeCallback(value, formattedValue, params);
    }

    function onChange(event, params) {
        if (props.readOnly) return;
        let value = props.getValueFromEvent(event);

        if (props.isDatePickerField) value = event;

        const parsedValue = props.parser ? props.parser(value) : value;

        setRenderValue(parsedValue);

        if (parsedValue === props.fieldState?.fieldValue) return;

        if (props.updateOn !== 'change') return;
        submitValue(parsedValue, params);
        if (props.setTouchedOnChange && !props.fieldState?.isTouched) {
            dispatch(xpFormSetFieldTouched(props.fieldModel))
        }
    }
    const keydownFunction = useMemo(
        () => {
            return (event) => {
                if(event.keyCode === 13) {
                    onBlur(undefined, true);
                }
/*
                if (props.isSelectField) {
                    if (
                        (event.keyCode >= 65 && event.keyCode <= 90 ) ||
                        (event.keyCode >= 97 && event.keyCode <= 122)
                    ) {
                        setInputBuffer(inputBufferRef.current + String.fromCharCode(event.keyCode));
                        if (inputBufferTimerRef.current) clearTimeout(inputBufferTimerRef.current);
                        setInputBufferTimer(setTimeout(() => {
                            console.log("Input buffer", inputBufferRef.current);
                            setInputBuffer("");
                        }, 1000));
                    }
                    if (event.keyCode === 32) {
                        setInputBuffer("");
                    }
                }
*/
            }
        },[],
    );
    function onBlur(event, onEnter) {
        if (!onEnter) document.removeEventListener("keydown", keydownFunction, {capture: true});
        if (fieldStateRef.current && !fieldStateRef.current.isTouched) {
            dispatch(xpFormSetFieldTouched(props.fieldModel))
        }
        if (props.updateOn !== 'blur') return;

        if (renderValueRef.current === fieldStateRef.current?.fieldValue) return;
        submitValue(renderValueRef.current);
    }
    function onFocus() {
        if (!props.isSelectField) document.addEventListener("keydown", keydownFunction, {capture: true});
    }

    if (!props.CompToRender) return null;
    if (!props.fieldState && !props.readOnly) return null;

    return (
        <props.CompToRender
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            readOnly={props.readOnly}
            helperText={props.helperText}
            variant="standard"
            size="small"
            value={(props.value !== undefined) ? props.value : (renderValue !== undefined) ? renderValue : ""}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            error={thereAreFieldErrors}
            placeholder = {templateString}
            {...(props.isDatePickerField ? {placeholderText: templateString} : {})} /*Needed for date picker*/
            {...props.controlProps}
            {...(props.dynamicControlProps ? props.dynamicControlProps({renderValue: renderValue}) : {})}
            {...props.dependentProps}
            label={props.label}
            field={props.field}
            formcontext={props.formContext}
            inputProps = {{className: className, name: props.fieldModel, tabIndex: props.tabIndex}}
            // showTemplateInView = {props.showTemplateInView}
        />
    )
}

XpFormFieldController.propTypes = {
    CompToRender : PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    errors       : PropTypes.object,
};
export default XpFormFieldController
