import React, {useCallback, useEffect, useMemo, useState} from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpDateTime} from "gui-common/components/XpDateTime";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {useOrmModelItem, userSelector} from "gui-common/orm/ormSelectors";
import XpSplitPane from "gui-common/components/XpSplitPane";
import "./fxTradeReportEventRenderer.css"
import {fxExecutionLegRoles, fxTradeReportEventTypes} from "gui-common/fxTradeReport/fxTradeReportConstants";
import XpFormattedAmount from "gui-common/numberFormat/XpFormattedAmount";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {useDispatch, useSelector} from "react-redux";
import {postCancelEventToApi} from "features/fxTradeReport/fxTradeReportApi";
import PropTypes from "prop-types";
import {useResizeObserver} from "gui-common/functions/hooks";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {refixSystemRights} from "appConfig/appUserRights";

FxTradeReportEventRenderer.detailIcon = 'confirmation_number';
FxTradeReportEventRenderer.detailIconToolTipKey = 'fxTradeReportEventsList.tradeReportIconToolTipKey';
function FxTradeReportEventRenderer (props) {
    const eventModel = useOrmModelItem('FxTradeReportEvent', props.fxTradeReportEventId);
    const [containerHeight, setContainerHeight] = useState(0);

    const observerCallback = useCallback(box => {
        // console.log("Container observer: ", box);
        setContainerHeight(box.height);
    }, [])
    const containerRef = useResizeObserver(observerCallback);

    useEffect(
        () => {
            if (props.setHeightCallback) {
                props.setHeightCallback(containerHeight)
            }
        },
        [containerHeight],
    );

    if (!eventModel) {
        return null;
    }
    const event = eventModel.ref;

    return (
        <div ref={containerRef}>
            <div className='xpAdminFormEntityCard fxTradeRenderer'>
                <XpSplitPane defaultWidthPercent={35} minSizePrimary={300} minSizeSecondary={400}>
                    <div>
                        <EventDetails event={event}/>
                    </div>
                    <div style={{overflow: 'auto'}}>
                        <OrderRenderer orderId={event.fxOrderReportId} event={event} instanceId={props.instanceId}/>
                        <RelatedOrderRenderer orderId={event.fxOrderReportId} event={event} instanceId={props.instanceId}/>
                    </div>
                </XpSplitPane>
            </div>
        </div>
    )
}
FxTradeReportEventRenderer.propTypes = {
    fxTradeReportEventId: PropTypes.number,
};
export default FxTradeReportEventRenderer


const divider= 65;

function SubHeader(props) {
    return (
        <h5 style={{padding: '0 0 5px 0', margin: 'unset'}}><XpTranslated trKey={props.trKey} trParams={props.trParams}/></h5>
    )
}
function EventDetails(props) {
    return (
        <div>
            <SubHeader trKey={'fxTradeReportEventRenderer.eventDetails'} />
            <XpFormStaticField inLineLayout inLineDivider={divider} labelTrKey='fxTradeReportEventsList.headers.eventType.label'          value={<XpTranslated trKey={'fxTradeReportEventsList.headers.eventType.values.' + props.event.eventType}/>}/>
            <XpFormStaticField inLineLayout inLineDivider={divider} labelTrKey='fxTradeReportEventsList.headers.externalId.label'         value={props.event.externalId}/>
            <XpFormStaticField inLineLayout inLineDivider={divider} labelTrKey='fxTradeReportEventsList.headers.eventDateTime.label'      value={<XpDateTime isoString={props.event.eventDateTime} format='lll'/>}/>
            <XpFormStaticField inLineLayout inLineDivider={divider} labelTrKey='fxTradeReportEventsList.headers.eventOrigin.label'        value={props.event.eventOrigin}/>
            <XpFormStaticField inLineLayout inLineDivider={divider} labelTrKey='fxTradeReportEventsList.headers.eventSource.label'        value={props.event.eventSource}/>
            <XpFormStaticField inLineLayout inLineDivider={divider} labelTrKey='fxTradeReportEventsList.headers.eventMessage.label'       value={props.event.eventMessage}/>
            <XpFormStaticField inLineLayout inLineDivider={divider} labelTrKey='fxTradeReportEventsList.headers.id.label'                 value={props.event.id}/>
            <EventInfoRenderer eventInfo={props.event.eventInfo} eventType={props.event.eventType} instanceId={props.instanceId}/>
        </div>
    )
}

function EventInfoRenderer(props) {
    const eventInfoContent = useMemo(
        () => {
            if (!props.eventInfo) {
                return null;
            }
            if (props.eventType === fxTradeReportEventTypes.EARLY_REDEMPTION) {
                return (
                    <div>
                        <RenderValue labelTrKey='fxOrderReportList.headers.backOffice.label'   value={props.eventInfo.backOffice}/>
                        <RenderValue labelTrKey='fxOrderReportList.headers.backOfficeId.label' value={props.eventInfo.backOfficeId}/>
                        <RenderValue labelTrKey='fxExecutionReportList.headers.remainingCounterAmount.label'    value={<XpFormattedAmount amount={props.eventInfo.remainingAmount} alignRight currencyPrefix={props.eventInfo.remainingAmountCurrency}/>}/>
                    </div>
                )
            }
            if (props.eventType === fxTradeReportEventTypes.CANCEL) {
                return (
                    <div>
                        <RenderValue labelTrKey='fxOrderReportList.headers.note.label'   value={props.eventInfo.note}/>
                    </div>
                )
            }
            return null;
        },
        [props]
    );
    if (!props.eventInfo) {
        return null;
    }
    if (!eventInfoContent) {
        return null;
    }
    return (
        <XpAccordion instanceId={'eventInfo-' + props.instanceId} headerKey={'fxTradeReportEventsList.headers.eventInfo.' + props.eventType} headerClassName="textGenerationsHeader" defaultShowState={false}>
            {eventInfoContent}
        </XpAccordion>
    )

}


function sortOnId(a, b) {
    return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
}
function sortOnLegRole(a, b) {
    if (a.legRole === fxExecutionLegRoles.NEAR) {
        return -1;
    }
    if (a.legRole === fxExecutionLegRoles.BUYOUT) {
        return 1;
    }
    return 0;
}

function RenderValue(props) {
    return (<XpFormStaticField inLineLayout compact inLineDivider={divider} labelTrKey={props.labelTrKey} value={props.value}/>)
}
function RenderUTI(props) {
    return (
        <div className={"xpFormFieldLine xpFormFieldInline"} style={{margin: 'unset', alignItems: 'baseline'}}>
            <div style={{width: '30px'}}>
                <label className='xpFormLabel'>UTI:</label>
            </div>
            <div
                className={"xpFormValueTextInner"}
                style={{width: 'calc(100% - 30px', fontSize: '12px', overflowWrap: 'break-word'}}
            >
                {props.value}
            </div>
        </div>

/*
        <div style={{fontSize: '12px'}}>
            <XpFormStaticField inLineLayout compact fixedLabelWidth={30} label={'UTI: '} value={props.value}/>
        </div>
*/
    )
}

function OrderRenderer(props) {
    const dispatch = useDispatch();
    const orderModel = useOrmModelItem('FxOrderReport', props.orderId);
    const order = orderModel?.ref;
    const mandatoryComments = useAppEnvProperty('mandatoryTradeReportActionComment');
    const user = useSelector(userSelector);

    const renderLegs = useMemo(
        () => {
            const legs = orderModel?.fxExecutionLegs.toModelArray().map(legModel => ({
                legRole: legModel.ref.legRole,
                legId: legModel.ref.id,
                ...(legModel.fxExecutionReportId ? legModel.fxExecutionReportId.ref : {})
            }))
            if (!legs?.length) {
                return [];
            }
            const retArray = legs.filter(leg => leg.legRole !== fxExecutionLegRoles.BUYOUT).sort(sortOnLegRole);
            return retArray.concat(legs.filter(leg => leg.legRole === fxExecutionLegRoles.BUYOUT).sort(sortOnId).reverse());
        },
        [orderModel]
    );
    const userCanSendCancellation = userHasSystemRight(user, refixSystemRights.TradeReport.SendManualCancelEvent);


    if (!order) {
        return null;
    }
    return (
        <div className={'fxTradeReportOrderDetail'}>
            <div className={'fxTradeReportOrderItemDetail'}>
                <SubHeader trKey={'fxTradeReportEventRenderer.orderDetails'} />
                <RenderValue labelTrKey='fxOrderReportList.headers.account.label'      value={order.account}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.productType.label'  value={<XpTranslated trKey={'fxOrderReportList.headers.productType.values.' + order.productType}/>}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.symbol.label'       value={order.symbol}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.backOfficeId.label' value={order.backOfficeId}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.backOffice.label'   value={order.backOffice}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.marketId.label'       value={order.marketId}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.market.label'       value={order.market}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.reference.label'    value={order.reference}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.id.label'           value={order.id}/>
                <RenderValue labelTrKey='fxExecutionReportList.headers.isin.label'     value={order.isin}/>
                <RenderUTI value={order.UTI}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.version.label'      value={order.version}/>
                <RenderValue labelTrKey='fxOrderReportList.headers.orderChannel.label' value={order.orderChannel}/>
                {userCanSendCancellation &&
                <WaitingXpoolButton
                    disabled={!props.event.eventType || props.event.eventType === fxTradeReportEventTypes.CANCEL}
                    modalType={CONFIRMATION_WITH_COMMENT_DIALOG}
                    modalKey={'fxTradeReportEventRenderer.sendCancel.modal'}
                    labelKey={'fxTradeReportEventRenderer.sendCancel.label'}
                    modalProps={{commentIsRequired: mandatoryComments?.createEvent?.cancelEvent}}
                    modalParams={{orderId: order.backOfficeId, account: order.account, productType: order.productType}}
                    onClickCallback = {(result) => dispatch(postCancelEventToApi(order.id, result.comment))}
                />}
            </div>
            {renderLegs.map(leg => (
                <div className={'fxTradeReportOrderItemDetail'} key={leg.legId}>
                    <SubHeader trKey={'fxTradeReportEventRenderer.legDetails'} trParams={{legRole: leg.legRole}} />
                    <RenderValue labelTrKey='fxExecutionReportList.headers.tradeDate.label'        value={leg.tradeDate}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.settlementDate.label'   value={leg.settlementDate}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.side.label'             value={<XpTranslated trKey={'fxExecutionReportList.headers.side.values.' + leg.side}/>}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.amount.label'           value={<XpFormattedAmount amount={leg.amount} alignRight currencyPrefix={leg.currency}/>}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.counterAmount.label'             value={<XpFormattedAmount amount={leg.counterAmount} alignRight currencyPrefix={leg.counterCurrency}/>}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.fxRate.label'           value={leg.fxRate}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.spotRate.label'         value={leg.spotRate}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.fwdPoints.label'        value={leg.fwdPoints}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.marginAmount.label'     value={leg.marginAmount}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.executedDateTime.label' value={<XpDateTime isoString={leg.executedDateTime} format='lll'/>}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.id.label'               value={leg.id}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.version.label'          value={leg.version}/>
                    <RenderValue labelTrKey='fxExecutionReportList.headers.isin.label'             value={leg.isin}/>
                    <RenderUTI value={order.UTI}/>
                </div>
            ))}
        </div>
    )
}

function RelatedOrderRenderer(props) {
    if (props.event.eventType !== fxTradeReportEventTypes.EARLY_REDEMPTION) {
        return null;
    }
    if (!props.event.relatedFxOrderReportId) {
        return (
            <SubHeader trKey={'fxTradeReportEventRenderer.relatedOrderNotKnown'} />
        )
    }
    return (
        <XpAccordion instanceId={'relatedOrder-' + props.instanceId} headerKey={'fxTradeReportEventRenderer.relatedOrder'} headerClassName="textGenerationsHeader" defaultShowState={false}>
            <OrderRenderer orderId={props.event.relatedFxOrderReportId} event={props.event} instanceId={props.instanceId}/>
        </XpAccordion>
    )
}
