import React, {useCallback, useMemo} from 'react';
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {useSelectorInstance, useSelectorRef} from "gui-common/functions/hooks";
import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {
    fxOrderReportProductTypes,
} from "gui-common/fxTradeReport/fxTradeReportConstants";
import {useLoadParentAtFirstRender} from "gui-common/orm/ormHooks";
import FxTradeReportEventRenderer from "gui-common/fxTradeReport/FxTradeReportEventRenderer";
import {instanceRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {refixDataRights} from "appConfig/appUserRights";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {
    requestAddEventInDeliveryToPendingToApi
} from "features/fxTradeReport/fxTradeReportApi";
import {fxTradeReportDeliveryStates} from "features/fxTradeReport/fxTradeReportConstants";
import {useDispatch} from "react-redux";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {FxTradeReportDeliveriesList} from "features/fxTradeReport/FxTradeReportDeliveriesList";
import {
    getFxTradeReportDeliveriesForEventSelector,
    getFxTradeReportDeliveriesForLeuSelector
} from "features/legalEntityUnit/legalEntityUnitSelectors";
import {getOrmItemLoadingSelector} from "gui-common/orm/ormLoadingSelectors";
import ComponentLoading from "gui-common/components/ComponentLoading";


FxTradeReportEventsList.detailIcon = 'confirmation_number';
FxTradeReportEventsList.detailIconToolTipKey = 'fxTradeReportEventsList.detailIconToolTipKey';
export function FxTradeReportEventsList (props) {
    const translateRef = useSelectorRef(selectTranslateFunction)
    const dispatch = useDispatch();
    const mandatoryComments = useAppEnvProperty('mandatoryTradeReportActionComment');
    const parentLoading = useSelectorInstance(getOrmItemLoadingSelector, {ormModel: 'FxTradeReportDelivery', itemId: props.rowDataSelectorProps.parentId});


    useLoadParentAtFirstRender(props);

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((params) => {}, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = [];

                const event = params.node.data;

                const userCanAddToPending = instanceRightAllowed(event.agreement, refixDataRights.TradeReportAgreement.AddEventsToPending);
                if (userCanAddToPending) menuItems.push({
                    name: translateRef.current("fxTradeReportEventsList.gridContextMenuItems.addToPending.menuItem"),
                    action: () => setTimeout(() => {
                        dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG, {modalKey: 'fxTradeReportEventsList.gridContextMenuItems.addToPending',
                            modalParams: {eventId: event.externalId, shortName: event.fxShortName, appId: event.appId}, commentIsRequired: mandatoryComments?.addEventsToPending?.single}))
                            .then(({status, comment}) => {
                                if (status === MODAL_TYPE_CONFIRM) {
                                    dispatch(requestAddEventInDeliveryToPendingToApi(event.deliveryId, event.id, comment));
                                }
                            })
                    }, 50),
                    disabled: event.delivery.status === fxTradeReportDeliveryStates.PENDING,
                });

                return menuItems;
            }, [translateRef.current]),
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translateRef.current('fxTradeReportEventsList.noItemsToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? true : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : props.getRowDataSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'FxTradeReportEvent',
                // detailCellRenderer      : 'xpGridSingleDetailCellRenderer',
                xpDetailRendererProps   : {
                    // The following pattern for single component.
                    // componentToRender: FxTradeReportEventRenderer,
                    // propsToComponent: {
                    //     getRowDataSelector: getFxOrderReportsForTradeReportEventSelector,
                    //     getRowDataSelectorProps: parentItemData => ({eventId: parentItemData.id}),
                    // },
                    availableComponents: [
                        {
                            detailType: 'FxTradeReportEventDetail',
                            componentToRender: FxTradeReportEventRenderer,
                            propsToComponent: {
                                getPropsToComponent: parentItemData => ({fxTradeReportEventId: parentItemData.id})
                                // getRowDataSelector: getFxOrderReportsForTradeReportEventSelector,
                                // getRowDataSelectorProps: parentItemData => ({eventId: parentItemData.id}),
                            }
                        },
                        {
                            detailType: 'FxTradeReportDeliveries',
                            componentToRender: FxTradeReportDeliveriesList,
                            propsToComponent: {
                                getRowDataSelector: getFxTradeReportDeliveriesForEventSelector,
                                getRowDataSelectorProps: parentItemData => ({eventId: parentItemData.id}),
                            },
                        },

                    ],
                    parentInstanceId: props.instanceId,
                },
            };
        },
        [translateRef.current, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            const columnDefs = [];
            xpColCreate(translateRef.current, columnDefs, false, 'externalId'       , 100 , 50, 'fxTradeReportEventsList.headers.externalId.label', {cellRenderer: 'agGroupCellRenderer', lockVisible: true, lockPosition: true});
            xpColCreate(translateRef.current, columnDefs, true , 'id'               , 50  , 50, 'fxTradeReportEventsList.headers.id.label', {cellStyle: {textAlign: "right"}});
            xpColCreate(translateRef.current, columnDefs, false, 'eventTypeT'       , 100 , 50, 'fxTradeReportEventsList.headers.eventType.label', {
                cellRenderer: 'xpTranslatedRenderer',
                cellRendererParams: {
                    filterTrKeys: Object.keys(fxOrderReportProductTypes).map(item => 'fxTradeReportEventsList.eventType.values.' + item)
                },
                filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
            });
            xpColCreate(translateRef.current, columnDefs, false, 'productTypeT'       , 100 , 50, 'fxOrderReportList.headers.productType.label', {
                cellRenderer: 'xpTranslatedRenderer',
                cellRendererParams: {
                    filterTrKeys: Object.keys(fxOrderReportProductTypes).map(item => 'fxOrderReportList.headers.productType.values.' + item)
                },
                filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
            });
            xpColCreate(translateRef.current, columnDefs, false, 'orderId'              , 50  , 50, 'fxOrderReportList.headers.backOfficeId.label');
            xpColCreate(translateRef.current, columnDefs, false, 'eventDateTime'    , 100 , 50, 'fxTradeReportEventsList.headers.eventDateTime.label', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
            xpColCreate(translateRef.current, columnDefs, false , 'eventOrigin'               , 50  , 50, 'fxTradeReportEventsList.headers.eventOrigin.label');
            xpColCreate(translateRef.current, columnDefs, false , 'eventSource'               , 50  , 50, 'fxTradeReportEventsList.headers.eventSource.label');
            xpColCreate(translateRef.current, columnDefs, true , 'createdDateTime'  , 100 , 50, 'fxTradeReportEventsList.headers.createdDateTime.label', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
            return columnDefs;
        },
        [translateRef.current]
    );

    if (parentLoading) {
        return (<div style={{textAlign:'center'}}><ComponentLoading loadingTrKey={'fxTradeReportEventsList.loadingEvents'}/></div>)
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={props.gridId ? props.gridId : "fxTradeReportEventsGrid-" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                rowData={props.rowData}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
