import {createSelector as ormCreateSelector} from "redux-orm";
import {orm} from "gui-common/orm/orm";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {createTranslatedFieldData} from "gui-common/xpGrid/xpGridFunctions";
import {stateRequestedOnIdAndProperty} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {enrichFxTradeReportEventListItem} from "gui-common/fxTradeReport/fxTradeReportFunctions";
import {fxTradeReportDeliveryStates} from "features/fxTradeReport/fxTradeReportConstants";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";

const deliveryRequestStateSelector = getRequestingStateOnModelSelector();

export function enrichFxTradeReportDeliveryListItem(deliveryModel, requestState, translate) {
    const delivery = deliveryModel.ref;
    delivery.nbrOfTradeReportEvents = deliveryModel.fxTradeReportEvents?.length;

    // delivery.appId = deliveryModel.tradeReportAgreementId ? deliveryModel.tradeReportAgreementId.legalEntityUnitId.ref.appId : "";

    delivery.agreement = deliveryModel.tradeReportAgreementId?.ref;

    createTranslatedFieldData(delivery, 'type', translate, ("fxTradeReportDeliveriesList.type." + delivery.deliveryMethod));

    const isWaiting = stateRequestedOnIdAndProperty(requestState, delivery.id, 'status')
    createTranslatedFieldData(delivery, 'status', translate, ("fxTradeReportDeliveriesList.statusTypes." + delivery.status), undefined, isWaiting);
    createTranslatedFieldData(delivery, 'requestType', translate, ("fxTradeReportDeliveriesList.requestTypes." + delivery.requestType), undefined, isWaiting);

    return delivery;
}

export const tradeReportAgreementPendingDeliveriesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props,
    (session, agreementId) => {
        const agreementModel = getOrmModelObjectById(session, 'Agreement', agreementId);
        if (!agreementModel ||agreementModel.fxTradeReportDeliveries?.length) {
            return undefined;
        }
        const pendingDeliveries = agreementModel.fxTradeReportDeliveries.filter(delivery => delivery.status === fxTradeReportDeliveryStates.PENDING);
        if (!pendingDeliveries) {
            return undefined;
        }
        return pendingDeliveries.toRefArray();
    }
);

export function sortDelivery(b, a) {
    if (a.status === fxTradeReportDeliveryStates.PENDING && b.status !== fxTradeReportDeliveryStates.PENDING) {
        return 1;
    }
    if (b.status === fxTradeReportDeliveryStates.PENDING && a.status !== fxTradeReportDeliveryStates.PENDING) {
        return -1;
    }
    return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
}

export const getFxTradeReportDeliveriesSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    state => deliveryRequestStateSelector(state, {model: 'FxTradeReportDelivery'}),
    state => selectTranslateFunction(state),
    (session, requestState, translate) => {
        const deliveryModels = session.FxTradeReportDelivery.all().toModelArray();
        // console.log("deliveryModels", deliveryModels);
        return deliveryModels.map(item => enrichFxTradeReportDeliveryListItem(item, requestState, translate)).sort(sortDelivery);
    }
);
export const getFxTradeReportEventsForDeliverySelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.deliveryId,
    selectTranslateFunction,
    (session, deliveryId, translate) => {

        const deliveryModel = session.FxTradeReportDelivery.idExists(deliveryId) ? session.FxTradeReportDelivery.withId(deliveryId) : undefined;
        if (!deliveryModel) return [];
        const agreement = deliveryModel.tradeReportAgreementId?.ref;
        const fxShortName = deliveryModel.ref.fxShortName;
        const appId = deliveryModel.ref.appId;

        return deliveryModel.fxTradeReportEvents.orderBy('eventDateTime', 'desc').toModelArray().map(eventModel => {
            const event = enrichFxTradeReportEventListItem(eventModel, translate);
            event.agreement = agreement;
            event.fxShortName = fxShortName;
            event.appId = appId;
            event.delivery = deliveryModel.ref;
            return event;
        });
    }
);
