import React, {Component} from 'react';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpTooltip from "gui-common/components/XpTooltip";

export default class XpGridHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ascSort: 'ag-hidden',
            descSort: 'ag-hidden',
            filterActive: 'ag-hidden',
            trKey: props.trKey,
            trParams: props.trParams,
            fallBackText: props.fallBackText,
            displayName: props.displayName,
        };

        props.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        props.column.addEventListener('filterChanged', this.onFilterChanged.bind(this));
    }

    getReactContainerStyle() {
        return {
            width: '100%'
        };
    }

    componentWillUnmount() {
        this.props.column.removeEventListener('sortChanged');
        this.props.column.removeEventListener('filterChanged');
    }

    componentDidMount() {
        this.onSortChanged();
        this.onFilterChanged();
    }


    refresh(params) {
        this.setState({trKey: params.trKey, trParams: params.trParams, fallBackText: params.fallBackText, displayName: params.displayName})
        return true;
    }

    getHeaderContent() {
        if (!this.state.trKey) return (
            <span className="ag-header-cell-text">
                {this.state.displayName}
            </span>
        );
        return (
            <XpTranslated className="ag-header-cell-text" trKey={this.state.trKey} trParams={this.state.trParams} fallBackText={this.state.fallBackText} spanStyle={{whiteSpace: 'nowrap'}}/>
        );
    }

    render() {

        return (
            <XpTooltip title={this.getHeaderContent()}>
                <div className={"ag-cell-label-container" + this.getHeaderSortClass(this.props)} style={{height: '100%'}}>
                    {this.props.enableMenu &&
                    <span ref={(menuButton) => { this.menuButton = menuButton; }}
                          className="ag-header-icon ag-header-cell-menu-button xpGridMenuButton"
                          onClick={this.onMenuClicked.bind(this)}>
                        <span className="ag-icon ag-icon-menu"></span>
                    </span>}
                    <div
    //                    className={"ag-cell-label-container" + this.getHeaderSortClass(this.props)} style={{height: '100%'}}
                        className={"ag-header-cell-label " + (this.props.xpClassName ? this.props.xpClassName : "")}
                        onClick={this.onSortRequested.bind(this)} onTouchEnd={this.onSortRequested.bind(this)}
                    >
                            {this.getHeaderContent()}
                    </div>
                    <span className={`ag-header-icon ag-filter-icon ${this.state.filterActive}`}     ><span className="ag-icon ag-icon-filter" ></span></span>
                    <span className={`ag-header-icon ag-sort-descending-icon ${this.state.descSort}`}><span className="ag-icon ag-icon-desc"   ></span></span>
                    <span className={`ag-header-icon ag-sort-ascending-icon ${this.state.ascSort}`}  ><span className="ag-icon ag-icon-asc"    ></span></span>
                </div>
            </XpTooltip>
        );
    }

    onMenuClicked() {
        this.props.showColumnMenu(this.menuButton);
    }
    onSortChanged() {
        this.setState({
            ascSort : this.props.column.isSortAscending()  ? '' : 'ag-hidden',
            descSort: this.props.column.isSortDescending() ? '' : 'ag-hidden',
        });
    }
    onFilterChanged() {
        this.setState({
            filterActive: this.props.column.isFilterActive() ? '' : 'ag-hidden',
        });
    }
    getHeaderSortClass(props) {
        let returnClass = ' ag-header-cell-sorted-none';
        if (props.column.isSortAscending()) returnClass = ' ag-header-cell-sorted-asc';
        if (this.props.column.isSortDescending()) returnClass = ' ag-header-cell-sorted-desc';
        return returnClass;
    }
    onSortRequested(event) {
        if (!this.props.enableSorting) return;
        let sortOrder = 'asc';
        if (this.props.column.isSortAscending()) sortOrder = 'desc';
        if (this.props.column.isSortDescending()) sortOrder = 'none';
        this.props.setSort(sortOrder, event.shiftKey);
    }
}
