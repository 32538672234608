import {getNestedIdProperty} from "gui-common/functions/functions";
import {xpColCreate, xpColHeadCreate} from "gui-common/xpGrid/xpGridFunctions";

export function preparePriceTolerancesGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id', 100, 50, 'priceTolerancesList.id');
    xpColCreate(translate, subArray, hiddenState, 'name', 250, 50, 'priceTolerancesList.name');
    xpColCreate(translate, subArray, hiddenState, 'numberOfAgreements', 100, 50, 'priceTolerancesList.numberOfAgreements');
    xpColHeadCreate(translate, outArray, subArray, 'priceTolerancesList.priceToleranceInfoHeader');

    xpColCreate(translate, subArray, hiddenState, 'startBusinessHours', 150, 50, 'priceTolerancesList.startBusinessHours');
    xpColCreate(translate, subArray, hiddenState, 'endBusinessHours', 150, 50, 'priceTolerancesList.endBusinessHours');
    xpColCreate(translate, subArray, hiddenState, 'priceToleranceInsideBusinessHours', 150, 50, 'priceTolerancesList.priceToleranceInsideBusinessHours');
    xpColCreate(translate, subArray, hiddenState, 'priceToleranceOutsideBusinessHours', 150, 50, 'priceTolerancesList.priceToleranceOutsideBusinessHours');

    xpColHeadCreate(translate, outArray, subArray, 'priceTolerancesList.priceInfoHeader');

    return [...outArray];
}

export function priceTolerancesIncomingTransformer(apiPriceTolerance) {
    return {
        ...apiPriceTolerance,
        currencyPairId                          : getNestedIdProperty(apiPriceTolerance, 'currencyPair'),
        name                                    : apiPriceTolerance.currencyPair?.key,
        description                             : apiPriceTolerance.description,
        startBusinessHours                      : apiPriceTolerance.priceTolerance?.startBusinessHours,
        endBusinessHours                        : apiPriceTolerance.priceTolerance?.endBusinessHours,
        priceToleranceInsideBusinessHours       : apiPriceTolerance.priceTolerance?.priceToleranceInsideBusinessHours,
        priceToleranceOutsideBusinessHours      : apiPriceTolerance.priceTolerance?.priceToleranceOutsideBusinessHours,
    }
}

export function priceTolerancesOutgoingTransformer(priceTolerance) {
    return {
        currencyPair: priceTolerance.currencyPair,
        id: priceTolerance.id,
        description: priceTolerance.description,
        priceTolerance: {
            startBusinessHours                  : priceTolerance.startBusinessHours,
            endBusinessHours                    : priceTolerance.endBusinessHours,
            priceToleranceInsideBusinessHours   : priceTolerance.priceToleranceInsideBusinessHours,
            priceToleranceOutsideBusinessHours  : priceTolerance.priceToleranceOutsideBusinessHours
        }
    }
}
