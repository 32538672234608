import {entityStateRequested} from "gui-common/requestEntityState/requestEntityStateReducer";
import {genericFetch} from "gui-common/api/apiCommon";
import {globalApiHandle} from "gui-common/api/apiConstants";

/* -----------------------------------------------------------------------------------------------------------------
* Functions to manually confirm a dispatch trade.
* -----------------------------------------------------------------------------------------------------------------*/
export function confirmDispatchTradeToApi(dispatchTrade, bookingReference, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!dispatchTrade || !bookingReference) {
                console.error("Incorrect parameters provided to confirmDispatchTradeToApi", dispatchTrade, bookingReference);
                return;
            }

            let logText = "Put dispatch trade confirmation. Trade id: " + dispatchTrade.id + ". Booking ref; " + bookingReference + ". Comment: " + comment + ".";
            let requestUrl = globalApiHandle.baseUrls.DispatchTrade + '/' + dispatchTrade.id + '/manual-confirm?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                responseHandler(dispatchTrade, bookingReference, comment),
                failHandler(dispatchTrade, bookingReference, comment),
                failHandler(dispatchTrade, bookingReference, comment),
                'PUT',
                {bookingReference: bookingReference, comment: comment},
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}


function responseHandler(dispatchTrade, bookingReference, comment) {
    return (resp) => {
        return (dispatch, getState) => {
            dispatch(entityStateRequested('DispatchTrade', dispatchTrade.id, 'ManualBook','status', ['Booked', 'ManualBook']));

        }
    }
}
function failHandler(dispatchTrade, bookingReference, comment) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}
