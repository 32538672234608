import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {userSelector} from "gui-common/orm/ormSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpAdminFormEntityList, {getXpAdminFormListInstanceId} from "gui-common/xpAdminForm/XpAdminFormEntityList";
import PriceTolerancesList from "./PriceTolerancesList";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {
    getPriceToleranceAdminFormConfig,
    getPriceToleranceAgreementAdminFormConfig
} from "appConfig/xpAdminFormConfig";
import {getXpGridStateSelector, useXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {AgreementsList} from "features/agreement/AgreementsList2";
import {getSpecificPriceToleranceSelector} from "./priceToleranceSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {useXpFormState} from "gui-common/xpForm/core/xpFormHooks";
import {getXpAdminFormModel} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {XP_FORM_EDIT, XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {refixSystemRights} from "appConfig/appUserRights";

const tolerancesFormInstanceId = 'priceToleranceSettings'
const agreementFormInstanceId = 'priceToleranceAgreementForm'

function PriceTolerancesPage() {
    const userLoggedIn = useSelector(userSelector);

    const priceToleranceGridInstanceId = getXpAdminFormListInstanceId(tolerancesFormInstanceId, 'PriceTolerances');
    const priceToleranceGridState = useXpGridState(priceToleranceGridInstanceId);

    const selectedPriceTolerance = useSelectorInstance(getSpecificPriceToleranceSelector, {selectId: priceToleranceGridState?.selectedId});

    const agreementListInstanceId = getXpAdminFormListInstanceId(agreementFormInstanceId, 'Agreement');

    const agreementListState         = useSelectorInstance(getXpGridStateSelector    , {instanceId: agreementListInstanceId});

    const agreementFormState      = useXpFormState(getXpAdminFormModel(agreementFormInstanceId, 'Agreement', {id: agreementListState?.selectedId}));
    const agreementFormUseState = agreementFormState ? agreementFormState.formUseState : undefined;


    return (
        <div>
            <h2><XpTranslated trKey={'priceTolerancesForm.header'}/></h2>
            <hr/>
            <div>
                <XpAdminFormEntityList
                    noParentData={true}
                    xpAdminFormInstanceId ={tolerancesFormInstanceId}
                    xpAdminFormConfig = {getPriceToleranceAdminFormConfig()}
                    suppressRowClickSelection={[XP_FORM_EDIT, XP_FORM_NEW].includes(agreementFormUseState)}
                    ormModel = 'PriceTolerances'
                    listComponent = {PriceTolerancesList}
                    formTemplate = 'priceTolerancesForm'
                    userCanEditEntity     = {userHasSystemRight(userLoggedIn, refixSystemRights.PriceTolerance.Update)}
                    userCanDeleteEntity   = {false}
                />
            </div>
            <hr/>
            <AgreementsWithOverride selectedPriceTolerance={selectedPriceTolerance}/>
        </div>
    )
}
export default withRouter(PriceTolerancesPage)

function AgreementsWithOverride(props) {
    if (!props.selectedPriceTolerance) {
        return null;
    }
    return (
        <div>
            <XpAccordion
                headerKey='priceTolerancesForm.agreementsWithOverride'
                headerParams={{currencyPair: props.selectedPriceTolerance?.name, count: props.selectedPriceTolerance.numberOfAgreements}}
                instanceId="priceTolerancesAgreements"
                defaultShowState={false}
                disabled = {!props.selectedPriceTolerance.numberOfAgreements}
            >
                <XpAdminFormEntityList
                    noParentData={true}
                    xpAdminFormInstanceId ={agreementFormInstanceId}
                    xpAdminFormConfig = {getPriceToleranceAgreementAdminFormConfig()}
                    ormModel = 'Agreement'
                    listComponent = {AgreementsList}
                    formTemplate = 'agreementForm'
                    filterFunction={(agreement) => {
                        return agreement.currencyPairId === props.selectedPriceTolerance?.currencyPairId
                            && agreement.overridePriceTolerances;
                    }}
                />
            </XpAccordion>
        </div>
    )
}
