import React, {useCallback, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import 'react-datepicker/dist/react-datepicker.css';
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CLOSE, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import PropTypes from "prop-types";
import {getReportSetFromApi} from "gui-common/report/reportApi"
import {
    reportDataReceived,
    clearReportData,
    reportRequested,
    reportLoaded
} from "gui-common/report/reportReducer";
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {CONFIRMATION_DIALOG, VIEW_REPORT_DIALOG} from "gui-common/modals/modalConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {xpFormChangeField, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {globalReportDataSetTypes} from "gui-common/report/reportConstants";

function ReportFilterForm ({enrichedReportData}) {

    const translate         = useXpTranslateFunction();
    const reportState       = useSelector(state => state.reportState);

    const formDataToDate          = useXpFormField('reportFilterForm.toDate');
    const formDataFromDate        = useXpFormField('reportFilterForm.fromDate');
    const formDataReportCurrency  = useXpFormField('reportFilterForm.reportCurrency');
    const formDataDataSetType     = useXpFormField('reportFilterForm.dataSetType');
    const formDataReportType      = useXpFormField('reportFilterForm.reportType');

    const ormCurrencies     = useSelector(activeCurrenciesSelector);
    const reportSetConfig   = useSelector(state => selectAppEnvProperty(state, 'reportSetConfig'));
    const reportConfig      = useSelector(state => selectAppEnvProperty(state, 'reportConfig'));

    const dispatch  = useDispatch();

    const setTypesList = useMemo(() => {
            const outArray = [];
            for (let key in globalReportDataSetTypes) {
                if (reportSetConfig && reportSetConfig[key] && reportSetConfig[key].hide) continue;
                outArray.push({
                    id: key,
                    trKey: 'report.dataSetTypes.'+key+'.name'
                });
            }
            return outArray;
        },
        [globalReportDataSetTypes, translate]
    );
    const reportTypesList = useMemo(() => {
            if (formDataDataSetType === "") return [];
            if (!globalReportDataSetTypes[formDataDataSetType]) return [];

            const outArray = [];
            for (let key in globalReportDataSetTypes[formDataDataSetType].reportTypes) {
                const langPath = 'report.dataSetTypes.'+formDataDataSetType+'.reportTypes.'+key;
                outArray.push({
                    id: key,
                    trKey: langPath + '.selectName',
                    trParams: {clientName: translate(langPath+'.clients')},
                });
            }
            return outArray;
        },
        [globalReportDataSetTypes, formDataDataSetType, translate]
    );

    const initialFormData = useMemo(
        () => {
            const initialDataSetType = formDataDataSetType ? formDataDataSetType : (setTypesList && setTypesList.length > 0 ? setTypesList[0].id : "");
            const availableReportTypes = initialDataSetType ? Object.keys(globalReportDataSetTypes[initialDataSetType].reportTypes) : undefined;
            const initialReportType  = formDataReportType  ? formDataReportType  : (availableReportTypes?.length > 0 ? availableReportTypes[0] : "");

            return {
                dataSetType       : initialDataSetType,
                reportType        : initialReportType,
                reportCurrency    : formDataReportCurrency ? formDataReportCurrency : ormCurrencies.find(item => item.currency === reportConfig.defaultReportCurrency)?.id,
                fromDate          : formDataToDate ? formDataToDate : moment().format('YYYY-MM-DD'),
                toDate            : formDataToDate ? formDataToDate : moment().format('YYYY-MM-DD'),
            };
        },
        []
    );

    const onFilterChange = useCallback((fieldModel, newValue, currentValue, setValue) => {
        return (dispatch, getState) => {

            const reportState = getState().reportState;
            if (!reportState.reportData) { // No need to confirm with user if there is no report data loaded.
                setValue(newValue);
                return;
            }

            // Set value so that the change is visible when the user gets the confirmation dialogue.
            setValue(newValue); // Set value so that the change is visible.
            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey : 'report.changeFilterModal'}))
                .then((result) => {
                    if (result.status === MODAL_TYPE_CONFIRM) {
                        dispatch(clearReportData());
                        if (fieldModel === 'reportFilterForm.dataSetType') { // If changing report set, the report type must be reset (useEffect will set default).
                            dispatch(xpFormChangeField('reportFilterForm.reportType', ""));
                        }
                    }
                    if (result.status === MODAL_TYPE_CANCEL) {
                        setValue(currentValue); // Change back to previous value.
                    }
                });
        }
    }, [reportState?.reportData])

    useEffect(
        () => {
            if (!formDataDataSetType || formDataReportType || !reportTypesList.length) {
                return;
            }
            const availableReportTypes = Object.keys(globalReportDataSetTypes[formDataDataSetType].reportTypes);
            const reportType  = availableReportTypes?.length > 0 ? availableReportTypes[0] : "";

            dispatch(xpFormChangeField('reportFilterForm.reportType', reportType));

        },
        [reportTypesList, formDataDataSetType, formDataReportType],
    );

    const handleSubmit = useCallback((reportFilter) => {
        const dataSetParams = globalReportDataSetTypes[reportFilter.dataSetType];
        const getExternalData = !(reportSetConfig && reportSetConfig[reportFilter.dataSetType] && reportSetConfig[reportFilter.dataSetType].useMockData);

        if (getExternalData) dispatch(getReportSetFromApi(reportFilter.dataSetType,
//        if (dataSetParams.externalData) dispatch(getReportSetFromApi('ExecutedOrders',
            {
                fromDate        : reportFilter.fromDate,
                toDate          : reportFilter.toDate,
                reportCurrencyId: reportFilter.reportCurrency,
            }));
        else dispatch(reportDataReceived(dataSetParams.mockData, reportFilter.dataSetType));
    }, [])

    function onReportLoaded (report) {
        dispatch(reportLoaded());
    }

    function viewReport() {
        console.log("View report", formDataReportType);
        dispatch(reportRequested());
        const reportCurrency = ormCurrencies.find(item => item.id === Number(formDataReportCurrency));
        dispatch(pushModalWindow(VIEW_REPORT_DIALOG,
            {
                dataSetType    : formDataDataSetType,
                reportType     : formDataReportType,
                reportData     : enrichedReportData,
                period         : translate('report.period') + formDataFromDate + ' - ' + formDataToDate,
                printed        : translate('report.printed') + moment().format('YYYY-MM-DD'),
                reportCurrency : reportCurrency ? reportCurrency.currency : "XXX",
                onRenderCallback : onReportLoaded,
            }))
            .then((result) => {
                if (result.status === MODAL_TYPE_CLOSE) {
                }
            });
    }

    const showReportCurrency = formDataDataSetType ? !(globalReportDataSetTypes[formDataDataSetType] && globalReportDataSetTypes[formDataDataSetType].hideReportCurrency) : false;

    const reportsAvailableOnDataSet = (reportTypesList.length > 0);

    return (
        <div xp-test-id="reportFilterForm">
            <XpForm
                className="filterFormContainer"
                formModel="reportFilterForm"
                onSubmit={handleSubmit}
                initialUseState={XP_FORM_EDIT}
                initialFormData={initialFormData}
            >
                <div className="filterFormField reportFilterSelect">
                    <XpFormSelectorInput
                        field         = "dataSetType"
                        selectList    = {setTypesList}
                        onChangeThunk={onFilterChange}
                        noTemplate noMargin
                    />
                </div>

                <div className="filterFormField reportFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin
                        field         = "fromDate"
                        dependentFields={{toDate: 'toDate'}}
                        dependentPropsFn={fields => ({maxDate: fields.toDate})}
                        minDate='2019-05-01'
                        onChangeThunk={onFilterChange}
                    />
                </div>

                <div className="filterFormField reportFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin
                        field         = "toDate"
                        dependentFields={{fromDate: 'fromDate'}}
                        dependentPropsFn={fields => ({minDate: fields.fromDate})}
                        maxDate={moment().format('YYYY-MM-DD')}
                        onChangeThunk={onFilterChange}
                    />
                </div>
                <div className="filterFormField reportFilterCurrency" style={showReportCurrency ? undefined : {visibility : 'hidden'}}>
                    <XpFormSelectorInput
                        field         = "reportCurrency"
                        selectList    = {ormCurrencies}
                        itemTextField = "currency"
                        onChangeThunk={onFilterChange}
                        noTemplate noMargin
                    />
                </div>

                <div className="filterFormButton">
                    <WaitingXpoolButton
                        labelKey        = 'reportFilterForm.submitButton'
                        onClickCallback = {() => dispatch(xpFormSubmitForm('reportFilterForm'))}
                        waiting         = {reportState.reportDataLoading}
                    />
                </div>

                {(reportState.reportData && (reportState.reportData.length > 0) && reportTypesList && (reportTypesList.length > 0)) &&
                <div className="filterFormRightButtonsGroup reportFilterViewReportGroup">
                    <div className="filterFormButton">
                        <WaitingXpoolButton
                            labelKey        = 'report.viewReportButton'
                            onClickCallback = {() => viewReport()}
                            waiting         = {(reportState.reportLoading || reportState.reportDataLoading)}
                            toolTipKey      = {reportState.reportLoading ? 'report.viewReportButtonWaitingTooltip' : undefined}
                        />
                    </div>
                    <div className="filterFormField reportFilterReportType">
                        <XpFormSelectorInput
                            field         = "reportType"
                            selectList    = {reportTypesList}
                            template      = {!reportsAvailableOnDataSet ? translate('reportFilterForm.noReportsForDataSet', {dataSet: translate('report.dataSetTypes.' + formDataDataSetType + '.name')}) : undefined}
                            noTemplate
                        />
                    </div>
                </div>}
            </XpForm>
        </div>
    );
}
ReportFilterForm.propTypes = {
    enrichedReportData: PropTypes.array,
};
export default ReportFilterForm;




