import {useSelector} from "react-redux";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {formatAmount, parseAmountInput, parseAndCleanInput} from "gui-common/numberFormat/numberFormatFunctions";
import TextField from "@mui/material/TextField";
import React from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";

function XpFormAmountInput (props) {
    const decDenLangState = useSelector(selectDecDenLangState);
    return (
        <XpFormLabeledInput
            alignRight
            parser = {value => parseAndCleanInput(value, decDenLangState)}
            updateOn = "blur"

            {...props}  // OBS: Everything before this line will be overridden by props (if set). Below will override anything in props.

            CompToRender={TextField}
            getValueFromEvent={event => event.target.value}
            isAmountField

            beforeChangeFunction={(before, after) => {
                const beforeValue = formatAmount(before, decDenLangState).replace(/\s/g, '');
                const afterValue  = formatAmount(after,  decDenLangState).replace(/\s/g, '');
                return (beforeValue === afterValue) ? null : beforeValue;
            }}
            valueFormatter = {value => parseAmountInput(value, decDenLangState)}
            valueRenderer = {value => formatAmount(value, decDenLangState)}
        />
    )
}
XpFormAmountInput.propTypes    = xpFormBasePropTypes;
export default XpFormAmountInput;
