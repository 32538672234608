import {LinkContainer} from "react-router-bootstrap";
import {NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import {useDispatch} from "react-redux";
import {logoutUserFromApi} from "gui-common/api/apiFunctions";

export function LogoutMenuItem(props) {
    const dispatch = useDispatch();
    return (
        <LinkContainer to='/login' onClick={() => dispatch(logoutUserFromApi())}>
            <NavDropdown.Item><XpTranslated trKey={'topMenu.logout'}/></NavDropdown.Item>
        </LinkContainer>
    )
}
