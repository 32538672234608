
/* -----------------------------------------------------------------------------------------------------------------
* Reset and reload ORM admin Form that is in state loading data when a new or update API response is received.
* -----------------------------------------------------------------------------------------------------------------*/
import {pushModalWindow} from "redux-promising-modals";
import {
    getNewXpAdminFormTemplateSelector,
    getXpAdminFormOrmModelDataSelector
} from "gui-common/xpAdminForm/xpAdminFormSelectors";
import {
    formDiffersFromCurrent,
    getXpAdminFormModel
} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {getXpAdminFormListInstanceId} from "gui-common/xpAdminForm/XpAdminFormEntityList";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {CONFIRMATION_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {xpFormApiClear, xpFormLoadForm, xpFormRemoveForm} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_EDIT, XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {getXpFormRootSelector, getXpFormUseStateSelector} from "gui-common/xpForm/core/xpFormSelectors";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {globalOrmConfig} from "gui-common/orm/ormModels";
import {entityIsDisabled, getLastTouchUser} from "gui-common/audit/auditFunctions";

function modelFormDataIsSubmitted(modelFormData, checkProp) {
    if (!modelFormData[checkProp]) return false; // No new form for this item.
    if (!modelFormData[checkProp]?.$_xpFormData.submittedToApi) return false; // User still editing. Do not reload.
    return true;
}
function handleOkCreate(ormModel, item, modelFormData, dispatch) {
    if (!item) return;
    if (!modelFormDataIsSubmitted(modelFormData, 'new')) return; // No new form or not submitted.

    if (globalOrmConfig.compareFunctions[ormModel]) {
        if (!globalOrmConfig.compareFunctions[ormModel](item, modelFormData.new)) return; // Check that created object is similar to form object. Use compare function if available on ormModel.
    }
    else {
        if (modelFormData.new.name && (modelFormData.new.name !== item.name)) return; // Check that name on new entity match name in new form
    }

    dispatch(xpFormRemoveForm(modelFormData.new.$_xpFormData.formModel));

    const listInstanceId = getXpAdminFormListInstanceId(modelFormData.xpAdminFormInstanceId, ormModel);
    dispatch(xpGridSetSelected(listInstanceId, item.id))
}
function handleOkUpdate(ormModel, item, modelFormData, dispatch) {
    if (!item || !modelFormData[item.id]) return; // No form is active for this id

    if (!modelFormData[item.id]?.$_xpFormData.submittedToApi) {
        // User still editing. Do not reload but inform user that an update was received. Different message depending on if the item was updated or disabled
        dispatch(pushModalWindow(INFORMATION_DIALOG, {
                modalKey: (entityIsDisabled(item) ? 'general.modalConfirmationEntityDisabledByOtherUser.' : 'general.modalConfirmationEntityUpdatedByOtherUser.') + ormModel,
                modalParams: {user: getLastTouchUser(item)},
            }));
        return;
    }
    // Inform the user if a disable was received while expecting an update!
    if (entityIsDisabled(item)) {
        dispatch(pushModalWindow(INFORMATION_DIALOG, {
            modalKey: 'general.modalConfirmationEntityDisabledDuringSaveByOtherUser.' + ormModel,
            modalParams: {user: getLastTouchUser(item)},
        }));
    }
    dispatch(xpFormRemoveForm(modelFormData[item.id]?.$_xpFormData.formModel));
}

function handleFailed(ormModel, itemProp, modelFormData, dispatch) {
    if (!modelFormDataIsSubmitted(modelFormData, itemProp)) return; // No new form or not submitted.
    dispatch(xpFormApiClear(modelFormData[itemProp]?.$_xpFormData.formModel));
}
function handleFailedCreate(ormModel, item, modelFormData, dispatch) {
    handleFailed(ormModel, 'new', modelFormData, dispatch)
}
function handleFailedUpdate(ormModel, item, modelFormData, dispatch) {
    if (!item) return;
    handleFailed(ormModel, item.id, modelFormData, dispatch)
}

const actionTypeOkHandlerMap = {
    'createEntity': handleOkCreate,
    'updateEntity': handleOkUpdate,
}
const actionTypeFailedHandlerMap = {
    'createEntity': handleFailedCreate,
    'updateEntity': handleFailedUpdate,
}

export function manageXpAdminFormFromApiResponse(ormModel, item, actionType, failed) { // item is an array if action is "entitiesLoaded!
    return (dispatch, getState) => {
        if (!ormModel)  return;

        const handler = failed ? actionTypeFailedHandlerMap[actionType] : actionTypeOkHandlerMap[actionType];
        if (!handler) return;

        const modelFormDataArray = getXpAdminFormOrmModelDataSelector()(getState(), {selectModel: ormModel});
        if (!modelFormDataArray || !modelFormDataArray.length) return; // No active form with the ormModel.

        for (const modelFormData of modelFormDataArray) { // Check each xp admin form instance
            handler(ormModel, item, modelFormData, dispatch);
        }
    }
}

export function xpAdminFormLoadNew(props) {
    return (dispatch, getState) => {
        const newItemTemplateSelector = getNewXpAdminFormTemplateSelector();
        const newFormTemplate   = newItemTemplateSelector(getState(), props);

        if (!newFormTemplate) {
            console.error("No newFormTemplate in setNew! Cannot create new.", props);
            return;
        }
        // dispatch(xpAdminFormLoad(props.xpAdminFormInstanceId, props.ormModel, newFormTemplate));
        dispatch(xpFormLoadForm(getXpAdminFormModel(props.xpAdminFormInstanceId, props.ormModel, undefined), newFormTemplate, XP_FORM_NEW));
    }
}

export function xpAdminFormCancelEditNew(formModel, currentData, newFormTemplate, addEventListener, closeForm) {
    return (dispatch, getState) => {
        const formUseState    = getXpFormUseStateSelector()(getState(), {model: formModel});
        const formData        = getXpFormRootSelector()(getState(), {model: formModel});
        const decDenLangState = selectDecDenLangState(getState());

        const formIsChanged = formDiffersFromCurrent(
            (formUseState === XP_FORM_EDIT) ? currentData : newFormTemplate,
            formData,
            decDenLangState
        );

        if (!formIsChanged) {
            closeForm();
        } else
            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'general.modalConfirmCancelEditNew'}))
                .then(({status}) => {
                    if (status === MODAL_TYPE_CONFIRM) {
                        closeForm();
                    }
                    if (status === MODAL_TYPE_CANCEL) {
                        addEventListener();
                    }
                });
    }
}


