import React, {useCallback, useMemo} from 'react'
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import 'react-datepicker/dist/react-datepicker.css';
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {getReportSetFromApi} from "gui-common/report/reportApi"
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getOrmModelLoading} from "gui-common/orm/ormLoadingSelectors";
import {getOrmItemsFromApi} from "gui-common/orm/ormApi";
import {getTimezoneNameFromBrowser} from "gui-common/appLocale/appLocaleFunctions";
import {globalApiHandle} from "gui-common/api/apiConstants";

function RequestHistoricDeliveriesPane(props) {
    const formModel = 'tradeReportDeliveryRequestForm'

    const formDataToDate          = useXpFormField('reportFilterForm.toDate');
    const formDataFromDate        = useXpFormField('reportFilterForm.fromDate');
    const itemsLoading  = useSelectorInstance(getOrmModelLoading, {ormModel: 'FxTradeReportDelivery'});

    const dispatch  = useDispatch();

    const handleSubmit = useCallback((requestFilter) => {
        dispatch(getOrmItemsFromApi(
            'FxTradeReportDelivery',
            undefined,
            {fromDate: requestFilter.fromDate, toDate: requestFilter.toDate, timeZone: getTimezoneNameFromBrowser()},
            globalApiHandle.baseUrls.FxTradeReportDelivery + "/historical"));
    }, [])

    return (
        <div style={{padding: '0 10px 0px 25px', marginTop: '-10px', marginBottom: '-1px'}}>
            <XpForm
                style={{padding: '0 0 10px 0', marginTop: '-10px'}}
                formTemplate={'fxTradeReportDeliveriesList.requestDeliveries'}
                className="filterFormContainer"
                formModel={formModel}
                onSubmit={handleSubmit}
                initialUseState={XP_FORM_EDIT}
                // initialFormData={initialFormData}
            >
                <div className="filterFormField reportFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin
                        field         = "fromDate"
                        dependentFields={{toDate: 'toDate'}}
                        dependentPropsFn={fields => ({maxDate: fields.toDate})}
                        minDate='2024-01-01'
                        defaultValue={moment().format('YYYY-MM-DD')}
                    />
                </div>

                <div className="filterFormField reportFilterDate">
                    <XpFormDateInput
                        noTemplate alignRight noMargin
                        field         = "toDate"
                        dependentFields={{fromDate: 'fromDate'}}
                        dependentPropsFn={fields => ({minDate: fields.fromDate})}
                        maxDate={moment().format('YYYY-MM-DD')}
                        defaultValue={moment().format('YYYY-MM-DD')}
                    />
                </div>

                <div className="filterFormButton">
                    <WaitingXpoolButton
                        labelKey        = 'fxTradeReportDeliveriesList.requestDeliveries.requestButton'
                        onClickCallback = {() => dispatch(xpFormSubmitForm(formModel))}
                        waiting         = {itemsLoading?.loadingAll}
                    />
                </div>

            </XpForm>
        </div>
    );
}
RequestHistoricDeliveriesPane.propTypes = {
};
export default RequestHistoricDeliveriesPane;




