import {createSelector} from "reselect";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {createTranslatedFieldData} from "gui-common/xpGrid/xpGridFunctions";

import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {getCurrencyPairString} from "gui-common/functions/functions";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {
    stateRequestedOnIdAndProperty
} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrAddCashedObject, getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {entityIsDisabled} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";


const listRateOrdersToDispatchTradeMap = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        const dispatchTradeMap = {};
        // console.log("Creating map");

        const listRateOrders = session.ListRateOrder.all().toRefArray();
        for (let listRateOrder of listRateOrders) {
            if (!dispatchTradeMap[listRateOrder.dispatchTradeId]) {
                dispatchTradeMap[listRateOrder.dispatchTradeId] = 1;
            }
            dispatchTradeMap[listRateOrder.dispatchTradeId]++;
        }
        // console.log("Done");
        return dispatchTradeMap;
    }
);

const dispatchTradeListRequestStateSelector = getRequestingStateOnModelSelector();
export const dispatchTradesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    state => dispatchTradeListRequestStateSelector(state, {model: 'DispatchTrade'}),
    listRateOrdersToDispatchTradeMap,
    (session, dispatchTradeRequestState, dispatchTradeNumberOfOrdersMap) => {
        // console.log("Selector dispatchTradesSelector running ");

        const dispatchTrades = session.DispatchTrade.all().orderBy('id', 'desc').toModelArray();

        const returnArray = enrichDispatchTrades(dispatchTrades, dispatchTradeRequestState, session, dispatchTradeNumberOfOrdersMap);

        // console.log("Selected dispatchTradesSelector:", returnArray);
        return returnArray;
    }
);

function enrichDispatchTrades(dispatchTradeModels, dispatchTradeRequestState, session, dispatchTradeToOrdersMap) {
    let returnArray = [];
    let cashedLegalEntityUnits = {};

    for (let dispatchTradeModel of dispatchTradeModels) {
        const dispatchTrade = dispatchTradeModel.ref;
        const leu = getOrAddCashedObject(dispatchTrade.legalEntityUnitId, 'LegalEntityUnit', cashedLegalEntityUnits, session);

        if (!leu || entityIsDisabled(leu)) {
            continue;
        }

        const displayAmount = dispatchTrade.dispatchedAmount + dispatchTrade.openAmount;
        let displaySide = dispatchTrade.side;
        if (dispatchTrade.side === 'BUYSELL') {
            if (displayAmount > 0) {
                displaySide = 'BUY';
            }
            if (displayAmount < 0) {
                displaySide = 'SELL';
            }
        }

        returnArray.push({
            ...dispatchTrade,
            displaySide: displaySide,
            isWaiting: stateRequestedOnIdAndProperty(dispatchTradeRequestState, dispatchTrade.id, 'status'),
            agreementId: dispatchTrade.agreementId,
            legalEntityUnitId: dispatchTrade.legalEntityUnitId,
            legalEntityUnitName: leu.name,
            currencyPairStr: dispatchTrade.currencyPair ? getCurrencyPairString(dispatchTrade.currencyPair) : "",
            amountCurrencyT: dispatchTrade.amountCurrency ? dispatchTrade.amountCurrency.currency : "",
            amount: Math.abs(displayAmount),
            numberOfOrders: dispatchTradeToOrdersMap ? dispatchTradeToOrdersMap[dispatchTrade.id] : dispatchTradeModel.listRateOrders.count(),
        });
    }
    return returnArray;
}

function addListDataPropsToDispatchTrades(dispatchTrades, translate) {
    for (let dispatchTrade of dispatchTrades) {
        createTranslatedFieldData(dispatchTrade, 'displaySide', translate, ("dispatchTradesList.sideTypes." + dispatchTrade.displaySide));
        createTranslatedFieldData(dispatchTrade, 'status', translate, ("dispatchTradesList.statusTypes." + dispatchTrade.status), undefined, dispatchTrade.isWaiting);
        createTranslatedFieldData(dispatchTrade, 'fxDeskReportState', translate, ("dispatchTradesList.fxDeskReportStateTypes." + dispatchTrade.fxDeskReportState), undefined, false);
    }
}

const enrichedDispatchTrades = state => dispatchTradesSelector(state);
const translate = state => selectTranslateFunction(state);
const filterFunction = (state, props) => props ? props.filterFunction : undefined;
export const getDispatchTradesListDataSelector = () => createSelector(
    [enrichedDispatchTrades, translate, filterFunction],
    (enrichedDispatchTrades, translate, filterFunction) => {
        if (!translate) return [];
        if (!enrichedDispatchTrades) return [];
        // console.log("Selector getDispatchTradesListDataSelector running: ", enrichedDispatchTrades, dispatchTradeRequestState);
        const filteredDispatchTrades = filterFunction ? enrichedDispatchTrades.filter(filterFunction) : enrichedDispatchTrades;
        addListDataPropsToDispatchTrades(filteredDispatchTrades, translate)
        // console.log("Selector getDispatchTradesListDataSelector done ", filteredDispatchTrades);
        return filteredDispatchTrades;
    }
);

const dispatchTradeRequestState = state => dispatchTradeListRequestStateSelector(state, {model: 'DispatchTrade'});

export const getDispatchTradesForListRateSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    translate,
    dispatchTradeRequestState,
    (state, props) => props ? props.listRateId : undefined,
    (session, translate, requestState, listRateId) => {
        // console.log("Selector getDispatchTradesForListRateSelector running: ", listRateId);
        if (!translate || !listRateId) {
            console.warn("No translate or listRateId provided ", listRateId);
            return [];
        }
        const listRateModel = getOrmModelObjectById(session, 'ListRate', listRateId);
        if (!listRateModel) {
            console.warn("Could not find listRateId ", listRateId);
            return [];
        }
        const dispatchTradeModels = listRateModel.dispatchTrades.toModelArray();
        const enrichedDispatchTrades = enrichDispatchTrades(dispatchTradeModels, requestState, session);

        addListDataPropsToDispatchTrades(enrichedDispatchTrades, translate)

        // console.log("Selector getDispatchTradesForListRateSelector done ", enrichedDispatchTrades);
        return enrichedDispatchTrades;
    }
);





