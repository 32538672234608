import {createSelector} from "reselect";
import {createSelector as createOrmSelector} from "redux-orm";
import {activeCurrencyPairsSelector} from "gui-common/orm/ormSelectors";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {entityIsActive} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";

/*
const currencyPairs = state => activeCurrencyPairsSelector(state, {agreementTypeArray: ['ListRateAgreement', 'MarketOrderAgreement']});
export const simulatorAvailableCurrencyPairsSelector = createSelector(
    [currencyPairs],
    (currencyPairs) => {
        return currencyPairs;
    }
);
*/
export const simulatorAvailableTenorsSelector = createSelector(
    [],
    () => {
        return ['TD', 'TM', 'SP', 'SN', 'BROKEN'];
    }
);

export const fixAccountsSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector legalEntityUnitsSelector running ");

        const fixAccounts = session.LegalEntityUnit.filter(entityIsActive).orderBy('id', 'desc').toRefArray().map(leu => leu.fxShortName);

        return fixAccounts;
    }
);

// Not relevant in refix with preloaded configuration since there is no active client concept.
export const marketSimulatorActiveConfigurationsSelector = createSelector(
    [],
    () => {
        return [];
    }
);
