import {globalOrmConfig} from "gui-common/orm/ormModels";
import {wizardCreatedFromOkApiResponse, wizardFromFailedApiResponse} from 'gui-common/wizard/wizardThunks';
import {
    ORM_CLEAR_DB,
    ORM_ENTITY_CLEAR_ALL,
    ORM_ENTITY_CLEAR_ON_CONDITION,
    ORM_ENTITY_CREATE,
    ORM_ENTITY_CREATE_FAILED,
    ORM_ENTITY_DELETE,
    ORM_ENTITY_DELETE_FAILED,
    ORM_ENTITY_LOAD_ALL,
    ORM_ENTITY_LOAD_ALL_FAILED,
    ORM_ENTITY_LOAD_BATCH,
    ORM_ENTITY_START_CREATE,
    ORM_ENTITY_START_DELETE,
    ORM_ENTITY_START_LOAD_ALL,
    ORM_ENTITY_START_UPDATE,
    ORM_ENTITY_UPDATE,
    ORM_ENTITY_UPDATE_FAILED,
} from "gui-common/orm/ormConstants"
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import {manageXpAdminFormFromApiResponse} from "gui-common/xpAdminForm/xpAdminFormThunks";
import {orm} from "gui-common/orm/orm";

export function ormClearData() {
    return {
        type : ORM_CLEAR_DB,
    };
}
export function ormEntitiesClearModel(itemType) {
    return {
        type : ORM_ENTITY_CLEAR_ALL,
        payload : {itemType}
    };
}
export function ormEntitiesClearModelOnCondition(itemType, conditionFn) {
    return {
        type : ORM_ENTITY_CLEAR_ON_CONDITION,
        payload : {itemType, conditionFn}
    };
}

// These actions are used to signal to the components that API calls related to ORM entities are started.
export function ormEntitiesGetStarted(itemType) {
    return {
        type : ORM_ENTITY_START_LOAD_ALL,
        payload : {itemType}
    };
}
export function ormEntityCreateStarted(itemType, item) {
    return {
        type : ORM_ENTITY_START_CREATE,
        payload : {itemType, item}
    };
}
export function ormEntityUpdateStarted(itemType, item) {
    return {
        type : ORM_ENTITY_START_UPDATE,
        payload : {itemType, item}
    };
}
export function ormEntityDeleteStarted(itemType, item) {
    return {
        type : ORM_ENTITY_START_DELETE,
        payload : {itemType, item}
    };
}
//*************

// These actions are used to signal to the components that API calls related to ORM entities have failed.
export function ormEntitiesGetFailedAction(itemType) {
    return {
        type : ORM_ENTITY_LOAD_ALL_FAILED,
        payload : {itemType}
    };
}
export function ormEntityCreateFailedAction(itemType, item) {
    return {
        type : ORM_ENTITY_CREATE_FAILED,
        payload : {itemType, item}
    };
}
export function ormEntityUpdateFailedAction(itemType, item) {
    return {
        type : ORM_ENTITY_UPDATE_FAILED,
        payload : {itemType, item}
    };
}
export function ormEntityDeleteFailed(itemType, item) {
    return {
        type : ORM_ENTITY_DELETE_FAILED,
        payload : {itemType, item}
    };
}
//*************

// These thunks are used to dispatch action creators for the ORM reducers but also dispatch other actions required when the ORM API calls fails.
export function ormEntitiesGetFailed(itemType) {
    return (dispatch, getState) => {
        dispatch(ormEntitiesGetFailedAction(itemType));
        const appReady = selectAppReadyState(getState());
        if (!appReady) return;
    };
}
export function ormEntityCreateFailed(itemType, item) {
    return (dispatch, getState) => {
        dispatch(ormEntityCreateFailedAction(itemType, item));
        const appReady = selectAppReadyState(getState());
        if (!appReady) return;

        dispatch(wizardFromFailedApiResponse(itemType, "createEntity", item));
        dispatch(manageXpAdminFormFromApiResponse(itemType, item, 'createEntity', true));
    };
}
export function ormEntityUpdateFailed(itemType, item) {
    return (dispatch, getState) => {
        dispatch(ormEntityUpdateFailedAction(itemType, item));
        const appReady = selectAppReadyState(getState());
        if (!appReady) return;

        dispatch(manageXpAdminFormFromApiResponse(itemType, item, 'updateEntity', true));
    };
}

// These actions are used to propagate the ORM data loaded from the API to the different parts of the application.
export function ormEntitiesReceivedAction(itemType, newItems) {
    return {
        type : ORM_ENTITY_LOAD_ALL,
        payload : {itemType, newItems}
    };
}
export function ormEntitiesBatchAction(batchItems) {
    return {
        type : ORM_ENTITY_LOAD_BATCH,
        payload : batchItems
    };
}
export function ormEntityCreatedAction(itemType, item) {
    return {
        type : ORM_ENTITY_CREATE,
        payload : {itemType, item},
    };
}
export function ormEntityUpdatedAction(itemType, item) {
    return {
        type : ORM_ENTITY_UPDATE,
        payload : {itemType, item}
    };
}
export function ormEntityDeleted(itemType, item) { // Delete is different to disabled that are handled through the updated functions in ORM api and reducer.
    return {
        type : ORM_ENTITY_DELETE,
        payload : {itemType, item}
    };
}
export function ormEntitiesReceived(itemType, newItems) {
    return (dispatch, getState) => {
        dispatch(ormEntitiesReceivedAction(itemType, newItems));
        const appReady = selectAppReadyState(getState());
        if (!appReady) return;
    };
}
export function ormEntityCreated(itemType, item) {
    return (dispatch, getState) => {
        dispatch(ormEntityCreatedAction(itemType, item));
        const appReady = selectAppReadyState(getState());
        if (!appReady) return;

        dispatch(wizardCreatedFromOkApiResponse(itemType, item, "createEntity"));
        dispatch(manageXpAdminFormFromApiResponse(itemType, item, 'createEntity', false));
    };
}
export function ormEntityUpdated(itemType, item) {
    return (dispatch, getState) => {
        dispatch(ormEntityUpdatedAction(itemType, item));
        const appReady = selectAppReadyState(getState());
        if (!appReady) return;

        if (globalOrmConfig.onUpdateThunks[itemType]) {
            dispatch(globalOrmConfig.onUpdateThunks[itemType](item))
        }
        dispatch(manageXpAdminFormFromApiResponse(itemType, item, 'updateEntity', false));
    };
}
export function ormEntityRefreshed(itemType, item) {
    return (dispatch, getState) => {
        dispatch(ormEntityUpdatedAction(itemType, item));
    };
}

// *********************************


function upsertOrmModel(modelClass, item) {
    if (item.executionRights === null) delete item.executionRights; // patch to not overwrite executionRights when doing refresh
    if (item.userRights      === null) delete item.userRights; // patch to not overwrite userRights when doing refresh
    if (item.systemRights    === null) delete item.systemRights; // patch to not overwrite systemRights when doing refresh
    // console.log("Upserint ... ", modelClass, item);

    modelClass.upsert(item);
}

function addEntitiesArray(session, itemArray, itemType) {
    if (!itemArray) return;

    const ModelClass = session[itemType];

    itemArray.forEach((item) => {
       addNestedEntities(session, itemType, item);
        upsertOrmModel(ModelClass, item);
    });
}

const addNestedEntities = (session, itemType, item) => {
    if (!globalOrmConfig.nestedEntities[itemType]) return;
    for (let nestItem of globalOrmConfig.nestedEntities[itemType]) {
        if (!item[nestItem.property]) continue;

        if (Array.isArray(item[nestItem.property])) {
            addEntitiesArray(session, item[nestItem.property], nestItem.model);
        }
        else {
            addEntitiesArray(session, [item[nestItem.property]], nestItem.model);
/*
            addNestedEntities(session, nestItem.model, item[nestItem.property]);
            const ModelClass = session[nestItem.model];
            upsertOrmModel(ModelClass, item[nestItem.property]);
*/
        }
        delete item[nestItem.property]; // clear nested array before upsert to ORM. No nested structures in ORM, those are created in the selectors.
    }
};

function loadOrmEntities (state, payload) {

    if (!payload.newItems.length) {
        console.warn("ORM_ENTITY_LOAD_ALL called with empty array. itemType=" + payload.itemType);
        return state;
    }
    const session = orm.session(state);

    addEntitiesArray(session, payload.newItems, payload.itemType);

    return session.state;
}
function clearOrmEntities (state, payload) {
    const session = orm.session(state);
    const ModelClass = session[payload.itemType];

    ModelClass.delete();
    return session.state;
}
function clearOrmEntitiesOnCondition (state, payload) {
    if (!payload.itemType || !payload.conditionFn) return state;

    const session = orm.session(state);
    const ModelClass = session[payload.itemType];
    const itemArray = ModelClass.all().toModelArray();
    itemArray.forEach(item => {
        if (payload.conditionFn(item.ref)) {
            item.delete();
        }
    });
    return session.state;
}
function loadBatch (state, payload) {
    const session = orm.session(state);

    if (payload.length === 0) return state;

    if (!payload.length) {
        console.warn("ORM_ENTITY_LOAD_BATCH called with empty array.");
        return state;
    }
    for (let i=0;i<payload.length;i++) {
        if (!payload[i].model || !payload[i].itemData || !payload[i].ormEventType) {
            console.warn("Invalid messageObject in ORM loadBatch: ", payload[i]);
            continue;
        }
        const ModelClass = session[payload[i].model];
        if (payload[i].ormEventType === 'delete') {
            deleteItem(ModelClass, payload[i].itemData, payload[i].model);
/*
            if(ModelClass.idExists(payload[i].itemData.id)) {
                const modelInstance = ModelClass.withId(payload[i].itemData.id);
                modelInstance.delete();
            }
            else console.warn("Cannot find item to delete in ORM loadBatch: ", payload[i]);
*/
        }
        else {
            addNestedEntities(session, payload[i].model, payload[i].itemData);
            upsertOrmModel(ModelClass, payload[i].itemData);
        }
    }
    return session.state;
}
function deleteItem(ModelClass, itemData, model) {
    if(ModelClass.idExists(itemData.id)) {
        if (globalOrmConfig.modelConfig[model] && globalOrmConfig.modelConfig[model].upsertOnDelete) {
            upsertOrmModel(ModelClass, itemData);
        }
        else {
            const modelInstance = ModelClass.withId(itemData.id);
            modelInstance.delete();
        }
    }
    else {
        console.warn("Cannot find item to delete in ORM loadBatch: ", itemData);
    }
}

function upsertOrmEntity (state, payload) {
    const session = orm.session(state);
    const ModelClass = session[payload.itemType];

    addNestedEntities(session, payload.itemType, payload.item);
/*
    const nestedArrayName = nestedOrmEntitiesArrayMap[payload.itemType];
    const nestedEntities = nestedArrayName ? payload.item[nestedArrayName] : undefined;
    payload.item[nestedArrayName] = undefined; // clear nested array before upsert to ORM. No nested structures in ORM, those are created in the selectors.
*/
    upsertOrmModel(ModelClass, payload.item);
/*
    if (nestedEntities) addEntitiesArray(session, nestedEntities, nestedOrmEntityType[payload.itemType]);
*/
    return session.state;
}

function deleteOrmEntity (state, payload) {
    const session = orm.session(state);
    const ModelClass = session[payload.itemType];
    deleteItem(ModelClass, payload.item, payload.itemType);
/*
    if(ModelClass.idExists(payload.item.id)) {
        if (globalOrmConfig.modelConfig[payload.itemType] && globalOrmConfig.modelConfig[payload.itemType].upsertOnDelete) {
            upsertOrmModel(ModelClass, payload.item);
        }
        else {
            const modelInstance = ModelClass.withId(payload.item.id);
            modelInstance.delete();
        }
    }
*/
    return session.state;
}

function clearOrmData (state, payload) {
    return orm.getEmptyState();
}

// Reducer action handlers map ************************************************
// ************************************************************************
const actionHandler = {
    [ORM_ENTITY_LOAD_ALL]   : loadOrmEntities,
    [ORM_ENTITY_LOAD_BATCH] : loadBatch,
    [ORM_ENTITY_UPDATE]     : upsertOrmEntity,
    [ORM_ENTITY_CREATE]     : upsertOrmEntity,
    [ORM_ENTITY_DELETE]     : deleteOrmEntity,
    [ORM_CLEAR_DB]          : clearOrmData,
    [ORM_ENTITY_CLEAR_ALL]  : clearOrmEntities,
    [ORM_ENTITY_CLEAR_ON_CONDITION]  : clearOrmEntitiesOnCondition,
};

export function ormReducer(state = orm.getEmptyState(), action) {
    const handler = actionHandler[action.type];
    return handler ? handler(state, action.payload) : state;
}
