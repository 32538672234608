import ProcessStatusWidget from "gui-common/processStatus/ProcessStatusWidget";
import UserMessagesList from "gui-common/userMessages/UserMessagesList";
import FxPriceList from "gui-common/fxPrice/FxPriceList";
import MarketSimulator from "gui-common/simulators/fxMarketSimulator/MarketSimulator";
import FixLogList from "gui-common/fixLog/FixLogList";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {commonSystemRights} from "gui-common/userRights/userRightsConstants";

const getCommonDashboardWidgets = (appConfig) => {
    const retObject = {};
    retObject.IntegrationStatusContainer = {
        component           : ProcessStatusWidget,
        authorizedFunction  : null,
        langPath            : 'processStatus.dashboardComponent.',
        icon                : 'traffic',
        keyName             : 'traffic',
        defaultSize         : {w: 5, h: 15}
    }
    retObject.UserMessagesList = {
        component           : UserMessagesList,
        authorizedFunction  : null,
        langPath            : 'userMessages.dashboardComponent.',
        icon                : 'announcement',
        keyName             : 'userMessages',
    };
    if (appConfig.useFx) {
        retObject.FxPriceList = {
            component           : FxPriceList,
                authorizedFunction  : null,
                langPath            : 'fxPrices.dashboardComponent.',
                icon                : 'euro_symbol',
                keyName             : 'fxPrices',
                defaultSize         : {w: 3, h: 10}
        };
        retObject.FixLogList = {
            component           : FixLogList,
                authorizedFunction  : (user) => userHasSystemRight(user, commonSystemRights.FixLog.Read),
                langPath            : 'fixLog.fixLogList.dashboardComponent.',
                icon                : 'question_answer',
                keyName             : 'fixLog',
                defaultSize         : {w: 6, h: 10}
        };
        retObject.MarketSimulator = {
            component           : MarketSimulator,
                authorizedFunction  : () => (window.oxygen_env.DEV_MODE === true),
                langPath            : 'marketSimulator.dashboardComponent.',
                icon                : 'currency_exchange',
                keyName             : 'marketSimulator',
                defaultSize         : {w: 7, h: 12}
        }
    }
    return retObject;
};
export function getDashboardConstants() {
    return dashboardConstants;
}
export const dashboardConstants = {};
export function setGlobalDashboardConfig(appConfig) {
    if (!appConfig.dashboard) {
        return;
    }
    dashboardConstants.widgets = {...getCommonDashboardWidgets(appConfig), ...appConfig.dashboard?.widgets};
}
