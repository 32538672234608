import {push} from "connected-react-router";
import {createSelector} from "reselect";
import {
    activeCountriesSelector, activeCurrenciesSelector, activeCurrencyPairsSelector, activeFixSessionsSelector,
    activeFxProvidersSelector, activeTenorsSelector,
} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {findAndSetDefaultId} from "gui-common/wizard/wizardFunctions";
import RfWizardClientBasicForm from "features/refixClientWizard/forms/RfWizardClientBasicForm";
import RfWizardCurrencyPairsForm from "features/refixClientWizard/forms/RfWizardCurrencyPairsForm";
import RfWizardListRateAgreementsForm from "features/refixClientWizard/forms/RfWizardListRateAgreementsForm";
import RfWizardMarketOrderAgreementsForm from "features/refixClientWizard/forms/RfWizardMarketOrderAgreementsForm";
import {activeDispatchPeriodsSelector, activePeriodsSelector} from "appConfig/orm/ormSelectors";
import RfWizardReviewAndSaveForm from "features/refixClientWizard/forms/RfWizardReviewAndSaveForm";
import {operatingUnitsWithFullClientCreateSelector} from "features/operatingUnit/operatingUnitSelectors";

export const refixWizards = {
    'refixClientWizard': {
        ormModel: 'Client',
        stepInfo: [
            {
                formComponent: RfWizardClientBasicForm,
                heading: 'clientInfoHeading',
                fields: ['name', 'description', 'countryId', 'clientRefId', 'restTimeZone', 'appId', 'operatingUnitId', 'fxShortName', 'fixSessionId', 'netDispatchTrades', 'sendToAccounting', 'clientApiChannel', 'exemptFromGlobalSuspend', 'tradingLimitCurrencyId', 'tradingLimit', 'netTradingLimit'],
                fieldLookup: {
                    operatingUnitId : 'operatingUnits',
                    countryId       : 'countries',
                    fixSessionId    : 'fixSessions',
                    tradingLimitCurrencyId : 'currencies',
                    clientApiChannel: 'clientApiChannels'
                }
            },
            {
                formComponent: RfWizardCurrencyPairsForm,
                heading: 'selectCurrencyPairHeading',
                fields: ["currencyPairs"],
                subFields: ["currencyPairId", "useListRateAgreements", "useMarketOrderAgreements"],
                summaryGroupField: "currencyPairId",
                fieldLookup: {
                    currencyPairId : 'currencyPairs',
                },
            },
            {
                formComponent: RfWizardListRateAgreementsForm,
                heading: 'listRateAgreementsHeading',
                fields: ["listRateAgreements"],
                subFields: ['tenorId', 'listRatePeriodId', 'dispatchLevel', 'dispatchTradePeriodId', 'buySellType', 'gracePeriod', 'openTime', 'closeTime'],
                summaryGroupField: "currencyPairId",
                fieldLookup: {
                    tenorId : 'tenors',
                    listRatePeriodId : 'listRatePeriods',
                    dispatchTradePeriodId : 'dispatchPeriods',
                    currencyPairId : 'currencyPairs',
                    buySellType : 'buySellTypes',
                },
                initialHidden: true,
                hideStepFn: (formData) => {
                    return (formData?.useListRateAgreements !== true)
                },
            },
            {
                formComponent: RfWizardMarketOrderAgreementsForm,
                heading: 'marketOrderAgreementsHeading',
                fields: ['marketOrderAgreements'],
                subFields: ['tenorString', 'openTime', 'closeTime'],
                summaryGroupField: "currencyPairId",
                fieldLookup: {
                    currencyPairId : 'currencyPairs',
                },
                initialHidden: true,
                hideStepFn: (formData) => {
                    return (formData?.useMarketOrderAgreements !== true)
                },
            },
            {
                formComponent: RfWizardReviewAndSaveForm,
                heading: 'finalStepHeading',
                info: 'finalStepInfo',
                fields:[],
            }
        ],
        formName: 'refixClientWizardForm',
        subFormName: 'agreementsForm',
        fileSpecification: 'onboardingWizardFileConfig',
        defaultParametersSelector: createSelector(
            [
                state => activeFixSessionsSelector(state),
                state => activeCountriesSelector(state),
                state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForWizard'}),
                state => selectAppEnvProperty(state, 'availableTimeZones'),
                state => activeCurrenciesSelector(state),
            ],
            (ormFixSessions, ormCountries, operatingUnits, timeZones, ormCurrencies) => {
                return {
                    fixSessionId    : findAndSetDefaultId(ormFixSessions),
                    countryId       : findAndSetDefaultId(ormCountries),
                    operatingUnitId : findAndSetDefaultId(operatingUnits),
                    restTimeZone    : findAndSetDefaultId(timeZones),
                    tradingLimitCurrencyId : findAndSetDefaultId(ormCurrencies),
                    fxEnabled       : true,
                }
            }
        ),
        lookupValuesSelector: createSelector(
            [
                state => activeFixSessionsSelector(state),
                state => activeCountriesSelector(state),
                state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForWizard'}),
                state => activeCurrenciesSelector(state),
                state => activeCurrencyPairsSelector(state),
                state => activeTenorsSelector(state),
                state => activePeriodsSelector(state),
                state => activeDispatchPeriodsSelector(state),
                state => selectAppEnvProperty(state, 'availableTimeZones'),
                state => activeTenorsSelector(state, {agreementType: 'ListRateAgreement'})
            ],
            (fixSessions, ormCountries, operatingUnits, ormCurrencies, ormCurrencyPairs, ormTenors, ormListRatePeriods, ormDispatchPeriods, availableTimeZones, listRateTenors) => {
                return {
                    countries       : ormCountries.map( item => ({...item, name: item.country })),
                    currencies      : ormCurrencies.map(item => ({...item, name: item.currency})),
                    currencyPairs   : ormCurrencyPairs.map(item => ({...item, name: item.currencyPairName})),
                    operatingUnits  : operatingUnits,
                    fixSessions     : fixSessions,
                    listRatePeriods : ormListRatePeriods.map(item => ({...item, name: item.period})),
                    dispatchPeriods : ormDispatchPeriods.map(item => ({...item, name: item.period})),
                    tenors          : ormTenors,
                    agreementTypes  : ['ListRateAgreement', 'MarketOrderAgreement'].map(item => ({id: item, name: item})),
                    buySellTypes    : ['BUY', 'SELL', 'BUYSELL'].map(item => ({id: item, name: item})),
                    timeZones       : availableTimeZones.map(item => ({id: item.id, name: item.id})),
                    listRateTenors  : listRateTenors.map(item => ({id: item.name, name: item.id})),
                    clientApiChannels: ['EXTERNAL', 'EXTERNAL'].map(item => ({id: item, name: item})),
                }
            }
        ),
        validationParamsSelector: createSelector(
            [
                state => selectAppEnvProperty(state, 'agreementFormConfig'),
                state => activePeriodsSelector(state),
                state => activeCurrencyPairsSelector(state)
            ],
            (agreementFormConfig, ormListRatePeriods, ormCurrencyPairs) => {
                let weekendPeriod = ormListRatePeriods.find(item => item.period === 'Weekend');
                return {
                    gracePeriodMaxSeconds  : agreementFormConfig?.fieldsConfig?.gracePeriod?.maxSeconds,
                    weekendPeriodId        : weekendPeriod.id,
                    ormCurrencyPairs       : ormCurrencyPairs
                }
            }
        ),
        submitTransformer: (formData) => {
            let agreements = [];
            if (formData.listRateAgreements) {
                for (let agreement of formData.listRateAgreements) {
                    agreements.push({...agreement, type: 'ListRateAgreement'})
                }
            }
            if (formData.marketOrderAgreements) {
                for (let agreement of formData.marketOrderAgreements) {
                    agreements.push({...agreement, type: 'MarketOrderAgreement'})
                }
            }
            const legalEntityUnit = {
                name             :formData.name + '(BU)',
                fxEnabled        : true,
                fixSessionId     : formData.fixSessionId,
                fxShortName      : formData.fxShortName,
                appId            : formData.appId,
                restTimeZone     : formData.restTimeZone,
                tradingLimit     : formData.tradingLimit,
                tradingLimitCurrencyId: formData.tradingLimitCurrencyId,
                netTradingLimit  : formData.netTradingLimit,
                exemptFromGlobalSuspend: formData.exemptFromGlobalSuspend,
                agreements       : agreements,
            };
            const legalEntity = {
                legalPersonType : 'CORPORATE',
                useLegalEntityUnits: false,
                name            : formData.name + '(LE)',
                countryId       : formData.countryId,
                legalEntityUnits: [legalEntityUnit],
            };
            let clientToSubmit = {
                ...formData,
                useLegalEntities: false,
                timeZone        : formData.restTimeZone,
                legalEntities   : [legalEntity],
            }
            console.log("Wizard sending client to API: ", clientToSubmit);
            return clientToSubmit
        },
        onSubmitSuccessThunk: (createdObject) => (dispatch, getState) => {
            const targetPath = selectAppEnvProperty(getState(), 'appStartPath');
            dispatch(push(targetPath));
        },
    }
}
