import React from 'react'
import PropTypes from "prop-types";
import spinnerGif from "gui-common/resources/spinner.gif";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function ComponentLoading(props) {

    return (
        <div>
            <p className="valueText">{props.loadingTrKey ? <XpTranslated trKey={props.loadingTrKey} trParams={props.loadingTrParams}/> : props.loadingMessage} </p>
            <img className="spinner" src={spinnerGif} alt="waiting" />
        </div>
    )
}
ComponentLoading.propTypes = {
    loadingTrKey:      PropTypes.string,
    loadingTrParams:   PropTypes.string,
    loadingMessage:    PropTypes.string,
    classNameSpinner:  PropTypes.string,
    classNameMessage:  PropTypes.string,
};
ComponentLoading.defaultProps = {
    classNameSpinner:      "spinner",
    classNameMessage:      "valueText",
};

export default ComponentLoading;


