import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";
import {prepareMarginGroupsGridColumns} from "features/marginGroup/marginGroupFunctions";
import {
    getMarginGroupsListDataSelector
} from "features/marginGroup/marginGroupSelectors";

export default function MarginGroupsList (props) {

    const translate = useSelector(selectTranslateFunction);

    const columnDefs = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                id                  : true,
                name                : false,
                description         : true,
                numberOfAgreements  : false,
                numberOfLeus        : false,
                priceTypeT          : false,
                bidMarginBps        : false,
                askMarginBps        : false,
                overrides           : false,
                createdDateTime     : false,
                createdByUser       : true,
                changedDateTime     : false,
                changedByUser       : true,
            };
            return prepareMarginGroupsGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );

    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            trKeyBase='marginGroupList'
            ormModel='MarginGroup'
            {...props}
            getRowDataSelector   = {getMarginGroupsListDataSelector}
            // rowDataSelectorProps = {props.rowDataSelectorProps}
        />
    )
}
