import React, {Component, useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {useResizeObserver} from "gui-common/functions/hooks";


class XpGridSingleDetailCellRenderer extends Component {
    // constructor(props) {
    //     super(props);
    // }

    refresh(params) {
        return true;
    }

    render () {
        const rowData = this.state.propsToComponent.getRowDataFunction ? this.state.propsToComponent.getRowDataFunction(this.props.data) : this.props.data;
        return (
            <DetailRenderer {...{...this.props, rowData: rowData}}/>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        gridState:  selectMyXpGridState(state, {instanceId: props.xpDetailRendererProps.parentInstanceId}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true} )(XpGridSingleDetailCellRenderer);


function DetailRenderer(props) {
    const [containerHeight, setContainerHeight] = useState(0);

    const observerCallback = useCallback(box => {
        // console.log("Container observer: ", box);
        setContainerHeight(box.height);
    }, [])
    const containerRef = useResizeObserver(observerCallback);

    useEffect(
        () => {
            // console.log("useEffect on heightState", props.instanceId, heightState);
            // if (props.setHeightCallback) props.setHeightCallback(containerHeight);

            props.node.setRowHeight(containerHeight);
            props.api.onRowHeightChanged();
            props.context.reCalculateGridHeight();

        },
        [containerHeight],
    );

    return (
        <div ref={containerRef}>
            <div className="xpGridDetailContainer xpGridSingleDetailContainer">
                <props.xpDetailRendererProps.componentToRender {...props}/>
            </div>
        </div>
    )
}
