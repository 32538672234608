import PropTypes from "prop-types";
import React from "react";
import XpTooltip from "gui-common/components/XpTooltip";
import addIcon from "gui-common/resources/addIcon.png";
import removeIcon from "gui-common/resources/binIcon.png";
import minusIcon from "gui-common/resources/minusIcon.png";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


export const xpIconButtonTypes = {
    add: 'add',
    delete: 'delete',
    minus: 'minus',
}
const typeIconMap = {
    [xpIconButtonTypes.add]     : addIcon,
    [xpIconButtonTypes.delete]  : removeIcon,
    [xpIconButtonTypes.minus]   : minusIcon,
}

function XpIconButton (props) {
    if (props.hidden) return null;
    return (
        <div
            style={props.imgStyle}
            onClick   = {props.disabled ? undefined : () => props.onClickCb()}
        >
            <XpTooltip trKey={props.toolTipKey} trParams={props.toolTipParams}>
                <img
                    src       = {typeIconMap[props.type]}
                    alt       = {props.type}
                    className = {"buttonIcon buttonIconInline" + (props.disabled ? " buttonIconDisabled" : "")}
                    style     = {props.disabled ? {filter: 'grayscale(100%)'} : undefined}
                />
            </XpTooltip>
            {props.labelKey &&
            <label className='buttonIconLabel'><XpTranslated trKey={props.labelKey} trParams={props.labelParams}/></label>}
        </div>
    )
}
XpIconButton.propTypes = {
    type: PropTypes.string,
    labelKey: PropTypes.string,
    labelParams: PropTypes.object,
    toolTipKey: PropTypes.string,
    toolTipParams: PropTypes.object,
    onClickCb: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    imgStyle: PropTypes.object
};
export default XpIconButton



