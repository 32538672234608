import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {rateInputParser} from "gui-common/numberFormat/numberFormatFunctions";
import {useDispatch, useSelector} from "react-redux";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {getActiveCurrencyPairsSelector} from "gui-common/orm/ormSelectors";
import {createSelectItems, useNoFormValidation, useSelectorInstance} from "gui-common/functions/hooks";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {marginGroupsPriceTypes} from "features/marginGroup/marginGroupConstants";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";

export function PriceMargin (props) {
    const currencyPairs   = useSelectorInstance(getActiveCurrencyPairsSelector, {});
    const formContext     = useXpFormContext()
    const priceTypes      = createSelectItems(marginGroupsPriceTypes);
    const decDenLangState = useSelector(selectDecDenLangState);
    const dispatch        = useDispatch()
    const formDataCurrencyPairId = useXpFormField('currencyPairId')
    const noFormValidation = useNoFormValidation();

    const filteredCurrencyPairs = useMemo(
        () => {
            if (noFormValidation) {
                return currencyPairs;
            }
            const returnArray = [];
            for (const currencyPair of currencyPairs) {
                if (currencyPair.id === formDataCurrencyPairId) {
                    returnArray.push(currencyPair);
                }
                if ((formContext.formUseState !== XP_FORM_VIEW) && props.allElements?.length && props.allElements.find(item => item.currencyPairId === currencyPair.id && !item.isDeleted)) {
                    continue;
                }
                returnArray.push(currencyPair);
            }
            return returnArray;
        },
        [currencyPairs, props.allElements]
    );


    const columnStyle    = {display: 'inline-block', width: 'calc(50% - 5px)'}
    return (
        <div className={props.className}>
            <div>
                {!props.hideCurrencyPair &&
                <XpFormSelectorInput
                    isRequired inLineLayout
                    inLineDivider={66}
                    field="currencyPairId"
                    selectList={filteredCurrencyPairs}
                    itemTextField="name"
                    onChangeCallback={value => {
                        const currencyPair = currencyPairs.find(item => item.id === Number(value));
                        dispatch(xpFormChangeField(formContext.formModel + '.currencyPairName', currencyPair?.name));
                    }}
                />}
                {props.showPriceType &&
                <XpFormSelectorInput
                    isRequired inLineLayout noTemplate
                    inLineDivider={66}
                    field="priceType"
                    selectList={priceTypes}
                    itemTextField="name"
                />}
            </div>
            <div>
                <div style={{...columnStyle, marginRight: '10px'}}>
                    <XpFormTextInput
                        inLineLayout isRequired noTemplate alignRight
                        inLineDivider={30}
                        field= "bidMarginBps"
                        parser={value => rateInputParser(value, decDenLangState)}
                    />
                </div>
                <div style={columnStyle}>
                    <XpFormTextInput
                        inLineLayout isRequired noTemplate alignRight
                        inLineDivider={30}
                        field= "askMarginBps"
                        parser={value => rateInputParser(value, decDenLangState)}
                    />
                </div>
            </div>
        </div>
    );
}

PriceMargin.propTypes = {
    className:        PropTypes.string,
};
