import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import LoadingDataSpinner from "gui-common/components/LoadingDataSpinner";
import React from "react";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {rerouteToLogin} from "gui-common/api/apiFunctions";
import {withRouter} from "react-router-dom";

function LostConnectionToApi(props) {
    return (
        <div className="welcomeScreen ">
            <h2 className="centeredText"><XpTranslated trKey={'api.lostConnection.header'}/></h2>
            <p  className="centeredText"><XpTranslated trKey={'api.lostConnection.message'}/></p>
            <LoadingDataSpinner trKey='api.lostConnection.spinnerText'/>
            <hr></hr>
            <WaitingXpoolButton autoFocus labelKey={'topMenu.login'} onClickCallback={() => rerouteToLogin()} />
        </div>
    )
}
export default withRouter(LostConnectionToApi)
