import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AuditEntriesList} from "gui-common/audit/AuditEntriesList"
import {ormModelLoading, ormItemLoading} from "gui-common/orm/ormLoadingSelectors";
import spinnerGif from "gui-common/resources/spinner.gif";
import "gui-common/audit/audit.css"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {globalAuditConfig} from "gui-common/audit/auditConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {loadAuditEntryFromApi} from "gui-common/audit/auditApi";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getAuditEntrySelector} from "gui-common/audit/auditSelectors";
import AuditFilesList from "gui-common/audit/files/AuditFilesList";

export function AuditViewContent(props) {
    const entryGridState     = useSelector(state => selectMyXpGridState(state, {instanceId: props.listInstanceId}));
    const selectedEntryId    = entryGridState ? entryGridState.selectedId : undefined;
    const selectedAuditEntry = useSelectorInstance(getAuditEntrySelector, {selectId: selectedEntryId, auditEntries: props.auditEntries})
    const auditItemsLoading  = useSelector(state => ormModelLoading(state, {ormModel: 'AuditEntry'}));
    const auditItemLoading   = useSelector(state => ormItemLoading(state, {ormModel: 'AuditEntry', itemId: selectedEntryId}));
    const currentApiVersion  = useAppEnvProperty('currentApiVersion');
    const historicAuditOnly  = useAppEnvProperty('historicAuditOnly');
    const dispatch  = useDispatch();
    const devMode            = useAppEnvProperty('devMode');

    useEffect(
        () => {
            if (!selectedAuditEntry) {
                return;
            }
            if (auditItemLoading || selectedAuditEntry.data) {
                return;
            }
            dispatch(loadAuditEntryFromApi(selectedAuditEntry));
        },
        [selectedAuditEntry],
    );

    const useHistoricAudit = useMemo(
        () => {
            return historicAuditOnly || (selectedAuditEntry?.apiVersion !== currentApiVersion);
        },
        [historicAuditOnly, selectedAuditEntry, currentApiVersion]
    );

    const actionConfig = useMemo(
        () => {
            if (!selectedAuditEntry) {
                return null;
            }
            const actionConfig = globalAuditConfig.actions[selectedAuditEntry.action];
            if (!actionConfig) {
                console.warn("No config for audit action", selectedAuditEntry.action, selectedAuditEntry);
                return null;
            }
            return actionConfig;
        },
        [selectedAuditEntry]
    );
    const ActionRenderer = actionConfig?.renderer;
    const propsToRenderer = actionConfig?.propsToRenderer;

    if (auditItemsLoading) {
        return (
            <div xp-test-id="auditEntriesListSpinner">
                <p className="valueText"><XpTranslated trKey='auditEntriesList.loading'/></p>
                <img className="spinner" src={spinnerGif} alt="waiting" />
            </div>
        )
    }
    return (
        <div>
            <AuditEntriesList
                inputData            = {props.auditEntries}
                // itemSelectedCallback = {(id) => auditEntrySelected(id)}
                instanceId           = {props.listInstanceId}
                fillAvailableSpace   = {false}
                rootModel            = {props.rootModel}
                hiddenState          = {props.hiddenState}
                suppressObjectAudit  = {props.suppressObjectAudit}
            />
            <div style={{minHeight: props.contentMinHeight ? props.contentMinHeight : undefined}}>
                {auditItemLoading &&
                <div xp-test-id="auditEntrySpinner" style={{display: 'absolute'}}>
                    <p className="valueText"><XpTranslated trKey="auditView.loadingEntry"/></p>
                    <img className="spinner" src={spinnerGif} alt="waiting" />
                </div>}
                {selectedAuditEntry && actionConfig &&
                <div style={{visibility: !auditItemLoading ? 'visible' : 'hidden'}}>
                    <ActionRenderer
                        actionConfig={actionConfig}
                        auditEntry={selectedAuditEntry}
                        useHistoricAudit={useHistoricAudit}
                        devMode={devMode}
                        {...propsToRenderer}
                    />
                </div>}
                {(selectedAuditEntry?.auditFiles?.length > 0) &&
                    <div>
                        <h3><XpTranslated trKey={'auditFilesList.header'} /></h3>
                        <AuditFilesList
                            auditFiles            = {selectedAuditEntry.auditFiles}
                            // itemSelectedCallback = {(id) => auditEntrySelected(id)}
                            instanceId           = {props.listInstanceId + '-files'}
                            suppressObjectAudit  = {true}
                        />
                    </div>
                }
            </div>
        </div>
    );
}
