import {createReducer} from 'gui-common/functions/reducer.js'
import {isArray} from "lodash";
import {useEffect} from "react";
import {useDispatch} from "react-redux";

export const APPEND_TO_APP_ENV_PARAMETER = 'APPEND_TO_APP_ENV_PARAMETER';
export const SET_APP_ENV_PARAMETER       = 'SET_APP_ENV_PARAMETER';

// Action creators ********************************************************
// ************************************************************************
export function appendToAppEnvParameter(envProp, value) {
    return {type: APPEND_TO_APP_ENV_PARAMETER, payload: {envProp: envProp, value: value}};
}
export function setAppEnvParameter(envProp, value) {
    return {type: SET_APP_ENV_PARAMETER, payload: {envProp: envProp, value: value}};
}

export function useAppEnv(appConfig) {
    const dispatch = useDispatch();
    useEffect(
        () => {
            dispatch(setAppEnvParameter('appConfig', appConfig));
        },
        [],
    );
}


// Reducer functions ********************************************************
// ************************************************************************
function appendToEnvProp(state, payload) {
    if (!payload || !payload.envProp) return state;
    return {
        ...state,
        [payload.envProp]: overlayValueOnState(state[payload.envProp], payload.value)
    }
}
function setEnvProp(state, payload) {
    if (!payload || !payload.envProp) return state;
    return {
        ...state,
        [payload.envProp]: payload.value
    }
}

/*
export const setEnvParameter2 = (env, envParam, setParam, defaultValue) => {
    if (window && window.oxygen_env && window.oxygen_env[envParam] !== undefined) env[setParam] = window.oxygen_env[envParam];
    else env[setParam] = defaultValue;
};
export const getEnvParameter = (envParam) => {
    return (window && window.oxygen_env) ? window.oxygen_env[envParam] : undefined;
};
*/

export function setEnvParameter(paramsObject, windowProp, envProp, defaultValue) {
    if (paramsObject[envProp]) {
        console.warn("Duplicate setting on env property ", envProp);
    }
    paramsObject[envProp] = {windowProp, defaultValue};
}

function overlayValueOnState(state, value) {
    if (state === undefined) {
        return value;
    }
    if (value === undefined) {
        return state;
    }
    if (typeof state !== 'object' && typeof value !== 'object') {
        return value;
    }
    if (isArray(value)) {
        return value;
    }
    const returnObject = {};

    if (typeof value === 'object') {
        for (const key in value) {
            returnObject[key] = overlayValueOnState(state[key], value[key]);
        }
    }
    if (typeof state === 'object') {
        for (const key in state) {
            if (value[key] !== undefined) {
                continue; // Already handled above when traversing value object.
            }
            returnObject[key] = overlayValueOnState(state[key], value[key]);
        }
    }
    return returnObject;
}
function applyEnvParamDefaultValueToState(state, envProp, defaultValue) {
    state[envProp] = overlayValueOnState(state[envProp], defaultValue)
}
function applyWindowConfigToState(state, envProp, windowProp, windowConfig) {
    if (windowConfig.client) {
        state[envProp] = overlayValueOnState(state[envProp], windowConfig.client[windowProp]);
    }
    if (windowConfig.env) {
        state[envProp] = overlayValueOnState(state[envProp], windowConfig.env[windowProp]);
    }
    if (windowConfig.override) {
        state[envProp] = overlayValueOnState(state[envProp], windowConfig.override[windowProp]);
    }
}

function getInitialSession (appConfig) {
    let commonParams = {appConfig: appConfig};
    setEnvParameter(commonParams,'WEB_SOCKET_HOSTS'    , 'webSocketHosts'      ,  []);
    setEnvParameter(commonParams,'WEB_SOCKET_HOST'     , 'webSocketHost'       ,  "");
    setEnvParameter(commonParams,'AUDIT_CONFIG'        , 'auditConfig'         , {activateObjectAuditLog: true});
    setEnvParameter(commonParams,'NO_BACKEND_MODE'     , 'noBackendMode'       , false);
    setEnvParameter(commonParams,'SOCKET_TIMER_DELAY'  , 'socketTimerDelay'    , 500);
    setEnvParameter(commonParams,'DEV_MODE'            , 'devMode'             , false);
    setEnvParameter(commonParams,'NO_FORM_VALIDATION'  , 'noFormValidation'    , false);
    setEnvParameter(commonParams,'HISTORIC_AUDIT_ONLY' , 'historicAuditOnly'   , false);
    setEnvParameter(commonParams,'DEV_PREVENT_RELOAD'  , 'devPreventReload'    , false);
    setEnvParameter(commonParams,'BASE_URL'            , 'baseUrl'             , "");
    setEnvParameter(commonParams,'LOGOUT_URL'          , 'logoutUrl'           , "http://www.nordicfc.se");
    setEnvParameter(commonParams,'USE_HOME_CURRENCY'   , 'useHomeCurrency'     , false);
    setEnvParameter(commonParams,'DEFAULT_HOME_CURRENCY', 'defaultHomeCurrency', 'SEK');
    setEnvParameter(commonParams,'AVAILABLE_TIMEZONES' , 'availableTimeZones'  , [{id: "Europe/Stockholm"}, {id: "Europe/Helsinki"}, {id: "Europe/Copenhagen"}]);
    setEnvParameter(commonParams,'AVAILABLE_LANGUAGES' , 'availableLanguages'  , [{ name: 'English', code: 'en' }, { name: 'Svenska', code: 'sv' }]);
    setEnvParameter(commonParams,'USER_MESSAGES_CONFIG', 'userMessages'        , { useGoToClient: false});
    setEnvParameter(commonParams,'MARKET_SIM_URL'      , 'marketSimBaseUrl'    , "");
    setEnvParameter(commonParams,'MARKET_SIM_CONFIG'   , 'marketSimConfig'     , {pokeMilliSeconds: 2000});
    setEnvParameter(commonParams,'GUI_STYLING'         , 'guiStyling'          , []);
    setEnvParameter(commonParams,'USE_MUI_FIELD_STYLE' , 'useMuiFieldStyle'    , false);
    setEnvParameter(commonParams,'USER_RIGHTS_CONFIG'  , 'userRightsConfig'    , {simpleAssignment: true});
    setEnvParameter(commonParams,'REPORT_CONFIG'       , 'reportConfig'        , {defaultReportCurrency: 'SEK'});
    // setEnvParameter(commonParams, 'FIX_LOG_DEFAULT_FILTER', 'fixLogDefaultFilter', ["^((?!35=S).)*$|^.*35=S.*537=1.*$"])
    setEnvParameter(commonParams, 'FIX_LOG_DEFAULT_FILTER', 'fixLogDefaultFilter', [""]);
    setEnvParameter(commonParams,'BANK_HOLIDAY_CONFIG' , 'bankHolidayConfig'   , {
        minYear: 2010,
        maxYear: 2050,
        fileSpecification: {
            fileExtension: ".csv",
            separator: ';',
            commentLinePrefix: '#',
            encoding: 'UTF-8',
            levelDefinitions: [
                {
                    parentLevelHeadingKey: null,
                    dataProp: 'bankHolidays',
                    columnDefinitions: [
/*
                        {heading: 'Currency' , field: 'currencyId' , type: 'string', mandatory: true, lookUp: 'currencies'},
                        {heading: 'Date'       , field: 'date'       , type: 'date'  , mandatory: true, /!*errorFunctions: {incorrectDateFormat: (date) => (String((new Date(date))) === 'Invalid Date')}*!/},
                        {heading: 'Name'       , field: 'description', type: 'string', mandatory: true},
                        {heading: 'Comment'         , field: 'comment'    , type: 'string', mandatory: false},
*/
                        {heading: 'ISOCurrencyCode' , field: 'currencyId' , type: 'string', mandatory: true, lookUp: 'currencies', ignoreLineOnLookUpFailed: true},
                        // {heading: 'EventYear'       , field: undefined    , type: 'string', mandatory: false},
                        {heading: 'EventDate'       , field: 'date'       , type: 'date'  , mandatory: true, /*errorFunctions: {incorrectDateFormat: (date) => (String((new Date(date))) === 'Invalid Date')}*/},
                        // {heading: 'EventDayOfWeek'  , field: undefined    , type: 'string', mandatory: false},
                        {heading: 'EventName'       , field: 'description', type: 'string', mandatory: true},
                    ]
                }
            ]
        },
    });
    setEnvParameter(commonParams,'AVAILABLE_LEGAL_PERSON_TYPES'  , 'availableLegalPersonTypes'  , [{id: "CORPORATE", default: true}]);
    setEnvParameter(commonParams,'ERP_INTEGRATION_CONFIG'        , 'erpIntegrationConfig'       , {erpEnabled: false, closePopUpTimeout: 3000});
    setEnvParameter(commonParams,'XP_GRID_CONFIG'                , 'xpGridConfig'               , {
        extraGridHeight: 5,
        defaultMaxRows: 10,
        maxRowsOnOrmModel: {
            'User'                  : 15,
            'AuditEntry'            : 20,
            'FxRejectConfiguration' : 15,
        }
    });
    setEnvParameter(commonParams,'RUN_CONFIGURATION_CONFIG'     , 'runConfigurationConfig'    , {
        auditSearchFields: {
            maxElements: 4,
        },
    });

    console.log("Starting with common default parameters :", commonParams);
    console.log("Starting with app default parameters :", appConfig);

    const initialState = {};
    const envPropToWindowProp = {};
    for (const key in commonParams) {
        applyEnvParamDefaultValueToState(initialState, key, commonParams[key].defaultValue);
        envPropToWindowProp[key] = commonParams[key].windowProp;
    }
    for (const key in appConfig) {
        applyEnvParamDefaultValueToState(initialState, key, appConfig[key].defaultValue);
        envPropToWindowProp[key] = appConfig[key].windowProp;
    }
    console.log("Starting with merged default parameters :", initialState);

    const windowConfig = {
        client   : window?.oxyGenClientConfiguration,
        env      : window?.oxyGenEnvConfiguration,
        override : window?.oxyGenConfigurationOverride,
    }
    console.log("Applying windows config :", windowConfig);
    for (const key in envPropToWindowProp) {
        applyWindowConfigToState(initialState, key, envPropToWindowProp[key], windowConfig);
    }
    console.log("Starting with merged env:", initialState);
    return initialState;
}

export const getAppEnvReducer = appConfig => createReducer(getInitialSession(appConfig), {
    [APPEND_TO_APP_ENV_PARAMETER] : appendToEnvProp,
    [SET_APP_ENV_PARAMETER]       : setEnvProp,
});
