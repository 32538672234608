export const DASHBOARD_SET_SIZEINFO = 'DASHBOARD_SET_SIZEINFO';
export const DASHBOARD_DROP_ITEM = 'DASHBOARD_DROP_ITEM';
export const DASHBOARD_CLICK_DROP_ITEM = 'DASHBOARD_CLICK_DROP_ITEM';
export const DASHBOARD_SET_LAYOUT = 'DASHBOARD_SET_LAYOUT';
export const DASHBOARD_REMOVE_COMPONENT = 'DASHBOARD_REMOVE_COMPONENT';
export const DASHBOARD_RELOAD_STATE = 'DASHBOARD_RELOAD_STATE';
export const DASHBOARD_RENAME_COMPONENT = 'DASHBOARD_RENAME_COMPONENT';
export const DRAGGING = 'DRAGGING';
export const DASHBOARD_DRAG_ITEM = 'DASHBOARD_DRAG_ITEM';
export const DASHBOARD_RESET_STATE = 'DASHBOARD_RESET_STATE';
export const DASHBOARD_SET_COMPONENT_STATE = 'DASHBOARD_SET_COMPONENT_STATE';
