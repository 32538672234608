import moment from "moment";

/*
export const listRateFields = {
    id                      : {align: "left"},
    clientName              : {align: "left"},
    clientId                : {align: "left"},
    appId                   : {align: "left"},
    agreementId             : {align: "right"},
    currencyPairStr         : {align: "left"},
    tenor                   : {align: "left"},
    period                  : {align: "left"},
    timeIssued              : {align: "left", isDateTime: true},
    buyPrice                : {align: "right"},
    sellPrice               : {align: "right"},
    buyAmount               : {align: "right"},
    sellAmount              : {align: "right"},
    totalBuyAmount          : {align: "right"},
    totalSellAmount         : {align: "right"},
    timeExpire              : {align: "left", isDateTime: true},
    originalExpire          : {align: "left", isDateTime: true},
    expireReason            : {align: "left"},
    expiredByUserId         : {align: "left"},
    expireComment           : {align: "left"},
    status                  : {align: "left"},
    year                    : {align: "left"},
    month                   : {align: "left"},
    yearMonth               : {align: "left"},
    yearNum                 : {align: "left"},
    monthNum                : {align: "left"},
}
*/

export const listRateMockData = [
    {id:  1, listRateId: 1, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "1H", tenor: "SP", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 4566  , reportCurrencyAmount: 54551  , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2018-11-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 11.94714351},
    {id:  3, listRateId: 1, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "1H", tenor: "SP", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 567656, reportCurrencyAmount: 6781868, counterAmount: 4711, clientReference: "cl ref 7", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2018-11-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 11.94714351},
    {id:  4, listRateId: 1, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "2H", tenor: "SP", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 4566  , reportCurrencyAmount: 54551  , counterAmount: 4711, clientReference: "cl ref 7", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-03", instructedTime: moment("2018-12-23").toISOString(), side: "SELL", clientName: "Svennes Segel", clientId: 35, rate: 11.94714351},
    {id:  5, listRateId: 1, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "2H", tenor: "TD", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 567656, reportCurrencyAmount: 6781868, counterAmount: 4711, clientReference: "cl ref 4", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-02", instructedTime: moment("2018-12-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 11.94714351},
    {id:  6, listRateId: 1, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "2H", tenor: "SN", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 76854 , reportCurrencyAmount: 918186 , counterAmount: 4711, clientReference: "cl ref 4", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-01-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 11.94714351},
    {id:  7, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "1H", tenor: "SN", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 223   , reportCurrencyAmount: 2664   , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-03", instructedTime: moment("2019-01-23").toISOString(), side: "SELL", clientName: "Svennes Segel", clientId: 35, rate: 11.94714351},
    {id:  8, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "2H", tenor: "TD", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 5678  , reportCurrencyAmount: 67836  , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-02-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 11.94714351},
    {id:  9, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "1H", tenor: "SP", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 789765, reportCurrencyAmount: 9435436, counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-02-23").toISOString(), side: "BUY" , clientName: "Kalles kulor" , clientId: 24, rate: 11.94714351},
    {id: 10, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "1H", tenor: "SP", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 12345 , reportCurrencyAmount: 147487 , counterAmount: 4711, clientReference: "cl ref 2", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-02-26").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 11.94714351},
    {id: 11, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 4670032, period: "1H", tenor: "SP", amountCurrency: "GBP", currencyPairStr: "GBP/SEK", amount: 5674  , reportCurrencyAmount: 67788  , counterAmount: 4711, clientReference: "cl ref 3", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-03-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 11.94714351},

    {id: 12, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "1H", tenor: "SP", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 45645 , reportCurrencyAmount: 441037 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2018-12-23").toISOString(), side: "SELL", clientName: "Svennes Segel", clientId: 35, rate: 9.662324},
    {id: 13, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "1H", tenor: "SP", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 56789 , reportCurrencyAmount: 548714 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2018-12-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 9.662324},
    {id: 14, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "8H", tenor: "SP", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 3434  , reportCurrencyAmount: 33180  , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-02", instructedTime: moment("2019-01-23").toISOString(), side: "BUY" , clientName: "Kalles kulor" , clientId: 24, rate: 9.662324},
    {id: 15, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "8H", tenor: "TD", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 356457, reportCurrencyAmount: 3444203, counterAmount: 4711, clientReference: "cl ref 2", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-02", instructedTime: moment("2019-01-23").toISOString(), side: "SELL", clientName: "Kalles kulor" , clientId: 24, rate: 9.662324},
    {id: 16, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "2H", tenor: "TD", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 3433  , reportCurrencyAmount: 33171  , counterAmount: 4711, clientReference: "cl ref 3", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-02", instructedTime: moment("2019-02-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 9.662324},
    {id: 17, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "1H", tenor: "TD", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 46776 , reportCurrencyAmount: 451965 , counterAmount: 4711, clientReference: "cl ref 4", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-03-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 9.662324},
    {id: 18, listRateId: 3, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "1H", tenor: "SP", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 7897  , reportCurrencyAmount: 76303  , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-03-23").toISOString(), side: "SELL", clientName: "Svennes Segel", clientId: 35, rate: 9.662324},
    {id: 19, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "1H", tenor: "SN", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 46776 , reportCurrencyAmount: 451965 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-02", instructedTime: moment("2019-06-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 9.662324},
    {id: 20, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 5591876, period: "1H", tenor: "SP", amountCurrency: "USD", currencyPairStr: "USD/SEK", amount: 7897  , reportCurrencyAmount: 76303  , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-06-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 9.662324},

    {id: 21, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "1H", tenor: "SP", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 78978 , reportCurrencyAmount: 843154 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2018-11-23").toISOString(), side: "SELL", clientName: "Kalles kulor" , clientId: 24, rate: 10.67581074},
    {id: 22, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "1H", tenor: "SP", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 5673  , reportCurrencyAmount: 60564  , counterAmount: 4711, clientReference: "cl ref 5", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2018-11-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 10.67581074},
    {id: 23, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "1H", tenor: "SP", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 78978 , reportCurrencyAmount: 843154 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-01-23").toISOString(), side: "SELL", clientName: "Kalles kulor" , clientId: 24, rate: 10.67581074},
    {id: 24, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "WE", tenor: "TD", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 5673  , reportCurrencyAmount: 60564  , counterAmount: 4711, clientReference: "cl ref 4", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-02", instructedTime: moment("2019-02-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 10.67581074},
    {id: 25, listRateId: 4, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "WE", tenor: "TD", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 24524 , reportCurrencyAmount: 261814 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-02", instructedTime: moment("2019-02-23").toISOString(), side: "BUY" , clientName: "Kalles kulor" , clientId: 24, rate: 10.67581074},
    {id: 26, listRateId: 4, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "WE", tenor: "TD", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 45445 , reportCurrencyAmount: 485162 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-03", instructedTime: moment("2019-03-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 10.67581074},
    {id: 27, listRateId: 4, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "1H", tenor: "SP", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 5688  , reportCurrencyAmount: 60724  , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-03-23").toISOString(), side: "BUY" , clientName: "Kalles kulor" , clientId: 24, rate: 10.67581074},
    {id: 28, listRateId: 4, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "8H", tenor: "SP", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 745   , reportCurrencyAmount: 7953   , counterAmount: 4711, clientReference: "cl ref 2", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-02", instructedTime: moment("2019-04-23").toISOString(), side: "SELL", clientName: "Kalles kulor" , clientId: 24, rate: 10.67581074},
    {id: 29, listRateId: 4, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "8H", tenor: "SP", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 67867 , reportCurrencyAmount: 724535 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-02", instructedTime: moment("2019-04-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 10.67581074},
    {id: 31, listRateId: 4, appId: 'xxxyyy123', amountCurrencyId: 4543826, period: "8H", tenor: "SP", amountCurrency: "EUR", currencyPairStr: "EUR/SEK", amount: 67867 , reportCurrencyAmount: 724535 , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-05-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 10.67581074},

    {id: 32, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "1H", tenor: "SP", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 78978 , reportCurrencyAmount: 85332  , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2018-12-23").toISOString(), side: "SELL", clientName: "Svennes Segel", clientId: 35, rate: 1.08045303},
    {id: 33, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "1H", tenor: "SP", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 7898  , reportCurrencyAmount: 8533   , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-02-23").toISOString(), side: "SELL", clientName: "Svennes Segel", clientId: 35, rate: 1.08045303},
    {id: 34, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "1H", tenor: "TD", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 242   , reportCurrencyAmount: 261    , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-02", instructedTime: moment("2019-02-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 1.08045303},
    {id: 35, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "WE", tenor: "SN", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 2345  , reportCurrencyAmount: 2534   , counterAmount: 4711, clientReference: "cl ref 5", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-02", instructedTime: moment("2019-03-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 1.08045303},
    {id: 36, listRateId: 2, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "1H", tenor: "SP", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 7656  , reportCurrencyAmount: 8272   , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-03", instructedTime: moment("2019-03-23").toISOString(), side: "BUY" , clientName: "Kalles kulor" , clientId: 24, rate: 1.08045303},
    {id: 37, listRateId: 6, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "WE", tenor: "SP", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 234245, reportCurrencyAmount: 253091 , counterAmount: 4711, clientReference: "cl ref 7", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-04-23").toISOString(), side: "BUY" , clientName: "Kalles kulor" , clientId: 24, rate: 1.08045303},
    {id: 38, listRateId: 6, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "1H", tenor: "SP", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 7689  , reportCurrencyAmount: 8308   , counterAmount: 4711, clientReference: "cl ref 1", meansOfPayment: "INVESTMENT", settlementDate: "2020-06-02", instructedTime: moment("2019-04-23").toISOString(), side: "SELL", clientName: "Svea Smide"   , clientId: 23, rate: 1.08045303},
    {id: 40, listRateId: 6, appId: 'xxxyyy123', amountCurrencyId: 5132107, period: "1H", tenor: "SP", amountCurrency: "NOK", currencyPairStr: "NOK/SEK", amount: 7689  , reportCurrencyAmount: 8308   , counterAmount: 4711, clientReference: "cl ref 8", meansOfPayment: "HEDGE"     , settlementDate: "2020-06-03", instructedTime: moment("2019-05-23").toISOString(), side: "BUY" , clientName: "Svea Smide"   , clientId: 23, rate: 1.08045303},
];


/*
const transformerMap = {
    buyPrice        : val => Number(val).toFixed(6),
    sellPrice       : val => Number(val).toFixed(6),
    buyAmount       : val => Number(val).toFixed(2),
    sellAmount      : val => Number(val).toFixed(2),
    totalBuyAmount  : val => Number(val).toFixed(2),
    totalSellAmount : val => Number(val).toFixed(2),
}
*/
export function enrichListRateReportData(item) {
    // applyFieldTransformerMap(transformerMap, item);

/*
    if (item.buyPrice       ) item.buyPriceT        = Number(item.buyPrice       ).toFixed(4);
    if (item.sellPrice      ) item.sellPriceT       = Number(item.sellPrice      ).toFixed(4);
    if (item.buyAmount      ) item.buyAmountT       = Number(item.buyAmount      ).toFixed(2);
    if (item.sellAmount     ) item.sellAmountT      = Number(item.sellAmount     ).toFixed(2);
    if (item.totalBuyAmount ) item.totalBuyAmountT  = Number(item.totalBuyAmount ).toFixed(2);
    if (item.totalSellAmount) item.totalSellAmountT = Number(item.totalSellAmount).toFixed(2);
*/
}
