import React, {useEffect, useMemo} from "react";
import DashboardPage from "gui-common/dashboard/DashboardPage";
import UserMessagesPage from "gui-common/userMessages/UserMessagesPage";
import UserPreferencesForm from "gui-common/userSettings/UserPreferencesForm";
import DataLoading from "gui-common/components/DataLoading";
import FailedDataLoad from "gui-common/api/FailedDataLoad";
import AuthenticationFailed from "gui-common/components/AuthenticationFailed";
import AuditViewPage from "gui-common/audit/AuditViewPage";
import ReportPage from "gui-common/report/ReportPage";
import AdminNoRunDate from "gui-common/noRunDate/AdminNoRunDate";
import FxRejectConfigPage from "gui-common/fxRejectConfig/FxRejectConfigPage";
import CurrencyPairConfigPage from "gui-common/currencyPairSettings/CurrencyPairConfigPage";
import EditTranslationsPage from "gui-common/appLocale/xpTranslated/EditTranslationsPage";
import BankHolidaysPage from "gui-common/bankHolidays/BankHolidaysPage";
import TestPage from "gui-common/app/TestPage";
import LostConnectionToApi from "gui-common/api/LostConnectionToApi";
import {useOrm} from "gui-common/orm/ormHooks";
import {useAppLocale} from "gui-common/appLocale/appLocaleHooks";
import {setGlobalModalsMap} from "gui-common/modals/modalConstants";
import {useEnvStyling} from "gui-common/css/stylingHooks";
// import {setUserRightsConfig} from "gui-common/userRights/userRightsConstants";
import {useSavedUserSettings} from "gui-common/userSettings/userState/userStateThunks";
import {setAuditConfig} from "gui-common/audit/auditConstants";
import {setGlobalRunConfigurationConfig} from "gui-common/runConfiguration/runConfigurationConstants";
import {setGlobalDashboardConfig} from "gui-common/dashboard/dashboardConfig";
import {setGlobalReportConfig} from "gui-common/report/reportConstants";
import {setGlobalWizardConfig} from "gui-common/wizard/wizardConstants";
import {setSimulatorsConfig} from "gui-common/simulators/fxMarketSimulator/fxMarketSimulatorFunctions";
import {selectIsDevMode} from "gui-common/app/appEnvSelectors";
import {useDispatch, useSelector} from "react-redux";
import {setGlobalRunStateConfig} from "gui-common/runState/runStateConstants";
import {useAppUserPreferences} from "gui-common/userSettings/userPreferencesHooks";
import {setProcessStatusConfig} from "gui-common/processStatus/processStatusConstants";
import {dashboardResetState} from "gui-common/dashboard/dashboardReducer";
import {setAppEnvParameter, useAppEnv} from "gui-common/app/getAppEnvReducer";
import AdminUserRightsPage from "gui-common/userRights/AdminUserRightsPage";
import ViewMyUserRights2 from "gui-common/userRights/ViewMyUserRights2";

export function useAppConfig(appConfig) {
    const dispatch = useDispatch();
    useOrm(appConfig.orm);
    useAppLocale(appConfig);
    useMemo(
        () => {
            setGlobalModalsMap(appConfig);
            // setUserRightsConfig(appConfig);
            setAuditConfig(appConfig);
            setGlobalRunConfigurationConfig(appConfig);
            setGlobalRunStateConfig(appConfig);
            setGlobalDashboardConfig(appConfig);
            dispatch(dashboardResetState());
            setGlobalReportConfig(appConfig);
            setGlobalWizardConfig(appConfig);
            setSimulatorsConfig(appConfig);
            setProcessStatusConfig(appConfig);
        },
        []
    );
    useEnvStyling();
    useSavedUserSettings(appConfig);
    useAppUserPreferences(appConfig);
    useAppEnv(appConfig);
}

function addIfUsed(target, isUsed, addArray) {
    if (!isUsed) {
        return;
    }
    target.push(...addArray);
}

export function useRouterPaths(appConfig) {
    const devMode = useSelector(selectIsDevMode);

    return useMemo(
        () => {
            const returnArray = [
                {path: "/userSettings"         , component: UserPreferencesForm},
                {path: "/loadingData"          , component: appConfig.dataLoadComponent ? appConfig.dataLoadComponent :DataLoading},
                {path: "/failedDataLoad"       , component: FailedDataLoad},
                {path: "/connectionDown"       , component: LostConnectionToApi},
                {path: "/authenticationFailed" , component: AuthenticationFailed},
                {path: "/editTranslations"     , component: EditTranslationsPage},
            ];
            addIfUsed(returnArray, appConfig.dashboard                  , [{path: "/dashboard"            , component: DashboardPage}]);
            addIfUsed(returnArray, appConfig.userRights                 , [{path: "/adminUserRights"      , component: AdminUserRightsPage}, {path: "/viewMyUserRights"         , component: ViewMyUserRights2}]);
            addIfUsed(returnArray, appConfig.audit                      , [{path: "/auditView"            , component: AuditViewPage}]);
            addIfUsed(returnArray, appConfig.userMessages               , [{path: "/userMessages"         , component: UserMessagesPage}]);
            addIfUsed(returnArray, appConfig.useNoRunDates              , [{path: "/adminNoRunDates"      , component: AdminNoRunDate}]);
            addIfUsed(returnArray, appConfig.useBankHolidays            , [{path: "/bankHolidays"         , component: BankHolidaysPage}]);
            addIfUsed(returnArray, appConfig.report                     , [{path: "/report"               , component: ReportPage}]);
            addIfUsed(returnArray, appConfig.useCurrencyPairSettings    , [{path: "/currencyPairSettings" , component: CurrencyPairConfigPage}]);
            addIfUsed(returnArray, appConfig.useFxRejectConfig          , [{path: "/fxRejectConfig"       , component: FxRejectConfigPage}]);
            if (devMode) {
                returnArray.push({path: "/testPage"             , component: TestPage})
            }
            return returnArray.concat(appConfig.routerPaths);
        },
        []
    );
}
