import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {pushModalWindow} from "redux-promising-modals";
import {CREATE_UM_DIALOG} from "gui-common/modals/modalConstants";
import {systemRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {topicsSelector, userSelector} from "gui-common/orm/ormSelectors";
import XpDropDownMenuItem from "gui-common/menuComponents/XpDropDownMenuItem";

function CreateUserMessageMenuItem(props) {
    const topics    = useSelector(topicsSelector);
    const user                   = useSelector(userSelector);
    const dispatch  = useDispatch();

    const userCanCreateUserMessage = useMemo(() => {
        for (let topic of topics) if (systemRightAllowed(user, 'UmTopic' + topic.topic , 'Create')) return true;
        return false;
    }, [topics, user]);

    if (!userCanCreateUserMessage) {
        return null;
    }
    return (
        <XpDropDownMenuItem
            toolTipTrKey={'topMenu.generateUserMessage.tooltip'}
            trKey           = {'topMenu.generateUserMessage.menuItem'}
            confirmFunction = {() => dispatch(pushModalWindow(CREATE_UM_DIALOG))}
        />
    )
}
export default CreateUserMessageMenuItem



