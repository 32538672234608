import React, {useEffect, useMemo} from 'react'
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';
import {
    getModifiedDataRightsCollectionSelector,
} from "gui-common/userRights/adminUserRightsSelectors"
import {
    getOrmItemLoadingSelector,
} from "gui-common/orm/ormLoadingSelectors";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {useSelectorInstance} from "gui-common/functions/hooks";
import ComponentLoading from "gui-common/components/ComponentLoading";
import {editUserDataRightsAction, resetUserDataRightsAction} from "gui-common/userRights/adminUserRightsReducer2";
import AdminDataRightsCollection2 from "gui-common/userRights/AdminDataRightsCollection2";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getUserDataRightsFromApi, putUserDataRightsFromApi} from "gui-common/userRights/adminUserRightsApi";


function AdminDataRights2(props) {
    const dispatch  = useDispatch();


    const modifiedDataRightsCollection = useSelectorInstance(getModifiedDataRightsCollectionSelector, {selectId: props.user?.userId})
    const activeDataRightsCollection   = props.user?.dataRights;
    const dataRightsCollectionLoading   = useSelectorInstance(getOrmItemLoadingSelector, {ormModel: 'User', itemId: props.user?.userId});

    useEffect(
        () => {
            if (!props.user) return;
            if (activeDataRightsCollection) return;
            if (dataRightsCollectionLoading) return;
            dispatch(getUserDataRightsFromApi(props.user.userId));
        },
        [props.user, activeDataRightsCollection],
    );

    const isModified = useMemo(() => {
        if (!modifiedDataRightsCollection?.dataRights) return false;
        if (!activeDataRightsCollection?.dataRights) return true;

        for (const modifiedItem of modifiedDataRightsCollection.dataRights)       {
            if (!activeDataRightsCollection.dataRights.find(item => item.id === modifiedItem.id)) return true;
        }
        for (let activeItem  of activeDataRightsCollection.dataRights) {
            if (!modifiedDataRightsCollection.dataRights.find(item => item.id === activeItem.id )) return true;
        }
        return false;
    }, [modifiedDataRightsCollection, activeDataRightsCollection]);

    function saveAndResetModified() {
        dispatch(putUserDataRightsFromApi(modifiedDataRightsCollection))
            .then(res => {
                dispatch(resetUserDataRightsAction(props.user));
            })
    }

    function saveAndActivateDataRights() {
        if (props.user.state !== 'Active') {
            saveAndResetModified();
            return;
        }
        // User is logged in. Request confirmation before saving.
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey:  'userRights.confirmSaveActiveUserModal'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    saveAndResetModified();
                }
                if (status === MODAL_TYPE_CANCEL) {}
            });
    }

    if (dataRightsCollectionLoading) return (
        <div style={{textAlign:'center'}}><ComponentLoading loadingTrKey={'userRights.loadingUser'}/></div>
    );

    if (!activeDataRightsCollection) {
        return(<h3 style={{textAlign:'center'}}><XpTranslated trKey="adminUserRights.noDataRightsAvailable"/></h3>)
    }

    return (
        <div>
            <AdminDataRightsCollection2
                modifiedDataRightsCollection = {modifiedDataRightsCollection}
                activeDataRightsCollection   = {activeDataRightsCollection}
            />
            <hr/>
            <div>
                {!modifiedDataRightsCollection &&
                <WaitingXpoolButton
                    onClickCallback = {() => dispatch(editUserDataRightsAction(activeDataRightsCollection))}
                    labelKey        = 'general.buttons.edit'
                />}
                {modifiedDataRightsCollection &&
                <WaitingXpoolButton
                    onClickCallback = {() => dispatch(resetUserDataRightsAction(props.user))}
                    labelKey        = 'general.buttons.cancel'
                />}
                {modifiedDataRightsCollection &&
                <WaitingXpoolButton
                    onClickCallback = {() => saveAndActivateDataRights()}
                    disabled        = {!isModified}
                    labelKey        = 'adminUserRights.saveAndActivateButton'
                    waiting         = {dataRightsCollectionLoading}
                    floatRight
                />}
            </div>
        </div>
    );
}
AdminDataRights2.propTypes = {
    user       : PropTypes.object.isRequired,
};

export default AdminDataRights2;

