import {clientsSelector} from "features/client/clientSelectors";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {
    getClientAdminFormConfig,
    getMarginGroupAdminFormConfig,
    getPriceToleranceAdminFormConfig
} from "appConfig/xpAdminFormConfig";
import {marginGroupsSelector} from "features/marginGroup/marginGroupSelectors";
import {priceTolerancesSelector} from "features/priceTolerances/priceToleranceSelectors";
import {getHistoricAuditConfig} from "gui-common/audit/auditFunctions";
import AuditStandardActionRenderer from "gui-common/audit/actionRenderers/AuditStandardActionRenderer";

const commonHistoricAuditData = {
    excludeField: {
        API_2_0: ['createdDateTime', 'createdByUser', 'changedDateTime', 'changedByUser', 'disabledDateTime', 'disabledByUser', 'executionRight', 'executionRights', 'userCanSetPoolingParameters'],
        API_3_2: ['createdDateTime', 'createdByUser', 'changedDateTime', 'changedByUser', 'disabledDateTime', 'disabledByUser', 'executionRight', 'executionRights', 'userCanSetPoolingParameters'],
    },
    skipParentLevels: {
        API_2_0: [],
        API_3_2: [],
    }
}
function localGetHistoricAuditConfig(data) {
    return getHistoricAuditConfig(data, commonHistoricAuditData);
}
export const refixAuditModelConfig = {
    Client: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : true,
            selector      : clientsSelector,
            selectorProps : {},
        },
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_3_2', 'API_2_0'],
                excludeFields:    ['legalEntities'],
                skipParentLevels: [],
            }
        ]),
    },
    LegalEntity: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0'],
                excludeFields:    ['legalEntityUnits', 'subsidiaries'],
                skipParentLevels: ['legalEntities'],
            },
            {
                apiVersions: ['API_3_2'],
                excludeFields:    ['legalEntityUnits', 'subsidiaries'],
                skipParentLevels: ['legalEntities'],
            }
        ]),
    },
    LegalEntityUnit: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    ['agreements', 'todayLimitOverrideByUser'],
                skipParentLevels: ['legalEntities', 'legalEntityUnits'],
            }
        ]),
    },
    Agreement: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'clientAudit',getXpAdminFormConfig: getClientAdminFormConfig},
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    [],
                skipParentLevels: ['legalEntities', 'legalEntityUnits', 'agreements'],
            }
        ]),
    },
    MarginGroup: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'marginGroupAudit', getXpAdminFormConfig: getMarginGroupAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : true,
            selector      : marginGroupsSelector,
            selectorProps : undefined,
        },
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    ['id'],
                skipParentLevels: [],
            }
        ]),
    },
    PriceTolerances: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'priceToleranceAudit', getXpAdminFormConfig: getPriceToleranceAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : true,
            selector      : priceTolerancesSelector,
            selectorProps : undefined,
        },
        historicAudit: localGetHistoricAuditConfig([
            {
                apiVersions: ['API_2_0', 'API_3_2'],
                excludeFields:    [],
                skipParentLevels: [],
            }
        ]),
    },
}

export const refixAuditPrototypeMap = {
    'se.nordicfc.refix.common.repository.entity.legal.ClientEntity'          : { model: 'Client' },
    'se.nordicfc.refix.common.repository.entity.legal.LegalEntityEntity'     : { model: 'LegalEntity' },
    'se.nordicfc.refix.common.repository.entity.legal.LegalEntityUnitEntity' : { model: 'LegalEntityUnit' },
    'se.nordicfc.oxygen.pricemargin.repository.entity.PriceMarginGroupEntity': { model: 'MarginGroup' },
    'se.nordicfc.refix.common.repository.entity.listrate.PriceToleranceConfigurationEntity'  : { model: 'PriceTolerances' },
    'se.nordicfc.refix.common.repository.entity.agreement.AgreementEntity'              : { model: 'Agreement' },
    'se.nordicfc.refix.common.repository.entity.agreement.TradeReportAgreementEntity'   : { model: 'Agreement' },
    'se.nordicfc.refix.common.repository.entity.agreement.ListRateAgreementEntity'      : { model: 'Agreement' },
    'se.nordicfc.refix.common.repository.entity.agreement.MarketOrderAgreementEntity'   : { model: 'Agreement' },
    'se.nordicfc.refix.common.repository.entity.agreement.MarketOrderAllAgreementEntity': { model: 'Agreement' },
    'se.nordicfc.refix.common.repository.entity.agreement.PriceFeedAgreementEntity'     : { model: 'Agreement' },
    'se.nordicfc.refix.common.repository.entity.agreement.ReferenceRateAgreementEntity' : { model: 'Agreement' },
};

export const refixAuditActions = { // Must be complemented with all actions in xpool
    ListRateAllAction: "ListRateAllAction",
    ListRateCurrencyAction: "ListRateCurrencyAction",
    ListRateAction: "ListRateAction",
    DispatchTradeAction: "DispatchTradeAction",
    ListRateLeuAction: "ListRateLeuAction",
    TradingLimitOverride: "TradingLimitOverride",
    ListRateAgreementAction: "ListRateAgreementAction",
    TradeReportApiAction: "TradeReportApiAction",
    TradeReportCreateEvent: "TradeReportCreateEvent",
    TradeReportAddToPending: "TradeReportAddToPending",
}

export const refixAuditActionConfig = { // Must be complemented with all actions in xpool
    [refixAuditActions.ListRateAllAction]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.ListRateCurrencyAction]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.ListRateAction]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.ListRateLeuAction]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.DispatchTradeAction]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.TradingLimitOverride]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.ListRateAgreementAction]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.TradeReportApiAction]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.TradeReportCreateEvent]: {
        renderer: AuditStandardActionRenderer,
    },
    [refixAuditActions.TradeReportAddToPending]: {
        renderer: AuditStandardActionRenderer,
    },
}
