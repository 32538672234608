import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";

import {onServiceActivationSwitchChange} from "gui-common/functions/functions";
import EprConnectStatusButton from "gui-common/erpIntegration/ErpConnectStatusButton";
import AdminErpServiceWidget from "gui-common/erpIntegration/AdminErpServiceWidget";
import XpFlexContainer from "gui-common/components/XpFlexContainer";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG, CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useDispatch} from "react-redux";
import {FIND_FX_TRADE_REPORT_ERP_CONFIG_DIALOG} from "appConfig/appModals";
import {
    assignConfigurationToLegalEntityToApi,
    deAssignConfigurationFromLegalEntityToApi
} from "features/fxTradeReport/fxTradeReportApi";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getOrmItemLoadingSelector} from "gui-common/orm/ormLoadingSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";


export function FxTradeReportToErpSettings (props) {
    const leName = useXpFormField('name');
    const serviceActive = useXpFormField('fxTradeReportToErpActive');
    const formContext = useXpFormContext()
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');
    const dispatch = useDispatch();
    const [configId, setConfigID] = useState(undefined);
    const itemLoading   = useSelectorInstance(getOrmItemLoadingSelector, {ormModel: 'FxTradeReportErpConfig', itemId: configId});
    const formConfig = useAppEnvProperty('legalEntityFormConfig');

    useEffect(
        () => {
            if (formContext?.currentData?.fxTradeReportErpConfiguration?.id && configId !== formContext?.currentData?.fxTradeReportErpConfiguration?.id) {
                setConfigID(formContext?.currentData?.fxTradeReportErpConfiguration?.id);
            }
        },
        [formContext?.currentData?.fxTradeReportErpConfiguration],
    );

    if (formDataPriceFeedOnly) {
        return null;
    }
    if (formConfig?.fieldsConfig?.erpConfig?.hide) {
        return null;
    }
    return (
        <div className={props.className}>
            <XpFormFieldGroup
                isExpanded={(serviceActive === true)}
                disableAnimations = {formContext?.formUseState === XP_FORM_VIEW}
                controlComponent={
                    <XpFormSwitchInput
                        field         = "fxTradeReportToErpActive"
                        defaultValue  = {false}
                        onChangeThunk={onServiceActivationSwitchChange(leName)}
                    />
                }
            >
                <XpFlexContainer>
                    <AdminErpServiceWidget
                        erpIntegrationId={formContext?.currentData?.fxTradeReportErpConfiguration?.erpIntegrationId}
                        businessObjectName={formContext?.currentData?.name}
                    />
                    {!formContext?.currentData?.fxTradeReportErpConfiguration ? (
                        <WaitingXpoolButton
                            className       = "xPoolButtonInTable"
                            labelKey ={"erpServiceConfiguration.tradeReport.connectConfiguration.label"}
                            onClickCallback ={() => {
                                dispatch(pushModalWindow(FIND_FX_TRADE_REPORT_ERP_CONFIG_DIALOG,
                                    {
                                        modalParams: {leName: formContext?.currentData?.name},
                                    }))
                                    .then(result => {
                                        if (result.status === MODAL_TYPE_CONFIRM) {
                                            setConfigID(result.selectedConfig?.id);
                                            dispatch(assignConfigurationToLegalEntityToApi(result.selectedConfig, formContext?.currentData, result.comment));
                                        }
                                        if (result.status === MODAL_TYPE_CANCEL) {}
                                    });
                            }}
                            waiting = {itemLoading}
                        />)
                        :
                        (<WaitingXpoolButton
                            className = "xPoolButtonInTable"
                            labelKey  = {"erpServiceConfiguration.tradeReport.disconnectConfiguration.label"}
                            onClickCallback ={() => {
                                dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG,
                                    {
                                        modalKey: "erpServiceConfiguration.tradeReport.disconnectConfiguration.modal",
                                        modalParams: {leName: formContext?.currentData?.name},
                                        commentIsRequired: true,
                                    }))
                                    .then(result => {
                                        if (result.status === MODAL_TYPE_CONFIRM) {
                                            dispatch(deAssignConfigurationFromLegalEntityToApi(formContext.currentData.fxTradeReportErpConfiguration, result.comment));
                                        }
                                        if (result.status === MODAL_TYPE_CANCEL) {}
                                    });
                            }}
                            waiting = {itemLoading}
                        />)
                    }

                </XpFlexContainer>
            </XpFormFieldGroup>
        </div>
    );
}
FxTradeReportToErpSettings.propTypes = {
    className:        PropTypes.string,
};
