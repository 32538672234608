import {selectAppEnvState, useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useMemo} from "react";
import {createSelector} from "reselect";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

export function useRefixServiceIsActive(service) {
    const appServices = useAppEnvProperty('appServices');
    return useMemo(
        () => {
            return appServices ? appServices[service] === true : false;
        },
        [appServices]
    );
}

export function useRefixServices(service) {
    return useAppEnvProperty('appServices');
}

export const getServiceActiveSelector = service => createSelector(
    [selectAppEnvState],
    (appEnvState) => {
        if (!appEnvState?.appServices) {
            return false;
        }
        return (appEnvState?.appServices[service] === true)
    }
);

export const fxUsedSelector = createSelector(
    [selectAppEnvState],
    (appEnvState) => {
        if (!appEnvState?.appServices) {
            return false;
        }
        const isActive = (appEnvState?.appServices[refixServices.ListRates] === true) ||
            (appEnvState?.appServices[refixServices.MarketOrder] === true) ||
            (appEnvState?.appServices[refixServices.PriceFeed] === true) ||
            (appEnvState?.appServices[refixServices.ReferenceRates] === true)

        return isActive;
    }
);
