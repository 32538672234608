import moment from "moment";
import hash from "object-hash";
import {setSavedUserState}                          from "gui-common/userSettings/userState/userStateReducer"
import {setActiveLanguageThunk}                     from "gui-common/appLocale/appLocaleFunctions";
import {setActiveClient}                            from "gui-common/userSettings/userPreferencesReducer";
import {setAmountDecimals, setAmountDenominator}    from "gui-common/numberFormat/numberFormatReducer";
import {genericFetch}                               from "gui-common/api/apiCommon";
import {ormEntitiesGetStarted} from "gui-common/orm/ormReducer";
import {ormEntityClearLoadAll} from "gui-common/orm/ormLoadingReducer";
import {userPreferencesSetProp} from "gui-common/userSettings/userPreferencesReducer";
import {globalUserStatesConfig} from "gui-common/userSettings/userState/userStateConstants";
import {globalApiHandle} from "gui-common/api/apiConstants";


export function processUserSetting(item, dispatch, getState) {
// Check if the received userBlob is mapped to a reload function.
    if (!globalUserStatesConfig[item.name])                return;
    if (!globalUserStatesConfig[item.name].reloadFunction) return;

    let valueObject = {...JSON.parse(item.value), userBlobId: item.id};
    console.log("Got user setting " + item.name + ".", valueObject);

    const userStateHash = hash(valueObject);
    dispatch(setSavedUserState(item.name, userStateHash));
    if (item.name === "userPreferencesState" && valueObject) {
        for (const propName in valueObject) {
            if (propName === 'userBlobId') continue;
            // Set default language if defined by user
            if (propName === 'activeLanguage') {
                dispatch(setActiveLanguageThunk(valueObject.activeLanguage));
                moment.locale(valueObject.activeLanguage);
                continue;
            }
            // Set default amount denominator if defined by user
            if (propName === 'amountDenominator') {
                dispatch(setAmountDenominator(Number(valueObject.amountDenominator)));
                continue;
            }
            // Set default number of decimals if defined by user
            if (propName === 'amountDecimals') {
                dispatch(setAmountDecimals(Number(valueObject.amountDecimals)));
                continue;
            }
            // All props not specifically handled shoudl be handled by the generic prop handler.
            dispatch(userPreferencesSetProp(propName, valueObject[propName]));
        }
        dispatch(setActiveClient({id: valueObject.activeClientId}));
    }
    else {
        dispatch(globalUserStatesConfig[item.name].reloadFunction(valueObject));
    }
}


/* -----------------------------------------------------------------------------------------------------------------
* Functions for fetching all user blobs at application start-up including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function fetchUserSettings() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let customText = "Get user settings " ;
            let requestUrl = globalApiHandle.baseUrls.userPreferences + "?";
            dispatch(ormEntitiesGetStarted('UserSetting'));
            dispatch(genericFetch(
                requestUrl,
                customText,
                fetchUserSettingsResponseHandler,
                userSettingsFailHandler,
                userSettingsFailHandler
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

function fetchUserSettingsResponseHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntityClearLoadAll('UserSetting'));
        if (!resp || resp.length === 0) {
            dispatch(setActiveClient({id: undefined}));
            return;
        }

        // User preferences must be run after other user blobs to override xpGridState
        resp.forEach(item => {if (item.name !== "userPreferencesState") processUserSetting(item, dispatch, getState)});

        const userPreferences = resp.find(item => item.name === "userPreferencesState");
        if (userPreferences) processUserSetting(userPreferences, dispatch, getState);
        else                 dispatch(setActiveClient({id: undefined}));
    }
}
function userSettingsFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntityClearLoadAll('UserSetting'));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for setting user blob attribute including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function postUserSetting(blobId, value) {
    return (dispatch, getState) => {
        // if (selectAppEnvProperty(getState(), 'noBackendMode')) return
        return new Promise((resolve, reject) => {

            let customText = "Set user settings " + blobId + " ";
            let requestUrl = globalApiHandle.baseUrls.userPreferences + "?";
            const jsonValue = JSON.stringify(value);

            dispatch(genericFetch(
                requestUrl,
                customText,
                postUserSettingResponseHandler,
                postUserSettingFailHandler,
                postUserSettingFailHandler,
                'POST',
                {name: blobId, value: jsonValue}
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}
function postUserSettingResponseHandler(resp) {
    return (dispatch, getState) => {
        const savedStateBlobId = getState()[resp.name] ? getState()[resp.name].userBlobId : undefined;
        if (savedStateBlobId && (savedStateBlobId === resp.id)) return; // All OK. No need to reload state with userBlobId.
        dispatch(globalUserStatesConfig[resp.name].reloadFunction({...getState()[resp.name], userBlobId: resp.id}));
    }
}
function postUserSettingFailHandler(resp) {
    return (dispatch, getState) => {
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions for deleting user blob attribute including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function deleteUserSetting(blobId, value) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!value?.userBlobId) {
                resolve();
                return;
            }

            let customText = "Delete user settings " + blobId + " ";
            let requestUrl = globalApiHandle.baseUrls.userPreferences + "/" + value.userBlobId + "?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                deleteUserSettingResponseHandler,
                deleteUserSettingFailHandler,
                deleteUserSettingFailHandler,
                'DELETE',
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}
function deleteUserSettingResponseHandler(resp) {
    return (dispatch, getState) => {
    }
}
function deleteUserSettingFailHandler(resp) {
    return (dispatch, getState) => {
    }
}
