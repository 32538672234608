import {createSelector as createOrmSelector} from "redux-orm";
import {createSelector} from 'reselect'
import {
    createMomentObjectsForProperties,
    createMomentObjectsFromDateTimes,
    getDefaultObject
} from "gui-common/functions/functions";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {activeCountriesSelector} from "gui-common/orm/ormSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {getOrAddCashedObject} from "gui-common/orm/ormFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {translateBoolFields} from "gui-common/xpGrid/xpGridFunctions";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";

/********* LegalEntity selectors *****************************************************************/

export const buildLegalEntityObject = (session, entityId) => {
    const legalEntity = session.LegalEntity.idExists(entityId) ? session.LegalEntity.withId(entityId) : undefined;
    if (!legalEntity) return undefined;

    const obj = legalEntity.ref;

    const client = session.Client.idExists(obj.clientId) ? session.Client.withId(obj.clientId).ref : {};

    const returnObject = {
        ...obj,
        ...createMomentObjectsFromDateTimes(obj),
        client: client,
        fxTradeReportErpConfiguration: legalEntity.fxTradeReportErpConfiguration?.ref,
        legalEntityUnits: legalEntity.legalEntityUnits.all().filter(entityIsActive).toModelArray().map(unit => {
            return {
                client: client,
                legalEntity: legalEntity.ref,
                ...unit.ref,
                ...createMomentObjectsFromDateTimes(unit.ref),
                ...createMomentObjectsForProperties(unit.ref, ['todayLimitOverrideDateTime']),
                agreements: unit.agreements.all().filter(entityIsActive).toModelArray().map(agreement => {
                    return {
                        ...agreement.ref,
                        ...createMomentObjectsFromDateTimes(agreement.ref),
                        ...createMomentObjectsForProperties(agreement.ref, ['suspendedDateTime', 'resumedDateTime']),
                    }
                }),
            }
        })
    };
    return returnObject;
};
export const getLegalEntitySelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (state, props) => props.selectId,
    (session, legalEntityId) => {
        const returnObject = buildLegalEntityObject(session, legalEntityId);
        //        console.log("Selected legal entity:", returnObject);
        return returnObject;
    }
);


export const legalEntitiesSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector legalEntitiesSelector running ");
        let returnArray = [];
        let cashedClients = {};

        const legalEntities = session.LegalEntity.filter(entityIsActive).orderBy('id', 'desc').toModelArray();
        for (let le of legalEntities) {
            // Now, build the legal entity unit
            const leRef = le.ref;

            const client = getOrAddCashedObject(leRef.clientId , 'Client', cashedClients, session);

            returnArray.push({
                ...leRef,
                ...getLastChange('LegalEntity', le),
                client: client,
            });
        }
        // console.log("legalEntitiesSelector done :", returnArray);
        return returnArray;
    }
);

const translate        = (state, props) => selectTranslateFunction(state);
const allLegalEntities = state          => legalEntitiesSelector(state);
const filterFunction   = (state, props) => props ? props.filterFunction : undefined;
const inputData        = (state, props) => props ? props.inputData      : undefined;
export const getLegalEntitiesListDataSelector = () => createSelector(
    [allLegalEntities, translate, filterFunction, inputData],
    (allLegalEntities, translate, filterFunction, inputData) => {
        // console.log("Selector getLegalEntitiesListDataSelector running ");
        const legalEntities = inputData ? inputData : filterFunction ? allLegalEntities.filter(filterFunction) : allLegalEntities;
        let returnArray       = [];
        for (let le of legalEntities) {
            const enrichedLe = {...le};

            translateBoolFields(enrichedLe, translate);
            enrichedLe.countryName = le.country ? le.country.country : "";

            returnArray.push(enrichedLe);
        }
        // console.log("Selected getLegalEntitiesListDataSelector:", returnArray);
        return returnArray;
    }
);


/********* New legal entity template selector *****************************************************************/

const legalPersonTypes = state => selectAppEnvProperty(state, 'availableLegalPersonTypes');
const countries        = state => activeCountriesSelector(state);
const parentItemData   = (state, props) => props ? props.parentItemData : undefined;
export const newLegalEntityFormTemplateSelector = createSelector(
    [legalPersonTypes, countries, parentItemData],
    (legalPersonTypes, countries, parentItemData) => {
        return {
            clientId        : parentItemData ? parentItemData.id : "", // Set clientId from superEntity client if provided.
            legalPersonType : getDefaultObject(legalPersonTypes).id,
            countryId       : getDefaultObject(countries).id,
            id:                     "",
            name:                   "",
            description:            "",
            country:                "",
            hasLEI:                 false,
            lei:                    "",
            useLegalEntityUnits:    false,
            createdDateTime:        null,
            createdByUser:          "",
            changedDateTime:        null,
            changedByUser:          "",
        }
    }
);
