import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx';
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {getLegalEntityUnitDashboardColumns} from "./legalEntityUnitFunctions";
import {
    getBusinessUnitListDataSelector,
    getFxTradeReportDeliveriesForLeuSelector
} from "./legalEntityUnitSelectors";
import {
    createListRatesMenuFunction,
    userCanExpireLeuListRates
} from "features/listRate/listRateFunctions";
import {pushModalWindow} from "redux-promising-modals";
import {TRADING_LIMIT_OVERRIDE_DIALOG} from "appConfig/appModals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";

import {tradingLimitOverrideToApi} from "features/legalEntityUnit/legalEntityUnitApi";
import {stateRequestedOnIdAndProperty} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {
    createListRateAgreementMenuFunction,
    userCanResumeLeuAgreements,
    userCanSuspendLeuAgreements
} from "features/agreement/agreementFunctions";
import {userCanDispatchLeuListRates} from "features/dispatchTrade/dispatchTradeFunctions";
import {userCanOverrideLimit} from "features/legalEntityUnit/legalEntityUnitFunctions";
import {ListRateList} from "features/listRate/ListRateList";
import {AgreementsList} from "features/agreement/AgreementsList2";
import {DispatchTradeList} from "features/dispatchTrade/DispatchTradeList2";
import {ListRateOrdersList} from "features/listRateOrder/ListRateOrdersList";
import {MarketOrdersList} from "features/marketOrder/MarketOrdersList2";
import {useSelectorRef} from "gui-common/functions/hooks";
import {listRateProcessStatusSelector} from "features/listRate/listRateSelectors";
import {FxTradeReportDeliveriesList} from "features/fxTradeReport/FxTradeReportDeliveriesList";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";
import {
    getListRateOrdersForLeuSelector,
} from "features/listRateOrder/listRateOrderSelectors";

export function createTodayLimitOverrideMenuItem(translate, dispatch, legalEntityUnit, legalEntityUnitRequestState) {
    return {
        name: translate("legalEntityUnitsList.gridContextMenuItems.overrideTradingLimit.menuItem"),
        action: function () {
            setTimeout(() => this.context.dispatch(pushModalWindow(TRADING_LIMIT_OVERRIDE_DIALOG, {legalEntityUnit: this.context.legalEntityUnit}))
                .then(({status, todayLimitOverride, comment}) => {
                    if (status === MODAL_TYPE_CONFIRM) {
                        dispatch(tradingLimitOverrideToApi(legalEntityUnit, todayLimitOverride, comment));
                    }
                    if (status === MODAL_TYPE_CANCEL) {}
                }), 50);
        },
        context: {legalEntityUnit: legalEntityUnit, dispatch: dispatch},
        disabled: stateRequestedOnIdAndProperty(legalEntityUnitRequestState, legalEntityUnit.id, 'todayLimitOverride'),
    }
}

export function DashboardBusinessUnitList ({instanceId, setHeightCallback, fillAvailableSpace, suppressRowClickSelection, rowDataSelectorProps}) {

    const moActive = useRefixServiceIsActive(refixServices.MarketOrder);
    const lrActive = useRefixServiceIsActive(refixServices.ListRates);
    const trActive = useRefixServiceIsActive(refixServices.TradeReport);

    const requestingStateOnModelSelector = useMemo(()=>{
        return getRequestingStateOnModelSelector();
    }, [])

    const commentRequiredMap = useSelector(state => state.appEnvState.mandatoryListRateActionComment.businessUnit);

    const listRateRequestState        = useSelector(state => requestingStateOnModelSelector(state, {model: 'ListRate'}))
    const listRateRequestStateRef     = useRef(listRateRequestState); // Needed to transport updated hook to callback scope.
    listRateRequestStateRef.current   = listRateRequestState;

    const legalEntityUnitRequestState        = useSelector(state => requestingStateOnModelSelector(state, {model: 'LegalEntityUnit'}))
    const legalEntityUnitRequestStateRef     = useRef(legalEntityUnitRequestState); // Needed to transport updated hook to callback scope.
    legalEntityUnitRequestStateRef.current   = legalEntityUnitRequestState;

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const listRateProcessStatusRef = useSelectorRef(listRateProcessStatusSelector);

    const dispatch      = useDispatch();

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data)   => {}, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = [];

                const userCanSuspend  = userCanSuspendLeuAgreements(params.node.data);
                const userCanResume   = userCanResumeLeuAgreements( params.node.data);
                const userCanExpire   = userCanExpireLeuListRates(  params.node.data);
                const userCanDispatch = userCanDispatchLeuListRates(   params.node.data);

                const createListRateAgreementMenuItem = (action) =>
                    createListRateAgreementMenuFunction(params.node.data.agreements, params.node.data.listRates, action, {legalEntityUnitId: params.node.data.id, type: 'ListRateAgreement'}, 'legalEntityUnitsList.gridContextMenuItems', params.node.data.name, commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch);
                const createListRateMenuItem = (action) =>
                    createListRatesMenuFunction(params.node.data.listRates, action, {legalEntityUnitId: params.node.data.id, type: 'ListRateAgreement'}, 'legalEntityUnitsList.gridContextMenuItems', params.node.data.name, commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch);

                if (userCanSuspend)  menuItems.push(createListRateAgreementMenuItem('suspend'));
                if (userCanResume)   menuItems.push(createListRateAgreementMenuItem('resume'));
                if (userCanExpire)   menuItems.push(createListRateMenuItem('expire'  ));
                if (userCanDispatch) menuItems.push(createListRateMenuItem('dispatch'));

                if (menuItems.length) menuItems.push("separator");

                const userCanOverrideTradingLimit = userCanOverrideLimit(params.node.data);
                if (userCanOverrideTradingLimit) menuItems.push(createTodayLimitOverrideMenuItem(translateRef.current, dispatch, params.node.data, legalEntityUnitRequestStateRef.current));

                return menuItems;
            }, [translate]),
        rowClassRules: {
            'xp-grid-rejected-order-row' : function(params) {
                return params.data.isSuspended;
                },
            'xp-grid-inactive-row'       : function(params) { return !params.data.isActive && !params.data.isSuspended},
        },
    };

    const gridOptions = useMemo(
        () => {
            const retObj =  {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('legalEntityUnitsList.noItemsToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : getBusinessUnitListDataSelector,
                rowDataSelectorProps    : rowDataSelectorProps,
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'LegalEntityUnit',
                xpDetailRendererProps   : {
                    availableComponents: [
                    ],
                    parentInstanceId: instanceId,
                },
            };
            if (lrActive) {
                retObj.xpDetailRendererProps.availableComponents.push(
                    {
                        detailType: 'listRateAgreements',
                        componentToRender: AgreementsList,
                        propsToComponent: {
                            rowDataSelectorProps: {agreementTypes: ['ListRateAgreement']},
                            rowDataFilterFunction: (item, parentItemData) => {
                                return ( item.legalEntityUnitId === parentItemData.id);
                            },
                        },
                    },
                    {
                        detailType: 'listRates',
                        componentToRender: ListRateList,
                        propsToComponent: {
                            rowDataFilterFunction: (item, parentItemData) => {
                                return ( item.legalEntityUnitId === parentItemData.id);
                            },
                        },
                    },
                    {
                        detailType: 'dispatchTrades',
                        componentToRender: DispatchTradeList,
                        propsToComponent: {
                            rowDataFilterFunction: (item, parentItemData) => {
                                return (item.legalEntityUnitId === parentItemData.id);
                            },
                        },
                    },
                    {
                        detailType: 'listRateOrders',
                        componentToRender: ListRateOrdersList,
                        propsToComponent: {
/*
                            rowDataFilterFunction: (item, parentItemData) => {
                                return (item.legalEntityUnitId === parentItemData.id);
                            },
*/
                            getRowDataSelector: getListRateOrdersForLeuSelector,
                            getRowDataSelectorProps: parentItemData => ({legalEntityUnitId: parentItemData.id}),
                        },
                    })
            }
            if (moActive) {
                retObj.xpDetailRendererProps.availableComponents.push(
                    {
                        detailType: 'MarketOrders',
                        componentToRender: MarketOrdersList,
                        propsToComponent: {
                            rowDataFilterFunction: (item, parentItemData) => {
                                return (item.legalEntityUnitId === parentItemData.id);
                            },
                        },
                    })
            }
            if (trActive) {
                retObj.xpDetailRendererProps.availableComponents.push(
                    {
                        detailType: 'FxTradeReportDeliveries',
                        componentToRender: FxTradeReportDeliveriesList,
                        propsToComponent: {
                            getRowDataSelector: getFxTradeReportDeliveriesForLeuSelector,
                            getRowDataSelectorProps: parentItemData => ({leuId: parentItemData.id}),
                        },
                    }
/*
                    {
                        detailType: 'FxOrderReports',
                        componentToRender: FxOrderReportList,
                        propsToComponent: {
                            getRowDataSelector: getFxOrderReportsForLeuSelector,
                            getRowDataSelectorProps: parentItemData => ({leuId: parentItemData.id}),
                        },
                    }
*/
                    )
            }
            return retObj;
        },
        [translate]
    );
    const colDefs = useMemo(
        () => {
            const businessUnitHiddenState = {
                name                        : false,
                description                 : true,
                id                          : true,

                ...((lrActive || moActive) ? {
                    fxProviderName              : false,
                    fxShortName                 : false,
                } : {}),

                ...(lrActive ? {
                    clientApiChannelT           : false,
                    exemptFromGlobalSuspendT    : false,

                    tradingLimit                : false,
                    tradingLimitCurrencyName    : false,
                    netTradingLimitT            : false,
                    todayLimitOverride          : false,
                    todayLimitOverrideDateTime  : true,
                    todayLimitOverrideByUserId  : true,
                    todayLimitOverrideComment   : true,
                    tradingLimitUtilization     : false,

                    buyAmount                   : false,
                    sellAmount                  : false,
                    timeExpire                  : false,
                    nextDispatchTime            : true,
                } : {}),

                createdDateTime             : true,
                createdByUser               : true,
                changedDateTime             : true,
                changedByUser               : true,
            };
            return getLegalEntityUnitDashboardColumns(translate, businessUnitHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                instanceId={instanceId}
                gridId={"legalEntityUnitDashboardGrid" + instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={setHeightCallback}
                suppressRowClickSelection={suppressRowClickSelection} // This must be a direct propagation of prop since the memoized grid options will no be updated by this prop.
            >
            </XpGrid>
        </div>
    );
}
