import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import { modalStandardParams} from "gui-common/modals/modalConstants";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {FxTradeReportErpConfigurationsList} from "features/fxTradeReport/FxTradeReportErpConfigurationsList";
import {getUnassignedConfigurationsFromApi} from "features/fxTradeReport/fxTradeReportApi";

export function FxTradeReportFindErpConfigModal(props) {
    const dispatch      = useDispatch();
    const [selectedConfig, setSelectedConfig] = useState(undefined);
    const [configurations, setConfigurations] = useState([]);
    const formName = "fxTradeReportFindErpConfigForm"
    const formDataComment = useXpFormField(formName + '.comment');

    function cancelAction() {
        props.popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm(formName));
    }

    function handleSubmit(formData) {
        props.popModalWindow({ status: MODAL_TYPE_CONFIRM , comment: formDataComment, selectedConfig: selectedConfig});
        dispatch(xpFormRemoveForm(formName));
    }

    useEffect(
        () => {
            dispatch(getUnassignedConfigurationsFromApi())
                .then(result => {
                    console.log("Result", result);
                    setConfigurations(result);

                    // setConfigurations([{id: 4711, erpSystem: "Fish system", legalEntityName: "Hej LE", legalEntityNumber: "4711", registerUser: "Kalle kula"}]);
                })
        },
        []
    );

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalLangEdit"}};
    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Find and select fx trade report erp configuration"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div>
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'erpServiceConfiguration.tradeReport.connectConfiguration.modal.header'} trParams={props.modalParams} /></h3>
                        <hr/>
                        <p className="valueTextDark"><XpTranslated trKey={'erpServiceConfiguration.tradeReport.connectConfiguration.modal.message'} trParams={props.modalParams} /></p>
                        <hr/>
                        <FxTradeReportErpConfigurationsList
                            rowData={configurations}
                            configurationSelected={config => {
                                setSelectedConfig(config)
                            }}
                        />
                        {selectedConfig &&
                            <div>Selected: {selectedConfig.legalEntityName}</div>
                        }

                        <div style={{paddingBottom: '30px'}}>
                            <XpForm
                                formModel={formName}
                                formTemplate={"erpServiceConfiguration.tradeReport.connectConfiguration.modal"}
                                onSubmit={(data) => handleSubmit(data)}
                                initialUseState={XP_FORM_EDIT}
                                initialFormData={{comment: ""}}
                            >
                                <XpFormTextAreaInput
                                    field="comment"
                                    isRequired={true}
                                    maxLength={200}
                                />
                            </XpForm>
                        </div>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "erpServiceConfiguration.tradeReport.connectConfiguration.modal.connect"
                                labelParams     = {props.modalParams}
                                onClickCallback = {() => dispatch(xpFormSubmitForm(formName))}
                                disabled={!selectedConfig}
                            />
                            <WaitingXpoolButton
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {cancelAction}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}

