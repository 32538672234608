    import {createReducer} from 'gui-common/functions/reducer.js'

export const USER_STATE_SAVE_STATE   = "USER_STATE_SAVE_STATE";
export const USER_STATE_REMOVE_STATE = "USER_STATE_REMOVE_STATE";
export const USER_STATE_RESET_STATE  = "USER_STATE_RESET_STATE";

// Action creators ********************************************************
// ************************************************************************
export function setSavedUserState(userStateId, saveState) {
    return {type: USER_STATE_SAVE_STATE, payload: {userStateId: userStateId, saveState: saveState}}
}
export function removeSavedUserState(userStateId) {
    return {type: USER_STATE_REMOVE_STATE, payload: {userStateId: userStateId}}
}
export function resetUserStateState() {
    return {type: USER_STATE_RESET_STATE, payload: undefined}
}

// Reducer functions ********************************************************
// ************************************************************************

const userStateInitialState = {
};

function saveUserState(state, payload) {
    let newState = {...state};
    newState[payload.userStateId] = payload.saveState;
    return newState;
}

function removeUserState(state, payload) {
    let newState = {...state};
    newState[payload.userStateId] = undefined;
    return newState;
}

function resetUserState(state, payload) {
    return userStateInitialState;
}

export default createReducer(userStateInitialState, {
    [USER_STATE_SAVE_STATE]   : saveUserState,
    [USER_STATE_REMOVE_STATE] : removeUserState,
    [USER_STATE_RESET_STATE]  : resetUserState,
});
