import {createSelector} from "reselect";
import {useSelectorInstance} from "gui-common/functions/hooks";

export const selectAppEnvState = state => state.appEnvState;

export const selectIsDevMode = createSelector(
    [selectAppEnvState],
    (appEnvState) => {
        if (!appEnvState) return false;
        return (appEnvState.devMode === true)
    }
);
const property = (state, property) => property;
export const getAppEnvPropertySelector = () => createSelector(
    [selectAppEnvState, property],
    (appEnvState, property) => {
        if (!appEnvState) return undefined;
        if (!property) return undefined;
        return appEnvState[property];
    }
);
export const selectAppEnvProperty = getAppEnvPropertySelector();

export function useAppEnvProperty(property) {
    return useSelectorInstance(getAppEnvPropertySelector, property);
}
