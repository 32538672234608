import {useEffect, useMemo} from "react";
import {
    globalOrmConfig
} from "gui-common/orm/ormModels";
import {useDispatch} from "react-redux";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getOrmModelItemSelector} from "gui-common/orm/ormSelectors";
import {getOrmItemFromApi} from "gui-common/orm/ormApi";

export function useOrm(appOrmConfig) {
    useMemo(
        () => {
            if (appOrmConfig.nestedEntities) {
                globalOrmConfig.nestedEntities   = {...globalOrmConfig.nestedEntities  , ...appOrmConfig.nestedEntities};
            }
            if (appOrmConfig.parentProperties) {
                globalOrmConfig.parentProperties   = {...globalOrmConfig.parentProperties  , ...appOrmConfig.parentProperties};
            }
            if (appOrmConfig.compareFunction) {
                globalOrmConfig.compareFunctions   = {...globalOrmConfig.compareFunctions  , ...appOrmConfig.compareFunction};
            }
            if (appOrmConfig.onUpdateThunks) {
                globalOrmConfig.onUpdateThunks   = {...globalOrmConfig.onUpdateThunks  , ...appOrmConfig.onUpdateThunks};
            }
            if (appOrmConfig.modelConfig) {
                globalOrmConfig.modelConfig   = {...globalOrmConfig.modelConfig  , ...appOrmConfig.modelConfig};
            }
        },
        [],
    );
}

export function useLoadParentChildListFromApiIfEmpty(props, getIfTrueFn) {
    const dispatch = useDispatch();
    const parentItemModel = useSelectorInstance(getOrmModelItemSelector, {
        ormModel: props.rowDataSelectorProps.parentModel,
        selectId: props.rowDataSelectorProps.parentId
    });
    useEffect(
        () => {
            if (getIfTrueFn(parentItemModel)) {
                dispatch(getOrmItemFromApi(props.rowDataSelectorProps.parentModel, props.rowDataSelectorProps.parentId));
            }
        },
        [],
    );
}

export function useLoadParentAtFirstRender(props) {
    const dispatch = useDispatch();
    const parentItemModel = useSelectorInstance(getOrmModelItemSelector, {
        ormModel: props.rowDataSelectorProps.parentModel,
        selectId: props.rowDataSelectorProps.parentId
    });
    useEffect(
        () => {
            if (props.loadParentOnFirstRender) {
                dispatch(getOrmItemFromApi(props.rowDataSelectorProps.parentModel, props.rowDataSelectorProps.parentId));
            }
        },
        [],
    );
}
