import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import RenderDomainRights2 from "gui-common/userRights/RenderDomainRights2";

/*
function RenderInstanceRights(props) {
    return (
        <div className="instanceUserRightsBlock">
            {Object.keys(props.instanceRights).map((instanceRight, index) => (
                <div key = {index}>
                    <div className="checkboxTdColumn" style={{display: "inline-block"}}>
                        <input
                            type="checkbox"
                            checked={props.instanceRights[instanceRight] === true}
                            disabled
                        />
                    </div>
                    <label className="labelCheckbox">{instanceRight}</label>
                </div>)
            )}
        </div>
    )
}
*/

function RenderDataRight(props) {

    const domainRights = useMemo(() => {
        const retMap = {...props.dataRight.entitlements};
        return retMap;
    }, [props.dataRight]);

    const header = useMemo(
        () => {
            return (
                <h4><XpTranslated trKey='adminUserRights.dataRightHeader' trParams={{instanceName: props.dataRight.shallowObject?.name}}/></h4>
            );
        }, [props.dataRight]);

    return (
        <div>
            <div className={'instanceUserRightsCard'}>
                {header}
                <div>
                    {domainRights &&
                    <RenderDomainRights2
                        domainRights={domainRights}
                    />}
                </div>
            </div>
        </div>
    );
}

RenderDataRight.propTypes = {
    dataRight : PropTypes.object.isRequired,
};

export default RenderDataRight;
