import {NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {pushModalWindow} from "redux-promising-modals";
import {useDispatch, useSelector} from "react-redux";
import {
    userSelector,
    usersSelector
} from "gui-common/orm/ormSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {SANDBOX_APP_ID_ACTIVATE_DIALOG} from "appConfig/appModals";
import XpDropDownMenuLinkItem from "gui-common/menuComponents/XpDropDownMenuLinkItem";
import XpMainMenuLinkItem from "gui-common/menuComponents/XpMainMenuLinkItem";
import {commonSystemRights} from "gui-common/userRights/userRightsConstants";
import {refixSystemRights} from "appConfig/appUserRights";
import {operatingUnitsWithFullClientCreateSelector} from "features/operatingUnit/operatingUnitSelectors";
import {OverallActionsDropDownMenu} from "features/overallActions/OverallActionsDropDownMenu";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

export const refixLeftMenuItems = [
    <XpMainMenuLinkItem         key={1} targetPath={'/dashboard'} trKey={'topMenu.dashBoard'}/>,
    <OverallActionsDropDownMenu key={2}/>
]

function SandboxActivateAppIdMenuItem() {
    const operatingUnits = useSelector(state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForAdmin'}));
    const isSandbox = useAppEnvProperty('isSandbox');
    const dispatch = useDispatch();

    if (!isSandbox || !operatingUnits?.length) return null;
    return (
        <div>
            <NavDropdown.Item
                key = 'activateAppId'
                onClick={() => dispatch(pushModalWindow(SANDBOX_APP_ID_ACTIVATE_DIALOG, {}))}
            >
                <XpTranslated trKey={'topMenu.sandboxActivateAppId'}/>
            </NavDropdown.Item>
        </div>
    )
}

export function RefixSettingsMenu(props) {
    const user           = useSelector(userSelector);
    const users          = useSelector(usersSelector);

    const moActive = useRefixServiceIsActive(refixServices.MarketOrder);
    const lrActive = useRefixServiceIsActive(refixServices.ListRates);
    const rrActive = useRefixServiceIsActive(refixServices.ReferenceRates);
    const pfActive = useRefixServiceIsActive(refixServices.PriceFeed);
    const trActive = useRefixServiceIsActive(refixServices.TradeReport);

    // const operatingUnitsWithFullClientCreate = useSelectorInstance(getOperatingUnitsWithFullClientCreateSelector, {nestedPropToCheck: 'nestedRightRequiredForWizard'});
    // const showClientCreateWizard = (operatingUnitsWithFullClientCreate.length > 0);

    const userCanAdminUsersConst            = (users && (users.length > 1));  // User endpoint will only return users that the user has rights to admin (and the user itself)

    const userCanReadFxRejectConfig         = userHasSystemRight(user, commonSystemRights.FxRejectConfiguration.Read);
    const userCanManageFxRejectConfig       = userHasSystemRight(user, commonSystemRights.FxRejectConfiguration.Update);
    const userCanManageSystemTexts          = userHasSystemRight(user, commonSystemRights.SystemText.Create);
    const showAuditMenu                     = userHasSystemRight(user, commonSystemRights.Audit.Read) || userHasSystemRight(user, commonSystemRights.SuperAudit.Read);

    const userCanReadCurrencyPairConfig     = userHasSystemRight(user, commonSystemRights.CurrencyPairSettings.Read);
    const userCanManageCurrencyPairConfig   = userHasSystemRight(user, commonSystemRights.CurrencyPairSettings.Update);
    const userCanReadPriceTolerances        = userHasSystemRight(user, refixSystemRights.PriceTolerance.Read)
    const userCanManagePriceTolerances      = userHasSystemRight(user, refixSystemRights.PriceTolerance.Update)
    const userCanManageMarginGroupConfig    = userHasSystemRight(user, refixSystemRights.PriceMarginGroup.Update) || userHasSystemRight(user, refixSystemRights.PriceMarginGroup.Create);
    const userCanReadMarginGroupConfig      = userHasSystemRight(user, refixSystemRights.PriceMarginGroup.Read);

    return (
        <NavDropdown title={<XpTranslated trKey={'topMenu.configDropdown'}/>} id="config-nav-dropdown" alignRight>

            {/*No authorization check on this menu. Only available clients will be visible.*/}
            <XpDropDownMenuLinkItem targetPath={'/clientSettings'}    trKey={'topMenu.clients'}             displayCondition={true}/>
            {/*<XpDropDownMenuLinkItem targetPath={'/refixClientWizard'} trKey={'topMenu.clientCreateWizard'}  displayCondition={showClientCreateWizard}/>*/}
            <XpDropDownMenuLinkItem targetPath={'/auditView'}         trKey={'topMenu.auditView'}           displayCondition={showAuditMenu}/>
            <XpDropDownMenuLinkItem targetPath={'/report'}            trKey={'topMenu.report'}              displayCondition={lrActive || moActive}/>

            <NavDropdown.Divider/>
            <SandboxActivateAppIdMenuItem/>

            <XpDropDownMenuLinkItem targetPath={'/adminUserRights'}      trKey={'topMenu.adminUserRights'}                                                                                   displayCondition={userCanAdminUsersConst}/>
            <XpDropDownMenuLinkItem targetPath={'/fxRejectConfig'}       trKey={userCanManageFxRejectConfig     ? 'topMenu.manageFxRejectConfig'       : 'topMenu.viewFxRejectConfig'}       displayCondition={moActive && (userCanReadFxRejectConfig || userCanManageFxRejectConfig)}/>
            <XpDropDownMenuLinkItem targetPath={'/currencyPairSettings'} trKey={userCanManageCurrencyPairConfig ? 'topMenu.manageCurrencyPairConfig'   : 'topMenu.viewCurrencyPairConfig'}   displayCondition={lrActive &&(userCanReadCurrencyPairConfig || userCanManageCurrencyPairConfig)}/>
            <XpDropDownMenuLinkItem targetPath={'/marginGroupConfig'}    trKey={userCanManageMarginGroupConfig  ? 'topMenu.manageMarginGroupConfig'    : 'topMenu.viewMarginGroupConfig'}    displayCondition={(lrActive || rrActive || pfActive) && (userCanReadMarginGroupConfig || userCanManageMarginGroupConfig)}/>
            <XpDropDownMenuLinkItem targetPath={'/priceToleranceConfig'} trKey={userCanManagePriceTolerances    ? 'topMenu.managePriceToleranceConfig' : 'topMenu.viewPriceToleranceConfig'} displayCondition={lrActive && (userCanReadPriceTolerances || userCanManagePriceTolerances)}/>
            <XpDropDownMenuLinkItem targetPath={'/editTranslations'}     trKey={'topMenu.manageTranslations'}                                                                                displayCondition={userCanManageSystemTexts}/>

        </NavDropdown>
    )
}

