import React, { Component } from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from 'moment';
import {push} from "connected-react-router";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

class WelcomeScreen extends Component {

    gotoWizard() {
        this.props.push("/refixClientWizard");
    }

    render() {
        moment.locale(this.props.currentLanguage);

        return (
            <div className="welcomeScreen ">
                <h2 className="centeredText">{this.props.translate('welcomeScreen.headerNoClient')}</h2>

                <div>
                    <hr></hr>
                    <p className="centeredText">{this.props.translate('welcomeScreen.configMessage1')}</p>
                    <button className="xPoolButton floatMiddle" onClick={() => this.gotoWizard()} autoFocus>
                        {this.props.translate('welcomeScreen.startWizardButton')}
                    </button>
                    <p className="centeredText">{this.props.translate('welcomeScreen.configMessage2')}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translate:          selectTranslateFunction(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        push:               (loadPath)  => dispatch(push(loadPath))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen));


