import {isNumber, isString} from "gui-common/functions/functions";
import moment from "moment";

export function aggregateOverDimensions(data, dimensions) {
    if (!Array.isArray(data)) {
        console.error("Incorrect data type in aggregateOverDimensions",data);
        return null;
    }
    if (data.length === 0) {
        console.error("No data in data array in aggregateOverDimensions",data);
        return null;
    }
    if (!Array.isArray(dimensions)) {
        console.error("Incorrect dimensions array type in aggregateOverDimensions",dimensions);
        return null;
    }
    if (dimensions.length === 0) {
        console.error("No dimension in dimensions array.");
        return null;
    }
    const thisDimension = dimensions[0]; // aggregate on first dimension in array
    if (!isString(thisDimension.dimension)) {
        console.error("Incorrect dimension type in aggregateOverDimensions",thisDimension.dimension);
        return null;
    }

    if (thisDimension.aggregators) {
        if (typeof thisDimension.aggregators !== 'object') {
            console.error("Incorrect aggregates type in aggregateOverDimensions",thisDimension.aggregators);
            return null;
        }
        for (let key in thisDimension.aggregators) {
            if (typeof thisDimension.aggregators[key] !== 'function') {
                console.error("Incorrect aggregate type in aggregateOverDimensions",thisDimension.aggregators[key]);
                return null;
            }
        }
    }

    if (data[0][thisDimension.dimension] === undefined) {
        console.error("Dimension not in data in data array in aggregateOverDimensions",thisDimension.dimension,data);
        return null;
    }

    let returnObject = {
        dimension       : thisDimension.dimension,
        levelNumber     : (dimensions.length),
        buckets         : {},
        totalAggregates : {},
    };
    if (thisDimension.aggregators) {
        for (let key in thisDimension.aggregators) returnObject.totalAggregates[key] = 0;
    }

    for (let item of data) {

        const dimInstance = item[thisDimension.dimension];
        if (!dimInstance) {
            console.error("No dimension value in data item in aggregateOverDimensions",item,thisDimension.dimension);
            return null;
        }

        const thisItemsAggregates = {};
        if (thisDimension.aggregators) {
            for (let key in thisDimension.aggregators) {
                const valueToAdd = thisDimension.aggregators[key](item);
                if (!isNumber(valueToAdd)) {
                    console.error("Incorrect type of calculated aggregate in aggregateOverDimensions", valueToAdd);
                    return null;
                }
                thisItemsAggregates[key] = valueToAdd;
                returnObject.totalAggregates[key] += valueToAdd;
            }
        }

        if (returnObject.buckets[dimInstance]) {
            for (let key in thisItemsAggregates) {
                returnObject.buckets[dimInstance].aggregates[key] += thisItemsAggregates[key];
            }
            returnObject.buckets[dimInstance].data.push(item);
        }
        else {
            returnObject.buckets[dimInstance] = {
                aggregates  : {},
                name : dimInstance,
                data : [item],
            };
            for (let key in thisItemsAggregates) {
                returnObject.buckets[dimInstance].aggregates[key] = thisItemsAggregates[key];
            }
        }
    }
    if (dimensions.length > 1) { // Create subDimension
        for (let bucketName in returnObject.buckets) {
            let thisBucket = returnObject.buckets[bucketName];
            thisBucket.subDimension = aggregateOverDimensions(thisBucket.data, dimensions.slice(1))
        }
    }
    else {
        returnObject.lastDimension = true;
    }
    return returnObject;
}

function addYearMonthToItem(item, dateSortField) {
    if (!item || !item[dateSortField]) return;
    let momentItem = moment(item[dateSortField]);
    item.yearMonth  = momentItem.format('MMMM') + ' ' + momentItem.format('YYYY');
    item.year       = momentItem.format('YYYY') + ' ';
    item.month      = momentItem.format('MMMM') + ' ';
    item.yearNum    = momentItem.year();
    item.monthNum   = momentItem.month();
}
const sortReportData = (a, b) => {
    if (a.yearNum < b.yearNum) return 1;
    if (a.yearNum > b.yearNum) return -1;
    if (a.monthNum < b.monthNum) return 1;
    if (a.monthNum > b.monthNum) return -1;
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
    return 0;
};

export function enrichAndSortReportData(dataArray, dataSetConfig) {
    let outArray = [];

    for (let item of dataArray) {
        addYearMonthToItem(item, dataSetConfig.dateSortField);
        outArray.push(item);
    }
    outArray.sort(sortReportData);
    return outArray;
}

export function transformReportData(dataArray, activeLanguage, dataSetConfig, fieldConfig) {
    let outArray = [];

    for (let item of dataArray) {
        const enrichedItem = {...item};
        for (let key in fieldConfig) {
            if (!enrichedItem[key]) continue;
            if (fieldConfig[key].toFixedDecimals) enrichedItem[key] = Number(enrichedItem[key]).toFixed(fieldConfig[key].toFixedDecimals);
            if (fieldConfig[key].transformerFunction) enrichedItem[key] = fieldConfig[key].transformerFunction(enrichedItem[key]);
        }
        if (dataSetConfig && dataSetConfig.dataEnrichmentFunction) dataSetConfig.dataEnrichmentFunction(enrichedItem);
        outArray.push(enrichedItem);
    }
    return outArray;
}

export function prepareReportGridColumns(fieldConfig, reportData) {

    if (!reportData || !reportData.length) return [];

    let subArray = [];
    const templateRecord = reportData[0];
    const dateTimeProps = {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}}

    const colCreate = (colName, width, minWidth, extraProps) => {
        subArray.push({
            colId: colName,
            field: colName,
            hide: false,
            width: width,
            minWidth: minWidth,
            headerName: colName, ...extraProps
        })
    };

    for (let key in templateRecord) {
        const recordWithFieldNotNull = reportData.find(item => item[key] !== null);


        const config = (fieldConfig && fieldConfig[key]) ? fieldConfig[key] : {};

        let cellStyle = {textAlign: (recordWithFieldNotNull && (typeof recordWithFieldNotNull[key] === "number")) ? 'right' : 'left'}

        colCreate(key, 100, 50,
            {
                cellStyle: {...cellStyle, ...config.cellStyle},
                ...(config.isDateTime ? dateTimeProps : {})
            });
    }

    return subArray;
}

export function applyFieldTransformerMap(transformerMap, item) {
    for (let fieldName in transformerMap) {
        if (item[fieldName] === undefined) continue;
        item[fieldName] = transformerMap[fieldName](item[fieldName]);
    }
}
