import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {useResizeObserver, useSelectorInstance, useSelectorRef} from "gui-common/functions/hooks";
import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {
    deliveryStates,
} from "gui-common/fxTradeReport/fxTradeReportConstants";
import {FxTradeReportEventsList} from "features/fxTradeReport/FxTradeReportEventsList";
import {
    getFxTradeReportDeliveriesSelector,
    getFxTradeReportEventsForDeliverySelector
} from "features/fxTradeReport/fxTradeReportSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {instanceRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {refixDataRights} from "appConfig/appUserRights";
import {useDispatch} from "react-redux";
import {fxTradeReportDeliveryStates, fxTradeReportRequestTypes} from "features/fxTradeReport/fxTradeReportConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {requestAddAllInDeliveryToPendingToApi} from "features/fxTradeReport/fxTradeReportApi";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import RequestHistoricDeliveriesPane from "features/fxTradeReport/RequestHistoricDeliveriesPane";
import {getOrmItemFromApi} from "gui-common/orm/ormApi";
import {getOrmModelLoading} from "gui-common/orm/ormLoadingSelectors";


FxTradeReportDeliveriesList.detailIcon = 'local_shipping';
FxTradeReportDeliveriesList.detailIconToolTipKey = 'fxTradeReportDeliveriesList.detailIconToolTipKey';
export function FxTradeReportDeliveriesList (props) {
    const [requestDeliveriesPaneHeight, setRequestDeliveriesPaneHeight] = useState(0);
    const [gridHeight, setGridHeight] = useState(0);

    const setHeightCallback = useCallback(box => {
        // console.log("Callback from resize observer: ", box);
        setRequestDeliveriesPaneHeight(box.height)
    }, [])

    const containerRef = useResizeObserver(setHeightCallback);

    const translateRef = useSelectorRef(selectTranslateFunction)
    const dispatch = useDispatch();
    const mandatoryComments = useAppEnvProperty('mandatoryTradeReportActionComment');

    const modelLoading = useSelectorInstance(getOrmModelLoading, {ormModel: 'FxTradeReportDelivery'});


    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((params) => {}, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                let menuItems = [];

                const delivery = params.node.data;

                const userCanAddToPending = instanceRightAllowed(delivery.agreement, refixDataRights.TradeReportAgreement.AddEventsToPending);
                if (userCanAddToPending) menuItems.push({
                    name: translateRef.current("fxTradeReportDeliveriesList.gridContextMenuItems.addAllToPending.menuItem"),
                    action: () => setTimeout(() => {
                        dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG, {modalKey: 'fxTradeReportDeliveriesList.gridContextMenuItems.addAllToPending', modalParams: {shortName: delivery.fxShortName, appId: delivery.appId}, commentIsRequired: mandatoryComments?.addEventsToPending?.allInDelivery}))
                            .then(({status, comment}) => {
                                if (status === MODAL_TYPE_CONFIRM) {
                                    dispatch(requestAddAllInDeliveryToPendingToApi(delivery.id, comment));
                                }
                            })
                    }, 50),
                    disabled: delivery.nbrOfTradeReportEvents === 0 ||delivery.status === fxTradeReportDeliveryStates.PENDING,
                });

                menuItems.push({
                    name: translateRef.current("fxTradeReportDeliveriesList.gridContextMenuItems.refreshDelivery.menuItem"),
                    action: () => dispatch(getOrmItemFromApi('FxTradeReportDelivery', params.node.data?.id)),
                    disabled: !params.node.expanded || (modelLoading[params.node.data?.id] && modelLoading[params.node.data?.id].loadingUpdate),
                });

                return menuItems;
            }, [translateRef.current, modelLoading]),
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translateRef.current('fxTradeReportDeliveriesList.noItemsToShow'),
                fillAvailableSpace      : (props.fillAvailableSpace === undefined) ? true : props.fillAvailableSpace,
                groupDefaultExpanded    : 0,
                getRowDataSelector      : props.getRowDataSelector ? props.getRowDataSelector : getFxTradeReportDeliveriesSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'FxTradeReportDelivery',
                xpDetailRendererProps   : {
                    availableComponents: [
                        {
                            detailType: 'FxTradeReportEvent',
                            componentToRender: FxTradeReportEventsList,
                            propsToComponent: {
                                loadParentOnFirstRender: true,
                                getRowDataSelector: getFxTradeReportEventsForDeliverySelector,
                                getRowDataSelectorProps: parentItemData => ({deliveryId: parentItemData.id}),
                            },
                        },
                    ],
                    parentInstanceId: props.instanceId,
                },
            };
        },
        [translateRef.current, props.rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            const columnDefs = [];

            xpColCreate(translateRef.current, columnDefs, false,'id'           , 100, 50 , 'fxTradeReportDeliveriesList.headers.id', {cellRenderer: 'agGroupCellRenderer', lockVisible: true, lockPosition: true});
            xpColCreate(translateRef.current, columnDefs, false,'appId'        , 200, 100, 'fxTradeReportDeliveriesList.headers.appId');
            xpColCreate(translateRef.current, columnDefs, false,'fxShortName'  , 100, 50, 'fxTradeReportDeliveriesList.headers.fxShortName');
            xpColCreate(translateRef.current, columnDefs, false,'requestTypeT'      , 200, 50 , 'fxTradeReportDeliveriesList.headers.requestType', {
                cellRenderer: 'xpTranslatedRenderer',
                cellRendererParams: {
                    filterTrKeys: Object.keys(fxTradeReportRequestTypes).map(key => 'fxTradeReportDeliveriesList.requestTypes.' + key)
                },
                filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
            });
            xpColCreate(translateRef.current, columnDefs, false,'statusT'      , 200, 50 , 'fxTradeReportDeliveriesList.headers.status', {
                cellRenderer: 'xpTranslatedRenderer',
                cellRendererParams: {
                    filterTrKeys: Object.keys(deliveryStates).map(item => 'fxTradeReportDeliveriesList.statusTypes.' + deliveryStates[item])
                },
                filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true},
                cellStyle: (params) => {
                    if (params.data.status === deliveryStates.DELIVERED)  return {background: 'rgba(178,255,183,0.5)'};
                    if ([deliveryStates.PROCESSING, deliveryStates.CANCELLED, deliveryStates.SUSPENDED].includes(params.data.status)) return {background: 'rgba(250,255,152,0.5)'};
                    if (params.data.status === deliveryStates.FAILED)    return {background: 'rgba(255,150,130,0.5)'};
                    return {background: 'none'};
                }
            });
            // xpColCreate(translateRef.current, columnDefs, false,'nbrOfTradeReports'    , 200, 50 , 'fxTradeReportDeliveriesList.headers.nbrOfTradeReports', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
            xpColCreate(translateRef.current, columnDefs, false,'createdDateTime' , 400, 100, 'fxTradeReportDeliveriesList.headers.createdDateTime');
            // xpColCreate(translateRef.current, columnDefs, false,'scheduledDeliveryDateTime' , 400, 100, 'fxTradeReportDeliveriesList.headers.scheduledDeliveryDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
            xpColCreate(translateRef.current, columnDefs, false,'deliveredDateTime' , 400, 100, 'fxTradeReportDeliveriesList.headers.deliveryDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});

            return columnDefs;
        },
        [translateRef.current]
    );

    useEffect(
        () => {
            if (props.setHeightCallback) {
                props.setHeightCallback(gridHeight + requestDeliveriesPaneHeight)
            }
        },
        [requestDeliveriesPaneHeight],
    );

    return (
        <div style={{width: "100%", height: "calc(100% - " + requestDeliveriesPaneHeight + "px"}}>
            <div ref={containerRef}>
                <XpAccordion
                    instanceId={props.gridId ? props.gridId : "fxTradeReportDeliveriesGrid-" + props.instanceId}
                    headerKey={'fxTradeReportDeliveriesList.requestDeliveries.header'}
                    headerClassName="textGenerationsHeader" defaultShowState={false}
                >
                    <RequestHistoricDeliveriesPane/>
                </XpAccordion>
            </div>
            <XpGrid
                gridId={props.gridId ? props.gridId : "fxTradeReportDeliveriesGrid-" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                rowData={props.rowData}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={gridHeight => {
                    if (props.setHeightCallback) {
                        props.setHeightCallback(gridHeight + requestDeliveriesPaneHeight)
                    }
                    setGridHeight(gridHeight);
                }}
            >
            </XpGrid>
        </div>
    );
}
