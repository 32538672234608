import PropTypes from "prop-types";
import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {pushModalWindow} from "redux-promising-modals";
import {ormModelLoadingNew} from "gui-common/orm/ormLoadingSelectors";

import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {postOrmItemToApi, putOrmItemToApi} from "gui-common/orm/ormApi";
import {addSavedUserState} from "gui-common/userSettings/sharedSettings/savedUserStatesReducer";
import {userSelector} from "gui-common/orm/ormSelectors";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import moment from "moment";
import {SAVE_SETTINGS_DIALOG} from "gui-common/modals/modalConstants";
import {globalUserStatesConfig} from "gui-common/userSettings/userState/userStateConstants";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {commonSystemRights} from "gui-common/userRights/userRightsConstants";

function SaveUserStateButton ({labelKey, saveShared, toolTipKey}) {

    const translate         = useSelector(selectTranslateFunction);
    const globalState       = useSelector(state => state);
    const sharedListLoading = useSelector(state => ormModelLoadingNew(state, {ormModel: 'SharedSetting'}));
    const user              = useSelector(state => userSelector(state));

    const dispatch = useDispatch();

    const userCanCreateSharedSetting = userHasSystemRight(user, commonSystemRights.SharedSetting.Create);
    if ((saveShared===true) && !userCanCreateSharedSetting) return null;

    function createSaveState(name, saveShared) {
        let savedArray = [];
        for (let userStateId in globalUserStatesConfig) {
            if (userStateId === 'savedUserStatesState') continue;
            if (userStateId === 'userPreferencesState') continue;
            savedArray.push({
                name : userStateId,
                value: JSON.stringify(globalState[userStateId]),
            });
        }
        return {name: name, value: JSON.stringify(savedArray), createdDateTime: moment()};
    }

    function saveSettingsModal () {

        const message = (saveShared !== undefined) ? translate('savedUserStates.saveSettingsModal.message1') : translate('savedUserStates.saveSettingsModal.message2');
        dispatch(pushModalWindow(SAVE_SETTINGS_DIALOG,
            {
                headerText        : translate('savedUserStates.saveSettingsModal.header'),
                messageText       : message,
                showSaveOptions   : ((saveShared === undefined) && userCanCreateSharedSetting),
                defaultSaveOption : (saveShared === true)
            }))
            .then((result) => {
                if (result.status === MODAL_TYPE_CONFIRM) {
                    const settings = createSaveState(result.settingName, result.saveShared);

                    if (result.saveShared) {
                        if (result.decision === "save")      dispatch(postOrmItemToApi('SharedSetting', settings)).catch(()=>{});
                        if (result.decision === "overwrite") dispatch(putOrmItemToApi('SharedSetting', {...settings, id: result.duplicate.id})).catch(()=>{});
                    }
                    else {
                        dispatch(addSavedUserState(settings));
                    }
                }
                if (result.status === MODAL_TYPE_CANCEL) {}
            });
    }

    return (
        <WaitingXpoolButton
            labelKey        = {labelKey}
            onClickCallback = {saveSettingsModal}
            waiting         = {sharedListLoading}
            toolTipKey      = {toolTipKey}
        />
    )
}
SaveUserStateButton.propTypes = {
    labelKey        : PropTypes.string.isRequired,
    saveSettings    : PropTypes.func,
    toolTipKey      : PropTypes.string,
    saveShared      : PropTypes.bool,
};
export default SaveUserStateButton
