import React, {useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpIconButton from "gui-common/components/XpIconButton";
import RunConfigurationForm from "gui-common/runConfiguration/RunConfigurationForm";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {useRunConfigurationConfig} from "gui-common/runConfiguration/runConfigurationFunctions";
import {useXpFormContext, useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormAddElement, xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import {xpFormIsReadOnly} from "gui-common/xpForm/core/xpFormFunctions";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

function RunConfigurationsList (props) {

    const dispatch  = useDispatch();
    // const user      = useSelector(userSelector);
    const formContext = useXpFormContext();
    const runTypeConfig    = useRunConfigurationConfig(props.type);

    const runConfigurationConfig = useAppEnvProperty( 'runConfigurationConfig');

    const dependentFields  = useXpFormFields({dependentFields: {...runTypeConfig.listDependentFields, runConfigurationElements: 'runConfiguration.' + props.type}});

    const selectedHideRunType = useSelectorInstance(runTypeConfig.getHideRunTypeSelector, {formContext: formContext, dependentFields: dependentFields})

    const [createdIndex, setCreatedIndex] = useState([]);

    const currentRunConfigurationElements = useMemo(
        () => {
            return !props.auditMode ? dependentFields?.runConfigurationElements : formContext?.currentData?.runConfiguration ? formContext?.currentData?.runConfiguration[props.type] : undefined;
        },
        [props.auditMode, formContext?.currentData, dependentFields?.runConfigurationElements]
    );

    const propsAccepted = useMemo(
        () => {
            if (
                !props.type
                || !runTypeConfig
            ) {
                console.error("Invalid props in RunConfigurationsList: ", props);
                return false;
            }
            return true;
        },
        []
    );

    const runConfigurationTypeFormTemplate = useMemo(
        () => {
            if (!runTypeConfig) return undefined;
            return runTypeConfig.getFormTemplate(props) + '.' + props.type;
        },
        []
    );

    const runConfigurationElementsAuditBeforeChange = useMemo(
        () => {
            if (!props.auditMode || !formContext?.beforeChangeData?.runConfiguration) return undefined;
            const beforeChangeArray = formContext.beforeChangeData.runConfiguration[props.type];
            if (!beforeChangeArray?.length              ) return undefined;

            // When in audit mode, the elements may differ in count and order between beforeChange and form. Must sort and add deleted to form and current when in audit mode.
            let returnData = [];

            if (currentRunConfigurationElements?.length) {
                currentRunConfigurationElements.forEach(formItem => {
                    returnData.push(beforeChangeArray ? beforeChangeArray.find(beforeItem => formItem.id === beforeItem.id) : undefined);
                });
            }

            beforeChangeArray.forEach(beforeItem => {
                if (returnData.find(returnItem => returnItem?.id === beforeItem.id)) return;
                returnData.push({...beforeItem, isDeleted: true});
            })
            return returnData;
        },
        [currentRunConfigurationElements, props.auditMode, formContext?.beforeChangeData]
    );

    // In audit mode, there may be elements in before change that are not present in the current data array rendered and loaded into xpForm.
    // These missing items must be added to the form as deleted items.
    const runConfigurationElementsToRender = useMemo(
        () => {
            let returnData = currentRunConfigurationElements?.length ? [...currentRunConfigurationElements] : [];
            if (props.auditMode && runConfigurationElementsAuditBeforeChange?.length) {
                runConfigurationElementsAuditBeforeChange.forEach(beforeItem => {
                    if (currentRunConfigurationElements && currentRunConfigurationElements.find(currentItem => currentItem?.id === beforeItem?.id)) return;
                    returnData.push({...beforeItem, isDeleted: true});
                })
            }
            return returnData;

        },
        [currentRunConfigurationElements, runConfigurationElementsAuditBeforeChange, props.auditMode]
    );


    const elementsRenderClassArray = useMemo(
        () => {
            if (!runConfigurationElementsToRender || !Array.isArray(runConfigurationElementsToRender)) return [];
            return runConfigurationElementsToRender.map((element, index) => {
                if (runTypeConfig.fixedFormCardClassName)           return runTypeConfig.fixedFormCardClassName;
                if (!element.id)                                    return 'adminEntityCardNew';
                if ( element.isDeleted)                             return 'adminEntityCardDisabled';
                if (!props.auditMode)                               return "";
                if (!runConfigurationElementsAuditBeforeChange || !runConfigurationElementsAuditBeforeChange[index])  return 'adminEntityCardNew'
                return "";
            });
        },
        [runConfigurationElementsToRender]
    );


    const numberOfActiveElements = useMemo(
        () => {
            if (!currentRunConfigurationElements || !Array.isArray(currentRunConfigurationElements)) return 0;
            const numberOfActiveElements = currentRunConfigurationElements.filter(item => {
                if (item.isDeleted) return false;
                if (!item.ownedByParent) return true;
                return item.includedInRunConfiguration;
            })
            return numberOfActiveElements.length;
        },
        [currentRunConfigurationElements]
    );

    const noActiveRunConfigurationElementsDefined = numberOfActiveElements === 0;

    const maxElementsReached = useMemo(
        () => {
            if (noActiveRunConfigurationElementsDefined) return false;
            if (!runConfigurationConfig[props.type]?.maxElements) return false;
            return (numberOfActiveElements >= runConfigurationConfig[props.type]?.maxElements);
        },
        [numberOfActiveElements]
    );

    const hideConfigType = useMemo(
        () => {
            if (runConfigurationConfig[props.type] && runConfigurationConfig[props.type].isDisabled) return true;
            if (runTypeConfig.hideConfigType && runTypeConfig.hideConfigType(formContext, dependentFields)) return true;
            if (selectedHideRunType === true) return true;
            if (!props.isSubForm) return false;

            if (!runConfigurationElementsToRender) return true; // Nothing defined for the level. Hide.
            if (runConfigurationElementsToRender.some(item => (!item.isDeleted && !item.ownedByParent))) return false; // There is at least one element defined on this level.
            if (runConfigurationElementsToRender.some(item => (item.ownedByParent && !item.includedInRunConfiguration))) return false; // There is at least one inherited element that is excluded on this level.
            return true; // No configurations on this level. Hide for embedded subforms.
        },
        [props.isSubForm, runConfigurationElementsToRender, dependentFields, formContext]
    );

    const userCanManage = useMemo(
        () => {
            if (formContext?.formUseState === XP_FORM_NEW) return true;
            return runTypeConfig.userCanManage(formContext?.rootCurrentData);
        },
        [formContext?.rootCurrentData, formContext?.formUseState]
    );


    function addRunConfiguration() {
        setCreatedIndex([...createdIndex, currentRunConfigurationElements ? currentRunConfigurationElements.length : 0]);
        dispatch(xpFormAddElement(formContext.formModel + '.runConfiguration.' + props.type, runTypeConfig.newTemplate));
    }
    function addRemoveRunConfiguration(index, runConfigElement) {
        let newArray = [...currentRunConfigurationElements];

        if (runConfigElement.ownedByParent) {
            newArray[index] = {...runConfigElement, includedInRunConfiguration: !runConfigElement.includedInRunConfiguration}
        }
        else {
            newArray[index] = {...runConfigElement, isDeleted: !runConfigElement.isDeleted}
        }
        dispatch(xpFormChangeField(props.formModel + '.runConfiguration.' + props.type, newArray));
    }

    if (!propsAccepted) return null;
    if (!formContext)   return null;
    if (hideConfigType) return null;

    const baseEntityFormModel = formContext.formModel;

    return (
        <div>
            <XpAccordion
                header          = {<XpTranslated trKey={runConfigurationTypeFormTemplate + '.accordionTitle'} trParams={{count: numberOfActiveElements}}/>}
                headerClassName = "textGenerationsHeader"
                instanceId      = {props.xpAdminFormInstanceId + "-" + props.rootOrmModel + "-" + props.ormModel + "-runConfigurations-" + props.type}
                bypass          = {(!runConfigurationElementsToRender?.length && xpFormIsReadOnly(formContext)) || !runTypeConfig.useAccordion}
            >
                <XpFormFieldset
                    field        = {'runConfiguration.' + props.type}
                    consumerProps = {runConfigurationElementsAuditBeforeChange ? {
                        beforeChangeData: runConfigurationElementsAuditBeforeChange,
                        currentData     : runConfigurationElementsToRender
                    } : undefined}
                >
                    <div className='runConfigurationTypeList'>
                        {(!Array.isArray(runConfigurationElementsToRender)) ? null :
                            runConfigurationElementsToRender.map((runConfigElement, index) => {
                                // The deleted elements must remain in the list, otherwise the index will be incorrect.
                                if (runConfigElement.isDeleted && !runConfigElement.id) return null; // Elements added but not saved should not be displayed, while saved elements should be displayed as deleted.
                                return (
                                    <div key={index}>
                                        <XpFormFieldset
                                            field        = {String(index)}
                                            formTemplate = {runConfigurationTypeFormTemplate}
                                            index        = {index}
                                        >
                                            <RunConfigurationForm
                                                {...props}
                                                justCreated     = {createdIndex.includes(index)}
                                                index           = {index}
                                                type            = {props.type}
                                                // formModel       = {props.formModel + '.runConfiguration.' + props.type + '.' + index}
                                                // formState       = {props.formState}
                                                ormModel            = {props.ormModel}
                                                baseEntityFormModel = {baseEntityFormModel}
                                                // formTemplate = {runConfigurationTypeFormTemplate}
                                                // entityData      = {getSubFormEntityData(currentRunConfigurationElements, index)}
                                                removeCb        = {() => addRemoveRunConfiguration(index, runConfigElement)}
                                                userCanManage   = {userCanManage}
                                                maxElementsReached = {maxElementsReached}
                                                allElements     = {currentRunConfigurationElements}
                                                className       = {elementsRenderClassArray[index]}
                                                isDeleted       = {runConfigElement.isDeleted}
                                                getActivationSwitchLabel   = {props.getActivationSwitchLabel}
                                            />
                                        </XpFormFieldset>
                                    </div>
                                )}
                            )
                        }
                        {(!runConfigurationElementsToRender?.length && xpFormIsReadOnly(formContext)) &&
                        <div className = 'field'><label><XpTranslated trKey={runConfigurationTypeFormTemplate + '.noneDefined'}/></label></div>}
                        <XpIconButton
                            type        = 'add'
                            labelKey    = {runConfigurationTypeFormTemplate + '.add'}
                            toolTipKey  = {!userCanManage ? "runConfiguration.notAuthorized" : (runConfigurationTypeFormTemplate + (maxElementsReached ? '.maxElementsReached' : '.addTooltip'))}
                            onClickCb   = {addRunConfiguration}
                            imgStyle    = {{marginTop: '-3px', marginBottom: '7px', marginLeft: '6.5px'}}
                            hidden      = {xpFormIsReadOnly(formContext) || (runTypeConfig.exactlyOneRequired && !noActiveRunConfigurationElementsDefined)}
                            disabled    = {maxElementsReached || !userCanManage}
                        />
                    </div>
                </XpFormFieldset>
            </XpAccordion>
        </div>
    )
}
export default RunConfigurationsList
