import React, {useMemo} from "react";
import RenderDataRight from "gui-common/userRights/RenderDataRight";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getDataRightsPrototypes} from "gui-common/userRights/AdminDataRightsCollection2";
import {compareEntityName} from "gui-common/userRights/adminUserRightsFunctions";

function RenderUserDataRights(props) {

    const prototypesToRender = useMemo(
        () => {
            if (!props.dataRights?.dataRights) {
                console.error("No activeDataRightsCollection to view!");
                return undefined;
            }
            return getDataRightsPrototypes(props.dataRights?.dataRights);
        },
        [props.dataRights]
    );

    if (!prototypesToRender?.length) {
        return (<h3><XpTranslated trKey="adminUserRights.noAssignedDataRights"/></h3>)
    }
    return (
        <div>
            {prototypesToRender.map((entityPrototypeName, index1) => {
                const filteredDataRights = props.dataRights?.dataRights.filter(item => item.prototype === entityPrototypeName).sort(compareEntityName);
                return (
                    <div key = {index1}>
                        {filteredDataRights.map((dataRight, index2) => (
                                <div key = {index2}>
                                    <RenderDataRight
                                        dataRight = {dataRight}
                                    />
                                </div>
                            ))
                        }
                    </div>
                )
            })}
        </div>
    );
}
RenderUserDataRights.propTypes = {
};

export default RenderUserDataRights;
