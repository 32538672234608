import React from 'react';
import {useSelector} from "react-redux";
import {AuditViewFilterForm} from "gui-common/audit/AuditViewFilterForm"
import "gui-common/audit/audit.css"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {auditEntriesSelector} from "gui-common/audit/auditSelectors";
import XpSplitPane from "gui-common/components/XpSplitPane";
import {AuditViewContent} from "gui-common/audit/AuditViewContent";

export const auditEntriesListInstanceId = "auditEntriesList";

function AuditViewPage(props) {
    const auditEntries       = useSelector(auditEntriesSelector);

    return (
        <div>
            <h2><XpTranslated trKey='auditView.header'/></h2>
            <hr/>
            <XpSplitPane defaultWidthPixels={250} minSizePrimary={150} maxSizePrimary={800}>
                <AuditViewFilterForm/>
                <AuditViewContent
                    auditEntries         = {auditEntries}
                    listInstanceId       = {'auditEntriesList'}
                    contentInstanceId    = {'auditPage'}
                    hiddenState = {{
                        id                  : true,
                        auditDateTime       : false,
                        actionT             : false,
                        affectedOrmModelT   : false,
                        affectedId          : true,
                        affectedName        : false,
                        processName         : true,
                        processInstance     : true,
                        userId              : true,
                        userFullName        : false,
                        apiVersion          : true,
                    }}
                />
            </XpSplitPane>
        </div>
    );
}
export default AuditViewPage;

// <h2><XpTranslated2 trKey='axa.fishes.header' trParams={{fish: 123}} /></h2>
