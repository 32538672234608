import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import ProcessTypeStatusLight from "gui-common/processStatus/ProcessTypeStatusLight";
import {processPrototypeStatusMapSelector} from "gui-common/processStatus/processStatusSelectors";
import {processStatusSortedPrototypes} from "gui-common/processStatus/processStatusConstants";

const containerHeight = 24;
function ProcessStatusMenuPanel (props) {

    const prototypeStatusMap = useSelector(processPrototypeStatusMapSelector);

    const containerWidth   = useMemo(() => {
        if (!prototypeStatusMap) return null;
        return Object.keys(prototypeStatusMap).length * containerHeight
    }, [prototypeStatusMap]);

    if (!prototypeStatusMap) return null;

    // console.log("Rendering IntegrationStatusMenuPanel");

    return (
        <div style={{height:containerHeight +'px', width: containerWidth+'px', display:'inline-block', marginRight:'5px', marginLeft:'3px', position: 'relative', top: '2px'}} xp-test-id='xpIntegrationStatusMenuPanel'>
            {processStatusSortedPrototypes.map((prototype, index) => {
                if (!prototypeStatusMap[prototype]) {
                    return null;
                }
                // const lastEvent = (item.events.length > 0) ? item.events[0] : null;
                return (
                    <div style={{width: ((containerHeight) + 'px'), display: 'inline-block', padding: '1px', paddingTop: '0px'}} key = {index}>
                        <ProcessTypeStatusLight
                            active      = {true}
                            processPrototype = {prototype}
                            processStatusMap = {prototypeStatusMap[prototype]}
                            // lastEvent   = {lastEvent}
                            isMenuLight = {true}
                            showName    = {true}
                        />
                    </div>
                )})
            }
        </div>
    )
}
export default ProcessStatusMenuPanel
