import React from 'react'
import PropTypes from 'prop-types';
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {XpDateTime} from "gui-common/components/XpDateTime";
import AuditRecordRawDataView from "gui-common/audit/AuditRecordRawDataView";
import {HistoricAuditModifiedObject} from "gui-common/audit/historicAudit/HistoricAuditModifiedObject";
import XpSplitPane from "gui-common/components/XpSplitPane";

import {useAuditModelConfig} from "gui-common/audit/auditFunctions";



export function HistoricAuditRecordRenderer(props) {
    const modelConfig = useAuditModelConfig(props.auditEntry);

    return (
        <div>
            <h3 className={'formHeaderEditNew'}>
                <XpTranslated trKey={'auditView.historic.header'}/>
            </h3>
            <div className='xpAdminFormEntityCard'>
                <XpSplitPane defaultWidthPercent={30}>
                    <div>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.auditDateTime'         value={<XpDateTime isoString={props.auditEntry.auditDateTime} format='lll'/>}/>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.userFullName'          value={props.auditEntry.user.firstName + ' ' + props.auditEntry.user.lastName}/>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.apiVersion'            value={props.auditEntry.apiVersion}/>
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.action'                value={<XpTranslated trKey={"auditView.actionTypes." + props.auditEntry.action + '.name'}/>} />
                        {modelConfig &&
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.model'                 value={<XpTranslated trKey={"general.modelNamesLarge." + props.auditEntry?.affectedOrmModel}/>} />}
                        {modelConfig &&
                        <XpFormStaticField inLineDivider={65} inLineLayout labelTrKey='auditEntriesList.headers.modifiedObjectName'    value={props.auditEntry.affectedName  + ' (' + props.auditEntry.affectedId + ')'}/>}
                    </div>
                    <div style={{width: '70%', padding: '5px'}}>
                        <HistoricAuditModifiedObject apiVersion={props.auditEntry.apiVersion} historicAuditConfig={modelConfig?.historicAudit} before={props.auditEntry.data.before} after={props.auditEntry.data.after}/>
                    </div>
                </XpSplitPane>
                <AuditRecordRawDataView {...props}/>
            </div>
        </div>
    );

}

HistoricAuditRecordRenderer.propTypes = {
    auditEntry : PropTypes.object.isRequired,
};
