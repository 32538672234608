import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavDropdown} from "react-bootstrap";
import {setAmountDecimalsThunk, setAmountDenominatorThunk} from "gui-common/numberFormat/numberFormatFunctions";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import {selectNumberDecimalState, selectNumberDenominatorState} from "gui-common/numberFormat/numberFormatSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";


function XpNumberFormatMenu () {
    const oxygenAppContext       = useOxygenAppContext();
    const appReady          = useSelector(selectAppReadyState);
    const appDenominator= useSelector(selectNumberDenominatorState);
    const appDecimals   = useSelector(selectNumberDecimalState);
    const dispatch    = useDispatch();

    if (!appReady) return null;
    if (oxygenAppContext.appConfig.mainMenu.hideNumberFormatWidget) {
        return null;
    }

    function shortDen() {
        switch (appDenominator) {
            case 1:             return (<XpTranslated trKey='topMenu.shortDen.units'/>);
            case 1000:          return (<XpTranslated trKey='topMenu.shortDen.thousands'/>);
            case 1000000:       return (<XpTranslated trKey='topMenu.shortDen.millions'/>);
            default:            return null;
        }
    }
    function shortDec() {
        switch (appDecimals) {
            case 0: return (<XpTranslated trKey='topMenu.shortDec.zero'/>);
            case 1: return (<XpTranslated trKey='topMenu.shortDec.one'/>);
            case 2: return (<XpTranslated trKey='topMenu.shortDec.two'/>);
            default:return null;
        }
    }
    function activeDenominator(den) {
        if (den !== appDenominator) return null;
        return (
            <XpTranslated trKey='topMenu.active'/>
        )
    }
    function activeDecimals(dec) {
        if (dec !== appDecimals) return null;
        return (
            <XpTranslated trKey='topMenu.active'/>
        )
    }

    function shortSettings(){
        return (
            <span>
                {shortDen()}.{shortDec()}
            </span>
        );
    }

    function denTitle(){
        return (
            <span className="subMenuDropDown">
                <XpTranslated trKey='topMenu.amountDenominator'/>
                 ({shortDen()})
            </span>
        );
    }
    function decTitle(){
        return (
            <span className="subMenuDropDown">
                <XpTranslated trKey='topMenu.amountDecimals'/>
                 ({shortDec()})
            </span>
        );
    }

    return (
        <NavDropdown title={shortSettings()} id="user-nav-dropdown" xp-test-id="mainMenuNumberDisplay" alignRight>
            <NavDropdown title={denTitle()} id="config-nav-den-dropdown" xp-test-id="mainMenuNumberDenominator" className="dropdown-item">
                <NavDropdown.Item key={1} onClick={() => dispatch(setAmountDenominatorThunk(1      ))} ><XpTranslated trKey='userPreferencesForm.amountDenominator.values.units'    />{activeDenominator(1)      }</NavDropdown.Item>
                <NavDropdown.Item key={2} onClick={() => dispatch(setAmountDenominatorThunk(1000   ))} ><XpTranslated trKey='userPreferencesForm.amountDenominator.values.thousands'/>{activeDenominator(1000)   }</NavDropdown.Item>
                <NavDropdown.Item key={3} onClick={() => dispatch(setAmountDenominatorThunk(1000000))} ><XpTranslated trKey='userPreferencesForm.amountDenominator.values.millions' />{activeDenominator(1000000)}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={decTitle()} id="config-nav-den-dropdown" xp-test-id="mainMenuNumberDecimals" className="dropdown-item dropdown-item-right">
                <NavDropdown.Item key={1} onClick={() => dispatch(setAmountDecimalsThunk(0))}><XpTranslated trKey='userPreferencesForm.amountDecimals.values.zero' />{activeDecimals(0)}</NavDropdown.Item>
                <NavDropdown.Item key={2} onClick={() => dispatch(setAmountDecimalsThunk(1))}><XpTranslated trKey='userPreferencesForm.amountDecimals.values.one'  />{activeDecimals(1)}</NavDropdown.Item>
                <NavDropdown.Item key={3} onClick={() => dispatch(setAmountDecimalsThunk(2))}><XpTranslated trKey='userPreferencesForm.amountDecimals.values.two'  />{activeDecimals(2)}</NavDropdown.Item>
            </NavDropdown>
        </NavDropdown>
    )
}
export default XpNumberFormatMenu
