import React, {useMemo} from 'react'
import {contextMenuClickThunk} from "gui-common/contextMenu/contextMenuFunctions";
import PropTypes from "prop-types";
import XpTooltip from "gui-common/components/XpTooltip";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    getProcessName, getProcessStatus, getProcessTypeShortName,
    getProcessTypeStatus
} from "gui-common/processStatus/processStatusFunctions";
import {useDispatch} from "react-redux";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";
import {webSocketStatus as webSocketState} from "gui-common/api/apiConstants";
import {StatusPackageRenderer} from "gui-common/processStatus/StatusPackageRenderer";

function ProcessTypeStatusLight(props) {

    const oxygenAppContext = useOxygenAppContext();
    const dispatch = useDispatch();

    const processName = useMemo(
        () => getProcessName(props.processPrototype),
        [props.processPrototype]
    );
    const processStatusType = useMemo(
        () => {
            return getProcessTypeStatus(props.processPrototype, props.processStatusMap)
        },
        [props.processPrototype, props.processStatusMap]
    );

    const processTypeShortName = useMemo(
        () => {
            return getProcessTypeShortName(props.processPrototype, props.processStatusMap)
        },
        [props.processPrototype, props.processStatusMap]
    );

    function getToolTip() {
        // if (!this.props.active)    return '';
        if (oxygenAppContext.apiHandle.status === webSocketState.DOWN) {
            return <span><XpTranslated trKey={'processStatus.socketDownTooltip'}/></span>;
        }
        // if (!this.props.lastEvent) return <span><XpTranslated trKey={'processStatus.noEventAvailableTooltip'} trParams={{componentName: this.props.showName}}/></span>;
        if (!Object.keys(props.processStatusMap).length) {
            return (
                <div>
                    <span>
                        <XpTranslated trKey='processStatus.processType' trParams={{componentName: processName}}/>
                        <XpTranslated trKey='processStatus.noProcessStarted'/>
                    </span>
                </div>
            )
        }
        return (
            <div>
                <XpTranslated trKey='processStatus.processType' trParams={{componentName: processName}}/>
                {Object.keys(props.processStatusMap).map((key, index) => {
                        const thisProcessState = props.processStatusMap[key];
                        return (
                            <div key={thisProcessState.processInstanceId}>
                                <hr/>
                                <span>
                                    <XpTranslated trKey='processStatus.processInstance' trParams={{instanceId: thisProcessState.processInstanceId}}/>
                                    <XpTranslated trKey='processStatus.itemStatus'  trParams={{componentStatus: getProcessStatus(thisProcessState)}}/>
                                    {/*<XpTranslated trKey='processStatus.version'     trParams={{version: thisProcessState.version}}/>*/}
                                    <XpTranslated trKey='processStatus.commit'      trParams={{commit: thisProcessState.revision}}/>
                                </span>
                                {thisProcessState.active &&
                                <StatusPackageRenderer statusPackage={thisProcessState.processStatus}/>}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    let renderClassName = props.isMenuLight ? "menuStatusLight " : "statusLight ";
    renderClassName = renderClassName + ((oxygenAppContext.apiHandle.status === webSocketState.DOWN) ? " statusLightWarning" : processStatusType.renderClass);

    //        const disableContextMenu = !this.props.active;
    const disableContextMenu = true; // No actions for the moment.
    const renderStyle = {};
    if (props.isMenuLight) {
        renderStyle.boxShadow   = 'none';
    }

    return (
        <XpTooltip title={getToolTip()}>
            <div
                onContextMenu={(e) => dispatch(contextMenuClickThunk(e, disableContextMenu, "IntegrationStatusItemMenu", {processStatus: props.processStatus}))}
                className={renderClassName}
                style={renderStyle}
                xp-test-id='xpIntegrationStatusLight'
            >
                {props.isMenuLight && <span className={'menuStatusLightShortName'}>{processTypeShortName}</span>}
            </div>
        </XpTooltip>
    )
}

ProcessTypeStatusLight.propTypes = {
    active:               PropTypes.bool.isRequired,
    processStatus:        PropTypes.object,
    noShadow:             PropTypes.bool,
};
export default ProcessTypeStatusLight;
