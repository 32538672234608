import {globalApiHandle} from "gui-common/api/apiConstants";
import {genericFetch} from "gui-common/api/apiCommon";


export function getFixLogFilterFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            const logText = "Get fix log filter ";
            let requestUrl = globalApiHandle.baseUrls.FixMessage + '/filter?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}

export function setFixLogFilterToApi(filter) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Set fix log filter ";
            let requestUrl = globalApiHandle.baseUrls.FixMessage + '/filter?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'POST',
                filter,
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}
