import {createSelector} from "reselect";

const apiState    = state => state.apiState;
export const selectAppReadyState = createSelector(
    [apiState],
    (apiState) => {
        if (!apiState) return false;
        return (apiState.appReady === true);
    }
);
export const selectWebSocketsStatus = createSelector(
    [apiState],
    (apiState) => {
        if (!apiState) return false;
        return apiState.socketsState;
    }
);
export const selectUserLoggedInState = createSelector(
    [apiState],
    (apiState) => {
        if (!apiState) return false;
        return (apiState.userLoggedIn === true);
    }
);
export const selectLoggedInUserId = createSelector(
    [apiState],
    (apiState) => {
        if (!apiState) return null;
        return apiState.userSystemId;
    }
);
export const selectLoadingTrKey = createSelector(
    [apiState],
    (apiState) => {
        if (!apiState) return undefined;
        return apiState.loadingTrKey;
    }
);

const awtState = state => state.awtState;
export const selectNextAwtToken = createSelector(
    [awtState],
    (awtState) => {
        if (!awtState?.length) return null;
        return awtState[0];
    }
);
