import {translateDateFields, xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import moment from "moment";
import {getEntityFromId} from "gui-common/functions/functions";
import {replaceParametersInText} from "gui-common/appLocale/xpTranslated/xpTranslatedFunctions";

export function animateAppContainer(messageId, className) {
    const thisRow = document.getElementById("userMessageRow-"+messageId);
    if (!thisRow) {
        console.warn("No user message row found in animateAppContainer for message id ", messageId);
        return;
    }
    const rowHeight = thisRow.offsetHeight + 2;

    const style = document.documentElement.style;
    style.setProperty('--userMessageSlideHeight', "-"+rowHeight+"px");

    let element = document.getElementById("appContentContainer");
    if (element) element.className = "content " + className;
}

const sortReportData = (a, b) => {
    if (a.createdDateTime < b.createdDateTime) return 1;
    return -1;
};

const getUserMessageText = (message, translate) => {
    if (!message.textPath) return message.plainText;
    const text = translate(message.textPath, message.parameters);
    if (typeof text !== 'string') { // Todo: Handle HTML as response
        console.warn("returnText not a string in getUserMessageText", text, message.textPath, message.parameters);
        return message.plainText;
    }
    if (text.includes("Missing translation") && message.plainText) return replaceParametersInText(message.plainText, message.parameters);
    return text;
};
export function formatUserMessagesGridData(dataArray, translate, currentLanguage, clients) {
    if (!dataArray?.length) {
        return [];
    }

    let returnArray = [];
    // List display in reverse order than rendering when displaying.
    for (let i = dataArray.length - 1; i >= 0; i--) {
        let item = dataArray[i];
        translateDateFields(item, 'YYYY-MM-DD, HH:mm:ss', currentLanguage);
//        item.text = getUserMessageText(item, translate);
        item.textRenderData = {
            trKey       : item.textPath,
            trParams    : item.parameters,
            fallBackText: item.plainText,
        }
        item.text = getUserMessageText(item, translate);
        if (item.parameters?.clientId) {
            const client = getEntityFromId(item.parameters.clientId, clients);
            item.clientName = client?.name;
        }
        if (item.parameters?.clientName) {
            item.clientName = item.parameters.clientName;
        }
        returnArray.push(item);
    }
    returnArray.sort(sortReportData);
    return returnArray;
}

export function prepareUserMessagesGridColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'clientId'            , 50 , 30, 'userMessages.listColumns.clientId');
    xpColCreate(translate, subArray, hiddenState, 'clientName'          , 100, 50, 'userMessages.listColumns.clientName', {enableRowGroup: true});
    xpColCreate(translate, subArray, hiddenState, 'userId'              , 100, 50, 'userMessages.listColumns.user', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'createdDateTimeT'    , 100, 30, 'userMessages.listColumns.createdDateTime');
    xpColCreate(translate, subArray, hiddenState, 'confirmedDateTimeT'  , 100, 50, 'userMessages.listColumns.confirmedDateTime', {cellRenderer: 'xpConfirmUmRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'topic'               , 50 , 50, 'userMessages.listColumns.topic');
    xpColCreate(translate, subArray, hiddenState, 'type'                , 50 , 50, 'userMessages.listColumns.type', {
        enableRowGroup: true, cellStyle: function (params) {
            if (!params.data) return;

            let outStyle = {background: 'var(--xpool-user-message-grid-info-background-color)', color: '#434343'};
            if (params.data.type === "error") {
                outStyle = {background: 'var(--xpool-user-message-grid-error-background-color)', color: '#434343'};
            } else if (params.data.type === "warning") {
                outStyle = {background: 'var(--xpool-user-message-grid-warning-background-color)', color: '#434343'};
            }
            return outStyle;
        }
    });
    xpColCreate(translate, subArray, hiddenState, 'text', 300, 50, 'userMessages.listColumns.text', {autoHeight: true, wrapText: true, tooltipField: 'text', cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'id'  , 50 , 50, 'userMessages.listColumns.id');

    return subArray;
}

export function userMessageTransformer(item) {

    item.confirmedDateTime  = item.confirmedDateTime ? moment(item.confirmedDateTime) : null;
    item.id                 = item.id                ? item.id : null;
    item.topic              = item.topic             ? item.topic : "";

    item.createdDateTime     = (item.userMessage && item.userMessage.createdDateTime)                   ? moment(item.userMessage.createdDateTime) : null;
    item.messageId           = (item.userMessage && item.userMessage.id)                                ? item.userMessage.id : null;
    item.parameters          = (item.userMessage && item.userMessage.parameters)                        ? item.userMessage.parameters : null;
    item.plainText           = (item.userMessage && item.userMessage.plainText)                         ? item.userMessage.plainText : "";
    item.requireConfirmation = (item.userMessage && (item.userMessage.requireConfirmation === false))   ? item.userMessage.requireConfirmation : true;
    item.textPath            = (item.userMessage && item.userMessage.textPath)                          ? item.userMessage.textPath : "";
    item.textPathId          = (item.userMessage && item.userMessage.textPathId)                        ? item.userMessage.textPathId : "";
    item.type                = (item.userMessage && item.userMessage.type)                              ? item.userMessage.type : "";

    item.userId             = (item.userMessage && item.userMessage.userId)  ? item.userMessage.userId : null;

    return item;
}
const userMessageParameterMaxLength = 250;
export function createUserMessageBody(userMessage, state) {

    let parameters = {};

    for (const key in userMessage.parameters) {
        parameters[key] = userMessage.parameters[key];
        if (typeof userMessage.parameters[key] !== 'string') continue;
        if (userMessage.parameters[key].length >= userMessageParameterMaxLength) parameters[key] = userMessage.parameters[key].slice(0, userMessageParameterMaxLength);
    }

    return {
        type: userMessage.type,
        topic: userMessage.topic,
        requireConfirmation: userMessage.requireConfirmation,
        textPath: userMessage.textPath,
        parameters: parameters,
        plainText: userMessage.plainText,
        createdDateTime: userMessage.createdDateTime ? userMessage.createdDateTime.toISOString() : null,
        confirmedDateTime: userMessage.confirmedDateTime ? userMessage.confirmedDateTime.toISOString() : null,
        userName: userMessage.userName,
        userId: userMessage.userId,
    }
}
