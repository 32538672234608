import {useMemo} from "react";


export function addRemovedActiveDataRights(modifiedEntityDataRights, activeEntityDataRights) {
    if (!modifiedEntityDataRights?.length) {
        return activeEntityDataRights ? [...activeEntityDataRights] : undefined;
    }

    if (!activeEntityDataRights) {
        return modifiedEntityDataRights ? [...modifiedEntityDataRights] : undefined;
    }

    const returnArray = [...modifiedEntityDataRights];
    activeEntityDataRights.forEach(activeItem => {
        if (!returnArray.find(assignedItem => assignedItem.id === activeItem.id)) returnArray.push(activeItem);
    });
    return returnArray;
}

export function compareEntityName( a, b ) {
    if ( a.shallowObject.name < b.shallowObject.name ) return -1;
    if ( a.shallowObject.name > b.shallowObject.name ) return 1;
    return 0;
}
export function compareEntityId( a, b ) {
    if ( a.id < b.id ) return -1;
    if ( a.id > b.id ) return 1;
    return 0;
}

export function usePrototypeDataRights(allDataRights, prototype) {
    return useMemo(() => {
        if (!allDataRights || !prototype) {
            return undefined;
        }
        return allDataRights.filter(item => item.prototype === prototype).sort(compareEntityName);
    }, [allDataRights, prototype]);
}
