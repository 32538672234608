import {AuditSearchField} from "gui-common/audit/AuditSearchField";


const commonRunConfigurations = {
    auditSearchFields: {
        formComponent  : AuditSearchField,
        useAccordion   : false,
        newTemplate    : {isActive: true, fieldName: "", fieldValue: ""},
        // listDependentFields: {parentAccountId: 'parentAccountId'},
        formDependentFields: {fieldType: 'fieldType', fieldName: 'fieldName', fieldValue: 'fieldValue'},
        getLabelKey    : (formContext, fields) => formContext.formTemplate + '.isActive.label',
        getLabelParams    : (formContext, fields) => {
            return {fieldType: fields.fieldType, fieldName: fields.fieldName, fieldValue: fields.fieldValue}
        },
        getFormTemplate: (formContext, fields) => 'auditViewFilterForm',
        // hideConfigType : (formContext, fields) => (formContext.ormModel === 'BankAccount') && !fields.parentAccountId,
        userCanManage     : (rootObjectData) => true,
        fixedFormCardClassName: 'auditSearchFieldCard',
    },
}

export let runConfigurationConfig = {};
export function setGlobalRunConfigurationConfig(appConfig) {
    Object.keys(commonRunConfigurations).forEach(key => runConfigurationConfig[key] = commonRunConfigurations[key])
    Object.keys(appConfig.runConfiguration).forEach(key => runConfigurationConfig[key] = appConfig.runConfiguration[key])
    // console.log("setting runConfigurationConfig", runConfigurationConfig);
}
