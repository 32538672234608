import {userSelector} from "gui-common/orm/ormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {logoutUserFromApi} from "gui-common/api/apiFunctions";


/* -----------------------------------------------------------------------------------------------------------------
* Execution rights updated. Inform user and reload data.
* -----------------------------------------------------------------------------------------------------------------*/
export function executionRightsUpdated(model, payload) {
    return (dispatch, getState) => {
        const state = getState();
        const thisUser = userSelector(state);
        if (thisUser.userId !== payload.userId) return; // Not relevant for the logged in user

        dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: 'general.modalConfirmExecutionRightsUpdated'}))
            .then((result) => {
                // const rerouteUrl   = window.oxygen_env.AUTHENTICATION_URL;
                dispatch(logoutUserFromApi());
            });
    }
}

export function pushDelayedModal(modalId, modalProps, delay) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                dispatch(pushModalWindow(modalId, modalProps))
                    .then(result => {resolve(result)}).catch(err => {reject(err)})
            }, delay ? delay : 50);
        })
    }
}
