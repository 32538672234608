import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import React, {useEffect} from 'react'
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {PriceTolerancesSettings} from "features/priceTolerances/formComponents/PriceTolerancesSettings";
import {useDispatch} from "react-redux";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {getPriceToleranceByCurrencyPairIdSelector} from "features/priceTolerances/priceToleranceSelectors";
import {useSelectorInstance} from "gui-common/functions/hooks";

export function PriceTolerancesOverride(props) {
    const isActive = useXpFormField('overridePriceTolerances')
    const startBusinessHoursFormValue = useXpFormField('startBusinessHours')
    const currencyPairIdFormValue = useXpFormField('currencyPairId')
    const formContext = useXpFormContext()
    const dispatch = useDispatch();
    const defaultPriceToleranceForCurrencyPair = useSelectorInstance(getPriceToleranceByCurrencyPairIdSelector, {currencyPairId: currencyPairIdFormValue});
    const formDataType             = useXpFormField('type');

    useEffect(() => {
        if (!isActive || startBusinessHoursFormValue || !defaultPriceToleranceForCurrencyPair) {
            return;
        }

        dispatch(xpFormChangeField(formContext.formModel + '.startBusinessHours'                , defaultPriceToleranceForCurrencyPair.startBusinessHours));
        dispatch(xpFormChangeField(formContext.formModel + '.endBusinessHours'                  , defaultPriceToleranceForCurrencyPair.endBusinessHours));
        dispatch(xpFormChangeField(formContext.formModel + '.priceToleranceInsideBusinessHours' , defaultPriceToleranceForCurrencyPair.priceToleranceInsideBusinessHours));
        dispatch(xpFormChangeField(formContext.formModel + '.priceToleranceOutsideBusinessHours', defaultPriceToleranceForCurrencyPair.priceToleranceOutsideBusinessHours));

    }, [isActive]);

    if (formDataType !== 'ListRateAgreement') {
        return null;
    }

    return (
        <XpFormFieldGroup
            isExpanded={isActive}
            disableAnimations = {formContext?.formUseState === XP_FORM_VIEW}
            controlComponent={
                <XpFormSwitchInput
                    field         = "overridePriceTolerances"
                    defaultValue  = {false}
                />
            }
        >
            <PriceTolerancesSettings/>

        </XpFormFieldGroup>
    );
}
