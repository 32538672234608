import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {setStyling} from "gui-common/app/commonAppFunctions";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

export function useEnvStyling() {
    const styleArray = useAppEnvProperty('guiStyling');
    const dispatch = useDispatch();
    const isSandbox = useAppEnvProperty('isSandbox')
    useEffect(
        () => {
            if (styleArray && styleArray.length > 0) {
                let defaultStyling = styleArray.find(item => item.default === true);
                if (!defaultStyling) defaultStyling = styleArray[0];
                dispatch(setStyling(defaultStyling, isSandbox));
            }
        },
        []
    );
}
