import React from 'react'
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalLogMessage} from "gui-common/modals/modalFunctions";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import cloneDeep from "lodash.clonedeep";

function ModalConfirmation(props) {

    function confirmAction(message) {
        const { resultTypes: { MODAL_TYPE_CONFIRM }, popModalWindow } = props;
        modalLogMessage('User confirmed message:', props);
        popModalWindow({ status: MODAL_TYPE_CONFIRM});
    }

    function cancelAction(message) {
        const { resultTypes: { MODAL_TYPE_CANCEL }, popModalWindow } = props;
        modalLogMessage('User cancelled after message:\n', props);
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    const showCancel  = props.cancelText  ? props.cancelText  : <XpTranslated trKey={props.modalKey ? (props.modalKey+'.cancel' ) : 'general.buttons.cancel' } fallbackKey='general.buttons.cancel'  trParams={props.modalParams}/>;
    const showConfirm = props.confirmText ? props.confirmText : <XpTranslated trKey={props.modalKey ? (props.modalKey+'.confirm') : 'general.buttons.confirm'} fallbackKey='general.buttons.confirm' trParams={props.modalParams}/>;
    const showHeader  = props.headerText  ? props.headerText  : <XpTranslated trKey={props.modalKey ? (props.modalKey+'.header' ) : ''} trParams={props.modalParams}/>;
    const showMessage = props.messageText ? props.messageText : <XpTranslated trKey={props.modalKey ? (props.modalKey+'.message') : ''} trParams={props.modalParams}/>;

    const modalRenderingParams = cloneDeep(modalStandardParams);
    modalRenderingParams.className.base = modalRenderingParams.className.base + " " + (props.bodyClassName ? props.bodyClassName : "");
    return (
        <div>
            <FocusLock>
            <ReactModal
                contentLabel="Confirmation modal"
                onRequestClose={(props) => cancelAction(showMessage)}
                shouldCloseOnEsc={true}
                {...modalRenderingParams}
            >
                <div xp-test-id="xpModalConfirmation">
                    <h3 className ="xpoolModalHeader">{showHeader}</h3>
                    <hr/>
                    <div className="valueTextDark">{showMessage}</div>
                    <hr/>

                    <div className="xpoolModalButtons">
                        <WaitingXpoolButton
                            xpTestId        = {"xpModalButtonConfirm"}
                            label           = {showConfirm}
                            onClickCallback = {() => confirmAction(showMessage)}
                        />
                        <WaitingXpoolButton
                            xpTestId        = {"xpModalButtonCancel"}
                            label           = {showCancel}
                            onClickCallback = {() => cancelAction(showMessage)}
                        />
                    </div>
                </div>
            </ReactModal>
            </FocusLock>
        </div>
    );
}

ModalConfirmation.propTypes = {
    modalKey:       PropTypes.string,
    modalParams:    PropTypes.object,
    headerText:     PropTypes.string,
    messageText:    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cancelText:     PropTypes.string,
    confirmText:    PropTypes.string,
    resultTypes:    PropTypes.object.isRequired,
    popModalWindow: PropTypes.func.isRequired
}
export default ModalConfirmation;

