import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import cloneDeep from "lodash/cloneDeep";
import UserMessageConfirmButton from "gui-common/userMessages/UserMessageConfirmButton2";
import {
    formatUserMessagesGridData,
    prepareUserMessagesGridColumns
} from "gui-common/userMessages/userMessagesFunctions";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {
    selectActiveLanguage,
    useXpTranslateFunction
} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";

function UserMessagesList(props) {

    const oxygenAppContext = useOxygenAppContext();
    const userMessageEnvConfig = useAppEnvProperty('userMessagesConfig')
    const translate = useXpTranslateFunction()
    const currentLanguage = useSelector(selectActiveLanguage)
    const clientsSelector = oxygenAppContext.appConfig.userMessages?.clientsSelector ? oxygenAppContext.appConfig.userMessages.clientsSelector : state => [];
    const ormClients = useSelector(clientsSelector);
    const userMessages = useSelector(state => state.userMessagesState);
    const dispatch = useDispatch();

    function gridContextMenuItems(params) {
        let menuItems = [];
        let menuItem = {};

        if (params.node.data.parameters?.clientId && userMessageEnvConfig?.useGoToClient && oxygenAppContext.appConfig.userMessages?.gotoClientThunks) {
            menuItem = {
                name: translate('userMessages.goToClientButton'),
                action: function () {
                    dispatch(oxygenAppContext.appConfig.userMessages.gotoClientThunks({id: params.node.data.parameters.clientId}));
                },
                context: params,
                disabled: props.suppressRowClickSelection,
            };
            menuItems.push(menuItem);
        }
        return menuItems;
    }

    const hiddenState = props.hiddenState ? props.hiddenState : {
        createdDateTimeT:   false,
        clientName:         false,
        clientId:           true,
        userId:             false,
        type:               false,
        topic:              false,
        text:               false,
        confirmedDateTimeT: false,
        id:                 true
    };

    const dataToRender = cloneDeep(userMessages); // Get separate copy.
    const gridData = formatUserMessagesGridData(dataToRender, translate, currentLanguage, ormClients);
    let preparedColumns = prepareUserMessagesGridColumns(translate, hiddenState);

    return (
        <XpGrid
            gridCellClicked={(params) => {}}
            gridRowClicked={(params) => {}}
            gridObjectSelected={(data) => {}}
            gridContextMenuItems={(params) => gridContextMenuItems(params)}

            gridId={"userMessagesGrid" + props.instanceId}
            instanceId={props.instanceId}

            columnDefs={preparedColumns}
            rowData={gridData}
            callingContext={this}

            treeData={false}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}
            // rowGroupPanelShow="always"
            groupUseEntireRow={false}
            overlayNoRowsTemplate={translate('userMessages.noUserMessages')}
            suppressRowClickSelection={true}

            useResponsiveRowHeight={true}

            fillAvailableSpace={true}

            frameworkComponents= {{
                xpConfirmUmRenderer: UserMessageConfirmButton,
            }}
        />
    );
}
export default withRouter(UserMessagesList);
