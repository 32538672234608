import {createSelector} from "redux-orm";
import {orm} from "gui-common/orm/orm";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {instanceRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {refixDataRights} from "appConfig/appUserRights";

export const operatingUnitsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.OperatingUnit.all().orderBy('name', 'desc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);
export const getOperatingUnitsWithFullClientCreateSelector = () => createSelector(
    orm,
    dbStateSelector,
    (session) => {
        return session.OperatingUnit.all().toModelArray().map(item => item.ref).filter(item => {
            return  instanceRightAllowed(item, refixDataRights.OperatingUnit.CreateClient) &&
                    instanceRightAllowed(item, refixDataRights.OperatingUnit.CreateLegalEntity) &&
                    instanceRightAllowed(item, refixDataRights.OperatingUnit.CreateLegalEntityUnit)
        });
    }
);
export const operatingUnitsWithFullClientCreateSelector = getOperatingUnitsWithFullClientCreateSelector();

/*
export const operatingUnitsWithClientCreateSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.OperatingUnit.all().toModelArray().map(item => item.ref);
        returnArray = returnArray.filter(unit => userCanCreate('Client', unit));
        return returnArray;
    }
);
*/
