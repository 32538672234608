import moment from "moment";
import {createUserMessageBody, userMessageTransformer} from "gui-common/userMessages/userMessagesFunctions";
import {createFxRejectConfigBody, fxRejectConfigTransformer} from "gui-common/fxRejectConfig/fxRejectConfigFunctions";
import {
    createCurrencyPairConfigBody,
    currencyPairConfigTransformer
} from "gui-common/currencyPairSettings/currencyPairConfigFunctions";
import {ormItemArrayTransformer} from "gui-common/orm/ormApi";
import {
    bankHolidayBulkAuditTransformer,
    bankHolidayTransformer,
    createBankHolidayBody,
    rootBankHolidayTransformer
} from "gui-common/bankHolidays/bankHolidaysFunctions";
import {
    getNestedIdProperty2,
    setNestedIdProperty,
    setParentIdIfDefined
} from "gui-common/functions/functions";
import {getAppEnvPropertySelector} from "gui-common/app/appEnvSelectors";
import {createSystemTextBody, systemTextTransformer} from "gui-common/appLocale/xpTranslated/xpTranslatedFunctions";
import {runConfigurationConfig} from "gui-common/runConfiguration/runConfigurationConstants";
import {globalRunStateConfig} from "gui-common/runState/runStateConstants";
import {
    fxExecutionLegIncomingTransformer,
    fxExecutionReportIncomingTransformer,
    fxOrderReportIncomingTransformer,
    fxTradeReportDeliveryIncomingTransformer,
    fxTradeReportEventIncomingTransformer,
} from "gui-common/fxTradeReport/fxTradeReportFunctions";
import {isArray} from "lodash";
import {transformExecutionRights} from "gui-common/userRights/userRightsFunctions";

export const commonIncomingTransformersMap = {
    Currency                : currencyResponseTransformer,
    CurrencyPair            : currencyPairResponseTransformer,
    Country                 : countryResponseTransformer,
    FxPrice                 : fxPriceResponseTransformer,
    FixMessage              : fixMessageResponseTransformer,
    FixSession              : fixSessionTransformer,
    NFCProcessStatus        : nfcProcessStatusTransformer,
    ErpSystem               : erpSystemTransformer,
    ErpIntegration          : erpIntegrationTransformer,
    OperatingUnit           : operatingUnitTransformer,
    UserMessage             : userMessageTransformer,
    User                    : userTransformer,
    Topic                   : topicTransformer,
    NoRunDate               : noRunDateTransformer,
    FxRejectConfiguration   : fxRejectConfigTransformer,
    SharedSetting           : sharedSettingTransformer,
    Tenor                   : tenorTransformer,
    SystemText              : systemTextTransformer,
    CurrencyPairSettings    : currencyPairConfigTransformer,
    BankHoliday             : bankHolidayTransformer,
    RootBankHoliday         : rootBankHolidayTransformer,
    BankHolidayBulkAudit    : bankHolidayBulkAuditTransformer,
    RunState                : runStateTransformer,
    LedgerAccount           : ledgerAccountTransformer,
    LedgerAccountBalance    : ledgerAccountBalanceTransformer,
    FxTradeReportEvent      : fxTradeReportEventIncomingTransformer,
    FxOrderReport           : fxOrderReportIncomingTransformer,
    FxExecutionLeg          : fxExecutionLegIncomingTransformer,
    FxExecutionReport       : fxExecutionReportIncomingTransformer,
    FxTradeReportDelivery   : fxTradeReportDeliveryIncomingTransformer
};
export const commonOutgoingTransformersMap = {
    Currency                : undefined,
    CurrencyPair            : undefined,
    Country                 : undefined,
    FxPrice                 : undefined,
    FixSession              : undefined,
    ErpSystem               : undefined,
    ErpIntegration          : createErpIntegrationBody,
    OperatingUnit           : undefined,
    UserMessage             : createUserMessageBody,
    User                    : createUserBody,
    Topic                   : undefined,
    NoRunDate               : createNoRunDateBody,
    FxRejectConfiguration   : createFxRejectConfigBody,
    SharedSetting           : createSharedSettingBody,
    Tenor                   : undefined,
    SystemText              : createSystemTextBody,
    CurrencyPairSettings    : createCurrencyPairConfigBody,
    BankHoliday             : createBankHolidayBody,
    RunState                : undefined,
    FxTradeReportEvent      : undefined,
    FxOrderReport           : undefined,
    FxExecutionLeg          : undefined,
    FxExecutionReport       : undefined,
    FxTradeReportDelivery   : undefined,
};

export function runStateTransformer(item, params) {
    let addProps = {};
    const runState = (typeof item.runState === 'object') ? item.runState : item;

    if (runState.runPeriod && runState.runPeriod.businessObjectPrototype && globalRunStateConfig.businessObjectConfig[runState.runPeriod.businessObjectPrototype]) addProps[globalRunStateConfig.businessObjectConfig[runState.runPeriod.businessObjectPrototype].ownerProperty] = runState.runPeriod.businessObjectId;

    const workFlowName = runState.workflow ? globalRunStateConfig.workflowPrototypeToWorkFlowMap[runState.workflow.workflowPrototype] : undefined;

    return {
        ...runState,
        ...addProps,
        childRunStates: (runState.childRunStates && runState.childRunStates.length) ? runState.childRunStates.map(childRunState => runStateTransformer(childRunState, params)) : undefined,
        runStateParams: (runState.runStateParams && workFlowName && globalRunStateConfig.workflowConfig[workFlowName]) ? globalRunStateConfig.workflowConfig[workFlowName].runStateParamsTransformer(runState.runStateParams) : null,
    }
}
/*function createFakeBalance(ledgerAccountId, balanceNumber, balance, date) {
    return {
        ledgerAccountId : ledgerAccountId,
        id              : ledgerAccountId * 100 + balanceNumber,
        balanceId       : ledgerAccountId * 100 + balanceNumber,
        balance         : balance,
        valueDate       : date,
        balanceTime     : date + "T11:33:04.795Z",
        createdDateTime : date + "T11:33:05.795Z",
        comment:         "Hejsan",
    }
}*/
function ledgerAccountTransformer(item, params) {
    setParentIdIfDefined(item, 'ownerId', params ? params.parentId : undefined);
/*
    item.balances =     [
        createFakeBalance(item.ledgerAccountId, 1, 510, "2021-10-18"),
        createFakeBalance(item.ledgerAccountId, 2, 620, "2021-10-22"),
        createFakeBalance(item.ledgerAccountId, 3, 730, "2021-10-24"),
        createFakeBalance(item.ledgerAccountId, 4, 390, "2021-10-26"),
        createFakeBalance(item.ledgerAccountId, 5, 270, "2021-10-29"),
        createFakeBalance(item.ledgerAccountId, 6, 140, "2021-11-02"),
    ]
*/

    return {
        id: item.ledgerAccountId,
        balance: item.balance,
        requestStatus    : item.requestStatus ? item.requestStatus : "",
        errorCode        : item.errorCode     ? item.errorCode     : "",
        errorDescription : item.errorDescription ? item.errorDescription : "",
        balances: item.balances ? ormItemArrayTransformer('LedgerAccountBalance', item.balances, {
            ...params,
            parentId: item.id
        }) : undefined,
    }
}
function ledgerAccountBalanceTransformer(item, params) {
    setParentIdIfDefined(item, 'ledgerAccountId', params ? params.parentId : undefined);

    if (item.actions?.length) {
        const createdAction  = item.actions.find(item => item.action === 'REGISTER');
        if (createdAction) {
            item.createdByUser   = createdAction.userId;
            item.createdDateTime = createdAction.actionDateTime;
            item.createdComment  = createdAction.comment;
        }
        const disabledAction = item.actions.find(item => item.action === 'DELETE');
        if (disabledAction) {
            item.disabledByUser   = disabledAction.userId;
            item.disabledDateTime = disabledAction.actionDateTime;
            item.disabledComment  = disabledAction.comment;
        }
    }

    return {
        ...item,
        // id: item.balanceId,
        id: item.auditId,
    };
}
function currencyResponseTransformer(item) {
    if (item.currency === null)          item.currency = "";
    return item;
}

function currencyPairResponseTransformer(item) {
    item.baseCurrencyId    = item.baseCurrency  ? item.baseCurrency.id  : null;
    item.quoteCurrencyId   = item.quoteCurrency ? item.quoteCurrency.id : null;
    item.rate              = item.rate          ? item.rate             : null;
    item.key               = item.key           ? item.key              : "";
    item.validAgreementTypes = item.validAgreementTypes ? item.validAgreementTypes : [];
    item.currencyPairName  = item.baseCurrency?.currency + item.quoteCurrency?.currency;
    return item;
}

function countryResponseTransformer(item) {
    if (item.country === null)          item.country = "";
    return item;
}

function fxPriceResponseTransformer(item) {
    item.currencyPairId     = getNestedIdProperty2(item, 'currencyPair');
    item.indicator          = item.indicator        ? item.indicator        : "";
    item.rate               = item.midRate          ? item.midRate          : 1;
    item.time               = item.time             ? moment(item.time)     : null;
    item.fromCurrencyId     = item.fromCurrencyId   ? item.fromCurrencyId   : null;
    item.toCurrencyId       = item.toCurrencyId     ? item.toCurrencyId     : null;
    item.currencyPairId     = item.currencyPairId   ? item.currencyPairId   : null;
    return item;
}

function fixMessageResponseTransformer(item) {
    if (item.fixMessageDirection === 'IN_BOUND') {
        item.guiDirection = '<--';
    }
    else if (item.fixMessageDirection === 'OUT_BOUND'){
        item.guiDirection = '-->';
    } else {
        item.guiDirection = '??';
    }

    if (item.messageCat != null) {
        item.guiMessageCat = item.messageCat.toUpperCase();
    }


    const fieldArray = item.message.split('|');

    item.toolTip = fieldArray.join('\n');
    return item;
}

function fixSessionTransformer(item) {
    item.providerId            = item.providerId            ? item.providerId            : null;
    item.time                  = item.time                  ? moment(item.time)          : null;
    item.description           = item.description           ? item.description           : "";
    return item;
}
function nfcProcessStatusTransformer(item) {
    return {
        id: item.nfcProcessStatus.processPrototype + "." + item.nfcProcessStatus.processInstanceId,
        active: item.active,
        heartBeatType: item.heartBeatType,
        ...item.nfcProcessStatus,
        epocTimestamp: Number.parseInt(item.nfcProcessStatus.timestamp)
    }
}
export function erpIntegrationTransformer(item) {
    return {
        ...item,
        erpSystemId: getNestedIdProperty2(item, 'erpSystem'),
    };
}
export function createErpIntegrationBody(item) {
    return {
        id: item.id,
        erpEnabled: item.erpEnabled,
        erpSystem: setNestedIdProperty(item, 'erpSystemId')
    };
}

function erpSystemTransformer(item) {
    return {...item};
}

function operatingUnitTransformer(item) {
    transformExecutionRights(item);
    item.name        = item.name        ? item.name        : "";
    item.description = item.description ? item.description : "";
    item.email       = item.email       ? item.email       : "";
    item.phoneNumber = item.phoneNumber ? item.phoneNumber : null;
    item.default     = item.default     ? item.default     : false;
    return item;
}


function userTransformer(source) {
    let user = {...source, ...(source.user ? source.user : {})}; // Needed to handle audit User object with user data in nested user object.

    if (user.userId) {
        user.id = user.userId;
    }
    user.userId               = user.userId               ? user.userId               : null ;
    user.firstName            = user.firstName            ? user.firstName            : ""   ;
    user.lastName             = user.lastName             ? user.lastName             : ""   ;
    if (!user.name) { // Used in audit for root items.
        user.name                 = user.firstName + ' ' + user.lastName + ' (' + user.socialSecurityNumber + ')';
    }
    user.socialSecurityNumber = user.socialSecurityNumber ? user.socialSecurityNumber : ""   ;
    user.countryCode          = user.countryCode          ? user.countryCode          : ""   ;
    // item.topics               = item.topics               ? item.topics               : []   ; // User object from BE sometimes do not include topics. This should not overwrite correct values in ORM.
    user.state                = user.state                ? user.state                : ""   ;

    let profilesString = "";
    if (user.profiles?.length) {
        for (let key of user.profiles) {
            profilesString = profilesString + ", " + key;
        }
    }
    if (profilesString !== "") user.profilesStr = profilesString.slice(2);

    if (user.systemRights) {
        user.systemRights = {
            id                      : user.id                       ? user.id                       : null,
            user                    : user.user                     ? user.user                     : {},
            userId                  : user.user                     ? user.user.userId              : null,
            entitlements            : generateEntitlementsMap(user.systemRights.systemRightsEntitlements),
        };
    }

    if (user.dataRights  ) {
        const dataRights = []

        if (isArray(user.dataRights.dataRight)) {
            for (const dataRight of user.dataRights.dataRight) {
                dataRights.push({
                    id: dataRight.id,
                    prototype: dataRight.prototype,
                    shallowObject: dataRight.shallowObject,
                    entitlements: generateEntitlementsMap(dataRight.entitlements),
                })
            }
        }

        user.dataRights =  {
            id         : user.id    ? user.id          : null,
            user       : user.user  ? user.user        : {},
            userId     : user.user  ? user.user.userId : null,
            dataRights : dataRights,
        }
    }

    return user;
}

function createUserBody(user) {
    return {
        userId               : user.userId              ,
        firstName            : user.firstName           ,
        lastName             : user.lastName            ,
        socialSecurityNumber : user.socialSecurityNumber,
        countryCode          : user.countryCode         ,
        topics               : user.topics              ,
    }
}

function generateEntitlementsMap(rightsArray) {
    const transformedRights = {};
    if (isArray(rightsArray)) {
        for (const rightItem of rightsArray) {
            if (!transformedRights[rightItem.id.domain]) {
                transformedRights[rightItem.id.domain] = {};
            }
            transformedRights[rightItem.id.domain][rightItem.id.right] = rightItem.active;
        }
    }
    return transformedRights;
}

export function createUserDataRightsBody(dataRights) {
    return dataRights?.dataRights?.map(item => item.id);
}

function createUserSystemRightsBody(item) {

    // Transform back from entityPrototypeRights systemPrototypeRights.
    const transformedSystemRights = {};
    if (item.systemRights && (typeof item.systemRights === 'object')) {
        for (let [key, value] of Object.entries(item.systemRights)) {
            transformedSystemRights[key] = {
                id: value.id,
                systemPrototypeRights: value.entityPrototypeRights
            }
        }
    }
    return {
        id                      : item.id                       ? item.id                       : null,
        user                    : item.user                     ? item.user                     : null,
        systemRights            : transformedSystemRights,
    }
}


function noRunDateTransformer(item) {
    item.id   = item.id   ? item.id   : null;
    item.date = item.date ? item.date : null;
    return item;
}
function createNoRunDateBody(item) {
    return {
        id    : item.userId,
        date  : item.date  ,
    }
}

function sharedSettingTransformer(item) {
    // setItemDateTimes(item);
    item.name  = item.name  ? item.name  : "";
    item.state = item.state ? item.state : null;
    return item;
}
function createSharedSettingBody(item) {
    return {
        id    : item.id,
        name  : item.name,
        value : item.value,
    }
}
function topicTransformer(item) {
    return {topic: item};
}

const envParameterSelector = getAppEnvPropertySelector();
function tenorTransformer(item, params) {
    const tenorsSortOrder = envParameterSelector(params.getState(), 'tenorsSortOrder');
    item.id    = item.id    ? item.id    : null;
    item.name  = item.name  ? item.name  : ""  ;
    item.sortIndex = tenorsSortOrder.indexOf(item.name);
    item.validAgreementTypes = item.validAgreementTypes ? item.validAgreementTypes : [];
    return item;
}

export function transformRunConfiguration(runConfiguration, params) {
    if (!runConfiguration || !Object.keys(runConfiguration).length) return undefined;
    let returnObject = {id: runConfiguration.id}
    for (const type in runConfiguration) {
        if (type === 'id') continue;
        returnObject[type] = (runConfiguration[type] && runConfigurationConfig[type]) ? runConfiguration[type].map(item => runConfigurationConfig[type].inputTransformer(item, params)) : undefined;
    }
    return returnObject;
}

export function createRunConfigurationBody(runConfiguration, params) {
    if (!runConfiguration) return null;
    let returnObject = {}
    for (const type in runConfiguration) {
        if (!runConfiguration[type] || !runConfigurationConfig[type]) {
            continue;
        }
        returnObject[type] = runConfiguration[type].map(item => ({
            id                          : item.id                           ? item.id                           : undefined,
            type                        : item.type                         ? item.type                         : undefined,
            isActive                    : item.isActive                     ? item.isActive                     : false,
            ownedByRunConfigurationId   : item.ownedByRunConfigurationId    ? item.ownedByRunConfigurationId    : undefined,
            ...runConfigurationConfig[type].outputTransformer(item, params),
        }));
    }
    if (!Object.keys(returnObject).length) {
        return null;
    }
    if (runConfiguration.id) {
        returnObject.id = runConfiguration.id;
    }
    return returnObject;
}
