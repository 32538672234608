import React from 'react'
import 'features/refixClientWizard/refixClientWizard.css';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";


function RfWizardReviewAndSaveForm(props) {
    return (
        <div className="submitRow">
            <h3><XpTranslated trKey={'refixClientWizard.finalStepInfo'}/></h3>
        </div>
    );
}
export default RfWizardReviewAndSaveForm;
