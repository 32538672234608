import {canLegalEntityBeDisabled} from "features/legalEntity/legalEntityFunctions";
import {
    createStandardAuditFields2, createTranslatedFieldData,
    translateBoolFields,
    xpColCreate, xpColHeadCreate
} from "gui-common/xpGrid/xpGridFunctions";
import cloneDeep from "lodash.clonedeep";
import {stateRequestedOnId} from "gui-common/requestEntityState/requestEntityStateFunctions";


export function canClientBeDisabled(client) {

    let returnObject = {
        canBeDisabled: true,
        modalKey: 'clientForm.modalConfirmDisableClient',
        modalParams: {name: client.name}
    };
    // Base case: Empty client!
    if (!client.legalEntities || (client.legalEntities.length === 0)) return returnObject;

    // Nested case. Check next level!
    if (!client.useLegalEntities && (client.legalEntities.length === 1)) {
        const disableParams = canLegalEntityBeDisabled(client.legalEntities[0], true);
        if (disableParams.canBeDisabled) {
            return returnObject;
        } else {
            return {canBeDisabled: false, modalKey: disableParams.modalKey, modalParams: disableParams.modalParams};
        }
    }

    // default case. There are multiple LEs under the client.
    let childrenNames = "";
    for (let key in client.legalEntities) {
        childrenNames = childrenNames + "- " + client.legalEntities[key].name + "\n";
    }
    returnObject.modalKey = 'clientForm.modalClientHasEntities';
    returnObject.modalParams = {name: client.name, children: childrenNames};
    returnObject.canBeDisabled = false;

    return returnObject;
}

export function formatClientsGridData(dataArray, activeLanguage, translate) {

    let outArray = cloneDeep(dataArray);

    for (let item of outArray) {
        // translateDateFields(item, 'lll', activeLanguage);
        translateBoolFields(item, translate);
        createTranslatedFieldData(item, 'clientApiChannel'     , translate, "clientForm.clientApiChannel.values." + item.clientApiChannel);

        item.operatingUnitT   = item.operatingUnit ? item.operatingUnit.name : "";
    }
    return outArray;
}

export function prepareClientsGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState,'name', 120, 50, 'clientForm.name.label');
    xpColCreate(translate, subArray, hiddenState,'id', 100, 50, 'clientForm.id.label');
    xpColCreate(translate, subArray, hiddenState,'description', 200, 50, 'clientForm.description.label');
    xpColCreate(translate, subArray, hiddenState,'clientApiChannelT', 100, 50, 'clientForm.clientApiChannel.label', {cellRenderer: 'xpTranslatedRenderer'});

    xpColCreate(translate, subArray, hiddenState,'accountManager', 200, 50, 'clientForm.accountManager.label');
    xpColCreate(translate, subArray, hiddenState,'operatingUnitT', 200, 50, 'clientForm.operatingUnitId.label');
    xpColHeadCreate(translate, outArray, subArray, 'clientsList.clientInformation');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}
