import {createStandardAuditFields2, xpColCreate, xpColHeadCreate} from "gui-common/xpGrid/xpGridFunctions";
import {getNestedIdProperty} from "gui-common/functions/functions";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {deleteOrmItemToApi, getOrmItemsFromApi} from "gui-common/orm/ormApi";
import moment from "moment";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {ormEntitiesClearModel} from "gui-common/orm/ormReducer";

export function bankHolidayTransformer(item) {
    const transformItem = (source) => {
        source.currencyId = getNestedIdProperty(source, 'currency');
        return {
            ...source,
            currencyName : source?.currency?.currency,
            comment      : source.comment ? source.comment : "",
        }
    };

    if (Array.isArray(item)) return transformItem(item[0]);
    else                     return transformItem(item);
}

export function rootBankHolidayTransformer(item) {
    return {
        ...item,
        bankHolidays: item.bankHolidays?.length ? item.bankHolidays.map(bh => bankHolidayTransformer(bh)) : item.bankHolidays,
    }
}

export function bankHolidayBulkAuditTransformer(item) {
    return {
        name: item.numberOfItems,
        ...item,
    }
}

export function createBankHolidayBody(item, decDenLangState, operationType) {

    const transformItem = (source) => {
        return {
            id: item.id,
            currency: {id: item.currencyId},
            description: item.description,
            comment: item.comment,
            timezone: "Europe/Stockholm",
            startDateTime: item.date + "T00:00:00.000",
            endDateTime: item.date + "T23:59:59.999",
        }
    };
    return transformItem(item);


/*   Removed funktion to create array with bank holidays.
    if (Array.isArray(item)) {
        return item.map(transformItem);
    }
    else if ((operationType === 'POST')) {
        return [transformItem(item)];
    }
    else {
        return transformItem(item);
    }
*/
}

export const bankHolidaysHiddenState = {
    id                  : true,
    name                : true,
    currencyName        : false,
    date                : false,
    description         : false,
    comment             : false,
    createdDateTime     : false,
    createdByUser       : false,
    changedDateTime     : false,
    changedByUser       : false,
    disabledDateTime    : true,
    disabledByUser      : true,
};

export function prepareBankHolidaysColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id',           80 , 50 , 'bankHolidays.list.id');
    xpColCreate(translate, subArray, hiddenState, 'name',         120, 50 , 'bankHolidays.list.name');
    xpColCreate(translate, subArray, hiddenState, 'currencyName', 70 , 50 , 'bankHolidays.list.currencyName');
    xpColCreate(translate, subArray, hiddenState, 'date',         120, 50 , 'bankHolidays.list.date');
    xpColCreate(translate, subArray, hiddenState, 'description',  200, 50 , 'bankHolidays.list.description');
    xpColCreate(translate, subArray, hiddenState, 'comment',      200, 100, 'bankHolidays.list.comment');
    xpColHeadCreate(translate, outArray, subArray, 'bankHolidays.list.bankHolidayInformation');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}

export function deleteHolidayAfterConfirm (event) {
    return (dispatch, getState) => {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "bankHolidays.list.gridContextMenuItems.delete.confirmModal", modalParams: {
                currency: event.currencyName,
                date: event.date,
                holiday: event.description
            }}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    dispatch(deleteOrmItemToApi('BankHoliday', event))
                }
            })
    }
}

export function loadClosestSixtyDaysBankHolidays() {
    return (dispatch, getState) => {
        let urlFilter = {includeDisabled: false};
        urlFilter.fromDate = moment().subtract(60, 'days').format('YYYY-MM-DD');
        urlFilter.toDate   = moment().add(     60, 'days').format('YYYY-MM-DD');
        dispatch(getOrmItemsFromApi('BankHoliday', undefined, urlFilter));
    }
}

export function clearAndReloadBankHolidays() {
    return (dispatch, getState) => {
        dispatch(ormEntitiesClearModel('BankHoliday'));
        dispatch(loadClosestSixtyDaysBankHolidays());
    }
}
