import React from 'react'
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalLogMessage} from "../modalFunctions";
import {modalStandardParams} from "gui-common/modals/modalConstants";


function ModalInformation(props) {

    function confirmAction(message) {
        const { resultTypes: { MODAL_TYPE_CONFIRM }, popModalWindow } = props;
        modalLogMessage('User confirmed info message:', props);
        popModalWindow({ status: MODAL_TYPE_CONFIRM});
    }

    const showConfirm = props.confirmText ? props.confirmText : <XpTranslated trKey={props.modalKey ? (props.modalKey+'.confirm') : 'general.buttons.confirm'} fallbackKey='general.buttons.confirm' trParams={props.modalParams}/>;
    const showHeader  = props.headerText  ? props.headerText  : <XpTranslated trKey={props.modalKey ? (props.modalKey+'.header' ) : ''} trParams={props.modalParams}/>;
    const showMessage = props.messageText ? props.messageText : <XpTranslated trKey={props.modalKey ? (props.modalKey+'.message') : ''} trParams={props.modalParams}/>;

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal " + props.className}};

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Information modal"
                    // style={customStyles}
                    onRequestClose={(props) => confirmAction(showMessage)}
                    shouldCloseOnEsc={false}
                    {...modalParams}
                >
                    <div xp-test-id="xpModalInformation">
                        <h3 className ="xpoolModalHeader">{showHeader}</h3>
                        <hr/>
                        <span className="valueTextDark">{showMessage}</span>
                        <hr/>
                        <div className="xpoolModalButtonsCenter">
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonConfirm"}
                                label           = {showConfirm}
                                onClickCallback = {() => confirmAction(showMessage)}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
ModalInformation.propTypes = {
    modalKey:       PropTypes.string,
    modalParams:    PropTypes.object,
    headerText:     PropTypes.string,
    messageText:    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    confirmText:    PropTypes.string,
    resultTypes:    PropTypes.object.isRequired,
    popModalWindow: PropTypes.func.isRequired
};
export default ModalInformation;
