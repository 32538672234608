import {xpGridsReloadState, xpGridsResetState} from "gui-common/xpGrid/xpGridsReducer";
import {dashboardReloadState, dashboardResetState} from "gui-common/dashboard/dashboardReducer";
import {xpAccordionReloadState, xpAccordionResetState} from "gui-common/xpAccordion/xpAccordionReducer";
import {userPreferencesReloadState, userPreferencesResetState} from "gui-common/userSettings/userPreferencesReducer";
import {reloadSavedUserState, resetSavedUserState} from "gui-common/userSettings/sharedSettings/savedUserStatesReducer";
import {xpTabsReloadState, xpTabsResetState} from "gui-common/components/xpTabs/xpTabsReducer";

const commonUserStatesConfig = {
    "xpGridsState"           : {reloadFunction: xpGridsReloadState           , resetFunction: xpGridsResetState           },
    "dashboardState"         : {reloadFunction: dashboardReloadState         , resetFunction: dashboardResetState         },
    "xpAccordionState"       : {reloadFunction: xpAccordionReloadState       , resetFunction: xpAccordionResetState       },
    "xpTabsState"            : {reloadFunction: xpTabsReloadState            , resetFunction: xpTabsResetState            },
    "userPreferencesState"   : {reloadFunction: userPreferencesReloadState   , resetFunction: userPreferencesResetState   },
    "savedUserStatesState"   : {reloadFunction: reloadSavedUserState         , resetFunction: resetSavedUserState         },
}
export let globalUserStatesConfig;
export function setGlobalUserStatesConfig(appConfig) {
    globalUserStatesConfig = {...commonUserStatesConfig, ...appConfig}
}
