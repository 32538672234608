import React from 'react'
import {useSelector} from "react-redux";
import 'gui-common/processStatus/processStatus.css';
import ProcessStatusPanel from "gui-common/processStatus/ProcessStatusPanel";
import {processPrototypeStatusMapSelector} from "gui-common/processStatus/processStatusSelectors";
import {processStatusSortedPrototypes} from "gui-common/processStatus/processStatusConstants";

export default function ProcessStatusWidget (props) {
    const prototypeStatusMap = useSelector(processPrototypeStatusMapSelector);
    if (!prototypeStatusMap) return null;
    return (
        <div>
            {processStatusSortedPrototypes.map((prototype, index) => {
                if (!prototypeStatusMap[prototype]) {
                    return null;
                }
                // const lastEvent = (item.events.length > 0) ? item.events[0] : null;
                return (
                    <ProcessStatusPanel
                        processPrototype = {prototype}
                        processStatusMap = {prototypeStatusMap[prototype]}
                        key={prototype}
                        instanceId={props.instanceId + "-" + prototype}
                    />
                )})
            }
        </div>
    )
}


