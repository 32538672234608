import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {createIsoUtcStringFromMomentObject} from "gui-common/functions/functions";
import moment from "moment";
import XpDynamicContentModal from "gui-common/modals/modalComponents/XpDynamicContentModal";
import ReactJson from "react-json-view";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {postOrmItemToApi} from "gui-common/orm/ormApi";
import {postTradeReportEventToApi} from "gui-common/fxTradeReport/fxTradeReportApi";

const formName = 'fxTradeReportForm'
export function PostFxTradeReportModal(props) {

    const dispatch    = useDispatch();
    const [jsonTradeReport, setJsonTradeReport] = useState(props.tradeReport);
    const [eventEdited, setEventEdited] = useState(false);


    function cancelAction() {
        if (props.clearFormOnExit) {
            dispatch(xpFormRemoveForm(formName));
        }
        props.popModalWindow({ status: MODAL_TYPE_CANCEL });
    }
    function updateEvent(params) {
        setJsonTradeReport(params.updated_src)
        setEventEdited(true);
    }

    return (
        <XpDynamicContentModal
            cancelAction={cancelAction}
            modalBaseClassName={"xpoolModal xpoolModalLangEdit"}
            header={(
                <div>
                    <h3 className="xpoolModalHeader">Create trade report</h3>
                    <hr/>
                    <p className="valueTextDark">Enter json structure for new trade report event in text field below.</p>
                    <hr/>
                </div>
            )}
            content={(
                <div style={{fontSize: 'var(--xpool-label-font-size)'}}>

                    <XpForm
                        formModel={formName}
                        onSubmit={(formData) => {
                            let tradeReport;
                            try {
                                tradeReport = formData.tradeReportJson ? JSON.parse(formData.tradeReportJson) : jsonTradeReport;
                            }
                            catch (e) {
                                console.log("Failed to parse JSON", e);
                                return;
                            }
                            dispatch(postTradeReportEventToApi(tradeReport))
                                .then(res => {
                                    props.popModalWindow({ status: MODAL_TYPE_CONFIRM, tradeReport: tradeReport});
                                    if (props.clearFormOnExit) {
                                        dispatch(xpFormRemoveForm(formName));
                                    }
                                })
                        }}
                        initialUseState={XP_FORM_EDIT}
                        initialFormData={{comment: ""}}
                    >
                        {props.tradeReport ?
                            <ReactJson
                                displayObjectSize sortKeys name={'replaced'} iconStyle={'circle'} src={props.tradeReport}
                                onEdit={params => {
                                    updateEvent(params);
                                    console.log("Edit:", params);
                                }}
                                onAdd={params => {
                                    updateEvent(params);
                                    console.log("Add:", params);
                                }}
                                onDelete={params => {
                                    updateEvent(params);
                                    console.log("Delete:", params);
                                }}
                            />
                        :
                            <XpFormTextAreaInput
                                label         = "Json input"
                                field         = "tradeReportJson"
                                maxLength     = {10000}
                                autoFocus     = {true}
                            />
                        }
                        <XpFormTextInput
                            label         = "Comment"
                            field         = "comment"
                        />
                    </XpForm>
                </div>
            )}
            footer={(
                <div>
                    <hr/>
                    <div className="xpoolModalButtons">
                        <WaitingXpoolButton
                            label           = 'Create event'
                            onClickCallback = {() => dispatch(xpFormSubmitForm(formName))}
                            waiting         = {false}
                            disabled        = {false}
                        />
                        <WaitingXpoolButton
                            xpTestId        = {"xpModalButtonCancel"}
                            labelKey        = 'general.buttons.cancel'
                            onClickCallback = {() => cancelAction()}
                        />
                    </div>
                </div>
            )}
        />
    );
}

/*
const fxTradeReport = {
    orderId: "123",
    fxExecutionReports: [{
        executedDateTime: createIsoUtcStringFromMomentObject(moment()),
        UTI: "test-UTI",
        execId: formData.execId,
        message: "test-msg",
        price: formData.price,
        spotRate: formData.spotRate,
        forwardPoints: formData.forwardPoints,
        settlementDate: formData.settlementDate,
    }],
    reference: "test-ref",
    account: formData.account,
    amount: formData.amount,
    productType: "FWD",
    currency: formData.currency,
    symbol: formData.symbol,
    side: formData.side,
    tenor: formData.tenor,
    settlementDate:formData.settlementDate,
    status: formData.status,
    statusMessage: "test-msg",
}
*/

/*
{
    "orderId": "123",
    "fxExecutionReports": [{
        "executedDateTime": "2023-07-12T14:23:01.123Z",
        "UTI": "test-UTI",
        "execId": "hejsan-execId",
        "message": "test-msg",
        "price":12.3456,
        "spotRate": 1.234,
        "forwardPoints": 0.1234,
        "settlementDate": "2023-07-23"
    }],
    "reference": "test-ref",
    "account": "fish-accounts",
    "amount": 2000,
    "productType": "FWD",
    "currency": "SEK",
    "symbol": "EUR/SEK",
    "side": "BUY",
    "tenor": "1W",
    "settlementDate": "2023-07-23",
    "status": 5,
    "statusMessage": "test-msg"
}
*/
