import PropTypes from "prop-types";
import React, {useMemo, useState} from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpDateTime} from "gui-common/components/XpDateTime";
import {AuditBulkItemsList} from "gui-common/audit/actionRenderers/AuditBulkItemsList";

import cloneDeep from "lodash/cloneDeep";
import AuditCrudRenderer from "gui-common/audit/actionRenderers/AuditCrudRenderer";
import XpSplitPane from "gui-common/components/XpSplitPane";
import ReactJson from "react-json-view";
import {useAuditModelConfig} from "gui-common/audit/auditFunctions";

const listInstanceId = "bulkAuditItemsList"
function AuditBulkCrudActionRenderer (props) {
    const modelConfig = useAuditModelConfig(props.auditEntry);
    const [selectedItem, setSelectedItem] = useState();
    // const ModelRenderer = modelConfig?.getRenderComponent();

    const selectedItemGeneratedAuditEntry = useMemo(
        () => {
            if (!selectedItem) {
                return undefined;
            }
            return {
                apiVersion: props.auditEntry.apiVersion,
                action: selectedItem.action,
                user: props.auditEntry.user,
                affected: selectedItem.itemData,
                affectedOrmModel: props.auditEntry.affectedOrmModel,
                affectedId: selectedItem.itemData.id,
                affectedName: selectedItem.itemData.name,
                affectedDescription: selectedItem.itemData.description,
                data: selectedItem,
                rawAuditData: cloneDeep(selectedItem),
            }
        },
        [selectedItem]
    );

    if (!modelConfig) {
        console.error("No model config found for audit record", props.auditRecord);
        return null;
    }


    return (
        <div>
            <h3 className={'formHeaderEditNew'}>
                <XpTranslated trKey={'auditView.actionTypes.FileUpload.header'}/>
                {': '}
                <span className="standardTextColor">
                    <XpTranslated trKey={'general.modelNamesLarge.' + props.auditEntry.affectedOrmModel}/>
                </span>
            </h3>
            <div className='xpAdminFormEntityCard'>
                <div className="adminFormContainer">
                    <div className="adminColumn">
                        {props.auditEntry.user &&
                            <XpFormStaticField inLineLayout labelTrKey='userPreferencesForm.userName' value={props.auditEntry.user.firstName + ' ' + props.auditEntry.user.lastName + ' (' + props.auditEntry.user.userId + ')'}/>
                        }
                        <XpFormStaticField inLineLayout labelTrKey='auditUserActionTypes.actionDisplayHeader.requestedDateTime'   value={<XpDateTime isoString={props.auditEntry.auditDateTime} format='lll'/>}/>
                        <XpFormStaticField inLineLayout labelTrKey='auditView.systemComponent'  value={props.auditEntry.processName + ' (' + props.auditEntry.processInstance + ')'}/>
                        <XpFormStaticField inLineLayout labelTrKey='auditView.actionTypes.FileUpload.nbrOfItems' value={props.auditEntry.actionInfo?.NbrOfItems}/>
                    </div>
                    <div className="adminColumn" style={{flex: '2 1 auto'}}>
                        <label><XpTranslated trKey={'auditView.actionTypes.FileUpload.listHeader'} /></label>
                        <AuditBulkItemsList
                            instanceId  = {listInstanceId}
                            modelConfig = {modelConfig}
                            data = {props.auditEntry.data}
                            itemSelectedCallback = {item => setSelectedItem(item)}
                        />
                    </div>
                        <div className="adminColumn">
                            <label><XpTranslated trKey={'auditView.actionTypes.FileUpload.selectedItem'} /></label>
                            {selectedItemGeneratedAuditEntry &&
                            <AuditCrudRenderer
                                auditEntry={selectedItemGeneratedAuditEntry}
                                bypassAccordion={true}
                            />}
                        </div>
                </div>
            </div>
            {props.devMode && !props.useHistoricAudit && props.auditEntry?.rawAuditData &&
                <div>
                    <h3 style={{marginBottom: '5px', borderBottom: 'var(--xpool-form-field-border)'}}><XpTranslated trKey={'auditView.historic.jsonView'}/></h3>
                    <XpSplitPane defaultWidthPercent={50}>
                        <div style={{fontSize: 'var(--xpool-label-font-size)'}}>
                            <ReactJson collapsed displayObjectSize sortKeys name={'created'} iconStyle={'circle'} src={props.auditEntry.rawAuditData.created} />
                        </div>
                        <div style={{fontSize: 'var(--xpool-label-font-size)'}}>
                            <ReactJson collapsed displayObjectSize sortKeys name={'deleted'} iconStyle={'circle'} src={props.auditEntry.rawAuditData.deleted} />
                        </div>
                    </XpSplitPane>
                </div>
            }
        </div>
    )
}
AuditBulkCrudActionRenderer.propTypes = {
    auditEntry: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired
};
export default AuditBulkCrudActionRenderer



