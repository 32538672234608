// Main CSS import sequence for gui-common. The order is important. Later imports overrides earlier.
// The CSS loading in the gui-common appStartup will be the first loaded. All later imports in the app
// or in other gui-startup libraries will override the css loads below.
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'
import 'gui-common/css/globalCssVariables.css'
import 'gui-common/css/generalElements.css'
import 'gui-common/css/bootstrapMenu.css'
import 'gui-common/css/generalForms.css'
import 'gui-common/css/generalTables.css'
import 'gui-common/css/filterForms.css'
import 'gui-common/css/xpMaterialUiClasses.css'
import 'gui-common/appLocale/xpTranslated/appLocale.css'
import 'gui-common/xpForm/core/xpForm.css'
import "gui-common/css/xpChart.css"
import 'gui-common/userMessages/userMessages.css';

import {Provider} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import Favicon from "react-favicon";
import {ConnectedRouter} from "connected-react-router";
import configureStore from "gui-common/app/store";
import IeNotSupported from "gui-common/components/IeNotSupported";
import RedirectToLoginPage from "gui-common/components/RedirectToLoginPage";
import nfcIcon from "gui-common/resources/nfc_icon.ico";
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=Nordic Financial Components AB,LicensedApplication=xpool,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-008654,ExpiryDate=24_June_2021_[v2]_MTYyNDQ4OTIwMDAwMA==bceccdb96675b256baa876377a3c30dc");

export function renderAppFromIndexJs(appConfig) {
    let rootElement = document.getElementById('root');

    const appStore = configureStore(appConfig);

    const renderPage = (content, rootElementStyling) => {
        console.log("Render page");

        ReactDOM.render(
            <div style={rootElementStyling}>
                <Favicon url={nfcIcon}/>
                {content}
            </div>,
            rootElement
        )
    };

    if (!window.oxyGenEnvConfiguration) {
        renderPage(
            <div>
                <h2> Installation error!</h2>
                <h2> No client-env-config.js file found in root directory. </h2>
            </div>
        )
        return;
    }
    if (!window.oxyGenClientConfiguration) {
        renderPage(
            <div>
                <h2> Installation error!</h2>
                <h2> No client-config.js file found in root directory. Cannot start without client configuration. </h2>
            </div>
        )
        return;
    }
    if (!window.oxyGenConfigurationOverride) console.warn("WARNING: No client-config-override.js loaded.");


    console.log("Navigator = ", navigator.appVersion);
    console.log("Build number:", window.oxygen_env.BUILD_NUMBER);
    console.log("Branch name :", window.oxygen_env.BRANCH_NAME);
    console.log("Commit:", window.oxygen_env.COMMIT_HASH);


    const appVersion = navigator.appVersion;
    const isIE = appVersion.indexOf("Trident") !== -1;

    if (isIE) { // First verify that browser is not IE
        renderPage(<IeNotSupported/>);
        return;
    }
    // OK, we are not running IE.

    // Check login credentials and redirect if not available.
    const rerouteUrl         = window.oxygen_env.AUTHENTICATION_URL;
    const noBackendMode      = window.oxygen_env.NO_BACKEND_MODE;
    const loginParamsFromUrl = getUrlLoginParams();

    if (noBackendMode || !loginParamsFromUrl) { // Check if application is started without a valid session, if so, redirect and show redirect page

        if (appConfig.renderAppWithNoSession) {
            renderPage(
                <Provider store={appStore}>
                    <appConfig.renderAppWithNoSession appConfig={appConfig}/>
                </Provider>
                , appConfig.rootElementStyling
            );
        }
        else {
            window.location.assign(rerouteUrl); // Use replace if "back" should not be possible... and change to login URL
            renderPage(<RedirectToLoginPage />);
        }
        return;
    }

    // expose store when run in Cypress
    if (window.Cypress) window.xpStore = appStore;

    let render = () => {
        const hist = require("gui-common/app/store.js").history;

        renderPage(
            <Provider store={appStore}>
                <ConnectedRouter history={hist}>
                    {/*<div style={{minHeight: "90%"}}>*/}
                        {/*<Favicon url={nfcIcon}/>*/}
                        <appConfig.appComponent appConfig={appConfig} initialLoginKeys={loginParamsFromUrl}/>
                    {/*</div>*/}
                </ConnectedRouter>
            </Provider>, appConfig.rootElementStyling
        );
    }

    render();
}
function getUrlLoginParams() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const ret =  {
        key         : (url && url.searchParams) ? url.searchParams.get("key")         : null,
        sessionId   : (url && url.searchParams) ? url.searchParams.get("sessionId")   : null,
        referenceId : (url && url.searchParams) ? url.searchParams.get("referenceId") : null,
    }
    return (ret.key && ret.sessionId && ret.referenceId) ? ret : undefined;
}

