export const commonSystemRightsDomainMap = {
    Audit: 'Audit',
    SuperAudit: 'SuperAudit',
    NoRunDate: 'NoRunDate',
    BankHolidays: 'BankHolidays',

    SharedSetting: 'SharedSettings',
    SystemText: 'SystemText',
    FixLog: 'ShowFixLog',

    FxRejectConfiguration: 'FxRejectConfiguration',
    CurrencyPairSettings: 'CurrencyPairSettings',

    ProcessStatus: 'ProcessStatus',

    UmTopicAdmin:'UmTopicAdmin',
    UmTopicInfo:'UmTopicInfo',
    UmTopicSupport: 'UmTopicSupport',
}
export const commonSystemRights = {
    Audit: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.Audit},
    },
    SuperAudit: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.SuperAudit},
    },

    NoRunDate: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.NoRunDate},
        Create      : {right:'Create'       , domain: commonSystemRightsDomainMap.NoRunDate},
        Delete      : {right:'Delete'       , domain: commonSystemRightsDomainMap.NoRunDate},
    },
    BankHolidays: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.BankHolidays},
        Create      : {right:'Create'       , domain: commonSystemRightsDomainMap.BankHolidays},
        Update      : {right:'Update'       , domain: commonSystemRightsDomainMap.BankHolidays},
        Delete      : {right:'Delete'       , domain: commonSystemRightsDomainMap.BankHolidays},
    },

    SharedSetting: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.SharedSetting},
        Create      : {right:'Create'       , domain: commonSystemRightsDomainMap.SharedSetting},
        Update      : {right:'Update'       , domain: commonSystemRightsDomainMap.SharedSetting},
        Delete      : {right:'Delete'       , domain: commonSystemRightsDomainMap.SharedSetting},
    },
    SystemText: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.SystemText},
        Create      : {right:'Create'       , domain: commonSystemRightsDomainMap.SystemText},
    },
    FixLog: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.FixLog},
    },

    FxRejectConfiguration: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.FxRejectConfiguration},
        Update      : {right:'Update'       , domain: commonSystemRightsDomainMap.FxRejectConfiguration},
    },
    CurrencyPairSettings: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.CurrencyPairSettings},
        Update      : {right:'Update'       , domain: commonSystemRightsDomainMap.CurrencyPairSettings},
    },

    ProcessStatus: {
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.ProcessStatus},
    },

    UmTopicAdmin: {
        Create      : {right:'Create'       , domain: commonSystemRightsDomainMap.UmTopicAdmin},
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.UmTopicAdmin},
    },
    UmTopicInfo: {
        Create      : {right:'Create'       , domain: commonSystemRightsDomainMap.UmTopicInfo},
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.UmTopicInfo},
    },
    UmTopicSupport: {
        Create      : {right:'Create'       , domain: commonSystemRightsDomainMap.UmTopicSupport},
        Read        : {right:'Read'         , domain: commonSystemRightsDomainMap.UmTopicSupport},
    },
}

export const commonDataRights = {
    CRUD: {
        Edit     : 'Edit',
        Update   : 'Update',
        EditBase : 'EditBase',
        Delete   : 'Delete',
    },
}

/*
export const globalUserRightsConfig = {};
export function setUserRightsConfig(appConfig) {
    globalUserRightsConfig.modelToPrototypeMap = appConfig.userRights?.modelToPrototypeMap;
}
*/
