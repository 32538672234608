import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {
    getXpFormRootSelector,
    getXpFormStateSelector,
    getXpFormUseStateSelector
} from "gui-common/xpForm/core/xpFormSelectors";
import {useDispatch} from "react-redux";
import {
    xpFormClearSubmit,
    xpFormLoadForm,
    xpFormSetFieldTouched,
    xpFormSubmitForm
} from "gui-common/xpForm/core/xpFormReducer";
import cloneDeep from "lodash/cloneDeep";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

export const XpFormContext = React.createContext(undefined);
function XpForm (props) {

    const useMuiFieldStyleFromEnv = useAppEnvProperty( 'useMuiFieldStyle');
    const formRoot     = useSelectorInstance(getXpFormRootSelector    , {model: props.formModel})
    const formState    = useSelectorInstance(getXpFormStateSelector   , {model: props.formModel})
    const formUseState = useSelectorInstance(getXpFormUseStateSelector, {model: props.formModel})
    const dispatch  = useDispatch();
    const noFormValidation = useAppEnvProperty('noFormValidation');

    // Effect responsible for setting up the form when default is edit.
    useEffect(
        () => {
            if (!formState && props.initialUseState) {
                dispatch(xpFormLoadForm(props.formModel, props.initialFormData, props.initialUseState));
            }
        },
        [],
    );
    function removeMetadataFromFormData(statePointer) {
        for (const field in statePointer) {
            if (typeof statePointer[field] === 'object') removeMetadataFromFormData(statePointer[field]);
            if (field[0] === '$') delete statePointer[field];
        }
    }
    function formHasFieldErrors(statePointer, errorObject) {
        if (noFormValidation) return false;

        let errorFound = false;
        for (const field in statePointer) {
            if (field[0] === '$') {
                if (statePointer[field].errors) {
                    errorObject[statePointer[field].fieldModel] = statePointer[field].errors;
                    errorFound = true;
                    if (!statePointer[field].isTouched) dispatch(xpFormSetFieldTouched(statePointer[field].fieldModel))
                }
                continue;
            }
            if ((statePointer[field] === undefined) || statePointer[field] === null) continue;
            if (typeof statePointer[field] === 'object') {
                if (formHasFieldErrors(statePointer[field], errorObject)) errorFound = true;
            }
        }
        return errorFound;
    }
    // Effect responsible for checking submit requests and acting on the request.
    useEffect(
        () => {
            if (!formState?.submitRequested) return;
            const errorObject = {};
            if (formHasFieldErrors(formRoot, errorObject)) {
                console.warn("Form contains errors, cannot submit! ",  errorObject, props.formModel, formRoot);
            }
            else {
                let submitObject = cloneDeep(formRoot);
                removeMetadataFromFormData(submitObject);
                props.onSubmit(submitObject);
            }
            dispatch(xpFormClearSubmit(props.formModel));
        },
        [formState?.submitRequested],
    );
    const contextData = useMemo(
        () => {
            return {
                formModel        : props.formModel,
                rootFormModel    : props.formModel,
                currentData      : props.currentData,
                beforeChangeData : props.beforeChangeData,
                rootCurrentData      : props.currentData,
                rootBeforeChangeData : props.beforeChangeData,
                formUseState     : formUseState,
                formTemplate     : props.formTemplate ? props.formTemplate : props.formModel,
                auditMode        : props.auditMode,
                useMuiFieldStyle : props.useMuiFieldStyle ? props.useMuiFieldStyle : useMuiFieldStyleFromEnv,
                ...props.consumerProps,
            }
        },
        [props.currentData, props.beforeChangeData, props.formTemplate, formUseState, props.consumerProps],
    );

    return (
        <form
            className={props.className}
            style={props.style}
            onSubmitCapture={event => {
                if (props.submitOnEnter) dispatch(xpFormSubmitForm(props.formModel));
                event.preventDefault();
            }}
        >
            <XpFormContext.Provider value={contextData}>
                {props.children}
            </XpFormContext.Provider>
        </form>
    )
}
XpForm.propTypes = {
    formModel       : PropTypes.string.isRequired,
    currentData     : PropTypes.object,
    beforeChangeData: PropTypes.object,
    formTemplate    : PropTypes.string,
    initialUseState : PropTypes.string,
    initialFormData : PropTypes.object,
    className       : PropTypes.string,
    style           : PropTypes.object,
    onSubmit        : PropTypes.func,
    onSubmitFail    : PropTypes.func,
    auditMode       : PropTypes.bool,
    consumerProps   : PropTypes.object,
    useMuiFieldStyle: PropTypes.bool,
};
export default XpForm



