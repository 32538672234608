import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {orm} from "gui-common/orm/orm";

export const getErpIntegrationSelector = () => ormCreateSelector(
    orm, dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, erpIntegrationId) => {
        if (!erpIntegrationId) return undefined;
        const erpIntegrationModel = getOrmModelObjectById(session, 'ErpIntegration', erpIntegrationId);
        if (!erpIntegrationModel) {
            console.warn("No ERP integration found for id ", erpIntegrationId);
            return undefined;
        }
        return {
            ...erpIntegrationModel.ref,
        }
    }
);
