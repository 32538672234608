import {registerOrmModels} from "gui-common/orm/ormModels";
import refixLogo from "appConfig/refixLogo.png";
import MarginGroupsPage from "features/marginGroup/MarginGroupsPage";
import ClientSettingsPage from "features/client/ClientSettingsPage";
import WelcomeScreen from "features/welcomeScreen/WelcomeScreen";
import {
    refixNestedOrmEntities,
    refixOrmModelCompareFunctions,
    refixOrmModelParentProperties,
    refixOrmModels
} from "appConfig/orm/ormConfig";
import {refixModals} from "appConfig/appModals";
import {
    refixAuditActionConfig,
    refixAuditModelConfig,
    refixAuditPrototypeMap
} from "appConfig/audit/auditConfig";
import {refixDashboardWidgets} from "appConfig/dashboardConfig";
import {refixReportingDataSetTypes} from "appConfig/report/appReportConstants";
import {
    fixAccountsSelector,
    marketSimulatorActiveConfigurationsSelector,
    simulatorAvailableTenorsSelector
} from "appConfig/simulatorsSelectors";
import {refixWizards} from "appConfig/wizardConfig";
import {refixLeftMenuItems, RefixSettingsMenu} from "appConfig/appMenuConfig";
import {shallowActiveClientsSelector} from "features/client/clientSelectors";
import {refixApiConfig} from "appConfig/api/apiConfig";
import {refixMaterialUiOverrides} from "appConfig/materialUiOverrides";
import {getRefixEnvParams} from "appConfig/appEnvConfig";
import {refixRunConfigurations} from "appConfig/runConfiguration/runConfigurationConstants";
import RefixApp from "appConfig/RefixApp";
import PriceTolerancesPage from "../features/priceTolerances/PriceTolerancesPage";
import {
    refixProcessStatusConfig,
    refixProcessStatusPackageConfig,
    refixProcessStatusSortedPrototypeList
} from "appConfig/processStatusConfig";
import {activeCurrencyPairsSelector} from "gui-common/orm/ormSelectors";
import {NavDropdown} from "react-bootstrap";
import {pushModalWindow} from "redux-promising-modals";
import {POST_FX_TRADE_REPORT_DIALOG} from "gui-common/modals/modalConstants";

export const refixAppConfig = {
    appLogo: refixLogo,
    appName: "refix",
    useContextMenu: true,
    useFxRejectConfig: true,
    useCurrencyPairSettings: true,
    useLedger: false,
    useBankHolidays: true,
    useNoRunDates: true,
    useFx: true,
    styling: {
        muiStylingOverrides: refixMaterialUiOverrides,
    },
    orm: {
        models: refixOrmModels,
        nestedEntities: refixNestedOrmEntities,
        parentProperties: refixOrmModelParentProperties,
        compareFunction: refixOrmModelCompareFunctions,
        onUpdateThunks: undefined,
    },
    api: refixApiConfig,
    lang: {
        files: [
            require('appConfig/appSpecificLang.json'),
            require('features/legalEntity/legalEntityLang.json'),
            require('features/legalEntityUnit/legalEntityUnitLang.json'),
            require('features/client/clientLang.json'),
            require('features/listRate/listRateLang.json'),
            require('features/currencyExposure/currencyExposureLang'),
            require('features/dispatchTrade/dispatchTradeLang'),
            require('features/listRateOrder/listRateOrderLang'),
            require('features/listRateOrderAllocation/listRateOrderAllocationLang.json'),
            require('features/agreement/agreementLang'),
            require('appConfig/report/appReportLang.json'),
            require('features/marketOrder/marketOrderLang'),
            require('features/refixClientWizard/refixClientWizardLang'),
            require('features/welcomeScreen/welcomeScreenLang.json'),
            require('features/marginGroup/marginGroupLang.json'),
            require('features/priceTolerances/priceTolerancesLang.json'),
            require('appConfig/runConfiguration/runConfigurationLang.json'),
        ],
        skipCommon: false,
    },
    userRights: {
        modelToPrototypeMap: {
            OperatingUnit           : ["OperatingUnitEntity"],
            Client                  : ["ClientEntity"],
            LegalEntity             : ["LegalEntityEntity"],
            LegalEntityUnit         : ["LegalEntityUnitEntity"],
            Agreement               : ["ListRateAgreementEntity", "MarketOrderAgreementEntity", "MarketOrderAllAgreementEntity", "ReferenceRateAgreementEntity", "PriceFeedAgreementEntity", "TradeReportAgreementEntity"],
            ListRate                : ["ListRateEntity"],
            DispatchTrade           : ["DispatchTradeEntity"],
            ListRateOrder           : ["ListRateOrderEntity"],
        }
    },
    userSettings: {
        saveTimer: 5000,
        savedUserStates: undefined,
        widgets: []
    },
    audit: {
        appActionConfig: refixAuditActionConfig,
        modelConfig: refixAuditModelConfig,
        prototypeMap: refixAuditPrototypeMap,
    },
    runConfiguration: refixRunConfigurations,
    dashboard: {
        widgets: refixDashboardWidgets,
    },
    report: {
        dataSetTypes: refixReportingDataSetTypes,
    },
    wizard: {
        wizards: refixWizards,
    },
    simulators: {
        marketSimulator: {
            baseUrl: '/test-market/v1/',
            fixAccountsSelector: fixAccountsSelector,
            activeConfigurationsSelector: marketSimulatorActiveConfigurationsSelector,
            availableCurrencyPairsSelector: activeCurrencyPairsSelector,
            availableTenorsSelector: simulatorAvailableTenorsSelector,
        }
    },
    userMessages: {
        gotoClientThunks: undefined,
        clientsSelector: shallowActiveClientsSelector,
    },
    modals: refixModals,
    mainMenu: {
        leftMenuItems: refixLeftMenuItems,
        rightMenuItems: [],
        SettingsMenu: RefixSettingsMenu,
        getDevMenuItems: (reduxStore, oxygenAppContext) => {
            return [
                <NavDropdown.Item key = 'createTradeReport' onClick={() =>
                    reduxStore.dispatch(pushModalWindow(POST_FX_TRADE_REPORT_DIALOG, {clearFormOnExit: false}))
                        .then((result) => {
                        })
                }>Simulate trade report</NavDropdown.Item>
            ]
        },
        devMenuItems: [],
    },
    routerPaths: [
        {path: "/welcome"          , component: WelcomeScreen},
        // {path: "/refixClientWizard", component: RefixClientWizardPage},
        {path: "/marginGroupConfig", component: MarginGroupsPage},
        {path: "/priceToleranceConfig", component: PriceTolerancesPage},
        {path: "/clientSettings"   , component: ClientSettingsPage},
    ],
    processStatus: {
        processConfig: refixProcessStatusConfig,
        packageConfig: refixProcessStatusPackageConfig,
        prototypeListSorted: refixProcessStatusSortedPrototypeList,
    },
    erp: true,
    reducers : {},
    envParams: getRefixEnvParams(),
    appComponent: RefixApp,
    useFxTradeReport: true,
}
registerOrmModels(refixAppConfig);




