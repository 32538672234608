import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {selectAppEnvState} from "gui-common/app/appEnvSelectors";
import {userPreferencesSetProp} from "gui-common/userSettings/userPreferencesReducer";

export function useAppUserPreferences(appConfig) {
    const dispatch = useDispatch();
    const envState = useSelector(selectAppEnvState);

    useEffect(
        () => {
            if (!appConfig.userSettings?.userPreferences) {
                return; // No app configured user preferences
            }
            for (const userPreference of appConfig.userSettings.userPreferences) {
                if (envState[userPreference.envPropName] === undefined) {
                    console.warn("User preference configured in appConfig but not in env config: ", userPreference.propName, envState[userPreference.envPropName]);
                    continue;
                }
                dispatch(userPreferencesSetProp(userPreference.propName, envState[userPreference.envPropName]));
            }
        },
        [],
    );
}
