import spinnerGif from "gui-common/resources/spinner.gif";
import PropTypes from "prop-types";
import React from "react";
import XpTooltip from "gui-common/components/XpTooltip";
import {useDispatch} from "react-redux";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import ToggleButton from "@mui/material/ToggleButton";

function WaitingXpoolToggleButton (props) {
    const dispatch          = useDispatch();
    const modalToLaunch     = props.modalType ? props.modalType : CONFIRMATION_DIALOG;

    const onClick = (params) => {
        if (!props.modalKey) {
            props.onClickCallback(params);
            return;
        }
        dispatch(pushModalWindow(modalToLaunch, {modalKey: props.modalKey, modalParams: props.modalParams, ...props.modalProps}))
            .then((result) => {
                if (result.status === MODAL_TYPE_CONFIRM) props.onClickCallback(params, result);
            });
    };

    if (!props.label && !props.labelKey) {
        console.error("Neither label nor labelKey provided to XpWaitingButton");
        return;
    }
    const labelToShow = props.label ? props.label : <XpTranslated trKey={props.labelKey} trParams={props.labelParams}/>;
    const activeToolTipParamsToDisplay   = props.toolTip ? {title: props.toolTip} : {trKey: props.toolTipKey, trParams: props.toolTipParams};
    const disabledToolTipParamsToDisplay = props.disabledToolTip ? {title: props.disabledToolTip} : {trKey: props.disabledToolTipKey, trParams: props.disabledToolTipParams};

    const toolTipParamsToDisplay = (props.disabled && props.disabledToolTip) ? disabledToolTipParamsToDisplay : activeToolTipParamsToDisplay;

    return (
        <XpTooltip {...toolTipParamsToDisplay}>
            <div style={{display: 'inline-block', float: (props.floatRight ? "right" : "none"), flexShrink: '0', width: props.fillWidth ? '100%' : undefined}}>
                <ToggleButton
                    disabled={props.disabled || props.waiting}
                    value="check"
                    selected={!!props.isActive}
                    size="small"
                    color="primary"
                    onChange={params => onClick(params)}
                    autoFocus={props.autoFocus}
                    style={{width: props.fillWidth ? '100%' : undefined}}
                >
                    <span>
                    {props.disabled ?
                        <React.Fragment>{labelToShow}</React.Fragment>
                        :
                        <div>
                            <p style={{visibility: 'hidden', verticalAlign: 'middle', margin: '0'}}>{labelToShow}</p>
                            <div style={{position: 'absolute', top: '0px', right: '0', bottom: '0', left: '0', margin: 'auto', height: '50px', lineHeight: '50px'}}>
                                {labelToShow}
                            </div>
                            {props.waiting && <img className="waitingButtonSpinner" src={spinnerGif} alt="waiting"/>}
                        </div>
                    }
                    </span>
                </ToggleButton>
            </div>
        </XpTooltip>
    )
}
WaitingXpoolToggleButton.propTypes = {
    onClickCallback : PropTypes.func.isRequired,
    label           : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelKey        : PropTypes.string,
    labelParams     : PropTypes.object,
    className       : PropTypes.string,
    xpTestId        : PropTypes.string,
    toolTip         : PropTypes.string,
    disabled        : PropTypes.bool,
    waiting         : PropTypes.bool,
    floatRight      : PropTypes.bool,
    modalKey        : PropTypes.string,
    modalParams     : PropTypes.object,
    fillWidth       : PropTypes.bool,
};
export default WaitingXpoolToggleButton


