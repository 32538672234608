import React from 'react'
import PropTypes from 'prop-types';
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import {PriceMargin} from "features/marginGroup/formComponents/PriceMargin";

export function MarginGroupBaseSettings (props) {
    return (
        <div className={props.className}>
            <XpFormFieldset
                formTemplate={'runConfiguration.marginGroupOverride'}
            >
                <PriceMargin hideCurrencyPair showPriceType/>
            </XpFormFieldset>
        </div>
    );
}

MarginGroupBaseSettings.propTypes = {
    className:        PropTypes.string,
};
