import hash from "object-hash"
import {setSavedUserState} from "gui-common/userSettings/userState/userStateReducer"
import {postUserSetting} from "gui-common/userSettings/userState/userSettingsApi";
import {useEffect, useMemo} from "react";
import {selectAppReadyState, selectUserLoggedInState} from "gui-common/api/apiSelectors";
import {useDispatch} from "react-redux";
import {useSelectorRef} from "gui-common/functions/hooks";
import {
    globalUserStatesConfig,
    setGlobalUserStatesConfig
} from "gui-common/userSettings/userState/userStateConstants";


/* -----------------------------------------------------------------------------------------------------------------
* Check if user settings has changed and update profile to BE if changed.
* -----------------------------------------------------------------------------------------------------------------*/
export function useSavedUserSettings(appConfig) {
    const appReady =  useSelectorRef(selectAppReadyState);
    const dispatch = useDispatch();

    useMemo(
        () => {
            if (appConfig.userSettings) {
                setGlobalUserStatesConfig(appConfig.userSettings?.savedUserStates);
            }
        },
        []
    );


    useEffect(
        () => {
            if (!appConfig.userSettings) {
                return;
            }
            const intervalHandle = setInterval(() => {
                if (appReady.current === true) {
                    dispatch(checkAndUpdateUserState());
                }
            }, appConfig.userSettings.saveTimer ? appConfig.userSettings.saveTimer : 5000)

            return () => {
                clearInterval(intervalHandle);
            }
        },
        [],
    );
}

export function checkAndUpdateUserState() {
    return (dispatch, getState) => {
        const currentState = getState();
        if (!selectUserLoggedInState(currentState)) return;
        for (let userStateId in globalUserStatesConfig) {
            const currentUserState = hash(currentState[userStateId]);
            const savedUserState = currentState.userStateState[userStateId];

            if (!savedUserState || (currentUserState !== savedUserState)) {
//                console.log("Saving " + userStateId + ": ", currentUserState);
                dispatch(postUserSetting(userStateId, currentState[userStateId]));
                dispatch(setSavedUserState(userStateId, currentUserState));
//                console.log(userStateId + " update");
            }
        }
    }
}
