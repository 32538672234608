import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {orm} from "gui-common/orm/orm";

export const listRateOrderAllocationsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        return session.ListRateOrderAllocation.all().orderBy('id', 'desc').toRefArray();
    }
);
