import React from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";

import {onServiceActivationSwitchChange} from "gui-common/functions/functions";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

export function FxTradeReportServiceActivation (props) {
    const leuName = useXpFormField('name');
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');
    const trActive = useRefixServiceIsActive(refixServices.TradeReport);

    if (formDataPriceFeedOnly) {
        return null;
    }
    if (!trActive) {
        return null;
    }
    return (
        <div className={props.className}>
            <XpFormSwitchInput
                field         = "fxTradeReportActive"
                defaultValue  = {true}
                onChangeThunk={onServiceActivationSwitchChange(leuName)}
            />
        </div>
    );
}
FxTradeReportServiceActivation.propTypes = {
    className:        PropTypes.string,
};
