import PropTypes from "prop-types";
import React from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function XpFormStaticField (props) {

    return (
        <div className={"xpFormFieldLine" + (props.inLineLayout ? " xpFormFieldInline" : "")} style={props.compact ? {margin: 'unset'} : {}}>
            <div style={props.inLineLayout ? {width: props.fixedLabelWidth ? (props.fixedLabelWidth + 'px') : (100 - props.inLineDivider) + '%'} : undefined}>
                <label className='xpFormLabel'>
                    {props.label ? props.label : <XpTranslated trKey={props.labelTrKey} trParams={props.labelTrParams}/>}
                </label>
            </div>
            <div
                className={(props.inLineLayout && props.alignRight) ? "xpFormValueTextAlignRightInner" : "xpFormValueTextInner"}
                style={props.inLineLayout  ? {width: props.fixedLabelWidth ? 'calc(100% - ' + props.fixedLabelWidth + 'px)' : props.inLineDivider + '%'} : undefined}
            >
                {props.value}
            </div>
        </div>
    )
}
XpFormStaticField.propTypes = {
    alignRight:     PropTypes.bool,
    inLineLayout:   PropTypes.bool,
    inLineDivider:  PropTypes.number,
    label:          PropTypes.string,
    labelTrKey:     PropTypes.string,
    labelTrParams:  PropTypes.object,
    fixedLabelWidth:PropTypes.number,
};
XpFormStaticField.defaultProps = {
    inLineDivider:  50,
}
export default XpFormStaticField



