import PropTypes from "prop-types";
import React, {useMemo} from "react";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {HistoricAuditRecordRenderer} from "gui-common/audit/historicAudit/HistoricAuditRecordRenderer";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {apiTransformIncoming} from "gui-common/api/apiFunctions";
import {useStore} from "react-redux";
import {commonAuditActions} from "gui-common/audit/auditConstants";
import AuditRecordRawDataView from "gui-common/audit/AuditRecordRawDataView";
import {useAuditModelConfig} from "gui-common/audit/auditFunctions";

function AuditCrudRenderer (props) {
    const historicAuditOnly  = useAppEnvProperty('historicAuditOnly');
    const currentApiVersion  = useAppEnvProperty('currentApiVersion');
    const modelConfig = useAuditModelConfig(props.auditEntry);
    const reduxStore = useStore();

    const useHistoricAudit = historicAuditOnly || (props.auditEntry?.apiVersion !== currentApiVersion);

    const transformedBefore = useMemo(
        () => {
            return props.auditEntry?.data?.before && props.auditEntry?.affectedOrmModel ?
                apiTransformIncoming(props.auditEntry.data.before, props.auditEntry.affectedOrmModel, reduxStore.getState, {addTenorBooleansToAgreement: true}) : undefined;
        },
        [props.auditEntry?.data]
    );
    const transformedAfter = useMemo(
        () => {
            if (props.auditEntry?.data?.after) {
                return props.auditEntry?.affectedOrmModel ?
                    apiTransformIncoming(props.auditEntry.data.after, props.auditEntry.affectedOrmModel, reduxStore.getState, {addTenorBooleansToAgreement: true}) : undefined;
            }
            else {
                return transformedBefore ? {...transformedBefore} : undefined;
            }
        },
        [props.auditEntry?.data]
    );

    if (!modelConfig) {
        return null;
    }

    if (!transformedBefore && !transformedAfter) {
        return null;
    }
    if (props.auditEntry.action === commonAuditActions.Create) {
        transformedAfter.isNew = true;
    }
    if (props.auditEntry.action === commonAuditActions.Delete) {
        transformedAfter.isDeleted = true;
    }

    if (useHistoricAudit) {
        return (<HistoricAuditRecordRenderer auditEntry={props.auditEntry} auditProps={props} />)
    }

    const ModelRenderer = modelConfig.getRenderComponent();

    return (
        <div xp-test-id="auditEntryAfterChange">
            <XpAccordion
                header={
                    <div>
                        <XpTranslated trKey={'auditView.actionTypes.' + props.auditEntry.action  + '.name'}/>
                        <XpTranslated trKey={'general.modelNamesLarge.' + props.auditEntry?.affectedOrmModel}/>
                        {': '}
                        <span className="standardTextColor">
                            {props.auditEntry.affected.name}
                        </span>
                    </div>}
                instanceId={props.contentInstanceId + "-modifiedObject"}
                bypass={props.bypassAccordion}
            >
                <ModelRenderer
                    {...modelConfig.renderProps}
                    auditEntry              = {props.auditEntry}
                    ormModel                = {props.auditEntry?.affectedOrmModel}
                    auditMode               = {true}
                    // Only used for bank account audit. Provide clientBankAccounts in audit data from BE.
                    rootAuditProps          = {modelConfig.getRootAuditProps ? modelConfig.getRootAuditProps(props.auditEntry) : undefined}
                    entityId                = {props.auditEntry?.affectedId}
                    beforeChangeData        = {transformedBefore}
                    currentData             = {transformedAfter}
                    auditContext            = {(props.auditEntry?.data?.contextBefore ||props.auditEntry?.data?.contextAfter) ? {before: props.auditEntry?.data?.contextBefore, after: props.auditEntry?.data?.contextAfter} : undefined}
                    suppressSubEntities     = {true}
                    suppressAccordion       = {true}
                    auditModeModifiedObject = {true}
                />
            </XpAccordion>
            {props.devMode && !props.useHistoricAudit &&
                <AuditRecordRawDataView {...props}/>
            }
        </div>
    )
}
AuditCrudRenderer.propTypes = {
    auditEntry: PropTypes.object.isRequired,
};
export default AuditCrudRenderer



