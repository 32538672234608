import React from 'react';
import {withRouter} from "react-router-dom";
import UserMessagesList from "gui-common/userMessages/UserMessagesList"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function UserMessagesPage (props) {
    return (
        <div style={{height: "100%"}}>
            <h2>
                <XpTranslated trKey='userMessages.header'/>
            </h2>
            <hr></hr>
            <div style={{height: "calc(100vh - 150px)"}}>
                <UserMessagesList instanceId="userMessagesPageGrid"/>
            </div>
        </div>
    );
}
export default withRouter(UserMessagesPage);
