import React, {useMemo} from 'react'
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {ListRateAgreementStatic2} from "features/agreement/formComponents/ListRateAgreementStatic2";
import {ListRateGraceAndPriceType} from "features/agreement/formComponents/ListRateGraceAndPriceType";
import {AgreementOpenTimesStatic} from "features/agreement/formComponents/AgreementOpenTimesStatic";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";




function sortOnCurrencyPairId(a, b) {
    return a.currencyPairId > b.currencyPairId ? 1 : b.currencyPairId > a.currencyPairId ? -1 : 0;
}
export function useSortedAgreementsArray() {
    const agreements = useXpFormField('listRateAgreements')

    let sortedAgreements = agreements.map((item, index) => ({...item, originalIndex: index}));

    return useMemo(
        () => {
            return sortedAgreements.sort(sortOnCurrencyPairId);
        },
        [agreements]
    );
}

function AgreementInfo(props) {
    return (
        <div>
            <h3 className='wizardFormCardHeader'><XpTranslated trKey='refixClientWizardForm.agreementsForm.agreementHeader' trParams={{currencyPair: props.agreement.currencyPairName}}/></h3>
            <div className='agreementDataColumn' style={{marginRight: '10px' }}>
                <ListRateAgreementStatic2/>
            </div>
            <div className='agreementDataColumn'>
                <ListRateGraceAndPriceType/>
                <AgreementOpenTimesStatic/>
            </div>
        </div>
    )
}

function RfWizardListRateAgreementsForm(props) {
    const agreementsArray = useSortedAgreementsArray()

    return (
        <div className={"adminFormContainer"}>
            {agreementsArray.map((agreement, index) => {
                return (
                    <div className="wizardFormCard wizardFormCardLarge" key={index}>
                        <XpFormFieldset
                            field={'listRateAgreements.' + agreement.originalIndex}
                            formTemplate="agreementForm"
                        >
                            <AgreementInfo agreement={agreement} index={index}/>
                        </XpFormFieldset>
                    </div>
                )
            })}
        </div>
    )
}
export default RfWizardListRateAgreementsForm;





/*class RfWizardListRateAgreementsForm extends Component {

    agrForm(agreement, index){
        const isFirst = (this.props.form.listRateAgreements.findIndex(x => x.currencyPairId === agreement.currencyPairId) === index);
        return (
            <WizardAgreementForm
                agreement={agreement}
                agreementType={'ListRateAgreement'}
                submitMethod={this.props.submitMethod}
                form={this.props.form}
                indexNo={index}
                isFirst={isFirst}
                key={'wizardAgreementForm_' + index}
            />
        );
    }
    render() {
        return (
            <div className="formCard">
                {this.props.form.listRateAgreements.map((x, index) => this.agrForm(x, index))}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const form = state.myForms.refixClientWizardForm;
    return {
        translate:              selectTranslateFunction(state),
        currentLanguage:        selectActiveLanguage(state),
        form:                   form,
        appEnvState:            state.appEnvState,
        currencyPairs:          activeCurrencyPairsSelector(state),
        listRateTenors:         activeTenorsSelector(state, {agreementType: 'ListRateAgreement'}),
        marketOrderTenors:      activeTenorsSelector(state, {agreementType: 'MarketOrderAgreement'}),
        activePeriods:          activePeriodsSelector(state),
        activeDispatchPeriods:  activeDispatchPeriodsSelector(state),
        agreements :            getField(state, 'myForms.refixClientWizardForm.agreements.0'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetForm:                  (model)     => dispatch(actions.reset(model)),
        submitForm:                 (model)     => dispatch(actions.submit(model)),
        setParametersFormValue: (model, string) => dispatch(actions.change(model, string)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RfWizardListRateAgreementsForm);*/
