import React, {useState, useMemo, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    addRemovedActiveDataRights,
    compareEntityName, usePrototypeDataRights
} from "gui-common/userRights/adminUserRightsFunctions";
import XpTooltip from "gui-common/components/XpTooltip";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {addDataRightAction, removeDataRightAction} from "gui-common/userRights/adminUserRightsReducer2";

function EntityList (props) {

    const sortedEntityList = useMemo(() =>
        {

            const entityDataRights =  props.suppressChangeDisplay ? props.modifiedEntityList : addRemovedActiveDataRights(props.modifiedEntityList, props.activeEntityList);
            return entityDataRights?.sort(compareEntityName)
        },
        [props.modifiedEntityList, props.activeEntityList]
    );

    const onLineClick = (instance) => {
        if (props.readOnly) return;
        if (props.selectedInstance && (instance.id === props.selectedInstance.id)) props.selectLineCb(null);
        else props.selectLineCb(instance)
    };

    const getRowTooltipKey = (isAdded, isRemoved) => {
        if (props.suppressChangeDisplay) return undefined;
        if (isAdded)   return 'userRights.entityDataRightsChange.' + (props.readOnly ? 'addedAudit' : 'added');
        if (isRemoved) return 'userRights.entityDataRightsChange.' + (props.readOnly ? 'removedAudit' : 'removed');
        return "userRights.entityDataRightsChange.active";
    };

    return (
        <div className={"adminUserRightsEntityList"} xp-test-id={'xpOperatingUnitList'}>
            <h3>{props.header}</h3>

            <table style={{width: '100%'}}>
                <tbody>
                    <tr className="HeaderRow" key="header">
                        <th style={{width: '40%'}}    ><XpTranslated trKey='general.fieldNames.name'       /></th>
                        <th style={{width: '60%'}}    ><XpTranslated trKey='general.fieldNames.description'/></th>
                    </tr>
                    {(sortedEntityList?.length > 0) ?
                        sortedEntityList.map(dataRightEntity => {
                            const isAdded   = props.activeEntityList   ? !props.activeEntityList.find(  item => item.id === dataRightEntity.id) : false;
                            const isRemoved = props.modifiedEntityList ? !props.modifiedEntityList.find(item => item.id === dataRightEntity.id) : false;

                            let renderClass = props.readOnly ? "" : "clickableRow";
                            if (props.selectedInstance && (props.selectedInstance.id === dataRightEntity.id)) {
                                renderClass = "activeClickableRow";
                            }
                            if (!props.suppressChangeDisplay && isAdded)   renderClass += " instanceUserRightsCardAdded";
                            if (!props.suppressChangeDisplay && isRemoved) renderClass  = "instanceUserRightsCardRemoved";

                            return (
                                <XpTooltip
                                    trKey={getRowTooltipKey(isAdded, isRemoved)}
                                    trParams={{name: dataRightEntity.shallowObject.name}}
                                    key={dataRightEntity.id}
                                >
                                    <tr
                                        className={renderClass}
                                        onClick={isRemoved ? undefined : () => onLineClick(dataRightEntity)}
                                    >
                                        <td style={{paddingLeft: '5px' }}><div>{dataRightEntity.shallowObject.name}</div></td>
                                        <td style={{paddingLeft: '5px' }}><div>{dataRightEntity.shallowObject.description}</div></td>
                                    </tr>
                                </XpTooltip>
                            )
                        })
                        :
                        <tr className="">
                            <td style={{paddingLeft: '5px' }} colSpan={3}>
                                <div>{props.nonItemsMessage}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

function AdminPrototypeDataRights (props) {
    const dispatch  = useDispatch();
    const translate = useSelector(selectTranslateFunction);

    const modifiedDataRights  = usePrototypeDataRights(props.modifiedDataRightsCollection?.dataRights, props.entityPrototypeName);
    const activeDataRights    = usePrototypeDataRights(props.activeDataRightsCollection?.dataRights, props.entityPrototypeName);
    const allAvailableDataRights      = usePrototypeDataRights(props.availableDataRights, props.entityPrototypeName);

    const availableDataRights = useMemo(
        () => {
            if (!modifiedDataRights?.length) {
                return props.availableDataRights;
            }
            return allAvailableDataRights.filter(available => modifiedDataRights.find(assigned => assigned.id === available.id) === undefined);
        },
        [allAvailableDataRights, modifiedDataRights]
    );

    const [selectedAssigned,   setSelectedAssigned]   = useState(null);
    const [selectedAvailable,  setSelectedAvailable]  = useState(null);

    useEffect(
        () => {
            if (selectedAssigned && !modifiedDataRights?.find(item => item.id === selectedAssigned.id)) setSelectedAssigned(null);
        },
        [selectedAssigned, modifiedDataRights],
    );
    useEffect(
        () => {
            if (selectedAvailable && !availableDataRights?.find(item => item.id === selectedAvailable.id)) setSelectedAvailable(null);
        },
        [selectedAvailable, availableDataRights],
    );

    if (!props.entityPrototypeName) {
        return null;
    }

    return (
        <div className={"adminUserRightsContainer"}>
            <EntityList
                header={
                    <span>
                        <XpTranslated trKey='adminUserRights.assignedEntities'/>
                        <XpTranslated trKey={'general.prototypeNamesSmallPlural.' + props.entityPrototypeName} fallBackText={props.entityPrototypeName}/>
                    </span>
                }
                nonItemsMessage    = {<XpTranslated trKey='adminUserRights.noEntitiesAssigned' trParams={{entityType: translate('general.prototypeNamesSmallPlural.' + props.entityPrototypeName)}}/>}
                selectLineCb       = {setSelectedAssigned}
                selectedInstance   = {selectedAssigned}
                modifiedEntityList = {modifiedDataRights}
                activeEntityList   = {activeDataRights}
                readOnly           = {props.readOnly}
            />
            {(!props.auditMode && modifiedDataRights) &&
            <div className={"adminUserRightsAssignButtons"} style={{visibility: props.readOnly ? 'hidden' : 'visible'}}>
                <WaitingXpoolButton
                    labelKey        = 'adminUserRights.assignButton'
                    onClickCallback = {() => dispatch(addDataRightAction(props.modifiedDataRightsCollection, selectedAvailable))}
                    disabled        = {selectedAvailable === null}
                />
                <WaitingXpoolButton
                    labelKey        = 'adminUserRights.removeButton'
                    onClickCallback = {() => dispatch(removeDataRightAction(props.modifiedDataRightsCollection, selectedAssigned))}
                    disabled        = {selectedAssigned  === null}
                />
            </div>}
            {(!props.auditMode && modifiedDataRights) &&
            <EntityList
                header={
                    <span>
                        <XpTranslated trKey='adminUserRights.availableEntities'/>
                        <XpTranslated trKey={'general.prototypeNamesSmallPlural.' + props.entityPrototypeName} fallBackText={props.entityPrototypeName}/>
                    </span>
                }
                nonItemsMessage    = {<XpTranslated trKey='adminUserRights.noEntitiesAvailable' trParams={{entityType: translate('general.prototypeNamesSmallPlural.' + props.entityPrototypeName)}}/>}
                selectLineCb       = {setSelectedAvailable}
                selectedInstance   = {selectedAvailable}
                modifiedEntityList = {availableDataRights}
                readOnly           = {props.readOnly}
                suppressChangeDisplay
            />}
        </div>
    )
}
export default AdminPrototypeDataRights
