import React from 'react'
import PropTypes from 'prop-types';
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {accountNumberParser} from "gui-common/xpForm/formComponentsFunctions";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";


export function TradeReportAgreementStatic (props) {
    const formDataType  = useXpFormField('type');
    const formContext = useXpFormContext();
    const noFormValidation       = useAppEnvProperty( 'noFormValidation');

    if (!['TradeReportAgreement'].includes(formDataType)) return null;

    return (
        <div className={props.className}>
            <XpFormTextInput
                readOnly={formContext.formUseState !== XP_FORM_NEW && !noFormValidation}
                isRequired inLineLayout
                field= "fxShortName"
                formTemplate = "legalEntityUnitForm"
                checkNoSpaces={true}
                checkOnlySpaces={true}
                minLength={2}
                maxLength={20}
                defaultValue={props.parentItemData?.fxShortName}
            />
            <XpFormTextInput
                inLineLayout isRequired
                field= "clientRefId"
                parser        = {accountNumberParser}
                checkNoSpaces = {true}
                exactLength={12}
                defaultValue={props.parentItemData?.client?.clientRefId}
            />
        </div>
    );
}
TradeReportAgreementStatic.propTypes = {
    className:        PropTypes.string,
};
