export const webSocketStatus = {
    'DOWN': 'down',
    'OPEN': 'open',
    'RUNNING': 'running',
    'STARTING': 'starting',
    'CLOSING': 'closing',
}
export function getCommonBaseUrls(appConfig) {
    const baseUrl    = "/oxygen-admin/v4/";
    return {
        signIn                  : "/aaa/v1/sign-in/",

        Country                 : baseUrl + "countries",
        FixSession              : baseUrl + "fix-sessions",
        NFCProcessStatus        : baseUrl + "process-status",
        User                    : baseUrl + "users",
        SharedSetting           : baseUrl + "shared-settings",

        userPreferences         : baseUrl + "user-settings",
        systemText              : baseUrl + "system-texts",
        uploadFile              : baseUrl + "upload-file",

        Currency                : baseUrl + "currencies",
        CurrencyPair            : baseUrl + "currency-pairs",
        FxPrice                 : baseUrl + "fx-prices",
        Tenor                   : baseUrl + "tenors",
        FixMessage              : baseUrl + "fix-log",

        ErpSystem               : baseUrl + "erp/erp-systems",
        ErpIntegration          : baseUrl + "erp/oauth2/connections",
        erpDataRequest          : baseUrl + "erp/data-request",
        erpClientConfiguration  : baseUrl + "erp/client-configuration",

        userSystemRights        : baseUrl + "user-system-rights",
        userDataRights          : baseUrl + "user-data-rights",
        availableDataRights     : baseUrl + "user-data-rights/assignable-data-rights",

        audit                   : baseUrl + "audit",
        historicAudit           : baseUrl + "audit-entries-historic",
        auditFiles              : baseUrl + "audit/files",

        Topic                   : baseUrl + "user-messages/topics",
        userMessage             : baseUrl + "user-messages",

        NoRunDate               : baseUrl + "no-run-dates",

        BankHoliday             : baseUrl + "bank-holidays",

        LedgerAccount           : baseUrl + "ledger-accounts",

        CurrencyPairSettings    : baseUrl + "currency-pair-settings",

        fxRejectConfig          : baseUrl + "fx-reject-configurations",

        reportSet               : baseUrl + "report-sets",

        RunState                : baseUrl + "run-state",

        FxOrderReport           : baseUrl + 'fx-trade-reports',
        FxTradeReportEvent      : baseUrl + 'fx-trade-report-events',
    }
}
export const globalApiHandle = {};
