import React from 'react'
import DashboardIcon from "gui-common/dashboard/DashboardIcon";
import {userSelector} from "gui-common/orm/ormSelectors";
import {dashboardConstants} from "gui-common/dashboard/dashboardConfig";
import {useSelector} from "react-redux";


function DashboardPicker(props) {
    const user = useSelector(userSelector);
    const dashboardState = useSelector(state => state.dashboardState)

    return (
        <div className="dashboardPicker">
            {dashboardState.pickerIcons.map(item => (
                <IconDiv key={item.keyName} widgetConfig={dashboardConstants.widgets[item.componentName]} item={item} user={user}/>
            ))}
        </div>
    );
}

function IconDiv(props) {
    const isActive = useSelector(
        props.widgetConfig.isActiveSelector ? props.widgetConfig.isActiveSelector : () => true
    );

    if (isActive === false) {
        return null;
    }

    if (props.widgetConfig.authorizedFunction && !props.widgetConfig.authorizedFunction(props.user)) {
        return null;
    }
    return (
        <DashboardIcon key={props.item.keyName} iconName={props.item.icon} componentName={props.item.componentName}/>
    )
}

export default DashboardPicker;
