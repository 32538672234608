import moment from "moment";
import {fetchUserSettings} from "gui-common/userSettings/userState/userSettingsApi";
import {getOrmItemFromApi, getOrmItemsFromApi} from "gui-common/orm/ormApi";
import {getUserMessagesFromApi} from "gui-common/userMessages/userMessagesApi";
import {getSystemTextsFromApi} from "gui-common/appLocale/xpTranslated/systemTextApi";
import {loadMatrixInSequence} from "gui-common/api/apiCommon";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {
    getAvailableDataRightsFromApi,
    getUserDataRightsFromApi,
    getUserSystemRightFromApi
} from "gui-common/userRights/adminUserRightsApi";
import {getFxRejectConfigFromApi} from "gui-common/fxRejectConfig/fxRejectConfigApi";
import {refixIncomingTransformersMap, refixOutgoingTransformersMap} from "appConfig/api/apiTransformers";
import {refixFilterWebSocketEventMap, refixProcessWebSocketEventMap} from "appConfig/api/webSocketConfig";
import {selectLoggedInUserId} from "gui-common/api/apiSelectors";
import {getServiceActiveSelector} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

// const baseUrl = "/refix-api/v4/";
const baseUrl = "/refix-admin/v4/"
const refixBaseUrls = {

    CurrencyPair                : baseUrl + "currency-pairs",
    Tenor                       : baseUrl + "tenors",

    ListRate                    : baseUrl + "list-rates",
    DispatchTrade               : baseUrl + "dispatch-trades",

    OperatingUnit               : baseUrl + "operating-units",
    Client                      : baseUrl + "clients",
    LegalEntity                 : baseUrl + "legal-entities",
    LegalEntityUnit             : baseUrl + "legal-entity-units",
    Agreement                   : baseUrl + "agreements",
    ListRatePeriod              : baseUrl + "list-rate-periods",
    DispatchPeriod              : baseUrl + "dispatch-periods",
    ListRateOrder               : baseUrl + "list-rates/orphan-orders",
    MarketOrder                 : baseUrl + "market-orders",
    MarginGroup                 : baseUrl + "price-margin-groups",
    PriceTolerances             : baseUrl + "price-tolerances",
    PriceFeed                   : baseUrl + "price-feeds",
    FxTradeReportDelivery       : baseUrl + "trade-report/deliveries",
    fxTradeReportActions        : baseUrl + 'trade-report/actions',
    fxTradeReportConfigurations : baseUrl + 'trade-report/erp-configurations',

    // Not ORM object related
    sandbox                     : baseUrl + "sandbox",
    reportSet                   : baseUrl + "report-sets",

};

export const refixApiConfig = {
    // baseUrl: baseUrl,
    incomingTransformersMap: refixIncomingTransformersMap,
    outgoingTransformersMap: refixOutgoingTransformersMap,
    baseUrls: refixBaseUrls,
    filterEventMap: refixFilterWebSocketEventMap,
    processEventMap: refixProcessWebSocketEventMap,
    initialDataLoadThunk: refixLoadApplicationDataThunk,
    onDataLoadCompletedThunk: undefined,
}
const getDataLoadFunctionMatrix = (getState) =>  {


    let fromDateStr = moment().subtract(1, 'days').format('YYYY-MM-DD');

    const moActive = getServiceActiveSelector(refixServices.MarketOrder)(getState());
    const lrActive = getServiceActiveSelector(refixServices.ListRates)(getState());
    const trActive = getServiceActiveSelector(refixServices.TradeReport)(getState());
    const rrActive = getServiceActiveSelector(refixServices.ReferenceRates)(getState());
    const pfActive = getServiceActiveSelector(refixServices.PriceFeed)(getState());

    if (selectAppEnvProperty(getState(), 'noBackendMode')) return [];

    const retArray = [[
        // {function: getOrmItemsFromApi, parameters: ['AuditRootModel']},
        {function: getOrmItemsFromApi, parameters: ['Currency']},
        {function: getOrmItemsFromApi, parameters: ['CurrencyPair']},
        {function: getOrmItemsFromApi, parameters: ['Country']},
        {function: getOrmItemsFromApi, parameters: ['NFCProcessStatus']},
        {function: getOrmItemsFromApi, parameters: ['User']},
        {function: getOrmItemsFromApi, parameters: ['OperatingUnit']},
        {function: getOrmItemsFromApi, parameters: ['Topic']},
        (lrActive                                               ) && {function: getOrmItemsFromApi, parameters: ['ListRatePeriod']},
        (lrActive                                               ) && {function: getOrmItemsFromApi, parameters: ['DispatchPeriod']},
        (lrActive                                               ) && {function: getOrmItemsFromApi, parameters: ['PriceTolerances']},
        (lrActive                                               ) && {function: getOrmItemsFromApi, parameters: ['CurrencyPairSettings']},

        (lrActive || moActive                                   ) && {function: getOrmItemsFromApi, parameters: ['Tenor']},
        (lrActive || moActive                                   ) && {function: getOrmItemsFromApi, parameters: ['FixSession']},
        (                        pfActive || rrActive           ) && {function: getOrmItemsFromApi, parameters: ['PriceFeed']},
        (lrActive ||             pfActive || rrActive           ) && {function: getOrmItemsFromApi, parameters: ['MarginGroup']},
    ],[
        {function: getOrmItemsFromApi, parameters: ['Client']},
        {function: getOrmItemFromApi , parameters: ['User', selectLoggedInUserId(getState())]},
        {function: getSystemTextsFromApi, parameters: []},
        {function: getUserMessagesFromApi, parameters: [fromDateStr]},
        {function: getOrmItemsFromApi, parameters: ['SharedSetting']},
        {function: getUserDataRightsFromApi, parameters: []},
        {function: getUserSystemRightFromApi, parameters: []},
        {function: getAvailableDataRightsFromApi, parameters: []},
        (lrActive                                               ) && {function: getOrmItemsFromApi, parameters: ['ListRateOrder']},
        (lrActive                                               ) && {function: getOrmItemsFromApi, parameters: ['ListRate']},
        (            moActive                                   ) && {function: getOrmItemsFromApi, parameters: ['MarketOrder']},
        (            moActive                                   ) && {function: getFxRejectConfigFromApi, parameters: ['Quote']},
        (            moActive                                   ) && {function: getFxRejectConfigFromApi, parameters: ['Order']},
        (lrActive || moActive || pfActive || rrActive           ) && {function: getOrmItemsFromApi, parameters: ['FxPrice']},
        (                                               trActive) && {function: getOrmItemsFromApi, parameters: ['FxTradeReportDelivery']},
    ]];

    return [
        retArray[0].filter(item => item),
        retArray[1].filter(item => item),
    ];
};

function refixLoadApplicationDataThunk() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(fetchUserSettings())
                .then(result => {
                    loadMatrixInSequence(getDataLoadFunctionMatrix(getState), dispatch)
/*
                        .then(result => {
                            let data = require('./restReturnClientData');
                            // addMockedData('Tenor'           , data.tenors           , dispatch, getState);
                            if (selectAppEnvProperty(getState(), 'noBackendMode')) {
                                addMockedData('CurrencyPair', data.currencyPairs, dispatch);
                                addMockedData('OperatingUnit', data.operatingUnits, dispatch);
                                addMockedData('Tenor', data.tenors, dispatch);
                                addMockedData('ListRatePeriod', data.periods, dispatch);
                                addMockedData('DispatchPeriod', data.dispatchPeriods, dispatch);
                                addMockedData('Client', data.clients, dispatch);
                                addMockedData('FxPrice', data.fxPrices, dispatch);
                                addMockedData('ListRateOrder', data.listRateOrders, dispatch);
                                addMockedData('MarketOrder', data.marketOrders, dispatch);
                            }
                            // addMockedData('AuditRootModel'      , data.auditRootModels   , dispatch);
                            if (selectIsDevMode(getState())) {
                                // addMockedData('CurrencyPairConfig'  , data.currencyPairConfig, dispatch);
                                // addMockedData('AuditEntry'          , data.auditRecords      , dispatch);
                            }
                        })
*/
                        .then(result => {resolve(result)})
                        .catch(err => {reject(err)})
                })
        })
    }
}
