import {createSelector} from "reselect";

const selectId                 = (state, props) => props ? props.selectId : null;

const adminUserRightsState = (state, props) => state.adminUserRightsState;
export const getModifiedDataRightsCollectionSelector = () => createSelector(
    [adminUserRightsState, selectId],
    (adminUserRightsState, selectId) => {
        if (!adminUserRightsState.modifiedDataRightsCollections || !selectId ) return undefined;
        return adminUserRightsState.modifiedDataRightsCollections[selectId];
    }
);

const availableDataRights = (state) => state.adminUserRightsState.availableDataRights;
export const availableDataRightsSelector = createSelector(
    [availableDataRights],
    (availableDataRights) => {
        return availableDataRights;
    }
);

const modifiedUsers = (state, props) => state.adminUserRightsState.modifiedUsers;
export const getModifiedUserSelector = () => createSelector(
    [modifiedUsers, selectId],
    (modifiedUsers, selectId) => {
        if (!modifiedUsers || !selectId ) return undefined;
        return modifiedUsers[selectId];
    }
);















