import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {marketOrderStates} from "features/marketOrder/marketOrderConstants";

export function prepareMarketOrdersColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id'                 , 50 , 50 , 'marketOrdersList.id', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'agreementId'        , 50 , 50 , 'marketOrdersList.agreementId', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'legalEntityUnitName', 50 , 50 , 'marketOrdersList.legalEntityUnitName');
    xpColCreate(translate, subArray, hiddenState, 'appId'              , 100, 50 , 'marketOrdersList.appId');
    xpColCreate(translate, subArray, hiddenState, 'currencyPairStr'    , 100, 50 , 'marketOrdersList.currencyPair');
    xpColCreate(translate, subArray, hiddenState, 'side'               , 50 , 50 , 'marketOrdersList.side');
    xpColCreate(translate, subArray, hiddenState, 'tenor'              , 50 , 50 , 'marketOrdersList.tenor');
    xpColCreate(translate, subArray, hiddenState, 'instructedSettlementDate', 100, 50, 'marketOrdersList.instructedSettlementDate');
    xpColCreate(translate, subArray, hiddenState, 'amount'             , 100, 50 , 'marketOrdersList.amount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'amountCurrencyT'    , 50 , 50 , 'marketOrdersList.amountCurrency');
    xpColCreate(translate, subArray, hiddenState, 'externalId'         , 200, 100, 'marketOrdersList.externalId');
    xpColCreate(translate, subArray, hiddenState, 'meansOfPayment'     , 100, 50 , 'marketOrdersList.meansOfPayment');
    xpColCreate(translate, subArray, hiddenState, 'createdDateTime'    , 200, 50 , 'marketOrdersList.createdDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'uti'                , 200, 50 , 'marketOrdersList.uti');
    xpColCreate(translate, subArray, hiddenState, 'statusT'            , 100, 50 , 'marketOrdersList.status'       , {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: marketOrderStates.map(item => 'marketOrdersList.statusTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'statusMessage'      , 200, 50 , 'marketOrdersList.statusMessage', {tooltipField: 'statusMessage'});
    xpColCreate(translate, subArray, hiddenState, 'executionTime'      , 200, 50 , 'marketOrdersList.executionTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'executionRate'      , 50 , 50 , 'marketOrdersList.executionRate', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'counterAmount'     , 100, 50 , 'marketOrdersList.counterAmount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'fxOrderId'          , 100, 50 , 'marketOrdersList.fxOrderId');
    xpColCreate(translate, subArray, hiddenState, 'spotRate'           , 100, 50 , 'marketOrdersList.spotRate', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'forwardPoints'      , 50 , 50 , 'marketOrdersList.forwardPoints', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'settlementDate'     , 100, 50 , 'marketOrdersList.settlementDate');
    xpColCreate(translate, subArray, hiddenState, 'marketOrderFileName', 200, 50 , 'marketOrdersList.marketOrderFileName');

    return subArray;
}

