import React from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {customerPriceFeedsSelector} from "appConfig/orm/ormSelectors";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {getDefaultItemId} from "gui-common/functions/functions";
import {useMarginGroupSelectList} from "features/marginGroup/marginGroupFunctions";


export function ReferenceRateAgreementStatic (props) {
    const priceFeeds    = useSelector(customerPriceFeedsSelector);
    const formDataType  = useXpFormField('type');

    const marginGroupsSelectList = useMarginGroupSelectList();

    if (!['ReferenceRateAgreement', 'PriceFeedAgreement'].includes(formDataType)) return null;

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field         = "priceFeedId"
                selectList    = {priceFeeds}
                defaultValue  = {getDefaultItemId(priceFeeds)}
            />
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field         = "marginGroupId"
                selectList    = {marginGroupsSelectList}
                defaultValue  = {getDefaultItemId(marginGroupsSelectList)}
            />
        </div>
    );
}
ReferenceRateAgreementStatic.propTypes = {
    className:        PropTypes.string,
};
