import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import {activeCurrencyPairsSelector, getActiveCurrencyPairsSelector} from "gui-common/orm/ormSelectors";
import {useSelector} from "react-redux";
import {getAuthorisedAgreementTypes} from "features/agreement/agreementFunctions";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import ShowAuditInfo from "gui-common/audit/ShowAuditInfo";
import {useSelectorInstance} from "gui-common/functions/hooks";


export function AgreementBaseStatic (props) {
    const formContext      = useXpFormContext();
    const formDataType     = useXpFormField('type');
    const noFormValidation = useSelector(state => selectAppEnvProperty(state, 'noFormValidation'));

    const currencyPairs = useSelectorInstance(getActiveCurrencyPairsSelector, {agreementType: formDataType});
    const formConfig    = useSelector(state => state.appEnvState.agreementFormConfig);

    const allAgreementTypes = [{id: "ListRateAgreement"}, {id: "MarketOrderAgreement"}, {id: "MarketOrderAllAgreement"}, {id: 'ReferenceRateAgreement'}, {id: 'PriceFeedAgreement'}, {id: 'TradeReportAgreement'}];
    const currencyPairsList = useMemo(() => {
        return currencyPairs.map(pair => { return {id: pair.id, pairStr: (pair.baseCurrency.currency + pair.quoteCurrency.currency)}});
    }, [currencyPairs]);

    const agreementTypesList = useMemo(() => {
        if (formContext.formUseState !== XP_FORM_NEW) {
            return allAgreementTypes;
        }
        let authorisedAgreementTypes = props.parentItemData ? getAuthorisedAgreementTypes(props.parentItemData) : [];
        let configuredAgreementTypes = allAgreementTypes.filter(item => authorisedAgreementTypes.includes(item.id));
        if (props.parentItemData.priceFeedOnly && !noFormValidation) return configuredAgreementTypes.filter(item => item.id === 'PriceFeedAgreement');
        return configuredAgreementTypes;
    }, [props.parentItemData?.priceFeedOnly]);

    function onAgreementTypeChange(fieldModel, newValue, currentValue, setValue) {
        return (dispatch, getState) => {
            const currencyPairs = getActiveCurrencyPairsSelector()(getState(), {agreementType: newValue});
            setValue(newValue);
            if (!formContext) return;

            dispatch(xpFormChangeField(formContext.formModel + '.currencyPairId', currencyPairs[0] ? currencyPairs[0].id : ""));
            dispatch(xpFormChangeField(formContext.formModel + '.name', newValue));
        }
    }

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate autoFocus
                inLineDivider={60}
                field="type"
                selectList    = {agreementTypesList}
                itemTextField = "id"
                onChangeThunk={onAgreementTypeChange}
                disabled={formContext?.formUseState !== XP_FORM_NEW}
            />
            {(['ListRateAgreement', 'MarketOrderAgreement'].includes(formDataType)) &&
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                inLineDivider={60}
                field="currencyPairId"
                selectList    = {currencyPairsList}
                itemTextField = "pairStr"
                disabled={formContext?.formUseState !== XP_FORM_NEW}
            />}
            <XpFormTextAreaInput
                field         = "comment"
                maxLength     = {200}
            />
            {((formContext.formUseState !== XP_FORM_NEW) && (formContext.currentData)) &&
            <div className="dateTimeFormSection">
                <ShowAuditInfo auditedObject={formContext.currentData} ormModel={props.ormModel} auditMode={props.auditMode}/>
            </div>}
        </div>
    );
}
AgreementBaseStatic.propTypes = {
    className:        PropTypes.string,
};
