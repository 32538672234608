import React, {useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    activeCurrenciesSelector,
    activeCurrencyPairsSelector,
    getActiveCurrencyPairsSelector,
} from "gui-common/orm/ormSelectors";
import removeIcon from "gui-common/resources/removeIcon.png";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormAddElement, xpFormChangeField, xpFormRemoveElement} from "gui-common/xpForm/core/xpFormReducer";
import {getXpFormRootSelector} from "gui-common/xpForm/core/xpFormSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useSelectorInstance} from "gui-common/functions/hooks";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";


const getInitialListRateAgreement = (currencyPair) => ({
    currencyPairId: currencyPair.id,
    currencyPairName: currencyPair.currencyPairName,
    type: "ListRateAgreement",
    dispatchLevel: "",
    dispatchTradePeriodId: "",
    buySellType: "BuySell",
    gracePeriod: "",
    openTime: "",
    closeTime: "",
});
const getInitialMarketOrderAgreement = (currencyPair) => ({
    currencyPairId: currencyPair.id,
    currencyPairName: currencyPair.currencyPairName,
    type: "MarketOrderAgreement",
    openTime: "",
    closeTime: "",
})
const getInitialMarketOrderAllAgreement = () => ({
    type: "MarketOrderAllAgreement",
    openTime: "",
    closeTime: "",
})

function RfWizardCurrencyPairsForm(props) {
    const formContext = useXpFormContext()
    const ormCurrencyPairs = useSelector(activeCurrencyPairsSelector);
    const ormCurrencies = useSelector(activeCurrenciesSelector);
    const activeListRateCurrencyPairs    = useSelectorInstance(getActiveCurrencyPairsSelector, {agreementType: 'ListRateAgreement'   });
    const activeMarketOrderCurrencyPairs = useSelectorInstance(getActiveCurrencyPairsSelector, {agreementType: 'MarketOrderAgreement'});
    const formDataCurrencyPairs          = useXpFormField('currencyPairs');
    const formDataSearchByCurrency       = useXpFormField('searchByCurrency');
    const formDataSearchCurrencyId       = useXpFormField('searchCurrencyId');
    const dispatch = useDispatch();

    function addCurrencyPair(currencyPairId) {
        const selectedCurrencyPair = ormCurrencyPairs.find(pair => pair.id === Number(currencyPairId));
        if (!selectedCurrencyPair) return;

        const newCurrencyPair = {...selectedCurrencyPair, currencyPairId: selectedCurrencyPair.id};
        if (selectedCurrencyPair.validAgreementTypes.indexOf('ListRateAgreement')    !== -1) {   // Add list rate agreement
            newCurrencyPair.useListRateAgreements = true;
            dispatch(xpFormAddElement(formContext.formModel + '.listRateAgreements', getInitialListRateAgreement(selectedCurrencyPair)));
        }
        if (selectedCurrencyPair.validAgreementTypes.indexOf('MarketOrderAgreement') !== -1) {   // Add market order agreement
            newCurrencyPair.useMarketOrderAgreements = true;
            dispatch(xpFormAddElement(formContext.formModel + '.marketOrderAgreements', getInitialMarketOrderAgreement(selectedCurrencyPair)));
        }
        if (selectedCurrencyPair.validAgreementTypes.indexOf('MarketOrderAllAgreement') !== -1) {   // Add market order agreement
            newCurrencyPair.useMarketOrderAgreements = true;
            dispatch(xpFormAddElement(formContext.formModel + '.marketOrderAllAgreements', getInitialMarketOrderAllAgreement()));
        }
        setTimeout(() => dispatch(xpFormAddElement(formContext.formModel + '.currencyPairs', newCurrencyPair)), 10);

        if (newCurrencyPair.useListRateAgreements   ) dispatch(xpFormChangeField(formContext.formModel + '.useListRateAgreements'   , true));
        if (newCurrencyPair.useMarketOrderAgreements) dispatch(xpFormChangeField(formContext.formModel + '.useMarketOrderAgreements', true));
        dispatch(xpFormChangeField(formContext.formModel + '.currencyPairId', ""));
    }

    function removeAgreements(currencyPairId, removeListRate, removeMarketOrder, getState) {
        const formData = getXpFormRootSelector()(getState(), {model: formContext.formModel});
        // Find and remove potential list rate agreements
        if (formData.listRateAgreements?.length && removeListRate) {
            for (const index in formData.listRateAgreements) {
                if (formData.listRateAgreements[index].currencyPairId !== currencyPairId) continue;
                dispatch(xpFormRemoveElement(formContext.formModel + '.listRateAgreements', index));
            }
        }
        // Find and remove potential market order agreements
        if (formData.marketOrderAgreements?.length && removeMarketOrder) {
            for (const index in formData.marketOrderAgreements) {
                if (formData.marketOrderAgreements[index].currencyPairId !== currencyPairId) continue;
                dispatch(xpFormRemoveElement(formContext.formModel + '.marketOrderAgreements', index))
            }
        }
        // Check if all agreements are gone and set global flag if that is the case.
        const updatedFormData = getXpFormRootSelector()(getState(), {model: formContext.formModel});
        if (!updatedFormData.listRateAgreements?.length   ) dispatch(xpFormChangeField(formContext.formModel + '.useListRateAgreements'   , false));
        if (!updatedFormData.marketOrderAgreements?.length) dispatch(xpFormChangeField(formContext.formModel + '.useMarketOrderAgreements', false));
    }

    function removeCurrencyPair(currencyPairId) {
        return (dispatch, getState) => {
            const formData = getXpFormRootSelector()(getState(), {model: formContext.formModel});

            const currencyPairIndex = formData.currencyPairs.findIndex(cPair => cPair.currencyPairId === currencyPairId);

            if (currencyPairIndex === -1) {
                console.warn("Currency pair not found in wizard remCurrPair", currencyPairId);
            }
            else {
                dispatch(xpFormRemoveElement(formContext.formModel + '.currencyPairs', currencyPairIndex))
            }
            removeAgreements(currencyPairId, true, true, getState)
        }
    }

    function toggleAgreementType(enabled, currencyPairId, agreementType) {
        return (dispatch, getState) => {
            const selectedCurrencyPair = ormCurrencyPairs.find(pair => pair.id === Number(currencyPairId));
            if (!selectedCurrencyPair) return;
            if (enabled) {
                dispatch(xpFormAddElement(
                    formContext.formModel + ((agreementType === 'listRate') ? '.listRateAgreements' : '.marketOrderAgreements'),
                    ((agreementType === 'listRate') ? getInitialListRateAgreement : getInitialMarketOrderAgreement)(selectedCurrencyPair)
                ));
                dispatch(xpFormChangeField(formContext.formModel + ((agreementType === 'listRate') ? '.useListRateAgreements' : '.useMarketOrderAgreements'), true));
            }
            else {
                removeAgreements(currencyPairId, (agreementType === 'listRate'), (agreementType === 'marketOrder'), getState)
            }
        }
    }

    function currencyPairList() {
        if(!formDataCurrencyPairs?.length) return null;
        return (
            <div className="currencyList">
                <div className="currencyRow">
                    <div className="selectedCurrencies">
                        <p className="valueText"><XpTranslated trKey={props.wizardName + '.selectedCurrencyPairs'}/></p>
                    </div>
                </div>
                {formDataCurrencyPairs.map((element, index) => {

                    const eligibleForListRate    = activeListRateCurrencyPairs.find(   cp => cp.id === element.currencyPairId) !== undefined;
                    const eligibleForMarketOrder = activeMarketOrderCurrencyPairs.find(cp => cp.id === element.currencyPairId) !== undefined;
                    return(
                        <XpFormFieldset
                            field = {'currencyPairs.' + index}
                            formTemplate={formContext.formTemplate + '.agreementsForm'}
                            key   = {index}
                        >
                            <div className="currencyRow">
                                <div className="removeCurrencies">
                                    <img style={{height: '18px', marginTop: "7px", float: "left"}} src={removeIcon} alt="Remove"
                                         className="closeDashboardComponentButton"
                                         onClick={() => dispatch(removeCurrencyPair(element.currencyPairId))}
                                    />
                                </div>
                                <div className="selectedCurrencies">
                                    {element.currencyPairName}
                                </div>
                                <div className="selectedAgreements" style={{visibility: eligibleForMarketOrder ? 'visible' : 'hidden'}}>
                                    <XpFormSwitchInput
                                        field="useMarketOrderAgreements"
                                        onChangeCallback={enabled => dispatch(toggleAgreementType(enabled, element.currencyPairId, 'marketOrder'))}
                                    />
                                </div>
                                <div className="selectedAgreements" style={{visibility: eligibleForListRate ? 'visible' : 'hidden'}}>
                                    <XpFormSwitchInput
                                        field="useListRateAgreements"
                                        onChangeCallback={enabled => dispatch(toggleAgreementType(enabled, element.currencyPairId, 'listRate'))}
                                    />
                                </div>
                            </div>
                        </XpFormFieldset>
                    )
                })}
            </div>
        );
    }


    const remainingCurrencyPairs = useMemo(
        () => {
            if (!formDataCurrencyPairs?.length) return ormCurrencyPairs;
            let retArray = [];
            for (const currencyPair of ormCurrencyPairs) {
                if (!formDataCurrencyPairs.find(formCcy => currencyPair.id === Number(formCcy.currencyPairId))) retArray.push(currencyPair);
            }
            return retArray;
        },
        [formDataCurrencyPairs]
    );

    const filteredCurrencyPairs = useMemo(
        () => {
            if (!formDataSearchByCurrency || !formDataSearchCurrencyId) return remainingCurrencyPairs;
            return remainingCurrencyPairs.filter(cp => ((cp.baseCurrency.id === formDataSearchCurrencyId) || (cp.quoteCurrency.id === formDataSearchCurrencyId)));
        },
        [remainingCurrencyPairs, formDataSearchByCurrency, formDataSearchCurrencyId]
    );

    return (
        <div  className="formCard">
            <div className={"adminFormContainer"}>
                <div className={"adminColumn admin2Column"}>
                    <div className="currencySelect">
                        <XpFormSwitchInput
                            field="searchByCurrency"
                        />
                        {formDataSearchByCurrency &&
                        <XpFormSelectorInput
                            field="searchCurrencyId"
                            selectList    = {ormCurrencies}
                            itemTextField = "currency"
                            // inLineLayout  = {false}
                        />}
                        <XpFormSelectorInput
                            field="currencyPairId"
                            selectList={filteredCurrencyPairs}
                            itemTextField = "currencyPairName"
                            onChangeCallback={addCurrencyPair}
                        />
                    </div>
                </div>
                <div className={"adminColumn admin2Column"}>
                    {currencyPairList()}
                </div>
            </div>
        </div>
    );
}

export default RfWizardCurrencyPairsForm;
