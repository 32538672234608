import React, {useEffect, useMemo} from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {useDispatch} from "react-redux";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getShallowClientSelector} from "features/client/clientSelectors";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

export function ExemptFromSuspendForm (props) {
    const formContext = useXpFormContext();
    const editClientApiChannel    = useXpFormField('xpAdminForm.clientSettings.Client.' + formContext.currentData?.client?.id + '.clientApiChannel');
    const newClientApiChannel     = useXpFormField('xpAdminForm.clientSettings.Client.new.clientApiChannel');
    const contextClientApiChannel = useXpFormField('clientApiChannel');
    const savedClientData = useSelectorInstance(getShallowClientSelector, {selectId: formContext.currentData?.client?.id});
    const dispatch = useDispatch();
    const noFormValidation = useAppEnvProperty('noFormValidation');
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');
    const lrActive = useRefixServiceIsActive(refixServices.ListRates);

    const clientApiChannel = useMemo(
        () => {
            if (formContext.formUseState === XP_FORM_VIEW) {
                return savedClientData?.clientApiChannel;
            }
            if (contextClientApiChannel) return contextClientApiChannel;
            if (editClientApiChannel) return editClientApiChannel;
            if (newClientApiChannel) return newClientApiChannel;
            return savedClientData?.clientApiChannel;
        },
        [editClientApiChannel, newClientApiChannel, savedClientData, contextClientApiChannel]
    );

    useEffect(
        () => {
            if (noFormValidation) {
                return;
            }
            if (clientApiChannel !== "INTERNAL") {
                dispatch(xpFormChangeField(formContext.formModel + '.exemptFromGlobalSuspend', false));
            }
        },
        [clientApiChannel, noFormValidation],
    );

    if (formDataPriceFeedOnly) {
        return null;
    }
    if (!lrActive) {
        return null;
    }

    return (
        <div className={props.className}>
            <XpFormSwitchInput
                field         = "exemptFromGlobalSuspend"
                defaultValue={false}
                disabled={!noFormValidation && (clientApiChannel !== "INTERNAL")}
            />
        </div>
    );
}
ExemptFromSuspendForm.propTypes = {
    className:        PropTypes.string,
};
