import React from 'react'
import PropTypes from 'prop-types';
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";


export function PriceFeedAgreementStatic (props) {
    const formDataType  = useXpFormField('type');

    if (!['PriceFeedAgreement'].includes(formDataType)) return null;

    return (
        <div className={props.className}>
            <XpFormTextInput
                isRequired inLineLayout
                field         = "targetTopic"
                parser = {value => {
                    if (value === undefined) return "";
                    let outString;
                    let parseStr = String(value);

                    outString = parseStr.replace(/[^a-zA-Z0-9._]/g, '');

                    if (
                        !isNaN(parseInt(outString[0], 10)) ||
                        (outString[0] === '_') ||
                        (outString[0] === '.')
                    ) return outString.slice(1);
                    return outString;
                }}
                maxLength={100}
            />
        </div>
    );
}
PriceFeedAgreementStatic.propTypes = {
    className:        PropTypes.string,
};
