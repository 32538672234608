import React, {useMemo} from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {activeTenorsSelector} from "gui-common/orm/ormSelectors"
import {activeDispatchPeriodsSelector, activePeriodsSelector} from "appConfig/orm/ormSelectors";
import {selectAppEnvProperty, useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormTimeInput from "gui-common/xpForm/core/XpFormTimeInput";
import {timeEarlierThanParameter, timeLaterThanParameter} from "gui-common/xpForm/formComponentsValidators";


export function ListRateAgreementStatic2 (props) {
    const tenors              = useSelector(state => activeTenorsSelector(state, {agreementType: 'ListRateAgreement'}));
    const listRatePeriods     = useSelector(activePeriodsSelector);
    const dispatchPeriods     = useSelector(activeDispatchPeriodsSelector);
    const noFormValidation    = useSelector(state => selectAppEnvProperty(state, 'noFormValidation'));
    const agreementFormConfig = useAppEnvProperty('agreementFormConfig');
    const formDataListRatePeriodId = useXpFormField('listRatePeriodId');
    const formDataTenor            = useXpFormField('tenor');
    const formDataType             = useXpFormField('type');

    const isWeekendRate = useMemo(
        () => {
            let period = listRatePeriods.find(item => item.id === Number(formDataListRatePeriodId));
            if (!period) return false;
            return (period.period === 'Weekend');
        },
        [formDataListRatePeriodId]
    );

    const tenorsToDisplay = useMemo(
        () => {
            if (noFormValidation) return tenors;
            if (!isWeekendRate) return tenors;
            return tenors.filter(item => (!['TD', 'ASAP', 'BROKEN'].includes(item.name)) );
        },
        [isWeekendRate]
    );

    const periodsToDisplay = useMemo(
        () => {
            if (noFormValidation) return listRatePeriods;
            if (!['TD', 'ASAP', 'BROKEN'].includes(formDataTenor)) return listRatePeriods;
            return listRatePeriods.filter(item => item.period !== 'Weekend');
        },
        [formDataTenor]
    );


    if (formDataType !== 'ListRateAgreement') return null;


    // const tenorsToDisplay  = (isWeekendRate && !noFormValidation) ? tenors.filter(item => item.name !== 'TD') : tenors;
    // const periodsToDisplay = ((formDataTenor === 'TD') && !noFormValidation) ? listRatePeriods.filter(item => item.period !== 'Weekend') : listRatePeriods;
    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field="tenor"
                selectList={tenorsToDisplay.map(item => {return {id: item.name}})}
                itemTextField="id"
                defaultValue={tenors?.length ? tenors[0].name : ""}
            />
            {(formDataTenor === 'ASAP') &&
            <XpFormTimeInput
                inLineLayout isRequired
                field = "todayCutOffTime"
                defaultValue   ={agreementFormConfig?.fieldsConfig?.todayCutOffTime?.default}
                dependentFields={{
                    closeTime: 'closeTime',
                    openTime : 'openTime',
                }}
                errors={{
                    earlierThanOpen   : (val, fields) => timeEarlierThanParameter(val, fields.openTime),
                    laterThanClose    : (val, fields) => timeLaterThanParameter(  val, fields.closeTime),
                }}
            />}
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field="listRatePeriodId"
                selectList={periodsToDisplay}
                itemTextField="period"
                defaultValue={listRatePeriods?.length ? listRatePeriods[0].id : ""}
            />
            <XpFormAmountInput
                inLineLayout
                field          = "dispatchLevel"
                mustBeAboveZero= {true}
            />
            <XpFormSelectorInput
                inLineLayout
                field="dispatchTradePeriodId"
                selectList={dispatchPeriods}
                itemTextField="period"
            />
        </div>
    );
}
ListRateAgreementStatic2.propTypes = {
    className:        PropTypes.string,
};
