import React from 'react'
import {useSelector} from "react-redux";
import {userSelector} from "gui-common/orm/ormSelectors"
import AdminUserStates from "gui-common/userSettings/sharedSettings/AdminUserStates"
import {EditTextModeSetting} from "gui-common/appLocale/xpTranslated/EditTextModeSetting";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {renderUserProfiles} from "gui-common/user/userFunctions";
import {withRouter} from "react-router-dom";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";
import {UserTopics} from "gui-common/userSettings/topics/UserTopics";
import {commonSystemRights} from "gui-common/userRights/userRightsConstants";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";

function UserInfo ({user}) {
    return (
        <div className="formCard userPrefsCard" xp-test-id='xpUserInfo'>
            <h3 className="userPrefsCardHeader">
                <XpTranslated trKey={'userPreferencesForm.userInfoHeader'}/>
            </h3>

            <div className="field">
                <label className="inlineLabel">
                    <XpTranslated trKey={'userPreferencesForm.userId.label'}/>
                </label>
                <p className="inlineValueText">{user.id}</p>
            </div>
            <div className="field">
                <label className="inlineLabel">
                    <XpTranslated trKey={'userPreferencesForm.userName'}/>
                </label>
                <p className="inlineValueText">{user.firstName + " " + user.lastName}</p>
            </div>
            <div className="field">
                <label className="">
                    <XpTranslated trKey={'userPreferencesForm.profiles'}/>
                </label>
                <div className="valueText">
                    {renderUserProfiles(user)}
                </div>
            </div>
        </div>
    )
}

function UserPreferencesForm(props) {
    const oxygenAppContext = useOxygenAppContext();
    const user = useSelector(userSelector);
    if (!user) return null;

    const userCanUpdateSystemTexts   = userHasSystemRight(user, commonSystemRights.SystemText.Create);

    return (
        <div>
            <h2>
                <XpTranslated trKey={'userPreferencesForm.header'}/>
                {user.firstName}
            </h2>
            <hr/>
            <XpForm formModel={'userPreferencesForm'} initialUseState = {XP_FORM_EDIT}>
            <div className={"adminFormContainer"}>
                    <div className="adminColumn admin3Column">
                        <UserInfo user = {user}/>
                        <div className="formCard userPrefsCard">
                            <UserTopics/>
                        </div>
                        {userCanUpdateSystemTexts &&
                        <div className="formCard userPrefsCard">
                            <EditTextModeSetting/>
                        </div>}
                    </div>

                    <div className="adminColumn admin3Column">
                        <div className="formCard userPrefsCard">
                            <AdminUserStates/>
                        </div>
                    </div>
                    <div className="adminColumn admin3Column">
                        {oxygenAppContext.appConfig.userSettings.widgets.map((setting, index) => (
                            <div className="formCard userPrefsCard" key={index}>
                                {setting}
                            </div>
                        ))}
                    </div>
            </div>
            </XpForm>
        </div>
    );
}
export default withRouter(UserPreferencesForm);


