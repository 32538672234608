import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import {
    timeEarlierOrSameThanParameter,
    timeLaterThanParameter, timeRequiredIfRelatedFieldsAreFilled
} from "gui-common/xpForm/formComponentsValidators";
import {useSelector} from "react-redux";
import {activePeriodsSelector} from "appConfig/orm/ormSelectors";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormTimeInput from "gui-common/xpForm/core/XpFormTimeInput";

export function AgreementOpenTimesStatic (props) {
    const listRatePeriods    = useSelector(activePeriodsSelector);
    const formDataListRatePeriodId = useXpFormField('listRatePeriodId');
    const formDataType             = useXpFormField('type');
    const formDataTenor            = useXpFormField('tenor');

    const weekendRate = useMemo(
        () => {
            let period = listRatePeriods.find(item => item.id === Number(formDataListRatePeriodId));
            if (!period) return false;
            return (period.period === 'Weekend');
        },
        [formDataListRatePeriodId]
    );

    if (!['ListRateAgreement', 'MarketOrderAgreement', 'MarketOrderAllAgreement', 'TradeReportAgreement'].includes(formDataType)) return null;

    return (
        <div className={props.className}>
            <XpFormTimeInput
                inLineLayout
                field= "openTime"
                dependentFields={{
                    closeTime: 'closeTime',
                    todayCutOffTime: 'todayCutOffTime'
                }}
                errors={{
                    laterThanClose    : (val, fields) => !weekendRate && timeLaterThanParameter(  val, fields.closeTime),
                    earlierThanClose  : (val, fields) => weekendRate &&  timeEarlierOrSameThanParameter(val, fields.closeTime),
                    allOrNoneRequired : (val, fields) => timeRequiredIfRelatedFieldsAreFilled(val, [fields.closeTime]),
                    laterThanCutoff   : (val, fields) => ((formDataTenor === 'ASAP') && (formDataType === 'ListRateAgreement')) && timeLaterThanParameter(  val, fields.todayCutOffTime),
                }}
            />
            <XpFormTimeInput
                inLineLayout
                field= "closeTime"
                dependentFields={{
                    openTime: 'openTime',
                    todayCutOffTime: 'todayCutOffTime'
                }}
                errors={{
                    earlierThanOpen   : (val, fields) => !weekendRate && timeEarlierOrSameThanParameter(val, fields.openTime),
                    laterThanOpen     : (val, fields) => weekendRate &&  timeLaterThanParameter(  val, fields.openTime),
                    allOrNoneRequired : (val, fields) => timeRequiredIfRelatedFieldsAreFilled(val, [fields.openTime]),
                    earlierThanCutoff : (val, fields) => ((formDataTenor === 'ASAP') && (formDataType === 'ListRateAgreement')) && timeEarlierOrSameThanParameter(val, fields.todayCutOffTime),
                }}
            />
        </div>
    );
}
AgreementOpenTimesStatic.propTypes = {
    className:        PropTypes.string,
};
