import React from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    activeCountriesSelector,
} from "gui-common/orm/ormSelectors";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormRadioInput from "gui-common/xpForm/core/XpFormRadioInput";
import XpFormCheckboxInput from "gui-common/xpForm/core/XpFormCheckboxInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";

function LegalEntityBaseStatic (props) {
    const formContext = useXpFormContext();
    const formConfig                = useSelector(state => state.appEnvState.legalEntityFormConfig);
    const availableLegalPersonTypes = useSelector(state => state.appEnvState.availableLegalPersonTypes);
    const ormCountries              = useSelector(activeCountriesSelector);
    const formDataHasLei = useXpFormField('hasLEI');
    const formDataLegalPersonType = useXpFormField('legalPersonType');

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field="countryId"
                selectList={ormCountries}
                itemTextField="country"
            />

            {((availableLegalPersonTypes.length > 1) && !formConfig.fieldsConfig.legalPersonType.hide) &&
            <XpFormRadioInput
                field="legalPersonType"
                radioItems    = {availableLegalPersonTypes}
            />}

            {((formDataLegalPersonType !== "NATURAL") && !formConfig.fieldsConfig.lei.hide) &&
            <div>
                {(formContext?.formUseState !== XP_FORM_VIEW) &&
                <XpFormCheckboxInput
                    field= "hasLEI"
                />}

                {((formContext.formUseState === XP_FORM_VIEW) ? (formContext?.currentData?.hasLEI === true) : (formDataHasLei === true)) &&
                <XpFormTextInput
                    field= "lei"
                    checkNoSpaces={true}
                    isRequired
                    checkOnlySpaces={false}
                    exactLength={20}
                />}
            </div>}

        </div>
    );
}

LegalEntityBaseStatic.propTypes = {
    className:        PropTypes.string,
};
export default LegalEntityBaseStatic;

