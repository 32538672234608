import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {listRateOrderStates} from "features/listRateOrder/listRateOrderConstants";
import {dispatchTradeSides} from "features/dispatchTrade/dispatchTradeConstants";

export function prepareListRateOrdersColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id'                , 100 , 100 , 'listRateOrdersList.id', {cellRenderer: 'agGroupCellRenderer', sort: 'desc', lockVisible: true, lockPosition: true});
    xpColCreate(translate, subArray, hiddenState, 'listRateId'        , 50 , 50 , 'listRateOrdersList.listRateId', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'legalEntityUnitName', 50 , 50 , 'listRateOrdersList.legalEntityUnitName');
    xpColCreate(translate, subArray, hiddenState, 'appId'              , 50 , 50 , 'listRateOrdersList.appId');
    xpColCreate(translate, subArray, hiddenState, 'sideT'             , 50 , 50 , 'listRateOrdersList.side', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: dispatchTradeSides.map(item => 'dispatchTradesList.sideTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'amount'            , 100, 50 , 'listRateOrdersList.amount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'numberOfAllocations', 100, 50 , 'listRateOrdersList.numberOfAllocations', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'amountCurrencyT'   , 50 , 50 , 'listRateOrdersList.amountCurrency');
    xpColCreate(translate, subArray, hiddenState, 'rate'              , 100, 50 , 'listRateOrdersList.rate', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'currencyPairStr'   , 200, 100, 'agreementsList.currencyPairStr');
    xpColCreate(translate, subArray, hiddenState, 'tenor'             , 50 , 50 , 'agreementsList.tenor');
    xpColCreate(translate, subArray, hiddenState, 'counterAmount'     , 100, 50 , 'listRateOrdersList.counterAmount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'clientReference'   , 200, 100, 'listRateOrdersList.clientReference');
    xpColCreate(translate, subArray, hiddenState, 'meansOfPayment'    , 100, 50 , 'listRateOrdersList.meansOfPayment');
    xpColCreate(translate, subArray, hiddenState, 'instructedTime'    , 200, 100, 'listRateOrdersList.instructedTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'YYYY-MM-DD, HH:mm:ss'}});
    xpColCreate(translate, subArray, hiddenState, 'settlementDate'    , 100, 50 , 'listRateOrdersList.settlementDate');
    xpColCreate(translate, subArray, hiddenState, 'guiStatusT'        , 100, 50 , 'listRateOrdersList.status', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: listRateOrderStates.map(item => 'listRateOrdersList.statusTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState, 'statusMessage'     , 200, 100, 'listRateOrdersList.statusMessage', {tooltipField: 'statusMessage'});

    xpColCreate(translate, subArray, hiddenState, 'rateInterBank'         , 100, 50 , 'listRateOrdersList.rateInterBank'         , {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'forwardPoints'         , 100, 50 , 'listRateOrdersList.forwardPoints'         , {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'forwardPointsInterBank', 100, 50 , 'listRateOrdersList.forwardPointsInterBank', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'spotRate'              , 100, 50 , 'listRateOrdersList.spotRate'              , {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'spotRateInterBank'     , 100, 50 , 'listRateOrdersList.spotRateInterBank'     , {cellStyle: {textAlign: "right"}});

    return subArray;
}
