import React  from 'react'
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import EntityBaseStaticFormComponent from "gui-common/xpAdminForm/EntityBaseStaticFormComponent";
import {ClientBaseStatic} from "features/client/formComponents/ClientBaseStatic";
import LegalEntityBaseStatic from "features/legalEntity/formComponents/LegalEntityBaseStatic";
import {LegalEntityUnitBaseStatic} from "features/legalEntityUnit/formComponents/LegalEntityUnitBaseStatic";
import {LegalEntityUnitFxSettings} from "features/legalEntityUnit/formComponents/LegalEntityUnitFxSettings";
import {ClientApiChannelSettings} from "features/client/formComponents/ClientApiChannelSettings";
import {FormTradingLimit} from "features/legalEntityUnit/formComponents/FormTradingLimit";
import {LegalEntityUnitRestTimeZone} from "features/legalEntityUnit/formComponents/LegalEntityUnitRestTimeZone";
import {ExemptFromSuspendForm} from "features/legalEntityUnit/formComponents/ExemptFromSuspendForm";


function RfWizardClientBasicForm(props) {
    return (
        <div className="formCard">
            <XpFormFieldset formTemplate='clientForm'>
                <div className={"adminFormContainer"}>
                    <div className={"adminColumn admin3Column"}>
                        <EntityBaseStaticFormComponent enforceUseCanEdit suppressNameAutofocus/>
                        <XpFormFieldset formTemplate='legalEntityUnitForm'>
                            <LegalEntityUnitBaseStatic/>
                        </XpFormFieldset>
                    </div>
                    <div className={"adminColumn admin3Column"} style={{paddingTop: '7px'}}>
                        <ClientBaseStatic/>
                        <XpFormFieldset formTemplate='legalEntityForm'>
                            <LegalEntityBaseStatic/>
                        </XpFormFieldset>
                        <XpFormFieldset formTemplate='legalEntityUnitForm'>
                            <LegalEntityUnitFxSettings/>
                        </XpFormFieldset>
                    </div>
                    <div className={"adminColumn admin3Column"} style={{paddingTop: '7px'}}>
                        <ClientApiChannelSettings/>
                        <XpFormFieldset formTemplate='legalEntityUnitForm'>
                            <ExemptFromSuspendForm/>
                            <FormTradingLimit/>
                            <LegalEntityUnitRestTimeZone/>
                        </XpFormFieldset>
                    </div>
                </div>
            </XpFormFieldset>
        </div>
    );
}
export default RfWizardClientBasicForm;
/*

class RfWizardClientBasicForm extends Component {
    render() {
        const entityData = {
            form: this.props.form,
            current: this.props.form,
            beforeChange: this.props.form
        };
//        const timeZones = (window.oxygen_env && window.oxygen_env.AVAILABLE_TIMEZONES) ? window.oxygen_env.AVAILABLE_TIMEZONES : [{id: "Europe/Stockholm"}];
        return(
            <div className="formCard">
                <Form
                    model="myForms.refixClientWizardForm"
                    onSubmit={this.props.submitMethod}
                    className={"wizardFormContainer"}
                  >
                    <div className={"wizardFormColumn1"}>
{/!*                        <XpFormTextInput
                            formTemplate="refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            thisFormData={this.props.form}
                            field="clientName"
                            disabled={false}
                            isRequired
                            checkNoSpaces={false}
                            checkOnlySpaces={true}
                            minLength={2}
                            maxLength={50}
                        />
                        <XpFormTextAreaInput
                            formTemplate="refixClientWizardForm"
                            formState={this.props.form.formState}
                            entityData={entityData}
                            thisFormData={this.props.form}
                            field="clientDescription"
                            disabled={false}
                            isRequired={false}
                            checkNoSpaces={false}
                            checkOnlySpaces={true}
                            maxLength={100}
                        />*!/}
                    </div>
                    <div className={"wizardFormColumn2"}>
{/!*
                        <XpFormTextInput
                            formTemplate="refixClientWizardForm"
                            formState={this.props.form.formState}
                            entityData={entityData}
                            thisFormData={this.props.form}
                            field="clientRefId"
                            disabled={false}
                            isRequired={true}
                            exactLength={12}
                            checkOnlyDigits={true}
                        />
*!/}
{/!*
                        <XpFormTextInput
                            field         = "appId"
                            formTemplate  = "refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            disabled      = {false}
                            checkNoSpaces = {true}
                            isRequired    = {true}
                            exactLength   = {34}
                        />
*!/}
{/!*
                        <XpFormSelectorInput
                            isRequired noTemplate
                            field="operatingUnitId"
                            formTemplate="refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            selectList={this.props.operatingUnits}
                            inLineLayout  = {false}
                            itemTextField="name"
                            disabled={false}
                        />
*!/}
{/!*
                        <XpFormSelectorInput
                            isRequired noTemplate
                            field="countryCode"
                            formTemplate="refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            selectList={this.props.ormCountries}
                            itemTextField="country"
                            disabled={false}
                            inLineLayout={false}
                        />
*!/}
                    </div>
                    <div className={"wizardFormColumn3"}>
{/!*
                        <XpFormSelectorInput
                            isRequired noTemplate
                            field="restTimeZone"
                            formTemplate="refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            selectList    = {this.props.appEnvState.availableTimeZones}
                            itemTextField = "id"
                            disabled      = {false}
                            inLineLayout  = {false}
                        />
*!/}
{/!*
                        <XpFormSelectorInput
                            isRequired noTemplate
                            field="fxProviderId"
                            formTemplate="refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            selectList={this.props.ormProviders.filter(item => item.providerType === 'FX')}
                            itemTextField="name"
                            disabled={false}
                            inLineLayout  = {false}
                        />
                        <XpFormTextInput
                            field="shortName"
                            formTemplate="refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            disabled={false}
                            checkNoSpaces={true}
                            checkOnlySpaces={false}
                            minLength={2}
                            maxLength={20}
                            isRequired={true}
                            inLineLayout  = {false}
                        />
*!/}
{/!*
                        <XpFormCheckboxInput
                            field         = "netDispatchTrades"
                            formTemplate  = "refixClientWizardForm"
                            formState     = {this.props.form.formState}
                            entityData    = {entityData}
                            disabled      = {false}
                        />
*!/}
                    </div>
                </Form>
            </div>
        )

    }
}

const mapStateToProps = (state, props) => {
    const form = state.myForms.refixClientWizardForm;
    return {
        translate:          selectTranslateFunction(state),
        currentLanguage:    selectActiveLanguage(state),
        form:               form,
        ormCurrencies :     activeCurrenciesSelector(state),
        operatingUnits :    operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForWizard'}),
        ormCountries:       activeCountriesSelector(state),
        appEnvState:        state.appEnvState,
        ormProviders:       shallowActiveProvidersSelector(state),
        //ormInstitutions:    activeBankAccountInstitutionsSelector(state),
        agreements :         getField(state, 'myForms.refixClientWizardForm.agreements.0'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetForm:                  (model)     => dispatch(actions.reset(model)),
        submitForm:                 (model)     => dispatch(actions.submit(model)),
        setParametersFormValue: (model, string)     => dispatch(actions.change(model, string)),
    }
};

export default RfWizardClientBasicForm;
*/
