export const dispatchTradeStates = [
    "Active",
    "TimeToDispatch",
    "SentToBook",
    "Timeout",
    "Booked",
    "ManualBook",
    "ClosedWithoutOrders",
    "ClosedWithoutNetVolume",
]
export const dispatchTradeSides = [
    "BUY",
    "SELL",
    "BUYSELL",
]

export const tradeReportStates = [
    "NotSent",
    "Sent",
    "Confirmed",
    "Failed"
]
