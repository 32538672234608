import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {XP_FORM_NEW, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {userCanEditBase} from "gui-common/userRights/userRightsFunctions";

export function getXpAdminFormModel(formInstance, ormModel, rootObject) {
    return 'xpAdminForm.' + formInstance + '.' + ormModel + '.' + (rootObject?.id ? rootObject.id : 'new');
}
export function childEntityInParentForm(childProps, formUseState, dependentFields, formModel, currentData) {
    if (!childProps.inParentFormProperty) return false;

    if (formUseState === XP_FORM_VIEW) {
        if (!currentData) return false;
        return (!currentData[childProps.inParentFormProperty]);
    }
    if (!dependentFields) return false;
    return !dependentFields[formModel + '.' + childProps.inParentFormProperty];
}

export function getFirstChild(childProp, thisEntity) {
    if (!childProp || !childProp.arrayProperty) return undefined;
    if (!thisEntity) return undefined;
    return thisEntity[childProp.arrayProperty] ? thisEntity[childProp.arrayProperty][0] : undefined;
}

export function formDiffersFromCurrent(currentData, formData, decDenLangState) {
    const str2num = (val) => stringNumberToFloat(val, decDenLangState);
    const logDiff = (key, curr, form) => {
        console.log("diff in  " + key + ": currentData: ", curr, " formData: ", form);
    }

    if (!formData) return true;

    for (let objKey in currentData) {
        const currVal = currentData[objKey];
        let   formVal = formData[objKey];

        if (currVal && currVal._isAMomentObject) continue;

        if (Array.isArray(currVal)) {
            if (!Array.isArray(formVal))           {logDiff(objKey, currVal, formVal); return true;}
            if (formVal.length !== currVal.length) {logDiff(objKey, currVal, formVal); return true;}

            for (let index in currVal) {
                if (formDiffersFromCurrent(currVal[index], formVal[index], decDenLangState)) {logDiff(objKey, currVal, formVal); return true;}
            }
            continue;
        }

        if ((currVal === null) && (formVal === null)) continue; // null is of type object.
        if (typeof currVal === "object") {
            if (typeof formVal !== "object") {logDiff(objKey, currVal, formVal); return true;}
            if (formDiffersFromCurrent(currVal, formVal, decDenLangState)) {logDiff(objKey, currVal, formVal); return true;}
            continue;
        }

        if ((typeof currVal === "number") && (typeof formVal !== "number")) {
            formVal = str2num(formVal);
        }
        if (currVal !== formVal) {logDiff(objKey, currVal, formVal); return true;}
    }
    return false;
}
/*
export function closeXpAdminForm(formInstance, ormModel, formData, dispatch) {


    if (!formData) return;
    const formModel = getXpAdminFormModel(formInstance, ormModel, formData);

/!*
    const formModelArray = formModel.split('.');
    const propToOmit = formModelArray[formModelArray.length-1];
    const rootModel = formModelArray.slice(0,formModelArray.length-1).join('.');
*!/

    // dispatch(actions.omit(rootModel, propToOmit));
    // dispatch(actions.change(formModel, null, {silent: true}));


    // I added this to avoid the problem with form crashing when first editing client with one element in a runConfiguration array and adding another element.
    // All works fins with saving the client with two entities. When opening edit again crashes the form complaining it can find index 1 in the array.
    // Calling the load action creator when closing the form (after save with the second element added) clears the crash. Looks like a bug in the form lib.
    // However, calling load will make updates from other users not update form when editing.

    // dispatch(actions.load(formModel, formData));
    // dispatch(actions.reset(formModel));
    // dispatch(actions.omit(rootModel, propToOmit));
    dispatch(xpAdminFormClose(formInstance, ormModel, formData));
    setTimeout(() => {
        // dispatch(actions.change(formModel, {}, {silent: true}));

        if (!formData.id) dispatch(actions.reset(formModel)); // Resetting the forms makes it crash for runConfiguration arrays. Works for new, i.e. id=null.

        // dispatch(actions.omit(rootModel, propToOmit));
    }, 10);
}*/

export function getFilteredRunConfigurations(runConfiguration) {
    let filteredRunConfiguration = runConfiguration.id ? {id: runConfiguration.id} : {};

    for (let type in runConfiguration) {
        if (type === 'id') continue;
        if (!Array.isArray(runConfiguration[type])) continue;

        let filteredTypeArray = [];
        let onlyIncludedParentItems = true;
        for (const runConfigurationElement of runConfiguration[type]) {
            if (runConfigurationElement.isDeleted) continue;
            if (!runConfigurationElement.ownedByParent) { // Always include local elements ...
                // Now we have a local parent element.
                onlyIncludedParentItems = false;
                filteredTypeArray.push(runConfigurationElement);
                continue;
            }
            if (runConfigurationElement.includedInRunConfiguration) { // ... and parent elements that are included
                filteredTypeArray.push(runConfigurationElement);
                continue;
            }
            // Now we have an excluded parent element.
            onlyIncludedParentItems = false;
        }
        if (onlyIncludedParentItems) continue; // No excluded parent elements or local elements, i.e. no user input on run configuration. Leave type blank to convey parent downwards in tree.

        filteredRunConfiguration[type] = filteredTypeArray;
    }
    return filteredRunConfiguration;
}

export function xpFormUserCanEditBase(formContext, ormModel, isSubForm) {
    if (formContext?.formUseState === XP_FORM_NEW) return true;
    if (formContext?.formUseState === XP_FORM_VIEW) return true;
    return userCanEditBase(formContext.currentData);

    // MT: I do not understand how the code below can be correct. We should use instance rights on the respective object.
    // if (!formContext.rootCurrentData) return false;
    // if (!isSubForm) return userCanEditBase(formContext.rootCurrentData);
    // return domainRightAllowed(ormModel, formContext.rootCurrentData, 'EditBase');
}

export function editBaseUsed(props) {
    if (props.xpAdminFormConfig && props.xpAdminFormConfig[props.ormModel]) {
        return props.xpAdminFormConfig[props.ormModel].editBaseUsed;
    }
    return false;
}

