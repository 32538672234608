import React from 'react';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {getUserSelector} from "gui-common/orm/ormSelectors"
import 'gui-common/userRights/adminUserRights.css'
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectMyXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import UserList from "gui-common/user/UserList";
import {useSelectorInstance} from "gui-common/functions/hooks";
import AdminDataRights2 from "gui-common/userRights/AdminDataRights2";

export const rightsUserListInstanceId = "rightsUserList";
function AdminUserRightsPage(props) {

    const userGridState = useSelector(state => selectMyXpGridState(state, {instanceId: rightsUserListInstanceId}));
    const selectedUserId = userGridState ? userGridState.selectedId : undefined;

    const selectedUser = useSelectorInstance(getUserSelector, {userId: selectedUserId});

    return (
        <div>
            <h2><XpTranslated trKey='adminUserRights.header'/></h2>
            <UserList
                instanceId = {rightsUserListInstanceId}
            />
            <hr/>
            {selectedUserId ?
                <div>
                    <AdminDataRights2
                        user = {selectedUser}
                    />
                </div>
                :
                <h3 style={{textAlign:'center'}}><XpTranslated trKey='adminUserRights.noUserSelected'/></h3>
            }
        </div>
    );
}

export default withRouter(AdminUserRightsPage);

