// import {createSelector} from "reselect";

export const selectEventServiceState = state => state.eventServiceState;

/*
export const getEventListenersSelector = () => createSelector(
    [
        selectEventServiceState,
        (state, props) => props,
    ],
    (eventServiceState, event) => {
        if (!eventServiceState) return null;
        if (!event) return null;
        return eventServiceState[event];
    }
);
*/
