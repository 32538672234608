import {
    createStandardAuditFields2,
    xpColCreate, xpColHeadCreate
} from "gui-common/xpGrid/xpGridFunctions";
import {allowAll, instanceRightAllowed} from "gui-common/userRights/userRightsFunctions";
import {agreementStates, agreementTypes} from "features/agreement/agreementConstants";
import {useStore} from "react-redux";
import {getOrmItemSelectorGetter} from "gui-common/orm/ormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_DIALOG, CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {getActiveListRatesWithOwnerProcessDown, getListRatesAffectedByAction} from "features/listRate/listRateFunctions";
import {putAgreementActionToApi} from "features/agreement/agreementApi";
import {entityIsActive} from "gui-common/audit/auditFunctions";
import {refixDataRights, refixDataRightsDomainMap} from "appConfig/appUserRights";
import {getOrmItemFromApi} from "gui-common/orm/ormApi";
import {tradeReportAgreementPendingDeliveriesSelector} from "features/fxTradeReport/fxTradeReportSelectors";
import {xpFormApiSubmit} from "gui-common/xpForm/core/xpFormReducer";

export function canAgreementBeDisabled(agreement, store) {
    // Only allow to disable of a list rate agreement when in state suspended.
    if (agreement.type === 'ListRateAgreement' && agreement.status !== 'Suspended') {
        return {
            canBeDisabled: false,
            modalKey: 'agreementForm.modalConfirmListRateAgreementActive',
            modalParams: {name: agreement.name}
        };
    }
    // Normally, agreements can always be disabled.
    return {
        canBeDisabled: true,
        modalKey: 'agreementForm.modalConfirmDisableAgreement',
        modalParams: {name: agreement.name}
    };
}

export function getAgreementCustomDisableExecutor(agreement, store, disableFunction) {
    if (agreement.type === 'TradeReportAgreement') {
        const pendingDeliveries = tradeReportAgreementPendingDeliveriesSelector(store.getState(), agreement.id);
        if (!pendingDeliveries?.length) {
            console.warn("No pending deliveries found for agreement ",agreement);
            return undefined;
        }
        return () => {
            const pendingDelivery = pendingDeliveries[0];
            store.dispatch(getOrmItemFromApi('FxTradeReportDelivery', pendingDelivery.id))
                .then(res => {
                    store.dispatch(pushModalWindow(CONFIRMATION_DIALOG,
                        {
                            modalKey: res?.response?.fxTradeReportEvents?.length ? 'agreementForm.modalConfirmTradeReportAgreementWithEvents' : 'agreementForm.modalConfirmDisableAgreement',
                            modalParams: {name: agreement.name, numberOfEvents: res?.response?.fxTradeReportEvents?.length},
                            bodyClassName: res?.response?.fxTradeReportEvents?.length ? "modalBodyHighestAlert" : undefined
                        }))
                        .then(({status}) => {
                            if (status === MODAL_TYPE_CONFIRM) {
                                disableFunction();
                            }
                            if (status === MODAL_TYPE_CANCEL) {}
                        });
                })
        }
    }
    return undefined;
}


export function getAgreementsColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState,'name'                 , 200, 100, 'agreementsList.name', {cellRenderer: 'agGroupCellRenderer', lockVisible: true, lockPosition: true});
    xpColCreate(translate, subArray, hiddenState,'id'                   , 100, 50 , 'agreementsList.id', {cellStyle: {textAlign: "right"}, sort: 'desc'});
    xpColCreate(translate, subArray, hiddenState,'currencyPairStr'      , 200, 100, 'agreementsList.currencyPairStr');
    xpColCreate(translate, subArray, hiddenState,'typeT'                , 100, 50, 'agreementsList.type', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: agreementTypes.map(item => 'agreementForm.type.values.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColCreate(translate, subArray, hiddenState,'clientApiChannelT'    , 100, 50, 'clientForm.clientApiChannel.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState,'tenor'                , 100, 50 , 'agreementsList.tenor');
    xpColCreate(translate, subArray, hiddenState,'exemptFromGlobalSuspendT', 50, 50, 'legalEntityUnitForm.exemptFromGlobalSuspend.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState,'todayCutOffTime'      , 100, 50 , 'agreementsList.todayCutOffTime');
    xpColCreate(translate, subArray, hiddenState,'listRatePeriodT'      , 100, 50 , 'agreementsList.listRatePeriod');
    xpColCreate(translate, subArray, hiddenState,'dispatchTradePeriodT' , 100, 50 , 'agreementsList.dispatchTradePeriod');
    xpColCreate(translate, subArray, hiddenState,'dispatchLevel'        , 200, 100, 'agreementsList.dispatchLevel', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'gracePeriodT'         , 200, 100, 'agreementsList.gracePeriod', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'buySellType'          , 100, 50 , 'agreementsList.buySellType');
    xpColCreate(translate, subArray, hiddenState,'meansOfPaymentT'      , 100, 50 , 'agreementsList.meansOfPayment');
    xpColCreate(translate, subArray, hiddenState,'openTime'             , 150, 80 , 'agreementsList.openTime');
    xpColCreate(translate, subArray, hiddenState,'closeTime'            , 150, 80 , 'agreementsList.closeTime');
    xpColCreate(translate, subArray, hiddenState,'comment'              , 300, 100, 'agreementsList.comment', {tooltipField: 'comment'});
    xpColCreate(translate, subArray, hiddenState,'guiStatusT'           , 300, 100, 'agreementsList.status', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: agreementStates.map(item => 'agreementsList.statusTypes.' + item)
        },
        filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: [], suppressSorting: true}
    });
    xpColHeadCreate(translate, outArray, subArray,  'agreementsList.agreementInfo');

    xpColCreate(translate, subArray, hiddenState,'suspendedByUserId'    , 300, 100, 'agreementsList.suspendedByUserId', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState,'suspendedDateTime'    , 300, 100, 'agreementsList.suspendedDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState,'suspendedComment'     , 300, 100, 'agreementsList.suspendedComment', {tooltipField: 'suspendedComment'});
    xpColHeadCreate(translate, outArray, subArray,  'agreementsList.suspendInfo');

    xpColCreate(translate, subArray, hiddenState,'resumedByUserId'      , 300, 100, 'agreementsList.resumedByUserId', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState,'resumedDateTime'      , 300, 100, 'agreementsList.resumedDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState,'resumedComment'       , 300, 100, 'agreementsList.resumedComment', {tooltipField: 'resumedComment'});
    xpColHeadCreate(translate, outArray, subArray,  'agreementsList.resumeInfo');

    xpColCreate(translate, subArray, hiddenState,'clientId'             , 100, 50 , 'agreementsList.clientId');
    xpColCreate(translate, subArray, hiddenState,'clientName'           , 300, 100, 'agreementsList.clientName');
    xpColCreate(translate, subArray, hiddenState,'legalEntityId'        , 100, 50 , 'agreementsList.legalEntityId');
    xpColCreate(translate, subArray, hiddenState,'legalEntityName'      , 300, 100, 'agreementsList.legalEntityName');
    xpColCreate(translate, subArray, hiddenState,'legalEntityUnitId'    , 100, 50 , 'agreementsList.legalEntityUnitId');
    xpColCreate(translate, subArray, hiddenState,'legalEntityUnitName'  , 300, 100, 'agreementsList.legalEntityUnitName');
    xpColHeadCreate(translate, outArray, subArray, 'agreementsList.partyInfo');

    xpColCreate(translate, subArray, hiddenState,'buyAmount'           , 100, 50 , 'listRatesList.buyAmount'       , {cellRenderer: 'xpGridNumberRenderer'  , cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'sellAmount'          , 100, 50 , 'listRatesList.sellAmount'      , {cellRenderer: 'xpGridNumberRenderer'  , cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState,'timeExpire'          , 200, 100, 'listRatesList.timeExpire'      , {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState,'nextDispatchTime'    , 200, 100, 'listRatesList.nextDispatchTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColHeadCreate(translate, outArray, subArray, 'agreementsList.activeListRate');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}

export function userCanSuspendAgreement(agreement) {
    return instanceRightAllowed(agreement, refixDataRights.ListRateAgreement.SuspendListRateAgreements);
}
export function userCanResumeAgreement(agreement) {
    return instanceRightAllowed(agreement, refixDataRights.ListRateAgreement.ResumeListRateAgreements);
}

export function userCanSuspendLeuAgreements(leu) {
    return instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.SuspendListRateAgreements);
}
export function userCanResumeLeuAgreements(leu) {
    return instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.ResumeListRateAgreements);
}

export function getTenorsToMap(ormTenors, tenorsInAgreement) {
    let retObject = {};

    ormTenors.forEach(tenor => {
        retObject[tenor.name] = false;
    })
    if (!tenorsInAgreement) return retObject

    Object.keys(tenorsInAgreement).forEach(key => {
        retObject[key] = tenorsInAgreement[key] === true;
    });

    return retObject;
}

export function getAuthorisedAgreementTypes(leu) {
    let authorizedPrototypes = [];
    if (instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.CreateListRateAgreement)) {
        authorizedPrototypes.push(refixDataRightsDomainMap.ListRateAgreement);
    }
    if (instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.CreateMarketOrderAgreement)) {
        authorizedPrototypes.push(refixDataRightsDomainMap.MarketOrderAgreement);
    }
    if (instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.CreateMarketOrderAllAgreement)) {
        authorizedPrototypes.push(refixDataRightsDomainMap.MarketOrderAllAgreement);
    }
    if (instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.CreateReferenceRateAgreement)) {
        authorizedPrototypes.push(refixDataRightsDomainMap.ReferenceRateAgreement);
    }
    if (instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.CreatePriceFeedAgreement)) {
        authorizedPrototypes.push(refixDataRightsDomainMap.PriceFeedAgreement);
    }
    if (instanceRightAllowed(leu, refixDataRights.LegalEntityUnit.CreateTradeReportAgreement)) {
        authorizedPrototypes.push(refixDataRightsDomainMap.TradeReportAgreement);
    }
    return authorizedPrototypes;
}

export function useAgreementSelectorFunction() {
    const reduxStore = useStore();
    const agreementSelector = getOrmItemSelectorGetter('Agreement')();

    return (selectId) => {
        const reduxState = reduxStore.getState();
        const listRateAgreementModel = agreementSelector(reduxState, {selectId: selectId});

        if (!listRateAgreementModel) {
            return undefined
        }
        return listRateAgreementModel.ref;
    }
}



export function noListRateProcessRunning(processStatus) {
    return !processStatus || !Object.keys(processStatus).length || !Object.values(processStatus).filter(item => item.active).length;
}

export function createListRateAgreementMenuFunction(agreements, listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, translateRef, dispatch) {
    const affectedAgreements = getAgreementsAffectedByAction(agreements, action);
    const affectedListRates= getListRatesAffectedByAction(listRates,  action);
    return {
        name: translateRef.current(trKeyBase + "." + action + ".menuItem", {count: affectedAgreements.length, name: parentName}),
        action: () => setTimeout(() => launchListRateAgreementAction(affectedAgreements, affectedListRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, dispatch), 50),
        context: {dispatch: dispatch},
        disabled: affectedAgreements.length === 0,
    }
}


export function launchListRateAgreementAction(agreements, listRates, action, filter, trKeyBase, parentName, commentRequiredMap, listRateProcessStatusRef, dispatch, highestAlert) {

    const listRatesWithProcessDown      = getActiveListRatesWithOwnerProcessDown(listRates, listRateProcessStatusRef.current);
    const listRatesWithProcessDownCount = listRatesWithProcessDown?.length;

    const noListRateProcessActive        = noListRateProcessRunning(listRateProcessStatusRef.current);

    function launchModal() {
        dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG, {modalKey: trKeyBase + "." + action, modalParams: {count: agreements.length, openListRates: listRates.length, name: parentName}, commentIsRequired: commentRequiredMap[action], bodyClassName: highestAlert ? "modalBodyHighestAlert" : ""}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    dispatch(putAgreementActionToApi(action, filter, agreements, listRates, result.comment));
                }
            })
    }

    if (noListRateProcessActive) {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "listRates.confirmNoListRateProcessRunningModal", modalParams: {}}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    launchModal();
                }
            })
        return;
    }

    if (listRatesWithProcessDown?.length !== 0) {
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: "listRates.confirmUnmanagedListRatesModal", modalParams: {count: listRatesWithProcessDownCount}}))
            .then((result) => {
                if (result?.status === MODAL_TYPE_CONFIRM) {
                    launchModal();
                }
            })
        return;
    }
    launchModal();
}


export function getAgreementsAffectedByAction(agreements, action) {
    if (!agreements || !agreements.length) return [];

    let checkStatus;
    if (action === 'suspend') {
        checkStatus = 'Active';
    }
    else if (action === 'resume') {
        checkStatus = 'Suspended';
    }
    else {
        return [];
    }
    return agreements.filter(item => {
        return entityIsActive(item) && item.status === checkStatus && item.type === 'ListRateAgreement'
    });
}
function getCount(items, status) {
    if (!items || !items.length) return 0;
    return items.filter(item => item.status === status).length;
}

export function agreementNotExemptFromGlobalActions(agreement) {
    return agreement.exemptFromGlobalSuspend !== true;
}
