import React from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";


export function LegalEntityUnitBaseStatic (props) {
    const formConfig         = useSelector(state => state.appEnvState.legalEntityUnitFormConfig);
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');

    if (formDataPriceFeedOnly) return null;
    return (
        <div className={props.className}>
            {(!formConfig.fieldsConfig.appId.hide) &&
            <XpFormTextInput
                inLineLayout
                inLineDivider={80}
                field         = "appId"
                checkNoSpaces = {true}
                isRequired    = {true}
                // exactLength   = {34}
                errors={{
                    exactLength: (val, fields) => {
                        if (!val ||!val.length) return false;
                        if (val.length === 32) return false;
                        if (val.length === 34) return false;
                        return true;
                    }
                }}
            />}
        </div>
    );
}
LegalEntityUnitBaseStatic.propTypes = {
    className:        PropTypes.string,
};
