import {
    completedWizard,
    failedSubmit,
    resetWizard,
    } from 'gui-common/wizard/wizardReducer';
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {xpFormLoadForm} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {getWizardDefaultParametersSelector} from "gui-common/wizard/wizardSelectors";
import {globalWizardConfig} from "gui-common/wizard/wizardConstants";

export function wizardCreatedFromOkApiResponse(ormModel, createdObject, actionType) {
    return (dispatch, getState) => {
        if (!ormModel || !createdObject) return;
        if(actionType !== "createEntity") return;

        const wizardState = getState().wizardState;
        for (let wizard of wizardState) {
            if (!wizard.submitted || !wizard.createObject || wizard.completed|| (wizard.ormModel !== ormModel)) continue;

            const wizardFormState = getState().xpFormState[wizard.wizardFormName];
            if (wizardFormState?.name !== createdObject.name) continue;

            dispatch(completedWizard({wizardName: wizard.wizardName}));

            dispatch(pushModalWindow(INFORMATION_DIALOG, {modalKey: wizard.wizardName + '.wizardFinishedModal'}))
            .then(({status}) => {
                if (status === MODAL_TYPE_CONFIRM) {
                    if (globalWizardConfig[wizard.wizardName].onSubmitSuccessThunk) dispatch(globalWizardConfig[wizard.wizardName].onSubmitSuccessThunk(createdObject));

                    const defaultParams = getWizardDefaultParametersSelector()(getState(), {wizardName: wizard.wizardName})
                    dispatch(resetWizard({wizardName: wizard.wizardName}));
                    dispatch(xpFormLoadForm(wizard.wizardFormName, defaultParams, XP_FORM_EDIT));
                }
            });
        }
    }
}

export function wizardFromFailedApiResponse(ormModel, actionType) {
    return (dispatch, getState) => {
        if (!ormModel) return;
        if(actionType !== "createEntity") return;
        const wizardState = getState().wizardState;
        const myWizard = wizardState.find(x => (x.submitted && x.createObject));
        if (!myWizard) return; // No wizard open.
        dispatch(failedSubmit({wizardName: myWizard.wizardName}));
    }
}
