import React, {useCallback, useMemo} from 'react';
import {useDispatch} from "react-redux";
import {useXpTranslateRef} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {useRequestingStateOnModelRef} from "gui-common/requestEntityState/requestEntityStateSelectors";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {globalApiHandle} from "gui-common/api/apiConstants";
import {fileDownload} from "gui-common/api/apiCommon";

const auditFilesHiddenState = {
    id           : true,
    fileName     : false,
    description  : false,
    fileSize     : false,
};

export default function AuditFilesList (props) {

    const dispatch        = useDispatch();

    const translateRef     = useXpTranslateRef();
    const reportFilesRequestStateRef = useRequestingStateOnModelRef('ReportFile');

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((data)   => {}, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                return [{
                    name: translateRef.current("auditFilesList.gridContextMenuItems.download"),
                    action: () =>
                        dispatch(fileDownload(
                            globalApiHandle.baseUrls.auditFiles + '/' + params.node.data.id + '?',
                            params.node.data.fileName, "Downloading " + params.node.data.fileName)
                        ),
                    context: {},
                    disabled: false,
                }]
           }, [translateRef?.current]),
        rowClassRules: {
        },
    };

    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : props.instanceId,
                overlayNoRowsTemplate   : translateRef.current('auditFilesList.noItemsToShow'),
                fillAvailableSpace      : false,
                groupDefaultExpanded    : 0,
                // getRowDataSelector      : getReportFilesListDataSelector,
                // rowDataSelectorProps    : props.rowDataSelectorProps,
                // treeData                : false,
                // masterDetail            : false,
            };
        },
        [translateRef.current]
    );
    const colDefs = useMemo(
        () => {
            let subArray = [];
            xpColCreate(translateRef.current, subArray, auditFilesHiddenState,'id'               , 100, 50 , 'auditFilesList.headers.id');
            xpColCreate(translateRef.current, subArray, auditFilesHiddenState,'fileName'         , 120, 50 , 'auditFilesList.headers.fileName');
            xpColCreate(translateRef.current, subArray, auditFilesHiddenState,'description'      , 120, 50 , 'auditFilesList.headers.description');
            xpColCreate(translateRef.current, subArray, auditFilesHiddenState,'fileSize'         , 200, 50 , 'auditFilesList.headers.size', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
            return subArray;
        },
        [translateRef.current]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={props.gridId ? props.gridId : "reportFilesGrid-" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                rowData={props.auditFiles}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
                suppressRowClickSelection={props.suppressRowClickSelection} // This must be a direct propagation of prop since the memoized grid options will no be updated by this prop.
            >
            </XpGrid>
        </div>
    );
}
