import {createSelector} from "reselect";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {entityIsActive} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";

export const bankHolidaysSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props?.inputData ? props.inputData : undefined,
    (session, inputData) => {
        if (inputData) return inputData;
        return session.BankHoliday.all().filter(entityIsActive).toRefArray();
    }
);

export const getBankHolidaysCalendarEventsSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    bankHolidaysSelector,
    (session, bankHolidays) => {
        if (!bankHolidays || !bankHolidays.length) return [];
        return bankHolidays.map(item => { return {
            ...item,
            title: item.currency.currency + ': ' +item.description,
            start: new Date(item.startDateTime),
            end  : new Date(item.endDateTime),
            allDay: true,
        }});
    }
);

export const getSpecificBankHolidaySelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    bankHolidaysSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, bankHolidays, itemId) => {
        if (!itemId) return undefined;
        if (!bankHolidays || !bankHolidays.length) return undefined;
        return bankHolidays.find(item => item.id === itemId);
    }
);

export const bankHolidaysTodaySelector = ormCreateSelector(
    orm,
    dbStateSelector,
    bankHolidaysSelector,
    (state, props) => props ? props.selectDate : undefined,
    (session, bankHolidays, selectDate) => {
        if (!selectDate) return undefined;
        if (!bankHolidays || !bankHolidays.length) return undefined;
        return bankHolidays.filter(item => item.date === selectDate);
    }
);
export const noRunDateTodaySelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectDate : undefined,
    (session, selectDate) => {
        if (!selectDate) return false;
        let noRunDates = session.NoRunDate.all().orderBy('date', 'asc').toModelArray().map(item => item.ref);
        if (!noRunDates || !noRunDates.length) return false;
        return (noRunDates.find(item => item.date === selectDate) !== undefined);
    }
);

export const newBankHolidayFormTemplateSelector = createSelector(
    [],
    () => {
        return {
            // currencyId  : "",
            date        : undefined,
            description : "",
            comment     : "",
        }
    }
);
