import React, {useEffect} from 'react'
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {AgreementOpenTimesStatic} from "features/agreement/formComponents/AgreementOpenTimesStatic";
import XpFormFieldset from "gui-common/xpForm/core/XpFormFieldset";
import {MarketOrderTenors} from "features/agreement/formComponents/MarketOrderTenors";
import {useDispatch} from "react-redux";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";


function AgreementInfo(props) {
    const formContext = useXpFormContext();
    const tenors = useXpFormField('tenors');
    const dispatch = useDispatch();

    useEffect(
        () => {
            if (!tenors) return;
            let retStr = "";
            Object.keys(tenors).forEach(tenor => {
                if (tenor[0] === '$') return;
                if (tenors[tenor] !== true) return;
                retStr += ', ' + tenor;
            })
            if (retStr !== '') retStr = retStr.slice(2);
            dispatch(xpFormChangeField(formContext.formModel + '.tenorString', retStr));
        },
        [tenors],
    );

    return (
        <div>
            <h3 className='wizardFormCardHeader'><XpTranslated trKey='refixClientWizardForm.agreementsForm.agreementHeader' trParams={{currencyPair: props.agreement.currencyPairName}}/></h3>
            <div className='agreementDataColumn' style={{width: 'calc(60%-5px)', marginRight: '10px' }}>
                <MarketOrderTenors/>
            </div>
            <div className='agreementDataColumn' style={{width: 'calc(40%-5px)', paddingTop: '10px'}}>
                <AgreementOpenTimesStatic/>
            </div>
        </div>
    )
}

function RfWizardMarketOrderAgreementsForm(props) {
    const agreements = useXpFormField('marketOrderAgreements')

    return (
        <div className={"adminFormContainer"}>
            {agreements.map((agreement, index) => {
                return (
                    <div className="wizardFormCard wizardFormCardLarge" key={index}>
                        <XpFormFieldset
                            field={'marketOrderAgreements.' + index}
                            formTemplate="agreementForm"
                        >
                            <AgreementInfo agreement={agreement} index={index}/>
                        </XpFormFieldset>
                    </div>
                )
            })}
        </div>
    )
}
export default RfWizardMarketOrderAgreementsForm;


/*

class RfWizardMarketOrderAgreementsForm extends Component {
/!*
    constructor (props) {
        super(props);
    }
*!/
    agrForm(agreement, index){
        return (
            <WizardAgreementForm
                agreement={agreement}
                agreementType={'MarketOrderAgreement'}
                submitMethod={this.props.submitMethod}
                form={this.props.form}
                indexNo={index}
                key={'wizardMOAgreementForm_' + index}
            />
        );
    }
    render() {
        let sortedAgreements = [...this.props.form.marketOrderAgreements];

        return (
            <div className="formCard">
                {sortedAgreements.map((x, index) => this.agrForm(x, index))}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const form = state.myForms.refixClientWizardForm;
    return {
        translate:              selectTranslateFunction(state),
        currentLanguage:        selectActiveLanguage(state),
        form:                   form,
        appEnvState:            state.appEnvState,
        currencyPairs:          activeCurrencyPairsSelector(state),
        listRateTenors:         activeTenorsSelector(state, {agreementType: 'ListRateAgreement'}),
        marketOrderTenors:      activeTenorsSelector(state, {agreementType: 'MarketOrderAgreement'}),
        activePeriods:          activePeriodsSelector(state),
        activeDispatchPeriods:  activeDispatchPeriodsSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetForm:              (model)         => dispatch(actions.reset(model)),
        submitForm:             (model)         => dispatch(actions.submit(model)),
        setParametersFormValue: (model, string) => dispatch(actions.change(model, string)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RfWizardMarketOrderAgreementsForm);
*/
