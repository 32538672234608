import {AgreementsListInDashBoard} from "features/agreement/AgreementsList2"
import {CurrencyExposureList} from "features/currencyExposure/CurrencyExposureList";
import {DispatchTradeList} from "features/dispatchTrade/DispatchTradeList2";
import {DashboardBusinessUnitList} from "features/legalEntityUnit/DashboardBusinessUnitList2";
import {OverallExposure} from "features/overallActions/OverallExposure";
import {ListRateOrdersList} from "features/listRateOrder/ListRateOrdersList";
import {MarketOrdersList} from "features/marketOrder/MarketOrdersList2";
import {ListRateList} from "features/listRate/ListRateList";
import {ListRateOrderAllocationsList} from "features/listRateOrderAllocation/ListRateOrderAllocationsList";
import {FxTradeReportDeliveriesList} from "features/fxTradeReport/FxTradeReportDeliveriesList";
import {fxUsedSelector, getServiceActiveSelector} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";
import FxPriceList from "gui-common/fxPrice/FxPriceList";
import FixLogList from "gui-common/fixLog/FixLogList";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {commonSystemRights} from "gui-common/userRights/userRightsConstants";


export const refixDashboardWidgets = {
    'FxPriceList': {
        component           : FxPriceList,
        authorizedFunction  : null,
        isActiveSelector    : fxUsedSelector,
        langPath            : 'fxPrices.dashboardComponent.',
        icon                : 'euro_symbol',
        keyName             : 'fxPrices',
        defaultSize         : {w: 3, h: 10}
    },
    'FixLogList':  {
        component           : FixLogList,
        authorizedFunction  : (user) => userHasSystemRight(user, commonSystemRights.FixLog.Read),
        isActiveSelector    : fxUsedSelector,
        langPath            : 'fixLog.fixLogList.dashboardComponent.',
        icon                : 'question_answer',
        keyName             : 'fixLog',
        defaultSize         : {w: 6, h: 10}
    },
    'MarketOrdersList': {
        component           : MarketOrdersList,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.MarketOrder),
        langPath            : 'marketOrdersList.dashboardComponent.',
        icon                : 'swap_horizontal_circle',
        keyName             : 'marketOrders',
        defaultSize         : {w: 18, h: 15}
    },
    'ClientsStatusList': {
        component           : DashboardBusinessUnitList,
        authorizedFunction  : null,
        langPath            : 'legalEntityUnitsList.dashboardComponent.',
        icon                : 'list',
        keyName             : 'businessUnits',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRateAgreementsList': {
        component           : AgreementsListInDashBoard,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.ListRates),
        langPath            : 'agreementsList.dashboardComponent.',
        icon                : 'assignment',
        keyName             : 'listRateAgreements',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRatesList': {
        component           : ListRateList,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.ListRates),
        langPath            : 'listRatesList.dashboardComponent.',
        icon                : 'trending_flat',
        keyName             : 'listRates',
        defaultSize         : {w: 18, h: 15}
    },
    'DispatchTradesList': {
        component           : DispatchTradeList,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.ListRates),
        langPath            : 'dispatchTradesList.dashboardComponent.',
        icon                : 'save_alt', // swap_horizontal_circle
        keyName             : 'dispatchTrades',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRateOrdersList': {
        component           : ListRateOrdersList,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.ListRates),
        langPath            : 'listRateOrdersList.dashboardComponent.',
        icon                : 'exit_to_app',
        keyName             : 'listRateOrders',
        defaultSize         : {w: 18, h: 15}
    },
    'ListRateOrderAllocationsList': {
        component           : ListRateOrderAllocationsList,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.ListRates),
        langPath            : 'listRateOrderAllocationsList.dashboardComponent.',
        icon                : ListRateOrderAllocationsList.detailIcon,
        keyName             : 'listRateOrderAllocations',
        defaultSize         : {w: 18, h: 15}
    },
    'CurrencyExposureList': {
        component           : CurrencyExposureList,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.ListRates),
        langPath            : 'currencyExposureList.dashboardComponent.',
        icon                : 'speed',
        keyName             : 'currencyExposure',
        defaultSize         : {w: 12, h: 10}
    },
    'OverallExposure': {
        component           : OverallExposure,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.ListRates),
        langPath            : 'overallExposure.dashboardComponent.',
        icon                : 'power_settings_new',
        keyName             : 'overallExposure',
        defaultSize         : {w: 6, h: 5}
    },
    'FxTradeReportDeliveries': {
        component           : FxTradeReportDeliveriesList,
        authorizedFunction  : null,
        isActiveSelector    : getServiceActiveSelector(refixServices.TradeReport),
        langPath            : 'fxTradeReportDeliveriesList.dashboardComponent.',
        icon                : 'local_shipping',
        keyName             : 'fxTradeReportDeliveries',
        defaultSize         : {w: 18, h: 15}
    }
};

