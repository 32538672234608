import React, {useCallback, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {prepareListRateOrdersColumns} from "features/listRateOrder/listRateOrderFunctions";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {getListRateOrdersListDataSelector} from "features/listRateOrder/listRateOrderSelectors";
import {ListRateOrderAllocationsList} from "features/listRateOrderAllocation/ListRateOrderAllocationsList";
import {getDispatchTradesListDataSelector} from "features/dispatchTrade/dispatchTradeSelectors";

export const listRateOrdersHiddenState = {
    id              : false,
    listRateId      : false,
    legalEntityUnitName: false,
    appId           : false,
    sideT           : false,
    amount          : false,
    amountCurrencyT : false,
    numberOfAllocations: false,
    currencyPairStr : false,
    tenor           : false,
    rate            : false,
    counterAmount   : true,
    clientReference : false,
    meansOfPayment  : true,
    instructedTime  : false,
    settlementDate  : false,
    guiStatusT      : false,
    statusMessage   : true,

    rateInterBank   : true,
    forwardPoints   : true,
    forwardPointsInterBank: true,
    spotRate        : true,
    spotRateInterBank     : true,
};

ListRateOrdersList.detailIcon = 'exit_to_app';
ListRateOrdersList.detailIconToolTipKey = 'listRateOrdersList.detailIconToolTipKey';
export function ListRateOrdersList ({itemSelectedCallback, instanceId, setHeightCallback, fillAvailableSpace, gridId, rowDataSelectorProps, getRowDataSelector}) {

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!itemSelectedCallback) return;
                itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [itemSelectedCallback]),
        rowClassRules   : {
            'xp-grid-rejected-order-row' : function(params) { return (params.data.guiStatus === "Rejected")},
            'xp-grid-inactive-row'       : function(params) { return (params.data.guiStatus === 'Booked')},
        },
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('listRateOrdersList.noOrdersToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                // rowDataSelector         : selectListRateOrdersListData,
                getRowDataSelector      : getRowDataSelector ? getRowDataSelector : getListRateOrdersListDataSelector,
                // getRowDataSelector      : getListRateOrdersListDataSelector,
                rowDataSelectorProps    : rowDataSelectorProps,
                treeData                : false,
                masterDetail            : true,
                ormModel                : 'ListRateOrder',
                xpDetailRendererProps   : {
                    availableComponents: [
                        {
                            detailType: 'listRateOrderAllocations',
                            componentToRender: ListRateOrderAllocationsList,
                            propsToComponent: {
                                getRowDataFunction: item => {
                                    return item.listRateOrderAllocations
                                },
                            },
                        }
                    ],
                    parentInstanceId: instanceId,
                },
            };
        },
        [translate, rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareListRateOrdersColumns(translate, listRateOrdersHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={gridId ? gridId : "listRateOrdersGrid-" + instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
