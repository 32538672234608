import React from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";


export function LegalEntityUnitRestTimeZone (props) {
    const formConfig         = useSelector(state => state.appEnvState.legalEntityUnitFormConfig);
    const availableTimeZones = useSelector(state => state.appEnvState.availableTimeZones);
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');

    if (formDataPriceFeedOnly) return null;
    return (
        <div className={props.className}>
            {((availableTimeZones.length > 1) && !formConfig.fieldsConfig.restTimeZone.hide) &&
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field="restTimeZone"
                selectList    = {availableTimeZones}
                itemTextField = "id"
            />}
        </div>
    );
}
LegalEntityUnitRestTimeZone.propTypes = {
    className:        PropTypes.string,
};
