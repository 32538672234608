import {genericFetch} from "gui-common/api/apiCommon";
import {warnAndResolve} from "gui-common/functions/functions";
import {ormEntityUpdated, ormEntityUpdateFailed, ormEntityUpdateStarted} from "gui-common/orm/ormReducer";
import {apiTransformIncoming} from "gui-common/api/apiFunctions";
import {globalApiHandle} from "gui-common/api/apiConstants";


export function userTopicActionToApi(action, userId, topic) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let method = "";
            switch (action) {
                case "delete":
                    method = 'DELETE';
                    break;
                case "assign":
                    method = 'POST';
                    break;
                default:
                    warnAndResolve("Request of userTopic action with invalid action: " + action, resolve);
                    return;
            }

            if (!action) {
                warnAndResolve("Request user topic called with no action", resolve);
                return;
            }
            if (!userId) {
                warnAndResolve("Request " + action + " called with no userId", resolve);
                return;
            }
            if (!topic) {
                warnAndResolve("Request " + action + " called with no topic", resolve);
                return;
            }
            let customText = "Request " + action + " with topic " + topic + " for userId " + userId;
            let requestUrl = globalApiHandle.baseUrls.User + "/" + userId + "/topics/" + action + "?topic=" + topic + "&";

            dispatch(ormEntityUpdateStarted('User', {id: userId}));
            dispatch(genericFetch(
                requestUrl,
                customText,
                requestUserTopicActionResponseHandler,
                requestUserTopicActionFailHandler,
                requestUserTopicActionFailHandler,
                method
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestUserTopicActionResponseHandler(resp) {
    return (dispatch, getState) => {
        let transformedResponse = apiTransformIncoming(resp, 'User', getState);
        dispatch(ormEntityUpdated('User', transformedResponse));
    }
}
function requestUserTopicActionFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntityUpdateFailed('User'));
    }
}
