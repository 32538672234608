import PropTypes from "prop-types";
import React from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpSplitPane from "gui-common/components/XpSplitPane";
import ReactJson from "react-json-view";

function AuditRecordRawDataView (props) {
    if (!props.auditEntry?.rawAuditData) {
        return null;
    }
    return (
        <div>
            <h3 style={{marginBottom: '5px', borderBottom: 'var(--xpool-form-field-border)'}}><XpTranslated trKey={'auditView.historic.jsonView'}/></h3>
            <XpSplitPane defaultWidthPercent={50}>
                <div style={{fontSize: 'var(--xpool-label-font-size)'}}>
                    <ReactJson collapsed displayObjectSize sortKeys name={'before'} iconStyle={'circle'} src={props.auditEntry.rawAuditData.before} />
                </div>
                <div style={{fontSize: 'var(--xpool-label-font-size)'}}>
                    <ReactJson collapsed displayObjectSize sortKeys name={'after'} iconStyle={'circle'} src={props.auditEntry.rawAuditData.after} />
                </div>
            </XpSplitPane>
            {props.auditEntry.rawAuditData.contextBefore && props.auditEntry.rawAuditData.contextAfter &&
            <div>
                <h3 style={{marginBottom: '5px', borderBottom: 'var(--xpool-form-field-border)'}}><XpTranslated trKey={'auditView.historic.jsonViewContext'}/></h3>
                <XpSplitPane defaultWidthPercent={50}>
                    <div style={{fontSize: 'var(--xpool-label-font-size)'}}>
                        <ReactJson collapsed displayObjectSize sortKeys name={'contextBefore'} iconStyle={'circle'} src={props.auditEntry.rawAuditData.contextBefore} />
                    </div>
                    <div style={{fontSize: 'var(--xpool-label-font-size)'}}>
                        <ReactJson collapsed displayObjectSize sortKeys name={'contextAfter'} iconStyle={'circle'} src={props.auditEntry.rawAuditData.contextAfter} />
                    </div>
                </XpSplitPane>
            </div>}
        </div>
    )
}
AuditRecordRawDataView.propTypes = {
    auditEntry: PropTypes.object.isRequired,
};
export default AuditRecordRawDataView



