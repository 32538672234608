import {
    ormEntitiesGetFailed,
    ormEntitiesGetStarted,
    ormEntitiesReceived,
    ormEntityUpdated, ormEntityUpdateFailed, ormEntityUpdateStarted, ormEntitiesClearModel
} from "gui-common/orm/ormReducer";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";
import {auditEntriesListInstanceId} from "gui-common/audit/AuditViewPage"
import {genericFetch} from "gui-common/api/apiCommon";
import {warnAndResolve} from "gui-common/functions/functions";
import {getAppEnvPropertySelector, selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {ormEntityClearLoadAll, ormEntityClearUpdate} from "gui-common/orm/ormLoadingReducer";
import {globalApiHandle} from "gui-common/api/apiConstants";

import {auditEntryTransformer2} from "gui-common/audit/auditFunctions";



/* -----------------------------------------------------------------------------------------------------------------
* Functions to load a audit entries from including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/

// If the loadDataCallback function is not defined, the data should be loaded into the ORM (base case for audit view)
export function requestAuditEntriesFromApi(filter, loadDataCallback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!filter) {
                warnAndResolve("Request audit entries called without filter", resolve);
                return;
            }

            let requestUrl = globalApiHandle.baseUrls.audit + "/entries?";
            const filterObject = {
                fromDate: filter.fromDate,
                toDate: filter.toDate,
                userId: filter.userId ? filter.userId : null,
                actionType: filter.action ? filter.action : null,
                searchFields: filter.runConfiguration?.auditSearchFields?.filter(item => item.isActive && !item.isDeleted)
                    .map(item => ({
                        fieldType: item.fieldType,
                        fieldName: item.fieldName ? item.fieldName : null,
                        fieldValue: item.fieldValue ? item.fieldValue : null,
                        exactMatch: item.exactMatch
                    })),
                andOrSearch: filter.andOrSearch,
            }

            let customText = "Request audit entries";

            dispatch(ormEntitiesGetStarted('AuditEntry'));
            dispatch(genericFetch(
                requestUrl,
                customText,
                requestAuditEntriesResponseHandler(loadDataCallback),
                requestAuditEntriesFailHandler,
                requestAuditEntriesFailHandler,
                'PUT',
                filterObject,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
export function requestAuditEntriesOnAffectedIdFromApi(affectedId, loadDataCallback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!affectedId) {
                warnAndResolve("Request audit entries on affectedId called without affectedId", resolve);
                return;
            }

            let requestUrl = globalApiHandle.baseUrls.audit + "/entries/affected/" + affectedId + "?";

            let customText = "Request audit entries on affectedId " + affectedId;

            dispatch(ormEntitiesGetStarted('AuditEntry'));
            dispatch(genericFetch(
                requestUrl,
                customText,
                requestAuditEntriesResponseHandler(loadDataCallback),
                requestAuditEntriesFailHandler,
                requestAuditEntriesFailHandler,
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

const requestAuditEntriesResponseHandler = (loadDataCallback) => (resp) => {
    return (dispatch, getState) => {
        let transformedResponse = resp.map(auditEntryTransformer2);
        if (loadDataCallback) {
            loadDataCallback(transformedResponse);
            dispatch(ormEntityClearLoadAll('AuditEntry')); // Must clear specifically since automatic functionality when using the ORM reducer is not activated.
            return;
        }
        dispatch(ormEntitiesClearModel('AuditEntry'));
        dispatch(ormEntitiesReceived('AuditEntry', transformedResponse));
        dispatch(xpGridSetSelected(auditEntriesListInstanceId, undefined));
    }
}

function requestAuditEntriesFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntitiesGetFailed('AuditEntry'));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to load a specific audit entry including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/

// If the loadDataCallback function is not defined, the data should be loaded into the ORM (base case for audit view)
export function loadAuditEntryFromApi(entry, loadDataCallback) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!entry?.id) {
                warnAndResolve("Request audit entry called with no entryId", resolve);
                return;
            }
            let customText = "Request audit entry id " + entry.id;

            const currentApiVersion = getAppEnvPropertySelector()(getState(), 'currentApiVersion');
            const isCurrent = (entry.apiVersion === currentApiVersion)
            const historicAuditOnly = selectAppEnvProperty(getState(), 'historicAuditOnly');
            const useHistoric = !isCurrent || historicAuditOnly

            let requestUrl = globalApiHandle.baseUrls.audit + "/entries/" + entry.id + "?";

            dispatch(ormEntityUpdateStarted('AuditEntry', {id: entry.id}));
            dispatch(genericFetch(
                requestUrl,
                customText,
                requestAuditEntryResponseHandler(entry.id, loadDataCallback, useHistoric),
                requestAuditEntryFailHandler(entry.id),
                requestAuditEntryFailHandler(entry.id),
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function requestAuditEntryResponseHandler(entryId, loadDataCallback, useHistoric) {
    return (resp) => {
        return (dispatch, getState) => {
            if (!resp) {
                console.log("API response for audit entry id " + entryId + " was empty");
                dispatch(ormEntityUpdateFailed('AuditEntry', {id: entryId}));
            }
            let transformedObject = {};
            transformedObject = auditEntryTransformer2(resp);

            if (loadDataCallback) {
                loadDataCallback(transformedObject);
                dispatch(ormEntityClearUpdate('AuditEntry', {id: entryId})); // Must clear specifically since automatic functionality when using the ORM reducer is not activated.
                return;
            }
            dispatch(ormEntityUpdated('AuditEntry', transformedObject));
        }
    }
}

function requestAuditEntryFailHandler(entryId) {
    return (resp) => {
        return (dispatch, getState) => {
            console.log("Failed to get audit entry ", resp);
            dispatch(ormEntityUpdateFailed('AuditEntry', {id: entryId}));
        }
    }
}

export function loadAuditSearchTypesFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let customText = "Request audit search fields";
            let requestUrl = globalApiHandle.baseUrls.audit + "/search-types?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function loadAuditSearchValuesFromApi(fieldType, fieldName) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!fieldType || !fieldName) {
                console.warn("Empty fieldType and/or fieldName in loadAuditSearchValuesFromApi", fieldType, fieldName)
                reject();
                return;
            }
            let customText = "Request audit search values";
            let requestUrl = globalApiHandle.baseUrls.audit + "/search-types/values?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                'PUT',
                {
                    type: fieldType,
                    name:fieldName
                }
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
