import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React, {useMemo} from "react";

function RenderDomainRights(props) {
    const [sortedDomainNames, sortedRightsNames] = useMemo(
        () => {
            if (!props.domainRights ||!Object.keys(props.domainRights).length) {
                return;
            }
            const rightsMap = {};
            for (const domain in props.domainRights) {
                if (!Object.keys(domain).length) {
                    continue;
                }
                for (const right in props.domainRights[domain]) {
                    rightsMap[right] = true;
                }
            }
            return [Object.keys(props.domainRights).sort(), Object.keys(rightsMap).sort()];
        },
        [props.domainRights]
    );

    if (!Object.keys(props.domainRights).length) return (
        <div><p className="valueText"><XpTranslated trKey='adminUserRights.noDomainRightsAvailable'/></p></div>
    );


    if (!props.domainRights) {
        return null;
    }

    return (
        <div style={{display: "inline-block", verticalAlign: "top"}}>
                {sortedDomainNames.map((domainName, index) => (

                    <div className="instanceUserRightsBlock" key={index}>
                        <h5><XpTranslated trKey={'general.prototypeNamesLarge.'+ domainName}/></h5>
                        {sortedRightsNames.map((rightName, index) => {
                                const domainRight = props.domainRights[domainName][rightName];
                                if (!domainRight) {
                                    return null;
                                }
                                return (
                            <div key = {index}>
                                <div className="checkboxTdColumn" style={{display: "inline-block"}}>
                                    <input
                                        type="checkbox"
                                        checked={domainRight === true}
                                        disabled
                                    />
                                </div>
                                <label className="labelCheckbox">{rightName}</label>
                            </div>)
                        }
                        )}
                    </div>
                    )
                )}
        </div>
    )
}
export default RenderDomainRights
