import {globalApiHandle} from "gui-common/api/apiConstants";
import {genericFetch} from "gui-common/api/apiCommon";

export function postTradeReportEventToApi(event) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Post fx trade report event ";
            let requestUrl = globalApiHandle.baseUrls.FxTradeReportEvent + '?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'POST',
                event,
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}

function fxTradeReportEventOutgoingTransformer(item) {
    return {
        id: item.id,
        externalId: item.externalId,
        eventDateTime: item.eventDateTime,
        eventType: item.eventType,
        fxOrderReport: fxOrderReportOutgoingTransformer(item.fxOrderReport),
        relatedFxOrderReport: item.relatedFxOrderReport ? fxOrderReportOutgoingTransformer(item.relatedFxOrderReport) : null,
    };
}
function fxOrderReportOutgoingTransformer(item) {
    if (item.fxExecutionReports) {
        item.fxExecutionReports = item.fxExecutionReports.map(item => fxExecutionReportOutgoingTransformer(item));
    }
    return {
        id: item.id,
        fxExecutionReports: item.fxExecutionReports.map(item  => fxExecutionReportOutgoingTransformer(item)),
        marketId: item.marketId,
        reference: item.reference,
        account: item.account,
        amount: item.amount,
        productType: item.productType,
        currency: item.currency,
        symbol: item.symbol,
        // side: item.side,
        tenor: item.tenor,
        settlementDate:item.settlementDate,
        orderStatus: item.orderStatus,
        statusMessage: item.statusMessage,
        reportStatus: item.reportStatus,
    };
}

function fxExecutionReportOutgoingTransformer(item) {
    return {
        id: item.id,
        executedDateTime: item.executedDateTime,
        tradeDate: item.tradeDate,
        legRole: item.legRole,
        side: item.side,
        UTI: item.UTI,
        isin: item.isin,
        execId: item.execId,
        message: item.message,
        price: item.price,
        spotRate: item.spotRate,
        forwardPoints: item.forwardPoints,
        settlementDate: item.settlementDate,
    };
}

