import React, {useMemo, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import 'gui-common/xpGrid/agGridStyleOverride.css';
import 'gui-common/xpGrid/xpGrid.css';
import {
    xpGridCreateGrid, xpGridResetGridState, xpGridSetColDef, xpGridSetExpandedState, xpGridSetFitToPage,
    xpGridSetSelected, xpGridSetState,
    xpGridSetVisibleSelected
} from "gui-common/xpGrid/xpGridsReducer";
import {
    calculateNewGridHeight, columnDefsAreEqual, createSetOrmPropertyMenuItem, createViewExecutionRightsModel
} from "gui-common/xpGrid/xpGridFunctions";
import {selectMyXpGridState, xpGridRowDataSelector} from "gui-common/xpGrid/xpGridSelectors";
import XpResizable from "gui-common/components/XpResizable";
import {selectAppEnvProperty, useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {xpGridCellRenderers, xpGridCellRenderersComponentMap} from "gui-common/xpGrid/xpGridCellRendererConstants";
import {isEqual} from "lodash";
import {createOpenObjectAuditMenuItem} from "gui-common/audit/auditFunctions";
import {useSelectorRef, useStateRef} from "gui-common/functions/hooks";
import {userSelector} from "gui-common/orm/ormSelectors";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function XpGrid3 (props) {

    const gridState      = useSelector(state => selectMyXpGridState(state, {instanceId: props.instanceId}));
    const gridStateRef   = useRef(gridState); // Needed to transport updated gridState hook to callback scope.
    gridStateRef.current = gridState;

    const translate      = useXpTranslateFunction();
    const translateRef   = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridApiRef   = useRef(undefined);

    const devMode     = useSelector(state => state.appEnvState.devMode);
    const devInfoModeRef = useSelectorRef(selectAppEnvProperty, 'devInfoMode');

    const user     = useSelector(userSelector);

    const auditConfig = useAppEnvProperty( 'auditConfig');

    const xpGridConfig = useSelector(state => selectAppEnvProperty(state, 'xpGridConfig'));
    const maxRowsToDisplay = useMemo(()=>{
        if (props.maxRowsToDisplay) return props.maxRowsToDisplay;
        if (xpGridConfig.maxRowsOnOrmModel && xpGridConfig.maxRowsOnOrmModel[props.ormModel]) return xpGridConfig.maxRowsOnOrmModel[props.ormModel];
        return xpGridConfig.defaultMaxRows;
    }, [props.maxRowsToDisplay])

    const [selectedObjectIdInformedToParent, setSelectedObjectIdInformedToParent] = useState(undefined);
    const selectedObjectIdInformedToParentRef   = useRef(selectedObjectIdInformedToParent); // Needed to transport updated translate hook to callback scope.
    selectedObjectIdInformedToParentRef.current = selectedObjectIdInformedToParent;

    const [gridIsReady, setGridIsReady] = useState(false);
    const [gridInFocusRef, setGridInFocus] = useStateRef(false);

    const rowDataSelector = useMemo(()=>{
        if (props.getRowDataSelector) return props.getRowDataSelector();
        return props.rowDataSelector;
    }, [])
    const rowDataToUseSelector = useMemo(()=>{
        return xpGridRowDataSelector();
    }, [])
    const rowDataToUse = useSelector(
        state => rowDataToUseSelector(state, {rowData: props.rowData, rowDataSelector: rowDataSelector, rowDataSelectorProps: props.rowDataSelectorProps})
    );

    const [managedHeightState, setManagedHeightState] = useState(false);
    const managedHeightStateRef   = useRef(managedHeightState); // Needed to transport updated translate hook to callback scope.
    managedHeightStateRef.current = managedHeightState;

    const [heightState, setHeightState] = useState(calculateNewGridHeight(props.columnDefs, rowDataToUse, maxRowsToDisplay));
    const heightStateRef   = useRef(heightState); // Needed to transport updated translate hook to callback scope.
    heightStateRef.current = heightState;

    const [calculatedColDefs, setCalculatedColDefs] = useState(calculateColDefs())

    const dispatch     = useDispatch();

    function addCellRendererPropsToColDef(colDef) {
        if (!colDef.cellRenderer) return;
        if (!xpGridCellRenderers[colDef.cellRenderer]) return;
        const cellRendererProps = xpGridCellRenderers[colDef.cellRenderer].colDefProps;
        if (!cellRendererProps) return;

        for (const key in cellRendererProps) {
            const thisProp = cellRendererProps[key];

            // Set the colDef prop to the cell renderer props if not specifically defined in the colDef.
            if (typeof thisProp === 'object') colDef[key] = {...thisProp, ...colDef[key]};
            else if (colDef[key] === undefined) colDef[key] = thisProp;
        }
    }

    function addStateAndRendererPropsToColDefs(colDefs, gridStateColState, gridStateColDef) {
        for (const colDef of colDefs) {
            if (colDef.children) {
                addStateAndRendererPropsToColDefs(colDef.children, gridStateColState, gridStateColDef);
                continue;
            }
            addCellRendererPropsToColDef(colDef);

            if (gridStateColState) {
                const thisColumnGridStateColState = gridStateColState.find(item => item.colId === colDef.colId);
                if (thisColumnGridStateColState) {
                    colDef.hide = thisColumnGridStateColState.hide;
                }
            }

            if (gridStateColDef) {
                const thisColumnGridStateColDef = gridStateColDef[colDef.colId];
                if (thisColumnGridStateColDef) {
                    for (const key in thisColumnGridStateColDef) {
                        colDef[key] = thisColumnGridStateColDef[key];
                    }
                }
            }
        }
    }

    function calculateColDefs() {
        let returnDefs = [...props.columnDefs];
        addStateAndRendererPropsToColDefs(returnDefs, gridStateRef?.current ? gridStateRef.current.colState : undefined, gridStateRef?.current ? gridStateRef.current.colDef : undefined);
        return returnDefs;
    }
    useEffect(
        () => {
            const newCalculatedColDefs = calculateColDefs();
            if (columnDefsAreEqual(newCalculatedColDefs, calculatedColDefs)) {
                return;
            }
            setCalculatedColDefs(newCalculatedColDefs);
        },
        [props.columnDefs],
    );

/*
    const colDefsToUse = useMemo(
        () => {
            return calculateColDefs();
    }, [props.columnDefs])
*/

    const onKeyDown = useMemo(
        () => {
            return (event) => {
                if ([38, 40].includes(event.keyCode)) {
                    event.stopPropagation();
                    event.preventDefault()
                }
                if (!gridApiRef.current) return;
                if (!gridStateRef?.current) return;
                // if (!gridStateRef.current.gridIsSelected) return;
                if (!gridInFocusRef.current) return;
                if (!gridStateRef.current.selectedId) return;
                let selectedRow = gridApiRef.current.rowModel.rowsToDisplay.find(row => row.data?.id === gridStateRef.current.selectedId);
                if (!selectedRow) return;

                let rowToSelect = undefined;
                if (event.keyCode === 40) { // ArrowDown
                    rowToSelect = gridApiRef.current.rowModel.rowsToDisplay[selectedRow.rowIndex + 1]; // If last line, this will be undefined;
                }
                if ((event.keyCode === 38) && selectedRow.rowIndex) { // ArrowUp (ignore if rowIndex === 0)
                    rowToSelect = gridApiRef.current.rowModel.rowsToDisplay[selectedRow.rowIndex - 1]; // If last line, this will be undefined;
                }
                if (!rowToSelect) return;
                dispatch(xpGridSetSelected(gridStateRef.current.instanceId, rowToSelect.data?.id));
            }
        },[],
    );

    useEffect(
        () => {
            if (!gridState) dispatch(xpGridCreateGrid(props.instanceId, props.defaultSelectedId))
            document.addEventListener("keydown", onKeyDown, false);
            return () => {
                document.removeEventListener("keydown", onKeyDown, false);
            };
        },
        [],
    );

    useEffect(
        () => {
            // console.log("useEffect on heightState", props.instanceId, heightState);
            if (props.setHeightCallback) props.setHeightCallback(heightState);
        },
        [heightState],
    );


    // Listen to updated selectedId in gridState and select row if needed. No need to use ref since this should only react on state updates
    useEffect(
        () => {
            if (!gridApiRef.current) return;
            if (!gridState) return;
            resetSelectedRow({api: gridApiRef.current});
        },
        [gridState?.selectedId],
    );

    if (!rowDataToUse) return null;
    if (gridState && !gridState.show) return null;


/**************************************************************
 * Grid height management and callback functions
 **************************************************************/
    function onHeightChangedFromResizable (newHeight) {
        setHeightState(newHeight);
        if (!managedHeightStateRef.current) setManagedHeightState(true);
    }

    function calculateAndSetHeight(params) {
        if (managedHeightStateRef.current) return;

        let newGridHeight = calculateNewGridHeight(props.columnDefs, params.api.rowModel.rowsToDisplay, maxRowsToDisplay);
        if (heightState === newGridHeight) return;
        // console.log("calculateAndSetHeight", props.instanceId, params.api.rowModel.rowsToDisplay, heightState, newGridHeight, maxRowsToDisplay);

        setHeightState(newGridHeight);
    }
    function resetHeightSettings() {
        let newGridHeight = calculateNewGridHeight(props.columnDefs, rowDataToUse, maxRowsToDisplay);
        setHeightState(newGridHeight);
        setManagedHeightState(false);
    }
    function reCalculateGridHeight() {
        if (managedHeightStateRef.current || !gridApiRef.current) {
            return;
        }
        let newGridHeight = calculateNewGridHeight(props.columnDefs, gridApiRef.current.rowModel.rowsToDisplay, maxRowsToDisplay);
        if (heightState === newGridHeight) return;
        // console.log("reCalculateGridHeight", props.instanceId, gridApiRef.current.rowModel.rowsToDisplay, heightState, newGridHeight, maxRowsToDisplay);
        setHeightState(newGridHeight);
    }

/**************************************************************
* Row selection and helper functions
**************************************************************/

    function informParentOfSelectedObject(selectedObject) {
        if (!props.gridObjectSelected) return;
        const selectedObjectId = selectedObject ? selectedObject.id : undefined;
        if (selectedObjectIdInformedToParentRef.current === selectedObjectId) return; // selected object already informed. no need to inform again.

        props.gridObjectSelected(selectedObject);
        setSelectedObjectIdInformedToParent(selectedObjectId);
    }
    function resetSelectedRow(params) {
        if (!params) return;
        if (!gridStateRef.current) return;

        if (!gridStateRef.current.selectedId) {
            params.api.deselectAll();
            informParentOfSelectedObject(undefined);
            return;
        }
        let selectedObject = undefined;
        for (let row of params.api.rowModel.rowsToDisplay) {
            if (!row.data || row.detail) continue; // Skip group and detail rows
            if (row.data.id !== gridStateRef.current.selectedId) continue;

            selectedObject = row.data;
            if (!row.selected) {
                row.setSelected(true);
            }
            try {
                params.api.ensureIndexVisible(row.rowIndex);
            }
            catch {
                console.warn("Could not set ensureIndexVisible in XpGrid.");
            }
        }
        if (!selectedObject && gridStateRef.current.selectedId) {
            // console.log("Setting selected to undefined in row 279");

            // This line does not work when a new object is created. The xpGridSetSelected with the new id is called before the grid is updated with the new object.
            // dispatch(xpGridSetSelected(props.instanceId, undefined));

            params.api.deselectAll();
        }
        informParentOfSelectedObject(selectedObject);
    }
    function selectRowIfNotSelected(params){
        if (!params || !params.node || !params.node.data) return false;

        // MT: For some reason the props is not updated in this callback chain. Getting updated props via contexts works.
        const propHandle = (params.context) ? (params.context) : props; // Use normal props if not context exists
        if (propHandle.gridRowClicked) propHandle.gridRowClicked(params);
        if (propHandle.suppressRowClickSelection) return false;

        if (params.node.selected) return true;

        // OK, time to select row.
        params.api.deselectAll();
        dispatch(xpGridSetSelected(props.instanceId, params.node.data.id));
        params.node.setSelected(true);
        informParentOfSelectedObject(params.node.data);
        return true;
    }
    function isIdVisible(params, id) {
        const visibleRows = (params.api && params.api.rowModel) ? params.api.rowModel.rowsToDisplay : null;
        if (!visibleRows) return false;
        if (visibleRows.find(row => {
            return (row.data?.id === id);
        })) return true;
        return false;
    }


/**************************************************************
 * Cell and row selection and click callbacks
 **************************************************************/
    function onCellClicked(params) {
        if (props.gridCellClicked) props.gridCellClicked(params);
    }
    function onRowSelected(params) {
        if (!gridStateRef.current || !params.node.data) return;
        if (params.node.selected && (params.node.data.id === gridStateRef.current.selectedId)) {
            return; // Already selected from onRowClicked
        }
        if (params.node.selected && !gridStateRef.current.selectedId) {
            onRowClicked(params, false);
            return;
        }
        if (!params.node.selected && (params.node.data.id === gridStateRef.current.selectedId)) {
            onRowClicked(params, false);
            return;
        }
    }
    function onRowClicked(params, isDoubleClick) {
        if (!gridStateRef.current) return;

        if (!params.node.data) return; // ignore click on group rows.
        if (params.node.detail) return; // ignore click on detail rows.
        if (props.gridRowClicked) props.gridRowClicked(params);
        if (isDoubleClick) {
            if (props.gridRowDoubleClicked) {
                props.gridRowDoubleClicked(params);
            }
            else {
                params.node.setExpanded(!params.node.expanded)
            }
        }

        if (params.context.suppressRowClickSelection) return;

        const selectedData = (params.node.data.id === gridStateRef.current.selectedId) ? undefined : params.node.data;

        dispatch(xpGridSetSelected(props.instanceId, selectedData ? selectedData.id : undefined));
        resetSelectedRow(params);
    }
    function onRowDoubleClicked(params) {
        onRowClicked(params, true);
        // onRowClicked(params, true);
    }


/**************************************************************
 * Grid updated and ready callback function
 **************************************************************/

    function checkAndSetFilter(params) {
        // Since the new data may contain data affected by the saved filter, the saved filter must be re-applied to the grid.
        let filterState = params.api.getFilterModel();
        if (isEqual(filterState, gridStateRef.current.filterState)) return;
        params.api.setFilterModel(gridStateRef.current.filterState);
    }

    function rowDataChanged(params) {
        // console.log("rowDataChanged: ", props.instanceId);
        resetSelectedRow(params);
        checkAndSetFilter(params);
    }
    function viewportChanged(params) {
    }
    function rowDataUpdated(params) {
        // console.log("rowDataUpdated: ", props.instanceId);
        calculateAndSetHeight(params);
        resetSelectedRow(params);
        checkAndSetFilter(params)
    }
    function componentStateChanged(params) {
        if (!!params?.api?.gridBodyComp ||!params.api.gridBodyComp.isAlive()) return; // Grid is not rendered yet. Calling api methods below will crash the grid.
        // if (!params || !params.api || !params.api.gridPanel || params.api.gridPanel.destroyed) return; // Grid is not rendered yet. Calling api methods below will crash the grid.
        // if (params.api.gridPanel.destroyed) return; // Grid is not rendered yet. Calling api methods below will crash the grid.
        calculateAndSetHeight(params);
        // resetSelectedRow(params, true); // Removed. Caused the grid to focus on the previously selected row if out of display and suppress the rowClicked callback.
    }

    function applyGridStateToColumns(columnApi) {
        if (!gridStateRef.current) return;
        if (gridStateRef.current.colState !== undefined) columnApi.setColumnState(gridStateRef.current.colState);

/*
        if (gridStateRef.current.colDef) {
            for (let colId in gridStateRef.current.colDef) {
                let thisColumn = columnApi.columnController.primaryColumns.find(col => col.colId === colId);
                if (!thisColumn && props.autoGroupColumnDef) {
                    thisColumn = columnApi.columnController.groupAutoColumns.find(col => col.colId === colId);
                }
                if (thisColumn) {
                    thisColumn.setColDef({...thisColumn.colDef, suppressSizeToFit: true}, {...thisColumn.colDef, suppressSizeToFit: true});
                }
            }
        }
*/
    }

    function setFilterFromState(gridApi, columnApi) {
        for (const gridColumn of columnApi.columnController.gridColumns) {

            const filter = gridApi.getFilterInstance(gridColumn.colDef.colId);
            if (!filter) continue;

            const currentFilterModel = filter.getModel()

            if (gridColumn.colDef.cellRendererParams?.filterTrKeys) {
                const filterValues = [];
                gridColumn.colDef.cellRendererParams.filterTrKeys.forEach((trKey, index) => {
                    let newValue = translateRef.current(trKey);
                    if (filterValues.includes(newValue)) {
                        console.error("Duplicate translated filter value in colDef ", gridColumn.colDef, newValue, filterValues);
                        newValue = newValue + index;
                    }
                    filterValues.push(newValue);
                })
                filter.setFilterValues(filterValues);
            }

            if (!gridStateRef.current?.filterState || !gridStateRef.current.filterState[gridColumn.colDef.colId]) continue;

            const thisFilterState = gridStateRef.current.filterState[gridColumn.colDef.colId];

            let valuesToSet = [];
            if (thisFilterState.trKeys) {
                thisFilterState.trKeys.forEach(trKey => valuesToSet.push(translateRef.current(trKey)));
            }
            if (thisFilterState.values) {
                thisFilterState.values.forEach(trKey => valuesToSet.push(translateRef.current(trKey)));
            }
            const newFilterModel = {...currentFilterModel, values: valuesToSet}
            filter.setModel(newFilterModel);
        }
        gridApi.onFilterChanged();
    }

    function onGridReady(params) {
        // console.log("onGridReady: ", props.instanceId);
        if (!params) return;
        gridApiRef.current = params.api;
        if (props.useResponsiveRowHeight) params.api.resetRowHeights();
        if (props.onGridReadyCallback) props.onGridReadyCallback(params);

        setGridIsReady(true);

        if (!gridStateRef.current) {
            params.api.sizeColumnsToFit();
            return;
        }

        setFilterFromState(params.api, params.columnApi);

        // if (gridStateRef.current.sortState   !== undefined) params.api.setSortModel(gridStateRef.current.sortState);
        applyGridStateToColumns(params.columnApi);


        if (gridStateRef.current.fitToPage === true) params.api.sizeColumnsToFit();

        if (gridStateRef.current.expState) {
            for (let id in gridStateRef.current.expState) {
                const displayRow = params.api.rowModel.rowsToDisplay.find(row => row.id === Number(id));
                if (displayRow && !displayRow.expanded) displayRow.setExpanded(true);
            }
        }
        if (gridStateRef.current.selectedId) { // Only check visibleSelectedId if there is something selected and filter is changed.
            dispatch(xpGridSetVisibleSelected(props.instanceId, isIdVisible(params, gridStateRef.current.selectedId)));
        }
        resetSelectedRow(params);
    }

    function gridUpdated (params) {
        // console.log("gridUpdated: ", props.instanceId);
        if (!params?.api?.gridBodyComp || !params.api.gridBodyComp.isAlive()) return; // Grid is not rendered yet. Calling api methods below will crash the grid.
        // if (!params || !params.api || !params.api.gridPanel || params.api.gridPanel.destroyed) return; // Grid is not rendered yet. Calling api methods below will crash the grid.

        if ((params.type === 'newColumnsLoaded') && gridStateRef && gridStateRef.current) {
            applyGridStateToColumns(params.columnApi);
            if (gridStateRef.current.fitToPage === true) params.api.sizeColumnsToFit();
            setFilterFromState(params.api, params.columnApi);
        }
        resetSelectedRow(params);
    }
    function gridChanged(params) {
        // console.log("gridChanged: ", props.instanceId, params.type);
        if (!params) return;
        if (props.useResponsiveRowHeight) params.api.resetRowHeights();

        if (!gridStateRef.current) {
            params.api.sizeColumnsToFit();
            return;
        }
        if (gridStateRef.current.fitToPage === true) params.api.sizeColumnsToFit();

        if ((params.type === "filterChanged") && gridStateRef.current.selectedId) { // Only check visibleSelectedId if there is something selected and filter is changed.
            dispatch(xpGridSetVisibleSelected(props.instanceId, isIdVisible(params, gridStateRef.current.selectedId)));
        }

        // The state update to xpGridReducer must be done here. If dispatched in onColumnResize, react throws an state flush while rendering exception.
        if ((params.type === "dragStopped") && (params.target.className === "ag-header-cell-resize")) {
            const columns = params.columnApi.columnController.gridColumns;
            for (let col of columns) {
                if (!col.colDef.suppressSizeToFit) continue;

                let savedColDef = (gridStateRef.current.colDef && gridStateRef.current.colDef[col.colId]) ? gridStateRef.current.colDef[col.colId] : {};
                if (savedColDef.suppressSizeToFit) continue;

                savedColDef.suppressSizeToFit = true;
                dispatch(xpGridSetColDef(props.instanceId, col.colId, savedColDef));
            }
        }

        // Automatic reset of filter must not update xpGridState. When data is updated the filter should be reset to state.
        if ((params.type === 'filterChanged') && (params.afterDataChange !== false)) return;

        let gridFilterState = params.api.getFilterModel();
        const newFilterState = {};

        for (const gridColumn of params.columnApi.columnController.gridColumns) {
            if (!gridFilterState[gridColumn.colDef.colId]) continue;
            const filter = params.api.getFilterInstance(gridColumn.colDef.colId);
            if (!filter) continue;

            const currentFilterModel = filter.getModel()
            if (!currentFilterModel?.values?.length) continue;

            newFilterState[gridColumn.colDef.colId] = {...currentFilterModel, values: [], trKeys: []};

            for (const filterValue of currentFilterModel.values) {
                let valueSet = false;
                if (gridColumn.colDef.cellRendererParams?.filterTrKeys) {
                    for (const trKey of gridColumn.colDef.cellRendererParams.filterTrKeys) {
                        if (translateRef.current(trKey) === filterValue) {
                            newFilterState[gridColumn.colDef.colId].trKeys.push(trKey);
                            valueSet = true;
                            break;
                        }
                    }
                }
                if (!valueSet) {
                    newFilterState[gridColumn.colDef.colId].values.push(filterValue);
                }
            }
        }

        let colState    = params.columnApi.getColumnState();
        dispatch(xpGridSetState(props.instanceId, [...colState], newFilterState));
    }

    function onGridSizeChanged(params) {
        if (props.useResponsiveRowHeight) params.api.resetRowHeights();

        if (!gridStateRef.current) {
            params.api.sizeColumnsToFit();
            return;
        }
        if (gridStateRef.current.fitToPage === true) params.api.sizeColumnsToFit();
    }
    function onRowGroupOpened(params) {
        // console.log("onRowGroupOpened: ", props.instanceId);
        if (!params.api.gridBodyComp.isAlive()) return; // Grid is not rendered yet. Calling api methods below will crash the grid.
        // if (!params || !params.api || !params.api.gridPanel || params.api.gridPanel.destroyed) return; // Grid is not rendered yet. Calling api methods below will crash the grid.
        // if (!params.api.rowModel || !params.api.rowModel.rowsToDisplay) return;

        // selectRowIfNotSelected(params)
        // resetSelectedRow(params);
        calculateAndSetHeight(params);

        let expState = {};
        for (let row of params.api.rowModel.rowsToDisplay) {
            if (row.master && row.canFlower && row.expanded) expState[row.id] = true;
        }
        dispatch(xpGridSetExpandedState(props.instanceId, expState));
    }
    function onColumnResized(params) {
        // console.log("onColumnResized: ", props.instanceId);
        if (params.source !== 'uiColumnDragged') return;
        if (params.column && params.column.colDef.suppressSizeToFit) return;

        const setColDef = (column) => {
            column.setColDef({...column.colDef, suppressSizeToFit: true}, {...column.colDef, suppressSizeToFit: true});
        }
        if (params.column) {
            setColDef(params.column);
            return;
        }
        if (!params.columns) return;

        for (let col of params.columns) {
            if (col.colDef.suppressSizeToFit) continue;
            setColDef(col);
        }
    }


/**************************************************************
 * Grid menu and context menu functions
 **************************************************************/

    function getCommonItems(menuItems, params) {
        let menuItem = {};
        if (!gridStateRef.current) return;

        const auditMenuItem = createOpenObjectAuditMenuItem(auditConfig, props.ormModel, params.node?.data, dispatch, translate, user);
        if (auditMenuItem) {
            menuItems.push(auditMenuItem)
        }

        menuItem = {
            name: translate(gridStateRef.current.fitToPage ? 'general.stopFitColumnsToPage' : 'general.fitColumnsToPage'),
            action: function () {
                dispatch(xpGridSetFitToPage(props.instanceId, !gridStateRef.current.fitToPage));
                if (gridStateRef.current.fitToPage) this.context.api.sizeColumnsToFit();
            },
            context: params
        };
        menuItems.push(menuItem);

        menuItem = {
            name: translate('general.resetGridState'),
            action: function (params) {
                dispatch(xpGridResetGridState(props.instanceId));
                this.context.columnApi.resetColumnState();
                this.context.api.setFilterModel({});
                // this.context.api.setSortModel([]);

                const columns = this.context.columnApi.getAllColumns()
                if (!columns || !columns.length) return;
                for (let col of columns) {
                    if (!col.colDef && !col.colDef.suppressSizeToFit) continue;
                    col.setColDef({...col.colDef, suppressSizeToFit: false}, null);
                }
                for (let row of this.context.api.rowModel.rowsToDisplay) {
                    if (row.master && row.canFlower && row.expanded) row.setExpanded(false);
                }
                resetHeightSettings();
            },
            context: params
        };
        menuItems.push(menuItem);
    }
    function getContextMenuItems(params) {
        let menuItems = [];

        let injectedContextMenuItems;
        if (props.gridContextMenuItems) injectedContextMenuItems = props.gridContextMenuItems(params);
        if (injectedContextMenuItems && (injectedContextMenuItems.length > 0)) {
            for (let item of injectedContextMenuItems) menuItems.push(item);
            menuItems.push("separator");
        }

        getCommonItems(menuItems, params);
        menuItems.push("copy");
        menuItems.push("csvExport");
        menuItems.push("excelExport");

        if (devMode || devInfoModeRef.current) menuItems.push("separator");
        if ((devMode || devInfoModeRef.current) && params.node) menuItems.push(createSetOrmPropertyMenuItem(props.ormModel, params.node.data, dispatch));
        if (devMode && params.node && params.node && (params.node.data.executionRights !== undefined)) menuItems.push(createViewExecutionRightsModel(params.node.data, dispatch));

        if (!selectRowIfNotSelected(params)) params.api.hidePopupMenu();
        return menuItems;
    }
    function getMainMenuItems(params) {
        let menuItems = [];

        menuItems.push("pinSubMenu");
        menuItems.push("separator");

        getCommonItems(menuItems, params);

        let injectedMenuItems;
        if (props.gridMenuItems) injectedMenuItems = props.gridMenuItems(params);
        if (injectedMenuItems && (injectedMenuItems.length > 0)) {
            for (let item of injectedMenuItems) menuItems.push(item);
            menuItems.push("separator");
        }
        return menuItems;
    }


/**************************************************************
 * Cell editing and row move functions
 **************************************************************/

    function onCellEditingStarted(params){
       selectRowIfNotSelected(params);
        if (props.onCellEditingStarted) props.onCellEditingStarted(params);
    }
    function onCellValueChanged(params){
        if (props.onCellValueChanged) props.onCellValueChanged(params);
    }
    function onCellEditingStopped(params){
        if (props.onCellEditingStopped) props.onCellEditingStopped(params);
    }
    function onRowDragEnd(params){
        if (props.onRowDragEnd) props.onRowDragEnd(params);
    }
    function onRowDragEnter(params){
        selectRowIfNotSelected(params);
        if (props.onRowDragEnter) props.onRowDragEnter(params);
    }
    function onRowDragLeave(params){
        selectRowIfNotSelected(params);
        if (props.onRowDragLeave) props.onRowDragLeave(params);
    }



/**************************************************************
 * AG-Grid props composition
 **************************************************************/

    const fixedProps = {
        getRowNodeId            : params => params?.id,
        suppressCellSelection   : false,
        rowSelection            : "single",
        popupParent             : document.getElementById("oxyGenApp"),
        getDataPath             : data => data.hierarchy,
        animateRows             : true,
        context                 : this,
        immutableData           : true,
        reactNext               : true,
        enableBrowserTooltips   : true,
        // debug                   : true,
};

    const getPropsFromProps = () => {
        const getDefaultColDef = () => {
            return {
                headerComponentParams: {menuIcon: "fa-bars"},
                sortable  : true,
/*
                filter    : 'agMultiColumnFilter',
                filterParams : {newRowsAction: 'keep', buttons: []},
*/
                filter    : true,
                filterParams : {excelMode: 'windows', newRowsAction: 'keep', buttons: []},
                resizable : true,
                ...props.defaultColDef
            }
        };
        return {
            gridId                                  : props.gridId,
            treeData                                : props.treeData,
            autoGroupColumnDef                      : props.autoGroupColumnDef,
            rowGroupPanelShow                       : props.rowGroupPanelShow,
            groupUseEntireRow                       : props.groupUseEntireRow,
            groupDefaultExpanded                    : (props.groupDefaultExpanded === undefined) ? 0 : props.groupDefaultExpanded,
            enableCellChangeFlash                   : !props.disableFlash,
            suppressRowClickSelection               : props.suppressRowClickSelection,

            columnDefs                              : calculatedColDefs,

            headerHeight                            : props.headerHeight,
            suppressDragLeaveHidesColumns           : props.suppressDragLeaveHidesColumns,
            suppressMakeColumnVisibleAfterUnGroup   : props.suppressMakeColumnVisibleAfterUnGroup,
            overlayNoRowsTemplate                   : props.overlayNoRowsTemplate,
            getRowClass                             : props.getRowClass,
            rowClassRules                           : props.rowClassRules,
            rowDragManaged                          : props.rowDragManaged,
            suppressRowDrag                         : props.suppressRowDrag,
            editType                                : props.editType,
            // stopEditingWhenGridLosesFocus           : props.stopEditingWhenGridLosesFocus,
            stopEditingWhenCellsLoseFocus           : props.stopEditingWhenCellsLoseFocus,
            getRowHeight                            : props.getRowHeight,
            applyColumnDefOrder                     : props.applyColumnDefOrder,
            isRowMaster                             : props.isRowMaster,
            defaultColDef                           : getDefaultColDef(),
            frameworkComponents                     : {
                ...props.frameworkComponents,
                ...xpGridCellRenderersComponentMap,
            },
        }
    };

    const getFuncProps = () => {
        return {
            onCellClicked           : params => onCellClicked(params),
            onRowClicked            : params => onRowClicked(params),
            onRowDoubleClicked      : params => onRowDoubleClicked(params),
            onRowSelected           : params => onRowSelected(params),
            onGridReady             : params => onGridReady(params),
            onRowGroupOpened        : params => onRowGroupOpened(params),
            onGridSizeChanged       : params => onGridSizeChanged(params),
            getContextMenuItems     : params => getContextMenuItems(params),
            getMainMenuItems        : params => getMainMenuItems(params),
            onDragStopped           : params => gridChanged(params),
            onColumnVisible         : params => gridChanged(params),
            onFilterChanged         : params => gridChanged(params),
            onSortChanged           : params => gridChanged(params),
            onColumnPinned          : params => gridChanged(params),
            onNewColumnsLoaded      : params => gridUpdated(params),
            onRowDataChanged        : params => rowDataChanged(params),
            onViewportChanged       : params => viewportChanged(params),
            onRowDataUpdated        : params => rowDataUpdated(params),
            onComponentStateChanged : params => componentStateChanged(params),
            onRowDragEnd            : params => onRowDragEnd(params),
            onRowDragEnter          : params => onRowDragEnter(params),
            onRowDragLeave          : params => onRowDragLeave(params),
            onCellEditingStarted    : params => onCellEditingStarted(params),
            onCellEditingStopped    : params => onCellEditingStopped(params),
            onCellValueChanged      : params => onCellValueChanged(params),
            onColumnResized         : params => onColumnResized(params),
        }
    };

    const masterDetailProps = {
        masterDetail: props.masterDetail,
        detailCellRenderer: props.detailCellRenderer ? props.detailCellRenderer : 'xpGridDetailCellRenderer',
        detailRowHeight: 0, // First render is not the detail renderer but the default height of the grid. Set to 0 to avoid flickering.
        detailCellRendererParams: {
            xpDetailRendererProps: {
                ...props.xpDetailRendererProps,
                maxRowsToDisplay        : maxRowsToDisplay,
                managedHeightStateRef   : managedHeightStateRef,
                heightStateRef          : heightStateRef
            },
        },
    };

    const gridCalculatedProps = {
        rowData: rowDataToUse,
        ...fixedProps,
        ...getFuncProps(),
        ...getPropsFromProps(),
        ...masterDetailProps
    }

/**************************************************************
 * Render function
 **************************************************************/

    // console.log("Rendering: ", props.instanceId, heightState);
    return (
        <XpResizable
            defaultHeight={props.fillAvailableSpace ? '100%' : heightState + (props.addExtraHeight ? props.addExtraHeight : 0) + (xpGridConfig?.extraGridHeight ? xpGridConfig?.extraGridHeight : 0)}
            isDisabled={props.fillAvailableSpace || !rowDataToUse || !rowDataToUse.length || props.disableResizable}
            setHeight={props.fillAvailableSpace ? undefined : heightState + ((props.addExtraHeight && !managedHeightState) ? props.addExtraHeight : 0) + ((xpGridConfig?.extraGridHeight && !managedHeightState) ? xpGridConfig?.extraGridHeight : 0)}
            setHeightCallback={onHeightChangedFromResizable}
            setManaged={managedHeightState}
        >
            <div
                style={{
                    boxSizing: "border-box",
                    height: "100%",
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "auto",
                    display: gridIsReady ? 'block' : 'none'
                }}
                className  = "ag-theme-balham"
                xp-test-id = {'xpGrid-' + props.instanceId}
                onFocus    = {() => setGridInFocus(true)}
                onBlur     = {() => setGridInFocus(false)}
            >
                <AgGridReact
                    {...gridCalculatedProps}
                    context={{...props, reCalculateGridHeight: reCalculateGridHeight}}
                >
                </AgGridReact>
            </div>
        </XpResizable>
    );
}
/*
XpGrid3.propTypes = {
    // xxx: PropTypes.string.isRequired,
};
*/
export default XpGrid3



