import React from 'react'
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalLogMessage} from "gui-common/modals/modalFunctions";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useDispatch, useSelector} from "react-redux";
import cloneDeep from "lodash.clonedeep";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpForm from "gui-common/xpForm/core/XpForm";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

function ModalConfirmationWithComment ({popModalWindow, commentIsRequired, modalKey, modalParams, headerText, messageText, cancelText, confirmText, resultTypes, bodyClassName}) {

    const translate = useSelector(selectTranslateFunction);
    const dispatch  = useDispatch();
    const formDataComment         = useXpFormField('confirmWithCommentForm.comment');
    const noFormValidation = useAppEnvProperty( 'noFormValidation');

    const logProps = {messageText: messageText, modalKey: modalKey, translate: translate, modalParams: modalParams};

    function cancelAction() {
        modalLogMessage('User cancelled after message:\n', logProps);
        dispatch(xpFormRemoveForm('confirmWithCommentForm'));
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }

    function handleSubmit() {
        modalLogMessage('User confirmed message:', logProps);
        popModalWindow({ status: MODAL_TYPE_CONFIRM, comment: formDataComment});
        dispatch(xpFormRemoveForm('confirmWithCommentForm'));
    }

    const showCancel  = cancelText  ? cancelText  : <XpTranslated trKey={modalKey ? (modalKey+'.cancel' ) : 'general.buttons.cancel' } fallbackKey='general.buttons.cancel'  trParams={modalParams}/>;
    const showConfirm = confirmText ? confirmText : <XpTranslated trKey={modalKey ? (modalKey+'.confirm') : 'general.buttons.confirm'} fallbackKey='general.buttons.confirm' trParams={modalParams}/>;
    const showHeader  = headerText  ? headerText  : <XpTranslated trKey={modalKey ? (modalKey+'.header' ) : ''} trParams={modalParams}/>;
    const showMessage = messageText ? messageText : <XpTranslated trKey={modalKey ? (modalKey+'.message') : ''} trParams={modalParams}/>;

    const modalRenderingParams = cloneDeep(modalStandardParams);
    modalRenderingParams.className.base = modalRenderingParams.className.base + " xpoolModalConfirmWithComment " + (bodyClassName ? bodyClassName : "");

    return (
        <div>
            <FocusLock>
            <ReactModal
                contentLabel="Confirmation modal"
                onRequestClose={cancelAction}
                shouldCloseOnEsc={true}
                {...modalRenderingParams}
            >
                <div xp-test-id={"xpModalConfirmationWithComment " + bodyClassName}>
                    <h3 className ="xpoolModalHeader">{showHeader}</h3>
                    <hr/>
                    <p className="valueTextDark">{showMessage}</p>
                    <hr/>

                    <XpForm
                        formModel='confirmWithCommentForm'
                        onSubmit={(data) => handleSubmit(data)}
                        initialUseState={XP_FORM_EDIT}
                        initialFormData={{comment: ""}}
                    >
                        <XpFormTextAreaInput
                            field         = "comment"
                            isRequired    = {commentIsRequired && !!noFormValidation}
                            maxLength     = {200}
                            autoFocus     = {true}
                        />
                    </XpForm>
                    <div className="xpoolModalButtons">
                        <WaitingXpoolButton
                            xpTestId        = {"xpModalButtonConfirm"}
                            label           = {showConfirm}
                            onClickCallback = {() => dispatch(xpFormSubmitForm("confirmWithCommentForm"))}
                            disabled        = {commentIsRequired && (formDataComment === "") && !noFormValidation}
                        />
                        <WaitingXpoolButton
                            xpTestId        = {"xpModalButtonCancel"}
                            label           = {showCancel}
                            onClickCallback = {() => cancelAction()}
                        />
                    </div>
                </div>
            </ReactModal>
            </FocusLock>
        </div>
    );
}
ModalConfirmationWithComment.propTypes = {
    modalKey:       PropTypes.string,
    modalParams:    PropTypes.object,
    headerText:     PropTypes.string,
    messageText:    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cancelText:     PropTypes.string,
    confirmText:    PropTypes.string,
    resultTypes:    PropTypes.object.isRequired,
    popModalWindow: PropTypes.func.isRequired,
    commentIsRequired: PropTypes.bool,
};

export default ModalConfirmationWithComment;

