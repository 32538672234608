import React, {useCallback, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {selectActiveLanguage, useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {commonAuditActions} from "gui-common/audit/auditConstants";
import {createTranslatedFieldData, xpColCreate} from "gui-common/xpGrid/xpGridFunctions";

export function AuditBulkItemsList (props) {

    const currentLanguage = useSelector(selectActiveLanguage);

    const translate     = useXpTranslateFunction();
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!props.itemSelectedCallback) return;
                props.itemSelectedCallback(data);
            },
            [props.itemSelectedCallback]),
        rowClassRules   : {},
        gridContextMenuItems    : useCallback(
            (params) => {
                return []
            }, []),
    };
    const gridOptions = useMemo(
        () => {
            const columnDefs = [];
            xpColCreate(translate, columnDefs, false, 'actionT', 100 , 50, 'auditView.actionTypes.FileUpload.listHeaders.action');
            xpColCreate(translate, columnDefs, false, 'id'     , 50 , 50, 'auditView.actionTypes.FileUpload.listHeaders.id');
            xpColCreate(translate, columnDefs, false, 'info'   , 400 , 100, 'auditView.actionTypes.FileUpload.listHeaders.info');
            return {
                instanceId              : props.instanceId,
                columnDefs              : columnDefs,
                overlayNoRowsTemplate   : translate('auditEntriesList.noAuditEntriesToShow'),
                fillAvailableSpace      : false,
                groupDefaultExpanded    : 0,
                treeData                : false,
                masterDetail            : false,
                ormModel                : props.modelConfig.model,
            };
        },
        [translate, props.modelConfig]
    );

    const rowData = useMemo(
        () => {
            if (!props.data) return [];
            return [
                ...formatGridData(props.data.created, currentLanguage, translate, commonAuditActions.Create, props.modelConfig),
                ...formatGridData(props.data.updated, currentLanguage, translate, commonAuditActions.Update, props.modelConfig),
                ...formatGridData(props.data.deleted, currentLanguage, translate, commonAuditActions.Delete, props.modelConfig),
            ]
        },
        [props.data, currentLanguage, translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                rowData={rowData}
                callingContext={this}
            >
            </XpGrid>
        </div>
    );
}

export function formatGridData(dataArray, currentLanguage, translate, action, modelConfig) {

    const getActionData = {
        [commonAuditActions.Create]: data => ({
            itemData: data,
            before: undefined,
            after: {...data, isNew: true}
        }),
        [commonAuditActions.Update]: data => ({
            itemData: data,
            before: data.before,
            after: data.after,
        }),
        [commonAuditActions.Delete]: data => ({
            itemData: data,
            before: undefined,
            after: {...data, isDeleted: true},
        }),
    }

    if (!dataArray) {
        return [];
    }
    const returnArray = []
    for (let sourceItem of dataArray) {
        const destItem = {
            action: action,
            ...getActionData[action](sourceItem),
        }
        destItem.id = destItem.itemData.id;
        createTranslatedFieldData(destItem, 'action' , translate, ('auditView.actionTypes.' + action + '.name'));
        destItem.info = modelConfig?.getInfoString ? modelConfig.getInfoString(destItem.itemData) : destItem.itemData.name;
        returnArray.push(destItem)
    }
    return returnArray;
}
