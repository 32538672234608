import React from "react";
import {useDispatch, useSelector} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import { modalStandardParams} from "gui-common/modals/modalConstants";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {ormItemLoading} from "gui-common/orm/ormLoadingSelectors";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";


export function ManualDispatchModal({popModalWindow, dispatchTrade}) {

    const tradeLoading  = useSelector(state => ormItemLoading(state, {itemId: dispatchTrade.id, ormModel: 'DispatchTrade'}));
    const commentIsRequired = useSelector(state => state.appEnvState.confirmDispatchTradeForm.commentIsRequired);
    const dispatch  = useDispatch();
    const formDataBookingReference = useXpFormField('confirmDispatchTradeForm.bookingReference');
    const formDataComment          = useXpFormField('confirmDispatchTradeForm.comment');


//    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalGenerateUserMessage"}};

    function cancelAction() {
        popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm('confirmDispatchTradeForm'));
    }

    function handleSubmit() {
        popModalWindow({ status: MODAL_TYPE_CONFIRM, bookingReference: formDataBookingReference, comment: formDataComment });
        dispatch(xpFormRemoveForm('confirmDispatchTradeForm'));
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Manual confirm dispatch trade dialog"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >

                    <div xp-test-id="manualDispatchTradeConfirmation">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'confirmDispatchTradeForm.header'} trParams={{tradeId: dispatchTrade.id}}/></h3>
                        <hr/>
                        <span><XpTranslated trKey={'confirmDispatchTradeForm.message'}/></span>
                        <hr/>
                        <XpForm
                            formModel='confirmDispatchTradeForm'
                            onSubmit={(data) => handleSubmit(data)}
                            initialUseState={XP_FORM_EDIT}
                            initialFormData={{bookingReference: "", comment: ""}}
                        >
                            <XpFormTextInput
                                isRequired
                                field="bookingReference"
                                maxLength={20}
                                autoFocus={true}
                            />

                            <XpFormTextAreaInput
                                field="comment"
                                isRequired={commentIsRequired}
                                maxLength={200}
                            />

                        </XpForm>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "confirmDispatchTradeForm.confirm"
                                onClickCallback = {() => dispatch(xpFormSubmitForm("confirmDispatchTradeForm"))}
                                waiting         = {tradeLoading}
                                disabled        = {((formDataBookingReference === "") || (commentIsRequired && formDataComment === ""))}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
