import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import 'react-datepicker/dist/react-datepicker.css';
import {
    usersSelector
} from "gui-common/orm/ormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {loadAuditSearchTypesFromApi, requestAuditEntriesFromApi} from "gui-common/audit/auditApi";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {ormModelLoading} from "gui-common/orm/ormLoadingSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {ormEntitiesClearModel} from "gui-common/orm/ormReducer";
import {globalAuditConfig} from "gui-common/audit/auditConstants";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {
    auditEntriesSelector,
} from "gui-common/audit/auditSelectors";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormDateInput from "gui-common/xpForm/core/XpFormDateInput";
import {
    xpFormChangeField,
    xpFormSubmitForm
} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import RunConfigurationsList from "gui-common/runConfiguration/RunConfigurationsList";
import XpFormRadioInput from "gui-common/xpForm/core/XpFormRadioInput";
import ComponentLoading from "gui-common/components/ComponentLoading";

function onFilterChange(fieldModel, newValue, currentValue, setValue) {
    return (dispatch, getState) => {

        const auditEntries = auditEntriesSelector(getState());
        if (!auditEntries || !auditEntries.length) { // No need to confirm with user if there are no audit entries loaded.
            setValue(newValue);
            return;
        }

        // Set value so that the change is visible when the user gets the confirmation dialogue.
        setValue(newValue); // Set value so that the change is visible.
        dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey : 'auditViewFilterForm.changeFilterModal'}))
            .then((result) => {
                if (result.status === MODAL_TYPE_CONFIRM) {
                    if (fieldModel === 'auditViewFilterForm.rootModel') dispatch(xpFormChangeField('auditViewFilterForm.rootObjectId', ''));
                    dispatch(ormEntitiesClearModel('AuditEntry'));
                }
                if (result.status === MODAL_TYPE_CANCEL) {
                    setValue(currentValue); // Change back to previous value.
                }
            });
    }
}

const baseFormModel = 'auditViewFilterForm';
const andOrValues = ["AND","OR"].map(item => ({id: item, name: item}))
export function AuditViewFilterForm (props) {
    const dispatch          = useDispatch();
    const users             = useSelector(usersSelector);
    const auditItemsLoading = useSelector(state => ormModelLoading(state, {ormModel: 'AuditEntry'}));
    const formData          = useXpFormFields({dependentFieldModels: {
            fromDate     : 'auditViewFilterForm.fromDate',
            toDate       : 'auditViewFilterForm.toDate',
            action       : 'auditViewFilterForm.action',
            userId       : 'auditViewFilterForm.userId',
            searchFields : 'auditViewFilterForm.runConfiguration.auditSearchFields'
        }})
    const [searchTypes       , setSearchTypes       ] = useState(undefined);
    const [searchTypesLoading, setSearchTypesLoading] = useState(true);

    const displayAndOrSelector = useMemo(
        () => {
            return formData?.searchFields?.length > 1 ? formData.searchFields.filter(item => item.isActive && !item.isDeleted).length > 1 : false;
        },
        [formData?.searchFields]
    );

    const actionsList = useMemo(
        () => {
            return Object.keys(globalAuditConfig.actions).map(key => ({id: key, trKey: "auditView.actionTypes." + key + ".name"}));
        },
        []
    );

    useEffect(
        () => {
            dispatch(loadAuditSearchTypesFromApi())
                .then(result => {
                    setSearchTypes(result.response);
                    setSearchTypesLoading(false);
                })
                .catch(result => {
                    setSearchTypes({
                        DELIVERY: ['id', 'name', 'deliveryTime'],
                        REPORT_EVENT: ['id', 'fileName', 'path'],
                    });
                    setSearchTypesLoading(false);
                });
        },
        [],
    );


    function handleSubmit(auditFilter) {
        dispatch(requestAuditEntriesFromApi(auditFilter));
    }

    const disableGetEntriesButton = !formData.fromDate || !formData.toDate;

    return (
        <div xp-test-id="auditViewFilterForm" style={{minHeight: '400px', overflow: 'auto'}}>

            <XpForm
                className="verticalFilterFormContainer"
                formModel={baseFormModel}
                onSubmit={handleSubmit}
                initialUseState={XP_FORM_EDIT}
                initialFormData={{
                    searchWidgets : {},
                    fromDate      : moment().format('YYYY-MM-DD'),
                    toDate        : moment().format('YYYY-MM-DD'),
                    runConfiguration : {}, //  auditSearchFields is handled as a run configuration in order to reuse the runConfiguration functionality.
                }}
            >
                <XpFormDateInput
                    noTemplate alignRight showMonthYearDropdown
                    field         = "fromDate"
                    dependentFields={{toDate: 'toDate'}}
                    dependentPropsFn={fields => ({maxDate: fields.toDate})}
                    minDate       = '2019-05-01'
                    onChangeThunk = {onFilterChange}
                />
                <XpFormDateInput
                    noTemplate alignRight showMonthYearDropdown
                    field         = "toDate"
                    dependentFields={{fromDate: 'fromDate'}}
                    dependentPropsFn={fields => ({minDate: fields.fromDate})}
                    maxDate       = {moment().format('YYYY-MM-DD')}
                    onChangeThunk = {onFilterChange}
                />
                <XpFormSelectorInput
                    field         = "action"
                    selectList    = {actionsList}
                    itemTextField = "id"
                    onChangeThunk = {onFilterChange}
                />
                <XpFormSelectorInput
                    field         = "userId"
                    selectList    = {users}
                    itemTextField = "fullName"
                    onChangeThunk = {onFilterChange}
                />

                <h5><XpTranslated trKey={'auditViewFilterForm.additionalFilters'}/></h5>

                {displayAndOrSelector &&
                <XpFormRadioInput
                    field="andOrSearch"
                    radioItems    = {andOrValues}
                    defaultValue={'AND'}
                />}

                {searchTypesLoading ?
                    <ComponentLoading loadingTrKey={'auditViewFilterForm.loadingSearchTypes'}/>
                    : !searchTypes || !Object.keys(searchTypes).length ?
                        <XpTranslated trKey={'auditViewFilterForm.noSearchTypesAvailable'}/>
                        :
                        <RunConfigurationsList
                        formModel={baseFormModel}
                        type={'auditSearchFields'}
                        runConfigurationBasePath=""
                        {...props}
                        searchTypes={searchTypes}
                        getActivationSwitchLabel={itemFormFields => (
                            <span>
                            {itemFormFields.fieldType ? <XpTranslated trKey={'auditViewFilterForm.auditSearchFields.fieldType.values.' + itemFormFields.fieldType} /> : <XpTranslated trKey={'auditViewFilterForm.auditSearchFields.fieldType.allValues'}/>}
                                {" | "}
                                {itemFormFields.fieldName ? itemFormFields.fieldName : <XpTranslated trKey={'auditViewFilterForm.auditSearchFields.fieldName.allValues'}/>}
{/*
                                {" | "}
                                {itemFormFields.fieldValue ? itemFormFields.fieldValue : <XpTranslated trKey={'auditViewFilterForm.auditSearchFields.fieldValue.allValues'}/>}
*/}
                        </span>
                        )}
                    />
                }

                <WaitingXpoolButton
                    labelKey        = {baseFormModel + '.submitButton'}
                    onClickCallback = {() => {dispatch(xpFormSubmitForm(baseFormModel))}}
                    waiting         = {auditItemsLoading}
                    disabled        = {disableGetEntriesButton}
                    toolTipKey      = {disableGetEntriesButton ? 'auditViewFilterForm.submitButtonDisabledTooltip' : 'auditViewFilterForm.submitButtonEnabledTooltip'}
                />

            </XpForm>

        </div>
    )
}
