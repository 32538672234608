import {useMemo} from "react";
import {globalOrmConfig} from "gui-common/orm/ormModels";
import {runConfigurationConfig} from "gui-common/runConfiguration/runConfigurationConstants";
console.log("importing runConfigurationConfig");

export function useRunConfigurationConfig(type) {
    return useMemo(
        () => {
            if (!type) return undefined;
            if (!runConfigurationConfig[type]) return undefined;
            return runConfigurationConfig[type];
        },
        [type]
    );
}

function getParentAndModel(thisEntity, thisModel) {
    if (!thisEntity || !thisModel) return;
    const parentProps = globalOrmConfig.parentProperties[thisModel];
    if (!parentProps) return;
    const parent = thisEntity[parentProps.property];
    if (!parent) return;
    return {parentObject: parent, parentModel: parentProps.ormModel};
}

function addRunConfigurations(entity, ormModel, runConfigurations) {
    if (entity.runConfiguration) {
        for (let type in entity.runConfiguration) {
            if (type === 'id') continue;
            if (runConfigurations[type]) continue; // If defined on one level, that level is the full definition to use. Do not seek higher in hierarchy.
            if (!Array.isArray(entity.runConfiguration[type])) continue;
            runConfigurations[type] = entity.runConfiguration[type].map(item => {
                return {
                    ...item,
                    fromOrmModel: item.fromOrmModel ? item.fromOrmModel : ormModel,
                    ownedByParent: true,
                    includedInRunConfiguration: true,
                }
            });
        }
    }
}

// This function takes an object, finds the parent and adds its run configuration and recursively merges the run configurations in the parent tree above
function findParentAndAddRunConfigurationElements(thisModelObject, thisOrmModel, parentRunConfigurations) {
    const parentAndModel = getParentAndModel(thisModelObject, thisOrmModel);
    if (!parentAndModel) return;
    addRunConfigurations(parentAndModel.parentObject, parentAndModel.parentModel, parentRunConfigurations)

    findParentAndAddRunConfigurationElements(parentAndModel.parentObject, parentAndModel.parentModel, parentRunConfigurations);
}

// This function takes a parent object, adds its run configuration and recursively merges the run configurations in the parent parent tree above
export function addParentRunConfigurationElements(parentModelObject, parentOrmModel, parentRunConfigurations) {
    if (!parentModelObject || !parentOrmModel) return;
    addRunConfigurations(parentModelObject, parentOrmModel, parentRunConfigurations)

    const nextParentAndModel = getParentAndModel(parentModelObject, parentOrmModel);
    if (!nextParentAndModel) return;

    addParentRunConfigurationElements(nextParentAndModel.parentObject, nextParentAndModel.parentModel, parentRunConfigurations);
}


const ormModelSortOrder = ["OperatingUnit", "Client", "LegalEntity", "LegalEntityUnit", "BankAccount", "BankAccountRelation"];

function compareParentRunConfigurationElement( a, b ) {
    if ( a.ownedByParent && !b.ownedByParent) return -1;
    if (!a.ownedByParent &&  b.ownedByParent) return  1;
    if (ormModelSortOrder.indexOf(a.fromOrmModel) < ormModelSortOrder.indexOf(b.fromOrmModel)) return -1;
    if (ormModelSortOrder.indexOf(a.fromOrmModel) > ormModelSortOrder.indexOf(b.fromOrmModel)) return  1;
    if (a.id < b.id) return -1;
    if (a.id > b.id) return  1;
    return 0;
}

export function createMergedRunConfigurationElements(startEntityModelObject, startOrmModel) {

    if (!startEntityModelObject || !startOrmModel) return [];

    let mergedRunConfigurations = {};

    findParentAndAddRunConfigurationElements(startEntityModelObject, startOrmModel, mergedRunConfigurations);

    if (!startEntityModelObject.runConfiguration) return mergedRunConfigurations;

    mergedRunConfigurations.id = startEntityModelObject.runConfiguration.id;

    // Now, we have extracted the aggregated run configuration from the parents, its time to merge with the local run configurations on the entity.
    for (let type in startEntityModelObject.runConfiguration) {
        if (!startEntityModelObject.runConfiguration[type]) continue;
        if (type === 'id') continue;

        if (!mergedRunConfigurations[type]) {
            mergedRunConfigurations[type] = startEntityModelObject.runConfiguration[type];
            continue;
        }

        mergedRunConfigurations[type].forEach(item => item.includedInRunConfiguration = false) // Reset all to not included and set to true if they exist in startEntityModelObject array


        const fromParentsTypeArray = mergedRunConfigurations[type];
        startEntityModelObject.runConfiguration[type].forEach(localItem => {
            const matchedParentItem = fromParentsTypeArray.find(item => {
                return (item.id === localItem.id);
            });
            if (matchedParentItem) {
                matchedParentItem.includedInRunConfiguration = true;
                return;
            }
            mergedRunConfigurations[type].push(localItem);
/*
            mergedRunConfigurations[type][matchedParentItemAtIndex] = {
                ...localItem,
                ownedByParent: true,
                includedInRunConfiguration: true
            }
*/
        })

        mergedRunConfigurations[type] = mergedRunConfigurations[type].sort(compareParentRunConfigurationElement);
        // startEntityModelObject.runConfiguration[type] = startEntityModelObject.runConfiguration[type].sort(compareParentRunConfigurationElement);
    }

    return mergedRunConfigurations;
}


export function checkConflictWithOtherElements(val, validator, props) {
    if (!props.allElements || !props.allElements.length) return false;
    const myself = props.allElements[props.index];

    return props.allElements.some((element, index) => {
        if (element.isDeleted) return false;
        if (element.ownedByParent && !element.includedInRunConfiguration) return false;
        if (index === props.index) return false; // this element should not be considered.
        return validator(val, element, myself, props.type);
    })
}
