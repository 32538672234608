import React from 'react';
import {withRouter} from "react-router-dom";
import 'gui-common/userRights/adminUserRights.css'
import {getUserSelector} from "gui-common/orm/ormSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {renderUserProfiles} from "gui-common/user/userFunctions";
import RenderUserDataRights from "gui-common/userRights/RenderUserDataRights";
import {useSelectorInstance} from "gui-common/functions/hooks";
import RenderDomainRights2 from "gui-common/userRights/RenderDomainRights2";

export function RenderUserProfiles(props) {
    if (!props.user) return null;
    return (
        <h4>
            <XpTranslated trKey='userPreferencesForm.profiles'    />
            {renderUserProfiles(props.user)}
        </h4>
    )
}

function ViewMyUserRights2(props) {
    const user         = useSelectorInstance(getUserSelector);
    return (
        <div>
            <h2><XpTranslated trKey={'adminUserRights.myRightsHeader'}/></h2>
            <p className="centeredText"><XpTranslated trKey={'adminUserRights.myRightsMessage'}/></p>
            <hr/>
            <div>
                <RenderUserProfiles user={user}/>
                {user.systemRights?.entitlements ?
                    <div className={'instanceUserRightsCard'}>
                        <h4><XpTranslated trKey='adminUserRights.systemRightsHeader'/></h4>
                        <RenderDomainRights2 // System rights
                            domainRights={user.systemRights?.entitlements}
                        />
                    </div>
                    :
                    <h3><XpTranslated trKey="adminUserRights.noAssignedSystemRights"/></h3>
                }
                <RenderUserDataRights
                    dataRights = {user.dataRights}
                />
            </div>
        </div>
    );
}

export default withRouter(ViewMyUserRights2);
