import React, {useCallback, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import {prepareMarketOrdersColumns} from "features/marketOrder/marketOrderFunctions";
import {selectMarketOrdersListData} from "features/marketOrder/marketOrderSelectors";

export const marketOrdersHiddenState = {
    id                      : false,
    agreementId             : true,
    legalEntityUnitName     : false,
    appId                   : true,
    currencyPairStr         : false,
    side                    : false,
    tenor                   : false,
    instructedSettlementDate: false,
    amount                  : false,
    amountCurrencyT         : false,
    externalId              : false,
    meansOfPayment          : true,
    createdDateTime         : false,
    uti                     : true,
    statusT                 : false,
    statusMessage           : true,
    executionTime           : false,
    executionRate           : false,
    counterAmount           : true,
    fxOrderId               : true,
    spotRate                : true,
    forwardPoints           : true,
    settlementDate          : false,
    marketOrderFileName     : true,
};
MarketOrdersList.detailIcon = 'swap_horizontal_circle';
MarketOrdersList.detailIconToolTipKey = 'marketOrdersList.detailIconToolTipKey';
export function MarketOrdersList ({itemSelectedCallback, instanceId, setHeightCallback, fillAvailableSpace, gridId, rowDataSelectorProps}) {

    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!itemSelectedCallback) return;
                itemSelectedCallback((data === undefined) ? undefined : data.id);
            },
            [itemSelectedCallback]),
        rowClassRules   : {
            'xp-grid-rejected-order-row' : function(params) { return !params.node.selected && (["Failed", "Unknown", "Cancelled", "Timeout"].includes(params.data.status))},
        },
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : instanceId,
                overlayNoRowsTemplate   : translate('marketOrdersList.noOrdersToShow'),
                fillAvailableSpace      : (fillAvailableSpace === undefined) ? true : fillAvailableSpace,
                groupDefaultExpanded    : 0,
                rowDataSelector         : selectMarketOrdersListData,
                rowDataSelectorProps    : rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'MarketOrder',
            };
        },
        [translate, rowDataSelectorProps]
    );
    const colDefs = useMemo(
        () => {
            return prepareMarketOrdersColumns(translate, marketOrdersHiddenState);
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={gridId ? gridId : "marketOrdersGrid-" + instanceId}
                {...gridCallbacks}
                {...gridOptions}
                columnDefs={colDefs}
                callingContext={this}
                setHeightCallback={setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}
