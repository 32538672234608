import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import 'gui-common/userRights/adminUserRights.css'
import {
    availableDataRightsSelector,
} from "gui-common/userRights/adminUserRightsSelectors";
import PropTypes from "prop-types";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import AdminPrototypeDataRights from "gui-common/userRights/AdminPrototypeDataRights";


export function getDataRightsPrototypes(dataRights) {
    if (!dataRights) {
        return undefined;
    }
    const includedEntityPrototypes = [];
    for (const dataRight of dataRights) {
        if (!includedEntityPrototypes.includes(dataRight.prototype)) includedEntityPrototypes.push(dataRight.prototype);
    }
    return includedEntityPrototypes.sort();
}

function AdminDataRightsCollection2(props) {

    const availableDataRights = useSelector(availableDataRightsSelector);

    const prototypesToRender = useMemo(
        () => {
            if (!props.activeDataRightsCollection?.dataRights) {
                // console.error("No activeDataRightsCollection to render!");
                return undefined;
            }
            const activePrototypes   = getDataRightsPrototypes(props.activeDataRightsCollection?.dataRights);
            const modifiedPrototypes = getDataRightsPrototypes(props.modifiedDataRightsCollection?.dataRights);
            const availablePrototypes  = getDataRightsPrototypes(availableDataRights);

            // This is needed since after-data might have an empty assigned array.
            if (props.auditMode) {
                for (const prototype of activePrototypes) {
                    if (modifiedPrototypes && !modifiedPrototypes.includes(prototype)) modifiedPrototypes.push(prototype);
                }
            }
            const prototypesToRender = modifiedPrototypes ? modifiedPrototypes : activePrototypes;
            if (!props.readOnly && availablePrototypes) {
                for (const prototype of availablePrototypes) {
                    if (!prototypesToRender.includes(prototype)) prototypesToRender.push(prototype);
                }
            }
            return prototypesToRender;
        },
        [props.activeDataRightsCollection, props.modifiedDataRightsCollection, availableDataRights, props.readOnly, props.auditMode]
    );

    if (!prototypesToRender) {
        return null;
    }
    if (!prototypesToRender?.length) {
        if (props.readOnly) return (<h3 style={{textAlign:'center'}}><XpTranslated trKey="adminUserRights.noAssignedDataRights"/></h3>)
        return (<h3 style={{textAlign:'center'}}><XpTranslated trKey="adminUserRights.noAssignableDataRightsForUser"/></h3>)
    }

    return (
        <div>
            {prototypesToRender.map((entityPrototypeName, index) => {return (
                <div key = {index}>
                    <AdminPrototypeDataRights
                        modifiedDataRightsCollection = {props.modifiedDataRightsCollection}
                        activeDataRightsCollection   = {props.activeDataRightsCollection}
                        availableDataRights          = {availableDataRights}
                        entityPrototypeName          = {entityPrototypeName}
                        auditMode                    = {props.auditMode}
                        readOnly                     = {props.readOnly || props.auditMode}
                    />
                </div>
            )})}
        </div>
    );
}
AdminDataRightsCollection2.propTypes = {
    dataRightsCollectionId : PropTypes.number,
};

export default AdminDataRightsCollection2;

export function AuditRenderDataRightsCollection(props) {

    const transformedBefore = useMemo(
        () => {
            return {dataRights: props.auditEntry?.data?.before}
        },
        [props.auditEntry?.data]
    );
    const transformedAfter = useMemo(
        () => {
            return {dataRights: props.auditEntry?.data?.after}
        },
        [props.auditEntry?.data]
    );

    if (!transformedBefore || !transformedAfter) {
        return null;
    }

    return (
        <AdminDataRightsCollection2
            activeDataRightsCollection   = {transformedBefore}
            modifiedDataRightsCollection = {transformedAfter}
            auditMode                    = {true}
            readOnly                     = {true}
        />
    )
}
