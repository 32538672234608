import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef} from 'react'
import {useDispatch, useSelector, useStore} from "react-redux";
import 'react-datepicker/dist/react-datepicker.css';
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {userCanDelete, userCanUpdate} from "gui-common/userRights/userRightsFunctions";
import {setFormButtonKey} from "gui-common/xpForm/formComponentsFunctions";
import {newXpAdminFormTemplateSelector} from "gui-common/xpAdminForm/xpAdminFormSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {ormModelLoadingNewOrUpdated} from "gui-common/orm/ormLoadingSelectors";
import {deleteOrmItemToApi} from "gui-common/orm/ormApi";
import {xpAdminFormCancelEditNew, xpAdminFormLoadNew} from "gui-common/xpAdminForm/xpAdminFormThunks";
import {CONFIRMATION_DIALOG, INFORMATION_DIALOG} from "gui-common/modals/modalConstants";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {XP_FORM_EDIT, XP_FORM_NEW, XP_FORM_VIEW} from "gui-common/xpForm/core/xpFormConstants";
import {
    xpFormApiClear,
    xpFormApiSubmit,
    xpFormLoadForm,
    xpFormRemoveForm,
    xpFormSubmitForm
} from "gui-common/xpForm/core/xpFormReducer";
import {entityIsActive, entityIsDisabled} from "gui-common/audit/auditFunctions";
import {getXpAdminFormListInstanceId} from "gui-common/xpAdminForm/XpAdminFormEntityList";
import {xpGridSetSelected} from "gui-common/xpGrid/xpGridsReducer";

const XpAdminFormButtons = forwardRef((props, ref) => {
    const propsRef = useRef(props); // Needed to transport updated hook to callback scope.
    propsRef.current = props;

    const dispatch        = useDispatch();
    const noFormValidation = useAppEnvProperty('noFormValidation');
    const newFormTemplate = useSelector(state => newXpAdminFormTemplateSelector(state, props));
    const ormModelLoading = useSelector(state => ormModelLoadingNewOrUpdated(state, {ormModel: props.ormModel}));
    const store = useStore();

    // The escape function reference must be memoized to make add and remove event listener to work properly
    const escFunction = useMemo(
        () => {
            return (event) => {
                if(event.keyCode === 27) {
                    cancelEditNew();
                }
            }
        },[],
    );
    useEffect(() => {
        if ((props.formUseState === XP_FORM_EDIT) || (props.formUseState === XP_FORM_NEW)) document.addEventListener("keydown", escFunction, false);
        if (props.formUseState === XP_FORM_VIEW) document.removeEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [props.formUseState]);

    function cancelEditNew() {
        document.removeEventListener("keydown", escFunction, false);

        dispatch(xpAdminFormCancelEditNew(
            props.formModel,
            propsRef.current.currentData,
            newFormTemplate,
            () => document.addEventListener("keydown", escFunction, false),
            () => {
                dispatch(xpFormRemoveForm(propsRef.current.formModel));
                // closeXpAdminForm(propsRef.current.xpAdminFormInstanceId, propsRef.current.ormModel, propsRef.current.entityData.form, dispatch);
                if (props.onCancelCallback) props.onCancelCallback();
            })
        )
    }


    function checkAndDisableEntity(entity) {
            if (!entity || !entity.id) return;

            function executeDisable() {
                dispatch(deleteOrmItemToApi(props.ormModel, entity))
                    .then(res => {
                        dispatch(xpFormApiClear(props.formModel));
                        const listInstanceId = getXpAdminFormListInstanceId(props.xpAdminFormInstanceId, props.ormModel);
                        dispatch(xpGridSetSelected(listInstanceId, undefined))
                        console.log("Successful delete: " + props.formModel + ': ', entity);
                    })
                    .catch(res => {
                        // dispatch(actions.reset(formModel));
                        dispatch(xpFormApiClear(props.formModel));
                        console.log("Failed delete: " + props.formModel + ': ', entity);
                    })
            }

            const checkBeforeDeleteFunction = props.xpAdminFormConfig[props.ormModel].checkBeforeDisableFunction;
            const disableParams = checkBeforeDeleteFunction ? checkBeforeDeleteFunction(entity, store) : undefined;

            if (props.xpAdminFormConfig[props.ormModel].getCustomDisableExecutor && !noFormValidation) {
                const customDisableExecutor = props.xpAdminFormConfig[props.ormModel].getCustomDisableExecutor(entity, store, executeDisable);
                if (customDisableExecutor) {
                    customDisableExecutor();
                    return;
                }
            }

            if ((disableParams && disableParams.canBeDisabled) || noFormValidation) {
                dispatch(pushModalWindow(CONFIRMATION_DIALOG,
                    {
                        modalKey   : disableParams.modalKey,
                        modalParams: disableParams.modalParams,
                    }))
                    .then(({status}) => {
                        if (status === MODAL_TYPE_CONFIRM) {
                            dispatch(xpFormApiSubmit(props.formModel));
                            executeDisable();
                        }
                        if (status === MODAL_TYPE_CANCEL) {}
                    });
            }
            else {
                dispatch(pushModalWindow(INFORMATION_DIALOG,
                    {
                        modalKey   : disableParams.modalKey,
                        modalParams: disableParams.modalParams,
                    }));
            }
    }

    function setEdit(rootObject) {
        document.addEventListener("keydown", escFunction, false);
        // dispatch(actions.setInitial(props.formModel));
        // dispatch(actions.reset(props.formModel));
        // dispatch(xpAdminFormLoad(props.xpAdminFormInstanceId, props.ormModel, rootObject));
        dispatch(xpFormLoadForm(  props.formModel, rootObject, XP_FORM_EDIT))
    }
    function setNew() {
        document.addEventListener("keydown", escFunction, false);
        dispatch(xpAdminFormLoadNew(props));
    }

    useImperativeHandle(ref, () => ({
            setNew: () => {
                setNew()
            },
            disableItem: () => {
                checkAndDisableEntity(propsRef.current.currentData);
            },
            setEdit: () => {
                setEdit(propsRef.current.currentData)
            },
            setEventListener: () => {
                document.addEventListener("keydown", escFunction, false);
            }
        }),
    )

    return (
        <div className="ormFormButtons">
            {((props.formUseState === XP_FORM_VIEW) && (userCanUpdate(props.currentData) || props.userCanEditEntity) && !props.hideEditButton) &&
            <WaitingXpoolButton
                xpTestId        = {"ormFormEditButton-" + props.ormModel}
                labelKey        = {setFormButtonKey(props, '.edit')}
                onClickCallback = {() => setEdit(props.currentData)}
                waiting={props.itemLoading}
            />}
            {((props.formUseState === XP_FORM_VIEW) && entityIsActive(props.currentData) && (userCanDelete(props.currentData, props.ormModel) || props.userCanDeleteEntity) && !props.hideDeleteButton) &&
            <WaitingXpoolButton
                xpTestId        = {"ormFormDisableButton-" + props.ormModel}
                labelKey        = {setFormButtonKey(props, '.disable')}
                onClickCallback = {() => checkAndDisableEntity(props.currentData)}
                waiting={props.itemLoading}
                floatRight
            />}
            {([XP_FORM_EDIT, XP_FORM_NEW].includes(props.formUseState)) &&
            <WaitingXpoolButton
                xpTestId        = {"ormFormCancelButton-"+props.ormModel}
                labelKey        = {entityIsDisabled(props.currentData) ? 'general.buttons.close' : setFormButtonKey(props, ".cancel")}
                onClickCallback = {cancelEditNew}
                waiting         = {ormModelLoading || props.itemLoading}
            />}
            {([XP_FORM_EDIT, XP_FORM_NEW].includes(props.formUseState)) &&
            <WaitingXpoolButton
                xpTestId        = {"ormFormSaveButton-"+props.ormModel}
                labelKey        = {setFormButtonKey(props, '.save')}
                onClickCallback = {() => dispatch(xpFormSubmitForm(props.formModel))}
                disabled        = {entityIsDisabled(props.currentData)}
                waiting         = {ormModelLoading || props.itemLoading}
                floatRight
            />}
        </div>
    );
})
export default XpAdminFormButtons


