import {userMessageTransformer, createUserMessageBody}  from "gui-common/userMessages/userMessagesFunctions"
import {addUserMessage, loadUserMessagesFromApi}        from "gui-common/userMessages/userMessagesReducer"
import {genericFetch}                                   from "gui-common/api/apiCommon";
import {ormEntitiesGetStarted} from "gui-common/orm/ormReducer";
import {ormEntityClearLoadAll} from "gui-common/orm/ormLoadingReducer";
import {globalApiHandle} from "gui-common/api/apiConstants";


function itemArrayTransformer(items) {
    let transformedItems = [];
    for (let item of items) {
        transformedItems.push(userMessageTransformer(item));
    }
    return transformedItems;
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to get user messages from BE including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getUserMessagesFromApi(fromDate) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get UserMessages";
            let requestUrl = globalApiHandle.baseUrls.userMessage + "?fromDate=" + fromDate + "&";

            dispatch(ormEntitiesGetStarted('UserMessage'));
            dispatch(genericFetch(
                requestUrl,
                logText,
                getResponseHandler,
                getFailHandler,
                getFailHandler,
                'GET',
                undefined, // no body in http request needed for GET.
                'UserMessage'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getResponseHandler(resp) {
    return (dispatch, getState) => {
        let transformedResponse = itemArrayTransformer(resp);
        dispatch(loadUserMessagesFromApi(transformedResponse));
        dispatch(ormEntityClearLoadAll('UserMessage'))
    }
}
function getFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntityClearLoadAll('UserMessage'))
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to add user message including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function postUserMessageToApi(item) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            const logText = "Post User Message";
            let requestUrl = globalApiHandle.baseUrls.userMessage + "?";
//            console.log('messagebody:', createUserMessageBody(item));
            dispatch(genericFetch(
                requestUrl,
                logText,
                postUserMessageResponseHandler,
                postUserMessageFailHandler,
                postUserMessageFailHandler,
                'POST',
                createUserMessageBody(item),
                'UserMessage'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function postUserMessageResponseHandler(resp) {
    return (dispatch, getState) => {
        // No action. User message will be received from web socket.
/*
        const message = userMessageTransformer(resp);
        dispatch(addUserMessage('UserMessage', {...message, requireConfirmation: true})); // When creating a user message in the GUI, it should be confirmed by the user.
*/
    }
}
function postUserMessageFailHandler(originalMessage) {
    return (resp) => {
        return (dispatch, getState) => {
            dispatch(addUserMessage('UserMessage', {...originalMessage, requireConfirmation: true})); // original message should be displayed to user even if send to API fails
        }
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to confirm a user message or a list of user messages including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/

const isInternal = (id) => {
    return (String(id).includes("internal"));
};


export function confirmUserMessageToApi(item) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let itemsToBeConfirmed =  [];
            if (Array.isArray(item)) { // The function can be called with a single user message or an array to be confirmed.
                for (let i =0; i < item.length; i++) {
                    if (isInternal(item[i].id)) continue;
                    itemsToBeConfirmed.push(item[i].id);
                }
            }
            else {
                if (isInternal(item.id)) return;
                itemsToBeConfirmed.push(item.id);
            }
            const logText = "Confirm User Messages ";
            let requestUrl = globalApiHandle.baseUrls.userMessage + "/confirm?";

            // return;
            dispatch(genericFetch(
                requestUrl,
                logText,
                putUserMessageResponseHandler,
                putUserMessageFailHandler,
                putUserMessageFailHandler,
                'PUT',
                itemsToBeConfirmed,
                'UserMessage'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function putUserMessageResponseHandler(resp) {
    return (dispatch, getState) => {
    }
}
function putUserMessageFailHandler(resp) {
    return (dispatch, getState) => {
    }
}

