import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {NavDropdown} from "react-bootstrap";
import englishFlag from "gui-common/resources/english.png";
import swedishFlag from "gui-common/resources/swedish.png";
import taiwanFlag from "gui-common/resources/taiwan.png";
import finnishFlag from "gui-common/resources/finnishFlag.gif";
import danishFlag from "gui-common/resources/danishFlag.gif";
import norwegianFlag from "gui-common/resources/norwegianFlag.gif";
import moment from "moment";
import {setActiveLanguageThunk} from "gui-common/appLocale/appLocaleFunctions";
import {
    useXpTranslatedActiveLanguage,
    useXpTranslatedLanguages
} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


const getFlag = (lang) => {
    switch (lang) {
        case "en": return englishFlag;
        case "sv": return swedishFlag;
        case "ch": return taiwanFlag;
        case "fi": return finnishFlag;
        case "dk": return danishFlag;
        case "no": return norwegianFlag;
        default:   return null;
    }
};
const getLanguage = (lang) => {
    switch (lang) {
        case "en": return "English";
        case "sv": return "Svenska";
        case "ch": return "Chinese";
        case "fi": return "Finnish";
        case "dk": return "Danish";
        case "no": return "Norwegian";
        default:   return null;
    }
};
function XpLanguageSelector (props) {

    const activeLanguage  = useXpTranslatedActiveLanguage();
    const languages       = useXpTranslatedLanguages();
    const dispatch        = useDispatch();

    function setLocale(code) {
        dispatch(setActiveLanguageThunk(code));
        moment.locale(code);
    }

    useEffect(
        () => {
            if (props.inMenu) {
                setTimeout(() => {
                    // This is idiotic. For some reason the menu lib sets focus on the lang selector. This code is to remove the focus. Looks ugly otherwise...
                    if (document.activeElement) document.activeElement.blur();
                }, 10)
            }
        },
        [],
    );

    if (!languages?.length) return null;

    if (props.inMenu) {
        return (
            <NavDropdown
                title={<span><img src={getFlag(activeLanguage)} alt={getLanguage(activeLanguage)}
                                  className="flagImage"/></span>}
                id="user-nav-dropdown"
                xp-test-id="mainMenuLocale"
                alignRight
            >
                {languages.map(language =>
                    <NavDropdown.Item key={language.code} onClick={() => setLocale(language.code)}>
                        <img className="flagImage flagImageInMenu" src={getFlag(language.code)} alt={language.name}/>
                        <span className="languageItem">{language.name}</span>
                    </NavDropdown.Item>
                )}
            </NavDropdown>
        )
    }
    return (
        <span>
                {languages.map(language =>
                    <span key={language.code} className={activeLanguage === language.code ? "activeLanguageItemButton" : "languageItemButton"} onClick={() => setLocale(language.code)}>
                        {language.name}
                    </span>
                )}
        </span>
    )
}
export default XpLanguageSelector



