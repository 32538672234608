import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {XpDateTime} from "gui-common/components/XpDateTime";
import {DisplayOperatingUser} from "gui-common/components/DisplayOperatingUser";
import XpTooltip from "gui-common/components/XpTooltip";
import {pushModalWindow} from "redux-promising-modals";
import {SHOW_AUDIT_HISTORY_DIALOG} from "gui-common/modals/modalConstants";
import {useDispatch} from "react-redux";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getOrmModelItemSelector} from "gui-common/orm/ormSelectors";
import {getLastChange} from "gui-common/audit/auditFunctions";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";


function ShowAuditInfo (props) {
    const auditedEntityOrmModelObject = useSelectorInstance(getOrmModelItemSelector, {selectId: props.auditedObject.id, ormModel: props.ormModel});
    const dispatch = useDispatch();
    const auditConfig = useAppEnvProperty( 'auditConfig');

    const lastChange = useMemo(
        () => {
            if (props.auditMode) return undefined;
            if (!auditedEntityOrmModelObject) return undefined;
            return getLastChange(props.ormModel, auditedEntityOrmModelObject);
        },
        [auditedEntityOrmModelObject]
    );

    function onDoubleClick(event) {
        if (!auditConfig?.activateObjectAuditLog) return;
        dispatch(
            pushModalWindow(SHOW_AUDIT_HISTORY_DIALOG, {auditedObject : props.auditedObject, ormModel: props.ormModel}))
            .then((result) => {});
    }

    if (props.bypass) return  null;
    return (
        <div>
            <div className="dateTimeFormSection">
                <div>
                    <label>
                        <XpTranslated trKey={'general.createdDateTime'}/>
                        <XpDateTime isoString={props.auditedObject.createdDateTime} format='lll'/>
                        {props.auditedObject.createdByUser && <DisplayOperatingUser userIdString={props.auditedObject.createdByUser}/>}
                    </label>
                </div>

                {lastChange?.changedDateTime &&
                <div>
                    <label onDoubleClick={(e) => onDoubleClick(e)}>
                        <XpTooltip trKey={'showAuditInfo.changedTooltip'} bypass={!auditConfig?.activateObjectAuditLog}>
                            <span>
                                <XpTranslated trKey={'general.changedDateTime'}/>
                                <XpDateTime isoString={lastChange.changedDateTime} format='lll'/>
                            </span>
                        </XpTooltip>
                        {
                            lastChange.changedByUser ?
                                <DisplayOperatingUser userIdString={lastChange.changedByUser}/>
                                :
                                <XpTranslated trKey={'general.changedBySystem'}/>
                        }
                    </label>
                </div>}

            </div>
        </div>
    )
}
ShowAuditInfo.propTypes = {
    auditedObject: PropTypes.object.isRequired,
    ormModel     : PropTypes.string.isRequired,
    bypass       : PropTypes.bool
};
export default ShowAuditInfo



