import React from 'react'
import PropTypes from 'prop-types';
import {useXpFormContext} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";
import {editBaseUsed, xpFormUserCanEditBase} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import ShowAuditInfo from "gui-common/audit/ShowAuditInfo";

function EntityBaseStaticFormComponent(props) {

    const formContext     = useXpFormContext();

    function fieldIsDisabled() {
        if (props.enforceUseCanEdit) return false;
        if (!editBaseUsed(props)) return false;
        return !xpFormUserCanEditBase(formContext, props.ormModel, props.isSubForm);
    }

    if (props.isSubForm) return null;

    return (
        <div className={props.className}>
            <XpFormTextInput
                field         = "name"
                disabled      = {fieldIsDisabled()}
                isRequired    = {true}
                autoFocus     = {!props.suppressNameAutofocus}
                maxLength     = {50}
            />
            <XpFormTextAreaInput
                field         = "description"
                disabled={fieldIsDisabled()}
                maxLength={200}
            />
            {((formContext.formUseState !== XP_FORM_NEW) && (formContext.currentData)) &&
            <div className="dateTimeFormSection">
                <ShowAuditInfo auditedObject={formContext.currentData} ormModel={props.ormModel} auditMode={props.auditMode}/>
            </div>}
        </div>
    )
}
EntityBaseStaticFormComponent.propTypes = {
    className:        PropTypes.string,
    isSubForm:        PropTypes.bool,
    useStaticObject:  PropTypes.bool,
};

EntityBaseStaticFormComponent.defaultProps = {
    className:  undefined,
    isSubForm:  false,
};
export default EntityBaseStaticFormComponent;
