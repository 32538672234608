import {genericFetch}                                   from "gui-common/api/apiCommon";
import {entityStateRequestedBulk} from "gui-common/requestEntityState/requestEntityStateReducer";
import {
    addRequestStateIfExpected,
    getRequestStatesOnListRates, setRequestedStateOnListRates
} from "features/listRate/listRateApi";
import {globalApiHandle} from "gui-common/api/apiConstants";

const actionToUrlMap = {
    'suspend': 'suspend',
    'resume' : 'resume',
}

function setRequestedStateOnAgreements(action, agreements, dispatch) {
    let stateRequestArray = [];

    for (let agreement of agreements) {
        addRequestStateIfExpected('Agreement', agreement, action, stateRequestArray);
    }
    dispatch(entityStateRequestedBulk(stateRequestArray));
}

export function enrichUrlAndLogWithId(url, log, filter) {
    if (!filter || !filter.id) return [url, log];
    let returnUrl = url;
    let returnLog = log;
    returnUrl += filter.id + '/';
    returnLog += '. Id: ' + filter.id;
    return [returnUrl, returnLog];
}
export function enrichUrlAndLogWithParameter(url, log, filter, ulrName, propName) {
    if (!filter || !filter[propName]) return [url, log];
    let returnUrl = url;
    let returnLog = log;
    returnUrl += ulrName + '=' + filter[propName] + '&';
    returnLog += '. ' + ulrName + ': ' + filter[propName];
    return [returnUrl, returnLog];
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to operate with actions on Agreements with filter parameters.
* -----------------------------------------------------------------------------------------------------------------*/
export function putAgreementActionToApi(action, filterParams, agreements, listRates, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!action) {
                console.error("No action in putAgreementActionToApi");
                return;
            }
            if (!actionToUrlMap[action]) {
                console.error("Unsupported action in putAgreementActionToApi", action);
                return;
            }

            let logText = "Put agreement action :" + actionToUrlMap[action];
            let requestUrl = globalApiHandle.baseUrls.Agreement + '/';

            [requestUrl, logText] = enrichUrlAndLogWithId(requestUrl, logText, filterParams)

            requestUrl += actionToUrlMap[action] + '?';

            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'agreement-type'        , 'type');
            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'currency-id'           , 'currencyId');
            [requestUrl, logText] = enrichUrlAndLogWithParameter(requestUrl, logText, filterParams, 'legal-entity-unit-id'  , 'legalEntityUnitId');

            dispatch(genericFetch(
                requestUrl,
                logText,
                responseHandler(action, agreements, listRates),
                failHandler(action, agreements, listRates),
                failHandler(action, agreements, listRates),
                'PUT',
                {comment: comment},
            )).then(result => {
                resolve(result)
            }).catch(err => {
                // reject(err)
            })
        })
    }
}


function responseHandler(action, agreements, listRates) {
    return (resp) => {
        return (dispatch, getState) => {
            setRequestedStateOnListRates(action, listRates, dispatch);
            setRequestedStateOnAgreements(action, agreements, dispatch);
        }
    }
}
function failHandler(action, agreements, listRates) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}
