import {entityStateRequested} from "gui-common/requestEntityState/requestEntityStateReducer";
import {genericFetch} from "gui-common/api/apiCommon";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {globalApiHandle} from "gui-common/api/apiConstants";

/* -----------------------------------------------------------------------------------------------------------------
* Functions to set a trading limit override on a legal entity unit.
* -----------------------------------------------------------------------------------------------------------------*/
export function tradingLimitOverrideToApi(legalEntityUnit, todayLimitOverride, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!legalEntityUnit || !todayLimitOverride) {
                console.error("Incorrect parameters provided to tradingLimitOverrideToApi", legalEntityUnit, todayLimitOverride);
                return;
            }

            let logText = "Put trading limit override. Leu id: " + legalEntityUnit.id + ". New limit: " + todayLimitOverride + ". Comment: " + comment + ".";
            let requestUrl = globalApiHandle.baseUrls.LegalEntityUnit + '/' + legalEntityUnit.id + '/limit-override?';

            const limitOverrideNumber = stringNumberToFloat(todayLimitOverride, selectDecDenLangState(getState()));

            dispatch(entityStateRequested('LegalEntityUnit', legalEntityUnit.id, limitOverrideNumber, 'todayLimitOverride'));

            dispatch(genericFetch(
                requestUrl,
                logText,
                responseHandler(legalEntityUnit, limitOverrideNumber),
                failHandler(legalEntityUnit, limitOverrideNumber),
                failHandler(legalEntityUnit, limitOverrideNumber),
                'PUT',
                {todayLimitOverride: limitOverrideNumber, comment: comment},
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}


function responseHandler(legalEntityUnit, limitOverrideNumber) {
    return (resp) => {
        return (dispatch, getState) => {
//            dispatch(entityStateRequested('LegalEntityUnit', legalEntityUnit.id, limitOverrideNumber, 'todayLimitOverride'));
        }
    }
}
function failHandler(legalEntityUnit, limitOverrideNumber) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}
