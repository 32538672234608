import React, {useCallback, useMemo, useRef} from 'react';

import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {getUsersToAdminDataSelector} from "gui-common/user/userSelectors";
import {prepareRightsUserGridColumns} from "gui-common/user/userFunctions";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function UserList(props) {

    const translate     = useXpTranslateFunction();
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback(
            (data) => {
                if (!props.itemSelectedCallback) return;
                if (data === undefined) props.itemSelectedCallback(undefined);
                else props.itemSelectedCallback(data.id);
            },
            [props.itemSelectedCallback]),
        gridContextMenuItems    : useCallback((params) => [], []),
        rowClassRules   : {},
    };
    const gridOptions = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                id:                   false,
                countryCode:          true,
                firstName:            false,
                lastName:             false,
                profilesStrT:         false,
                stateT:               false,
                socialSecurityNumber: true,
            };

            return {
                getRowDataSelector      : getUsersToAdminDataSelector,
                rowDataSelectorProps    : props.rowDataSelectorProps,
                instanceId              : props.instanceId,
                columnDefs              : prepareRightsUserGridColumns(translateRef.current, hiddenState),
                overlayNoRowsTemplate   : translate('rightsUserList.noUsersToShow'),
                fillAvailableSpace      : false,
                groupDefaultExpanded    : 0,
                treeData                : false,
                masterDetail            : false,
                suppressRowClickSelection: props.suppressRowClickSelection,
                ormModel                : 'User',
            };
        },
        [translate]
    );

    return (
        <div style={{width: "100%", height: "100%"}}>
            <XpGrid
                gridId={"usersGrid-" + props.instanceId}
                {...gridCallbacks}
                {...gridOptions}
                callingContext={this}
                setHeightCallback={props.setHeightCallback}
            >
            </XpGrid>
        </div>
    );
}

export default UserList;
