import {ormItemArrayTransformer} from "gui-common/orm/ormApi";
import {getNestedIdProperty, setParentId} from "gui-common/functions/functions";
import {createTranslatedFieldData} from "gui-common/xpGrid/xpGridFunctions";

export function fxTradeReportDeliveryIncomingTransformer(item, params) {
    setParentId(item, 'agreementId', params ? params.parentId : null);
    if (item.fxTradeReportEvents) {
        item.fxTradeReportEventsTmp   = ormItemArrayTransformer('FxTradeReportEvent', item.fxTradeReportEvents, {...params, parentId: item.id});
        item.fxTradeReportEvents = item.fxTradeReportEvents.map(item => item.id); // This is needed for the many-to-many ORM relation
    }
    return {...item}
}
export function fxTradeReportEventIncomingTransformer(item, params) {
    setParentId(item, 'deliveryId', params ? params.parentId : null);

    if (item.fxOrderReport) {
        item.fxOrderReportId = getNestedIdProperty(item, 'fxOrderReport');
        item.fxOrderReport   = ormItemArrayTransformer('FxOrderReport', [item.fxOrderReport], {...params, parentId: item.id});
    }
    if (item.relatedFxOrderReport) {
        item.relatedFxOrderReportId = getNestedIdProperty(item, 'relatedFxOrderReport');
        item.relatedFxOrderReport   = ormItemArrayTransformer('FxOrderReport', [item.relatedFxOrderReport], {...params, parentId: item.id});
    }
    const extraProps = params?.propsFromParent ? params.propsFromParent : {};
    return {
        ...extraProps,
        ...item
    };
}
export function fxOrderReportIncomingTransformer(item, params) {
    if (item.fxExecutionLegs) {
        item.fxExecutionLegs = ormItemArrayTransformer('FxExecutionLeg', item.fxExecutionLegs, {...params, parentId: item.id});
    }
    const extraProps = params?.propsFromParent ? params.propsFromParent : {};
    return {
        ...extraProps,
        ...item
    };
}

export function fxExecutionLegIncomingTransformer(item, params) {
    setParentId(item, 'fxOrderReportId', params ? params.parentId : null);
    if (item.fxExecutionReport) {
        item.fxExecutionReportId = getNestedIdProperty(item, 'fxExecutionReport');
        item.fxExecutionReport   = ormItemArrayTransformer('FxExecutionReport', [item.fxExecutionReport], {...params, parentId: item.id});
    }
    const extraProps = params?.propsFromParent ? params.propsFromParent : {};
    return {
        ...extraProps,
        ...item
    };
}

export function fxExecutionReportIncomingTransformer(item, params) {
    return item;
}

export function enrichFxOrderReportListItem(orderReport, translate) {
    createTranslatedFieldData(orderReport, 'productType' , translate, ("fxOrderReportList.headers.productType.values."  + orderReport.productType  ));
    createTranslatedFieldData(orderReport, 'orderStatus' , translate, ("fxOrderReportList.headers.orderStatus.values."  + orderReport.orderStatus  ));
    createTranslatedFieldData(orderReport, 'reportStatus', translate, ("fxOrderReportList.headers.reportStatus.values." + orderReport.reportStatus ));
    return orderReport;
}
export function enrichFxTradeReportEventListItem(eventModel, translate) {
    const event = eventModel.ref;
    event.orderId     = eventModel.fxOrderReportId?.ref?.backOfficeId;
    event.productType = eventModel.fxOrderReportId?.ref?.productType;
    createTranslatedFieldData(event, 'eventType'   , translate, ("fxTradeReportEventsList.headers.eventType.values."  + event.eventType  ));
    createTranslatedFieldData(event, 'productType' , translate, ("fxOrderReportList.headers.productType.values."  + event.productType  ));
    return event;
}

