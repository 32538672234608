import {attr, fk, many, Model, oneToOne} from 'redux-orm';

export const refixNestedOrmEntities = {
    Client: [
        {
            property: "legalEntities",
            model: "LegalEntity",
            useSubEntityProp: 'useLegalEntities',
            nestedRightRequiredForWizard: true,
            nestedRightRequiredForAdmin : true,
        }
    ],
    LegalEntity: [
        {
            property: "legalEntityUnits",
            model: "LegalEntityUnit",
            useSubEntityProp: 'useLegalEntityUnits',
            nestedRightRequiredForWizard: true,
            nestedRightRequiredForAdmin : true,
        }, {
            property: "fxTradeReportErpConfiguration",
            model: "FxTradeReportErpConfig",
        }
    ],
    LegalEntityUnit: [
        {
            property: "agreements",
            model: "Agreement",
            nestedRightRequiredForWizard: true,
            nestedRightRequiredForAdmin : false,
        }, {
            property: "fxOrderReports",
            model: "FxOrderReport",
        }, {
            property: "fxOrderReports",
            model: "FxOrderReport",
        }
    ],
    FxTradeReportErpConfig: [
        {
            property: "erpIntegration",
            model: "ErpIntegration",
        }
    ],
    Agreement: [
        {
            property: "listRates",
            model: "ListRate",
        }, {
            property: "marketOrders",
            model: "MarketOrder",
        }, {
            property: "fxTradeReportDeliveries",
            model: "FxTradeReportDelivery",
        }
    ],
    FxTradeReportDelivery: [
        {
            property: "fxTradeReportEventsTmp", // Since this is a many-to-many relation, this property is used to inject the events in the generic nested ORM handling.
            model: "FxTradeReportEvent",
        }
    ],
    ListRate: [
        {
            property: "dispatchTrades",
            model: "DispatchTrade",
        }
    ],
    DispatchTrade: [
        {
            property: "listRateOrders",
            model: "ListRateOrder",
        }
    ],
    ListRateOrder: [
        {
            property: "listRateOrderAllocations",
            model: "ListRateOrderAllocation",
        },
    ],
    ListRateOrderResult: [
        {
            property: "listRateOrder",
            model: "ListRateOrder",
        },
        {
            property: "listRate",
            model: "ListRate",
        },
        {
            property: "dispatchTrade",
            model: "DispatchTrade",
        },
        {
            property: "legalEntityUnit",
            model: "LegalEntityUnit",
        }
    ]
};


export const refixOrmModelParentProperties = {
    Agreement               : {property: 'legalEntityUnitId'  , ormModel: 'LegalEntityUnit'},
    ListRate                : {property: 'agreementId'        , ormModel: 'Agreement'},
    DispatchTrade           : {property: 'listRateId'         , ormModel: 'ListRate'},
    ListRateOrder           : {property: 'dispatchTradeId'    , ormModel: 'DispatchTrade'},
    ListRateOrderAllocation : {property: 'listRateOrderId'    , ormModel: 'ListRateOrder'},
};

export const refixOrmModelCompareFunctions = {
    Agreement         : (resp, form) => {
        if (resp.type === 'ListRateAgreement') {
            if (form.type !== resp.type) {
                return false;
            }
            if (form.currencyPairId !== resp.currencyPair.id) {
                return false;
            }
            return true;
        }
        if (resp.type === 'MarketOrderAgreement') {
            if (form.type !== resp.type) {
                return false;
            }
            if (form.currencyPairId !== resp.currencyPair.id) {
                return false;
            }
            return true;
        }
        if (resp.type === 'MarketOrderAllAgreement') {
            if (form.type !== resp.type) {
                return false;
            }
            return true;
        }
        if (resp.type === 'TradeReportAgreement') {
            if (form.type !== resp.type) {
                return false;
            }
            if (form.fxShortName !== resp.fxShortName) {
                return false;
            }
            return true;
        }
        if (resp.type === 'ReferenceRateAgreement') {
            if (Number(form.priceFeedId)   !== resp.priceFeedId) return false;
            if (Number(form.marginGroupId) !== resp.marginGroupId) return false;
            return true;
        }
        if (resp.type === 'PriceFeedAgreement') {
            return (form.targetTopic === resp.targetTopic);
        }
    }
};

class Client extends Model {}
Client.modelName = 'Client';
Client.fields = {
    id:                     attr(),
    name:                   attr(),
    description:            attr(),
    accountManager:         attr(),
    operatingUnitId:        fk('OperatingUnit', 'includedClients'),
    clientRefId:            attr(),
    useLegalEntities:       attr(),
    clientApiChannel:       attr(),
    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdClients'),
    changedDateTime:        attr(),
    changedByUser:          fk('User',  'changedClients'),
};

class LegalEntity extends Model {}
LegalEntity.modelName = 'LegalEntity';
LegalEntity.fields = {
    id:                     attr(),
    clientId:               fk('Client', 'legalEntities'),
    name:                   attr(),
    description:            attr(),
    countryId:              fk('Country'),
    lei:                    attr(),
    useLegalEntityUnits:    attr(),
    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdLegalEntities'),
    changedDateTime:        attr(),
    changedByUser:          fk('User',  'changedLegalEntities'),
};

class LegalEntityUnit extends Model {}
LegalEntityUnit.modelName = 'LegalEntityUnit';
LegalEntityUnit.fields = {
    id:                     attr(),
    legalEntityId:          fk('LegalEntity', 'legalEntityUnits'),
    marginGroupId:          fk('MarginGroup', 'legalEntityUnits'),
    marginGroupAssigned:    attr(),
    name:                   attr(),
    description:            attr(),
    fxEnabled:              attr(),
    fixSessionId:           fk('FixSession', 'consumers'),
    fxShortName:            attr(),
    tradingLimit:           attr(),
    tradingLimitCurrencyId: attr(),
    netTradingLimit:        attr(),
    priceFeedOnly:          attr(),
    exemptFromGlobalSuspend: attr(),
    netDispatchTrades:      attr(),
    sendToAccounting:       attr(),

    createdDateTime:        attr(),
    createdByUser:          fk('User', 'createdLegalEntityUnits'),
    changedDateTime:        attr(),
    changedByUser:          fk('User',  'changedLegalEntityUnits'),
};
class Agreement extends Model {}
Agreement.modelName = 'Agreement';
Agreement.fields = {
    id                  : attr(),
    legalEntityUnitId   : fk('LegalEntityUnit' , 'agreements'),
    type                : attr(),
    marginGroupId       : fk('MarginGroup', 'agreements'),
    // Many fields here that will be depending on the agreement type.
    createdDateTime     : attr(),
    createdByUser       : fk('User', 'createdAgreements'),
    changedDateTime     : attr(),
    changedByUser       : fk('User',  'changedAgreements'),
};

class ListRate extends Model {}
ListRate.modelName = 'ListRate';
ListRate.fields = {
    id               : attr(),
    agreementId      : fk('Agreement'     , 'listRates'),
    currencyPairId   : fk('CurrencyPair'  , 'listRates'),
    tenor            : attr(),
    listRatePeriodId : fk('ListRatePeriod', 'listRates'),
    dispatchTradePeriodId : fk('DispatchPeriod', 'listRates'),
    buySellType      : attr(),
    dispatchLevel    : attr(),
    status           : attr(),
    timeIssued       : attr(),
    timeExpire       : attr(),
    nextDispatchTime : attr(),
    originalExpire   : attr(),
    buyPrice         : attr(),
    sellPrice        : attr(),
    buyAmount        : attr(),
    sellAmount       : attr(),
    totalBuyAmount   : attr(),
    totalSellAmount  : attr(),
};

class ListRateOrder extends Model {}
ListRateOrder.modelName = 'ListRateOrder';
ListRateOrder.fields = {
    id                  : attr(),
    dispatchTradeId     : fk('DispatchTrade'  , 'listRateOrders'),
    listRateId          : fk('ListRate', 'listRateOrders'),
    legalEntityUnitId   : fk('LegalEntityUnit', 'listRateOrders'),
    amount              : attr(),
    amountCurrency      : attr(),
    side                : attr(),
    meansOfPayment      : attr(),
    clientReference     : attr(),
    counterAmount       : attr(),
    rate                : attr(),
    settlementDate      : attr(),
    instructedTime      : attr(),
    status              : attr(),
    statusMessage       : attr(),
};
class ListRateOrderAllocation extends Model {}
ListRateOrderAllocation.modelName = 'ListRateOrderAllocation';
ListRateOrderAllocation.fields = {
    id                  : attr(),
    listRateOrderId     : fk('ListRateOrder', 'listRateOrderAllocations'),
    allocationAmount    : attr(),
    customerRst         : attr(),
    customerBankId      : attr(),
    customerNumber      : attr(),
    clientAllocationReference: attr(),
};

class DispatchTrade extends Model {}
DispatchTrade.modelName = 'DispatchTrade';
DispatchTrade.fields = {
    listRateId:             fk('ListRate'   , 'dispatchTrades'),
    id:                     attr(), // The listrate system dispatch trade identifier, called dispatchTradeId externally
    openAmount:             attr(), // Denominated in base currency with two padded decimals
    dispatchedAmount:       attr(), // Denominated in base currency with two padded decimals
    side:                   attr(), // BUY or SELL
    status:                 attr(),
    tradeTime:              attr(), // Instruction date and time of the trade
    settlementDate:         attr(), // Settlement date of the forward leg in the trade
    counterparty:           attr(), // Client short name from the client configuration (must be stored on order since the config can change)
    clientSpotRate:         attr(), // The client's spot rate incl. margin and risk premium when the listrate was issued. No fwd points.
    refSpotRate:            attr(), // IB spot rate when the listrate was issued
    riskPremium:            attr(), // Listrate risk premium when listrate was issued
    clientFwdPoints:        attr(), // Fwd points including client margin (at sportrate issue)
    refFwdPoints:           attr(), // IB fwd points when the listrate was issued
    ibSpotRate:             attr(), // IB spot rate when the dispatch trade was created
    spotMargin:             attr(),
    ibFwdPoints:            attr(), // IB fwd points when the dispatch trade was created
    fwdPointsDiff:          attr(), // refFwdPoints – ibFWDPoints for BUY. Opposite for SELL
    fwdPointsMargin:        attr(),
    refSpotRateRPpts:       attr(),
    bookingReference:       attr(), // Booking reference from backoffice system
    bookingReferenceOrigin: attr(), // Source ID of backoffice system
};
class MarketOrder extends Model {}
MarketOrder.modelName = 'MarketOrder';
MarketOrder.fields = {
    id                  : attr(),
    agreementId         : fk('Agreement'      , 'marketOrders'),
    legalEntityUnitId   : fk('LegalEntityUnit', 'marketOrders'),
    currencyPair        : attr(),
    side                : attr(),
    tenor               : attr(),
    instructedSettlementDate : attr(),
    amount              : attr(),
    amountCurrency      : attr(),
    externalId          : attr(),
    meansOfPayment      : attr(),
    instructedTime      : attr(),
    uti                 : attr(),
    status              : attr(),
    statusMessage       : attr(),
    executionTime       : attr(),
    executionRate       : attr(),
    counterAmount       : attr(),
    fxOrderId           : attr(),
    spotRate            : attr(),
    forwardPoints       : attr(),
    settlementDate      : attr(),
};

class ListRatePeriod extends Model {}
ListRatePeriod.modelName = 'ListRatePeriod';
ListRatePeriod.fields = {
    id      : attr(),
    period  : attr(),
    enabled : attr(),
};
class DispatchPeriod extends Model {}
DispatchPeriod.modelName = 'DispatchPeriod';
DispatchPeriod.fields = {
    id      : attr(),
    period  : attr(),
    enabled : attr(),
};
class PriceFeed extends Model {}
PriceFeed.modelName = 'PriceFeed';
PriceFeed.fields = {
    id              : attr(),
    name            : attr(),
    description     : attr(),
    priceFeedType   : attr(),
    default         : attr(),
    sortIndex       : attr(),
    account         : attr(),
    tenor           : attr(),
    openExchangeKey : attr(),
};
class MarginGroup extends Model {}
MarginGroup.modelName = 'MarginGroup';
MarginGroup.fields = {
    id                      : attr(),
    name                    : attr(),
    description             : attr(),
    default                 : attr(),
    sortIndex               : attr(),
    bidMarginBps            : attr(),
    askMarginBps            : attr(),
    priceType               : attr(),
    priceMarginOverrides    : attr(), // array with priceMargin objects
    createdDateTime         : attr(),
    createdByUser           : fk('User', 'createdMarginGroups'),
    changedDateTime         : attr(),
    changedByUser           : fk('User',  'changedMarginGroups'),
};
class PriceTolerances extends Model {}
PriceTolerances.modelName = 'PriceTolerances'
PriceTolerances.fields = {
    id                                  : attr(),
    currencyPairId                      : attr(),
    name                                : attr(),
    numberOfAgreements                  : attr(),
    description                         : attr(),
    startBusinessHours                  : attr(),
    endBusinessHours                    : attr(),
    priceToleranceInsideBusinessHours   : attr(),
    priceToleranceOutsideBusinessHours  : attr()
}
class ListRateOrderResult extends Model {}
ListRateOrderResult.modelName = 'ListRateOrderResult';
ListRateOrderResult.fields = {
    listRateOrder           : attr(),
    listRate                : attr(),
    dispatchTrade           : attr(),
    legalEntityUnit         : attr(),
};

class FxTradeReportDelivery extends Model {}
FxTradeReportDelivery.modelName = 'FxTradeReportDelivery';
FxTradeReportDelivery.fields = {
    id: attr(),
    type: attr(),
    status: attr(),
    createdDateTime:        attr(),
    deliveryDateTime:       attr(),
    scheduledDeliveryDateTime: attr(),
    deliveryMethod: attr(),
    fxTradeReportEvents: many('FxTradeReportEvent', 'deliveries'),
    tradeReportAgreementId: fk('Agreement', 'fxTradeReportDeliveries'),
};

class FxTradeReportErpConfig extends Model {}
FxTradeReportErpConfig.modelName = 'FxTradeReportErpConfig';
FxTradeReportErpConfig.fields = {
    id: attr(),
    erpIntegrationId: fk('ErpIntegration', 'fxTradeReportErpConfig'),
    currencyConfigurations: attr(),
    legalEntityId: oneToOne('LegalEntity', 'fxTradeReportErpConfiguration'),

    balanceAccount: attr(),
    profitAccount: attr(),
    lossAccount: attr(),
    voucherSeries: attr(),
    voucherPeriod: attr(),
    balancePeriod: attr(),
    accountingPrinciple: attr(),
    valuationPrinciple: attr(),

    createdDateTime         : attr(),
    createdByUser           : fk('User', 'createdFxTradeReportErpConfig'),
    changedDateTime         : attr(),
    changedByUser           : fk('User',  'changedFxTradeReportErpConfig'),
};

export const refixOrmModels = [
    Client,
    LegalEntity,
    LegalEntityUnit,
    Agreement,
    ListRate,
    ListRatePeriod,
    DispatchPeriod,
    ListRateOrder,
    ListRateOrderAllocation,
    DispatchTrade,
    MarketOrder,
    PriceFeed,
    MarginGroup,
    PriceTolerances,
    ListRateOrderResult,
    FxTradeReportDelivery,
    FxTradeReportErpConfig,
];
