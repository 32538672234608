import {createReducer} from 'gui-common/functions/reducer.js'
import cloneDeep from "lodash/cloneDeep";

export const REGISTER_EVENT_FUNCTION   = 'REGISTER_EVENT_FUNCTION';
export const UNREGISTER_EVENT_FUNCTION = 'UNREGISTER_EVENT_FUNCTION';

// Action creators ********************************************************
// ************************************************************************
export function registerEventFunction(subscriberFunction, event) {
    return {
        type: REGISTER_EVENT_FUNCTION,
        payload: {subscriberFunction, event}
    };
}
export function unregisterEventFunction(subscriberFunction, event) {
    return {
        type: UNREGISTER_EVENT_FUNCTION,
        payload: {subscriberFunction, event}
    };
}

// Reducer functions ********************************************************
// ************************************************************************

function refInArray(arr, ref) {
    if (arr?.length) {
        for (const fnRef of arr) {
            if (fnRef === ref) {
                return true;
            }
        }
    }
    return false;
}

function removeRefFromArray(arr, ref) {
    if (!arr?.length) {
        return arr;
    }
    const retArray = [];
    for (const fnRef of arr) {
        if (fnRef === ref) {
            continue;
        }
        retArray.push(fnRef);
    }
    return retArray;
}

function register(state, payload) {
    if (!payload.event || !payload.subscriberFunction) {
        console.warn("Register event function called without event or function", payload);
        return state;
    }
    if (refInArray(state[payload.event], payload.subscriberFunction)) {
        return state;
    }
    const newState = cloneDeep(state);

    if (!newState[payload.event]) {
        newState[payload.event] = [payload.subscriberFunction];
    }
    else {
        newState[payload.event].push(payload.subscriberFunction);
    }
    return newState;
}

function unregister(state, payload) {
    if (!payload.subscriberFunction) {
        console.warn("Unregister event function called without function", payload);
        return state;
    }
    if (payload.event) {
        if (!state[payload.event]) {
            return state;
        }
        if (!refInArray(state[payload.event], payload.subscriberFunction)) {
            return state;
        }
        const newState = cloneDeep(state);
        newState[payload.event] = removeRefFromArray(state[payload.event], payload.subscriberFunction);
        if (!newState[payload.event].length) {
            delete newState[payload.event];
        }
        return newState;
    }
    else {
        const inEventLists = [];
        for (const key in state) {
            if (refInArray(state[key], payload.subscriberFunction)) {
                inEventLists.push(key);
            }
        }
        if (inEventLists.length) {
            const newState = cloneDeep(state);
            for (const key of inEventLists) {
                newState[key] = removeRefFromArray(state[key], payload.subscriberFunction);
                if (!newState[key].length) {
                    delete newState[key];
                }
            }
            return newState
        }
        return state;
    }
}

const getInitialState = () => {
    return {}
}

export default createReducer(getInitialState(), {
    [REGISTER_EVENT_FUNCTION]   : register,
    [UNREGISTER_EVENT_FUNCTION] : unregister,
});
