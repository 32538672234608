import {savedCurrencyPairConfigSelector} from "gui-common/currencyPairSettings/currencyPairConfigSelectors";
import XpAdminForm from "gui-common/xpAdminForm/XpAdminForm";
import {bankHolidayAdminFormConfig} from "gui-common/xpAdminForm/xpAdminFormConfig";
import CurrencyPairConfigType from "gui-common/currencyPairSettings/CurrencyPairConfigType";
import FxRejectConfigType from "gui-common/fxRejectConfig/fxRejectConfigType";
import AuditBulkBankHolidays from "gui-common/bankHolidays/AuditBulkBankHolidays";
import {getHistoricAuditConfig} from "gui-common/audit/auditFunctions";
import AuditCrudRenderer from "gui-common/audit/actionRenderers/AuditCrudRenderer";
import AuditBulkCrudActionRenderer from "gui-common/audit/actionRenderers/AuditBulkCrudActionRenderer";
import AuditNoRunDate from "gui-common/noRunDate/AuditNoRunDate";
import {AuditRenderDataRightsCollection} from "gui-common/userRights/AdminDataRightsCollection2";

export const auditOperationTypes = [
    "Create",
    "Update",
    "Delete"
]
const commonAuditPrototypeMap = {

    'se.nordicfc.xpool.common.model.audits.v1.BankHolidayAudit'                             : { model: 'BankHoliday'},
    'se.nordicfc.oxygen.bankholidays.repository.entity.BankHolidayEntity'                   : { model: 'BankHoliday'},
    'se.nordicfc.xpool.common.model.audits.v1.BankHolidayBulkAudit'                         : { model: 'BankHolidayBulkAudit'},

    'se.nordicfc.oxygen.norundate.repository.entity.NoRunDateEntity'                        : { model: 'NoRunDate'},

    'se.nordicfc.oxygen.fxrejectconfiguration.repository.entity.FxRejectConfigurationEntity': { model: 'FxRejectConfiguration'},
    'se.nordicfc.oxygen.currencypairsettings.repository.entity.CurrencyPairSettingEntity'   : { model: 'CurrencyPairSettings'},
    'se.nordicfc.oxygen.aaa.user.repository.entity.UserAuditEntity'                         : { model: 'User'},

/*
    'se.nordicfc.common.userdatarights.model.audit.v1.UserDataRights'                       : { model: 'UserDataRights'},
    'se.nordicfc.common.entitydataright.audit.v1.EntityDataRight'                           : { model: 'EntityDataRight' },
    'se.nordicfc.common.systemrights.audit.v1.EntityDataRight'                              : { model: 'SystemRights' },
*/

};
/*
const commonAuditModelsFromRootModel = {
    "RootBankHoliday"      : ["BankHoliday", "RootBankHoliday", "BankHolidayBulkAudit"],
    // "BankHoliday"          : ["BankHoliday"],
    "FxRejectConfiguration": ["FxRejectConfiguration"],
    "AuditUserActionType"  : ["AuditUserActionType"],
}
*/

const commonExcludeFields = ['createdDateTime', 'changedDateTime', 'disabledDateTime', 'disabledByUser', 'createdByUser', 'changedByUser']
// const usersToAuditSelector = getUsersToAdminDataSelector();
const commonAuditConfig = {
    CurrencyPairSettings: {
        getRenderComponent: () => CurrencyPairConfigType,
        renderProps     : {instanceId: 'currencyPairConfigAudit', configType: 'audit'},
        isRoot          : true,
        rootProps       : {
            showBefore    : true,
            selector      : savedCurrencyPairConfigSelector,
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    FxRejectConfiguration: {
        getRenderComponent: () => FxRejectConfigType,
        renderProps     : {instanceId: 'fxRejectConfigAudit', configType: 'audit'},
        isRoot          : true,
        rootProps       : {
            showBefore    : true,
            selector      : undefined, // Should not be possible to select specific fx reject config
            selectorProps : undefined,
            hideRootObjectSelector: true,
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_3_2', 'API_2_0'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    RootBankHoliday: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'bankHolidayAudit', xpAdminFormConfig: bankHolidayAdminFormConfig},
        isRoot            : true,
        rootProps         : {
            showBefore    : false,
            selector      : undefined, // Should not be possible to select specific bank holiday
            selectorProps : undefined,
            hideRootObjectSelector: true,
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    BankHoliday: {
        getRenderComponent: () => XpAdminForm,
        renderProps       : {xpAdminFormInstanceId: 'bankHolidayAudit', xpAdminFormConfig: bankHolidayAdminFormConfig},
        isRoot            : false,
        getInfoString     : item => item.currency.currency + ': ' + item.date + ': ' + item.description,
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['V_1', 'XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields, 'startDateTime', 'endDateTime'],
                skipParentLevels: ['bankHolidays'],
            }
        ]),
    },
    BankHolidayBulkAudit: {
        getRenderComponent: () => AuditBulkBankHolidays,
        renderProps       : {},
        isRoot            : false,
        overrideAndUseRoot: true,
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
/*
    User: {
        getRenderComponent: () => AdminDataRights2,
        renderProps     : {instanceId: 'userAudit'},
        isRoot          : true,
        // getNameFn       : item => item.firstName + ' ' + item.lastName,
        rootProps       : {
            showBefore    : true,
            selector      : usersToAuditSelector,
            selectorProps : {},
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
*/
/*
    DataRightsCollection: {
        getRenderComponent: () => AuditRenderDataRightsCollection,
        renderProps     : {},
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
    UserSystemRight: {
        getRenderComponent: () => AuditUserSystemRights,
        renderProps     : {instanceId: 'userSystemRightsAudit'},
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
*/
/*
    AuditUserActionType: {
        getRenderComponent: () => AuditUserDataRights,
        renderProps     : {},
        isRoot          : true,
        rootProps       : {
            showBefore    : false,
            selector      : activeAuditActionTypesSelector,
            selectorProps : {},
            getTrKey      : name => 'auditUserActionTypes.' + name + '.label'
        },
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['XP_V30', 'RF_V30'],
                excludeFields:    [...commonExcludeFields],
                skipParentLevels: [],
            }
        ]),
    },
*/
    NoRunDate: {
        getRenderComponent: () => AuditNoRunDate,
        getInfoString     : item => item.currency.currency + ': ' + item.date + ': ' + item.description,
        historicAudit: getHistoricAuditConfig([
            {
                apiVersions: ['V_1', 'XP_V30', 'API_2_0', 'API_3_2'],
                excludeFields:    [...commonExcludeFields, 'startDateTime', 'endDateTime'],
                skipParentLevels: ['bankHolidays'],
            }
        ]),
    },
};

export const commonAuditActions = {
    Create: "Create",
    Update: "Update",
    Delete: "Delete",
    BulkDelete: "BulkDelete",
    FileUpload: "FileUpload",
    AssignDataRights: "AssignDataRights",
}

export const commonAuditActionConfig = {
    [commonAuditActions.Create]: {
        renderer: AuditCrudRenderer,
    },
    [commonAuditActions.Update]: {
        renderer: AuditCrudRenderer,
    },
    [commonAuditActions.Delete]: {
        renderer: AuditCrudRenderer,
    },
    [commonAuditActions.AssignDataRights]: {
        renderer: AuditRenderDataRightsCollection,
    },
    [commonAuditActions.BulkDelete]: {
        renderer: AuditBulkCrudActionRenderer,
    },
    [commonAuditActions.FileUpload]: {
        renderer: AuditBulkCrudActionRenderer,
        propsToRenderer: {isFile: true},
    },
}

export let auditConfig = {};
export let auditPrototypeMap = {};
export let auditModelsFromRootModel = {};
export let auditRootModelFromModelMap = {};
export let auditOrmToPrototypeMap = {};

export let auditActions = {};

export const globalAuditConfig = {};


export function setAuditConfig(appConfig) {
/*
    auditConfig              = {...commonAuditConfig                , ...appConfig.audit?.modelConfig};
    auditPrototypeMap        = {...commonAuditPrototypeMap          , ...appConfig.audit?.prototypeMap};
    auditModelsFromRootModel = {...commonAuditModelsFromRootModel   , ...appConfig.audit?.modelsFromRootModels};
    auditActions             = {...commonAuditActions               , ...appConfig.audit?.auditActions};
    auditRootModelFromModelMap = (() => {
        const retObj = {};
        Object.keys(auditModelsFromRootModel).forEach(key => {
            const auditModels = auditModelsFromRootModel[key];
            auditModels.forEach(item => retObj[item] = key);
        });
        return retObj;
    })();
    auditOrmToPrototypeMap = (() => {
        const retObj = {};
        Object.keys(auditPrototypeMap).forEach(key => retObj[auditPrototypeMap[key].model] = key);
        return retObj;
    })();
*/

    // New audit config. Above is old model.
    globalAuditConfig.actions               = {...commonAuditActionConfig   , ...appConfig.audit?.appActionConfig};
    globalAuditConfig.models                = {...commonAuditConfig         , ...appConfig.audit?.modelConfig};
    globalAuditConfig.prototypeToModelMap   = {...commonAuditPrototypeMap   , ...appConfig.audit?.prototypeMap};
}
