import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {prepareLegalEntityUnitsGridColumns} from "features/legalEntityUnit/legalEntityUnitFunctions";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";
import {getBusinessUnitListDataSelector} from "features/legalEntityUnit/legalEntityUnitSelectors";

export default function LegalEntityUnitsList (props) {
    const translate = useSelector(selectTranslateFunction);
    const columnDefs = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                name:               false,
                description:        false,
                id:                 true,
                fixSessionName:     false,
                fxShortName:        false,
                createdDateTime:    true,
                createdByUser:      true,
                changedDateTime:    true,
                changedByUser:      true,
            };
            return prepareLegalEntityUnitsGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );

    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            trKeyBase='legalEntityUnitsList'
            ormModel='LegalEntityUnit'
            {...props}
            getRowDataSelector   = {getBusinessUnitListDataSelector}
            rowDataSelectorProps = {props.rowDataSelectorProps}
        />
    )
}
