import React, {useCallback, useMemo} from 'react';
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import XpGrid from "gui-common/xpGrid/XpGrid3";
import { useSelectorRef} from "gui-common/functions/hooks";
import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {useDispatch} from "react-redux";

export function FxTradeReportErpConfigurationsList (props) {
    const translateRef = useSelectorRef(selectTranslateFunction)
    const dispatch = useDispatch();

    const gridCallbacks = {
        gridRowClicked          : useCallback((params) => {}, []),
        gridCellClicked         : useCallback((params) => {}, []),
        gridObjectSelected      : useCallback((params) => {props.configurationSelected(params)}, []),
        gridContextMenuItems    : useCallback(
            (params) => {
                if (!params || !params.node || !params.node.data) return [];
                return [];
            }, [translateRef.current]),
    };
    const gridOptions = useMemo(
        () => {
            return {
                instanceId              : "selectFxTradeReportErpConfigurationsList",
                overlayNoRowsTemplate   : translateRef.current('fxTradeReport.erpConfigurationsList.noItemsToShow'),
                fillAvailableSpace      : false,
                groupDefaultExpanded    : 0,
                // getRowDataSelector      : props.getRowDataSelector ? props.getRowDataSelector : getFxTradeReportDeliveriesSelector,
                // rowDataSelectorProps    : props.rowDataSelectorProps,
                treeData                : false,
                masterDetail            : false,
                ormModel                : 'FxTradeReportErpConfig'
            };
        },
        [translateRef.current]
    );
    const colDefs = useMemo(
        () => {
            const columnDefs = [];

            xpColCreate(translateRef.current, columnDefs, false,'id'             , 100, 50 , 'fxTradeReport.erpConfigurationsList.headers.id', {cellRenderer: 'agGroupCellRenderer', lockVisible: true, lockPosition: true});
            xpColCreate(translateRef.current, columnDefs, false,'erpSystemName'  , 200, 100, 'fxTradeReport.erpConfigurationsList.headers.erpSystem');
            xpColCreate(translateRef.current, columnDefs, false,'legalEntityName', 100, 50, 'fxTradeReport.erpConfigurationsList.headers.legalEntityName');
            xpColCreate(translateRef.current, columnDefs, false,'legalEntityNumber', 100, 50, 'fxTradeReport.erpConfigurationsList.headers.legalEntityNumber');
            xpColCreate(translateRef.current, columnDefs, false,'registerUser', 100, 50, 'fxTradeReport.erpConfigurationsList.headers.fxShortName');
            xpColCreate(translateRef.current, columnDefs, false,'createdDateTime' , 400, 100, 'fxTradeReport.erpConfigurationsList.headers.createdDateTime');

            return columnDefs;
        },
        [translateRef.current]
    );

    return (
        <XpGrid
            gridId={gridOptions.instanceId}
            {...gridCallbacks}
            {...gridOptions}
            rowData={props.rowData}
            columnDefs={colDefs}
            callingContext={this}
        >
        </XpGrid>
    );
}
