import React from 'react'
import 'gui-common/dashboard/dashboard.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import DashboardPicker from "./DashboardPicker";
import DashboardCanvas from "./DashboardCanvas";
import {withRouter} from "react-router-dom";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";

function DashboardPage (props) {
    const oxygenAppContext = useOxygenAppContext();
    return (
        <div className="dashboardContainer">
            <DashboardPicker appConfig={oxygenAppContext.appConfig}/>
            <DashboardCanvas appConfig={oxygenAppContext.appConfig}/>
        </div>
    );
}
export default withRouter(DashboardPage);
