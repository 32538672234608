import {createSelector} from "reselect";
import {createSelector as ormCreateSelector} from "redux-orm";
import {
    createMomentObjectFromUtcTimestamp, createMomentObjectsForProperties,
    createMomentObjectsFromDateTimes,
    getDefaultObject
} from "gui-common/functions/functions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {
    createIsWaitingData, createTranslatedFieldData,
    translateBoolFields,
} from "gui-common/xpGrid/xpGridFunctions";
import {buildEnrichedRefixAgreement} from "features/agreement/agreementSelectors";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {stateRequestedOnIdAndProperty} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {
    activeCurrenciesSelector,
    activeFixSessionsSelector,
    shallowActiveProvidersSelector
} from "gui-common/orm/ormSelectors";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";
import {
    enrichFxOrderReportListItem
} from "gui-common/fxTradeReport/fxTradeReportFunctions";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {enrichFxTradeReportDeliveryListItem, sortDelivery} from "features/fxTradeReport/fxTradeReportSelectors";
import {fxUsedSelector} from "refix-gui-common/appConfig/refixSelectors";

/********* LegalEntityUnit selectors *****************************************************************/


function setTimeIfAfter(leuTime, lrTime) {
    if (!lrTime) return leuTime;
    if (!leuTime) return lrTime;
    if (lrTime.isBefore(leuTime)) return lrTime;
    return leuTime
}
function setTimeIfBefore(leuTime, lrTime) {
    if (!lrTime) return leuTime;
    if (!leuTime) return lrTime;
    if (lrTime.isAfter(leuTime)) return lrTime;
    return leuTime
}

export const buildLegalEntityUnitObject = (session, legalEntityUnit) => {
    if (!legalEntityUnit) return undefined;
    const legalEntityUnitRef = legalEntityUnit.ref;
    const legalEntity = session.LegalEntity.idExists(legalEntityUnitRef.legalEntityId) ? session.LegalEntity.withId(legalEntityUnitRef.legalEntityId).ref : {};
    const client = session.Client.idExists(legalEntity.clientId) ? session.Client.withId(legalEntity.clientId).ref : {};
    const agreements = legalEntityUnit.agreements.filter(entityIsActive).toModelArray().map(agreement => buildEnrichedRefixAgreement(session, agreement));
    const fxOrderReports = legalEntityUnit.fxOrderReports.toRefArray();

    let buyAmount = 0;
    let sellAmount = 0;
    let listRateArray = [];

    let isActive = false;
    let isSuspended = false;
    let timeExpire = null;
    let nextDispatchTime = null;

    for (let agreement of agreements) {
        if (!agreement) continue;
        if (agreement.type !== 'ListRateAgreement') continue;
        if (agreement.status === 'Suspended') isSuspended = true;
        if (!agreement.listRates || (agreement.listRates.length === 0)) continue;

        for (let listRate of agreement.listRates) {
            if ((listRate.status === 'Active') || (listRate.status === 'InExpiry')) {
                isActive = true;
                buyAmount += listRate.buyAmount;
                sellAmount += listRate.sellAmount;

                const listRateExpireMomentObject = createMomentObjectFromUtcTimestamp(listRate.timeExpire);
                timeExpire = setTimeIfAfter(timeExpire, listRateExpireMomentObject);

                const listRateDispatchMomentObject = createMomentObjectFromUtcTimestamp(listRate.nextDispatchTime);
                nextDispatchTime = setTimeIfBefore(nextDispatchTime, listRateDispatchMomentObject);
            }
            listRateArray.push(listRate);
        }
    }

    return {
        ...legalEntityUnitRef,
        ...getLastChange('LegalEntityUnit', legalEntityUnit),
        client              : client,
        legalEntity         : legalEntity,
        fixSessionName      : (legalEntityUnitRef.fixSession)           ? legalEntityUnitRef.fixSession.name : "",
        tradingLimitCurrencyName: (legalEntityUnitRef.tradingLimitCurrency) ? legalEntityUnitRef.tradingLimitCurrency.currency : "",
        agreements          : agreements,
        fxOrderReports      : fxOrderReports,
        isActive            : isActive,
        isSuspended         : isSuspended,
        buyAmount           : isActive ? buyAmount  : "",
        sellAmount          : isActive ? sellAmount : "",
        listRates           : listRateArray,
        timeExpire          : timeExpire       ? timeExpire.toISOString() : null,
        nextDispatchTime    : nextDispatchTime ? nextDispatchTime.toISOString() : null,
        clientApiChannel    : client?.clientApiChannel,
    };
};

export const getLegalEntityUnitSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.selectId,
    (session, legalEntityUnitId) => {
        const legalEntityUnit = session.LegalEntityUnit.idExists(legalEntityUnitId) ? session.LegalEntityUnit.withId(legalEntityUnitId) : undefined;
        if (!legalEntityUnit) return undefined;

        const legalEntityUnitRef = legalEntityUnit.ref;
        const legalEntity = session.LegalEntity.idExists(legalEntityUnitRef.legalEntityId) ? session.LegalEntity.withId(legalEntityUnitRef.legalEntityId).ref : {};
        const client = session.Client.idExists(legalEntity.clientId) ? session.Client.withId(legalEntity.clientId).ref : {};

        const returnObject = {
            client: client,
            legalEntity: legalEntity,
            ...legalEntityUnitRef,
            ...createMomentObjectsFromDateTimes(legalEntityUnitRef),
            ...createMomentObjectsForProperties(legalEntityUnitRef, ['todayLimitOverrideDateTime']),
            agreements: legalEntityUnit.agreements.all().filter(entityIsActive).toModelArray().map(agreement => {
                return {
                    ...agreement.ref,
                    ...createMomentObjectsFromDateTimes(agreement.ref),
                    ...createMomentObjectsForProperties(agreement.ref, ['suspendedDateTime', 'resumedDateTime']),
                }
            }),
        };

//        console.log("Selected legal entity unit:", returnObject);
        return returnObject;
    }
);
export const legalEntityUnitsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];
        // console.log("Selector legalEntityUnitsSelector running ");
        session.LegalEntityUnit.filter(entityIsActive).orderBy('id', 'desc').toModelArray().forEach(legalEntityUnit => {
            returnArray.push(buildLegalEntityUnitObject(session, legalEntityUnit));
        });
        // console.log("legalEntityUnitsSelector done :", returnArray);
        return returnArray;
    }
);

const dashboardBusinessUnitListDataStateRequestSelector = getRequestingStateOnModelSelector();
const legalEntityUnits   = state => legalEntityUnitsSelector(state);
const translate          = state => selectTranslateFunction(state);
const legalEntityUnitRequestState  = state => dashboardBusinessUnitListDataStateRequestSelector(state, {model: 'LegalEntityUnit'})
const filterFunction   = (state, props) => props ? props.filterFunction : undefined;
const inputData        = (state, props) => props ? props.inputData      : undefined;
export const getBusinessUnitListDataSelector = () => createSelector(
    [legalEntityUnits, translate, legalEntityUnitRequestState, filterFunction, inputData],
    (legalEntityUnits, translate, legalEntityUnitRequestState, filterFunction, inputData) => {
        if (!legalEntityUnits)  return [];
        if (!translate) return [];
        // console.log("Selector getBusinessUnitListDataSelector running ", legalEntityUnits, legalEntityUnitRequestState);

        const leus = inputData ? inputData : filterFunction ? legalEntityUnits.filter(filterFunction) : legalEntityUnits;

        let returnArray = [];
        for (let legalEntityUnit of leus) {
            translateBoolFields(legalEntityUnit, translate);
            createTranslatedFieldData(legalEntityUnit, 'clientApiChannel'     , translate, "clientForm.clientApiChannel.values." + legalEntityUnit.clientApiChannel);
            createIsWaitingData(legalEntityUnit, 'todayLimitOverride', stateRequestedOnIdAndProperty(legalEntityUnitRequestState, legalEntityUnit.id, 'todayLimitOverride'));
            returnArray.push({...legalEntityUnit});
        }
        // console.log("getBusinessUnitListDataSelector done :", returnArray);
        return returnArray;
    }
);

export const getFxOrderReportsForLeuSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.leuId,
    selectTranslateFunction,
    (session, leuId, translate) => {

        const leuModel = getOrmModelObjectById(session, 'LegalEntityUnit', leuId);
        if (!leuModel) return [];

        return leuModel.fxOrderReports.toRefArray().map(item => enrichFxOrderReportListItem(item, translate));

    }
);

const deliveryRequestStateSelector = getRequestingStateOnModelSelector();
export const getFxTradeReportDeliveriesForLeuSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.leuId,
    state => deliveryRequestStateSelector(state, {model: 'FxTradeReportDelivery'}),
    selectTranslateFunction,
    (session, leuId, requestState, translate) => {
        const leuModel = getOrmModelObjectById(session, 'LegalEntityUnit', leuId);
        if (!leuModel?.agreements) {
            return [];
        }
        const retArray = [];
        leuModel.agreements.toModelArray().forEach(agreement => {
            if (agreement.fxTradeReportDeliveries) {
                agreement.fxTradeReportDeliveries.toModelArray()
                    .forEach(item => retArray.push(enrichFxTradeReportDeliveryListItem(item, requestState, translate)));
            }
        })
        return retArray.sort(sortDelivery);
    }
);

export const getFxTradeReportDeliveriesForEventSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props.eventId,
    state => deliveryRequestStateSelector(state, {model: 'FxTradeReportDelivery'}),
    selectTranslateFunction,
    (session, eventId, requestState, translate) => {
        const eventModel = getOrmModelObjectById(session, 'FxTradeReportEvent', eventId);
        if (!eventModel?.deliveries) {
            return [];
        }
        const retArray = [];
        eventModel.deliveries.toModelArray().forEach(delivery => {
            retArray.push(enrichFxTradeReportDeliveryListItem(delivery, requestState, translate));
        })

        return retArray;
    }
);


/********* New legal entity unit template selector *****************************************************************/

const fixSessions   = state => activeFixSessionsSelector(state);
const ormCurrencies = state => activeCurrenciesSelector(state);
const availableTimeZones        = state => state.appEnvState.availableTimeZones;
const parentItemData   = (state, props) => props ? props.parentItemData : undefined;
const fxUsed        = state => fxUsedSelector(state);
export const newLegalEntityUnitFormTemplateSelector = createSelector(
    [fixSessions, ormCurrencies, availableTimeZones, parentItemData, fxUsed],
    (fixSessions, ormCurrencies, availableTimeZones, parentItemData, fxUsed) => {
        return {
            id:                     "",
            tradingLimitCurrencyId  : (ormCurrencies[0] && ormCurrencies[0].id) ? ormCurrencies[0].id : "",
            fixSessionId            : (fixSessions?.length && fixSessions[0] && fixSessions[0].id) ? fixSessions[0].id : "",
            legalEntityId           : parentItemData ? parentItemData.id : undefined, // Set legalEntityId from super entity legalEntity, if provided
            restTimeZone            : getDefaultObject(availableTimeZones).id,
            name:                   "",
            description:            "",
            priceFeedOnly:          false,
            appId:                  "",
            fxEnabled:              fxUsed === true,
            fxShortName:            "",
            tradingLimit:           "",
            netTradingLimit:        false,
            createdDateTime:        null,
            createdByUser:          "",
            changedDateTime:        null,
            changedByUser:          "",
            netDispatchTrades   : false,
            sendToAccounting   : false,
        }
    }
);
