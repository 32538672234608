import {globalApiHandle} from "gui-common/api/apiConstants";
import {genericFetch} from "gui-common/api/apiCommon";
import {refixIncomingTransformersMap} from "appConfig/api/apiTransformers";
import {ormEntityUpdateStarted} from "gui-common/orm/ormReducer";

export function postSuspendAllTradeReportApisToApi(message) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Post suspend all trade report APIs ";
            let requestUrl = globalApiHandle.baseUrls.fxTradeReportActions + '/suspend-all-api-services?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'POST',
                message,
            )).then(result => {
                resolve(result)
            }).catch(err => {
            })
        })
    }
}

export function postResumeAllTradeReportApisToApi(message) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Post resume all trade report APIs ";
            let requestUrl = globalApiHandle.baseUrls.fxTradeReportActions + '/resume-all-api-services?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'POST',
                message,
            )).then(result => {
                resolve(result)
            }).catch(err => {
            })
        })
    }
}

export function postCancelEventToApi(orderId, message) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Post fx trade report event ";
            let requestUrl = globalApiHandle.baseUrls.fxTradeReportActions + '/send-cancel-event?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'POST',
                {fxOrderReportId: orderId, comment: message},
            )).then(result => {
                resolve(result)
            }).catch(err => {
            })
        })
    }
}

export function requestAddAllInDeliveryToPendingToApi(deliveryId, message) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!deliveryId) {
                reject("Undefined deliveryId");
            }

            let logText = "Post add all to pending ";
            let requestUrl = globalApiHandle.baseUrls.FxTradeReportDelivery + '/' + deliveryId + '/add-all-events-to-pending?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'PUT',
                message,
            )).then(result => {
                resolve(result)
            }).catch(err => {
            })
        })
    }
}

export function requestAddEventInDeliveryToPendingToApi(deliveryId, eventId, message) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!deliveryId) {
                reject("Undefined deliveryId");
            }
            if (!eventId) {
                reject("Undefined eventId");
            }
            let logText = "Post add event to pending ";
            let requestUrl = globalApiHandle.baseUrls.FxTradeReportDelivery + '/' + deliveryId + '/events/ ' + eventId + '/add-to-pending?';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'PUT',
                message,
            )).then(result => {
                resolve(result)
            }).catch(err => {
            })
        })
    }
}

export function getUnassignedConfigurationsFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let customText = "Request unassigned fx trade report ERP configurations";
            let requestUrl = globalApiHandle.baseUrls.fxTradeReportConfigurations + "/unassigned?";

            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                'GET'
            )).then(result => {
                resolve(result?.response?.map(item => refixIncomingTransformersMap.FxTradeReportErpConfig(item)));
            }).catch(err => {reject(err)})
        })
    }
}

export function assignConfigurationToLegalEntityToApi(configuration, legalEntity, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let customText = "Assign fx trade report ERP configurations to legal entity";
            let requestUrl = globalApiHandle.baseUrls.fxTradeReportConfigurations + "/" + configuration.id + "/assign-legal-entity?";

            dispatch(ormEntityUpdateStarted('FxTradeReportErpConfig', configuration));
            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                'PUT',
                {legalEntityId: legalEntity.id, comment: comment}
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function deAssignConfigurationFromLegalEntityToApi(configuration, comment) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let customText = "De-assign fx trade report ERP configurations to legal entity";
            let requestUrl = globalApiHandle.baseUrls.fxTradeReportConfigurations + "/" + configuration.id + "/unassign-legal-entity?";

            dispatch(ormEntityUpdateStarted('FxTradeReportErpConfig', configuration));
            dispatch(genericFetch(
                requestUrl,
                customText,
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                (resp) => (dispatch, getState) => {},
                'PUT',
                {comment: comment}
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
