import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {topicsSelector, userSelector} from "gui-common/orm/ormSelectors";
import WaitingXpoolToggleButton from "gui-common/components/WaitingXpoolToggleButton";
import {userTopicActionToApi} from "gui-common/userSettings/topics/userTopicAdminApi";

export function UserTopics (props) {
    const topics = useSelector(topicsSelector);
    const user = useSelector(userSelector);

    const renderTopics = useMemo(
        () => {
            return topics.map(topic => ({...topic, isActive: user.topics?.includes(topic.topic)}));
        },
        [user?.topics, topics]
    );

    return (
        <div>
            <h3 className="userPrefsCardHeader">
                <XpTranslated trKey={'userTopicAdmin.header'}/>
            </h3>
            <label>
                <XpTranslated trKey={'userTopicAdmin.label'}/>
            </label>
            <div>
                {renderTopics.map(topic => (<TopicButton topic={topic} key = {topic.id}/>))}
            </div>
        </div>
    )
}

function TopicButton (props) {
    const user = useSelector(userSelector);
    const dispatch  = useDispatch();

    const [instructedState, setInstructedState]   = useState(props.topic.isActive);

    return (
        <div style={{display: 'inline-block', marginRight: '5px', marginBottom: '5px'}}>
            <WaitingXpoolToggleButton
                label={props.topic.topic}
                isActive={props.topic.isActive}
                waiting={instructedState !== props.topic.isActive}
                onClickCallback={(params) => {
                    dispatch(userTopicActionToApi(props.topic.isActive ? "delete" : "assign", user.id, props.topic.topic))
                    setInstructedState(!props.topic.isActive)
                }}
            />
        </div>
    )
}
TopicButton.propTypes = {
    topic: PropTypes.object.isRequired,
};




