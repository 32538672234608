import PropTypes from "prop-types";
import React, {useMemo, useState, useCallback} from "react";
import {useResizeObserver} from "gui-common/functions/hooks";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import FocusLock from "react-focus-lock";
import ReactModal from "react-modal";
import cloneDeep from "lodash.clonedeep";

function XpDynamicContentModal (props) {

    const [headerHeight, setHeaderHeight ] = useState();

    const observerCallback = useCallback(box => {
        console.log("headerRef observer: ", box);
        setHeaderHeight(box.height);
    }, [])
    const headerRef = useResizeObserver(observerCallback);

    const [footerHeight, setFooterHeight ] = useState();
    const footerRef = useResizeObserver(box => {
        console.log("footerRef observer: ", box);
        setFooterHeight(box.height);
    })

    const maxContentHeight = useMemo(
        () => {
            const headerPlusFooterHeight   = headerHeight + footerHeight;
            const paddingPlusMarginsHeight = props.paddingPlusMarginsHeight ? props.paddingPlusMarginsHeight : 22 + 30;
            return 'calc(100vh - '+ (headerPlusFooterHeight + paddingPlusMarginsHeight) + 'px)'
        },
        [headerHeight, footerHeight, props.paddingPlusMarginsHeight]
    );

    const modalParams = useMemo(
        () => {
            const retObject = cloneDeep(modalStandardParams)
            if (props.modalBaseClassName) {
                retObject.className.base = props.modalBaseClassName;
            }
            return retObject;
        },
        [props.modalBaseClassName]
    );


    return (
        <div>
            <FocusLock>
                <ReactModal
                    // contentLabel="Maybe not needed..."
                    onRequestClose={() => props.cancelAction()}
                    shouldCloseOnEsc={props.shouldCloseOnEsc === undefined ? true : props.shouldCloseOnEsc}
                    {...modalParams}
                >
                    <div>
                        <div ref={headerRef}      >{props.header}</div>
                        <div style={{maxHeight: maxContentHeight, overflow: 'auto'}} >{props.content}</div>
                        <div ref={footerRef}      >{props.footer}</div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    )
}
XpDynamicContentModal.propTypes = {
    header  : PropTypes.node.isRequired,
    content : PropTypes.node.isRequired,
    footer  : PropTypes.node.isRequired,
    cancelAction : PropTypes.func.isRequired,
    paddingPlusMarginsHeight : PropTypes.number,
    shouldCloseOnEsc : PropTypes.bool,
    modalBaseClassName : PropTypes.string,
};
export default XpDynamicContentModal



