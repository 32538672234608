import {createSelector} from "reselect";
import {createSelector as createOrmSelector} from "redux-orm/lib/redux";
import {orm} from "gui-common/orm/orm";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {reFixAgreementsSelector} from "features/agreement/agreementSelectors";


export const newPriceToleranceFormTemplateSelector = createSelector(
    [],
    () => {
        return {
            currencyPair: "",
            description: "",
            startBusinessHours                  : "",
            endBusinessHours                    : "",
            priceToleranceInsideBusinessHours   : "",
            priceToleranceOutsideBusinessHours  : ""
        }
    }
);

export const getSpecificPriceToleranceSelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    reFixAgreementsSelector,
    (session, selectId, agreements) => {
        const model =  getOrmModelObjectById(session, 'PriceTolerances', selectId);
        if (!model) {
            return undefined;
        }
        return {
            numberOfAgreements: getNumberOfAgreements(agreements, model.ref),
            ...model.ref
        };
    }
);
export const getPriceToleranceByCurrencyPairIdSelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.currencyPairId : undefined,
    (session, currencyPairId) => {
        try {
            const model =  session.PriceTolerances.get({currencyPairId: currencyPairId});
            return model?.ref;
        } catch (error) {
            console.error("Multiple currency pairs were found with currency pair id: " + currencyPairId);
            return undefined;
        }
    }
);

export const priceTolerancesSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        return session.PriceTolerances.all().orderBy('name', 'asc').toRefArray();
    }
);

const allPriceTolerances  = state          => priceTolerancesSelector(state);

export const getPriceTolerancesListDataSelector = () => createSelector(
    [allPriceTolerances, reFixAgreementsSelector],
    (allPriceTolerances, agreements) => {
        if (!allPriceTolerances?.length) {
            return [];
        }
        let returnArray = []
        for (let priceTolerance of allPriceTolerances) {
            returnArray.push({
                ...priceTolerance,
                numberOfAgreements: getNumberOfAgreements(agreements, priceTolerance),
            })
        }
        return returnArray;
    }
)

function getNumberOfAgreements(agreements, priceTolerance) {
    let numberOfAgreements = 0;
    for (const agreement of agreements) {
        if (agreement.type !== 'ListRateAgreement') {
            continue;
        }
        if (!agreement.overridePriceTolerances) {
            continue;
        }
        if (agreement.currencyPairId === priceTolerance.currencyPairId) {
            numberOfAgreements++;
        }
    }
    return numberOfAgreements;
}



