import {useDispatch, useSelector} from "react-redux";
import {reFixAgreementsSelector} from "features/agreement/agreementSelectors";
import {listRateProcessStatusSelector, listRatesSelector} from "features/listRate/listRateSelectors";
import {userSelector} from "gui-common/orm/ormSelectors";
import {useSelectorRef} from "gui-common/functions/hooks";
import {
    agreementNotExemptFromGlobalActions,
    getAgreementsAffectedByAction,
    launchListRateAgreementAction
} from "features/agreement/agreementFunctions";
import XpDropDownMenuItem from "gui-common/menuComponents/XpDropDownMenuItem";
import {
    getListRatesAffectedByAction,
    launchListRatesAction,
    listRateNotExemptFromGlobalActions
} from "features/listRate/listRateFunctions";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {refixSystemRights} from "appConfig/appUserRights";
import {NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React, {useMemo} from "react";
import {legalEntityUnitsSelector} from "features/legalEntityUnit/legalEntityUnitSelectors";
import {pushModalWindow} from "redux-promising-modals";
import {CONFIRMATION_WITH_COMMENT_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {
    postResumeAllTradeReportApisToApi,
    postSuspendAllTradeReportApisToApi
} from "features/fxTradeReport/fxTradeReportApi";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";

export function OverallActionsDropDownMenu() {
    const agreements = useSelector(reFixAgreementsSelector);
    const listRates = useSelector(listRatesSelector)
    const lrActive = useRefixServiceIsActive(refixServices.ListRates);
    const trActive = useRefixServiceIsActive(refixServices.TradeReport);

    const notExemptAgreements = useMemo(
        () => {
            return agreements?.filter(agreementNotExemptFromGlobalActions);
        },
        [agreements]
    );

    const notExemptListRates = useMemo(
        () => {
            return listRates?.filter(listRateNotExemptFromGlobalActions);
        },
        [listRates]
    );

    const commentIsMandatoryConfig = useSelector(state => state.appEnvState.mandatoryListRateActionComment.all);
    const user = useSelector(userSelector);

    const listRateProcessStatusRef = useSelectorRef(listRateProcessStatusSelector);
    const dispatch = useDispatch();

    const createAgreementMenuItem = (action) => {
        const affectedAgreements = getAgreementsAffectedByAction(notExemptAgreements, action);
        const affectedListRates = getListRatesAffectedByAction(notExemptListRates,  action);
        return (
            <XpDropDownMenuItem
                trKey={'overallExposure.' + action + '.button'}
                trParams={{count: affectedAgreements.length}}
                confirmFunction={() => launchListRateAgreementAction(affectedAgreements, affectedListRates, action, {type: 'ListRateAgreement'}, 'overallExposure', "", commentIsMandatoryConfig, listRateProcessStatusRef, dispatch, action === 'suspend')}
                disabled={affectedAgreements.length === 0}
            />
        )
    };

    const createListMenuItem = (action) => {
        const affectedListRates = getListRatesAffectedByAction(notExemptListRates, action);
        return (
            <XpDropDownMenuItem
                trKey={'overallExposure.' + action + '.button'}
                trParams={{count: affectedListRates.length}}
                confirmFunction={() => launchListRatesAction(affectedListRates, action, {type: 'ListRateAgreement'}, 'overallExposure', "", commentIsMandatoryConfig, listRateProcessStatusRef, dispatch)}
                disabled={affectedListRates.length === 0}
            />
        )
    };

    const userCanSuspendAll = userHasSystemRight(user, refixSystemRights.ListRatesAll.Suspend);
    const userCanResumeAll = userHasSystemRight(user, refixSystemRights.ListRatesAll.Resume);
    const userCanExpireAll = userHasSystemRight(user, refixSystemRights.ListRatesAll.Expire);
    const userCanDispatchAll = userHasSystemRight(user, refixSystemRights.ListRatesAll.Dispatch);
    const userCantopResumeAllTradeReportApiServices = userHasSystemRight(user, refixSystemRights.TradeReport.StopResumeAllApiServices);

    if (!userCanSuspendAll && !userCanResumeAll && !userCanExpireAll && !userCanDispatchAll && !userCantopResumeAllTradeReportApiServices) {
        return null;
    }
    return (
        <NavDropdown title={<XpTranslated trKey={'topMenu.actionsDropdown'}/>} id="config-nav-dropdown">
            {lrActive && userCanSuspendAll && createAgreementMenuItem('suspend')}
            {lrActive && userCanResumeAll && createAgreementMenuItem('resume')}
            {lrActive && userCanExpireAll && createListMenuItem('expire')}
            {lrActive && userCanDispatchAll && createListMenuItem('dispatch')}
            {trActive && userCantopResumeAllTradeReportApiServices && <StopAllApiServicesMenuItem/>}
            {trActive && userCantopResumeAllTradeReportApiServices && <ResumeAllApiServicesMenuItem/>}
        </NavDropdown>
    )
}

function StopAllApiServicesMenuItem(props) {
    const legalEntityUnits = useSelector(legalEntityUnitsSelector);
    const mandatoryComments = useAppEnvProperty('mandatoryTradeReportActionComment');
    const dispatch = useDispatch();

    const leusWithTradeReportAgreementsAndActiveApiService = useMemo(
        () => {
            if (!legalEntityUnits?.length) {
                return [];
            }
            return legalEntityUnits.reduce((leus, leu) => {
                if (!leu.fxTradeReportActive || !leu.agreements?.length || !leu.agreements.filter(agreement => agreement.type === 'TradeReportAgreement').length) {
                    return leus;
                }
                leus.push(leu);
                return leus;
            }, []);
        },
        [legalEntityUnits]
    );


    return (
        <XpDropDownMenuItem
            trKey={'fxTradeReport.overallActions.suspendAllTradeReportApiServices.menuItem'}
            trParams={{count: leusWithTradeReportAgreementsAndActiveApiService.length}}
            confirmFunction={() => {
                if (legalEntityUnits?.length === 0) {
                    return;
                }
                dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG, {modalKey: 'fxTradeReport.overallActions.suspendAllTradeReportApiServices', modalParams: {count: legalEntityUnits.length}, commentIsRequired: mandatoryComments?.tradeReportApiAll.suspend, bodyClassName: "modalBodyHighestAlert"}))
                    .then((result) => {
                        if (result?.status === MODAL_TYPE_CONFIRM) {
                            dispatch(postSuspendAllTradeReportApisToApi(result.comment));
                        }
                    })
            }}
            disabled={leusWithTradeReportAgreementsAndActiveApiService.length === 0}
        />
    )
}

function ResumeAllApiServicesMenuItem(props) {
    const legalEntityUnits = useSelector(legalEntityUnitsSelector);
    const mandatoryComments = useAppEnvProperty('mandatoryTradeReportActionComment');
    const dispatch = useDispatch();

    const leusWithTradeReportAgreementsAndPassiveApiService = useMemo(
        () => {
            if (!legalEntityUnits?.length) {
                return [];
            }
            return legalEntityUnits.reduce((leus, leu) => {
                if (leu.fxTradeReportActive || !leu.agreements?.length || !leu.agreements.filter(agreement => agreement.type === 'TradeReportAgreement').length) {
                    return leus;
                }
                leus.push(leu);
                return leus;
            }, []);
        },
        [legalEntityUnits]
    );

    return (
        <XpDropDownMenuItem
            trKey={'fxTradeReport.overallActions.resumeAllTradeReportApiServices.menuItem'}
            trParams={{count: leusWithTradeReportAgreementsAndPassiveApiService.length}}
            confirmFunction={() => {
                if (legalEntityUnits?.length === 0) {
                    return;
                }
                dispatch(pushModalWindow(CONFIRMATION_WITH_COMMENT_DIALOG, {modalKey: 'fxTradeReport.overallActions.resumeAllTradeReportApiServices', modalParams: {count: legalEntityUnits.length}, commentIsRequired: mandatoryComments?.tradeReportApiAll.resume}))
                    .then((result) => {
                        if (result?.status === MODAL_TYPE_CONFIRM) {
                            dispatch(postResumeAllTradeReportApisToApi(result.comment));
                        }
                    })
            }}
            disabled ={leusWithTradeReportAgreementsAndPassiveApiService.length === 0}
        />
    )
}
