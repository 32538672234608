import {createReducer} from "gui-common/functions/reducer";
import cloneDeep from "lodash.clonedeep";
export const DATA_RIGHTS_RESET_USER                = 'DATA_RIGHTS_RESET_USER';
export const DATA_RIGHTS_EDIT_USER                 = 'DATA_RIGHTS_EDIT_USER';
export const DATA_RIGHTS_ADD_DATA_RIGHT            = 'DATA_RIGHTS_ADD_DATA_RIGHT';
export const DATA_RIGHTS_REMOVE_DATA_RIGHT         = 'DATA_RIGHTS_REMOVE_DATA_RIGHT';
export const DATA_RIGHTS_SET_AVAILABLE_DATA_RIGHTS = 'DATA_RIGHTS_SET_AVAILABLE_DATA_RIGHTS';

// Action creators ********************************************************
// ************************************************************************
export function resetUserDataRightsAction(user) {
    return {
        type: DATA_RIGHTS_RESET_USER,
        payload: user,
    };
}
export function editUserDataRightsAction(dataRightsCollection) {
    return {
        type: DATA_RIGHTS_EDIT_USER,
        payload: dataRightsCollection,
    };
}
export function addDataRightAction(dataRightsCollection, dataRight) {
    return {
        type: DATA_RIGHTS_ADD_DATA_RIGHT,
        payload: {dataRightsCollection, dataRight},
    };
}
export function removeDataRightAction(dataRightsCollection, dataRight) {
    return {
        type: DATA_RIGHTS_REMOVE_DATA_RIGHT,
        payload: {dataRightsCollection, dataRight},
    };
}
export function setAvailableDataRightsAction(dataRights) {
    return {
        type: DATA_RIGHTS_SET_AVAILABLE_DATA_RIGHTS,
        payload: dataRights,
    };
}

function resetUserDataRights(state, payload) {
    if (!payload) {
        console.error("No user in resetDataRightsCollection", payload);
        return state;
    }
    if (!state.modifiedDataRightsCollections[payload.userId]) {
        return state;
    }
    const newState = cloneDeep(state);
    delete newState.modifiedDataRightsCollections[payload.userId];
    return newState;
}
function editUserDataRights(state, payload) {
    if (!payload) {
        console.error("No user in editUserDataRights", payload);
        return state;
    }
    // userId is always the same as id on dataRightsCollection
    if (state.modifiedDataRightsCollections[payload.id]) {
        return state;
    }
    const newState = cloneDeep(state);
    newState.modifiedDataRightsCollections[payload.id] = payload;
    return newState;
}
function addRemoveDataRight(state, payload, action) {
    if (!payload.dataRight) {
        console.error("No dataRight in addRemoveDataRight", payload);
        return state;
    }
    const newState = cloneDeep(state);

    const modifiedDRC = newState.modifiedDataRightsCollections[payload.dataRightsCollection.id];
    if (!modifiedDRC) {
        console.error("No dataRightsCollection in modified state in addRemoveDataRight", payload);
        return state;
    }
    if (action === 'remove' && !modifiedDRC.dataRights.find(item => item.id === payload.dataRight.id)) {
        console.warn("No dataRight to remove in addRemoveDataRight", payload, modifiedDRC.dataRights);
        return state;
    }
    if (action === 'add' && modifiedDRC.dataRights.find(item => item.id === payload.dataRight.id)) {
        console.warn("dataRight to add already exists in addRemoveDataRight", payload, modifiedDRC.dataRights);
        return state;
    }
    if (action === 'add') {
        modifiedDRC.dataRights.push(payload.dataRight);
    }
    else if (action === 'remove') {
        modifiedDRC.dataRights = modifiedDRC.dataRights.filter(item => item.id !== payload.dataRight.id);
    }
    else {
        console.error("Invalid action in addRemoveDataRight", action, payload);
        return state;
    }
    newState.modifiedDataRightsCollections[payload.dataRightsCollection.id] = modifiedDRC;
    return newState;
}
function setAvailableDataRights(state, payload) {
    if (!payload) {
        console.error("No dataRights in setAvailableDataRights", payload);
        return state;
    }
    const newState = cloneDeep(state);
    newState.availableDataRights = payload
    return newState;
}

const initialState = {
    modifiedDataRightsCollections: {},
    availableDataRights: [],
};
export default createReducer(initialState, {
    [DATA_RIGHTS_RESET_USER]                : resetUserDataRights,
    [DATA_RIGHTS_EDIT_USER]                 : editUserDataRights,
    [DATA_RIGHTS_ADD_DATA_RIGHT]            : (state, payload) => addRemoveDataRight(state, payload, 'add'),
    [DATA_RIGHTS_REMOVE_DATA_RIGHT]         : (state, payload) => addRemoveDataRight(state, payload, 'remove'),
    [DATA_RIGHTS_SET_AVAILABLE_DATA_RIGHTS] : setAvailableDataRights,
});
