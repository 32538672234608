import {useDispatch, useSelector} from "react-redux";
import {confirmAllUserMessages} from "gui-common/userMessages/userMessageThunks";
import {openUserMessagesSelector} from "gui-common/userMessages/userMessagesSelectors";
import XpDropDownMenuItem from "gui-common/menuComponents/XpDropDownMenuItem";
import React from "react";

function ConfirmAllUserMessagesMenuItem(props) {
    const openUserMessages = useSelector(openUserMessagesSelector);
    const dispatch  = useDispatch();
    const confirmAllUserMessagesDisabled = openUserMessages ? (openUserMessages.length) === 0 : true;

    return (
        <XpDropDownMenuItem
            trKey           = {'topMenu.confirmAllUserMessages.menuItem'}
            toolTipTrKey={confirmAllUserMessagesDisabled ? 'topMenu.confirmAllUserMessages.tooltipDisabled' : 'topMenu.confirmAllUserMessages.tooltipActive'}
            confirmFunction = {confirmAllUserMessagesDisabled ? undefined : () => dispatch(confirmAllUserMessages())}
            disabled={confirmAllUserMessagesDisabled}
        />
    )
}
export default ConfirmAllUserMessagesMenuItem
