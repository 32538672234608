import { createTheme } from '@mui/material/styles';
import {useMemo} from "react";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";

const newThemeStylingOverridesBase = {
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&:before': {
                        display: 'none',
                        margin: 0,
                    },
                    '&:first-of-type': {
                        borderTop: "none",
                    },
                    paddingTop: 5,
                    background: "none",
                    borderTop: "solid 1px lightgray",
                    '&.Mui-expanded': {
                        margin: 0,
                        background: "none",
                        transition: "border cubic-bezier(0.4, 0, 0.2, 1) 400ms"
                    },
                    transition: "border cubic-bezier(0.4, 0, 0.2, 1) 100ms",
                    rounded: {
                        borderRadius: 5,
                    },
                    boxShadow: "none",
                    '&.Mui-disabled': {
                        opacity: 1,
                        background: "none !important"
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: 0,
                    // paddingTop: 5,
                    paddingLeft: 5,
                    // borderBottom: "solid 1px lightgray",
                    '&.Mui-expanded': {
                        minHeight: "0 !important",
                    },
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)',
                    },
                },
                content: {
                    marginTop: "4px !important",
                    marginBottom: "4px !important",
                    paddingLeft: 8
                },
                /*
                                expandIcon: {
                                    left: 0,
                                    // right: "none",
                                    position: "absolute",
                                },
                */
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    paddingTop: 7,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0, // 7px
                    // borderTop: "solid 1px lightgray",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    whiteSpace: "pre-line",
                    fontFamily: "var(--xpool-font-family)",
                    fontSize: "var(--xpool-tooltip-font-size)",
                    fontWeigh: "var(--xpool-tooltip-font-weight)",
                    maxWidth: "500px",
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    cursor: 'auto'
                },
                thumbColorPrimary: {
                    color: "var(--xpool-primary-color-mid)",
                    "&.Mui-active": {
                        color: "var(--xpool-primary-color-strong) !important",
                    },
                    "&&:hover": {
                        color: "var(--xpool-primary-color-strong) !important",
                        boxShadow: 'none'
                    }
                },
                rail: {
                    color: "var(--xpool-primary-color-mid)",
                },
                track: {
                    color: "var(--xpool-primary-color-mid)",
                },
                valueLabelOpen: {
                    transform: 'unset'
                },
                thumb: {
                    cursor: 'pointer',
                    "&.Mui-focusVisible": {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'var(--xpool-font-family)',
                    fontSize: 'var(--xpool-standard-font-size)',
                    fontWeight: 'var(--xpool-field-value-font-weight)',
                    background: 'white',
                    '&.Mui-disabled': {
                        background: 'none',
                    },
                },
                input: {
                    padding: '2px 5px 2px 5px',
                    border:  'var(--xpool-form-field-border)',
                    borderRadius: 'var(--xpool-form-field-border-radius) !important',
                },
                multiline: {
                    padding: '0',
                },
                inputMultiline: {
                    padding: '2px 0 2px 5px',
                }
            },
        },
        MuiInput:{
            styleOverrides: {
                underline: {
                    // This is magical. Must be exactly two '&' for hover and ripple bottom border to work, but remove the ugly dotted line when disabled.
                    "&&:before": {
                        borderBottom: "none"
                    },
                },
                input: {
                    '&.Mui-disabled': {
                        // '-webkit-text-fill-color': 'var(--xpool-body-text-color)',
                        WebkitTextFillColor: 'var(--xpool-body-text-color)',
                        color: 'var(--xpool-body-text-color)',
                    },
                }
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginRight: '10px',
                },
                label: {
                    fontSize  : 'var(--xpool-label-font-size)',
                    fontWeight: 'var(--xpool-label-font-weight)',
                    color     : 'var(--xpool-label-text-color)',
                    '&.Mui-disabled': {
                        color: 'var(--xpool-label-text-color);',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                marginNormal: {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 'none',
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    // padding: '2px 5px 2px 5px',
                    padding: '0px 0px 2px 0px !important',
                    border:  'var(--xpool-form-field-border)',
                    borderRadius: 'var(--xpool-form-field-border-radius) !important',
                },
                input: {
                    padding: '0px 30px 0px 5px !important',
                    border:  'none',
                },
                option: {
                    fontFamily: 'var(--xpool-font-family)',
                    fontSize: 'var(--xpool-standard-font-size)',
                    fontWeight: 'var(--xpool-field-value-font-weight)',
                    background: 'white',
                    '&.Mui-focused': {
                        backgroundColor: 'var(--xpool-grid-selected-background-color) !important',
                    },
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'var(--xpool-font-family)',
                    fontSize: 'var(--xpool-standard-font-size)',
                    fontWeight: 'var(--xpool-field-value-font-weight)',
                    background: 'white',
                }
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    height: '34px',
                    width: '48px'
                },
                switchBase: {
                    height: '34px',
                    width: '34px',
                    color: 'var(--xpool-primary-color-mid)',
                    "&.Mui-disabled": {
                        color: 'var(--xpool-primary-color-weak)',
                    },
                    "&.Mui-checked.Mui-disabled": {
                        color: 'var(--xpool-primary-color-mid)',
                    },
                    "&.Mui-checked": {
                        transform: 'translateX(16px)',
                        color: 'var(--xpool-primary-color-strong)',
                    },
                    "&.Mui-checked+.MuiSwitch-track": {
                        backgroundColor: 'var(--xpool-primary-color-mid)',
                    },
                },
                sizeSmall: {
                    marginLeft: '4px',
                },
                track: {
                    backgroundColor: 'var(--xpool-primary-color-mid)',
                },
                thumb: {
                    height: '14px',
                    width: '14px',
                }
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    marginRight: '-4px',
                    transform: "scale(0.9)",
                    color: 'var(--xpool-primary-color-strong)',
                    "&.Mui-disabled": {
                        color: 'var(--xpool-primary-color-weak)',
                    },
                    "&.Mui-checked": {
                        color: 'var(--xpool-primary-color-strong)',
                    },
                    "&.Mui-checked.Mui-disabled": {
                        color: 'var(--xpool-primary-color-mid)',
                    },
                }
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    transform: "scale(0.9)",
                    marginRight: '-4px',
                }
            },
        },
        MuiTouchRipple: {
            styleOverrides: {
                root: {
                    // transform: "scale(0.9)",
                    marginRight: '-3.5px',
                },
                rippleVisible: {
                    transform: "scale(0.9)",
                    marginTop: '2px',
                },
                ripplePulsate: {
                    // marginTop: '2px',
                }
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                sizeSmall: {
                    fontWeight: 'var(--xpool-field-value-font-weight)',
                    // fontSize: 'var(--xpool-standard-font-size)',
                    padding: '0px 5px 7px 5px',
                },
                root: {
                    textTransform: 'unset',
                    '&.Mui-selected': {
                        backgroundColor: 'var(--xpool-grid-selected-background-color)',
                        color: 'var(--xpool-body-text-color)',
                        fontWeight: 'var(--xpool-bold-font-weight)',
                        borderColor: 'var(--xpool-body-text-color)',
                    },
                }
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    background: 'var(--xpool-secondary-background-color)',
                    borderBottom: 'var(--xpool-grid-row-border)',
                }
            }
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'var(--xpool-font-family)',
        ].join(','),
    },
}

export function useMuiTheme(appStylingConfig) {
    const useMuiFieldStyle = useAppEnvProperty('useMuiFieldStyle');
    return useMemo(
        () => {
            return useMuiFieldStyle ?
                getXpMaterialThemeUseMuiStyle(appStylingConfig.muiStylingOverrides)
                :
                getXpMaterialTheme(appStylingConfig.muiStylingOverrides);
        },
        [appStylingConfig]
    );
}

const getXpMaterialTheme = (appOverrides) => createTheme({
    components: {
        ...newThemeStylingOverridesBase.components,
        ...appOverrides.components,
    },
    typography: newThemeStylingOverridesBase.typography,
});

const getXpMaterialThemeUseMuiStyle = (appOverrides) => createTheme({
    components: {
        ...newThemeStylingOverridesBase.components,
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'var(--xpool-font-family)',
                    fontSize: 'var(--xpool-standard-font-size)',
                    fontWeight: 'var(--xpool-field-value-font-weight)',
                    // background: 'white',
                    '&.Mui-disabled': {
                        background: 'none',
                        color: 'var(--xpool-body-text-color)',
                    },
                },
                input: {
                    // padding: '2px 5px 2px 5px',
                    // border:  'var(--xpool-form-field-border)',
                    // borderRadius: 'var(--xpool-form-field-border-radius) !important',
                    borderRadius: '0',
                },
                multiline: {
                    // padding: 'none',
                },
                inputMultiline: {
                    // padding: '2px 0 2px 5px',
                }
            },
        },
        MuiInput:{
            styleOverrides: {
/*
                underline: {
                    // This is magical. Must be exactly two '&' for hover and ripple bottom border to work, but remove the ugly dotted line when disabled.
                    "&&:before": {
                        borderBottom: "none"
                    },
                },
*/
                input: {
                    paddingTop: 4,
                    '&.Mui-disabled': {
                        // '-webkit-text-fill-color': 'var(--xpool-body-text-color)',
                        color: 'var(--xpool-body-text-color)',
                        WebkitTextFillColor: 'var(--xpool-body-text-color)',
                    },
                }
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    overflow: "unset",
                    margin: '0',
                    fontWeight: 'var(--xpool-label-font-weight)',
                    color: 'var(--xpool-label-text-color);',
                    '&.Mui-disabled': {
                        color: 'var(--xpool-label-text-color);',
                    },
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    overflow: "unset",
                    margin: '0',
                    fontWeight: 'var(--xpool-label-font-weight)',
                    color: 'var(--xpool-label-text-color);',
                    '&.Mui-disabled': {
                        color: 'var(--xpool-label-text-color);',
                    },
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
/*
                inputRoot: {
                    // padding: '2px 5px 2px 5px',
                    // paddingRight: '0px !important',
                    // border:  'var(--xpool-form-field-border)',
                    // borderRadius: 'var(--xpool-form-field-border-radius) !important',
                },
                input: {
                    // padding: '2px 30px 2px 5px !important',
                    // border:  'none',
                },
*/
                option: {
                    '&.Mui-focused': {
                        backgroundColor: 'var(--xpool-grid-selected-background-color) !important',
                    },
                },
            },
        },
        ...appOverrides.components,
    },
    typography: newThemeStylingOverridesBase.typography,
});
