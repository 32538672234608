import {genericFetch} from "gui-common/api/apiCommon";
import {globalApiHandle} from "gui-common/api/apiConstants";


export function postErpConnectionConfigToApi(erpIntegration, config) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Post ERP config connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.erpClientConfiguration + "?erpIntegrationId=" + erpIntegration.id + "&";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}
                    // windowPop(resp.redirectUri, 800, 700)
                },
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'POST',
                config,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function requestErpConnectionFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request ERP connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.ErpIntegration + "/" + erpIntegration.id + "/connect?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}
                    // windowPop(resp.redirectUri, 800, 700)
                },
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'POST',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
export function requestRevokeErpConnectionFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request ERP connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.ErpIntegration + "/" + erpIntegration.id + "/revoke?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'PUT',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
export function requestCancelErpConnectionFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request ERP connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.ErpIntegration + "/" + erpIntegration.id + "/connect-cancel?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'PUT',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function requestErpPayablesFromApi(erpIntegration, params) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request payables from ERP connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.erpDataRequest + "/" + erpIntegration.id + "/payables?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function requestErpReceivablesFromApi(erpIntegration, params) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request payables from ERP connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.erpDataRequest + "/" + erpIntegration.id + "/receivables?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function requestErpChartOfAccountsFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request chart of accounts from ERP connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.erpDataRequest + "/" + erpIntegration.id + "/chart-of-accounts?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}

export function requestErpVoucherSeriesFromApi(erpIntegration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Request verification series from ERP connection for " + erpIntegration.id;
            let requestUrl = globalApiHandle.baseUrls.erpDataRequest + "/" + erpIntegration.id + "/voucher-series?";
            dispatch(genericFetch(
                requestUrl,
                logText,
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                (resp) => {return (dispatch, getState) => {}},
                'GET',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
