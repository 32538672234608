import {createReducer} from 'gui-common/functions/reducer.js'

export const XP_TRANSLATED_INIT                  = 'XP_TRANSLATED_INIT';
export const XP_TRANSLATED_LOAD_TRANSLATIONS     = 'XP_TRANSLATED_LOAD_TRANSLATIONS';
export const XP_TRANSLATED_SET_ACTIVE_LANGUAGE   = 'XP_TRANSLATED_SET_ACTIVE_LANGUAGE';
export const XP_TRANSLATED_SET_EDIT_TEXT_MODE    = 'XP_TRANSLATED_SET_EDIT_TEXT_MODE';
export const XP_TRANSLATED_LOAD_TEXT_GENERATIONS = 'XP_TRANSLATED_LOAD_TEXT_GENERATIONS';

// Action creators ********************************************************
// ************************************************************************
export function xpTranslatedInit(languages, params) {
    return {
        type: XP_TRANSLATED_INIT,
        payload: {languages: languages, params: params},
    };
}
export function xpTranslatedLoadTranslations(translations) {
    return {
        type: XP_TRANSLATED_LOAD_TRANSLATIONS,
        payload: translations,
    };
}
export function xpTranslatedSetActiveLanguage(code) {
    return {
        type: XP_TRANSLATED_SET_ACTIVE_LANGUAGE,
        payload: code,
    };
}
export function xpTranslatedSetEditTextMode(editTextMode) {
    return {
        type: XP_TRANSLATED_SET_EDIT_TEXT_MODE,
        payload: editTextMode
    };
}
export function xpTranslatedLoadTextGenerations(textGenerations) {
    return {
        type: XP_TRANSLATED_LOAD_TEXT_GENERATIONS,
        payload: textGenerations
    };
}

// Reducer functions ********************************************************
// ************************************************************************
function init(state, payload) {
    if (payload?.defaultLanguage) {
        state.defaultLanguage = payload.defaultLanguage;
    }
    if (payload?.languages?.length) {
        const newLangArray = [...state.languages];
        const newIndexMap = {...state.langCodeToIndexMap};
        for (const newLang of payload?.languages) {
            if (newLangArray.find(item => item.code === newLang.code)) {
                continue;
            }
            newLangArray.push(newLang);
            newIndexMap[newLang.code] = newLangArray.length - 1;
        }
        state.languages = newLangArray;
        state.langCodeToIndexMap = newIndexMap;
    }
    return {...state};
}

function addTrKey(trKey, langArray, trState, languages) {
    if (Array.isArray(langArray)) {
        // This is a real translation, add to trState
        if (!trState[trKey]) {
            trState[trKey] = {}
        }
        for (const [index, lang] of langArray.entries()) {
            trState[trKey][languages[index].code] = [{text: lang}];
        }
        return;
    }
    for (const key in langArray) {
        addTrKey((trKey ? trKey + '.' : '') + key, langArray[key], trState, languages)
    }
}

/*
function addTrTree(newTree, targetTree, languages) {
    if (Array.isArray(newTree)) {
        // This is a real translation, add to trState
        for (const [index, lang] of newTree.entries()) {
            targetTree[languages[index].code] = lang;
        }
        return;
    }
    for (const key in newTree) {
        if (!targetTree[key]) {
            targetTree[key] = {}
        }
        addTrTree(newTree[key], targetTree[key], languages)
    }
}
*/


function loadTranslations(state, payload) {
    const newTrKeys = {...state.trKeys};
    addTrKey("", payload, newTrKeys, state.languages)

/*
    const newTrTree = {...state.trTree};
    addTrTree(payload, newTrTree, state.languages)
*/

    return {...state, trKeys: newTrKeys /*trTree: newTrTree*/};
}
function setActiveLanguage(state, payload) {
    return {...state, activeLanguage: payload}
}
function setEditTextMode(state, payload) {
    return {...state, editTextMode: payload}
}
function loadTextGenerations(state, payload) {
    if (!payload?.length) {
        return state;
    }

    let anythingChanged = false;
    for (const item of payload) {
        if (!item.textKey || !item.languageCode) {
            console.warn("Invalid textGeneration object in setTextGeneration", item);
            continue;
        }
        if (!state.trKeys[item.textKey]) {
            state.trKeys[item.textKey] = {isNewKey: true}
            anythingChanged = true;
        }

        // Add array with generations to the lang code if not already there. Also add system default as the first generation.
        if (!state.trKeys[item.textKey][item.languageCode]) { // Add array with generations to the lang code.
            state.trKeys[item.textKey][item.languageCode] = [{
                id              : 0,
                isSystemDefault : true,
                text            : "No system default text",
            }];
            anythingChanged = true;
        }
        // Check that the id of the item is not already in array. This will happen when the socket update is received for the initiating user.
        if (state.trKeys[item.textKey][item.languageCode].find(existing => existing.id === item.id)){
            continue;
        }
        const newLangArray = state.trKeys[item.textKey][item.languageCode];
        newLangArray.unshift(item);
        state.trKeys[item.textKey] = {...state.trKeys[item.textKey], [item.languageCode]: newLangArray};
        anythingChanged = true;
    }

    return anythingChanged ? {...state} : state;
}

const initialState = {
    defaultLanguage    : 'en',
    activeLanguage     : 'en',
    languages          : [{name: "English", code: "en"}, {name: "Svenska", code: "sv"}],
    editTextMode       : false,
    langCodeToIndexMap : {'en': 0, 'sv': 1},
    trKeys             : {},
};

export default createReducer(initialState, {
    [XP_TRANSLATED_INIT]                  : init,
    [XP_TRANSLATED_LOAD_TRANSLATIONS]     : loadTranslations,
    [XP_TRANSLATED_SET_ACTIVE_LANGUAGE]   : setActiveLanguage,
    [XP_TRANSLATED_SET_EDIT_TEXT_MODE]    : setEditTextMode,
    [XP_TRANSLATED_LOAD_TEXT_GENERATIONS] : loadTextGenerations,
});
