import PropTypes from "prop-types";
import React, {useEffect, useMemo, useRef, useState} from "react";
import XpFormFieldGroup from "gui-common/xpForm/XpFormFieldGroup";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {useRunConfigurationConfig} from "gui-common/runConfiguration/runConfigurationFunctions";
import {useSelector} from "react-redux";
import XpIconButton from "gui-common/components/XpIconButton";
import {useXpFormContext, useXpFormFields} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormIsReadOnly} from "gui-common/xpForm/core/xpFormFunctions";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


function RunConfigurationForm (props) {

    const formContext = useXpFormContext()
    const runTypeConfig = useRunConfigurationConfig(props.type);
    const dependentFields  = useXpFormFields({
        dependentFields: {
            ...runTypeConfig.formDependentFields,
            ownedByParent               : 'ownedByParent',
            isDeleted                   : 'isDeleted',
            includedInRunConfiguration  : 'includedInRunConfiguration',
            isActive                    : 'isActive',
            id                          : 'id',
            fromOrmModel                : 'fromOrmModel',
        }
    });
    const translate  = useSelector(selectTranslateFunction);
    const [containerHeight, setContainerHeight] = useState( props.justCreated ? 0 : 'auto');
    const [isClosing      , setIsClosing      ] = useState(false);
    const [isClosed       , setIsClosed       ] = useState(false);

    const containerRef = useRef(null);
    const contentRef   = useRef(null);

    useEffect(
        () => {
            if (containerHeight === 'auto') return;
            if (!isClosing) return;
            setContainerHeight(0);
        },
        [isClosing, containerHeight],
    );
    useEffect(
        () => {
            setTimeout(() => {
                if (props.justCreated) setContainerHeight(contentRef?.current?.scrollHeight ? contentRef.current.scrollHeight : 100)
            }, 50);
        },
        [],
    );

/*
    const entityDataToUse = useMemo(
        () => {
            return (props.entityData.form.ownedByParent && (props.formState === 'new')) ? {form: props.entityData.form, current: props.entityData.form} : props.entityData;
        },
        [props.entityData, props.formState]
    );
*/
    const buttonType = useMemo(
        () => {
            if (!runTypeConfig) return undefined;
            if (xpFormIsReadOnly(formContext) || !props.userCanManage) return undefined;
            if (!dependentFields.ownedByParent) return dependentFields.isDeleted ? 'add' : 'delete';
            return dependentFields.includedInRunConfiguration ? 'minus' : 'add'
        },
        [dependentFields, formContext]
    );
    const disableAddRemoveButton = useMemo(
        () => {
            if ((buttonType === 'add') && props.maxElementsReached) return true;
            return false;
        },
        [buttonType, props.maxElementsReached]
    );
    const addRemoveButtonToolTip = useMemo(
        () => {
            if (!runTypeConfig) return "";
            if (disableAddRemoveButton)        return formContext.formTemplate + '.maxElementsReached'
            if (dependentFields.ownedByParent) return formContext.formTemplate + ((buttonType === 'add') ? '.reActivateParentConfiguration' : '.removeParentTooltip')
            return formContext.formTemplate + ((buttonType === 'add') ? '.addTooltip' : '.removeTooltip')
        },
        [disableAddRemoveButton, dependentFields, formContext?.formTemplate]
    );


    if (!runTypeConfig) return null;

    function isExpanded() {
        if (!dependentFields.isActive) return false;
        if (dependentFields.ownedByParent && !dependentFields.includedInRunConfiguration) return false;
        return true;
    }

    return (
        <div
            ref={containerRef}
            className={(isClosing || (props.justCreated && (containerHeight !== 'auto'))) ? " formFieldsGroupContainerCloseTransition" : ""}
            style={{height: containerHeight}}
            onTransitionEnd={() => {
                if (isClosing && !isClosed) {
                    // console.log("removeCb: ", props.index);
                    props.removeCb();
                    setIsClosed(true);
                    return;
                }
                if (props.justCreated) {
                    setContainerHeight('auto');
                }
            }}
        >
            <div
                ref = {contentRef}
            >
                <XpFormFieldGroup
                    bypassControlComponent = {props.bypassControlComponent}
                    bypass            = {runTypeConfig.exactlyOneRequired || props.enforceExactlyOneRequired}
                    className         = {'runConfigurationFormFieldsGroup ' + (dependentFields.ownedByParent ? 'ownedByParentRunConfig ' : ' ') + props.className}
                    isExpanded        = {isExpanded()}
                    removeComponent = {
                        <XpIconButton
                            type        = {buttonType}
                            toolTipKey  = {addRemoveButtonToolTip}
                            onClickCb   = {() => {
                                if ((buttonType === 'delete') && (!dependentFields.id)) {
                                    setContainerHeight(containerRef.current.scrollHeight + 'px');
                                    setIsClosing(true);
                                }
                                else {
                                    props.removeCb()
                                }
                            }}
                            hidden      = {xpFormIsReadOnly(formContext) || !props.userCanManage}
                            disabled    = {disableAddRemoveButton}
                        />
                    }
                    controlComponent  = {
                        <XpFormSwitchInput
                            field         = "isActive"
                            // noMargin
                            label         = { props.getActivationSwitchLabel ? props.getActivationSwitchLabel(dependentFields) :
                                (<span>
                                    {dependentFields.ownedByParent &&
                                    <XpTranslated trKey={'runConfiguration.fromParent'}/>}
                                    {dependentFields.ownedByParent &&
                                    <XpTranslated trKey={'general.modelNamesSmall.' + dependentFields.fromOrmModel}/>}
                                    {dependentFields.ownedByParent && ': '}
                                    <XpTranslated
                                        trKey      = {runTypeConfig.getLabelKey    ? runTypeConfig.getLabelKey(   formContext, dependentFields) : undefined}
                                        trParams   = {runTypeConfig.getLabelParams ? runTypeConfig.getLabelParams(formContext, dependentFields) : undefined}
                                    />
                                </span>)
                            }
                            // labelKey      = {runTypeConfig.getLabelKey    ? runTypeConfig.getLabelKey(   formContext, dependentFields) : undefined}
                            // labelParams   = {runTypeConfig.getLabelParams ? runTypeConfig.getLabelParams(formContext, dependentFields) : undefined}
                            toolTipKey    = {dependentFields.ownedByParent ? 'runConfiguration.ownedByParentTooltip' : undefined}
                            toolTipParams = {{parentType: translate('general.modelNamesSmall.' + dependentFields.fromOrmModel)}}
                            disabled      = {dependentFields.ownedByParent || props.isDeleted || !props.userCanManage}
                            forceDisabledUncheck = {dependentFields.ownedByParent && !dependentFields.includedInRunConfiguration}
                        />
                    }
                >
                    <runTypeConfig.formComponent
                        {...props}
                        disabled   = {(!formContext?.auditMode && !props.userCanManage) || dependentFields.ownedByParent || props.isDeleted}
                    />
                </XpFormFieldGroup>
            </div>
        </div>
    )
}
RunConfigurationForm.propTypes = {
    type        : PropTypes.string.isRequired,
    removeCb    : PropTypes.func,
    userCanDelete: PropTypes.bool,
    className   : PropTypes.string,
};
export default RunConfigurationForm



