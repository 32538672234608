import PropTypes from "prop-types";
import React, {useMemo} from "react";
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import ReactJson from "react-json-view";



function addObjectProps(path, propObject, propArray) {
    for (let propKey in propObject) {
        if (Array.isArray(propObject[propKey])) {
            propArray.push({propName: path + propKey + '.header', propValue: propObject[propKey].map(item => {
                    return addObjectProps((path + propKey + '.subProps.' ), item, []);
                })});
        }
        else if (typeof propObject[propKey] === 'object') {
            return addObjectProps((path + propKey + '.' ), propObject[propKey], propArray);
        }
        else {
            propArray.push({propName: path + propKey, propValue: propObject[propKey]});
        }
    }
    return propArray
}

function renderActionProp(auditEntry, propToRender, index) {
    if (Array.isArray(propToRender.propValue)) {
        return (
            <XpAccordion
                header          = {<XpTranslated trKey={'auditUserActionTypes.' + auditEntry.actionType + '.actionProps.' + propToRender.propName}/>}
                headerClassName = "textGenerationsHeader"
                instanceId      = {'auditAction-' + auditEntry.actionType + "-" + propToRender.propName}
                bypass          = {!propToRender.propValue?.length}
            >
                {propToRender.propValue.map((subItem, subIndex) => {
                    return (
                        <div className='xpAdminFormEntityCard' key={subIndex}>
                            {subItem.map((subProp, subPropIndex) => renderActionProp(auditEntry, subProp, subPropIndex))}
                        </div>
                    )
                })}
            </XpAccordion>
        )
    }
    else {
        return (
            <XpFormStaticField key={index} inLineLayout labelTrKey={'auditUserActionTypes.' + auditEntry.actionType + '.actionProps.' + propToRender.propName} value={propToRender.propValue}/>
        )
    }
}

function AuditActionDataRenderer (props) {
    const propsToRender = useMemo(
        () => {
            let actionData = props.auditEntry.actionData;
            if (!actionData && props.actionConfig?.getActionData) { // Typically the case in the new audit implementation
                actionData = props.actionConfig?.getActionData(props.auditEntry);
            }
            return actionData ? addObjectProps("", actionData, []) : [];
        },
        [props.auditEntry]
    );
    // className="adminColumn auditActionBaseColumn"
    return (
        <div style={{paddingLeft: '10px'}}>
            <h5 style={{marginBottom: '5px', borderBottom: 'var(--xpool-form-field-border)'}}><XpTranslated trKey={'auditView.actionRenderer.actionInfo'}/></h5>
            {propsToRender.map((propToRender, index) => renderActionProp(props.auditEntry, propToRender, index))}
            {props.auditEntry.actionInfo && Object.keys(props.auditEntry.actionInfo).map(actionKey => {
                if (actionKey === 'Action') {
                    return null;
                }
                return (
                    <XpFormStaticField key={actionKey} inLineLayout fixedLabelWidth={150} labelTrKey={'auditView.actionInfoKeys.' + actionKey}  value={props.auditEntry.actionInfo[actionKey]}/>
                )
            })}
            <h5 style={{marginBottom: '5px', borderBottom: 'var(--xpool-form-field-border)'}}><XpTranslated trKey={'auditView.actionRenderer.jsonDataView'}/></h5>
            <div style={{fontSize: 'var(--xpool-label-font-size)'}}>
                <ReactJson collapsed displayObjectSize sortKeys name={'data'} iconStyle={'circle'} src={props.auditEntry.data} />
            </div>
        </div>
    )
}
AuditActionDataRenderer.propTypes = {
    auditEntry: PropTypes.object.isRequired,
};
export default AuditActionDataRenderer



