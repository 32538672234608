import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {activeFixSessionsSelector} from "gui-common/orm/ormSelectors";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

export function LegalEntityUnitFxSettings (props) {
    const fixSessions         = useSelector(activeFixSessionsSelector);
    const isSandbox = useAppEnvProperty('isSandbox');
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');
    const formDataFixSessionId = useXpFormField('fixSessionId');
    const lrActive = useRefixServiceIsActive(refixServices.ListRates);
    const moActive = useRefixServiceIsActive(refixServices.MarketOrder);

    if (formDataPriceFeedOnly) {
        return null;
    }
    if(!lrActive && !moActive) {
        return null;
    }
    return (
        <div className={props.className}>
            <XpFormSelectorInput
                inLineLayout showTemplateInView
                field="fixSessionId"
                selectList={fixSessions}
                itemTextField="name"
                // defaultValue={fxProviders?.length ? fxProviders[0].id : null}
                dependentFields={{
                    enableMarketOrder: 'enableMarketOrder',
                    enableListrates: 'enableListrates',
                }}
                errors={{
                    noFxProviderWithFxServicesActivated    : (val, fields) => !val && (fields.enableMarketOrder || fields.enableListrates),
                }}
            />
            {formDataFixSessionId &&
            <XpFormTextInput
                isRequired inLineLayout
                field= "fxShortName"
                checkNoSpaces={true}
                checkOnlySpaces={false}
                minLength={2}
                maxLength={isSandbox ? undefined : 20}
            />}
         </div>
    );
}

LegalEntityUnitFxSettings.propTypes = {
    className:        PropTypes.string,
};

