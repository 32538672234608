import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {prepareLegalEntityGridColumns} from "features/legalEntity/legalEntityFunctions";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";
import {getLegalEntitiesListDataSelector} from "features/legalEntity/legalEntitySelectors";


export default function LegalEntitiesList (props) {
    const translate = useSelector(selectTranslateFunction);
    const columnDefs = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                name:               false,
                description:        false,
                id:                 true,
                countryName:        false,
                // legalEntityType:    true,
                lei:                false,
                createdDateTime:    true,
                createdByUser:      true,
                changedDateTime:    true,
                changedByUser:      true,
            };
            return prepareLegalEntityGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );

    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            trKeyBase='legalEntitiesList'
            ormModel='LegalEntity'
            {...props}
            getRowDataSelector   = {getLegalEntitiesListDataSelector}
            rowDataSelectorProps = {props.rowDataSelectorProps}
        />
    )
}
