import {ormEntityCreated, ormEntityDeleted, ormEntityRefreshed, ormEntityUpdated} from "gui-common/orm/ormReducer";

const ormCreate         = {batchPossible: true, ormEventType: 'create', functionToDispatch: (itemType, item) => ormEntityCreated(itemType, item)}
const ormUpdate         = {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)}
const ormUpdateDelete   = {batchPossible: true, ormEventType: 'delete', functionToDispatch: (itemType, item) => ormEntityUpdated(itemType, item)}
const ormDelete         = {batchPossible: true, ormEventType: 'delete', functionToDispatch: (itemType, item) => ormEntityDeleted(itemType, item)}
const ormRefreshed      = {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityRefreshed(itemType, item)}
export const ormFunctionMap = {
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Refresh' : {batchPossible: true, ormEventType: 'update', functionToDispatch: (itemType, item) => ormEntityRefreshed(itemType, item)},
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Create': ormUpdate,
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Update': ormUpdate,
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Delete': ormUpdateDelete,
};
export const ormFunctionMapDeleted = {
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Refresh' : ormRefreshed,
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Create'  : ormUpdate,
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Update'  : ormUpdate,
    'se.nordicfc.oxygen.aaa.authentication.controllers.websocket.message.functions.Delete'  : ormDelete,
};
