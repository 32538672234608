import React from "react";

export default function XpFlexContainer (props) {

    return (
        <div className={'xpFlexContainer'}>
            {props.children}
        </div>
    )
}



