import {createSelector} from "redux-orm";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {orm} from "gui-common/orm/orm";

export const activePeriodsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.ListRatePeriod.all().filter({enabled: true}).orderBy('duration', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);

export const activeDispatchPeriodsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.DispatchPeriod.all().filter({enabled: true}).orderBy('duration', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);

export const activePriceFeedsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.PriceFeed.all().orderBy('name', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
        // return [{id:23, name: "Fish feed"}]
    }
);
export const customerPriceFeedsSelector = createSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.PriceFeed.all().filter({priceFeedType: "CUSTOMER"}).orderBy('name', 'asc').toModelArray().map(item => item.ref);
        return returnArray;
        // return [{id:23, name: "Fish feed"}]
    }
);

/********* Order selectors *****************************************************************/
/*
//const clientBankAccountsSelector = makeClientBankAccountsSelector();
export const activeClientOrderListSelector = function() {
    return(
        createSelector(
            orm,
            dbStateSelector,
            //    state => state.poolAccountsState.selectedAccountId,
            //    state => state.cashPoolState.selectedAccountId,
            //    state => clientBankAccountsSelector(state),
            state => selectActiveClientId(state),
            (session, activeClientId) => {

                //        console.log("Selector activeClientOrderListSelector running ", activeClientId);

                let returnArray = [];
                session.Order.filter({clientId: activeClientId}).orderBy('id', 'desc').toModelArray().forEach(order => {

                    const obj = order.ref;

                    returnArray.push(Object.assign({}, obj, {
                        client: session.Client.withId(obj.clientId).ref,
                        currencyPairStr: session.CurrencyPair.idExists(obj.currencyPairId) ? session.CurrencyPair.withId(obj.currencyPairId).ref.key : "",
                        buySellIndicator: session.FxPrice.idExists(obj.fxPriceId) ? session.FxPrice.withId(obj.fxPriceId).ref.indicator : "",
                        baseCurrency: session.Currency.idExists(obj.baseCurrencyId) ? session.Currency.withId(obj.baseCurrencyId).ref.currency : "",
                    }));
                });
                //        console.log("Selected cash pool orders:", returnArray);
                return returnArray;
            }
        )
    )
};

const setBuySell = (orderType, buySell) => { // This is required since far leg should be shown for Swaps.
    if (orderType !== 'FxSwap')  return buySell;
    if (buySell === 'Buy')  return 'Sell';
    if (buySell === 'Sell')  return 'Buy';
    return buySell;
};
export const allClientsOrderListSelector = function() {
    return(
    createSelector(
        orm,
        dbStateSelector,
        (session) => {
            let returnArray = [];
            session.Order.all().orderBy('id', 'desc').toModelArray().forEach(order => {

                const obj = order.ref;
                // MT: Check that the referenced client exist
                if (!session.Client.idExists(obj.clientId)) return;

                returnArray.push(Object.assign({}, obj, {
                    client: session.Client.withId(obj.clientId).ref,
                    currencyPairStr: session.CurrencyPair.idExists(obj.currencyPairId) ? session.CurrencyPair.withId(obj.currencyPairId).ref.key : "",
                    buySellIndicator: session.FxPrice.idExists(obj.fxPriceId) ? setBuySell(obj.orderType, session.FxPrice.withId(obj.fxPriceId).ref.indicator) : "",
                    baseCurrency: session.Currency.idExists(obj.baseCurrencyId) ? session.Currency.withId(obj.baseCurrencyId).ref.currency : "",
                }));
            });
            //        console.log("Selected all client orders:", returnArray);
            return returnArray;
        }
    )
    )
};
*/
