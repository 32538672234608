import {ormFunctionMap} from "gui-common/api/ormSocketMethodMaps";
import {commonWebSocketEvents} from "gui-common/api/webSocketConfig";

/* -----------------------------------------------------------------------------------------------------------------
* Socket message dictionaries for models and message types specific to the application. Common messages in gui-common
* -----------------------------------------------------------------------------------------------------------------*/
export const refixWebSocketEvents = {
    ...commonWebSocketEvents,
    'CLIENT'                : 'se.nordicfc.refix.common.api.model.legal.RefixApiShallowClient',
    'LEGAL_ENTITY'          : 'se.nordicfc.refix.common.api.model.legal.RefixApiShallowLegalEntity',
    'LEGAL_ENTITY_UNIT'     : 'se.nordicfc.refix.common.api.model.legal.RefixApiShallowLegalEntityUnit',
    'AGREEMENT'             : 'se.nordicfc.refix.common.api.model.agreement.RefixApiShallowAgreement',
    'LIST_RATE'             : 'se.nordicfc.refix.listrate.admin.api.model.RefixAdminApiShallowListRate',
    'LIST_RATE_ORDER'       : 'se.nordicfc.refix.listrate.admin.api.model.RefixAdminApiShallowListRateOrder',
    'DISPATCH_TRADE'        : 'se.nordicfc.refix.listrate.admin.api.model.RefixAdminApiShallowDispatchTrade',
    'MARKET_ORDER'          : 'se.nordicfc.refix.market.admin.api.model.RefixApiMarketOrder',
    'PRICE_LEU'             : 'se.nordicfc.refix.common.model.event.legal.PriceEventLegalEntityUnit',
    'MARGIN_GROUP'          : 'se.nordicfc.oxygen.pricemargin.model.PriceMarginGroup',
    'PRICE_TOLERANCE'       : 'se.nordicfc.refix.common.api.model.listrate.RefixApiPriceToleranceConfiguration',
    'LIST_RATE_ORDER_RESULT': 'se.nordicfc.refix.common.model.result.ListRateOrderResult',
    'TRADE_REPORT_DELIVERY' : 'se.nordicfc.refix.common.api.model.fxtradereport.RefixApiShallowFxTradeReportDelivery',
    'TRADE_REPORT_ERP_CONFIG' : 'se.nordicfc.refix.common.model.shallow.fxtradereport.ShallowFxTradeReportErpConfiguration'
}
export const refixProcessWebSocketEventMap = {
    [refixWebSocketEvents.CLIENT]                   : { batchPossible: false, model: 'Client'               , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LEGAL_ENTITY]             : { batchPossible: false, model: 'LegalEntity'          , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LEGAL_ENTITY_UNIT]        : { batchPossible: false, model: 'LegalEntityUnit'      , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.AGREEMENT]                : { batchPossible: false, model: 'Agreement'            , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LIST_RATE]                : { batchPossible: true , model: 'ListRate'             , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LIST_RATE_ORDER]          : { batchPossible: true , model: 'ListRateOrder'        , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.DISPATCH_TRADE]           : { batchPossible: true , model: 'DispatchTrade'        , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.MARKET_ORDER]             : { batchPossible: true , model: 'MarketOrder'          , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.PRICE_LEU]                : { batchPossible: true , model: 'LegalEntityUnit'      , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.MARGIN_GROUP]             : { batchPossible: true , model: 'MarginGroup'          , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.PRICE_TOLERANCE]          : { batchPossible: true , model: 'PriceTolerances'      , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.LIST_RATE_ORDER_RESULT]   : { batchPossible: true , model: 'ListRateOrderResult'  , functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.TRADE_REPORT_DELIVERY]    : { batchPossible: true , model: 'FxTradeReportDelivery', functionMap: ormFunctionMap, logMessage: false },
    [refixWebSocketEvents.TRADE_REPORT_ERP_CONFIG]  : { batchPossible: true , model: 'FxTradeReportErpConfig', functionMap: ormFunctionMap, logMessage: false },
};
export const refixFilterWebSocketEventMap = {}
