import React from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {activeCurrenciesSelector} from "gui-common/orm/ormSelectors";
import {stringNumberToFloat} from "gui-common/numberFormat/numberFormatFunctions";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormAmountInput from "gui-common/xpForm/core/XpFormAmountInput";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

export function FormTradingLimit (props) {
    const ormCurrencies   = useSelector(activeCurrenciesSelector);
    const decDenLangState = useSelector(selectDecDenLangState);
    const formDataPriceFeedOnly = useXpFormField('priceFeedOnly');
    const formDataTodayLimitOverride = useXpFormField('todayLimitOverride');
    const formContext = useXpFormContext();
    const lrActive = useRefixServiceIsActive(refixServices.ListRates);

    if (formDataPriceFeedOnly) {
        return null;
    }
    if (!lrActive) {
        return null;
    }
    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired inLineLayout alignRight noTemplate
                field="tradingLimitCurrencyId"
                selectList    = {ormCurrencies}
                itemTextField = "currency"
                inLineDivider={30}
            />
            <XpFormAmountInput
                isRequired inLineLayout
                field         = "tradingLimit"
                errors={{
                    lowerThanZero:     (val) => /\d/.test(val) && (stringNumberToFloat(val, decDenLangState) < 0),
                }}
            />
            {(formContext.auditMode && (formDataTodayLimitOverride > 0)) &&
            <XpFormAmountInput
                inLineLayout
                field         = "todayLimitOverride"
            />}
            <XpFormSwitchInput
                field         = "netTradingLimit"
            />
        </div>
    );
}
FormTradingLimit.propTypes = {
    className:        PropTypes.string,
};
