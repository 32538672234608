import {createSelector as ormCreateSelector} from "redux-orm";
import {createSelector} from "reselect";
import {
    activeCurrencyPairsSelector,
    activeTenorsSelector,
    getActiveCurrencyPairsSelector,
    getOrmObjectWithId,
} from "gui-common/orm/ormSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {buildListRateObject} from "features/listRate/listRateSelectors";
import {createTranslatedFieldData, translateBoolFields,} from "gui-common/xpGrid/xpGridFunctions";
import {
    createMomentObjectFromUtcTimestamp,
    getCurrencyPairString
} from "gui-common/functions/functions";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {stateRequestedOnId} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {getAuthorisedAgreementTypes, getTenorsToMap} from "features/agreement/agreementFunctions";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {activePeriodsSelector} from "appConfig/orm/ormSelectors";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";


export const getCleanAgreementsSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => (props && props.filterFunction) ? props.filterFunction : undefined,
    (session, filterFunction) => {
        const returnArray = filterFunction
            ? session.Agreement.filter(filterFunction).orderBy('id', 'desc').toModelArray().map(item => item.ref)
            : session.Agreement.orderBy('id', 'desc').toModelArray().map(item => item.ref);
        return returnArray;
    }
);

const getTenorsString = (ormTenors, tenors) => {
    if (!tenors?.length) {
        return "";
    }
    let retStr = "";
    tenors.forEach(tenor => {
        retStr += ', ' + tenor;
    });
    return retStr.slice(2);
};
function getCurrencyPairStrAndName(agreement) {
    if (!agreement.currencyPair) {
        console.error("No currency pair in agreement object: ", agreement);
        return {};
    }
    return {
        currencyPairStr : getCurrencyPairString(agreement.currencyPair),
        name            : getCurrencyPairString(agreement.currencyPair),
        baseCurrency    : agreement.currencyPair.baseCurrency.currency,
        quoteCurrency   : agreement.currencyPair.quoteCurrency.currency,
    }
}
export function buildEnrichedRefixAgreement(session, agreementModel) {
    const agreementRef = agreementModel.ref;

    const legalEntityUnit = agreementModel.legalEntityUnitId;
    const legalEntity     = !legalEntityUnit ? null : legalEntityUnit.legalEntityId;
    const client          = !legalEntity     ? null : legalEntity.clientId;

    let typeSpecificData = {guiStatus: agreementRef.status};

    if (['ListRateAgreement', 'MarketOrderAgreement'].indexOf(agreementRef.type) !== -1) {
        typeSpecificData = {...typeSpecificData, ...getCurrencyPairStrAndName, ...getCurrencyPairStrAndName(agreementRef)};
    }
    if (agreementRef.type === 'MarketOrderAllAgreement') typeSpecificData.name = 'MarketOrderAllAgreement';

    if (['MarketOrderAgreement', 'MarketOrderAllAgreement'].indexOf(agreementRef.type) !== -1) {
        const includeTenor = (tenor) => (tenor.validAgreementTypes.indexOf(agreementRef.type) !== -1);
        const ormTenors = session.Tenor.filter(includeTenor).orderBy('sortIndex', 'asc').toModelArray().map(tenor => tenor.ref);

        typeSpecificData.tenorsMap = getTenorsToMap( ormTenors, agreementRef.tenorsMap)
        typeSpecificData.tenor  = getTenorsString(ormTenors, agreementRef.tenors);
        typeSpecificData.dispatchLevel = "";
    }
    if (agreementRef.type === 'ListRateAgreement') {
        typeSpecificData.isActive     = false;
        typeSpecificData.buyAmount    = "";
        typeSpecificData.sellAmount   = "";
        typeSpecificData.gracePeriod  = (agreementRef.gracePeriod >= 0) ? String(agreementRef.gracePeriod) : "";
        typeSpecificData.gracePeriodT = (agreementRef.gracePeriod >= 0) ? agreementRef.gracePeriod + 's'   : "";
        typeSpecificData.listRatePeriodT = agreementRef.listRatePeriod ? agreementRef.listRatePeriod.period : "";
        typeSpecificData.dispatchTradePeriodT = agreementRef.dispatchTradePeriod ? agreementRef.dispatchTradePeriod.period : "";
        typeSpecificData.exemptFromGlobalSuspend = legalEntityUnit ? legalEntityUnit.ref.exemptFromGlobalSuspend : "";

        const listRates = agreementModel.listRates.filter(item => (['Active', 'InExpiry'].indexOf(item.status) !== -1)).toModelArray();
        typeSpecificData.listRates = listRates ? listRates.map(listRate => buildListRateObject(listRate)) : [];

        if (listRates && listRates.length) {
            typeSpecificData.buyAmount        = 0;
            typeSpecificData.sellAmount       = 0;
            typeSpecificData.isActive         = true;
            typeSpecificData.timeExpire       = null;
            typeSpecificData.nextDispatchTime = null;

            for (let listRate of typeSpecificData.listRates) {
                listRate.exemptFromGlobalSuspend = typeSpecificData.exemptFromGlobalSuspend;
                typeSpecificData.buyAmount += listRate.buyAmount;
                typeSpecificData.sellAmount += listRate.sellAmount;

                const listRateExpireMomentObject = createMomentObjectFromUtcTimestamp(listRate.timeExpire);
                if (!typeSpecificData.timeExpire || listRateExpireMomentObject.isAfter(typeSpecificData.timeExpire)) typeSpecificData.timeExpire = listRateExpireMomentObject;

                const listRateDispatchMomentObject = createMomentObjectFromUtcTimestamp(listRate.nextDispatchTime);
                if (!typeSpecificData.nextDispatchTime || listRateDispatchMomentObject.isBefore(typeSpecificData.nextDispatchTime)) typeSpecificData.nextDispatchTime = listRateDispatchMomentObject;
            }
            typeSpecificData.timeExpire       = typeSpecificData.timeExpire       ? typeSpecificData.timeExpire.toISOString()       : "";
            typeSpecificData.nextDispatchTime = typeSpecificData.nextDispatchTime ? typeSpecificData.nextDispatchTime.toISOString() : "";
        }
        if ((agreementRef.status === 'Active') && !typeSpecificData.isActive) typeSpecificData.guiStatus = "InActive";
    }
    if (['ReferenceRateAgreement', 'PriceFeedAgreement'].indexOf(agreementRef.type) !== -1) {
        typeSpecificData.dispatchLevel = "";
    }
    if (agreementRef.type === 'TradeReportAgreement') {
        // todo 2023-12-06: Add specific data to be displayed in agreements list
    }


    return {
        ...agreementRef,
        ...getLastChange('Agreement', agreementModel),
        client              : client            ? client.ref                : null,
        legalEntity         : legalEntity       ? legalEntity.ref           : null,
        legalEntityUnit     : legalEntityUnit   ? legalEntityUnit.ref       : null,
        clientId            : client            ? client.ref.id             : null,
        legalEntityId       : legalEntity       ? legalEntity.ref.id        : null,
        legalEntityUnitId   : legalEntityUnit   ? legalEntityUnit.ref.id    : null,
        clientName          : client            ? client.ref.name           : "",
        legalEntityName     : legalEntity       ? legalEntity.ref.name      : "",
        legalEntityUnitName : legalEntityUnit   ? legalEntityUnit.ref.name  : "",
        clientApiChannel    : client            ? client.ref.clientApiChannel : "",
        ...typeSpecificData,
    };
}

export const getReFixAgreementSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => (props && props.nestedEntityLevels) ? props.nestedEntityLevels : undefined,
    (state, props) => (props && props.selectId) ? props.selectId : undefined,
    (session, nestedEntityLevels, selectId) => {
        const agreement = getOrmObjectWithId(session, 'Agreement', selectId);
        if (!agreement) return undefined;

        const enrichedAgreement = buildEnrichedRefixAgreement(session, agreement);
        return enrichedAgreement;
    }
);
export const reFixAgreementSelector = getReFixAgreementSelector();

export const reFixAgreementsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector reFixAgreementsSelector running ");
        let returnArray = [];

        const agreements = session.Agreement.filter(entityIsActive).orderBy('id', 'desc').toModelArray();
        for (let agreement of agreements) {
            // Now, build the agreement
            const enrichedAgreement = buildEnrichedRefixAgreement(session, agreement);
            if (enrichedAgreement) returnArray.push(enrichedAgreement);
        }
        // console.log("reFixAgreementsSelector done :", returnArray);
        return returnArray;
    }
);

const agreementsStateRequestSelector = getRequestingStateOnModelSelector();
const translate          = state => selectTranslateFunction(state);
const agreementRequestState = state => agreementsStateRequestSelector(state, {model: 'Agreement'})
const enrichedAgreements = (state, props) => props.inputData ? props.inputData : reFixAgreementsSelector(state);
const filterFunction    = (state, props) => props ? props.filterFunction : undefined;
const agreementTypes    = (state, props) => props ? props.agreementTypes : ['ListRateAgreement', 'MarketOrderAgreement', 'MarketOrderAllAgreement'];
export const getAgreementsListDataSelector = () => createSelector(
    [enrichedAgreements, translate, agreementRequestState, filterFunction, agreementTypes],
    (enrichedAgreements, translate, agreementRequestState, filterFunction, agreementTypes) => {
        if (!enrichedAgreements) return [];
        if (!translate) return [];
        // console.log("Selector getAgreementsListDataSelector running ", agreementRequestState, filterFunction, agreementTypes);

        let returnArray = [];
        for (let agreement of enrichedAgreements) {
            if (!agreement) continue;
            if (agreementTypes && agreementTypes.indexOf(agreement.type) === -1) continue;
            if (filterFunction && !filterFunction(agreement)) continue;
            translateBoolFields(agreement, translate);
            createTranslatedFieldData(agreement, 'clientApiChannel', translate, "clientForm.clientApiChannel.values." + agreement.clientApiChannel);
            createTranslatedFieldData(agreement, 'guiStatus'     , translate, ("agreementsList.statusTypes." + agreement.guiStatus), undefined, stateRequestedOnId(agreementRequestState, agreement.id));
            createTranslatedFieldData(agreement, 'type'          , translate, ("agreementForm.type.values." + agreement.type      ), undefined);
            if (agreement.type === 'ListRateAgreement') {
                createTranslatedFieldData(agreement, 'meansOfPayment', translate, ("agreementForm.meansOfPayment.values." + agreement.meansOfPayment      ), undefined);
            }

            // In audit mode, the enrichedAgreements are not enriched from the enrichment function. Currency pair Str and name must be set here.
            if ((['ListRateAgreement', 'MarketOrderAgreement'].indexOf(agreement.type) !== -1) && !agreement.currencyPairStr) {
                agreement = {...agreement, ...getCurrencyPairStrAndName(agreement)};
            }
            if (agreement.type === 'TradeReportAgreement') {
                // todo 2023-12-06: Add specifics...
            }


            returnArray.push({...agreement});
        }
        // console.log("getAgreementsListDataSelector done :", returnArray);
        return returnArray;
    }
);

/********* New agreement template selector *****************************************************************/

const currencyPairs             = state => getActiveCurrencyPairsSelector()(state, {agreementType: 'ListRateAgreement'});
const listRatePeriods           = state => activePeriodsSelector(state);
const tenors                    = state => activeTenorsSelector(state, {agreementType: 'ListRateAgreement'});
const parentItemData            = (state, props) => props ? props.parentItemData : undefined;
export const newListRateAgreementFormTemplateSelector = createSelector(
    [currencyPairs, listRatePeriods, tenors, parentItemData],
    (currencyPairs, listRatePeriods, tenors, parentItemData) => {
        let authorizedAgreements = parentItemData ? getAuthorisedAgreementTypes(parentItemData) : [];

        if (parentItemData && parentItemData.priceFeedOnly) authorizedAgreements = authorizedAgreements.filter(item => item === 'PriceFeedAgreement');

        const defaultType = authorizedAgreements?.length ? authorizedAgreements[0] : "";
        return {
            id: "",
            legalEntityUnitId: parentItemData ? parentItemData.id : undefined, // Set legalEntityUnitId from super entity legalEntityUnit, if provided
            currencyPairId: (currencyPairs[0] && currencyPairs[0].id) ? currencyPairs[0].id : "",
            name: defaultType,
            listRatePeriodId: (listRatePeriods[0] && listRatePeriods[0].id) ? listRatePeriods[0].id : "",
            tenor: tenors[0] ? tenors[0].name : "",
            dispatchTradePeriodId: "",
            buySellType: "BuySell", // BuySell, Buy or Sell
            meansOfPayment: 'HEDGE',
            dispatchLevel: "",
            gracePeriod: "",
            openTime: "",
            closeTime: "",
            comment: "",
            type: defaultType,
            createdDateTime: null,
            createdByUser: "",
            changedDateTime: null,
            changedByUser: "",
        }
    }
);

