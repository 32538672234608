import React, {useMemo} from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {pushModalWindow} from "redux-promising-modals";
import {useXpFormContext} from "gui-common/xpForm/core/xpFormHooks";
import {XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {
    operatingUnitsSelector,
    operatingUnitsWithFullClientCreateSelector
} from "features/operatingUnit/operatingUnitSelectors";

export function ClientBaseStatic (props) {
    const formContext = useXpFormContext();
    const isSandbox = useAppEnvProperty('isSandbox');

    const availableTimeZones = useSelector(state => state.appEnvState.availableTimeZones);
    const formConfig         = useSelector(state => state.appEnvState.clientFormConfig);
    const opUnitsForNew      = useSelector(state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForAdmin'}));
    const allOpUnits         = useSelector(operatingUnitsSelector);

    const operatingUnits = useMemo(() => {
        return (formContext.formUseState === XP_FORM_NEW) ? opUnitsForNew : allOpUnits;
    }, [formContext?.formUseState]);

    function onOpUnitChange(model, newValue, oldValue, changeValue) {
        return (dispatch, getState) => {
            if (formContext.formUseState === XP_FORM_NEW) {
                changeValue(newValue);
                return;
            }
            dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey : 'clientForm.modalConfirmOpGroupChange'}))
                .then((result) => {
                    if (result.status === MODAL_TYPE_CONFIRM) changeValue(newValue);
                    if (result.status === MODAL_TYPE_CANCEL)  changeValue(oldValue);
                });
        }
    }
    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field="operatingUnitId"
                selectList={operatingUnits}
                onChangeThunk={onOpUnitChange}
                itemTextField="name"
            />
            {((availableTimeZones.length > 1) && !formConfig.fieldsConfig.timeZone.hide) &&
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field="timeZone"
                selectList    = {availableTimeZones}
                itemTextField = "id"
            />}
            <XpFormTextInput
                inLineLayout isRequired
                field= "clientRefId"
                exactLength={isSandbox ? undefined : 12}
                checkOnlyDigits={!isSandbox}
            />
        </div>
    );
}

ClientBaseStatic.propTypes = {
    className:        PropTypes.string,
};
