import {xpColCreate} from "gui-common/xpGrid/xpGridFunctions";

export function prepareListRateOrderAllocationsColumns(translate, hiddenState) {
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'id'              , 50 , 50 , 'listRateOrderAllocationsList.id', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'listRateOrderId' , 50 , 50 , 'listRateOrderAllocationsList.listRateOrderId', {cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'allocationAmount', 100, 50 , 'listRateOrderAllocationsList.allocationAmount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'customerRst'     , 200, 50 , 'listRateOrderAllocationsList.customerRst');
    xpColCreate(translate, subArray, hiddenState, 'customerBankId'  , 200, 50 , 'listRateOrderAllocationsList.customerBankId');
    xpColCreate(translate, subArray, hiddenState, 'customerNumber'  , 200, 50 , 'listRateOrderAllocationsList.customerNumber');
    xpColCreate(translate, subArray, hiddenState, 'clientAllocationReference', 200, 50 , 'listRateOrderAllocationsList.clientAllocationReference');

    return subArray;
}
