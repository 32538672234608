import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {rateInputParser} from "gui-common/numberFormat/numberFormatFunctions";
import XpFormTimeInput from "gui-common/xpForm/core/XpFormTimeInput";
import {
    timeEarlierOrSameThanParameter,
    timeLaterThanParameter
} from "gui-common/xpForm/formComponentsValidators";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

export function PriceTolerancesSettings(props) {
    return (
        <div className={props.className}>
            <HeaderRow trKey={'priceTolerancesForm.businessHoursLabel'} />
            <TableRow
                leftField={<XpFormTimeInput
                    inLineLayout isRequired noTemplate
                    field="startBusinessHours"
                    formTemplate={'priceTolerancesForm'}
                    dependentFields={{endTime: "endBusinessHours"}}
                    errors={{laterThanEndTime : (val, fields) => timeLaterThanParameter(val, fields.endTime)}}
                />}
                rightField={<XpFormTimeInput
                    inLineLayout isRequired noTemplate
                    field="endBusinessHours"
                    formTemplate={'priceTolerancesForm'}
                    dependentFields={{startTime: "startBusinessHours"}}
                    errors={{earlierThanStartTime : (val, fields) => timeEarlierOrSameThanParameter(val, fields.startTime)}}
                />}
            />

            <HeaderRow trKey={'priceTolerancesForm.priceToleranceLabel'} />
            <TableRow
                leftField={<XpFormTextInput
                    alignRight inLineLayout isRequired noTemplate
                    field="priceToleranceInsideBusinessHours"
                    formTemplate={'priceTolerancesForm'}
                    parser={rateInputParser}
                />}
                rightField={<XpFormTextInput
                    alignRight inLineLayout isRequired noTemplate
                    field="priceToleranceOutsideBusinessHours"
                    formTemplate={'priceTolerancesForm'}
                    parser={rateInputParser}
                />}
            />
        </div>
    )
}

function TableRow(props) {
    return (
        <table style={{width: '100%', margin: '0'}}>
            <tbody>
            <tr key="headerTop">
                <th style={{width: '49%' }} className="blankHeader" > </th>
                <th style={{width: '2%'  }} className="blankHeader" > </th>
                <th style={{width: '49%' }} className="blankHeader" > </th>
            </tr>
            <tr key="header">
                <td className="blankHeader">
                    {props.leftField}
                </td>
                <td className="blankHeader"> </td>
                <td className="blankHeader">
                    {props.rightField}
                </td>
            </tr>
            </tbody>
        </table>
    )
}
function HeaderRow(props) {
    return (
        <div className = 'field'>
            <label>
                <XpTranslated trKey={props.trKey}/>
            </label>
        </div>
    )
}
