import {
    enrichMarketOrderReportData,
    MarketOrdersTurnoverAllClients,
    MarketOrdersTurnoverPerClient,
    MarketOrdersTurnoverPerClientCompact,
    MarketOrdersTurnoverForOneClient,
    marketOrderMockData,
} from "features/marketOrder/marketOrderReport";
import {
    dispatchTradeMockData,
    enrichDispatchTradeReportData
} from "features/dispatchTrade/dispatchTradeReport";
import {enrichListRateReportData, listRateMockData} from "features/listRate/listRateReport";
import {
    enrichListRateOrderReportData,
    ListRateOrdersTurnoverAllClients,
    ListRateOrdersTurnoverPerClient,
    ListRateOrdersTurnoverPerClientCompact,
    ListRateOrdersTurnoverForOneClient,
    listRateOrderMockData
} from "features/listRateOrder/listRateOrderReport";
import {ReportConfigSelectClient} from "gui-common/report/reportComponents/ReportConfigSelectClient";

export const refixReportingDataSetTypes = {
    'MarketOrders': {
        reportTypes: {
            'TurnoverAllClients': {
                configComponent: undefined,
                reportComponent: MarketOrdersTurnoverAllClients,
                defaultSettings: undefined,
            },
            'TurnoverPerClient': {
                configComponent: undefined,
                reportComponent: MarketOrdersTurnoverPerClient,
                defaultSettings: undefined,
            },
            'TurnoverPerClientCompact': {
                configComponent: undefined,
                reportComponent: MarketOrdersTurnoverPerClientCompact,
                defaultSettings: undefined,
            },
            'TurnoverPerCurrencyAndMonth': {
                configComponent: ReportConfigSelectClient,
                reportComponent: MarketOrdersTurnoverForOneClient,
                defaultSettings: {
                    showClients: true,
                    showMonths: false,
                    sumClient: true,
                    sumMonth: true,
                    sumYear: true,
                    showClientId: "",
                    showClientName: ""
                }
            },
        },
        // reportFields            : marketOrdersFields,
        dateSortField           : 'createdDateTime',
        statusField             : 'orderStatus',
        excludeStatusFromTurnover: ['Failed'],
        dataEnrichmentFunction  : enrichMarketOrderReportData,
        mockData                : marketOrderMockData,
        hideReportCurrency      : false,
    },
    'ListRateOrders': {
        reportTypes: {
            'TurnoverAllClients': {
                configComponent: undefined,
                reportComponent: ListRateOrdersTurnoverAllClients,
                defaultSettings: undefined,
            },
            'TurnoverPerClient': {
                configComponent: undefined,
                reportComponent: ListRateOrdersTurnoverPerClient,
                defaultSettings: undefined,
            },
            'TurnoverPerClientCompact': {
                configComponent: undefined,
                reportComponent: ListRateOrdersTurnoverPerClientCompact,
                defaultSettings: undefined,
            },
            'TurnoverPerCurrencyAndMonth': {
                configComponent: ReportConfigSelectClient,
                reportComponent: ListRateOrdersTurnoverForOneClient,
                defaultSettings: {
                    showClients: true,
                    showMonths: false,
                    sumClient: true,
                    sumMonth: true,
                    sumYear: true,
                    showClientId: "",
                    showClientName: ""
                }
            },
        },
        // reportFields            : listRateOrderFields,
        dateSortField           : 'instructedTime',
        statusField             : 'status',
        excludeStatusFromTurnover: ['Rejected'],
        dataEnrichmentFunction  : enrichListRateOrderReportData,
        mockData                : listRateOrderMockData,
        hideReportCurrency      : false,
    },
    'SentDispatchTrades': {
        reportTypes             : {},
        // reportFields            : dispatchTradeFields,
        dateSortField           : 'tradeTime',
        dataEnrichmentFunction  : enrichDispatchTradeReportData,
        mockData                : dispatchTradeMockData,
        hideReportCurrency      : false,
    },
    'IssuedListRates': {
        reportTypes             : {},
        // reportFields            : listRateFields,
        dateSortField           : 'timeIssued',
        dataEnrichmentFunction  : enrichListRateReportData,
        mockData                : listRateMockData,
        hideReportCurrency      : true,
    },
    'EmptyListRates': {
        reportTypes             : {},
        // reportFields            : listRateFields,
        dateSortField           : 'timeIssued',
        dataEnrichmentFunction  : enrichListRateReportData,
        mockData                : listRateMockData,
        hideReportCurrency      : true,
    },
};

