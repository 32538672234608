import {getCurrencyPairString} from "gui-common/functions/functions";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {createSelector} from "reselect";
import {createTranslatedFieldData} from "gui-common/xpGrid/xpGridFunctions";
import {orm} from "gui-common/orm/orm";

function buildMarketOrderObject(session, marketOrder) {

    const legalEntityUnit = marketOrder.legalEntityUnitId;

    return {
        ...marketOrder.ref,
        legalEntityUnitName : legalEntityUnit ? legalEntityUnit.name  : "",
        appId               : legalEntityUnit ? legalEntityUnit.appId : "",
        currencyPairStr     : marketOrder.currencyPair   ? getCurrencyPairString(marketOrder.currencyPair) : "",
        amountCurrencyT     : marketOrder.amountCurrency ? marketOrder.amountCurrency.currency : "",
    }
}

export const getMarketOrdersSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        //        console.log("Selector activeReFixAgreementsSelector running ", activeClientId);
        let returnArray = [];
        session.MarketOrder.all().orderBy('id', 'desc').toModelArray().forEach(order => {
            const enrichedOrder = buildMarketOrderObject(session, order);
            if (enrichedOrder) returnArray.push(enrichedOrder);
        });
//        console.log("Selected market orders:", returnArray);
        return returnArray;
    }
);


const translate       = state => selectTranslateFunction(state);
const marketOrders    = state => getMarketOrdersSelector(state);
const filterFunction  = (state, props) => props ? props.filterFunction : undefined;
export const selectMarketOrdersListData = createSelector(
    [marketOrders, filterFunction, translate],
    (marketOrders, filterFunction, translate) => {
        if (!translate) return [];
        if (!marketOrders) return [];
        let outArray = [];
        for (let order of marketOrders) {
            if (filterFunction && !filterFunction(order)) continue;
            createTranslatedFieldData(order, 'status', translate, ("marketOrdersList.statusTypes." + order.status));
            outArray.push(order);
        }
        return outArray;
    }
);

