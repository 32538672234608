export function stateRequestedOnId(modelRequestState, id) {
    if (!modelRequestState) return false;
    return (modelRequestState[id] !== undefined);
}
export function stateRequestedOnIdAndProperty(modelRequestState, id, property) {
    if (!modelRequestState) return false;
    if (!modelRequestState[id]) return false;
    return (modelRequestState[id][property] !== undefined);
}


