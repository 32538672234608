import {genericFetch} from "gui-common/api/apiCommon";
import {ormEntitiesGetStarted} from "gui-common/orm/ormReducer";
import {ormEntityClearLoadAll} from "gui-common/orm/ormLoadingReducer";
import {xpTranslatedLoadTextGenerations} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";
import {createSystemTextBody, systemTextTransformer} from "gui-common/appLocale/xpTranslated/xpTranslatedFunctions";
import {globalApiHandle} from "gui-common/api/apiConstants";


export function handleSystemTextResponseThunk(items) {
    return (dispatch, getState) => {
        let transformedItems = [];
        for (let item of items) {
            if (!item.languageCode || !item.textKey) {
                continue;
            }
            transformedItems.push(systemTextTransformer(item));
        }
        dispatch(xpTranslatedLoadTextGenerations(transformedItems));
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to get user messages from BE including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function getSystemTextsFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get SystemText ";
            let requestUrl = globalApiHandle.baseUrls.systemText + "?";

            dispatch(ormEntitiesGetStarted('SystemText'));
            dispatch(genericFetch(
                requestUrl,
                logText,
                getResponseHandler,
                getFailHandler,
                getFailHandler,
                'GET',
                undefined, // no body in http request needed for GET.
                'UserMessage'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function getResponseHandler(resp) {
    return (dispatch, getState) => {
        dispatch(handleSystemTextResponseThunk(resp));
        dispatch(ormEntityClearLoadAll('SystemText'));
    }
}
function getFailHandler(resp) {
    return (dispatch, getState) => {
        dispatch(ormEntityClearLoadAll('SystemText'))
    }
}

/* -----------------------------------------------------------------------------------------------------------------
* Functions to add user message including handler functions for success and fail.
* -----------------------------------------------------------------------------------------------------------------*/
export function postSystemTextToApi(item) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            const logText = "Post SystemText";
            let requestUrl = globalApiHandle.baseUrls.systemText + "?";
            console.log('messagebody:', createSystemTextBody(item));
            dispatch(genericFetch(
                requestUrl,
                logText,
                postUserMessageResponseHandler,
                postUserMessageFailHandler,
                postUserMessageFailHandler,
                'POST',
                createSystemTextBody(item),
                'UserMessage'
            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function postUserMessageResponseHandler(resp) {
    return (dispatch, getState) => {
        dispatch(handleSystemTextResponseThunk([resp]));
    }
}
function postUserMessageFailHandler(originalMessage) {
    return (resp) => {
        return (dispatch, getState) => {
        }
    }
}
