import React, {useMemo} from 'react'
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {accountNumberParser} from "gui-common/xpForm/formComponentsFunctions";
import {activePeriodsSelector} from "appConfig/orm/ormSelectors";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";


export function ListRateGraceAndPriceType (props) {

    const formConfig = useSelector(state => state.appEnvState.agreementFormConfig);
    const listRatePeriods    = useSelector(activePeriodsSelector);
    const formDataListRatePeriodId = useXpFormField('listRatePeriodId');
    const formDataType             = useXpFormField('type');

    const selectedListRatePeriodDuration = useMemo(
        () => {
            const selectedListRatePeriod = listRatePeriods.find(item => item.id === Number(formDataListRatePeriodId));
            return selectedListRatePeriod ? selectedListRatePeriod.duration * 60 : undefined;
        },
        [formDataListRatePeriodId]
    );

    if (formDataType !== 'ListRateAgreement') return null;

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field         = "buySellType"
                selectList    = {[{id:"BuySell"},{id:"Buy"},{id:"Sell"}]}
                itemTextField = "id"
            />
            <XpFormSelectorInput
                isRequired inLineLayout noTemplate
                field         = "meansOfPayment"
                selectList    = {[{id:"HEDGE"},{id:"INVESTMENT"}]}
                itemTextField = "id"
                defaultValue = {"HEDGE"}
            />
            <XpFormTextInput
                isRequired inLineLayout alignRight
                field         = "gracePeriod"
                parser        = {accountNumberParser}
                checkNoSpaces = {true}
                errors={{
                    graceLongerThanMax: (val) => {
                        if (selectedListRatePeriodDuration && (Number(val) > selectedListRatePeriodDuration)) return false; // handles by graceLongerThanPeriod error.
                        if (Number(val) > formConfig.fieldsConfig.gracePeriod.maxSeconds) return {checkValue: formConfig.fieldsConfig.gracePeriod.maxSeconds};
                        return false;
                    },
                    graceLongerThanPeriod: (val) => {
                        if (selectedListRatePeriodDuration && (Number(val) > selectedListRatePeriodDuration)) return {checkValue: selectedListRatePeriodDuration}
                        return false;
                    },
                }}
            />
        </div>
    );
}
ListRateGraceAndPriceType.propTypes = {
    className:        PropTypes.string,
};
