import {addUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import cloneDeep from "lodash/cloneDeep";
import {push} from "connected-react-router";
import {warnAndReject, xpIsRequired} from "gui-common/functions/functions";
import {ormEntitiesGetStarted} from "gui-common/orm/ormReducer";
import {ormEntityClearLoadAll} from "gui-common/orm/ormLoadingReducer";
import {apiGetAwtToken} from "gui-common/api/apiFunctions";
import {apiSetAppReadyState} from "gui-common/api/apiReducers";
import {globalApiHandle} from "gui-common/api/apiConstants";


function getErrorMessage(resp) {
    if (!resp?.tppMessages?.length) {
        return "No message provided";
    }
    return resp.tppMessages[0].text;
}
function getErrorCode(resp) {
    if (!resp?.tppMessages?.length) {
        return "No code provided";
    }
    return resp.tppMessages[0].code;
}
export function logAndDispatchApiErrorUserMessage(dispatch, httpCode, fetchType, textPath, resp, ormModel) {

    const code = resp?.code ? resp.code : getErrorCode(resp);
    const text = resp?.text ? resp.text : getErrorMessage(resp);

    console.error(fetchType + " API call failed. Http code: " + httpCode + ", error code: " + (code ? code : "No error code") + ", error message: " + (text ? text : "No message") + ".");
    dispatch(addUserMessageThunk("error", textPath, {fetchType: fetchType, httpCode: httpCode, errorCode: code ? code : "No error code", message: text ? text : "No message"}, ormModel === 'UserMessage'));
}


function getUrlWithToken(noToken, url, dispatch, getState) {
    if (noToken) {
        return url;
    }
    const token = apiGetAwtToken(dispatch, getState());
    if (token) {
        return url + 'awt=' + token.awt + '&sessionId=' + token.sessionId + '&referenceId=' + token.referenceId;
    }

/*
    // This is the case when we login to the session and gets the first array of awt tokens

    // If app is up and running, i.e. logged in, this is a terminal failure and the user should be rerouted to authenticationFailed.
    const appReady =  selectAppReadyState(getState());
    if (appReady) return undefined;

    // If app is not up and running, i.e. not logged in, this is probably the first sign in REST cal to get the first batch of AWT tokens.
    const loginParamsFromUrl = getUrlLoginParams();
    if (!loginParamsFromUrl ||!loginParamsFromUrl.key || !loginParamsFromUrl.referenceId || !loginParamsFromUrl.sessionId) return undefined;

    return url + 'key=' + loginParamsFromUrl.key + '&sessionId=' + loginParamsFromUrl.sessionId + '&referenceId=' + loginParamsFromUrl.referenceId;
*/
}

export function apiFileUpload(ormModel, fileSpecification, fileContent, targetPrototype) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const logText = "Load file with " + ormModel;

            if (!targetPrototype || !ormModel || !fileSpecification || !fileSpecification.columnDefinitions || !fileSpecification.separator) {
                console.error("Invalid parameters in apiFileUpload.", ormModel, fileSpecification, targetPrototype);
                warnAndReject("Invalid parameters in apiFileUpload.", reject);
                return;
            }

            let requestUrl = globalApiHandle.baseUrls.uploadFile + "/" + targetPrototype + "?";

            let filteredFileSpecification = {
                ...fileSpecification,
                columnDefinitions: fileSpecification.columnDefinitions.map(item => { return {
                    heading: item.heading, field: item.field, type: item.type, mandatory: item.mandatory
                }}),
            }

            let body = new FormData();
            let file = new File([fileContent], "File from FE");
            body.append('file'              , file);
            body.append('fileSpecification' , JSON.stringify(filteredFileSpecification ));

            dispatch(ormEntitiesGetStarted(ormModel));
            dispatch(genericFetch(
                requestUrl,
                logText,
                fileUploadResponseHandler,
                fileUploadFailHandler,
                fileUploadFailHandler,
                'POST',
                undefined,
                ormModel,
                undefined,
                undefined,
                {body: body, headers: undefined}

            )).then(result => {resolve(result)}).catch(err => {reject(err)})
        })
    }
}
function fileUploadResponseHandler(resp, ormModel) {
    return (dispatch, getState) => {
        dispatch(ormEntityClearLoadAll(ormModel))
    }
}
function fileUploadFailHandler(resp, ormModel) {
    return (dispatch, getState) => {
        dispatch(ormEntityClearLoadAll(ormModel))
        // dispatch(ormEntityCreateFailed(ormModel));
    }
}

function logAndSetAppStateNotReady(customText, dispatch) {
    console.error(customText + " got no web session token available! Logoff!");
    dispatch(apiSetAppReadyState(false));
    dispatch(push('/authenticationFailed'));
    return customText + " could not pop web session token.";
}

export function fileDownload(
    url                 = xpIsRequired('url'),
    fileName            = xpIsRequired('fileName'),
    customText          = xpIsRequired('customText'),
    noToken,
    customParameters
) {
    return (dispatch, getState) => {
        return new Promise(function (resolve, reject) {

            const baseUrl = getState().appEnvState.baseUrl;
            const requestUrl = getUrlWithToken(false, baseUrl + url, dispatch, getState);
            if (!requestUrl) {
                reject(logAndSetAppStateNotReady(customText, dispatch));
                return;
            }

            const parameters = {
                credentials: 'include', // needed to include cookie when running cross-origin (when running local)
                method: 'GET',
                headers: {
                    // 'Content-Type': 'application/pdf',
                },
                ...customParameters
            };

            return fetch(requestUrl, parameters)
                .then(resp => {
                    // MT: Found this in the internet... Neat function to merge two functions response in one .then. See array in resp below.
                    return Promise.all([resp, resp.blob()]);
                })
                .then(resp => {
                    if ((resp[0].status === 200) || (resp[0].status === 201)) { // Handle success
                        if (!resp[1]) {
                            console.error(customText + " returned no payload!");
                            reject(customText + " returned no payload!");
                            return;
                        }
                        const url = window.URL.createObjectURL(
                            new Blob([resp[1]]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);

                        console.log(customText + " downloaded successfully");

                        resolve();
                    }
                    else if (resp[0].status === 403) {
                        logAndDispatchApiErrorUserMessage(dispatch, resp[0].status, customText, "userMessages.error.apiFetchFailAuthorization");
                        reject(customText + " returned authorization failed : " + resp[0].status);
                    } else { // Handle fail
                        logAndDispatchApiErrorUserMessage(dispatch, resp[0].status, customText, ((resp[0].status >= 400) && (resp[0].status < 500)) ? "userMessages.error.apiFetchFail400" : "userMessages.error.apiFetchFail500");
                        reject(customText + " returned http response: " + resp[0].status);
                    }
                })
                .catch(resp => {
                    console.error(customText + " failed with catch. Resp: ", resp);
                    dispatch(addUserMessageThunk("error", "userMessages.error.apiFetchFailedCatch", {fetchType: customText}, false));
                    reject(customText + " failed");
                })
        })
    }
}

export function genericFetch(
    url                 = xpIsRequired('url'),
    customText          = xpIsRequired('customText'),
    responseHandler     = xpIsRequired('responseHandler'),
    failedHttpHandler   = xpIsRequired('failedHttpHandler'),
    exceptionHandler    = xpIsRequired('exceptionHandler'),
    method,
    body,
    ormModel,
    requestedBaseUrl,
    noToken,
    customParameters
) {
    return (dispatch, getState) => {
        return new Promise(function(resolve, reject) {

            const baseUrl = requestedBaseUrl ? requestedBaseUrl : getState().appEnvState.baseUrl;

            const requestUrl = getUrlWithToken(noToken, baseUrl + url, dispatch, getState);
            if (!requestUrl) {
                reject(logAndSetAppStateNotReady(customText, dispatch));
                return;
            }

            const parameters = {
//                credentials:    'same-origin', // needed to include cookie when running same-origin (as in dev and prod)
                credentials:    'include', // needed to include cookie when running cross-origin (when running local)
//                mode:           'cors',
                method:         method ? method                                : 'GET',
                body:           body   ? JSON.stringify(body)                  : undefined,
                headers:        body   ? {'Content-Type': 'application/json'}  : undefined,
                ...customParameters,
            };
            //console.log("Calling API " + customText + ": " + requestUrl);
            //console.log(parameters);
            return fetch(requestUrl, parameters)
                .then(resp => {
                    if (resp.status === 500) {
                        return resp;
                    }
                    else {
                        return Promise.all([resp, resp.text()]);
                    }
                })
                .then(resp => {
                    if (resp.status === 500) {
                        if (failedHttpHandler) dispatch(failedHttpHandler(resp, ormModel, body));
                        logAndDispatchApiErrorUserMessage(dispatch, resp.status, customText, "userMessages.error.apiFetchFail500", undefined, ormModel);
                        reject(customText + " returned 500. Reason: " + resp.statusText);
                        return;
                    }

                    const httpResponse  = resp[0];
                    const responseBody = resp[1] ? JSON.parse(resp[1]) : undefined;

                    if ((httpResponse.status === 200) || (httpResponse.status === 201)) { // Handle success
                        let logResp = cloneDeep(responseBody);
                        console.log(customText + " returned payload: ", logResp);

                        if (responseHandler) dispatch(responseHandler(responseBody?.response, ormModel, body));
                        resolve(responseBody);
                    }
                    else if (httpResponse.status === 403) {
                        if (failedHttpHandler) dispatch(failedHttpHandler(resp, ormModel, body));
                        logAndDispatchApiErrorUserMessage(dispatch, httpResponse.status, customText, "userMessages.error.apiFetchFailAuthorization", responseBody, ormModel);
                        reject({errorMessage: customText + " returned authorization failed : " + httpResponse.status + ". Reason: " + getErrorMessage(responseBody), apiResponse: resp});
                    }
                    else { // Handle fail
                        if (failedHttpHandler) dispatch(failedHttpHandler(resp, ormModel, body));
                        logAndDispatchApiErrorUserMessage(dispatch, httpResponse.status, customText, "userMessages.error.apiFetchFail400", responseBody, ormModel);
                        reject({errorMessage: customText + " returned http response: " + httpResponse.status + ". Message: " + getErrorMessage(responseBody), apiResponse: resp});
                    }
                })
                .catch(resp => {
                    if (exceptionHandler) dispatch(exceptionHandler(resp, ormModel, body));
                    console.error(customText + " failed in catch. Resp: ", resp);
                    dispatch(addUserMessageThunk("error", "userMessages.error.apiFetchFailedCatch", {fetchType: customText}, ormModel === 'UserMessage'));
                    reject({errorMessage: customText + " failed", apiResponse: resp});
                })
        })
    }
}


export function nonAuthorizedFetch(
    url                 = xpIsRequired('url'),
    customText          = xpIsRequired('customText'),
    responseHandler     = xpIsRequired('responseHandler'),
    failedHttpHandler   = xpIsRequired('failedHttpHandler'),
    exceptionHandler    = xpIsRequired('exceptionHandler'),
    method,
    body,
    customParameters
) {
    return (dispatch, getState) => {
        return new Promise(function(resolve, reject) {

            const parameters = {
//                credentials:    'same-origin', // needed to include cookie when running same-origin (as in dev and prod)
                credentials:    'include', // needed to include cookie when running cross-origin (when running local)
//                 mode:           'no-cors',
                // mode:           'cors',
                method:         method ? method                                : 'GET',
                body:           body   ? JSON.stringify(body)                  : undefined,
                headers:        body   ? {'Content-Type': 'application/json'}  : undefined,
                ...customParameters,
            };

            return fetch(url, parameters)
                .then(resp => {
                    return Promise.all([resp, resp.json()]);
                })
                .then(resp => {
                    if ((resp[0].status === 200) || (resp[0].status === 201)) { // Handle success
                        if (!resp[1]) {
                            console.error(customText + " returned no payload!");
                            reject(customText + " returned no payload!");
                            return;
                        }
                        let logResp = cloneDeep(resp[1]);
                        console.log(customText + " returned payload: ", logResp);

                        if (responseHandler) dispatch(responseHandler(resp[1].response, body));
                        resolve(resp[1]);
                    }
                    else { // Handle fail
                        if (failedHttpHandler) dispatch(failedHttpHandler(resp, body));
                        console.error(customText + " API call failed. ", resp);
                        reject(customText + " returned http response: " + resp[0].status + ". Message: " + getErrorMessage(resp[1]));
                    }
                })
                .catch(resp => {
                    if (exceptionHandler) dispatch(exceptionHandler(resp, body));
                    console.error(customText + " failed with catch. Resp: ", resp);
                    reject(customText + " failed");
                })
        })
    }
}


export function loadMatrixParallel(loadMatrix, dispatch) {
    return Promise.all(loadMatrix.map(loadChain => {
        return new Promise((resolve, reject) => {
            if (!loadChain || !loadChain.length) resolve();
            // This way of chaining a loop of promises were found at the following website: https://stackoverflow.com/questions/40328932/javascript-es6-promise-for-loop/40329190
            for (let i = 0, p = Promise.resolve(); i <= loadChain.length; i++) {
                p = p.then(_ => {
                    if (i === loadChain.length) {
                        resolve();
                    } else return dispatch(loadChain[i].function(...loadChain[i].parameters))
                })
                    .catch(err => {
                        console.error("Data load chain failed: " + err);
                        reject(err);
                    });
            }
        })
    }))
}

export function loadMatrixInSequence(loadMatrix, dispatch) {
    return new Promise((resolve, reject) => {
        if (!loadMatrix || !loadMatrix.length) resolve();
        // This way of chaining a loop of promises were found at the following website: https://stackoverflow.com/questions/40328932/javascript-es6-promise-for-loop/40329190
        for (let i = 0, p = Promise.resolve(); i <= loadMatrix.length; i++) {
//            console.log("Starting chain ",i);
            p = p
                .then(_ => {
//                    console.log("In then statement chain ",i);
                    if (i === loadMatrix.length) {
                        resolve();
                    } else return Promise.all(loadMatrix[i].map(item => dispatch(item.function(...item.parameters))));
                })
                .catch(err => {
                    console.error("Data load chain failed: " + err);
                    reject(err);
                });
        }
    })
}

export function loadAllInParallel(loadArray, dispatch) {
    return Promise.all(loadArray.map(load => {
            return dispatch(load.function(...load.parameters));
        }))
}
