import {createStandardAuditFields2, xpColCreate, xpColHeadCreate} from "gui-common/xpGrid/xpGridFunctions";
import {allowAll, instanceRightAllowed} from "gui-common/userRights/userRightsFunctions";

export function canLegalEntityUnitBeDisabled(legalEntityUnit) {
    let returnObject = {
        canBeDisabled: true,
        modalKey: 'legalEntityUnitForm.modalConfirmDisableLegalEntityUnit',
        modalParams: {name: legalEntityUnit.name}
    };

    if (legalEntityUnit.agreements && legalEntityUnit.agreements.length) {
        let childrenNames = "";
        for (let agreement of legalEntityUnit.agreements) {
            childrenNames = childrenNames + "- " + agreement.name + "\n";
        }
        returnObject.canBeDisabled = false;
        returnObject.modalKey = 'legalEntityUnitForm.modalLegalEntityUnitHasAgreements';
        returnObject.modalParams   = {name: legalEntityUnit.name, children: childrenNames};
        return returnObject;
    }
    // LegalEntityUnit can be disabled!
    return returnObject;
}

export function prepareLegalEntityUnitsGridColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'name', 120, 50, 'legalEntityUnitForm.name.label');
    xpColCreate(translate, subArray, hiddenState, 'description', 250, 50, 'legalEntityUnitForm.description.label');
    xpColCreate(translate, subArray, hiddenState, 'id', 80, 30, 'legalEntityUnitForm.id');
    xpColCreate(translate, subArray, hiddenState, 'fixSessionName', 200, 50, 'legalEntityUnitForm.fixSessionId.label');
    xpColCreate(translate, subArray, hiddenState, 'fxShortName', 150, 50, 'legalEntityUnitForm.fxShortName.label');
    xpColHeadCreate(translate, outArray, subArray, 'legalEntityUnitsList.entityInformation');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}

export function getLegalEntityUnitDashboardColumns(translate, hiddenState) {
    let outArray = [];
    let subArray = [];

    xpColCreate(translate, subArray, hiddenState, 'name'            , 200, 100, 'legalEntityUnitForm.name.label', {cellRenderer: 'agGroupCellRenderer', lockVisible: true, lockPosition: true});
    xpColCreate(translate, subArray, hiddenState, 'description'     , 250, 50, 'legalEntityUnitForm.description.label');
    xpColCreate(translate, subArray, hiddenState, 'id'              , 80 , 30, 'legalEntityUnitForm.id');
    xpColCreate(translate, subArray, hiddenState, 'fixSessionName'  , 200, 50, 'legalEntityUnitForm.fixSessionId.label');
    xpColCreate(translate, subArray, hiddenState, 'fxShortName'     , 100, 50, 'legalEntityUnitForm.fxShortName.label');
    xpColCreate(translate, subArray, hiddenState,'clientApiChannelT', 100, 50, 'clientForm.clientApiChannel.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState,'exemptFromGlobalSuspendT', 50, 50, 'legalEntityUnitForm.exemptFromGlobalSuspend.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColHeadCreate(translate, outArray, subArray,  'legalEntityUnitsList.entityInformation');

    xpColCreate(translate, subArray, hiddenState, 'tradingLimitCurrencyName', 100, 50, 'legalEntityUnitsList.tradingLimitCurrencyId');
    xpColCreate(translate, subArray, hiddenState, 'tradingLimit'           , 100, 50, 'legalEntityUnitForm.tradingLimit.label', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}} );
    xpColCreate(translate, subArray, hiddenState, 'todayLimitOverride'     , 100, 50, 'legalEntityUnitsList.todayLimitOverride',
        {
            cellRenderer: 'xpGridNumberRenderer',
            cellStyle: (params) => {return {background: params.data.todayLimitOverride ? 'rgba(250,255,152,0.5)' : 'none', textAlign: "right"}},
        });
    xpColCreate(translate, subArray, hiddenState, 'tradingLimitUtilization', 100, 50, 'legalEntityUnitsList.tradingLimitUtilization', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'netTradingLimitT'        , 100, 50, 'legalEntityUnitForm.netTradingLimit.label', {cellRenderer: 'xpTranslatedRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'todayLimitOverrideDateTime'  , 150, 50, 'legalEntityUnitsList.todayLimitOverrideDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'todayLimitOverrideByUserId'  , 150, 50, 'legalEntityUnitsList.todayLimitOverrideByUserId', {cellRenderer: 'xpGridUserRenderer'});
    xpColCreate(translate, subArray, hiddenState, 'todayLimitOverrideComment'   , 150, 50, 'legalEntityUnitsList.todayLimitOverrideComment', {tooltipField: 'bookingComment'});
    xpColHeadCreate(translate, outArray, subArray,  'legalEntityUnitsList.limitInformation');

    xpColCreate(translate, subArray, hiddenState, 'buyAmount'        , 100, 50, 'listRatesList.buyAmount' , {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'sellAmount'       , 100, 50, 'listRatesList.sellAmount', {cellRenderer: 'xpGridNumberRenderer', cellStyle: {textAlign: "right"}});
    xpColCreate(translate, subArray, hiddenState, 'timeExpire'       , 150, 50, 'listRatesList.timeExpire', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColCreate(translate, subArray, hiddenState, 'nextDispatchTime' , 150, 50, 'listRatesList.nextDispatchTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});
    xpColHeadCreate(translate, outArray, subArray,  'legalEntityUnitsList.listRateInformation');

    outArray = [...outArray, ...createStandardAuditFields2(hiddenState, translate)];

    return outArray;
}
export function userCanOverrideLimit(legalEntityUnit) {
    if (allowAll) return true;
    if (!legalEntityUnit) return false;
    return instanceRightAllowed(legalEntityUnit,'LimitOverride');
}

