import spinnerGif from "gui-common/resources/spinner.gif";
import PropTypes from "prop-types";
import React from "react";
import XpTooltip from "gui-common/components/XpTooltip";
import {useDispatch} from "react-redux";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";

function WaitingXpoolButton ({
                                 onClickCallback,
                                 label,
                                 labelKey,
                                 labelParams,
                                 disabled,
                                 waiting,
                                 toolTip,
                                 toolTipKey,
                                 toolTipParams,
                                 disabledToolTip,
                                 disabledToolTipKey,
                                 disabledToolTipParams,
                                 floatRight,
                                 className,
                                 xpTestId,
                                 modalType,
                                 modalProps,
                                 modalKey,
                                 modalParams,
                                 autoFocus,
                                 fillWidth,
                                 style,
                                 addIcon
}) {
    const dispatch          = useDispatch();
    const modalToLaunch     = modalType ? modalType : CONFIRMATION_DIALOG;

    const onClick = () => {
        if (!modalKey) {
            onClickCallback();
            return;
        }
        dispatch(pushModalWindow(modalToLaunch, {modalKey: modalKey, modalParams: modalParams, ...modalProps}))
            .then((result) => {
                if (result.status === MODAL_TYPE_CONFIRM) onClickCallback(result);
            });
    };

    if (!label && !labelKey) {
        console.error("Neither label nor labelKey provided to XpWaitingButton");
        return;
    }
    const labelToShow = label ? label :
        <span>
            {addIcon ? addIcon : null}
            <XpTranslated trKey={labelKey} trParams={labelParams}/>
        </span>;
    const activeToolTipParamsToDisplay   = toolTip ? {title: toolTip} : {trKey: toolTipKey, trParams: toolTipParams};
    const disabledToolTipParamsToDisplay = disabledToolTip ? {title: disabledToolTip} : {trKey: disabledToolTipKey, trParams: disabledToolTipParams};

    const toolTipParamsToDisplay = (disabled && (disabledToolTip || disabledToolTip)) ? disabledToolTipParamsToDisplay : activeToolTipParamsToDisplay;

    return (
        <XpTooltip {...toolTipParamsToDisplay}>
            <div style={{display: 'inline-block', float: (floatRight ? "right" : "none"), flexShrink: '0', width: fillWidth ? '100%' : undefined}}>
                <button
                    xp-test-id={xpTestId ? xpTestId : 'xpWaitingXpoolButton'}
                    type="button"
                    className={"xPoolButton " + (className ? className : "") + (floatRight ? " xPoolButtonFloatRight" : "")}
                    onClick={() => onClick()}
                    disabled={disabled || waiting}
                    autoFocus={autoFocus}
                    style={{...(style ? style : {}), width: fillWidth ? '100%' : undefined}}
                >
                    {disabled ?
                        <React.Fragment>{labelToShow}</React.Fragment>
                        :
                        <div>
                            <p style={{visibility: 'hidden', verticalAlign: 'middle', margin: '0'}}>{labelToShow}</p>
                            <div style={{position: 'absolute', top: '0px', right: '0', bottom: '0', left: '0', margin: 'auto', height: '50px', lineHeight: '49px'}}>
                                {labelToShow}
                            </div>
                            {waiting && <img className="waitingButtonSpinner" src={spinnerGif} alt="waiting"/>}
                        </div>
                    }
                </button>
            </div>
        </XpTooltip>
    )
}
WaitingXpoolButton.propTypes = {
    onClickCallback : PropTypes.func.isRequired,
    label           : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelKey        : PropTypes.string,
    labelParams     : PropTypes.object,
    className       : PropTypes.string,
    xpTestId        : PropTypes.string,
    toolTip         : PropTypes.string,
    disabled        : PropTypes.bool,
    waiting         : PropTypes.bool,
    floatRight      : PropTypes.bool,
    modalKey        : PropTypes.string,
    modalParams     : PropTypes.object,
    fillWidth       : PropTypes.bool,
};
export default WaitingXpoolButton


