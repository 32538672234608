import {orm} from "gui-common/orm/orm";
import {attr, fk, many, Model} from "redux-orm";

const commonNestedOrmEntities = {
    LedgerAccount: [
        {
            property : "balances",
            model    : "LedgerAccountBalance"
        }
    ],
    RootBankHoliday: [
        {
            property : "bankHolidays",
            model    : "BankHoliday"
        }
    ],
    FxTradeReportEvent: [
        {
            property : "fxOrderReport",
            model    : "FxOrderReport"
        }, {
            property : "relatedFxOrderReport",
            model    : "FxOrderReport"
        }
    ],
    FxOrderReport: [
        {
            property : "fxExecutionLegs",
            model    : "FxExecutionLeg"
        }
    ],
    FxExecutionLeg: [
        {
            property : "fxExecutionReport",
            model    : "FxExecutionReport"
        }
    ],
};
const commonOrmParentProperties = {
    OperatingUnit   : undefined,
    Client          : {property: 'operatingUnitId', ormModel: 'OperatingUnit'},
    LegalEntity     : {property: 'clientId'       , ormModel: 'Client'},
    LegalEntityUnit : {property: 'legalEntityId'  , ormModel: 'LegalEntity'},
};

const commonOrmCompareFunctions = {}

const commonOrmModelConfig = {}

class Currency extends Model {}
Currency.modelName = 'Currency';
Currency.fields = {
    id:         attr(),
    currency:   attr(),
    enabled:    attr()
};

class Country extends Model {}
Country.modelName = 'Country';
Country.fields = {
    id:         attr(),
    country:    attr(),
    enabled:    attr()
};

class CurrencyPair extends Model {}
CurrencyPair.modelName = 'CurrencyPair';
CurrencyPair.fields = {
    id:                 attr(),
    baseCurrencyId:     fk('Currency', 'currencyPairsBaseCurrency'),
    quoteCurrencyId:    fk('Currency', 'currencyPairsQuoteCurrency'),
    key:                attr(), // Currency pair as a string
    rate:               attr(),
    enabled:            attr()
};

class CurrencyPairSettings extends Model {}
CurrencyPairSettings.modelName = 'CurrencyPairSettings';
CurrencyPairSettings.fields = {
    id:                 attr(),
    currencyPairId:     fk('CurrencyPair', 'currencyPairSettings'),
    configType:         attr(),
    configObject:       attr(),
    changedDateTime:    attr(),
    changedByUser:      attr(),
};

class FxPrice extends Model {}
FxPrice.modelName = 'FxPrice';
FxPrice.fields = {
    id:                 attr(),
    fromCurrencyId:     attr(),
    toCurrencyId:       attr(),
    currencyPairId:     attr(),
    indicator:          attr(),
    rate:               attr(),
    time:               attr(),
};
class FixMessage extends Model {}
FixMessage.modelName = 'FixMessage';
FixMessage.fields = {
    id:                 attr(),
};

class FxRejectConfiguration extends Model {}
FxRejectConfiguration.modelName = 'FxRejectConfiguration';
FxRejectConfiguration.fields = {
    id:                 attr(),
    rejectType:         attr(),
    matchOrderIndex:    attr(),
    rejectCode:         attr(),
    matchText:          attr(),
    autoGenerated:      attr(),
    retryExecution:     attr(),
    originalReasonText: attr(),
    errorResponseText:  attr(),
    changedDateTime:    attr(),
    changedByUser:      attr(),
};

class FixSession extends Model {}
FixSession.modelName = 'FixSession';
FixSession.fields = {
    id:            attr(),
    name:          attr(),
    description:   attr(),
    fixSession:    attr(),
};

class NFCProcessStatus extends Model {}
NFCProcessStatus.modelName = 'NFCProcessStatus';
NFCProcessStatus.fields = {
    id:                 attr(),
    active:             attr(),
    processPrototype:   attr(),
    processPrototypeId: attr(),
    processStatus:      attr(),
    enabled:            attr()
};

class ErpSystem extends Model {}
ErpSystem.modelName = 'ErpSystem';
ErpSystem.fields = {
    id:            attr(),
    name:          attr(),
    description:   attr(),
    erpPrototype:  attr(),
    erpSpecifics:  attr(),
};

class ErpIntegration extends Model {}
ErpIntegration.modelName = 'ErpIntegration';
ErpIntegration.fields = {
    id:            attr(),
    erpEnabled:    attr(),
    erpSystem:     attr(),
    status:        attr(),
    reason:        attr(),
    connectionSessionId: attr(),
};

class OperatingUnit extends Model {}
OperatingUnit.modelName = 'OperatingUnit';
OperatingUnit.fields = {
    id:            attr(),
    name:          attr(),
    email:         attr(),
    phoneNumber:   attr(),
    enabled:       attr()
};

class User extends Model {}
User.modelName = 'User';
User.fields = {
    id                  : attr(),
    firstName           : attr(),
    lastName            : attr(),
    socialSecurityNumber: attr(),
    countryCode         : attr(),
    topics              : attr(),
    state               : attr(),
    profiles            : attr(),
    profilesStr         : attr(),
};

class AuditEntry extends Model {}
AuditEntry.modelName = 'AuditEntry';
AuditEntry.fields = {
    id             : attr(),
    auditDateTime  : attr(),
    model          : attr(),
    prototype      : attr(),
    rootPrototype  : attr(),
    operationType  : attr(),
    apiVersion     : attr(),
    client         : attr(),
    user           : attr(),
    modifiedObject : attr(),
};

class Topic extends Model {}
Topic.modelName = 'Topic';
Topic.fields = {
    topic           : attr(),
};

class NoRunDate extends Model {}
NoRunDate.modelName = 'NoRunDate';
NoRunDate.fields = {
    date           : attr(),
};

class SharedSetting extends Model {}
SharedSetting.modelName = 'SharedSetting';
SharedSetting.fields = {
    id              : attr(),
    name            : attr(),
    state           : attr(),
    createdByUser   : fk('User', 'createdBlobs'),
    changedDateTime : attr(),
};

class Tenor extends Model {}
Tenor.modelName = 'Tenor';
Tenor.fields = {
    id      : attr(),
    tenor   : attr(),
    enabled : attr(),
};

class BankHoliday extends Model {}
BankHoliday.modelName = 'BankHoliday';
BankHoliday.fields = {
    id                  : attr(),
    currency            : attr(),
    description         : attr(),
    comment             : attr(),
    timezone            : attr(),
    startDateTime       : attr(),
    endDateTime         : attr(),
    name                : attr(),
    date                : attr(),
    createdDateTime     : attr(),
    createdByUser       : attr(),
    changedDateTime     : attr(),
    changedByUser       : attr(),
    disabledDateTime    : attr(),
    disabledByUser      : attr(),
};

class RootBankHoliday extends Model {}
RootBankHoliday.modelName = 'RootBankHoliday';
RootBankHoliday.fields = {
    id                  : attr(),
    bankHolidays        : attr(),
};

class LedgerAccount extends Model {}
LedgerAccount.modelName = 'LedgerAccount';
LedgerAccount.fields = {
    id      : attr(),
    ledgerAccountId: attr(),
    type    : attr(),
    balance : attr(),
};

class LedgerAccountBalance extends Model {}
LedgerAccountBalance.modelName = 'LedgerAccountBalance';
LedgerAccountBalance.fields = {
    id:              attr(),
    balanceId:       attr(),
    ledgerAccountId: fk('LedgerAccount', 'balances'),
    balance:         attr(),
    balanceTime:     attr(),
    valueDate:       attr(),
    comment:         attr(),
    createdDateTime: attr(),
    createdByUser:   attr(),
    changedDateTime: attr(),
    changedByUser:   attr(),
    disabledDateTime:attr(),
    disabledByUser:  attr(),
};


class FxTradeReportEvent extends Model {}
FxTradeReportEvent.modelName = 'FxTradeReportEvent';
FxTradeReportEvent.fields = {
    id: attr(),
    externalId: attr(),
    eventType: attr(),
    eventDateTime: attr(),
    createdDateTime: attr(),

    account: attr(),
    amount: attr(),
    productType: attr(),
    currency: attr(),
    symbol: attr(),
    side: attr(),
    tenor: attr(),
    settlementDate:attr(),
    orderStatus: attr(),
    statusMessage: attr(),
    reportStatus: attr(),
    fxOrderReportId: fk('FxOrderReport', 'fxTradeReportEvents'),
    relatedFxOrderReportId: fk('FxOrderReport', 'fxTradeReportEventsAsRelated'),
};

class FxOrderReport extends Model {}
FxOrderReport.modelName = 'FxOrderReport';
FxOrderReport.fields = {
    id: attr(),
    createdDateTime: attr(),
    marketId: attr(),
    reference: attr(),
    account: attr(),
    amount: attr(),
    productType: attr(),
    currency: attr(),
    symbol: attr(),
    side: attr(),
    tenor: attr(),
    settlementDate:attr(),
    orderStatus: attr(),
    statusMessage: attr(),
    reportStatus: attr(),
    legalEntityUnitId: fk('LegalEntityUnit', 'fxOrderReports'),
};

class FxExecutionLeg extends Model {}
FxExecutionLeg.modelName = 'FxExecutionLeg';
FxExecutionLeg.fields = {
    id: attr(),
    legRole: attr(),
    fxOrderReportId: fk('FxOrderReport', 'fxExecutionLegs'),
    fxExecutionReportId: fk('FxExecutionReport', 'fxExecutionLegs'),
};

class FxExecutionReport extends Model {}
FxExecutionReport.modelName = 'FxExecutionReport';
FxExecutionReport.fields = {
    id: attr(),
    createdDateTime: attr(),
    executedDateTime: attr(),
    UTI: attr(),
    execId: attr(),
    message: attr(),
    price: attr(),
    spotRate: attr(),
    forwardPoints: attr(),
    settlementDate: attr(),
};

class FxTradeReportVoucher extends Model {}
FxTradeReportVoucher.modelName = 'FxTradeReportVoucher';
FxTradeReportVoucher.fields = {
    id: attr(),
    fxOrderReports: many('FxOrderReport', 'vouchers'),
};



function addIfUsed(target, isUsed, addArray) {
    if (!isUsed) {
        return;
    }
    target.push(...addArray);
}
function getCommonOrmModels(appConfig) {
    const returnArray = [Country, Currency, CurrencyPair, Tenor, NFCProcessStatus, OperatingUnit, User, SharedSetting];

    addIfUsed(returnArray, appConfig.useFx                      , [FxPrice, FixMessage, FixSession]);
    addIfUsed(returnArray, appConfig.erp                        , [ErpSystem, ErpIntegration]);
    addIfUsed(returnArray, appConfig.audit                      , [AuditEntry]);
    addIfUsed(returnArray, appConfig.userMessages               , [Topic]);
    addIfUsed(returnArray, appConfig.useNoRunDates              , [NoRunDate]);
    addIfUsed(returnArray, appConfig.useBankHolidays            , [BankHoliday, RootBankHoliday]);
    addIfUsed(returnArray, appConfig.useLedger                  , [LedgerAccount, LedgerAccountBalance]);
    addIfUsed(returnArray, appConfig.useCurrencyPairSettings    , [CurrencyPairSettings]);
    addIfUsed(returnArray, appConfig.useFxRejectConfig          , [FxRejectConfiguration]);
    addIfUsed(returnArray, appConfig.useFxTradeReport           , [FxTradeReportEvent, FxOrderReport, FxExecutionLeg, FxExecutionReport, FxTradeReportVoucher]);
    return returnArray;
}

export function registerOrmModels(appConfig) {
    orm.register(...getCommonOrmModels(appConfig), ...appConfig.orm.models)
}
export const globalOrmConfig = {
    nestedEntities: commonNestedOrmEntities,
    parentProperties: commonOrmParentProperties,
    compareFunctions: commonOrmCompareFunctions,
    onUpdateThunks: {},
    modelConfig: commonOrmModelConfig,
};
