import {
    activeFxPricesSelector,
} from 'gui-common/orm/ormSelectors.js'
import {createSelector} from "reselect";
import {createSelector as createOrmSelector} from 'redux-orm';
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {orm} from "gui-common/orm/orm";

const activeFxPrices      = state          => activeFxPricesSelector(state);
const filterCurrencyPairs = (state, props) => props.filterCurrencyPairs;


export const fxPricesToDisplaySelector = createSelector(
    [activeFxPrices,filterCurrencyPairs],
    (activeFxPrices,filterCurrencyPairs) => {
        if (!activeFxPrices)          return undefined;

        const currencyPairInFilterArray = (currencyPair) => {
            return filterCurrencyPairs.find(item => item === currencyPair.id)
        };
        const buyPricesOnly = (price) => {return price.indicator === "SELL"};

        let returnArray = activeFxPrices.filter(buyPricesOnly);
        if (filterCurrencyPairs && filterCurrencyPairs.length) {
            returnArray = returnArray.filter(currencyPairInFilterArray);
        }
        // console.log("fxPricesToDisplaySelector returned: ", returnArray);
        return returnArray;
    }
);

export const getCurrentFxPriceSelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectCurrencyPairId : undefined,
    (session, selectCurrencyPairId) => {
        if (!selectCurrencyPairId) return;

        let currencyPairModel = getOrmModelObjectById(session, 'CurrencyPair', selectCurrencyPairId);
        if (!currencyPairModel) return;

        let buyPrice = session.FxPrice.filter(item => item.currencyPairId === selectCurrencyPairId).toRefArray().find(item => item.indicator === 'BUY');
        if (!buyPrice) return;
        return {
            fxRate: buyPrice.midRate,
            currencyPair: currencyPairModel.ref
        }
   }
);
