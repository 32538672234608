export const fxTradeReportDeliveryStates = {
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED'
}
export const fxTradeReportRequestTypes = {
    NEW: 'NEW',
    CONFIRMED: 'CONFIRMED',
    TRADED: 'TRADED',
    OPEN: 'OPEN'
}
