import PropTypes from "prop-types";
import React, {useMemo} from "react";
import XpTooltip from "gui-common/components/XpTooltip";

import "./erpIntegration.css"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    requestCancelErpConnectionFromApi,
    requestRevokeErpConnectionFromApi
} from "gui-common/erpIntegration/erpIntegrationApi";
import {useDispatch} from "react-redux";
import XpContextMenu from "gui-common/contextMenu/XpContextMenu";
import spinnerGif from "gui-common/resources/spinner.gif";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import {CONFIRMATION_DIALOG} from "gui-common/modals/modalConstants";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getErpIntegrationSelector} from "gui-common/erpIntegration/erpIntegrationSelectors";
import {isArray} from "lodash";


function AdminErpServiceWidget (props) {

    const erpIntegration = useSelectorInstance(getErpIntegrationSelector, {selectId: props.erpIntegrationId});
    // const [erpStatusAtConnecting, setErpStatusAtConnecting] = useState(undefined);
    const dispatch = useDispatch();
    // const erpConfig = useAppEnvProperty('erpIntegrationConfig');
    // const [revokeRequested,   setRevokeRequested]   = useState(false);

    const backgroundCss = useMemo(
        () => {
            if (!erpIntegration?.erpEnabled) return 'erpIntegrationNotConfigured';
            switch (erpIntegration?.status) {
                case 'CONNECTED'    : return 'erpIntegrationReady';
                case 'CONNECTING'   : return 'erpIntegrationWarning';
                case 'EXPIRED'      :
                case 'REVOKED'      :
                case 'TIMEOUT'      :
                case 'FAILED'       : return 'erpIntegrationError';
                case 'INITIAL'      : return 'erpIntegrationNotConnected';
                default:
            }
            return 'erpIntegrationNotConnected';
        },
        [erpIntegration?.status, erpIntegration?.erpEnabled]
    );

/*
    useEffect(
        () => {
            if (erpIntegration?.status === 'REVOKED') {
                setRevokeRequested(false);
            }
        },
        [erpIntegration?.status],
    );
*/

    const contextMenuItems = useMemo(
        () => {
            const returnArray = [];
            if (erpIntegration?.status === 'CONNECTED') {
                returnArray.push ({
                    trKey: 'erpIntegration.contextMenuFunctions.revoke.label',
                    trParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName},
                    action: () => dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'erpIntegration.contextMenuFunctions.revoke', modalParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName}}))
                        .then((result) => {
                            if (result.status === MODAL_TYPE_CONFIRM) dispatch(requestRevokeErpConnectionFromApi(erpIntegration));
                        }),
                })
            }
            else if (erpIntegration?.status === 'CONNECTING') returnArray.push ({
                trKey: 'erpIntegration.contextMenuFunctions.cancel.label',
                trParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName},
                action: () => dispatch(pushModalWindow(CONFIRMATION_DIALOG, {modalKey: 'erpIntegration.contextMenuFunctions.cancel', modalParams: {name: erpIntegration?.erpSystem?.name, boName: props.businessObjectName}}))
                    .then((result) => {
                        if (result.status === MODAL_TYPE_CONFIRM) {
                            dispatch(requestCancelErpConnectionFromApi(erpIntegration))
                                .then( () =>
                                {
                                    // erpLoginWindow.close();
                                });
                        }
                    }),
            })
            if (isArray(props.contextMenuItems)) {
                returnArray.push(...props.contextMenuItems);
            }
            return returnArray;
        },
        [erpIntegration, props.businessObjectName, props.contextMenuItems, dispatch]
    );

    const tooltipTrKey = useMemo(
        () => {
            if (props.erpEnabled) {
                return 'erpIntegration.erpConnectionStatusTooltip.' + erpIntegration?.status;
            }
            return 'erpIntegration.erpConnectionStatusTooltip.NO_ERP';
        },
        [props.erpEnabled, erpIntegration?.status]
    );

    const textTrKey = useMemo(
        () => {
            if (props.erpEnabled) {
                return 'erpIntegration.erpConnectionStatus.' + erpIntegration?.status;
            }
            return 'erpIntegration.erpConnectionStatus.NO_ERP';
        },
        [props.erpEnabled, erpIntegration?.status]
    );

/*
    function getConnectButtonLabel() {
        if (erpIntegration?.status === 'CONNECTED') {
            return "erpIntegration.erpConnectionStatus.connectedButton.label"
        }
        if (erpIntegration?.status === 'CONNECTING') {
            return "erpIntegration.erpConnectionStatus.connectingButton.label"
        }
        return "erpIntegration.erpConnectionStatus.connectButton.label"
    }
*/

    return (
            <XpContextMenu
                bypass    = {!erpIntegration?.erpEnabled}
                menuItems = {contextMenuItems}
            >
                <XpTooltip placement="top" trKey={tooltipTrKey} trParams={{boName: props.businessObjectName, erpSystemName: erpIntegration?.erpSystem?.name}}>
                    <div className={"erpIntegrationIndicatorBase " + backgroundCss} style={props.removeBorder ? {border: 'none'} : {}}>
                        {(erpIntegration?.status === 'CONNECTING') &&
                        <img className="smallTableSpinner" src={spinnerGif} alt="waiting" style={{margin: '-5px', marginTop: '-7px'}}/>}
                        <XpTranslated trKey={textTrKey} />
                    </div>
                </XpTooltip>
            </XpContextMenu>
    )
}
AdminErpServiceWidget.propTypes = {
    erpIntegrationId: PropTypes.number,
    businessObjectName: PropTypes.string,
    connectButtonOnly: PropTypes.bool
};
export default AdminErpServiceWidget
