import {nonAuthorizedFetch} from "gui-common/api/apiCommon";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {globalSimulatorsConfig} from "gui-common/simulators/simulatorConstants";

const urlFunctionMap = {
    defaultPriceSpread  : "client-market/default-price-spread",
    clientPriceOverride : "client-prices/price-override",
    clientPrice         : "client-prices",
    fixAccounts         : "client-prices/fix-accounts",
}
function invalidClientPrice(clientPrice) {
    return (!clientPrice || !clientPrice.sessionId || !clientPrice.tenor);
}
function getRequestUrl(getState, functionName, clientPrice, currencyPairToRequest) {
    let returnUrl = selectAppEnvProperty(getState(), 'marketSimBaseUrl') + globalSimulatorsConfig.fxMarketSimulator.baseUrl;
    returnUrl += urlFunctionMap[functionName] + "?";
    returnUrl += "sessionId="       + clientPrice.sessionId;
    returnUrl += "&currencyPair="   + currencyPairToRequest;
    returnUrl += "&tenor="          + clientPrice.tenor;
    if (clientPrice.fixAccount) {
        returnUrl += "&fixAccount=" + clientPrice.fixAccount;
    }
    return returnUrl;
}

export function getClientPriceFromApi(clientPrice) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (invalidClientPrice(clientPrice)) return;
            const currencyPairToRequest = clientPrice.currencyPair ? clientPrice.currencyPair.key : "ALL"


            let logText = "Get price for " + currencyPairToRequest;
            let requestUrl =  getRequestUrl(getState, 'clientPrice', clientPrice, currencyPairToRequest);

            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}

export function overrideClientPriceToApi(clientPrice) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (invalidClientPrice(clientPrice)) return;
            const currencyPairToRequest = clientPrice.currencyPair ? clientPrice.currencyPair.key : "ALL"

            let logText = "Set price override for " + currencyPairToRequest;
            let requestUrl =  getRequestUrl(getState, 'clientPriceOverride', clientPrice, currencyPairToRequest);

            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'PUT',
                {
                    clientPriceOverride: clientPrice.clientPriceOverride,
                    functionOverride: clientPrice.functionOverride
                },
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}

export function getClientPricesWithOverridesFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get prices with overrides";
            const requestUrl = selectAppEnvProperty(getState(), 'marketSimBaseUrl') + globalSimulatorsConfig.fxMarketSimulator.baseUrl + "client-prices/all-with-overrides?";

            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}

export function getFixAccountsFromApi() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let logText = "Get fix accounts";
            const requestUrl = selectAppEnvProperty(getState(), 'marketSimBaseUrl') + globalSimulatorsConfig.fxMarketSimulator.baseUrl + urlFunctionMap.fixAccounts;


            dispatch(nonAuthorizedFetch(requestUrl, logText,
                (resp) => {
                    return (dispatch, getState) => {
                        // dispatch(marketSimulatorConfigUpdate(clientPrice));
                    }},
                emptyFailHandler,
                emptyFailHandler,
                'GET'
            )).then(result => {resolve(result)}).catch(err => {reject(err)});

        })
    }
}

function emptyFailHandler(resp) {
    return (dispatch, getState) => {
    }
}
