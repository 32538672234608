import {createSelector as createOrmSelector} from "redux-orm";
import {createSelector} from 'reselect'
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {createTranslatedFieldData, translateBoolFields} from "gui-common/xpGrid/xpGridFunctions";
import {entityIsActive} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";
import {reFixAgreementsSelector} from "features/agreement/agreementSelectors";
import {legalEntityUnitsSelector} from "features/legalEntityUnit/legalEntityUnitSelectors";


const getOverridesString = (overrides) => {
    if (!Array.isArray(overrides)) return "";
    let retStr = "";

    overrides.forEach(override => {
        const cp = override.currencyPair;
        retStr += ', ' + cp.baseCurrency.currency + cp.quoteCurrency.currency;
    });
    return (retStr === '') ? retStr : retStr.slice(2);
};

const buildMarginGroupObject = (marginGroupModel) => {
    if (!marginGroupModel) return undefined;
    const returnObject = {
        ...marginGroupModel.ref,
        numberOfAgreements: marginGroupModel.agreements.all().filter(entityIsActive).toModelArray().length,
        numberOfLeus: marginGroupModel.legalEntityUnits.all().filter(entityIsActive).toModelArray().length,
        // ...createMomentObjectsFromDateTimes(marginGroupModel),
        overrides: getOverridesString(marginGroupModel.ref.priceMarginOverrides),
    };
    return returnObject;
};

export const getActiveMarginGroupsSelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];
        session.MarginGroup.all().filter(entityIsActive).orderBy('name', 'asc').toModelArray().forEach(marginGroupModel => {
            returnArray.push(buildMarginGroupObject(marginGroupModel));
        });
        return returnArray;
    }
);
export const marginGroupsSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = [];
        session.MarginGroup.all().orderBy('name', 'asc').toModelArray().forEach(marginGroupModel => {
            returnArray.push(buildMarginGroupObject(marginGroupModel));
        });
        return returnArray;
    }
);

export const getSpecificMarginGroupSelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, selectId) => {
        if (!selectId) return null;
        const modelObject = getOrmModelObjectById(session, 'MarginGroup', selectId);
        const returnObject = buildMarginGroupObject(modelObject);
        return returnObject;
    }
);

const translate        = (state, props) => selectTranslateFunction(state);
const allMarginGroups  = state          => marginGroupsSelector(state);
const filterFunction   = (state, props) => props ? props.filterFunction : undefined;
export const getMarginGroupsListDataSelector = () => createSelector(
    [allMarginGroups, translate, filterFunction, reFixAgreementsSelector, legalEntityUnitsSelector],
    (allMarginGroups, translate, filterFunction, agreements, leus) => {
        // console.log("Selector getLegalEntitiesListDataSelector running ");
        const marginGroups = filterFunction ? allMarginGroups.filter(filterFunction) : allMarginGroups;
        let returnArray       = [];

        for (let marginGroup of marginGroups) {
            const enrichedMarginGroup = {...marginGroup};
            translateBoolFields(enrichedMarginGroup, translate);
            createTranslatedFieldData(enrichedMarginGroup, 'priceType', translate, ("runConfiguration.marginGroupOverride.priceType.values." + enrichedMarginGroup.priceType));
            returnArray.push(enrichedMarginGroup);
        }
        // console.log("Selected getLegalEntitiesListDataSelector:", returnArray);
        return returnArray;
    }
);

/********* New margin group template selector *****************************************************************/

export const newMarginGroupFormTemplateSelector = createSelector(
    [],
    () => {
        return {
            name                    : "",
            description             : "",
            default                 : false,
            sortIndex               : -1,
            bidMarginBps            : "",
            askMarginBps            : "",
            priceType               :  "BIDASK",
            runConfiguration        : {}, //  priceMarginOverrides is handled as a run configuration in order to reuse the runConfiguration functionality.
        }
    }
);

