import {createSelector as createOrmSelector} from "redux-orm";
import {createSelector} from 'reselect'
import {
    createMomentObjectsForProperties,
    createMomentObjectsFromDateTimes,
    getDefaultObject
} from "gui-common/functions/functions";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {selectAppEnvProperty, useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {entityIsActive, getLastChange} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";
import {operatingUnitsWithFullClientCreateSelector} from "features/operatingUnit/operatingUnitSelectors";

export const shallowActiveClientsSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {

        let returnArray = [];
        session.Client.all().filter(entityIsActive).orderBy('name', 'asc').toModelArray().forEach(clientModel => {
            const obj = clientModel.ref;
            returnArray.push({
                ...obj,
                ...createMomentObjectsFromDateTimes(obj),
                ...getLastChange('Client', clientModel),
            });
        });

        // console.log("Shallow active clients:");
        // console.log(returnArray);
        return returnArray;
    }
);

export const clientsSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        return session.Client.all().toModelArray().map(item => item.ref);
    }
);
export const shallowActiveClientSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        return undefined;
    }
);
export const buildClientObject = (session, entityId, getDeep) => {
    const client = session.Client.idExists(entityId) ? session.Client.withId(entityId) : undefined;
    if (!client) return undefined;

    const obj = client.ref;

    const returnObject = {
        ...obj,
        ...createMomentObjectsFromDateTimes(obj),
 //       operatingUnit: session.OperatingUnit.idExists(obj.operatingUnitId) ? session.OperatingUnit.withId(obj.operatingUnitId).ref : null,
        legalEntities: getDeep ? client.legalEntities.all().filter(entityIsActive).toModelArray().map(entity => {
            return {
                client: client.ref,
                fxTradeReportErpConfiguration: entity.fxTradeReportErpConfiguration?.ref,
                ...entity.ref,
                ...createMomentObjectsFromDateTimes(entity.ref),
//                country: session.Country.idExists(entity.ref.countryId) ? session.Country.withId(entity.ref.countryId).ref.country : "",
                legalEntityUnits: entity.legalEntityUnits.all().filter(entityIsActive).toModelArray().map(unit => {
                    return {
                        client: client.ref,
                        legalEntity: entity.ref,
                        ...unit.ref,
                        ...createMomentObjectsFromDateTimes(unit.ref),
                        ...createMomentObjectsForProperties(unit.ref, ['todayLimitOverrideDateTime']),
//                        tradingLimitCurrency: session.Currency.idExists(obj.tradingLimitCurrencyId) ? session.Currency.withId(obj.tradingLimitCurrencyId).ref.currency : "",
                        agreements: unit.agreements.all().filter(entityIsActive).toModelArray().map(agreement => {
                            return {
                                ...agreement.ref,
                                ...createMomentObjectsFromDateTimes(agreement.ref),
                                ...createMomentObjectsForProperties(agreement.ref, ['suspendedDateTime', 'resumedDateTime']),
                            }
                        }),
                    }
                }),
            }
        }) : undefined,
    };
    return returnObject;
};
export const getSpecificClientSelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, clientId) => {
        if (!clientId) return null;

        const returnObject = buildClientObject(session, clientId, true);
        return returnObject;
    }
);
export const getShallowClientSelector = () => createOrmSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (session, clientId) => {
        if (!clientId) return null;

        const returnObject = buildClientObject(session, clientId, false);
        return returnObject;
    }
);


/********* New client template selector *****************************************************************/

const apiChannels = state => selectAppEnvProperty(state, 'availableClientApiChannels');

const availableTimeZones = state => selectAppEnvProperty(state, 'availableTimeZones');
const operatingUnits     = state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForAdmin'});
export const newClientFormTemplateSelector = createSelector(
    [
        availableTimeZones,
        operatingUnits,
        apiChannels
    ],
    (availableTimeZones, operatingUnits, apiChannels) => {
        const defaultOpUnit = getDefaultObject(operatingUnits);
        const defaultApiChannel = getDefaultObject(apiChannels);
        return {
            operatingUnitId     : defaultOpUnit ? defaultOpUnit.id : undefined,
            timeZone            : getDefaultObject(availableTimeZones).id,
            clientApiChannel    : defaultApiChannel?.id,
            // timeZone: momentTZ.tz.guess(),
            id:                     "",
            name:                   "",
            description:            "",
            accountManager:         "",
            clientRefId:            "",
            useLegalEntities:       false,
            createdDateTime:        null,
            createdByUser:          "",
            changedDateTime:        null,
            changedByUser:          "",
        }
    }
);

