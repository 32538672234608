import React from 'react'
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {selectOrmItemsLoading} from "gui-common/orm/ormLoadingSelectors";
import spinnerGif from 'gui-common/resources/spinner.gif';
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {selectLoadingTrKey} from "gui-common/api/apiSelectors";

function DataLoading() {
    const loadingTrKey = useSelector(selectLoadingTrKey);
    const ormItemsLoading = useSelector(selectOrmItemsLoading);
    if (!selectOrmItemsLoading || !selectOrmItemsLoading.length)

    return (
        <div>
            <div style={{paddingTop: 0, marginTop: "-10px"}} xp-test-id='xpDataLoaderSpinner'>
                <img className="loadingSpinner" src={spinnerGif} alt="waiting" />
                <XpTranslated trKey={loadingTrKey}/>
            </div>
        {ormItemsLoading.map(model =>
            <div key={model} style={{paddingTop: 0, marginTop: "-10px"}} xp-test-id='xpDataLoaderSpinner'>
                <img className="loadingSpinner" src={spinnerGif} alt="waiting" />
                <XpTranslated trKey={'general.loadingData'}/>
                <XpTranslated trKey={'general.modelNamesLargePlural.'+model}/>
                ...
            </div>
        )}
        </div>
    );
}

export default withRouter(DataLoading);
