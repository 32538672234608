import {createSelector} from "reselect";
import {getFormatter, speedFormatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {getCleanAgreementsSelector, reFixAgreementsSelector} from "features/agreement/agreementSelectors";
import {roundRate} from 'features/currencyExposure/currencyExposureFunctions';
import {
    activeFxPricesSelector,
} from 'gui-common/orm/ormSelectors.js'
import {entityIsActive} from "gui-common/audit/auditFunctions";

const agreementSelector = getCleanAgreementsSelector();
function agreementsFilterFunction(item) {
    return (entityIsActive(item) && (item.type === 'ListRateAgreement'))
}
const listRateAgreementsL0 = state => agreementSelector(state, {filterFunction: agreementsFilterFunction});
export const selectActiveCurrencies = createSelector(
    [listRateAgreementsL0],
    (listRateAgreementsL0) => {
        if (!listRateAgreementsL0) return [];

        let activeCurrencies = [];
        for (let agreement of listRateAgreementsL0) {
            if (agreement.type !== 'ListRateAgreement') continue;
            if (!activeCurrencies.find(curr => curr.id === agreement.currencyPair.baseCurrency.id )) activeCurrencies.push(agreement.currencyPair.baseCurrency);
            if (!activeCurrencies.find(curr => curr.id === agreement.currencyPair.quoteCurrency.id)) activeCurrencies.push(agreement.currencyPair.quoteCurrency);
        }
        return activeCurrencies.sort((firstEl,secondEl) => firstEl.sortOrder - secondEl.sortOrder);
    }
);

const activeCurrencies     = state => selectActiveCurrencies(state);
const listRateAgreements = state => reFixAgreementsSelector(state);
const homeCurrency         = state => state.userPreferencesState.homeCurrency;
const activeFxPrices      = state          => activeFxPricesSelector(state);
export const selectCurrencyExposures = createSelector(
    [listRateAgreements, activeCurrencies, homeCurrency, activeFxPrices],
    (listRateAgreements, activeCurrencies, homeCurrency, activeFxPrices) => {
        if (!listRateAgreements)  return [];
        if (!activeCurrencies) return [];
        if(!activeFxPrices) return [];

        let exposures = {};
        for (let curr of activeCurrencies) {
            exposures[curr.currency] = {
                id:                     curr.id,
                currency:               curr.currency,
                BS:                     "",
                amount:                 0,
                exposureBaseCurrency:   0,
                exposureAvgRate:        0,
                marketRate:             0,
                agreements:             [],
                listRates :             [],
                isActive:               false,
            };
        }
        for (let agreement of listRateAgreements) {
            if (agreement.type !== 'ListRateAgreement') continue;

            let baseExposure  = exposures[agreement.baseCurrency];
            let quoteExposure = exposures[agreement.quoteCurrency];

            baseExposure.agreements.push(agreement);
            quoteExposure.agreements.push(agreement);

            if (!agreement.listRates || !agreement.listRates.length) continue;

            if (agreement.listRates) {
                baseExposure.listRates  = baseExposure.listRates.concat(agreement.listRates);
                quoteExposure.listRates = quoteExposure.listRates.concat(agreement.listRates);
            }

            //console.log('listrates', agreement.listRates);
            for (let listRate of agreement.listRates) {
                if ((listRate.status !== 'Active') && (listRate.status !== 'InExpiry')) continue;
                baseExposure.isActive = true;
                quoteExposure.isActive = true;

                for (let dispatchTrade of listRate.dispatchTrades) {
                    if (dispatchTrade.status !== 'Active') continue;

                    const convertedAmount = (dispatchTrade.amountCurrency.currency === agreement.baseCurrency) ? dispatchTrade.openAmount : dispatchTrade.openAmount / dispatchTrade.rate;

                    const side = (dispatchTrade.side === 'BUYSELL') ? (convertedAmount < 0 ? 'SELL' : 'BUY') : dispatchTrade.side;

                    if (side === 'BUY') {
                        let nBuyRate = agreement.quoteCurrency === homeCurrency ? listRate.buyPrice : currentRate(agreement.baseCurrency, homeCurrency, activeFxPrices);
                        baseExposure.openSell = (baseExposure.openSell === undefined ? 0 : baseExposure.openSell) + convertedAmount;
                        baseExposure.exposureAvgRate = newAvgRate(baseExposure.amount, baseExposure.exposureAvgRate, convertedAmount * -1, nBuyRate);
                        baseExposure.amount = baseExposure.amount - convertedAmount;
                        baseExposure.exposureBaseCurrency = 0;

                        quoteExposure.openSell = (quoteExposure.openSell === undefined ? 0 : quoteExposure.openSell) + convertedAmount;
                        quoteExposure.exposureAvgRate = agreement.quoteCurrency === homeCurrency ? 1 : newAvgRate(quoteExposure.amount, quoteExposure.exposureAvgRate, convertedAmount, agreement.baseCurrency === homeCurrency ? 1 / listRate.buyPrice : currentRate(agreement.quoteCurrency, homeCurrency, activeFxPrices));
                        quoteExposure.amount = quoteExposure.amount + convertedAmount * listRate.buyPrice;
                        quoteExposure.exposureBaseCurrency = 0;
                    }
                    if (side === 'SELL') {
                        let nSellRate = agreement.quoteCurrency === homeCurrency ? listRate.sellPrice : currentRate(agreement.baseCurrency, homeCurrency, activeFxPrices);
                        // ToDo: fix openrates to handle equal buy and sell  --baseExposure.openBuyRate = (baseExposure.openBuyRate === undefined ? 0 : baseExposure.openBuyRate)
                        baseExposure.openBuy = (baseExposure.openBuy === undefined ? 0 : baseExposure.openBuy) + convertedAmount;
                        baseExposure.exposureAvgRate = newAvgRate(baseExposure.amount, baseExposure.exposureAvgRate, convertedAmount, nSellRate);
                        baseExposure.amount = baseExposure.amount + convertedAmount;
                        baseExposure.exposureBaseCurrency = 0;

                        quoteExposure.openBuy = (quoteExposure.openBuy === undefined ? 0 : quoteExposure.openBuy) + convertedAmount;
                        quoteExposure.exposureAvgRate = agreement.quoteCurrency === homeCurrency ? 1 : newAvgRate(quoteExposure.amount, quoteExposure.exposureAvgRate, convertedAmount, agreement.baseCurrency === homeCurrency ? 1 / listRate.sellPrice : currentRate(agreement.quoteCurrency, homeCurrency, activeFxPrices));
                        quoteExposure.amount = quoteExposure.amount - convertedAmount * listRate.sellPrice;
                        quoteExposure.exposureBaseCurrency = 0;
                    }
                    baseExposure.BS = baseExposure.amount < 0 ? "S" : "B";
                    quoteExposure.BS = quoteExposure.amount < 0 ? "S" : "B";
                }
            }
        }
        let retExposures = [];
        for (let currency in exposures) {
            exposures[currency].marketRate = currentRate(currency, homeCurrency, activeFxPrices);
            exposures[currency].exposureBaseCurrency = exposures[currency].isActive ? exposures[currency].amount * exposures[currency].marketRate : "";
            exposures[currency].PNL = exposures[currency].isActive ? exposures[currency].amount * exposures[currency].marketRate - exposures[currency].amount * exposures[currency].exposureAvgRate : "";
            exposures[currency].amount = exposures[currency].isActive ? exposures[currency].amount : "";
            retExposures.push(exposures[currency]);
        }
        return retExposures;
    }
);
const newAvgRate = (initialAmount, initialRate, newAmount, newRate) => {
    if(initialAmount + newAmount === 0) {
        return 0;
    }
    return ((initialAmount * initialRate) + (newAmount * newRate)) / (initialAmount + newAmount);
};
const currentRate = (currency, homeCurrency, fxRates) => {
    if (!currency || !homeCurrency) return "N/A";
    if(currency === homeCurrency)   return 1;

    let price = fxRates.find(cPair => cPair.currencyPair === ((currency + "/" + homeCurrency)));
    if (price) return price.midRate;

    // No price for currency/baseCurrency found => check inverted currency pair:
    price = fxRates.find(cPair => cPair.currencyPair === ((homeCurrency + "/" + currency)));
    if (price) return 1 / price.midRate;

    // No price for baseCurrency/currency found => return error string:
    return "N/A";
};

const currencyExposures = state => selectCurrencyExposures(state);
const translate         = state => selectTranslateFunction(state);
const decDenLangState   = state => selectDecDenLangState(state);
const filterFunction    = (state, props) => props ? props.filterFunction : undefined;
export const selectCurrencyExposuresListData = createSelector(
    [currencyExposures, translate, decDenLangState, filterFunction],
    (currencyExposures, translate, decDenLangState, filterFunction) => {
        if (!currencyExposures)  return [];
        if (!translate) return [];
        if (!decDenLangState) return [];
        const formatter = getFormatter(decDenLangState);
        const formatA = (amount) => {
            return speedFormatAmount(amount, decDenLangState, formatter)
        };
        let outArray = [];
        for (let curr of currencyExposures) {
            if (filterFunction && !filterFunction(curr)) continue; // Handling of filter injected from parent component in master detail grids
            outArray.push({
                ...curr,
                currency                : curr.currency,
                BS                      : curr.BS,
                amountT                 : formatA(curr.amount),
                exposureBaseCurrencyT   : isNaN(curr.exposureBaseCurrency) ? translate('currencyExposureList.noMarketRate') : formatA(curr.exposureBaseCurrency),
                exposureAvgRateT        : curr.exposureAvgRate === 0 ? "" : roundRate(curr.exposureAvgRate),
                marketRateT             : isNaN(curr.marketRate) ? translate('currencyExposureList.noMarketRate') : roundRate(curr.marketRate),
                PNLT                    : formatA(curr.PNL)
            });
        }
        return outArray;
    }
);
export const selectOverallCurrencyExposure = createSelector(
    [currencyExposures, decDenLangState, homeCurrency],
    (currencyExposures, decDenLangState, homeCurrency) => {
        if (!currencyExposures)  return [];
        if (!decDenLangState) return [];
        const formatter = getFormatter(decDenLangState);
        const formatA = (amount) => {
            return speedFormatAmount(amount, decDenLangState, formatter)
        };
        let totalExposure = 0;
        let agreements = [];
        let listRates = [];
        for (let curr of currencyExposures) {
            totalExposure += curr.currency !== homeCurrency ? Math.abs(curr.exposureBaseCurrency ? curr.exposureBaseCurrency : 0) : 0;
            for (let agreement of curr.agreements) {
                if (!agreements.find(item => item.id === agreement.id)) {
                    agreements.push(agreement);
                    if (agreement.listRates) listRates = listRates.concat(agreement.listRates);
                }
            }
        }
        return {totalExposure: formatA(totalExposure), agreements: agreements, listRates: listRates};
    }
);
