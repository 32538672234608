import {createSelector as createOrmSelector} from 'redux-orm';
import {orm} from "gui-common/orm/orm";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";

export const processPrototypeStatusMapSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        const processStatusList = session.NFCProcessStatus.orderBy('name', 'asc').toRefArray();
        const prototypeMap = {};
        for (const processStatus of processStatusList) {
            if (!prototypeMap[processStatus.processPrototype]) {
                prototypeMap[processStatus.processPrototype] = {};
            }
            if (processStatus.processInstanceId !== 0) {
                prototypeMap[processStatus.processPrototype][processStatus.processInstanceId] = processStatus;
            }
        }
        return prototypeMap;
    }
);
