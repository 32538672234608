import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import XpTooltip from "gui-common/components/XpTooltip";
import refreshIcon from "gui-common/resources/refresh3.png";
import warningLight from "gui-common/resources/warningLight.gif";
import spinnerGif from "gui-common/resources/croppedSpinner.gif";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import {webSocketStatus as webSocketState} from "gui-common/api/apiConstants";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";

const toolTipMap = {
    [webSocketState.RUNNING] : 'topMenu.reloadButton.tooltip',
    [webSocketState.DOWN]    : 'topMenu.reloadButton.socketDown',
    [webSocketState.OPEN]    : 'topMenu.reloadButton.socketStarting',
    [webSocketState.STARTING]: 'topMenu.reloadButton.socketStarting',
}
const iconMap = {
    [webSocketState.RUNNING] : refreshIcon,
    [webSocketState.DOWN]    : warningLight,
    [webSocketState.OPEN]    : spinnerGif,
    [webSocketState.STARTING]: spinnerGif,
}
const cursorMap = {
    [webSocketState.RUNNING] : 'pointer',
    [webSocketState.DOWN]    : 'pointer',
    [webSocketState.OPEN]    : 'progress',
    [webSocketState.STARTING]: 'progress',
}

function ApiStatusIcon (props) {
    const oxygenAppContext = useOxygenAppContext();
    const appReady         = useSelector(selectAppReadyState);
    const appStartPath     = useAppEnvProperty('appStartPath');
    const router           = useSelector(state => state.router);

    const reloadAllowed = useMemo(
        () => {
            return [webSocketState.RUNNING, webSocketState.DOWN].includes(oxygenAppContext.apiHandle.status);
        },
        [oxygenAppContext.apiHandle.status]
    );

    return (
        <XpTooltip trKey={toolTipMap[oxygenAppContext.apiHandle.status]}>
            <img
                style={{cursor: cursorMap[oxygenAppContext.apiHandle.status]}}
                src={iconMap[oxygenAppContext.apiHandle.status]} alt={"Refresh"} className="refreshIcon"
                onClick={reloadAllowed ? () => oxygenAppContext.apiHandle.reload(appReady ? router.location.pathname : appStartPath) : undefined}
            />
        </XpTooltip>
    )
}
ApiStatusIcon.propTypes = {
};
export default ApiStatusIcon



