import React from "react";
import {useDispatch} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import { modalStandardParams} from "gui-common/modals/modalConstants";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {MODAL_TYPE_CANCEL, MODAL_TYPE_CONFIRM} from "gui-common/modals/modalResultTypes";
import XpForm from "gui-common/xpForm/core/XpForm";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import {xpFormRemoveForm, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import XpFormTextInput from "gui-common/xpForm/core/XpFormTextInput";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {genericFetch} from "gui-common/api/apiCommon";
import {globalApiHandle} from "gui-common/api/apiConstants";

export function SandboxAppIdActivationModal({popModalWindow, legalEntityUnit}) {
    const dispatch      = useDispatch();
    const formDataAppId = useXpFormField('sandboxAppIdActivationForm.appId');


    function cancelAction() {
        popModalWindow({ status: MODAL_TYPE_CANCEL });
        dispatch(xpFormRemoveForm('sandboxAppIdActivationForm'));
    }

    function handleSubmit(formData) {
        popModalWindow({ status: MODAL_TYPE_CONFIRM });
        dispatch(xpFormRemoveForm('sandboxAppIdActivationForm'));
        dispatch(activateAppIdToApi(formData.appId))
        // Call new endpoint
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Daily trading limit override dialog"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalStandardParams}
                >

                    <div xp-test-id="SandboxAppIdActivationModal">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'sandboxActivateAppIdModal.header'}/></h3>
                        <hr/>
                        <p className="valueTextDark"><XpTranslated trKey={'sandboxActivateAppIdModal.message'} /></p>
                        <hr/>
                        <div style={{paddingBottom: '30px'}}>
                            <XpForm
                                formModel='sandboxAppIdActivationForm'
                                formTemplate={"legalEntityUnitForm"}
                                onSubmit={(data) => handleSubmit(data)}
                                initialUseState={XP_FORM_EDIT}
                                initialFormData={{appId: ""}}
                            >
                                <XpFormTextInput
                                    field         = "appId"
                                    checkNoSpaces = {true}
                                    isRequired    = {true}
                                    autoFocus
                                    errors={{
                                        exactLength: (val, fields) => {
                                            if (!val ||!val.length) return false;
                                            if (val.length === 32) return false;
                                            if (val.length === 34) return false;
                                            return true;
                                        }
                                    }}
                                />
                            </XpForm>
                        </div>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        = "sandboxActivateAppIdModal.confirm"
                                onClickCallback = {() => dispatch(xpFormSubmitForm("sandboxAppIdActivationForm"))}
                                disabled={!((formDataAppId?.length === 32) || (formDataAppId?.length === 34))}
                            />
                            <WaitingXpoolButton
                                xpTestId        = {"xpModalButtonCancel"}
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}

function activateAppIdToApi(appId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            if (!appId) {
                console.error("No appId to function activateAppIdToApi");
                return;
            }

            let logText = "Put activate appId : " + appId;
            let requestUrl = globalApiHandle.baseUrls.sandbox + '/activate?appId=' +appId + '&';

            dispatch(genericFetch(
                requestUrl,
                logText,
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                () => (resp) => {return (dispatch, getState) => {}},
                'PUT',
                undefined,
            )).then(result => {resolve(result)}).catch(err => {})
        })
    }
}
