import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {formatDateFieldRelativeNow, isMomentObject} from "gui-common/xpGrid/xpGridFunctions";
import {createMomentObjectFromUtcTimestamp, formatDateField} from "gui-common/functions/functions";
import {selectActiveLanguage} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import moment from "moment/moment";


export function XpDateTime ({isoString, epoc, format}) {

    const currentLanguage = useSelector(selectActiveLanguage);

    const momentObject  = useMemo( () => {
        if (isoString) {
            return isMomentObject(isoString) ? isoString : createMomentObjectFromUtcTimestamp(isoString)
        }
        if (epoc) {
            return moment(epoc);
        }
        return undefined;
    }, [isoString, epoc]);


    const displayString = useMemo(
        () =>  {
            if (!momentObject) {
                return undefined;
            }
            return (format === 'relative') ? formatDateFieldRelativeNow(momentObject, currentLanguage) : formatDateField(momentObject, currentLanguage, format)
        },
        [momentObject, currentLanguage, format]
    );

    if (displayString === undefined) {
        return null;
    }

    return (
        <span>
            {displayString}
        </span>
    )
}



