import moment from "moment/moment";
import {xpTranslatedInit, xpTranslatedLoadTranslations} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";
import {useAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {useDispatch} from "react-redux";
import {setActiveLanguageThunk} from "gui-common/appLocale/appLocaleFunctions";
import {useEffect} from "react";

function addIfUsed(target, isUsed, addArray) {
    if (!isUsed) {
        return;
    }
    target.push(...addArray);
}

export function useAppLocale(appConfig) {

/* Propably not used. Dont know...
    window.onpopstate = function (event) {
        console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
    };
*/
    const dispatch = useDispatch();
    const languages = useAppEnvProperty('availableLanguages');

    useEffect(
        () => {

            moment.updateLocale('en', {
                calendar : {
                    lastDay : '[Yesterday at] HH:mm:ss',
                    sameDay : 'HH:mm:ss',
                    nextDay : '[Tomorrow at] HH:mm:ss',
                    lastWeek : 'YYYY-MM-DD, HH:mm:ss',
                    nextWeek : 'YYYY-MM-DD, HH:mm:ss',
                    sameElse : 'YYYY-MM-DD, HH:mm:ss'
                },
                week: {
                    dow: 1, // First day of week is Monday
                }
            });
            moment.updateLocale('sv', {
                calendar : {
                    lastDay : '[Igår kl] HH:mm:ss',
                    sameDay : 'HH:mm:ss',
                    nextDay : '[Imorgon kl] HH:mm:ss',
                    lastWeek : 'YYYY-MM-DD, HH:mm:ss',
                    nextWeek : 'YYYY-MM-DD, HH:mm:ss',
                    sameElse : 'YYYY-MM-DD, HH:mm:ss'
                },
                week: {
                    dow: 1, // First day of week is Monday
                }
            });

            dispatch(xpTranslatedInit(languages, { defaultLanguage: 'en' }))

            if (!appConfig.lang.skipCommon) {
                const commonFiles = [
                    require('gui-common/app/appLang.json'),
                    require('gui-common/appLocale/xpTranslated/xpTranslatedLang.json'),
                    require('gui-common/api/apiLang.json'),
                    require('gui-common/processStatus/processStatusLang.json'),
                    require('gui-common/userSettings/userSettingsLang.json'),
                    require('gui-common/userSettings/sharedSettings/savedUserStatesLang.json'),
                    require('gui-common/components/fileReaderLang'),
                ];

                addIfUsed(commonFiles, appConfig.useFx                      , [require('gui-common/fxPrice/fxPriceLang.json'), require('gui-common/fixLog/fixLogLang.json')]);
                addIfUsed(commonFiles, appConfig.erp                        , [require('gui-common/erpIntegration/erpIntegrationLang.json')]);
                addIfUsed(commonFiles, appConfig.dashboard                  , [require('gui-common/dashboard/dashboardLang.json')]);
                addIfUsed(commonFiles, appConfig.userRights                 , [require('gui-common/userRights/adminUserRightsLang')]);
                addIfUsed(commonFiles, appConfig.audit                      , [require('gui-common/audit/auditLang.json')]);
                addIfUsed(commonFiles, appConfig.userMessages               , [require('gui-common/userMessages/userMessagesLang.json'), require('gui-common/userSettings/topics/userTopicAdminLang.json')]);
                addIfUsed(commonFiles, appConfig.useNoRunDates              , [require('gui-common/noRunDate/noRunDateLang')]);
                addIfUsed(commonFiles, appConfig.report                     , [require('gui-common/report/reportLang.json')]);
                addIfUsed(commonFiles, appConfig.wizard                     , [require('gui-common/wizard/wizardLang')]);
                addIfUsed(commonFiles, appConfig.useBankHolidays            , [require('gui-common/bankHolidays/bankHolidaysLang')]);
                addIfUsed(commonFiles, appConfig.runConfiguration           , [require('gui-common/runConfiguration/runConfigurationLang.json')]);
                addIfUsed(commonFiles, appConfig.simulators                 , [require('gui-common/simulators/fxMarketSimulator/marketSimulatorLang.json')]);
                addIfUsed(commonFiles, appConfig.useCurrencyPairSettings    , [require('gui-common/currencyPairSettings/currencyPairConfigLang')]);
                addIfUsed(commonFiles, appConfig.useFxRejectConfig          , [require('gui-common/fxRejectConfig/fxRejectConfigLang')]);
                addIfUsed(commonFiles, appConfig.useFxTradeReport           , [require('gui-common/fxTradeReport/fxTradeReportLang.json')]);

                dispatch(xpTranslatedLoadTranslations(getLangObjectFromFiles(commonFiles)));
            }
            dispatch(xpTranslatedLoadTranslations(getLangObjectFromFiles(appConfig.lang.files)));
            dispatch(setActiveLanguageThunk('en'));

        },
        [],
    );
}
// eslint-disable-next-line
function getLangObjectFromFiles(files) {
    const returnObject = {};
    for (const file of files) {
        Object.assign(returnObject, file);
    }
    return returnObject;
}
