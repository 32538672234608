import PropTypes from "prop-types";
import React from "react";

function AuditNoRunDate (props) {
    return (
        <div className={'xpAdminFormEntityCard ' + (props.auditEntry.data.before ? 'adminEntityCardDisabled' : 'adminEntityCardNew')}>
            {props.auditEntry.data.before ? props.auditEntry.data.before.date : props.auditEntry.data.after.date}
        </div>
    )
}
AuditNoRunDate.propTypes = {
    auditEntry: PropTypes.object.isRequired,
};
export default AuditNoRunDate



