import {LinkContainer} from "react-router-bootstrap";
import {NavDropdown} from "react-bootstrap";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import React from "react";
import PropTypes from "prop-types";

function XpDropDownMenuLinkItem(props) {
    if (!props.displayCondition) {
        return null;
    }
    return (
        <LinkContainer to={props.targetPath} key={props.targetPath}>
            <NavDropdown.Item xp-test-id={props.testId}>
                    <XpTranslated trKey={props.trKey} spanStyle={{whiteSpace: 'nowrap'}}/>
            </NavDropdown.Item>
        </LinkContainer>
    )
}
XpDropDownMenuLinkItem.propTypes = {
    displayCondition: PropTypes.bool,
    targetPath: PropTypes.string.isRequired,
    testId: PropTypes.string,
    trKey: PropTypes.string.isRequired,
};
XpDropDownMenuLinkItem.defaultProps = {
    displayCondition: true,
};

export default XpDropDownMenuLinkItem;
