import React from 'react'
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {logoutUserFromApi} from "gui-common/api/apiFunctions";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";

function FailedDataLoad(props) {
    const oxygenAppContext = useOxygenAppContext();
    const dispatch = useDispatch();
    return (
        <div className="welcomeScreen ">
            <h2 className="centeredText"><XpTranslated trKey={'api.failedDataLoad.header'}/></h2>
            <p className="centeredText"><XpTranslated trKey={'api.failedDataLoad.message'}/></p>
            <div>
                <hr></hr>
                <WaitingXpoolButton autoFocus labelKey={'api.failedDataLoad.retryButton'} onClickCallback={() => oxygenAppContext.apiHandle.reload()} />
                <WaitingXpoolButton autoFocus labelKey={'topMenu.logout'} onClickCallback={() => dispatch(logoutUserFromApi())} />
            </div>
        </div>
    )
}
export default withRouter(FailedDataLoad)
