import {reportSetStyling} from "gui-common/report/reportReducer";

export function setStyling(styling, isSandbox) {
    return (dispatch, getState) => {
        let head  = document.head;
        let style = document.createElement('style');
        let stylingString = ':root {'
        for (let item of styling.styleOverride) stylingString += item;
        stylingString += "}";
        style.appendChild(document.createTextNode(stylingString));

        if (styling.fonts) {
            for (let font of styling.fonts) {
                style.appendChild(document.createTextNode(font));
            }
        }

        if (styling.classes) {
            for (let ccsClass of styling.classes) {
                style.appendChild(document.createTextNode(ccsClass));
            }
        }
        if (isSandbox) {
            style.appendChild(document.createTextNode('.oxyGenApp {height: 100%; border: 3px solid greenyellow}'));
        }

        head.appendChild(style);

        dispatch(reportSetStyling(styling.reportStyling));
    }
}
