import {useMemo} from "react";
import {useSelector} from "react-redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {preparePriceTolerancesGridColumns} from "features/priceTolerances/priceTolerancesFunctions";
import XpAdminFormEntityGridTemplate from "gui-common/xpAdminForm/XpAdminFormEntityGridTemplate";
import {getPriceTolerancesListDataSelector} from "./priceToleranceSelectors";

export default function PriceTolerancesList(props) {

    const translate = useSelector(selectTranslateFunction);

    const columnDefs = useMemo(
        () => {
            const hiddenState = props.hiddenState ? props.hiddenState : {
                id                                  : true,
                name                                : false,
                description                         : false,
                numberOfAgreements                  : false,
                startBusinessHours                  : false,
                endBusinessHours                    : false,
                priceToleranceInsideBusinessHours   : false,
                priceToleranceOutsideBusinessHours  : false
            }
            return preparePriceTolerancesGridColumns(translate, hiddenState);
        },
        [translate, props.hiddenState]
    );

    return (
        <XpAdminFormEntityGridTemplate
            columnDefs={columnDefs}
            trKeyBase='priceTolerancesList'
            ormModel='PriceTolerances'
            {...props}
            getRowDataSelector={getPriceTolerancesListDataSelector}
        />
    )
}
