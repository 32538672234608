import {useSelectorInstance} from "gui-common/functions/hooks";
import {useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {replaceParametersInText} from "gui-common/appLocale/xpTranslated/xpTranslatedFunctions";


const xpTrState = state => state.xpTranslatedState;
const trKey = (state, props) => props;
const getTrObjectSelector = () => createSelector(
    [xpTrState, trKey],
    (xpTrState, trKey) => {
        if (!xpTrState || !trKey) {
            return undefined;
        }
        return xpTrState?.trKeys[trKey];
    }
);

export function useXpTranslationObject(trKey) {
    const trObject = useSelectorInstance(getTrObjectSelector, trKey)
    return useMemo(() => {
        return trObject;
    }, [trObject])
}

export function useXpTranslatedDefaultLanguage() {
    return useSelector(createSelector(
        [xpTrState],
        (xpTrState) => {
            return xpTrState.defaultLanguage;
        }
    ));
}

export const selectActiveLanguage = createSelector(
    [xpTrState],
    (xpTrState) => {
        if (!xpTrState) return null;
        return xpTrState.activeLanguage;
    }
);

export function useXpTranslatedActiveLanguage() {
    return useSelector(selectActiveLanguage);
}

export function useXpTranslatedEditTextMode() {
    return useSelector(createSelector(
        [xpTrState],
        (xpTrState) => {
            return xpTrState.editTextMode;
        }
    ));
}

export function useXpTranslatedLanguages() {
    return useSelector(createSelector(
        [xpTrState],
        (xpTrState) => {
            return xpTrState.languages;
        }
    ));
}

export function useXpTrKeys() {
    return useSelector(createSelector(
        [xpTrState],
        (xpTrState) => {
            return xpTrState.trKeys;
        }
    ));
}

function getTranslateFn(xpTrState) {
    if (!xpTrState?.activeLanguage) return undefined;
    return (trKey, trParams) => {
        const trObject = xpTrState.trKeys[trKey];
        if (!trObject) {
            return "No text defined for " + trKey;
        }
        let codeText = xpTrState.trKeys[trKey][xpTrState.activeLanguage];
        if (!codeText || !codeText[0]) {
            codeText = xpTrState.trKeys[trKey][xpTrState.defaultLanguage];
        }
        return replaceParametersInText(codeText[0].text, trParams);
    }
}

export const selectTranslateFunction = createSelector(
    [xpTrState],
    (xpTrState) => {
        return getTranslateFn(xpTrState)
    }
);
export function useXpTranslateFunction() {
    return useSelector(selectTranslateFunction);
}
export function useXpTranslateRef() {
    const translate     = useSelector(selectTranslateFunction);
    const translateRef = useRef(translate); // Needed to transport updated translate hook to callback scope.
    translateRef.current = translate;
    return translateRef;
}


/*
function getTranslation(state, trKey) {
    if (!trKey) return undefined;
    const modelArray = trKey.split('.');
    if (modelArray.length < 1) return undefined;
    let statePointer = state;
    let field;
    for (let i = 0; i < (modelArray.length); i++) {
        field = modelArray[i];
        statePointer = statePointer[field];
        if (statePointer === undefined) return undefined;
        if (statePointer === null     ) return undefined;
    }
    return statePointer
}
*/
/*
const getTrObjectSelector = () => createSelector(
    [xpTrState, trKey],
    (xpTrState, trKey) => {
        return getTranslation(xpTrState.trTree, trKey);
    }
);
export function useXpTranslation(trKey) {
    const trObject = useSelectorInstance(getTrObjectSelector, trKey)
    return useMemo(()=>{
        return trObject;
    }, [trObject])
}
*/
