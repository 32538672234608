export const fxOrderReportSide =  {
    BUY: "BUY",
    SELL: "SELL",
}
export const fxExecutionLegRoles =  {
    NEAR: "NEAR",
    FAR: "FAR",
    BUYOUT: "BUYOUT"
}
export const fxOrderReportStatus =  {
    NEW: "NEW",
    EXECUTED: "EXECUTED",
}
export const fxTradeReportEventTypes =  {
    NEW: "New",
    AMEND: "Amend",
    CANCEL: "Cancel",
    EARLY_REDEMPTION: "EarlyRedemption",
}
export const fxOrderReportProductTypes =  {
    FWD: "FWD",
    SWAP: "SWAP",
}
export const deliveryStates = {
    PENDING: 'PENDING',
    SUSPENDED: 'SUSPENDED',
    PROCESSING: 'PROCESSING',
    DELIVERED: 'DELIVERED',
    ACCEPTED: 'ACCEPTED',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
}
export const deliveryActions = {
    Deliver: 'deliver',
    Suspend: 'suspend',
    Resume: 'resume',
    Cancel: 'cancel',
    Resend: 'resend',
}
