import React from 'react';
import Tooltip from '@mui/material/Tooltip'
import PropTypes from "prop-types";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";

function XpTooltip(props){
    if (!props.title && !props.trKey) return props.children;
    if (props.bypass) return props.children;

    const toShow = props.title ? props.title : <XpTranslated trKey={props.trKey} trParams={props.trParams}/>;
    return (
        <Tooltip
            leaveDelay={200}
            enterDelay={1000}
            enterNextDelay={1000}
            title={toShow}
            placement={props.placement}
        >
            {props.children}
        </Tooltip>
    );
}
XpTooltip.propTypes = {
    title     : PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    trKey     : PropTypes.string,
    trParams  : PropTypes.object,
    children  : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    placement : PropTypes.string,
    bypass    : PropTypes.bool,
};

XpTooltip.defaultProps = {
};
export default XpTooltip;
