import React, {useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import ReactModal from "react-modal";
import PropTypes from 'prop-types';
import FocusLock from "react-focus-lock";
import {PDFViewer} from "@react-pdf/renderer";

import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import {getStyleSheet} from "gui-common/report/reportComponents/reportStyleSheet";
import {selectDecDenLangState} from "gui-common/numberFormat/numberFormatSelectors";
import {selectAppEnvProperty} from "gui-common/app/appEnvSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {globalReportDataSetTypes} from "gui-common/report/reportConstants";


function ModalViewReport ({resultTypes: { MODAL_TYPE_CLOSE }, popModalWindow, reportType, dataSetType, reportData, period, printed, reportCurrency, onRenderCallback})  {

    const defaultReportConfig = useMemo(
        () => {
            if (!globalReportDataSetTypes[dataSetType] || !globalReportDataSetTypes[dataSetType].reportTypes[reportType]) {
                console.log("Incorrect parameters to default settings in report modal ", reportType, dataSetType);
                return undefined;
            }
            return globalReportDataSetTypes[dataSetType].reportTypes[reportType].defaultSettings;
        },
        [reportType, dataSetType]
    );

    const [reportConfig,  setReportConfig] = useState(defaultReportConfig);
    const translate       = useSelector(selectTranslateFunction);
    const decDenLangState = useSelector(selectDecDenLangState);
    const reportEnvConfig = useSelector(state => selectAppEnvProperty(state, 'reportSetConfig'));
    const reportStyling   = useSelector(state => state.reportState.reportStyling);

    const reportPrefix = reportEnvConfig && reportEnvConfig[dataSetType] && reportEnvConfig[dataSetType].reportPrefix;

    function cancelAction() {
        popModalWindow({ status: MODAL_TYPE_CLOSE });
    }

    const ConfigComponent = globalReportDataSetTypes[dataSetType].reportTypes[reportType].configComponent;
    const ReportComponent = globalReportDataSetTypes[dataSetType].reportTypes[reportType].reportComponent;

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalReport"}};

    const styleSheet = useMemo(() => {return getStyleSheet(reportStyling);},[reportStyling]);

    const langPath = 'report.dataSetTypes.'+dataSetType+'.reportTypes.'+reportType;

    const filteredReportData = (globalReportDataSetTypes[dataSetType].statusField && globalReportDataSetTypes[dataSetType].excludeStatusFromTurnover) ?
        reportData.filter(item => globalReportDataSetTypes[dataSetType].excludeStatusFromTurnover.indexOf(item[globalReportDataSetTypes[dataSetType].statusField]) === -1)
        : reportData;

    console.log("Rendering report modal", reportData);

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Save settings dialog"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div xp-test-id="xpModalViewReport">
                        <h3 className="reportModalHeader">
                            <XpTranslated trKey='report.viewReportHeader'/>
                        </h3>
                        <div className="reportModalHead">
                            <div className="reportModalCloseButton">
                                <WaitingXpoolButton
                                    xpTestId        = {"xpModalButtonCancel"}
                                    labelKey        = 'report.viewReportModal.closeButton'
                                    onClickCallback = {() => cancelAction()}
                                    floatRight
                                />
                            </div>
                            {(ConfigComponent !== undefined) &&
                            <div className="reportModalConfig">
                                <ConfigComponent
                                    reportData   = {filteredReportData}
                                    reportConfig    = {reportConfig}
                                    setReportConfig = {setReportConfig}
                                    translate       = {translate}
                                    langPath        = {langPath}
                                />
                            </div>}
                        </div>
                        <hr/>
                        <div style={{textAlign: 'center'}}>
                            <PDFViewer
                                height={1100}
                                width={740}
                            >
                                <ReportComponent
                                    reportData      = {filteredReportData}
                                    reportConfig    = {reportConfig}
                                    reportProps     = {{
                                        translate       : translate,
                                        decDenLangState : decDenLangState,
                                        styleSheet      : styleSheet,
                                        langPath        : langPath,
                                        period          : period,
                                        printed         : printed,
                                        reportCurrency  : reportCurrency,
                                        onRenderCallback: onRenderCallback,
                                        reportPrefix    : reportPrefix,
                                    }}
                                />
                            </PDFViewer>
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}


ModalViewReport.propTypes = {
    reportType        : PropTypes.string.isRequired,
    dataSetType       : PropTypes.string.isRequired,
    reportData        : PropTypes.array.isRequired,
    resultTypes       : PropTypes.object,
    popModalWindow    : PropTypes.func.isRequired,
    onRenderCallback  : PropTypes.func,
};

export default ModalViewReport
