import {createSelector} from "reselect";
import {useSelectorInstance, useSelectorInstanceRef} from "gui-common/functions/hooks";

const grossRowData          = (state, props) => props.rowData ? undefined : props.rowDataSelector ? props.rowDataSelector(state, props.rowDataSelectorProps) : undefined;
const rowData               = (state, props) => props.rowData;
export const xpGridRowDataSelector = () => createSelector(
    [grossRowData, rowData],
    (grossRowData, rowData) => {
        if (rowData) return rowData;
        if (!grossRowData) return [];
        return grossRowData;
    }
);
const xpGrids = state => state.xpGridsState.xpGrids;
const instanceId = (state, props) => props ? props.instanceId : undefined;
export const getXpGridStateSelector = () => createSelector(
    [xpGrids, instanceId],
    (xpGrids, instanceId) => {
        if (!instanceId) return undefined;
        if (!xpGrids) return undefined;
        if (!xpGrids.length) return undefined;
        let gridState = xpGrids.find(item => item.instanceId === instanceId);
        //console.log("gridState in Selector for instanceId: " + instanceId);
        //console.log(gridState);
        return gridState;
    }
);
export const selectMyXpGridState = getXpGridStateSelector();

export function useXpGridState(instanceId) {
    return useSelectorInstance(getXpGridStateSelector, {instanceId: instanceId});
}

export function useXpGridStateRef(instanceId) {
    return useSelectorInstanceRef(getXpGridStateSelector, {instanceId: instanceId});
}
