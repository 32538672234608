import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from "prop-types";
import React, {useCallback, useMemo} from "react";
import XpFormLabeledInput, {xpFormBasePropTypes} from "gui-common/xpForm/core/XpFormLabeledInput";
import {useXpTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";

function getValueFromOption(option, props) {
    if (typeof option !== 'object') {
        return option;
    }
    const propName = props.itemTextField ? props.itemTextField : 'name';
    return option[propName];
}

function XpFreeFormSelect(props) {
    const renderList = useMemo(
        () => {
            return props.selectList;
        },
        [props.selectList]
    );

    const currentValue = useMemo(
        () => {
            return props.value;
        },
        [props.value]
    );

    return (
        <Autocomplete
            value={currentValue}
            onChange={(event, item, reason) => {
                console.log("onChange: ", item, reason);
                if (reason === 'clear') {
                    props.onChange("", {selectedItem: undefined});
                    return;
                }
                if (item === undefined ||item === null) {
                    props.onChange("", {selectedItem: undefined})
                    return;
                }
                props.onChange(getValueFromOption(item, props), {selectedItem: item})
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        {getValueFromOption(option, props)}
                    </li>
                )
            }}
            getOptionLabel={(option) => {
                return getValueFromOption(option, props);
            }}
            onClose={(event, reason) => {
                if (reason === 'escape') {
                    props.onChange(props.value);
                }
            }}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}

            freeSolo

            autoSelect
            // disableClearable
            clearOnEscape
            renderInput={(params) => {
                return (
                    <TextField {...params} autoFocus={props.autoFocus} label={props.label} variant={'standard'} margin="normal"/>
                )
            }}
            options={renderList}
        />
    )
}


function XpFormFreeFormSelectorInput (props) {
    return (
        <XpFormLabeledInput
            {...props}

            CompToRender={XpFreeFormSelect}
            getValueFromEvent={useCallback(event => event, [])}
            isSelectField
            setTouchedOnChange

            controlProps={{selectList: props.selectList}}

            beforeChangeFunction={useCallback((before, after) => {
                let afterChangeValue = (!after) ? "" : after;
                return (String(before) === String(afterChangeValue)) ? null : before;
            }, [])}
            valueRenderer={useCallback((value, props) => {
                return <span>{value}</span>
            }, [])}
        />
    )
}

XpFormFreeFormSelectorInput.propTypes = {
    ...xpFormBasePropTypes,
    selectList: PropTypes.array.isRequired,
};
export default XpFormFreeFormSelectorInput;
