import {createTranslatedFieldData, translateDateFields, xpColCreate} from "gui-common/xpGrid/xpGridFunctions";
import {
    auditActions,
    auditModelsFromRootModel,
    auditOperationTypes, commonAuditActions,
    globalAuditConfig,
} from "gui-common/audit/auditConstants";
import {pushModalWindow} from "redux-promising-modals";
import {SHOW_AUDIT_HISTORY_DIALOG} from "gui-common/modals/modalConstants";
import {globalOrmConfig} from "gui-common/orm/ormModels";
import moment from "moment";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {commonSystemRights} from "gui-common/userRights/userRightsConstants";
import {apiTransformIncoming} from "gui-common/api/apiFunctions";
import cloneDeep from "lodash/cloneDeep";
import {useMemo} from "react";

export function formatAuditEntriesGridData(dataArray, currentLanguage, translate, rootConfig) {
    for (let item of dataArray) {
        translateDateFields(item, 'lll', currentLanguage);

        if (item.action) {
            createTranslatedFieldData(item, 'action' , translate, ('auditView.actionTypes.' + item.action + '.enrichedName'), {action: item.actionInfo?.Action});
        }
        if (item.affectedOrmModel) {
            createTranslatedFieldData(item, 'affectedOrmModel'         , translate, ('general.modelNamesLarge.' + item.affectedOrmModel));
        }

        item.id = item.id ? item.id : "";
        item.userId = item.user ? item.user.id : "";
        item.userFullName = item.user ? item.user.firstName + " " + item.user.lastName : "";
        item.apiVersion = item.apiVersion ? item.apiVersion : "";
    }
    return dataArray;
}

export function prepareAuditEntriesGridColumns(translate, hiddenState, rootModel) {
    let subArray = [];
    xpColCreate(translate, subArray, hiddenState, 'id'                , 60 , 30, 'auditEntriesList.headers.id');
    xpColCreate(translate, subArray, hiddenState, 'auditDateTime'     , 100, 50, 'auditEntriesList.headers.auditDateTime', {cellRenderer: 'xpGridDateTimeRenderer', cellRendererParams: {xpDateTimeFormat: 'relative'}});

    xpColCreate(translate, subArray, hiddenState, 'actionT'    , 80 , 40, 'auditEntriesList.headers.action', {
        cellRenderer: 'xpTranslatedRenderer',
        cellRendererParams: {
            filterTrKeys: auditOperationTypes.map(item => 'auditView.actionTypes.' + item + '.name')
        },
    });
    xpColCreate(translate, subArray, hiddenState, 'affectedOrmModelT', 80 , 40, 'auditEntriesList.headers.model', {
        cellRenderer: 'xpTranslatedRenderer',

        cellRendererParams: {
            filterTrKeys: (rootModel && auditModelsFromRootModel[rootModel]) ? auditModelsFromRootModel[rootModel].map(item => 'general.modelNamesLarge.' + item) : [],
        },
    });
    xpColCreate(translate, subArray, hiddenState, 'affectedId'     , 60 , 30, 'auditEntriesList.headers.modifiedObjectId');
    xpColCreate(translate, subArray, hiddenState, 'affectedName'   , 120, 50, 'auditEntriesList.headers.modifiedObjectName');
    xpColCreate(translate, subArray, hiddenState, 'processName'    , 60 , 30, 'auditEntriesList.headers.processName');
    xpColCreate(translate, subArray, hiddenState, 'processInstance', 120, 50, 'auditEntriesList.headers.processInstance')   ;

    xpColCreate(translate, subArray, hiddenState, 'userId'            , 60 , 30, 'auditEntriesList.headers.userId');
    xpColCreate(translate, subArray, hiddenState, 'userFullName'      , 160, 50, 'auditEntriesList.headers.userFullName');
    xpColCreate(translate, subArray, hiddenState, 'apiVersion'        , 120, 50, 'auditEntriesList.headers.apiVersion');
    return subArray;
}

export function createOpenObjectAuditMenuItem(auditConfig, ormModel, item, dispatch, translate, user) {

    if (!auditConfig.activateObjectAuditLog || !ormModel || !globalAuditConfig.models[ormModel] || !item || !userHasSystemRight(user, commonSystemRights.Audit.Read)) {
        return;
    }

    return {
        name: translate('showAuditInfo.gridMenu'),
        action: function () {
            this.context.dispatch(pushModalWindow(SHOW_AUDIT_HISTORY_DIALOG,{auditedObject : item, ormModel: ormModel}))
        },
        context: {item: item, dispatch: dispatch},
        disabled: false,
    }
}

export function entityIsDisabled(entity) {
    if (!entity) return false;
    return !entityIsActive(entity);
}
export function entityIsActive(entity) {
    if (!entity) return false;
    return !entity.disabledByUser;
}
export function getDisableByUser(entity) {
    if (entityIsActive(entity)) return undefined;
    return entity.disabledByUser;
}
export function getLastTouchUser(entity) {
    if (entity.disabledByUser) return entity.disabledByUser;
    if (entity.changedByUser) return entity.changedByUser;
    return entity.createdByUser;
}
export function getLastChangedAuditInfo(entity) {
    return {
        changedDateTime : entity?.changedDateTime,
        changedByUser   : entity?.changedByUser,
    }
}

export function getLastChange(ormModel, modelObject) {

    let lastChangeObject = getLastChangedAuditInfo(modelObject.ref);

    if (!globalOrmConfig.nestedEntities[ormModel] || !globalOrmConfig.nestedEntities[ormModel].length) {
        return lastChangeObject;
    }
    for (const nestedConfig of globalOrmConfig.nestedEntities[ormModel]) {
        if (!nestedConfig.useSubEntityProp) { // no prop for this, i.e. never managed separately, i.e. integrated part of the object.
            continue;
        }
        if (modelObject.ref[nestedConfig.useSubEntityProp] === true) { // Sub modelObject managed separately. Should not be included in this filter request
            continue;
        }
        if (!modelObject[nestedConfig.property]) {
            continue;
        }
        if (!globalAuditConfig.models[nestedConfig.model]) {
            continue;
        }
        const nestedObjects = modelObject[nestedConfig.property].toModelArray();
        if (nestedObjects?.length) {
            const nestedLastChanged = getLastChange(nestedConfig.model, nestedObjects[0]);
            // If current lastChangeObject is empty, use the one from the child entity.
            if (!lastChangeObject.changedDateTime || moment(nestedLastChanged.changedDateTime).isAfter(moment(lastChangeObject.changedDateTime))) {
                lastChangeObject = nestedLastChanged;
            }
        }
    }
    return lastChangeObject;
}

export function getHistoricAuditConfig(data, commonHistoricAuditData) {
    const retObject = {}
    if (commonHistoricAuditData) {
        data.forEach(item => item.apiVersions.forEach(apiVersion => retObject[apiVersion] = {
            excludeFields    : [...commonHistoricAuditData?.excludeField[apiVersion]    , ...item.excludeFields],
            skipParentLevels : [...commonHistoricAuditData?.skipParentLevels[apiVersion], ...item.skipParentLevels]
        }))
    }
    else {
        data.forEach(item => item.apiVersions.forEach(apiVersion => retObject[apiVersion] = {
            excludeFields    : item.excludeFields,
            skipParentLevels : item.skipParentLevels,
        }))
    }
    return retObject;
}

export function auditEntryTransformer2(item) {

    // const primaryAffected = item.affected?.find(item => item.primary === true);
    const affectedPrototype = item.primaryAffected?.affectedPrototype ? item.primaryAffected.affectedPrototype : item.actionInfo?.Prototype;

    let primaryAffected;
    let prototypeConfig
    if (affectedPrototype != null) {
        primaryAffected = item.primaryAffected;
        prototypeConfig = globalAuditConfig.prototypeToModelMap[affectedPrototype];
    }
/*
    else if (item.action === commonAuditActions.AssignDataRights) {
        primaryAffected = {
            name: item.actionInfo?.UserName + " (" + item.actionInfo?.UserSoc + ")"
        }
        prototypeConfig = { model: 'User'};
    }
*/

    const processPrototypeSplitArray = item.actor?.system?.processPrototype?.split('.');

    let parsedData = undefined;
    if (item.data) {
        parsedData = {};
        for (const key in item.data) {
            parsedData[key] = JSON.parse(item.data[key]);
        }
        item.data = parsedData;
        console.log("Data: ", parsedData);
    }

    return {
        ...item,
        user: item.actor?.user ? apiTransformIncoming(item.actor.user, 'User') : null,
        affected: primaryAffected,
        affectedOrmModel: prototypeConfig?.model,
        affectedId: primaryAffected?.affectedId,
        affectedName: primaryAffected?.name,
        affectedDescription: primaryAffected?.description,
        processName: processPrototypeSplitArray ? processPrototypeSplitArray[processPrototypeSplitArray.length - 1] : null,
        processInstance: item.actor?.system?.processInstanceId,
        data: parsedData,
        rawAuditData: cloneDeep(parsedData),
    };
}

export function useAuditModelConfig(auditEntry) {
    return useMemo(
        () => {
            const modelConfig = globalAuditConfig.models[auditEntry.affectedOrmModel];
            if (!modelConfig) {
                console.warn("No audit modelConfig config found for prototype ", auditEntry.affectedOrmModel, auditEntry);
                return undefined;
            }
            return modelConfig;
        },
        [auditEntry]
    );
}

function addToIdMap(map, context, prop) {
    if (context && context[prop] && context[prop].id) {
        map[context[prop].id] = context[prop];
    }
}

export function getItemsFromContextProperty(auditContext, parentProperty) {
    if (!auditContext) {
        return [];
    }
    // Using map to get one instance for each id when same in before and after
    const idMap = {};
    addToIdMap(idMap, auditContext.before, parentProperty);
    addToIdMap(idMap, auditContext.after, parentProperty);

    // Send as array with 0, 1, or 2 item with unique IDs
    return Object.values(idMap);
}
