import React, {Component} from 'react'
import ProcessTypeStatusLight from "gui-common/processStatus/ProcessTypeStatusLight"
import connect from "react-redux/es/connect/connect";
import sizeMe from 'react-sizeme'
import PropTypes from "prop-types";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getProcessName} from "gui-common/processStatus/processStatusFunctions";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {XpDateTime} from "gui-common/components/XpDateTime";
import {StatusPackageRenderer} from "gui-common/processStatus/StatusPackageRenderer";


class ProcessStatusPanel extends Component {

    render() {

        if (!this.props.processPrototype || !this.props.processStatusMap) return null;

        const sizeBase = this.props.size.width * 0.037;
        const headFontSize = Math.max(13, sizeBase * 1.2) + 'px';

        return (
            <div className="integrationStatusItem">
                <ProcessTypeStatusLight
                    active={true}
                    processPrototype={this.props.processPrototype}
                    processStatusMap={this.props.processStatusMap}
                    isMenuLight={false}
                />
                <div style={{width: '80%'}}>
                    <div className="itemText" style={{fontSize: headFontSize}}>
                        {getProcessName(this.props.processPrototype)}
                    </div>
                    <XpAccordion
                        defaultShowState={false}
                        header={<XpTranslated trKey={'processStatus.showDetails'}/>}
                        headerClassName="textGenerationsHeader"
                        instanceId={'processStatus-' + this.props.instanceId + this.props.processPrototype}
                    >
                        {Object.keys(this.props.processStatusMap).length ? Object.keys(this.props.processStatusMap).map((key, index) => {
                            const thisProcessState = this.props.processStatusMap[key];
                            return (
                                <label className='valueTextSmall' key={thisProcessState.processInstanceId}>
                                    {(index > 0) && <hr/>}
                                    <XpTranslated trKey='processStatus.processInstance' trParams={{instanceId: thisProcessState.processInstanceId}}/>
                                    <XpTranslated trKey='processStatus.version' trParams={{version: thisProcessState.version}}/>
                                    <XpTranslated trKey='processStatus.commit' trParams={{commit: thisProcessState.revision}}/>
                                    <XpTranslated trKey='processStatus.buildTime'/><XpDateTime epoc={thisProcessState.epocTimestamp} format='relative'/>
                                    {thisProcessState.active &&
                                        <StatusPackageRenderer statusPackage={thisProcessState.processStatus}/>}
                                </label>
                            )
                        }) : <h5><XpTranslated trKey='processStatus.noProcessStarted'/></h5>
                        }
                    </XpAccordion>
                </div>
            </div>
        )
    }
}

const makeMapStateToProps = () => {
    const mapStateToProps = (state, props) => {
        return {};
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const ConnectedProcessStatusPanel = connect(makeMapStateToProps, mapDispatchToProps)(sizeMe()(ProcessStatusPanel));

ConnectedProcessStatusPanel.propTypes = {
    processStatusMap: PropTypes.object.isRequired,
    processPrototype: PropTypes.string.isRequired,
    instanceId: PropTypes.string.isRequired,
};

ConnectedProcessStatusPanel.defaultProps = {};

export default ConnectedProcessStatusPanel;



