import {getFormatter, speedFormatAmount} from "gui-common/numberFormat/numberFormatFunctions";
import {Document, Page} from "@react-pdf/renderer";
import {
    XpReportContent, XpReportPageFooter,
    XpReportPageHeader,
    XpReportPageNumber
} from "gui-common/report/reportComponents/reportComponents";
import PropTypes from "prop-types";
import React from "react";

export function AggregateOverDimensionsReport ({reportData, reportProps, dimensions, reportHeader, wrapFirstLevel, hideFirstHeaderName}) {

    if (!reportData) {
        return (
            <Document
                title={reportProps.translate('report.reportDataInvalid')}
                author="Nordic Financial Components AB"
            >
                <Page
                    style={reportProps.styleSheet.page}
                >
                    <XpReportPageHeader
                        styleSheet = {reportProps.styleSheet}
                        header     = {reportProps.translate('report.reportDataInvalid')}
                    />
                </Page>
            </Document>
        )
    }

    const reportHeaderT = reportHeader ? reportHeader : reportProps.translate(reportProps.langPath+'.name');
    const formatter = getFormatter(reportProps.decDenLangState);
    const formatA = (amount) => {return speedFormatAmount(amount, reportProps.decDenLangState, formatter)};

    console.log("Rendering report", reportData);
    return (
        <Document
            title={reportHeaderT}
            author="Nordic Financial Components AB"
            onRender = {reportProps.onRenderCallback}
        >
            <Page
                size="A4"
                orientation="portrait"
                wrap
                style={reportProps.styleSheet.page}
            >
                <XpReportPageHeader
                    styleSheet = {reportProps.styleSheet}
                    header     = {reportHeaderT}
                    period     = {reportProps.period}
                    printed    = {reportProps.printed}
                />
                <XpReportContent
                    styleSheet      = {reportProps.styleSheet}
                    reportData      = {reportData}
                    formatter       = {formatA}
                    dimensions      = {dimensions}
                    langPath        = {reportProps.langPath}
                    langParams      = {{reportCurrency: reportProps.reportCurrency}}
                    translate       = {reportProps.translate}
                    hideFirstHeaderName = {hideFirstHeaderName}
                    wrapFirstLevel  = {wrapFirstLevel}
                />
                <XpReportPageNumber
                    styleSheet = {reportProps.styleSheet}
                    translate  = {reportProps.translate}
                />
                <XpReportPageFooter
                    styleSheet = {reportProps.styleSheet}
                    showText   = {reportProps.reportPrefix + reportHeaderT}
                />

            </Page>
        </Document>
    )
}
AggregateOverDimensionsReport.propTypes = {
    reportData      : PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    reportProps     : PropTypes.object.isRequired,
    dimensions      : PropTypes.array.isRequired,
};
