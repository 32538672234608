import React, {useEffect, useMemo} from 'react'
import PropTypes from 'prop-types';
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {useDispatch} from "react-redux";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {useRefixServiceIsActive} from "refix-gui-common/appConfig/refixSelectors";
import {refixServices} from "refix-gui-common/appConfig/refixConstants";

export function PriceFeedOnlySwitch (props) {
    const formContext = useXpFormContext();
    const priceFeedOnlyFormValue = useXpFormField('priceFeedOnly')
    const dispatch = useDispatch()
    const pfActive = useRefixServiceIsActive(refixServices.PriceFeed);

    const disableSwitch = useMemo(
        () => {
            if (!formContext?.currentData?.agreements?.length) return false;
            return formContext.currentData.agreements.some(item => item.type !== 'PriceFeedAgreement');
        },
        [formContext?.currentData?.agreements]
    );
    useEffect(
        () => {
            if (priceFeedOnlyFormValue === true) {
                dispatch(xpFormChangeField(formContext.formModel + '.enableListrates', false))
                dispatch(xpFormChangeField(formContext.formModel + '.enableMarketOrder', false))
                dispatch(xpFormChangeField(formContext.formModel + '.enableMarketOrderFileReader', false))
                dispatch(xpFormChangeField(formContext.formModel + '.enableIndicativeRates', false))
            }
        },
        [priceFeedOnlyFormValue],
    );

    if (!pfActive) {
        return null;
    }

    return (
        <div className={props.className}>
            <XpFormSwitchInput
                field         = "priceFeedOnly"
                disabled={disableSwitch}
            />
        </div>
    );
}
PriceFeedOnlySwitch.propTypes = {
    className:        PropTypes.string,
};
