import {NavDropdown} from "react-bootstrap";
import {pushModalWindow} from "redux-promising-modals";
import {MODAL_TYPE_CANCEL} from "gui-common/modals/modalResultTypes";
import React from "react";
import {getOrmObjectWithId} from "gui-common/orm/ormSelectors";
import {GET_TEST_PARAMETERS_DIALOG} from "gui-common/modals/modalConstants";

export function getOrmDevMenuItems(dispatch) {
    let returnArray = [];

    returnArray.push(<NavDropdown.Item key = 'clearModel'    onClick={() => {
        dispatch(pushModalWindow(GET_TEST_PARAMETERS_DIALOG,
            {
                headerText: 'Clear request state for model',
                messageText: 'Enter entity type',
                label1: "ORM entity model",
            }
        ))
            .then((result) => {
                if (result.status === MODAL_TYPE_CANCEL) return;
                dispatch({type: 'ENTITY_STATE_REQUEST_CLEAR_MODEL', payload: {model: result.p1}});
            });
    }}>Clear request state</NavDropdown.Item>);

    returnArray.push(<NavDropdown.Item key = 'updateOrmEntityStatus'    onClick={() => {
        dispatch(pushModalWindow(GET_TEST_PARAMETERS_DIALOG,
            {
                headerText: 'Update ORM entity status',
                messageText: 'Enter entity type, id and status below.',
                label1: "ORM entity model",
                label2: "Entity ID",
                label3: "New status"
            }
        ))
            .then((result) => {
                if (result.status === MODAL_TYPE_CANCEL) return;
                dispatch({type: 'ORM_ENTITY_UPDATE', payload: {itemType: result.p1, item:{id:Number(result.p2), status:result.p3}}})
            });
    }}>Set ORM item status</NavDropdown.Item>);

    returnArray.push(<NavDropdown.Item key = 'updateOrmEntityProperty'    onClick={() => {
        dispatch(pushModalWindow(GET_TEST_PARAMETERS_DIALOG,
            {
                headerText: 'Update ORM entity status',
                messageText: 'Enter entity type, id and status below.',
                label1: "ORM entity model",
                label2: "Entity ID",
                label3: "property to set",
                label4: "Numeric value",
                label5: "String value"
            }
        ))
            .then((result) => {
                if (result.status === MODAL_TYPE_CANCEL) return;
                let item = {};
                item.id = Number(result.p2);
                item[result.p3] = Number(result.p4);
                item[result.p3] = result.p5;
                dispatch({type: 'ORM_ENTITY_UPDATE', payload: {itemType: result.p1, item: item}})
            });
    }}>Set ORM item property</NavDropdown.Item>);


    return returnArray;
}

export function getOrAddCashedObject(id, model, cash, session) {
    if (!id) return undefined;
    if (cash[id]) return cash[id];
    const newCashedObject = getOrmObjectWithId(session, model, id);
    if (!newCashedObject) return undefined;
    cash[id] = newCashedObject.ref;
    return newCashedObject.ref;
}
export function getOrAddCashedModelObject(id, model, cash, session) {
    if (!id) return undefined;
    if (cash[id]) return cash[id];
    const newCashedObject = getOrmObjectWithId(session, model, id);
    if (!newCashedObject) return undefined;
    cash[id] = newCashedObject;
    return newCashedObject;
}


export function getOrmModelObjectById(session, model, id) {
    if (!model || !session || !id) return undefined;
    const ModelClass = session[model];
    if (!session[model].idExists(id)) return;
    return ModelClass.withId(id);
}
export function getOrmRefObjectById(session, model, id) {
    const modelObject = getOrmModelObjectById(session, model, id)
    return modelObject ? modelObject.ref : undefined;
}
export function findAndBuildEnrichedEntity(session, ormModel, entityId, buildFunction, getDeep) {
    if (!entityId) return undefined;

    const modelObject = getOrmModelObjectById(session, ormModel, entityId);
    if (!modelObject) return undefined;

    return buildFunction(modelObject, getDeep);
}

