import React, {Component} from 'react';
import {connect} from 'react-redux';
import {popModalWindow} from 'redux-promising-modals';
import * as modalResultTypes from 'gui-common/modals/modalResultTypes';
import {selectModals} from "gui-common/modals/modalsSelectors"
import 'gui-common/modals/modals.css'
import {globalModalsMap} from "gui-common/modals/modalConstants";


class ModalManager extends Component {

    render () {

        // console.log(this.props.modals);

        const currentModals = this.props.modals;

        const renderedModals = currentModals.types.map((modalType, index) => {
            const Component = globalModalsMap.get(modalType) || null;
            const modalProps = currentModals.props[index];

            return Component && (
                <Component
                    {...modalProps}
                    resultTypes={modalResultTypes}
                    popModalWindow={this.props.popModalWindow}
                    key={modalType + index}
                />)
        });
        // console.log(renderedModals);
        return <span>{renderedModals}</span>
    }
}

const mapStateToProps = (state) => {
    return {
        modals:    selectModals(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        popModalWindow: (type, params)         => dispatch(popModalWindow(type, params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);

