import {createSelector} from "reselect";
import {getRequestingStateOnModelSelector} from "gui-common/requestEntityState/requestEntityStateSelectors";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {createTranslatedFieldData, translateBoolFields} from "gui-common/xpGrid/xpGridFunctions";
import {
    stateRequestedOnId,
} from "gui-common/requestEntityState/requestEntityStateFunctions";
import {createSelector as createOrmSelector, createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrAddCashedObject} from "gui-common/orm/ormFunctions";
import {getCurrencyPairString} from "gui-common/functions/functions";
import {entityIsDisabled} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";

export function buildListRateObject (listRate) {
    const nestedData = listRate.dispatchTrades ? {dispatchTrades: listRate.dispatchTrades.filter(item => item.status === 'Active').toRefArray()} : {};
    return {
        ...listRate.ref,
        ...nestedData,
    };
}

export const listRatesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector listRatesSelector running ");
        let returnArray = [];
        let cashedAgreements  = {};
        let cashedLeus    = {};
        let cashedLes     = {};
        let cashedClients = {};

        const listRates = session.ListRate.orderBy('timeIssued', 'desc').toModelArray();
        for (let listRateModel of listRates) {
            const listRateRef = listRateModel.ref;
            // These lookups consume a lot of rendering time. With 321 orders in the system, calculation of the list went from 46ms to 9 ms without the three lines below.
            const agreement       = getOrAddCashedObject(listRateRef.agreementId  , 'Agreement'  , cashedAgreements  , session);
            let leu = undefined;
            if (agreement) {
                leu = getOrAddCashedObject(agreement.legalEntityUnitId  , 'LegalEntityUnit'  , cashedLeus  , session);
            }
            if (!leu || entityIsDisabled(leu)) {
                continue;
            }
            let le     =      getOrAddCashedObject(leu.legalEntityId , 'LegalEntity', cashedLes      , session);
            let client = le ? getOrAddCashedObject(le.clientId       , 'Client'     , cashedClients  , session) : undefined;

            returnArray.push({
                ...listRateRef,
                dispatchTrades       : listRateModel.dispatchTrades?.filter(item => item.status === 'Active').toRefArray(),
                currencyPair         : agreement ? agreement.currencyPair : undefined,
                legalEntityUnitId    : agreement ? agreement.legalEntityUnitId : undefined,
                legalEntityUnitName  : leu ? leu.name : undefined,
                clientId             : client ? client.id : undefined,
                clientApiChannel     : client ? client.clientApiChannel : undefined,
                currencyPairStr      : agreement ? getCurrencyPairString(agreement.currencyPair) : "",
                gracePeriodT         : listRateRef.gracePeriod + 's',
                listRatePeriodT      : listRateRef.listRatePeriod ? listRateRef.listRatePeriod.period         : "",
                dispatchTradePeriodT : listRateRef.dispatchTradePeriod ? listRateRef.dispatchTradePeriod.period : "",
                exemptFromGlobalSuspend : leu?.exemptFromGlobalSuspend,
                tenor                : ((listRateRef.tenor === 'BROKEN') && listRateRef.settlementDate) ? listRateRef.settlementDate : listRateRef.tenor,
            });
        }
        // console.log("Selected listRatesSelector:", returnArray);
        return returnArray;
    }
);

const enrichedListRates = state => listRatesSelector(state);
export const activeListRatesSelector = createSelector(
    [enrichedListRates],
    (enrichedListRates) => {
        return enrichedListRates.filter(lr => lr.status !== 'Expired');
    }
);

const activeListRates = state => activeListRatesSelector(state);
const clientId = (state, props) => props ? props.clientId : undefined;
export const getClientHasActiveListRatesSelector = () => createSelector(
    [activeListRates, clientId],
    (activeListRates, clientId) => {
        if (!clientId) {
            return false;
        }
        if (!activeListRates?.length) {
            return false;
        }
        return activeListRates.filter(lr => lr.clientId === clientId).length !== 0;
    }
);

const listRateListRequestStateSelector = getRequestingStateOnModelSelector();
const listRateRequestState = (state, props) => listRateListRequestStateSelector(state, {model: 'ListRate'})
const translate            = (state, props) => selectTranslateFunction(state);
const filterFunction    = (state, props) => props ? props.filterFunction : undefined;
export const getListRatesListDataSelector = () => createSelector(
    [enrichedListRates, translate, listRateRequestState, filterFunction],
    (enrichedListRates, translate, listRateRequestState, filterFunction) => {
        // console.log("Selector getListRatesListDataSelector running ");
        let returnArray       = [];
        for (let listRate of enrichedListRates) {
            if (filterFunction && !filterFunction(listRate)) continue;
            translateBoolFields(listRate, translate);
            createTranslatedFieldData(listRate, 'clientApiChannel' , translate, "clientForm.clientApiChannel.values." + listRate.clientApiChannel);
            createTranslatedFieldData(listRate, 'status', translate, ("listRatesList.statusTypes." + listRate.status), undefined, stateRequestedOnId(listRateRequestState, listRate.id));
            returnArray.push({
                ...listRate
            });
        }
        // console.log("Selected getListRatesListDataSelector:", returnArray);
        return returnArray;
    }
);

export const listRateProcessStatusSelector = createOrmSelector(
    orm,
    dbStateSelector,
    (session) => {
        const processStatusList = session.NFCProcessStatus.filter(item => item.processPrototype.indexOf("ListRateApplication") !== -1).toRefArray();
        const instanceIdMap = {};
        for (const processStatus of processStatusList) {
            if (processStatus.processInstanceId === 0) {
                continue;
            }
            if (instanceIdMap[processStatus.processInstanceId]) {
                console.warn("Another ListRate process with instanceId = ", processStatus.processInstanceId);
            }
            instanceIdMap[processStatus.processInstanceId] = processStatus;
        }
        return instanceIdMap;
    }
);
