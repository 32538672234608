export const agreementTypes = [
    "ListRateAgreement",
    "MarketOrderAgreement",
    "ReferenceRateAgreement",
    "PriceFeedAgreement"
]
export const agreementStates = [
    "Created",
    "Ready",
    "Active",
    "InActive",
    "Suspended",
]
