import {
    commonDataRights,
    commonSystemRights,
    commonSystemRightsDomainMap
} from "gui-common/userRights/userRightsConstants";

export const appSystemRightsDomainMap = {
    ...commonSystemRightsDomainMap,
    PriceTolerance: 'PriceTolerance',
    PriceMarginGroup: 'PriceMarginGroup',

    ListRatesAll: 'ListRatesAll',
    ListRatesCurrency: 'ListRatesCurrency',

    TradeReport: 'TradeReport',

    UmTopicClient: 'UmTopicClient',
    UmTopicListRate: 'UmTopicListRate',
    UmTopicOrder: 'UmTopicOrder'
}
export const refixSystemRights = {
    ...commonSystemRights,
    PriceTolerance: {
        Read        : {right:'Read'         , domain: appSystemRightsDomainMap.PriceTolerance},
        Update      : {right:'Update'       , domain: appSystemRightsDomainMap.PriceTolerance},
    },
    PriceMarginGroup: {
        Read        : {right:'Read'         , domain: appSystemRightsDomainMap.PriceMarginGroup},
        Create      : {right:'Create'       , domain: appSystemRightsDomainMap.PriceMarginGroup},
        Update      : {right:'Update'       , domain: appSystemRightsDomainMap.PriceMarginGroup},
        Delete      : {right:'Delete'       , domain: appSystemRightsDomainMap.PriceMarginGroup},
    },

    ListRatesAll: {
        Suspend : {right:'SuspendListRateAgreements'  , domain: appSystemRightsDomainMap.ListRatesAll},
        Resume  : {right:'ResumeListRateAgreements'   , domain: appSystemRightsDomainMap.ListRatesAll},
        Expire  : {right:'ExpireListRates'   , domain: appSystemRightsDomainMap.ListRatesAll},
        Dispatch: {right:'DispatchListRates' , domain: appSystemRightsDomainMap.ListRatesAll},
    },
    ListRatesCurrency: {
        Suspend : {right:'SuspendListRateAgreements'  , domain: appSystemRightsDomainMap.ListRatesCurrency},
        Resume  : {right:'ResumeListRateAgreements'   , domain: appSystemRightsDomainMap.ListRatesCurrency},
        Expire  : {right:'ExpireListRates'   , domain: appSystemRightsDomainMap.ListRatesCurrency},
        Dispatch: {right:'DispatchListRates' , domain: appSystemRightsDomainMap.ListRatesCurrency},
    },

    TradeReport: {
        SendManualCancelEvent: {right: 'SendManualCancelEvent', domain: appSystemRightsDomainMap.TradeReport},
        StopResumeAllApiServices: {right: 'StopResumeTradeReportApiServices', domain: appSystemRightsDomainMap.TradeReport},
    },

    UmTopicClient: {
        Create      : {right:'Create'       , domain: appSystemRightsDomainMap.UmTopicClient},
        Read        : {right:'Read'         , domain: appSystemRightsDomainMap.UmTopicClient},
    },
    UmTopicListRate: {
        Create      : {right:'Create'       , domain: appSystemRightsDomainMap.UmTopicListRate},
        Read        : {right:'Read'         , domain: appSystemRightsDomainMap.UmTopicListRate},
    },
    UmTopicOrder: {
        Create      : {right:'Create'       , domain: appSystemRightsDomainMap.UmTopicOrder},
        Read        : {right:'Read'         , domain: appSystemRightsDomainMap.UmTopicOrder},
    },
}

export const refixDataRightsDomainMap = {
    OperatingUnit          : 'OperatingUnit',
    Client                 : 'Client',
    LegalEntity            : 'LegalEntity',
    LegalEntityUnit        : 'LegalEntityUnit',
    ListRateAgreement      : "ListRateAgreement",
    MarketOrderAgreement   : "MarketOrderAgreement",
    MarketOrderAllAgreement: "MarketOrderAllAgreement",
    ReferenceRateAgreement : "ReferenceRateAgreement",
    PriceFeedAgreement     : "PriceFeedAgreement",
    TradeReportAgreement   : "TradeReportAgreement",
}
export const refixDataRights = {
    ...commonDataRights,
    OperatingUnit: {
        CreateClient                : 'CreateClient',
        CreateLegalEntity           : 'CreateLegalEntity',
        CreateLegalEntityUnit       : 'CreateLegalEntityUnit',
    },
    Client: {
        Update                  : 'Update',
        EditBase                : 'EditBase',
        Delete                  : 'Delete',
        CreateLegalEntity       : 'CreateLegalEntity',
        CreateLegalEntityUnit   : 'CreateLegalEntityUnit',
    },
    LegalEntity: {
        Update                  : 'Update',
        EditBase                : 'EditBase',
        EditErpConfig           : 'EditErpConfig',
        Delete                  : 'Delete',
        CreateLegalEntityUnit   : 'CreateLegalEntityUnit',
    },
    LegalEntityUnit: {
        Update              : 'Update',
        EditBase            : 'EditBase',
        Delete              : 'Delete',
        LimitOverride       : 'LimitOverride',
        DispatchListRates   : 'DispatchListRates',
        ExpireListRates     : 'ExpireListRates',
        SuspendListRateAgreements       : 'SuspendListRateAgreements',
        ResumeListRateAgreements        : 'ResumeListRateAgreements',
        CreateListRateAgreement         : 'CreateListRateAgreement',
        CreateMarketOrderAgreement      : 'CreateMarketOrderAgreement',
        CreateMarketOrderAllAgreement   : 'CreateMarketOrderAllAgreement',
        CreateReferenceRateAgreement    : 'CreateReferenceRateAgreement',
        CreatePriceFeedAgreement        : 'CreatePriceFeedAgreement',
        CreateTradeReportAgreement      : 'CreateTradeReportAgreement',
    },
    ListRateAgreement: {
        Update                      : 'Update',
        Delete                      : 'Delete',
        SuspendListRateAgreements   : 'SuspendListRateAgreements',
        ResumeListRateAgreements    : 'ResumeListRateAgreements',
        DispatchListRates           : 'DispatchListRates',
        ExpireListRates             : 'ExpireListRates',
        CloseListRates              : 'CloseListRates',
        ConfirmDispatchTrades       : 'ConfirmDispatchTrades',
    },
    MarketOrderAgreement: {
        Update              : 'Update',
        Delete              : 'Delete',
    },
    MarketOrderAllAgreement: {
        Update              : 'Update',
        Delete              : 'Delete',
    },
    ReferenceRateAgreement: {
        Update              : 'Update',
        Delete              : 'Delete',
    },
    PriceFeedAgreement: {
        Update              : 'Update',
        Delete              : 'Delete',
    },
    TradeReportAgreement: {
        Update              : 'Update',
        Delete              : 'Delete',
        AddEventsToPending  : 'AddEventsToPending',
    },
}
