import React from 'react';
import {useDispatch} from "react-redux";
import XpGrid from 'gui-common/xpGrid/XpGrid3.jsx'
import {pushModalWindow} from "redux-promising-modals";
import {prepareTranslationsGridColumns, formatTranslationsGridData} from "gui-common/appLocale/appLocaleFunctions";
import {SET_TRANSLATION_DIALOG} from "gui-common/modals/modalConstants";
import {
    useXpTranslatedLanguages,
    useXpTranslateFunction,
    useXpTrKeys
} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";


export function TranslationsList (props) {

    // const languages       = useSelector(state => getLanguages(state.locale));
    // const textGenerations = useSelector(selectTextGenerations);
    // const translations    = useSelector(state => getTranslations(state.locale));
    // const translate       = useSelector(selectTranslateFunction);

    const trObjects  = useXpTrKeys();
    const translate  = useXpTranslateFunction();
    const languages  = useXpTranslatedLanguages();


    const dispatch      = useDispatch();

    function onGridCellClicked(params) {return true;}
    function onGridRowClicked(params) {return true;}
    function onGridObjectSelected(data) {return true;}
    function gridContextMenuItems(params) {
        let menuItems = [];

        let menuItem = {
            name: translate('general.buttons.edit'),
            action: function () {
                this.context.context.callingContext.dispatch(
                    pushModalWindow(SET_TRANSLATION_DIALOG, {trKey : this.context.node.data.trKey}))
                    .then((result) => {});
            },
            context: params,
        };
        menuItems.push(menuItem);

        return menuItems;
    }

    let hiddenState = {
        trKey:      false,
        generationsAvailableT: false,
        isNewKeyT:  false,
    };
    for (let lang of languages) {
        hiddenState[lang.code] = false;
    }

    const gridData = formatTranslationsGridData(translate, trObjects, languages);
    let preparedColumns = prepareTranslationsGridColumns(translate, hiddenState, languages);

    return (
        <XpGrid
            gridCellClicked={(params) => onGridCellClicked(params)}
            gridRowClicked={(params) => onGridRowClicked(params)}
            gridRowDoubleClicked={(params) => {
                dispatch(pushModalWindow(SET_TRANSLATION_DIALOG, {trKey : params.node.data.trKey}))
                    .then((result) => {});
            }}
            gridObjectSelected={(data) => onGridObjectSelected(data)}
            gridContextMenuItems={(params) => gridContextMenuItems(params)}

            gridId={props.instanceId}
            instanceId={props.instanceId}

            columnDefs={preparedColumns}
            rowData={gridData}
            callingContext={{dispatch: dispatch}}

            treeData={false}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}
            // rowGroupPanelShow="always"
            groupUseEntireRow={false}
            overlayNoRowsTemplate={translate('userMessages.noUserMessages')}

            suppressRowClickSelection={false}

            fillAvailableSpace={true}
        >
        </XpGrid>
    );
}
