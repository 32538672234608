import PropTypes from "prop-types";
import React from "react";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import XpFormStaticField from "gui-common/xpForm/core/XpFormStaticField";
import {XpDateTime} from "gui-common/components/XpDateTime";
import AuditActionDataRenderer from "gui-common/audit/actionRenderers/AuditActionDataRenderer";
import XpSplitPane from "gui-common/components/XpSplitPane";

function AuditStandardActionRenderer (props) {

    const fieldProps = {
        inLineLayout: true,
        inLineDivider: 60,
    }

    return (
        <div>
            <h3 className={'formHeaderEditNew'}>
                <XpTranslated trKey={'general.modelNamesLarge.AuditUserActionType'}/>
                {': '}
                <span className="standardTextColor">
                    <XpTranslated trKey={'auditView.actionTypes.' + props.auditEntry.action + '.enrichedName'} trParams={{action: props.auditEntry?.actionInfo?.Action}}/>
                </span>
            </h3>
            <div className='xpAdminFormEntityCard'>
                <XpSplitPane defaultWidthPercent={40} minSizePrimary={400} minSizeSecondary={400}>
                    <div>
                        <XpFormStaticField {...fieldProps} labelTrKey='auditUserActionTypes.actionDisplayHeader.requestedActionType' value={<XpTranslated trKey={'auditView.actionTypes.' + props.auditEntry.action + '.enrichedName'} trParams={{action: props.auditEntry?.actionInfo?.Action}}/>}/>
                        {props.auditEntry.user &&
                            <XpFormStaticField {...fieldProps} labelTrKey='userPreferencesForm.userName' value={props.auditEntry.user.firstName + ' ' + props.auditEntry.user.lastName + ' (' + props.auditEntry.user.id + ')'}/>
                        }
                        <XpFormStaticField {...fieldProps} labelTrKey='auditView.systemComponent'  value={props.auditEntry.processName + ' (' + props.auditEntry.processInstance + ')'}/>
                        <XpFormStaticField {...fieldProps} labelTrKey='auditUserActionTypes.actionDisplayHeader.requestedDateTime'   value={<XpDateTime isoString={props.auditEntry.auditDateTime} format='lll'/>}/>
                    </div>
                    <div>
                        <AuditActionDataRenderer auditEntry={props.auditEntry} fieldProps={fieldProps}/>
                    </div>
                </XpSplitPane>
            </div>
        </div>
    )
}
AuditStandardActionRenderer.propTypes = {
    auditEntry: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired
};
export default AuditStandardActionRenderer



