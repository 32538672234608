import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk'
import { modalsMiddleware } from 'redux-promising-modals';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import getRootReducer from "gui-common/app/rootReducer";

export const history = createBrowserHistory();

const enhancers = [];
const middleware = [
    thunkMiddleware,
    modalsMiddleware,
    routerMiddleware(history)
];

const composedEnhancers = composeWithDevTools(
    applyMiddleware(...middleware),
    ...enhancers
);

export default function configureStore(appConfig) {
    const store = createStore(
        getRootReducer(history, appConfig),
        undefined,
        composedEnhancers
    );
    return store;
}
