import React, {useMemo} from 'react'
import {useSelector} from "react-redux";
import UserMessageRow from "gui-common/userMessages/UserMessageRow"
import {selectAppReadyState} from "gui-common/api/apiSelectors";
import {useOxygenAppContext} from "gui-common/app/oxygenContext";
import {withRouter} from "react-router-dom";

function UserMessagesDisplay(props) {
    const oxygenAppContext = useOxygenAppContext();
    const appReady = useSelector(selectAppReadyState);
    const allUserMessages = useSelector(state => state.userMessagesState);

    const displayList = useMemo (
        () => {
            return allUserMessages.filter(msg => msg.displayPanel);
        },
        [allUserMessages]
    );
    if (!appReady || !oxygenAppContext.appConfig.userMessages) {
        return null;
    }

    return (
        <div className={"userMessagesContainer"}>
            {displayList.map((msg, index) => <UserMessageRow appConfig={oxygenAppContext.appConfig} message={msg} key={msg.id}/>)}
        </div>
    );
}
export default withRouter(UserMessagesDisplay);

/*
        <div className={"userMessagesContainer" + (!oxygenAppContext.appConfig.mainMenu ? " userMessagesContainerNoMenu" : "") }>
*/
