import {getCurrencyPairString} from "gui-common/functions/functions";
import {createSelector as ormCreateSelector} from "redux-orm/lib/redux";
import {selectTranslateFunction} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import {createSelector} from "reselect";
import {createTranslatedFieldData} from "gui-common/xpGrid/xpGridFunctions";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {getOrAddCashedObject, getOrmModelObjectById} from "gui-common/orm/ormFunctions";
import {entityIsDisabled} from "gui-common/audit/auditFunctions";
import {orm} from "gui-common/orm/orm";

export const listRateOrdersSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        // console.log("Selector listRateOrdersSelector running ");
        const listRateOrders = session.ListRateOrder.all().orderBy('instructedTime', 'desc').toModelArray();
        let returnArray = enrichListRateOrders(listRateOrders, session);
        // console.log("Selected listRateOrdersSelector:", returnArray);
        return returnArray;
    }
);

function enrichListRateOrders(orderModels, session) {
    let returnArray = [];
    let cashedDispatchTrades    = {};
    let cashedLegalEntityUnits  = {};

    for (let listRateOrder of orderModels) {
        const listRateOrderRef = listRateOrder.ref;
        // These lookups consume a lot of rendering time. With 321 orders in the system, calculation of the list went from 46ms to 9 ms without the two lines below.
        const dispatchTrade   = getOrAddCashedObject(listRateOrderRef.dispatchTradeId  , 'DispatchTrade'  , cashedDispatchTrades  , session);
        const leu = getOrAddCashedObject(listRateOrderRef.legalEntityUnitId, 'LegalEntityUnit', cashedLegalEntityUnits, session);

        if (!leu || entityIsDisabled(leu)) {
            continue;
        }
        const allocations = listRateOrder.listRateOrderAllocations.toRefArray();

        const dispatchTradeStatus = dispatchTrade ? dispatchTrade.status : "";
        returnArray.push({
            ...listRateOrderRef,
            numberOfAllocations : allocations?.length,
            listRateOrderAllocations: allocations,
            settlementDate      : dispatchTrade?.settlementDate,
            legalEntityUnitName : leu ? leu.name : "",
            appId               : leu ? leu.appId : "",
            dispatchTradeStatus : dispatchTradeStatus,
            currencyPairStr     : listRateOrder.currencyPair   ? getCurrencyPairString(listRateOrder.currencyPair) : "",
            amountCurrencyT     : listRateOrder.amountCurrency ? listRateOrder.amountCurrency.currency : "",
            guiStatus           : !listRateOrder.dispatchTradeStatus ? listRateOrder.status : (['Active', 'TimeToDispatch', 'SentToBook', 'Timeout'].indexOf(dispatchTradeStatus) !== -1) ? listRateOrder.status : 'Booked',
        });
    }
    return returnArray;
}

function addListDataPropsToListRateOrders(listRateOrders, translate) {
    for (let order of listRateOrders) {
        createTranslatedFieldData(order, 'side'     , translate, ("dispatchTradesList.sideTypes."   + order.side));
        createTranslatedFieldData(order, 'guiStatus', translate, ("listRateOrdersList.statusTypes." + order.guiStatus));
    }
}

const enrichedListRateOrders            = state          => listRateOrdersSelector(state);
const filterFunction                    = (state, props) => props ? props.filterFunction : undefined;
const onlyIncludeActiveDispatchTrades   = (state, props) => props ? props.onlyIncludeActiveDispatchTrades : undefined;
const translate                         = state => selectTranslateFunction(state);
export const getListRateOrdersListDataSelector = () => createSelector(
    [enrichedListRateOrders, filterFunction, onlyIncludeActiveDispatchTrades, translate],
    (enrichedListRateOrders, filterFunction, onlyIncludeActiveDispatchTrades, translate) => {
        if (!translate) return [];
        if (!enrichedListRateOrders) return [];
        // console.log("Selector getListRateOrdersListDataSelector running ");

        let filteredOrders = [];
        for (let listRateOrder of enrichedListRateOrders) {
            if (onlyIncludeActiveDispatchTrades) {
                if (!listRateOrder.dispatchTradeId) continue;
                if (listRateOrder.dispatchTradeStatus !== "Active") continue;
            }
            if (filterFunction && !filterFunction(listRateOrder)) continue;
            filteredOrders.push({...listRateOrder});
        }
        addListDataPropsToListRateOrders(filteredOrders, translate);
        // console.log("Selected getListRateOrdersListDataSelector:", filteredOrders);
        return filteredOrders;
    }
);

export const getListRateOrdersForListRateSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    translate,
    (state, props) => props ? props.listRateId : undefined,
    (session, translate, listRateId) => {
        // console.log("Selector getListRateOrdersForListRateSelector running: ", listRateId);
        if (!translate || !listRateId) {
            console.warn("No translate or listRateId provided ", listRateId);
            return [];
        }
        const listRateModel = getOrmModelObjectById(session, 'ListRate', listRateId);
        if (!listRateModel) {
            console.warn("Could not find listRateId ", listRateId);
            return [];
        }
/*
        const orderModels = listRateModel.dispatchTrades.all().toModelArray().reduce((oModels, dtModel) => {
            oModels.push(...dtModel.listRateOrders.all().toModelArray());
            return oModels;
        }, []);
*/

        // const enrichedListRateOrders = enrichListRateOrders(orderModels, session);
        const enrichedListRateOrders = enrichListRateOrders(listRateModel.listRateOrders.all().toModelArray(), session);
        addListDataPropsToListRateOrders(enrichedListRateOrders, translate)

        // console.log("Selector getListRateOrdersForListRateSelector done ", enrichedListRateOrders);
        return enrichedListRateOrders;
    }
);

export const getListRateOrdersForLeuSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    translate,
    (state, props) => props ? props.legalEntityUnitId : undefined,
    (session, translate, legalEntityUnitId) => {
        // console.log("Selector getListRateOrdersForLeuSelector running: ", listRateId);
        if (!translate || !legalEntityUnitId) {
            console.warn("No translate or legalEntityUnitId provided ", legalEntityUnitId);
            return [];
        }
        const leuModel = getOrmModelObjectById(session, 'LegalEntityUnit', legalEntityUnitId);
        if (!leuModel) {
            console.warn("Could not find legalEntityUnitId ", legalEntityUnitId);
            return [];
        }
        const enrichedListRateOrders = enrichListRateOrders(leuModel.listRateOrders.all().toModelArray(), session);
        addListDataPropsToListRateOrders(enrichedListRateOrders, translate)

        // console.log("Selector getListRateOrdersForLeuSelector done ", enrichedListRateOrders);
        return enrichedListRateOrders;
    }
);

export const getListRateOrdersForDispatchTradeSelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    translate,
    (state, props) => props ? props.dispatchTradeId : undefined,
    (session, translate, dispatchTradeId) => {
        // console.log("Selector getListRateOrdersForDispatchTradeSelector running: ", dispatchTradeId);
        if (!translate || !dispatchTradeId) {
            console.warn("No translate or dispatchTradeId provided ", dispatchTradeId);
            return [];
        }
        const dispatchTradeModel = getOrmModelObjectById(session, 'DispatchTrade', dispatchTradeId);
        if (!dispatchTradeModel) {
            console.warn("Could not find dispatchTradeId ", dispatchTradeId);
            return [];
        }
        const orderModels = dispatchTradeModel.listRateOrders.all().toModelArray();

        const enrichedListRateOrders = enrichListRateOrders(orderModels, session);
        addListDataPropsToListRateOrders(enrichedListRateOrders, translate)

        // console.log("Selector getListRateOrdersForDispatchTradeSelector done ", enrichedListRateOrders);
        return enrichedListRateOrders;
    }
);
