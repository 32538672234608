import {
    processStatusConfig,
    processStatusPackageConfig,
    processStatusTypes
} from "gui-common/processStatus/processStatusConstants";

export function getProcessTypeShortName(processPrototype) {
    const processConfig = processStatusConfig[processPrototype];
    return processConfig?.processTypeShortName ? processConfig?.processTypeShortName : "";
}
export function getProcessName(processPrototype) {
    const processConfig = processStatusConfig[processPrototype];
    if (processConfig?.getName) {
        return processConfig.getName(processPrototype);
    }
    const prototypeArray = processPrototype.split('.');
    return prototypeArray[prototypeArray.length - 1]
}
export function getProcessTypeStatus(processPrototype, processStatusMap) {
    if (!processStatusMap || !Object.keys(processStatusMap).length) {
        return processStatusTypes.DOWN;
    }
    const processArray = Object.keys(processStatusMap).map(key => processStatusMap[key]);
    if (!processArray.filter(item => item.active).length) {
        return processStatusTypes.DOWN;
    }
    if (processArray.some(process => !process.active)) {
        return processStatusTypes.WARNING;
    }
    const processConfig = processStatusConfig[processPrototype];
    if (processConfig?.getStatusType) {
        return processConfig.getStatusType(processArray);
    }
    return processStatusTypes.RUNNING;
}
export function getProcessStatus(thisProcessState) {
    if (['PING', 'ACTIVE', 'SERVICE_UPDATE'].includes(thisProcessState.heartBeatType)) {
        return 'Running';
    }
    if (thisProcessState.heartBeatType === 'CLOSING') {
        return 'Closed';
    }
    if (thisProcessState.heartBeatType === 'TIMEOUT') {
        return 'Timeout';
    }
    return 'Unknown'
}


export function getProcessStatusPackageConfig(statusPackage) {
    if (!statusPackage) return {
        undefined
    };
    return processStatusPackageConfig[statusPackage.packageType];
}
