import React from "react";
import {withRouter} from "react-router-dom";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {
    getMarginGroupAdminFormConfig,
    getMarginGroupAgreementAdminFormConfig,
    getMarginGroupLegalEntityUnitAdminFormConfig, getPriceToleranceAgreementAdminFormConfig
} from "appConfig/xpAdminFormConfig";
import XpAdminFormEntityList, {getXpAdminFormListInstanceId} from "gui-common/xpAdminForm/XpAdminFormEntityList";
import {useSelector} from "react-redux";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import MarginGroupsList from "features/marginGroup/MarginGroupsList";
import {userSelector} from "gui-common/orm/ormSelectors";
import LegalEntityUnitsList from "../legalEntityUnit/LegalEntityUnitsList";
import {useSelectorInstance} from "gui-common/functions/hooks";
import {getXpGridStateSelector, useXpGridState} from "gui-common/xpGrid/xpGridSelectors";
import {getSpecificMarginGroupSelector} from "./marginGroupSelectors";
import {useXpFormState} from "gui-common/xpForm/core/xpFormHooks";
import {getXpAdminFormModel} from "gui-common/xpAdminForm/xpAdminFormFunctions";
import {XP_FORM_EDIT, XP_FORM_NEW} from "gui-common/xpForm/core/xpFormConstants";
import {AgreementsList} from "features/agreement/AgreementsList2";
import XpAccordion from "gui-common/xpAccordion/XpAccordion";
import {refixSystemRights} from "appConfig/appUserRights";

const marginsFormInstanceId = 'marginGroupSettings'
const legalEntityUnitFormInstanceId = 'marginGroupLegalEntityUnitForm'
const agreementFormInstanceId = 'marginGroupAgreementForm'

function MarginGroupsPage () {
    const userLoggedIn = useSelector(userSelector);

    const marginGroupGridInstanceId = getXpAdminFormListInstanceId(marginsFormInstanceId, 'MarginGroup');
    const marginGroupGridState = useXpGridState(marginGroupGridInstanceId);

    const selectedMarginGroup = useSelectorInstance(getSpecificMarginGroupSelector, {selectId:marginGroupGridState?.selectedId})

    const legalEntityUnitListInstanceId = getXpAdminFormListInstanceId(legalEntityUnitFormInstanceId, 'LegalEntityUnit')
    const legalEntityUnitListState = useSelectorInstance(getXpGridStateSelector, {instanceId: legalEntityUnitListInstanceId})
    const legalEntityUnitFormState = useXpFormState(getXpAdminFormModel(legalEntityUnitListInstanceId, 'LegalEntityUnit', {id: legalEntityUnitListState?.selectedId}));
    const legalEntityUnitFormUseState = legalEntityUnitFormState ? legalEntityUnitFormState.formUseState : undefined;


    return (
        <div>
            <h2><XpTranslated trKey={'marginGroupForm.header'}/></h2>
            <hr/>
            <div>
                <XpAdminFormEntityList
                    noParentData          = {true}
                    xpAdminFormInstanceId ={marginsFormInstanceId}
                    xpAdminFormConfig     = {getMarginGroupAdminFormConfig()}
                    suppressRowClickSelection={[XP_FORM_EDIT, XP_FORM_NEW].includes(legalEntityUnitFormUseState)}
                    ormModel              = 'MarginGroup'
                    listComponent         = {MarginGroupsList}
                    formTemplate          = 'marginGroupForm'
                    userCanAddEntity      = {userHasSystemRight(userLoggedIn, refixSystemRights.PriceMarginGroup.Create)}
                    userCanEditEntity     = {userHasSystemRight(userLoggedIn, refixSystemRights.PriceMarginGroup.Update)}
                    userCanDeleteEntity   = {userHasSystemRight(userLoggedIn, refixSystemRights.PriceMarginGroup.Delete)}
                />
            </div>
            <hr/>
            <AgreementsWithMarginGroup selectedMarginGroup={selectedMarginGroup}/>
            <LeusWithMarginGroup selectedMarginGroup={selectedMarginGroup}/>
        </div>
    )
}
export default withRouter(MarginGroupsPage)

function LeusWithMarginGroup(props) {
    if (!props.selectedMarginGroup) {
        return null;
    }
    return (
        <div>
            <XpAccordion
                headerKey='marginGroupForm.legalEntityUnits'
                headerParams={{marginGroup: props.selectedMarginGroup.name, count: props.selectedMarginGroup.numberOfLeus}}
                instanceId="marginGroupsLeus"
                defaultShowState={false}
                disabled = {!props.selectedMarginGroup.numberOfLeus}
            >
                <XpAdminFormEntityList
                    noParentData={true}
                    xpAdminFormInstanceId = {legalEntityUnitFormInstanceId}
                    xpAdminFormConfig = {getMarginGroupLegalEntityUnitAdminFormConfig()}
                    ormModel = 'LegalEntityUnit'
                    listComponent = {LegalEntityUnitsList}
                    formTemplate = 'legalEntityUnitForm'
                    filterFunction={(legalEntityUnit) => {
                        return legalEntityUnit.marginGroupId === props.selectedMarginGroup?.id
                    }}
                />
            </XpAccordion>
        </div>
    )
}

function AgreementsWithMarginGroup(props) {
    if (!props.selectedMarginGroup) {
        return null;
    }
    return (
        <div>
            <XpAccordion
                headerKey='marginGroupForm.agreements'
                headerParams={{marginGroup: props.selectedMarginGroup.name, count: props.selectedMarginGroup.numberOfAgreements}}
                instanceId="marginGroupsAgreements"
                defaultShowState={false}
                disabled = {!props.selectedMarginGroup.numberOfAgreements}
            >
                <XpAdminFormEntityList
                    noParentData={true}
                    xpAdminFormInstanceId ={agreementFormInstanceId}
                    xpAdminFormConfig = {getMarginGroupAgreementAdminFormConfig()}
                    ormModel = 'Agreement'
                    listComponent = {AgreementsList}
                    formTemplate = 'agreementForm'
                    filterFunction={(agreement) => {
                        return agreement.marginGroup?.id === props.selectedMarginGroup?.id;
                    }}
                />
            </XpAccordion>
        </div>
    )
}
