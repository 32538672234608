import {PriceMargin} from "features/marginGroup/formComponents/PriceMargin";
import {fxTradeReportDeliveryStates} from "features/fxTradeReport/fxTradeReportConstants";

export const refixRunConfigurations = {
    marginGroupOverride: {
        formComponent  : PriceMargin,
        useAccordion   : true,
        newTemplate    : {isActive: true, priceType: 'BIDASK', bidMarginBps: "", askMarginBps: ""},
        // listDependentFields: {parentAccountId: 'parentAccountId'},
        formDependentFields: {currencyPairName: 'currencyPairName'},
        getLabelKey    : (formContext, fields) => {
            if (!fields.currencyPairName) return formContext.formTemplate + '.isActive.noCurrencyPairDefined'
            return formContext.formTemplate + '.isActive.label'
        },
        getLabelParams    : (formContext, fields) => {return {currencyPair: fields.currencyPairName}},
        getFormTemplate: (formContext, fields) => 'runConfiguration',
        // hideConfigType : (formContext, fields) => (formContext.ormModel === 'BankAccount') && !fields.parentAccountId,
        userCanManage     : (rootObjectData) => true,
    },
}
