import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectOverallCurrencyExposure} from "features/currencyExposure/currencyExposureSelectors";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import "features/currencyExposure/currencyExposure.css";
import {userSelector} from "gui-common/orm/ormSelectors";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {listRateProcessStatusSelector} from "features/listRate/listRateSelectors";
import {useSelectorRef} from "gui-common/functions/hooks";
import {
    getListRatesAffectedByAction,
    launchListRatesAction,
    listRateNotExemptFromGlobalActions
} from "features/listRate/listRateFunctions";
import {
    agreementNotExemptFromGlobalActions,
    getAgreementsAffectedByAction,
    launchListRateAgreementAction
} from "features/agreement/agreementFunctions";
import {refixSystemRights} from "appConfig/appUserRights";

export function OverallExposure () {
    const user          = useSelector(userSelector);
    const totalExposure = useSelector(selectOverallCurrencyExposure);
    const homeCurrency  = useSelector(state => state.userPreferencesState.homeCurrency);
    const commentIsMandatoryConfig = useSelector(state => state.appEnvState.mandatoryListRateActionComment.all);

    const listRateProcessStatusRef = useSelectorRef(listRateProcessStatusSelector);

    const dispatch      = useDispatch();

    const userCanSuspendAll  = userHasSystemRight(user, refixSystemRights.ListRatesAll.Suspend);
    const userCanResumeAll   = userHasSystemRight(user, refixSystemRights.ListRatesAll.Resume);
    const userCanExpireAll   = userHasSystemRight(user, refixSystemRights.ListRatesAll.Expire);
    const userCanDispatchAll = userHasSystemRight(user, refixSystemRights.ListRatesAll.Dispatch);

    const notExemptAgreements = useMemo(
        () => {
            return totalExposure?.agreements?.filter(agreementNotExemptFromGlobalActions);
        },
        [totalExposure?.agreements]
    );

    const notExemptListRates = useMemo(
        () => {
            return totalExposure?.listRates?.filter(listRateNotExemptFromGlobalActions);
        },
        [totalExposure?.listRates]
    );

    const createAgreementButton = (action) => {
        const affectedAgreements = getAgreementsAffectedByAction(notExemptAgreements, action);
        const affectedListRates  = getListRatesAffectedByAction(notExemptListRates,  action);
        return (
            <WaitingXpoolButton
                labelKey        = {'overallExposure.' + action + '.button'}
                labelParams     = {{count: affectedAgreements.length}}
                onClickCallback = {() => launchListRateAgreementAction(affectedAgreements, affectedListRates, action, {type: 'ListRateAgreement'}, 'overallExposure', "", commentIsMandatoryConfig, listRateProcessStatusRef, dispatch, action === 'suspend')}
                disabled        = {affectedAgreements.length === 0}
                toolTipKey      ={'overallExposure.' + action + '.toolTip'}
                disabledToolTipKey ={'overallExposure.' + action + '.disabledToolTip'}
                toolTipParams   = {{count: affectedAgreements.length}}
            />
        )};

    const createListRateButton = (action) => {
        const affectedListRates = getListRatesAffectedByAction(notExemptListRates, action);
        return (
            <WaitingXpoolButton
                labelKey        = {'overallExposure.' + action + '.button'}
                labelParams     = {{count: affectedListRates.length}}
                onClickCallback = {() => launchListRatesAction(affectedListRates, action, {}, 'overallExposure', "", commentIsMandatoryConfig, listRateProcessStatusRef, dispatch)}
                disabled        = {affectedListRates.length === 0}
                toolTipKey      ={'overallExposure.' + action + '.toolTip'}
                disabledToolTipKey ={'overallExposure.' + action + '.disabledToolTip'}
                toolTipParams   = {{count: affectedListRates.length}}
            />
        )};

    return (
        <div xp-test-id="overallExposure" className="overallExposureContainer">
            <h3 className="overallExposureHeader">
                <XpTranslated trKey={'overallExposure.exposure'} trParams={{exposure: totalExposure.totalExposure, currency: homeCurrency}}/>
            </h3>
            <div className="overallExposureButtonsContainer">
                {userCanSuspendAll  && createAgreementButton('suspend' )}
                {userCanResumeAll   && createAgreementButton('resume'  )}
                {userCanExpireAll   && createListRateButton( 'expire'  )}
                {userCanDispatchAll && createListRateButton( 'dispatch')}
            </div>
        </div>
    );
}

