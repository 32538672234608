import React from "react";
import {withRouter} from "react-router-dom";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {getClientAdminFormConfig} from "appConfig/xpAdminFormConfig";
import XpAdminFormEntityList from "gui-common/xpAdminForm/XpAdminFormEntityList";
import ClientsList from "features/client/ClientsList";
import {useSelector} from "react-redux";
import {getFormLabelKey} from "gui-common/xpForm/formComponentsFunctions";
import {operatingUnitsWithFullClientCreateSelector} from "features/operatingUnit/operatingUnitSelectors";

function ClientSettingsPage () {

    const operatingUnits = useSelector(state => operatingUnitsWithFullClientCreateSelector(state, {nestedPropToCheck: 'nestedRightRequiredForAdmin'}));

    return (
        <div>
            <h2>
                <XpTranslated trKey={getFormLabelKey({formTemplate: 'clientForm'}, ".formHeader")}/>
            </h2>
            <hr/>
            <div>
                <XpAdminFormEntityList
                    noParentData          = {true}
                    xpAdminFormInstanceId = 'clientSettings'
                    xpAdminFormConfig     = {getClientAdminFormConfig()}
                    ormModel              = 'Client'
                    listComponent         = {ClientsList}
                    formTemplate          = 'clientForm'
                    userCanAddEntity      = {operatingUnits.length !== 0} // If any op unit in list the user can add entity
                />
            </div>
        </div>
    )
}
export default withRouter(ClientSettingsPage)
