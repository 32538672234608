import {globalOrmConfig} from "gui-common/orm/ormModels";
import {entityIsDisabled} from "gui-common/audit/auditFunctions";
import {commonDataRights} from "gui-common/userRights/userRightsConstants";

export const allowAll = (window.oxygen_env && (window.oxygen_env.ALLOW_ALL !== undefined)) ? window.oxygen_env.ALLOW_ALL : false;

//************************************************************************************************************
//************** INSTANCE RIGHTS FUNCTIONS *********************************************************************
//************************************************************************************************************

export function transformExecutionRights(item) {
    if (item?.executionRights?.length) {
        item.executionRights = item.executionRights.reduce((retList, right) => {
            if (right.active) {
                retList.push(right.right);
            }
            return retList;
        }, []);
    }
}
export const instanceRightAllowed = (instance, right) => {
    if (allowAll) return true;
    if (!instance?.executionRights) return false;
    return (instance.executionRights.includes(right));
};
function nestedInstanceRightAllowed(instance, thisModelToCheck, rightToCheck, nestedPropToCheck) {
    if (!instanceRightAllowed(instance, rightToCheck)) {
        return false;
    }
    if (!nestedPropToCheck) {
        return true;
    }
    if (!globalOrmConfig.nestedEntities[thisModelToCheck]) {
        return true;
    }
    for (let nestedModel of globalOrmConfig.nestedEntities[thisModelToCheck]) {
        if (!nestedModel[nestedPropToCheck]) continue;
        if (!instance[nestedModel.property]) continue;
        for (let childInstance of instance[nestedModel.property]) {
            if (entityIsDisabled(childInstance)) continue;
            if (!nestedInstanceRightAllowed(childInstance, nestedModel.model, rightToCheck, nestedPropToCheck)) return false;
        }
    }
    return true;
}
export function userCanDelete(objectInstance, ormModel) {
    if (!objectInstance) return false;
    if (ormModel) return nestedInstanceRightAllowed(objectInstance, ormModel, commonDataRights.CRUD.Delete, 'nestedRightRequiredForAdmin');
    return instanceRightAllowed(objectInstance,commonDataRights.CRUD.Delete);
}
export function userCanUpdate(objectInstance) {
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,commonDataRights.CRUD.Update);
}
// --- field specific rights on Client, Legal entity, Legal Entity Unit and BankAccount ----
export function userCanEditBase(objectInstance) {
    if (!objectInstance) return false;
    return instanceRightAllowed(objectInstance,commonDataRights.CRUD.EditBase);
}



//************************************************************************************************************
//************** DOMAIN RIGHTS FUNCTIONS *********************************************************************
//************************************************************************************************************

/*export const domainRightAllowed = (model, instance, right) => {
    if (allowAll) return true;
    if (!instance.executionRights || !instance.executionRights.domainRights) return false;
    const prototypes = globalUserRightsConfig.modelToPrototypeMap[model];
    for (let prototype of prototypes) {
        const prototypeDomainRights = instance.executionRights.domainRights[prototype];
        if (!prototypeDomainRights) return false;
        if (prototypeDomainRights.entityPrototypeRights[right] === true) return true;
    }
    return false;
};*/

/*
export function userCanReadDomain(modelToRead, parent) {
    if (allowAll) return true;
    if (!modelToRead) {
        console.error("No modelToRead in userCanReadDomain");
        return false;
    }
    if (typeof parent === 'object') {
        return domainRightAllowed(modelToRead, parent, 'Read');
    }
    else {
        console.error("Incorrect type for parent in userCanCreate");
        return false;
    }
}
*/

// This will only work for Create since all other rights would require to parse instanceRights structure in actual data
/*
function nestedDomainRightsAllowed(instance, thisModelToCheck, rightToCheck, nestedPropToCheck) {
    if (!domainRightAllowed(thisModelToCheck, instance, rightToCheck)) return false;
    if (!nestedPropToCheck) return true;
    for (let nestedModel of globalOrmConfig.nestedEntities[thisModelToCheck]) {
        if (!nestedModel[nestedPropToCheck]) continue;
        if (!nestedDomainRightsAllowed(instance, nestedModel.model, rightToCheck, nestedPropToCheck)) return false;
    }
    return true;
}
*/

// Used to check for what op units the user can create full client structure including bank accounts.
/*
export function userCanCreate(modelToCreate, parent, nestedPropToCheck) {
    if (allowAll) return true;
    if (!modelToCreate) {
        console.error("No modelToCreate in userCanCreate");
        return false;
    }

    let parentArray;
    if      (Array.isArray(parent))      parentArray = parent
    else if (typeof parent === 'object') parentArray = [parent]
    else {
        console.error("Incorrect type for parent in userCanCreate");
        return false;
    }
    for (let parent of parentArray) if (nestedDomainRightsAllowed(parent, modelToCreate, 'Create', nestedPropToCheck)) return true;
    return false;
}
*/


//************************************************************************************************************
//************** SYSTEM RIGHTS FUNCTIONS *********************************************************************
//************************************************************************************************************
export function systemRightAllowed(user, domain, right) {
    if (allowAll) return true;
    if (!user?.systemRights?.entitlements || !user.systemRights.entitlements[domain]) return false;
    return (user.systemRights.entitlements[domain][right] === true);
}
export function userHasSystemRight(user, systemRightFunction) {
    return systemRightAllowed(user, systemRightFunction.domain, systemRightFunction.right)
}

//************************************************************************************************************
//************** Support functions to get info on user rights configuration **********************************
//************************************************************************************************************
/*function getEntityDataRight(user, entity, prototype) {
    if (!user.dataRightsCollections || user.dataRightsCollections.length) return undefined;

    for (let dataRightsCollection of user.dataRightsCollections) {
        if (!dataRightsCollection.assigned) continue;
        for (let entityDataRight of dataRightsCollection.assigned) {
            if (entityDataRight.entity.id !== entity.id) continue;
            if (entityDataRight.entityPrototypeName !== prototype) continue;
            return entityDataRight;
        }
    }
}
function userRightRestricted(entityDataRight, right, user) {
    if (!right || !entityDataRight) return false;

    if (entityDataRight.instanceRights && (entityDataRight.instanceRights[right] === false)) return true;

    if (entityDataRight.domainRights) {
        for (let domain in entityDataRight.domainRights) {
            const domainRights = entityDataRight.domainRights[domain];
            if (domainRights && domainRights.entityPrototypeRights && (domainRights.entityPrototypeRights[right] === false)) return true;
        }
    }
    if (!entityDataRight.childDataRightsCollectionId) return false;

    const childDataRightsCollection = user.dataRightsCollections.find(item => item.id === entityDataRight.childDataRightsCollectionId);
    if (!childDataRightsCollection || !childDataRightsCollection.assigned || !childDataRightsCollection.assigned.length) return false;

    for (let childEntityDataRight of childDataRightsCollection.assigned) {
        if (userRightRestricted(childEntityDataRight, right, user)) return true;
    }
    return false;
}*/
/*
export function userRightRestrictedBelowEntity(
    user       = xpIsRequired('user'  ),
    entity     = xpIsRequired('entity'),
    model      = xpIsRequired('model' ),
    right      = xpIsRequired('right' ),
) {
    if (allowAll) return false;
    if (!user ||!entity || !model || !right) {
        console.error("Incorrect parameters to function userRightRestrictedBelowEntity", user, entity, model, right);
        return true;
    }
    const entityDataRight = getEntityDataRight(user, entity, globalUserRightsConfig.modelToPrototypeMap[model]);
    if (!entityDataRight) return false;

    return userRightRestricted(entityDataRight, right, user);
}
*/

/*
function getFilteredList(list) {
}
*/
