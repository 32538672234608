import React from "react";
import {useDispatch} from "react-redux";
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {xpTranslatedSetEditTextMode} from "gui-common/appLocale/xpTranslated/xpTranslatedReducer";
import {useXpTranslatedEditTextMode} from "gui-common/appLocale/xpTranslated/xpTranslatedSelectors";
import XpFormSwitchInput from "gui-common/xpForm/core/XpFormSwitchInput";


export function EditTextModeSetting () {
    const editTextMode = useXpTranslatedEditTextMode();
    const dispatch     = useDispatch();

    return (
        <div>
            <h3 className="userPrefsCardHeader">
                <XpTranslated trKey={'appLangSetEditMode.header'}/>
            </h3>

            <XpFormSwitchInput
                field={'activateEditTestMode'}
                labelKey={'appLangSetEditMode.activateEditTestMode'}
                onChangeCallback={value => dispatch(xpTranslatedSetEditTextMode(value))}
                defaultValue={editTextMode}
            />

        </div>
    )
}
