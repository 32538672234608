import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import WaitingXpoolButton from "gui-common/components/WaitingXpoolButton";
import ReactModal from "react-modal";
import FocusLock from "react-focus-lock";
import {topicsSelector, userSelector} from "gui-common/orm/ormSelectors"
import {XpTranslated} from "gui-common/appLocale/xpTranslated/XpTranslated";
import {generateUserMessageThunk} from "gui-common/userMessages/userMessageThunks";
import {userMessageTypes} from "gui-common/userMessages/userMessageTypeEnum";
import {MODAL_TYPE_CANCEL} from "gui-common/modals/modalResultTypes";
import {userHasSystemRight} from "gui-common/userRights/userRightsFunctions";
import {modalStandardParams} from "gui-common/modals/modalConstants";
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpForm from "gui-common/xpForm/core/XpForm";
import {useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {xpFormRemoveField, xpFormSubmitForm} from "gui-common/xpForm/core/xpFormReducer";
import {XP_FORM_EDIT} from "gui-common/xpForm/core/xpFormConstants";
import XpFormTextAreaInput from "gui-common/xpForm/core/XpFormTextAreaInput";

const thisFormName = "createUserMessageForm";
function CreateUserMessage({popModalWindow, confirmText, cancelText, translationKey}) {

    const topics    = useSelector(state => topicsSelector(state));
    const thisUser  = useSelector(userSelector);

    const dispatch  = useDispatch();

    const formDataMessage = useXpFormField(thisFormName + '.message');
    const formDataTopic   = useXpFormField(thisFormName + '.topic');
    const formDataType    = useXpFormField(thisFormName + '.type');

    const modalParams = {...modalStandardParams, className: {...modalStandardParams.className, base: "xpoolModal xpoolModalGenerateUserMessage"}};

    const topicsToShow = useMemo(
        () => {
            return topics.filter(topic => userHasSystemRight(thisUser, {domain: 'UmTopic' + topic.topic, right: 'Create'}))
                .map(topic => ({id: topic.topic, name: topic.topic}));
        },
        []
    );

    function cancelAction() {
        clearForm();
        popModalWindow({ status: MODAL_TYPE_CANCEL });
    }
    function clearForm(){
        dispatch(xpFormRemoveField(thisFormName + '.message'));
        dispatch(xpFormRemoveField(thisFormName + '.topic'));
        dispatch(xpFormRemoveField(thisFormName + '.type'));
    }

    return (
        <div>
            <FocusLock>
                <ReactModal
                    contentLabel="Generate user message"
                    onRequestClose={() => cancelAction()}
                    shouldCloseOnEsc={true}
                    {...modalParams}
                >
                    <div xp-test-id="generateUserMessageModal">
                        <h3 className="xpoolModalHeader"><XpTranslated trKey={'topMenu.generateUserMessage.menuItem'}/></h3>
                        <hr/>
                        <span><XpTranslated trKey={'topMenu.generateUserMessage.description'}/></span>
                        <hr/>
                        <XpForm
                            formModel="createUserMessageForm"
                            initialUseState={XP_FORM_EDIT}
                            onSubmit={()=>{
                                dispatch(generateUserMessageThunk(formDataType, formDataMessage, formDataTopic));
                                clearForm();
                                popModalWindow({ status: MODAL_TYPE_CANCEL });
                            }}
                            formTemplate='userMessages.addUserMessage'
                            submitOnEnter
                        >
                            <XpFormSelectorInput
                                isRequired inLineLayout
                                field='topic'
                                selectList={topicsToShow}
                                autoFocus={true}
                            />
                            <XpFormSelectorInput
                                isRequired inLineLayout noTemplate
                                field='type'
                                selectList={Object.values(userMessageTypes).map(type => ({id: type, name: type}))}
                                defaultValue={userMessageTypes.INFO}
                            />
                            <XpFormTextAreaInput
                                field         = "message"
                                maxLength     = {200}
                            />
                        </XpForm>
                        <div className="xpoolModalButtons">
                            <WaitingXpoolButton
                                labelKey        ='userMessages.addUserMessage.buttonConfirm'
                                onClickCallback = {() => dispatch(xpFormSubmitForm(thisFormName))}
                                disabled        = {formDataMessage === "" || formDataTopic === ""}
                            />
                            <WaitingXpoolButton
                                labelKey        = 'general.buttons.cancel'
                                onClickCallback = {() => cancelAction()}
                            />
                        </div>
                    </div>
                </ReactModal>
            </FocusLock>
        </div>
    );
}
export default CreateUserMessage;
