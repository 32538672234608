import {createSelector as ormCreateSelector} from "redux-orm";
import {dbStateSelector} from "gui-common/orm/dbStateSelector";
import {orm} from "gui-common/orm/orm";


export const auditEntriesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.AuditEntry.all().orderBy('id', 'desc').toModelArray().map(item => item.ref);
//        console.log("Audit entries: ", returnArray);
        return returnArray;
    }
);
export const getAuditEntrySelector = () => ormCreateSelector(
    orm,
    dbStateSelector,
    (state, props) => props ? props.selectId : undefined,
    (state, props) => props ? props.auditEntries : undefined,
    (session, selectId, auditEntries) => {
        const entryFromOrm = session.AuditEntry.idExists(selectId) ? session.AuditEntry.withId(selectId).ref : undefined;
        return entryFromOrm ? entryFromOrm : auditEntries?.find(item => item.id === selectId);
    }
);
export const auditEntrySelector = getAuditEntrySelector()
export const activeAuditRootModelsSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.AuditRootModel.filter({enabled: true}).orderBy('sortOrder', 'asc').toRefArray();
        return returnArray;
    }
);
export const activeAuditActionTypesSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    (session) => {
        let returnArray = session.AuditUserActionType.filter({enabled: true}).toRefArray();
        return returnArray.map(item => ({...item, trKey: 'auditUserActionTypes.' + item.name + '.label'}));
    }
);
export const activeAuditActionTypesMapSelector = ormCreateSelector(
    orm,
    dbStateSelector,
    activeAuditActionTypesSelector,
    (session, actionTypes) => {
        let returnMap = {};
        actionTypes.forEach(type => {
            returnMap[type.prototype] = {...type};
        })
        return returnMap;
    }
);
