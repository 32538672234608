import React, {useEffect, useMemo, useState} from 'react'
import XpFormSelectorInput from "gui-common/xpForm/core/XpFormSelectorInput";
import XpFormFreeFormSelectorInput from "gui-common/xpForm/core/XpFormFreeFormSelectorInput";
import {xpFormChangeField} from "gui-common/xpForm/core/xpFormReducer";
import {useXpFormContext, useXpFormField} from "gui-common/xpForm/core/xpFormHooks";
import {useDispatch} from "react-redux";
import {loadAuditSearchValuesFromApi} from "gui-common/audit/auditApi";
import XpFormCheckboxInput from "gui-common/xpForm/core/XpFormCheckboxInput";

function mapList(list) {
    return list.reduce((retList, item) => {
        if (item === null) {
            return retList;
        }
        retList.push({id: item, name: item});
        return retList;
    }, []);
}


export function AuditSearchField (props) {
    const formContext = useXpFormContext();
    const dispatch = useDispatch();
    const formDataFieldType = useXpFormField('fieldType');
    const formDataFieldName = useXpFormField('fieldName');

    const [searchValues         , setSearchValues       ] = useState(undefined);
    const [searchValuesLoading, setSearchValuesLoading] = useState(false);

    const searchTypes = useMemo(
        () => {
            return props.searchTypes ? mapList(Object.keys(props.searchTypes)) : [];
        },
        [props.searchTypes]
    );
    const searchFields = useMemo(
        () => {
            const fieldTypesList = props.searchTypes ? props.searchTypes[formDataFieldType] : undefined;
            if (!fieldTypesList?.length) {
                return [];
            }
            return mapList(fieldTypesList);
        },
        [props.searchTypes, formDataFieldType]
    );

    useEffect(
        () => {
            if (!formDataFieldType || searchValuesLoading) {
                return;
            }
            if (!formDataFieldName) {
                setSearchValues(undefined);
                return;
            }
            setSearchValuesLoading(true);
            dispatch(loadAuditSearchValuesFromApi(formDataFieldType, formDataFieldName))
                .then(result => {
                    setSearchValues(mapList(result.response));
                    setSearchValuesLoading(false);
                })
                .catch(result => {
                    setSearchValues(undefined);
                    setSearchValuesLoading(false);
                });
        },
        [formDataFieldType, formDataFieldName],
    );

    if (!searchTypes?.length) {
        return null;
    }

    return (
        <div className={props.className}>
            <XpFormSelectorInput
                isRequired noTemplate
                field="fieldType"
                selectList={searchTypes}
                itemTextField="name"
                defaultValue={searchTypes[0].id}
            />
            <XpFormSelectorInput
                field="fieldName"
                selectList={searchFields}
                itemTextField="name"
            />
            <XpFormFreeFormSelectorInput
                field="fieldValue"
                // selectList={["Hejsan","Kalle","Bollar"]}
                selectList={searchValues ? searchValues : []}
                itemTextField="name"
                noTemplate
                defaultValue=""
                onChangeCallback={(value, formattedValue, params) => {
                    // console.log("In callback", value, formattedValue, params);
                    dispatch(xpFormChangeField(formContext.formModel + '.exactFieldValue', typeof params?.selectedItem === 'object'));
                }}
            />
            <XpFormCheckboxInput
                field={'exactMatch'}
                defaultValue={true}
            />
        </div>
    );
}
